import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { AppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  Chip,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BookIcon from '@mui/icons-material/Book';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedIcon from '@mui/icons-material/Verified';
import CollectionsIcon from '@mui/icons-material/Collections';
import { RootState } from '../../types/store';
import { fetchUserIdeabooks } from '../../redux/slices/ideabookSlice';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { ideabooks = [], loading: ideabooksLoading } = useSelector((state: RootState) => ({
    ideabooks: state.ideabooks.ideabooks || [],
    loading: state.ideabooks.loading,
  }));

  useEffect(() => {
    dispatch(fetchUserIdeabooks());
  }, [dispatch]);

  const userName = user?.name || 'Guest';
  // Consider a user profile complete if they have name, email, and role
  const isProfileComplete = !!(user?.name && user?.email && user?.role);
  // For now, consider Google-authenticated users as verified
  const isVerified = !!user?.googleId;

  const quickActions = [
    {
      title: 'Search for services',
      icon: <SearchIcon />,
      onClick: () => navigate('/search'),
      description: 'Find trusted service providers in your area',
    },
    {
      title: 'View your ideabooks',
      icon: <CollectionsIcon />,
      onClick: () => navigate('/ideabooks'),
      description: 'Browse and manage your saved ideas and inspirations',
    },
    {
      title: 'View your services',
      icon: <BookIcon />,
      onClick: () => navigate('/services'),
      description: 'Check your service requests and appointments',
    },
    {
      title: 'Update your profile',
      icon: <PersonIcon />,
      onClick: () => navigate('/dashboard/profile-setup'),
      description: 'Keep your information up to date',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Welcome Section */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              mb: 3,
              background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
              color: 'white',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Avatar
                src={user?.avatar}
                alt={userName}
                sx={{ width: 64, height: 64, bgcolor: 'primary.dark' }}
              >
                {userName.charAt(0)}
              </Avatar>
              <Box>
                <Typography variant="h4" gutterBottom fontWeight="bold">
                  Welcome back, {userName}!
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {isVerified && (
                    <Chip
                      icon={<VerifiedIcon />}
                      label="Verified Account"
                      color="success"
                      size="small"
                    />
                  )}
                  {!isProfileComplete && (
                    <Chip
                      label="Complete Your Profile"
                      color="warning"
                      size="small"
                      onClick={() => navigate('/profile')}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Typography variant="h6" sx={{ opacity: 0.9 }}>
              Find and book trusted service providers for your home.
            </Typography>
          </Paper>
        </Grid>

        {/* Quick Actions */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="primary">
                Quick Actions
              </Typography>
              <List>
                {quickActions.map((action, index) => (
                  <React.Fragment key={action.title}>
                    <ListItem
                      button
                      onClick={action.onClick}
                      sx={{
                        py: 2,
                        '&:hover': {
                          backgroundColor: 'action.hover',
                          transform: 'translateX(8px)',
                          transition: 'transform 0.2s',
                        },
                      }}
                    >
                      <ListItemIcon sx={{ color: 'primary.main' }}>
                        {action.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={action.title}
                        secondary={action.description}
                        primaryTypographyProps={{
                          fontWeight: 'medium',
                        }}
                      />
                    </ListItem>
                    {index < quickActions.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Recent Activity */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="primary">
                Recent Ideabooks
              </Typography>
              {ideabooksLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <Typography>Loading ideabooks...</Typography>
                </Box>
              ) : ideabooks.length > 0 ? (
                <ImageList sx={{ maxHeight: 300 }} cols={2} rowHeight={164}>
                  {ideabooks.slice(0, 4).map((ideabook) => (
                    <ImageListItem
                      key={ideabook._id}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.8,
                          transform: 'scale(1.02)',
                          transition: 'all 0.2s',
                        },
                      }}
                      onClick={() => navigate(`/ideabooks/${ideabook._id}`)}
                    >
                      <img
                        src={ideabook.coverImage || '/placeholder-ideabook.jpg'}
                        alt={ideabook.title}
                        loading="lazy"
                        style={{ objectFit: 'cover', height: '100%' }}
                      />
                      <ImageListItemBar
                        title={ideabook.title}
                        subtitle={`${ideabook.itemCount} items`}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              ) : (
                <Box
                  sx={{
                    color: 'text.secondary',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 200,
                    textAlign: 'center',
                    p: 3,
                  }}
                >
                  <Typography variant="body1">
                    No ideabooks created yet.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/ideabooks/new')}
                    sx={{ mt: 2 }}
                  >
                    Create Your First Ideabook
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Coming Soon Features */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              mt: 2,
              background: 'linear-gradient(45deg, #3f51b5 30%, #7986cb 90%)',
              color: 'white',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: -15,
                right: -15,
                backgroundColor: 'rgba(255,255,255,0.1)',
                borderRadius: '50%',
                width: 150,
                height: 150,
              }}
            />
            <Typography variant="h5" gutterBottom fontWeight="bold">
              Coming Soon
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'medium' }}>
                  • Schedule recurring services
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.8 }}>
                  Set up regular maintenance for your home
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'medium' }}>
                  • Real-time service tracking
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.8 }}>
                  Know exactly when your service provider will arrive
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'medium' }}>
                  • Service provider ratings
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.8 }}>
                  Share your experience and read reviews from others
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
