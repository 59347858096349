import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import axios from '../../services/axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Alert,
  Divider,
  CircularProgress,
} from '@mui/material';
import { loginStart, loginSuccess, loginFailure } from '../../redux/slices/authSlice';
import authService from '../../services/authService';
import GoogleLoginButton from '../../components/auth/GoogleLoginButton';
import { RootState } from '../../redux/store';
import analytics from '../../utils/analytics';

interface LoginFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const [serverError, setServerError] = useState('');
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>
  ) => {
    setServerError('');
    try {
      dispatch(loginStart());
      const response = await authService.login(values);
      dispatch(loginSuccess(response));

      // Track successful login
      analytics.trackLogin('email', response.user._id);

      // Check if user is a provider and redirect to onboarding if needed
      if (response.user.role === 'provider') {
        // Check if onboarding is completed
        try {
          const onboardingResponse = await axios.get('/onboarding/status', {
            headers: { Authorization: `Bearer ${response.token}` },
          });
          const onboardingData = onboardingResponse.data;

          if (!onboardingData.onboardingStatus.completed) {
            // Redirect to onboarding if not completed
            navigate('/pro/onboarding/0');
            return;
          }
        } catch (error) {
          console.error('Error checking onboarding status:', error);
        }
      }

      // Redirect based on user role
      if (response.user.role === 'provider') {
        navigate('/pro/dashboard');
      } else if (response.user.role === 'seller' as any) {
        navigate('/marketplace/seller/dashboard');
      } else if (response.user.role === 'admin') {
        navigate('/admin/dashboard');
      } else {
        // Default navigation for customers
        navigate('/');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Login failed';
      setServerError(errorMessage);
      dispatch(loginFailure(errorMessage));
    } finally {
      setSubmitting(false);
    }
  };

  const handleGoogleStart = () => {
    setIsGoogleLoading(true);
    setServerError('');
  };

  const handleGoogleError = (error: Error) => {
    setIsGoogleLoading(false);
    setServerError(error.message);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: '100%',
          maxWidth: 400,
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom align="center">
          Login to Homezy
        </Typography>

        {serverError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {serverError}
          </Alert>
        )}

        <GoogleLoginButton
          onStart={handleGoogleStart}
          onError={handleGoogleError}
        />

        <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography variant="body2" color="text.secondary">
            OR
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, handleBlur, values, isSubmitting }) => (
            <Form>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                margin="normal"
                disabled={isSubmitting}
              />

              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                margin="normal"
                disabled={isSubmitting}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, height: 48 }}
                disabled={isSubmitting || isGoogleLoading}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Login'
                )}
              </Button>

              <Box sx={{ textAlign: 'center' }}>
                <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
                  <Typography color="primary" sx={{ mb: 1 }}>
                    Forgot password?
                  </Typography>
                </Link>
                <Link to="/register" style={{ textDecoration: 'none' }}>
                  <Typography color="primary">Don't have an account? Register</Typography>
                </Link>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default Login;
