import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Avatar,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Business as BusinessIcon,
  LocationOn as LocationOnIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import axiosInstance from '../../services/axios';

interface Service {
  _id: string;
  title: string;
  description: string;
  category: {
    name: string;
  };
  price: {
    type: string;
    amount: number;
    minAmount?: number;
    maxAmount?: number;
  };
}

interface ProviderDetail {
  _id: string;
  user: {
    _id: string;
    name: string;
    email: string;
    phone?: string;
    avatar?: string;
  };
  businessName: string;
  businessDescription?: string;
  yearsInBusiness?: number;
  location?: {
    address: string;
    coordinates: [number, number];
  };
  services: Service[];
  isVerified: boolean;
  isActive: boolean;
  averageRating?: number;
  totalReviews?: number;
  completedJobs?: number;
  createdAt: string;
  updatedAt: string;
  rejectionReason?: string;
}

const ProviderDetail: React.FC = () => {
  const { providerId } = useParams<{ providerId: string }>();
  const navigate = useNavigate();
  
  const [provider, setProvider] = useState<ProviderDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    fetchProviderDetails();
  }, [providerId]);

  const fetchProviderDetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/admin/providers/${providerId}`);
      setProvider(response.data);
      if (response.data.rejectionReason) {
        setRejectionReason(response.data.rejectionReason);
      }
    } catch (err: any) {
      console.error('Error fetching provider details:', err);
      setError(err.message || 'Failed to load provider details');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyProvider = () => {
    setVerificationDialogOpen(true);
  };

  const handleCloseVerificationDialog = () => {
    setVerificationDialogOpen(false);
  };

  const handleSubmitVerification = async (isVerified: boolean) => {
    if (!provider) return;
    
    try {
      setVerifying(true);
      
      const payload: { isVerified: boolean; rejectionReason?: string } = {
        isVerified
      };
      
      if (!isVerified && rejectionReason.trim()) {
        payload.rejectionReason = rejectionReason.trim();
      }
      await axiosInstance.put(`/admin/providers/${provider._id}/verify`, payload);
      
      
      // Update the provider state
      setProvider(prev => prev ? { ...prev, isVerified } : null);
      setVerificationDialogOpen(false);
      
    } catch (err: any) {
      console.error('Error verifying provider:', err);
      setError(err.message || 'Failed to verify provider');
    } finally {
      setVerifying(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>Loading provider details...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/admin/providers')}
        >
          Back to Providers
        </Button>
      </Container>
    );
  }

  if (!provider) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="warning" sx={{ mb: 3 }}>
          Provider not found
        </Alert>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/admin/providers')}
        >
          Back to Providers
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/admin/providers')}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h4" component="h1">
          Provider Details
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Provider Info */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                src={provider.user.avatar}
                sx={{ width: 80, height: 80, mr: 2 }}
              >
                {provider.businessName.charAt(0)}
              </Avatar>
              <Box>
                <Typography variant="h5">{provider.businessName}</Typography>
                <Box sx={{ mt: 1 }}>
                  {provider.isVerified ? (
                    <Chip label="Verified" color="success" size="small" />
                  ) : (
                    <Chip label="Unverified" color="default" size="small" />
                  )}
                  {' '}
                  {provider.isActive ? (
                    <Chip label="Active" color="primary" size="small" sx={{ ml: 1 }} />
                  ) : (
                    <Chip label="Inactive" color="default" size="small" sx={{ ml: 1 }} />
                  )}
                </Box>
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <List dense>
              <ListItem>
                <ListItemText 
                  primary="Provider Name" 
                  secondary={provider.user.name} 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Email" 
                  secondary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <EmailIcon fontSize="small" sx={{ mr: 1 }} />
                      {provider.user.email}
                    </Box>
                  } 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              {provider.user.phone && (
                <ListItem>
                  <ListItemText 
                    primary="Phone" 
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PhoneIcon fontSize="small" sx={{ mr: 1 }} />
                        {provider.user.phone}
                      </Box>
                    } 
                    primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                    secondaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
              )}
              {provider.location && (
                <ListItem>
                  <ListItemText 
                    primary="Location" 
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocationOnIcon fontSize="small" sx={{ mr: 1 }} />
                        {provider.location.address}
                      </Box>
                    } 
                    primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                    secondaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
              )}
              {provider.yearsInBusiness && (
                <ListItem>
                  <ListItemText 
                    primary="Years in Business" 
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <BusinessIcon fontSize="small" sx={{ mr: 1 }} />
                        {provider.yearsInBusiness}
                      </Box>
                    } 
                    primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                    secondaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemText 
                  primary="Member Since" 
                  secondary={new Date(provider.createdAt).toLocaleDateString()} 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            </List>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color={provider.isVerified ? "error" : "success"}
                fullWidth
                onClick={handleVerifyProvider}
                startIcon={provider.isVerified ? <CloseIcon /> : <CheckIcon />}
              >
                {provider.isVerified ? "Revoke Verification" : "Verify Provider"}
              </Button>
            </Box>
          </Paper>

          {/* Stats Card */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Provider Stats
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h4">{provider.services?.length || 0}</Typography>
                  <Typography variant="body2" color="text.secondary">Services</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h4">{provider.totalReviews || 0}</Typography>
                  <Typography variant="body2" color="text.secondary">Reviews</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h4">{provider.completedJobs || 0}</Typography>
                  <Typography variant="body2" color="text.secondary">Jobs</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Provider Details */}
        <Grid item xs={12} md={8}>
          {/* Business Description */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Business Description
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1">
              {provider.businessDescription || "No business description provided."}
            </Typography>
          </Paper>

          {/* Services */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Services ({provider.services?.length || 0})
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            {provider.services && provider.services.length > 0 ? (
              <Grid container spacing={2}>
                {provider.services.map((service) => (
                  <Grid item xs={12} key={service._id}>
                    <Card variant="outlined">
                      <CardHeader
                        title={service.title}
                        subheader={service.category.name}
                        action={
                          <Tooltip title="View Service">
                            <IconButton>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary" paragraph>
                          {service.description}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Price:</strong> {' '}
                          {service.price.type === 'fixed' && `$${service.price.amount}`}
                          {service.price.type === 'hourly' && `$${service.price.amount}/hr`}
                          {service.price.type === 'range' && `$${service.price.minAmount} - $${service.price.maxAmount}`}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No services listed.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Verification Dialog */}
      <Dialog open={verificationDialogOpen} onClose={handleCloseVerificationDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {provider.isVerified 
            ? "Revoke Provider Verification" 
            : "Verify Provider"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              {provider.businessName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {provider.user.name} ({provider.user.email})
            </Typography>
            
            {!provider.isVerified && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="body1" gutterBottom>
                  Are you sure you want to verify this provider?
                </Typography>
              </Box>
            )}
            
            {provider.isVerified && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="body1" gutterBottom>
                  Are you sure you want to revoke verification for this provider?
                </Typography>
                <TextField
                  label="Reason for Rejection"
                  multiline
                  rows={4}
                  fullWidth
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVerificationDialog}>Cancel</Button>
          {!provider.isVerified && (
            <Button 
              onClick={() => handleSubmitVerification(true)}
              variant="contained" 
              color="primary"
              disabled={verifying}
              startIcon={verifying ? <CircularProgress size={20} /> : <CheckIcon />}
            >
              Verify Provider
            </Button>
          )}
          {provider.isVerified && (
            <Button 
              onClick={() => handleSubmitVerification(false)}
              variant="contained" 
              color="error"
              disabled={verifying}
              startIcon={verifying ? <CircularProgress size={20} /> : <CloseIcon />}
            >
              Revoke Verification
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProviderDetail;