import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import ProfessionalSearchBar from '../search/ProfessionalSearchBar';
import { ICategory } from '../../types/models';
import { SearchParams } from '../../types/search';
import { useNavigate } from 'react-router-dom';
import analytics from '../../utils/analytics';

interface EnhancedHeroSectionProps {
  categories?: ICategory[];
}

const EnhancedHeroSection: React.FC<EnhancedHeroSectionProps> = ({ categories = [] }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const handleProfessionalSearch = (params: SearchParams) => {
    const searchParams = new URLSearchParams();
    if (params.query) searchParams.set('service', params.query);
    if (params.location?.address) searchParams.set('emirate', params.location.address);

    // Track search event
    analytics.trackSearch(
      params.query || 'empty query',
      0 // We don't know the results count yet
    );

    navigate(`/find-professionals?${searchParams.toString()}`);
  };

  return (
    <Box sx={{ width: '100%', py: 4 }}>
      {/* Hero Section */}
      <Box sx={{ mb: 6, textAlign: 'center', maxWidth: '1200px', mx: 'auto' }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          fontWeight="bold"
          color="primary.dark"
        >
          Find Trusted Professionals
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          gutterBottom
          sx={{
            maxWidth: '600px',
            mx: 'auto',
            mb: 4,
          }}
        >
          Connect with top-rated service providers in your area
        </Typography>

        {/* Search Bar */}
        <Box sx={{ mt: 4 }}>
            <Box>
              <ProfessionalSearchBar onSearch={handleProfessionalSearch} />
            </Box>

            {/* Popular searches */}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  mr: 1,
                  fontWeight: 500,
                }}
              >
                Popular:
              </Typography>
              {['AC Maintenance', 'Villa Renovation', 'Interior Design', 'Cleaning Services', 'Pool Maintenance'].map((term) => (
                <Typography
                  key={term}
                  variant="body1"
                  color="primary"
                  sx={{
                    cursor: 'pointer',
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    transition: 'all 0.2s',
                    '&:hover': {
                      textDecoration: 'underline',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    // Track popular search term click
                    analytics.trackEvent({
                      category: 'Search',
                      action: 'Popular Term Click',
                      label: term,
                    });
                    navigate(`/find-professionals?service=${encodeURIComponent(term)}`);
                  }}
                >
                  {term}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
    </Box>
  );
};

export default EnhancedHeroSection;