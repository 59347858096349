import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Rating, Paper, Button,
  Divider, CircularProgress, Alert, Tabs, Tab,
  LinearProgress, Grid,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import axios from '../../../services/axios';
import { useAuth } from '../../../hooks/useAuth';
import ReviewSourceBadge from '../../reviews/ReviewSourceBadge';
import GoogleBusinessConnect from '../onboarding/GoogleBusinessConnect';
import GoogleReviewImport from '../onboarding/GoogleReviewImport';
import ReviewRequestForm from '../../reviews/ReviewRequestForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`reviews-tabpanel-${index}`}
      aria-labelledby={`reviews-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `reviews-tab-${index}`,
    'aria-controls': `reviews-tabpanel-${index}`,
  };
};

interface ProReviewsManagerProps {
  providerId?: string;
}

/**
 * Component for managing pro reviews
 * Shows existing reviews and provides options to import Google reviews or request reviews from customers
 */
const ProReviewsManager: React.FC<ProReviewsManagerProps> = ({ providerId }) => {
  const { getAuthToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [reviews, setReviews] = useState<any[]>([]);
  const [stats, setStats] = useState<any>({
    average: 0,
    total: 0,
    distribution: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
  });
  const [tabValue, setTabValue] = useState(0);

  // Fetch provider reviews and stats
  useEffect(() => {
    const fetchReviews = async () => {
      if (!providerId) {
        setError('Provider ID is required');
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const token = getAuthToken();
        if (!token) {
          setError('Authentication required');
          setLoading(false);
          return;
        }

        // Fetch reviews for this provider
        const reviewsResponse = await axios.get(`/reviews/provider/${providerId}`);
        setReviews(reviewsResponse.data.reviews || []);

        // Calculate stats
        if (reviewsResponse.data.reviews && reviewsResponse.data.reviews.length > 0) {
          const reviewsData = reviewsResponse.data.reviews;
          const total = reviewsData.length;
          let sum = 0;
          const distribution = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

          reviewsData.forEach((review: any) => {
            sum += review.rating;
            distribution[review.rating as 1|2|3|4|5]++;
          });

          setStats({
            average: total > 0 ? sum / total : 0,
            total,
            distribution,
          });
        }
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to fetch reviews');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [getAuthToken, providerId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Calculate percentage for rating distribution
  const calculatePercentage = (count: number) => {
    if (stats.total === 0) return 0;
    return (count / stats.total) * 100;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}

      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" gutterBottom>Reviews</Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography variant="h2" color="primary" sx={{ fontWeight: 'bold' }}>
                {stats.average.toFixed(1)}
              </Typography>
              <Rating value={stats.average} precision={0.5} readOnly size="large" />
              <Typography variant="body2" color="text.secondary">
                {stats.total} reviews
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box sx={{ mb: 3 }}>
              {[5, 4, 3, 2, 1].map((rating) => (
                <Box key={rating} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" sx={{ width: 30 }}>{rating}</Typography>
                  <Box sx={{ flexGrow: 1, mx: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(stats.distribution[rating])}
                      sx={{ height: 8, borderRadius: 4 }}
                    />
                  </Box>
                  <Typography variant="body2" sx={{ width: 40 }}>
                    {calculatePercentage(stats.distribution[rating]).toFixed(0)}%
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h6">
            Import reviews from Google, or request from other past customers
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setTabValue(1)}
          >
            Get reviews
          </Button>
        </Box>

        <Tabs value={tabValue} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="All Reviews" {...a11yProps(0)} />
          <Tab label="Get Reviews" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          {reviews.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                No reviews yet. Import reviews from Google or request reviews from your customers.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => setTabValue(1)}
              >
                Get Reviews
              </Button>
            </Box>
          ) : (
            <Box>
              {reviews.map((review, index) => (
                <Paper key={index} sx={{ p: 2, mb: 2, border: '1px solid #eee' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {review.customerName || 'Anonymous Customer'}
                    </Typography>
                    <ReviewSourceBadge source={review.source || 'homezy'} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Rating value={review.rating} readOnly size="small" />
                    <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                      {new Date(review.createdAt).toLocaleDateString()}
                    </Typography>
                  </Box>

                  <Typography variant="body1">{review.content}</Typography>
                </Paper>
              ))}
            </Box>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <GoogleIcon sx={{ mr: 1 }} /> Import Google Reviews
            </Typography>
            <Typography variant="body2" paragraph>
              Connect your Google Business Profile to import your existing reviews.
            </Typography>

            <Box sx={{ mt: 2 }}>
              <GoogleBusinessConnect />
              <GoogleReviewImport />
            </Box>
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <EmailIcon sx={{ mr: 1 }} /> Request Reviews via Email
            </Typography>
            <Typography variant="body2" paragraph>
              Send review requests to your past customers who haven't used Homezy yet.
            </Typography>

            <ReviewRequestForm />
          </Box>
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default ProReviewsManager;