import React, { useState, MouseEvent } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  ListItemIcon,
  useTheme,
  useMediaQuery,
  Badge,
} from '@mui/material';
import Logo from './Logo';
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  Person as PersonIcon,
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  ExitToApp as LogoutIcon,
  Settings as SettingsIcon,
  Add as AddIcon,
  Message as MessageIcon,
  Assignment as QuoteIcon,
  PhotoLibrary as IdeabookIcon,
  Style as StyleIcon,
  Lightbulb as InspirationIcon,
  ShoppingCart as CartIcon,
  Store as StoreIcon,
  MenuBook as MenuBookIcon,
  Article as ArticleIcon,
  CalendarMonth as CalendarMonthIcon,
  Calculate as CalculateIcon,
  BookOnline as BookOnlineIcon,
  Explore as ExploreIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import { RootState } from '../../types/store';
import NotificationDropdown from '../../components/pro/ui/NotificationDropdown';

interface MenuItem {
  label: string;
  icon: JSX.Element;
  path: string;
  show: boolean;
}

const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { items: cartItems } = useSelector((state: RootState) => state.cart);
  const isProvider = user?.role === 'provider';
  const isSeller = user?.role === 'seller';
  const isCustomer = user?.role === 'customer';

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<HTMLElement | null>(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState<HTMLElement | null>(null);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleResourcesMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setResourcesAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMobileMenuAnchor(null);
    setResourcesAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleMenuClose();
    navigate('/');
  };

  const menuItems: MenuItem[] = [
    {
      label: 'Ideas & Inspiration',
      icon: <InspirationIcon fontSize="small" />,
      path: '/ideas',
      show: isAuthenticated && isCustomer, // Only for customers
    },
    {
      label: 'My Ideabooks',
      icon: <IdeabookIcon fontSize="small" />,
      path: '/ideas/ideabooks',
      show: isAuthenticated && isCustomer, // Only for customers
    },
    {
      label: 'Dashboard',
      icon: <DashboardIcon fontSize="small" />,
      path: isProvider ? '/pro/dashboard' : isSeller ? '/seller/dashboard' : '/dashboard',
      show: isAuthenticated,
    },
    {
      label: 'Messages',
      icon: <MessageIcon fontSize="small" />,
      path: isProvider ? '/pro/messages' : isSeller ? '/seller/messages' : '/dashboard/messages',
      show: isAuthenticated,
    },
    {
      label: 'Notifications',
      icon: <NotificationsIcon fontSize="small" />,
      path: isProvider ? '/pro/notifications' : '/dashboard/notifications',
      show: isAuthenticated,
    },
    {
      label: 'Lead Requests',
      icon: <QuoteIcon fontSize="small" />,
      path: '/pro/leads',
      show: isAuthenticated && isProvider,
    },
    {
      label: 'Calendar',
      icon: <CalendarMonthIcon fontSize="small" />,
      path: '/pro/calendar',
      show: isAuthenticated && isProvider,
    },
    // Removed redundant "Seller Portal" option since sellers already have Dashboard
    {
      label: 'My Products',
      icon: <BusinessIcon fontSize="small" />,
      path: '/seller/dashboard/products',
      show: isAuthenticated && isSeller, // Only for sellers
    },
    {
      label: 'My Services',
      icon: <BusinessIcon fontSize="small" />,
      path: '/pro/services',
      show: isAuthenticated && isProvider,
    },
    {
      label: 'Profile',
      icon: <PersonIcon fontSize="small" />,
      path: isProvider
        ? '/pro/profile'
        : isSeller
        ? '/seller/dashboard/profile'
        : '/dashboard/profile-setup',
      show: isAuthenticated,
    },
    {
      label: 'Settings',
      icon: <SettingsIcon fontSize="small" />,
      path: '/dashboard/settings',
      show: isAuthenticated,
    },
  ];

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
    >
      {menuItems
        .filter((item) => item.show)
        .map((item) => (
          <MenuItem
            key={item.path}
            onClick={() => navigate(item.path)}
            selected={location.pathname === item.path}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  const renderResourcesMenu = (
    <Menu
      anchorEl={resourcesAnchorEl}
      open={Boolean(resourcesAnchorEl)}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
    >
      <MenuItem onClick={() => navigate('/resources')}>
        <ListItemIcon>
          <MenuBookIcon fontSize="small" />
        </ListItemIcon>
        Home Resources
      </MenuItem>
      <MenuItem onClick={() => navigate('/resources/stories')}>
        <ListItemIcon>
          <ArticleIcon fontSize="small" />
        </ListItemIcon>
        Articles and Stories
      </MenuItem>
      <MenuItem onClick={() => navigate('/resources/estimators')}>
        <ListItemIcon>
          <CalculateIcon fontSize="small" />
        </ListItemIcon>
        Cost Estimators
      </MenuItem>
      <MenuItem onClick={() => navigate('/resources/guides')}>
        <ListItemIcon>
          <BookOnlineIcon fontSize="small" />
        </ListItemIcon>
        Guides
      </MenuItem>
      <MenuItem onClick={() => navigate('/services')}>
        <ListItemIcon>
          <BusinessIcon fontSize="small" />
        </ListItemIcon>
        Services
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchor}
      open={Boolean(mobileMenuAnchor)}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
    >
      {/* Navigation items for all users */}
      <MenuItem onClick={() => navigate('/ideas')}>
        <ListItemIcon>
          <InspirationIcon fontSize="small" />
        </ListItemIcon>
        Ideas & Inspiration
      </MenuItem>
      <MenuItem onClick={() => navigate('/find-professionals')}>
        <ListItemIcon>
          <SearchIcon fontSize="small" />
        </ListItemIcon>
        Find Professionals
      </MenuItem>
      <MenuItem onClick={() => navigate('/marketplace')}>
        <ListItemIcon>
          <StoreIcon fontSize="small" />
        </ListItemIcon>
        Marketplace
      </MenuItem>
      <MenuItem onClick={() => navigate('/resources')}>
        <ListItemIcon>
          <ExploreIcon fontSize="small" />
        </ListItemIcon>
        Explore
      </MenuItem>
      <MenuItem onClick={() => navigate('/resources/stories')} sx={{ pl: 4 }}>
        Articles and Stories
      </MenuItem>
      <MenuItem onClick={() => navigate('/resources/estimators')} sx={{ pl: 4 }}>
        Cost Estimators
      </MenuItem>
      <MenuItem onClick={() => navigate('/resources/guides')} sx={{ pl: 4 }}>
        Guides
      </MenuItem>
      <MenuItem onClick={() => navigate('/services')} sx={{ pl: 4 }}>
        Services
      </MenuItem>
      <MenuItem onClick={() => navigate('/marketplace/cart')}>
        <ListItemIcon>
          <Badge badgeContent={cartItems.length} color="error">
            <CartIcon fontSize="small" />
          </Badge>
        </ListItemIcon>
        Cart
      </MenuItem>
      {isAuthenticated ? (
        menuItems
          .filter((item) => item.show)
          .map((item) => (
            <MenuItem
              key={item.path}
              onClick={() => navigate(item.path)}
              selected={location.pathname === item.path}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.label}
            </MenuItem>
          ))
      ) : (
        <>
          <MenuItem onClick={() => navigate('/login')}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            Login
          </MenuItem>
          <MenuItem onClick={() => navigate('/register')}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            Register
          </MenuItem>
        </>
      )}
      {isAuthenticated && (
        <>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <AppBar position="fixed" color="default" elevation={1}>
      <Toolbar>
        {isMobile && (
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMobileMenuOpen}>
            <MenuIcon />
          </IconButton>
        )}

        <Box sx={{ flexGrow: isMobile ? 1 : 0, mr: 3 }}>
          <Logo variant="full" size={isMobile ? 'small' : 'medium'} />
        </Box>

        {!isMobile && (
          <>
            {/* Show these buttons for both unauthenticated users and customers */}
            {(!isAuthenticated || isCustomer) && (
              <>
                <Button
                  color="inherit"
                  startIcon={<StyleIcon />}
                  onClick={() => navigate('/ideas')}
                  sx={{ mr: 2 }}
                >
                  Ideas
                </Button>
                <Button
                  color="inherit"
                  startIcon={<SearchIcon />}
                  onClick={() => navigate('/find-professionals')}
                  sx={{ mr: 2 }}
                >
                  Find Professionals
                </Button>
                <Button
                  color="inherit"
                  startIcon={<StoreIcon />}
                  onClick={() => navigate('/marketplace')}
                  sx={{ mr: 2 }}
                >
                  Shop
                </Button>
              </>
            )}
            {isProvider && (
              <>
                <Button
                  color="inherit"
                  startIcon={<DashboardIcon />}
                  onClick={() => navigate('/pro/dashboard')}
                  sx={{ mr: 2 }}
                >
                  Dashboard
                </Button>
                <Button
                  color="inherit"
                  startIcon={<BusinessIcon />}
                  onClick={() => navigate('/pro/services')}
                  sx={{ mr: 2 }}
                >
                  My Services
                </Button>
              </>
            )}
            {isSeller && (
              <Button
                color="inherit"
                startIcon={<StoreIcon />}
                onClick={() => navigate('/seller/dashboard')}
                sx={{ mr: 2 }}
              >
                Seller Portal
              </Button>
            )}
            {isProvider && (
              <>
                <Button
                  color="inherit"
                  startIcon={<QuoteIcon />}
                  onClick={() => navigate('/pro/leads')}
                  sx={{ mr: 2 }}
                >
                  Lead Requests
                </Button>
                <Button
                  color="inherit"
                  startIcon={<CalendarMonthIcon />}
                  onClick={() => navigate('/pro/calendar')}
                  sx={{ mr: 2 }}
                >
                  Calendar
                </Button>
              </>
            )}
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />

        {/* Resources Button - Visible for all users */}
        <Button
          color="inherit"
          startIcon={<ExploreIcon />}
          onClick={handleResourcesMenuOpen}
          sx={{ mr: 2 }}
          aria-controls="resources-menu"
          aria-haspopup="true"
        >
          Explore
        </Button>

        {/* Cart Icon - Visible for all users */}
        <IconButton color="inherit" sx={{ mr: 2 }} onClick={() => navigate('/marketplace/cart')}>
          <Badge badgeContent={cartItems.length} color="error">
            <CartIcon />
          </Badge>
        </IconButton>

        {!isMobile && !isAuthenticated && (
          <Box>
            <Button color="inherit" onClick={() => navigate('/login')} sx={{ mr: 1 }}>
              Login
            </Button>
            <Button variant="contained" color="primary" onClick={() => navigate('/register')}>
              Register
            </Button>
          </Box>
        )}

        {isAuthenticated && (
          <>
            <IconButton
              color="inherit"
              sx={{ mr: 2 }}
              onClick={() =>
                navigate(
                  isProvider
                    ? '/pro/messages'
                    : isSeller
                    ? '/seller/messages'
                    : '/dashboard/messages'
                )
              }
            >
              <Badge badgeContent={0} color="error">
                <MessageIcon />
              </Badge>
            </IconButton>

            {/* Replace the notification icon with NotificationDropdown */}
            <NotificationDropdown color="primary" />

            <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
              <Avatar src={user?.avatar} sx={{ width: 32, height: 32 }}>
                {user?.name?.[0]?.toUpperCase()}
              </Avatar>
            </IconButton>
          </>
        )}
      </Toolbar>
      {renderMenu}
      {renderMobileMenu}
      {renderResourcesMenu}
    </AppBar>
  );
};

export default Header;
