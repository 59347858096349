import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Container,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Breadcrumbs,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';
import { Lead, ProviderResponse, SubmitResponseDTO } from '../../types/lead';
import LeadResponseForm from '../../components/pro/ui/LeadResponseForm';
import axios from '../../services/axios';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HistoryIcon from '@mui/icons-material/History';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lead-tabpanel-${index}`}
      aria-labelledby={`lead-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `lead-tab-${index}`,
    'aria-controls': `lead-tabpanel-${index}`,
  };
};

interface StatusHistoryItem {
  status: string;
  date: string;
  note?: string;
}

const LeadDetailPage: React.FC = () => {
  const { leadId } = useParams<{ leadId: string }>();
  const [lead, setLead] = useState<Lead | null>(null);
  const [responses, setResponses] = useState<ProviderResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showResponseForm, setShowResponseForm] = useState(false);
  const [hasResponded, setHasResponded] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusNote, setStatusNote] = useState('');
  const [newStatus, setNewStatus] = useState<string>('');
  const [statusHistory, setStatusHistory] = useState<StatusHistoryItem[]>([]);
  const [leadCost, setLeadCost] = useState<number | null>(null);
  const { getAuthToken, user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLeadDetails = async () => {
      try {
        setLoading(true);
        const token = getAuthToken();

        if (!token) {
          setError('Authentication required');
          setLoading(false);
          return;
        }

        // Fetch lead details
        const leadResponse = await axios.get(`/leads/provider/${leadId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setLead(leadResponse.data);

        // Fetch responses for this lead
        const responsesResponse = await axios.get(`/leads/responses/${leadId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setResponses(responsesResponse.data);

        // Check if the current provider has already responded
        if (
          user &&
          responsesResponse.data.some(
            (response: ProviderResponse) => response.providerId === user._id
          )
        ) {
          setHasResponded(true);
        }

        // Fetch status history (mock data for now)
        const mockStatusHistory: StatusHistoryItem[] = [
          {
            status: 'pending',
            date: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString(),
            note: 'Lead created',
          },
          {
            status: 'matched',
            date: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000).toISOString(),
            note: 'Matched with your services',
          },
        ];

        // Add current status if different from the last one in history
        if (leadResponse.data.status !== mockStatusHistory[mockStatusHistory.length - 1].status) {
          mockStatusHistory.push({
            status: leadResponse.data.status,
            date: new Date().toISOString(),
          });
        }

        setStatusHistory(mockStatusHistory);

        // Set lead cost (mock data for now)
        setLeadCost(15.75);

        setError(null);
      } catch (err) {
        console.error('Error fetching lead details:', err);
        setError('Failed to load lead details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (leadId) {
      fetchLeadDetails();
    }
  }, [leadId, getAuthToken, user]);

  const handleSubmitResponse = async (values: SubmitResponseDTO) => {
    try {
      const token = getAuthToken();

      if (!token || !leadId) {
        setError('Authentication required');
        return;
      }

      await axios.post(`/leads/response/${leadId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Update UI to show the provider has responded
      setHasResponded(true);
      setShowResponseForm(false);

      // Refresh responses
      const responsesResponse = await axios.get(`/leads/responses/${leadId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setResponses(responsesResponse.data);

      // Update status history
      setStatusHistory([
        ...statusHistory,
        {
          status: 'responded',
          date: new Date().toISOString(),
          note: 'You responded to this lead',
        },
      ]);

      // Update lead status if needed
      if (lead && lead.status === 'matched' || lead?.status === 'pending') {
        setLead({
          ...lead,
          status: 'responded',
        });
      }
    } catch (err) {
      console.error('Error submitting response:', err);
      setError('Failed to submit your response. Please try again later.');
    }
  };

  const handleUpdateStatus = async () => {
    try {
      const token = getAuthToken();

      if (!token || !leadId || !newStatus) {
        setError('Authentication required or invalid status');
        return;
      }

      // In a real implementation, this would be an API call
      // For this example, we'll update the state directly
      if (lead) {
        setLead({
          ...lead,
          status: newStatus as any,
        });

        // Update status history
        setStatusHistory([
          ...statusHistory,
          {
            status: newStatus,
            date: new Date().toISOString(),
            note: statusNote || undefined,
          },
        ]);
      }

      setStatusDialogOpen(false);
      setStatusNote('');
      setNewStatus('');
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status. Please try again later.');
    }
  };

  const getStatusChip = (status: string) => {
    let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' =
      'default';
    let icon = null;

    switch (status) {
      case 'pending':
        color = 'warning';
        icon = <AccessTimeIcon fontSize="small" />;
        break;
      case 'matched':
        color = 'info';
        icon = <CheckCircleIcon fontSize="small" />;
        break;
      case 'responded':
        color = 'primary';
        icon = <MessageIcon fontSize="small" />;
        break;
      case 'accepted':
        color = 'success';
        icon = <CheckCircleIcon fontSize="small" />;
        break;
      case 'declined':
        color = 'error';
        icon = <CancelIcon fontSize="small" />;
        break;
      case 'completed':
        color = 'secondary';
        icon = <CheckCircleIcon fontSize="small" />;
        break;
    }

    return (
      <Chip
        icon={icon ? icon : undefined}
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        color={color}
        size="small"
        sx={{ fontWeight: 'medium' }}
      />
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getActiveStep = () => {
    switch (lead?.status) {
      case 'pending':
        return 0;
      case 'matched':
        return 1;
      case 'responded':
        return 2;
      case 'accepted':
        return 3;
      case 'completed':
        return 4;
      case 'declined':
        return -1; // Special case for declined
      default:
        return 0;
    }
  };

  const openStatusDialog = (status: string) => {
    setNewStatus(status);
    setStatusDialogOpen(true);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Alert>
        <Button variant="outlined" onClick={() => navigate('/pro/leads')}>
          Back to Lead Requests
        </Button>
      </Container>
    );
  }

  if (!lead) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          Lead not found
        </Alert>
        <Button variant="outlined" onClick={() => navigate('/pro/leads')}>
          Back to Lead Requests
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Link component={RouterLink} to="/pro/dashboard" color="inherit">
          Dashboard
        </Link>
        <Link component={RouterLink} to="/pro/leads" color="inherit">
          Leads
        </Link>
        <Typography color="text.primary">Lead Details</Typography>
      </Breadcrumbs>

      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => navigate('/pro/leads')}
            aria-label="back to leads"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" component="h1">
            Lead Request Details
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, mt: { xs: 2, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}>
          {leadCost !== null && (
            <Chip
              icon={<AttachMoneyIcon />}
              label={`Lead Cost: $${leadCost.toFixed(2)}`}
              color="primary"
              variant="outlined"
            />
          )}
          <Chip
            icon={<HistoryIcon />}
            label="View History"
            variant="outlined"
            onClick={() => setTabValue(2)}
          />
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Status</Typography>
              {getStatusChip(lead.status)}
            </Box>

            <Stepper activeStep={getActiveStep()} orientation="vertical">
              <Step>
                <StepLabel>Pending</StepLabel>
                <StepContent>
                  <Typography variant="body2">
                    Lead is pending matching with service providers.
                  </Typography>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Matched</StepLabel>
                <StepContent>
                  <Typography variant="body2">
                    Lead has been matched with your services.
                  </Typography>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Responded</StepLabel>
                <StepContent>
                  <Typography variant="body2">
                    You have responded to this lead.
                  </Typography>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Accepted</StepLabel>
                <StepContent>
                  <Typography variant="body2">
                    Customer has accepted your proposal.
                  </Typography>
                  {lead.status === 'accepted' && (
                    <Button
                      size="small"
                      onClick={() => openStatusDialog('completed')}
                      sx={{ mt: 1 }}
                    >
                      Mark as Completed
                    </Button>
                  )}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Completed</StepLabel>
                <StepContent>
                  <Typography variant="body2">
                    This job has been completed.
                  </Typography>
                </StepContent>
              </Step>
            </Stepper>

            {lead.status !== 'declined' && lead.status !== 'completed' && (
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => openStatusDialog('declined')}
                  startIcon={<CancelIcon />}
                >
                  Decline Lead
                </Button>
              </Box>
            )}
          </Paper>

          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Customer Information
            </Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Customer"
                  secondary={typeof lead.customer === 'object' ? lead.customer.name : 'Customer'}
                />
              </ListItem>

              {lead.guestInfo && (
                <>
                  <ListItem>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Email" secondary={lead.guestInfo.email} />
                  </ListItem>

                  {lead.guestInfo.phone && (
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Phone" secondary={lead.guestInfo.phone} />
                    </ListItem>
                  )}
                </>
              )}

              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Location" secondary={lead.details.location.address} />
              </ListItem>

              {lead.details.budget && (
                <ListItem>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Budget"
                    secondary={`$${lead.details.budget.min} - $${lead.details.budget.max}`}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper sx={{ mb: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="lead tabs"
                variant={isMobile ? "fullWidth" : "standard"}
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Responses" {...a11yProps(1)} />
                <Tab label="History" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {typeof lead.service === 'object' ? lead.service.name : 'Service Request'}
                </Typography>
                <Typography variant="body1" paragraph>
                  {lead.details.description}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                  Preferred Dates
                </Typography>
                {lead.details.preferredDates && lead.details.preferredDates.length > 0 ? (
                  <Grid container spacing={2}>
                    {lead.details.preferredDates.map((date, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Chip
                          icon={<CalendarTodayIcon />}
                          label={format(new Date(date), 'PPP')}
                          variant="outlined"
                          sx={{ width: '100%', justifyContent: 'flex-start' }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="body2">No preferred dates specified</Typography>
                )}

                {lead.customerPreferences && lead.customerPreferences.length > 0 && (
                  <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" gutterBottom>
                      Customer Preferences
                    </Typography>
                    <List dense>
                      {lead.customerPreferences.map((pref, index) => (
                        <ListItem key={index} sx={{ py: 0 }}>
                          <ListItemText
                            primary={`Question ID: ${pref.questionId}`}
                            secondary={`Selected options: ${pref.selectedOptions.join(', ')}${
                              pref.customValue ? ` (Custom: ${pref.customValue})` : ''
                            }`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}

                <Divider sx={{ my: 2 }} />

                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                  {hasResponded ? (
                    <Alert severity="success" sx={{ width: '100%' }}>
                      You have already responded to this lead request.
                    </Alert>
                  ) : showResponseForm ? (
                    <Box sx={{ width: '100%' }}>
                      <LeadResponseForm
                        leadId={leadId || ''}
                        onSubmit={handleSubmitResponse}
                        onCancel={() => setShowResponseForm(false)}
                        leadDetails={{
                          service: typeof lead.service === 'object' ? lead.service.name : 'Service Request',
                          budget: lead.details.budget,
                        }}
                      />
                    </Box>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowResponseForm(true)}
                      disabled={hasResponded}
                    >
                      Respond to this Request
                    </Button>
                  )}
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Responses
                </Typography>

                {responses.length === 0 ? (
                  <Alert severity="info">
                    No responses yet. Be the first to respond to this lead!
                  </Alert>
                ) : (
                  <Grid container spacing={2}>
                    {responses.map((response) => (
                      <Grid item xs={12} key={response._id}>
                        <Card variant="outlined">
                          <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                              <Avatar sx={{ mr: 2 }}>
                                {response.providerData?.businessName?.charAt(0) || 'P'}
                              </Avatar>
                              <Box>
                                <Typography variant="subtitle1" gutterBottom>
                                  {response.providerData
                                    ? response.providerData.businessName || response.providerData.name
                                    : 'Provider'}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                  Submitted: {format(new Date(response.createdAt), 'PPP')}
                                </Typography>
                              </Box>
                              <Chip
                                label={response.status.charAt(0).toUpperCase() + response.status.slice(1)}
                                size="small"
                                color={response.status === 'accepted' ? 'success' : 'default'}
                                sx={{ ml: 'auto' }}
                              />
                            </Box>

                            <Divider sx={{ my: 1 }} />

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Price: ${response.details.price}
                                </Typography>
                                <Typography variant="body2">
                                  Duration: {response.details.estimatedDuration}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Available Dates:
                                </Typography>
                                <List dense>
                                  {response.details.availableDates.map((date, index) => (
                                    <ListItem key={index} sx={{ py: 0 }}>
                                      <ListItemText primary={format(new Date(date), 'PPP')} />
                                    </ListItem>
                                  ))}
                                </List>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Description:
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  {response.details.description}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Terms:
                                </Typography>
                                <Typography variant="body2">{response.details.terms}</Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Status History
                </Typography>

                <List>
                  {statusHistory.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {getStatusChip(item.status)}
                              <Typography variant="body2" sx={{ ml: 2 }}>
                                {format(new Date(item.date), 'PPP p')}
                              </Typography>
                            </Box>
                          }
                          secondary={item.note}
                        />
                      </ListItem>
                      {index < statusHistory.length - 1 && <Divider component="li" />}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>

      {/* Status Update Dialog */}
      <Dialog open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
        <DialogTitle>
          {newStatus === 'declined' ? 'Decline Lead' : 'Update Status'}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            {newStatus === 'declined'
              ? 'Are you sure you want to decline this lead? This action cannot be undone.'
              : `Are you sure you want to mark this lead as ${newStatus}?`}
          </Typography>
          <TextField
            fullWidth
            label="Add a note (optional)"
            multiline
            rows={3}
            value={statusNote}
            onChange={(e) => setStatusNote(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleUpdateStatus}
            color={newStatus === 'declined' ? 'error' : 'primary'}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LeadDetailPage;
