import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  Divider,
  Alert,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  CardActions,
  Chip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse, isAfter, isBefore, addDays, startOfDay } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useCalendar } from '../../../contexts/CalendarContext';
import { DateException, TimeSlot } from '../../../types/calendar';

interface ExceptionFormValues {
  date: Date;
  available: boolean;
  timeSlots: TimeSlot[];
  reason: string;
}

const DEFAULT_TIME_SLOT: TimeSlot = { start: '09:00', end: '17:00' };

const DateExceptionsManager: React.FC = () => {
  const { exceptions, loading, error, fetchExceptions, createException, updateException, deleteException } = useCalendar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingException, setEditingException] = useState<DateException | null>(null);
  const [formValues, setFormValues] = useState<ExceptionFormValues>({
    date: new Date(),
    available: false,
    timeSlots: [DEFAULT_TIME_SLOT],
    reason: '',
  });
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    fetchExceptions();
  }, [fetchExceptions]);

  const handleOpenDialog = (exception?: DateException) => {
    if (exception) {
      setEditingException(exception);
      setFormValues({
        date: new Date(exception.date),
        available: exception.available,
        timeSlots: [...exception.timeSlots],
        reason: exception.reason || '',
      });
    } else {
      setEditingException(null);
      setFormValues({
        date: new Date(),
        available: false,
        timeSlots: [DEFAULT_TIME_SLOT],
        reason: '',
      });
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((prev) => ({ ...prev, date }));
    }
  };

  const handleAvailabilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const available = event.target.checked;
    setFormValues((prev) => ({
      ...prev,
      available,
      timeSlots: available ? (prev.timeSlots.length ? prev.timeSlots : [DEFAULT_TIME_SLOT]) : [],
    }));
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prev) => ({ ...prev, reason: event.target.value }));
  };

  const handleAddTimeSlot = () => {
    setFormValues((prev) => {
      const lastSlot = prev.timeSlots[prev.timeSlots.length - 1];
      const startTime = lastSlot.end;
      const endTime = format(
        addDays(parse(startTime, 'HH:mm', new Date()), 1),
        'HH:mm'
      );

      return {
        ...prev,
        timeSlots: [...prev.timeSlots, { start: startTime, end: endTime }],
      };
    });
  };

  const handleRemoveTimeSlot = (index: number) => {
    setFormValues((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.filter((_, i) => i !== index),
    }));
  };

  const handleTimeChange = (
    index: number,
    field: 'start' | 'end',
    date: Date | null
  ) => {
    if (date) {
      const timeString = format(date, 'HH:mm');
      setFormValues((prev) => ({
        ...prev,
        timeSlots: prev.timeSlots.map((slot, i) =>
          i === index ? { ...slot, [field]: timeString } : slot
        ),
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      const exceptionData = {
        date: format(formValues.date, 'yyyy-MM-dd'),
        available: formValues.available,
        timeSlots: formValues.timeSlots,
        reason: formValues.reason,
      };

      if (editingException) {
        await updateException(editingException._id, exceptionData);
      } else {
        await createException(exceptionData);
      }

      handleCloseDialog();
    } catch (err) {
      console.error('Error saving date exception:', err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (exceptionId: string) => {
    try {
      await deleteException(exceptionId);
    } catch (err) {
      console.error('Error deleting date exception:', err);
    }
  };

  const sortedExceptions = [...(exceptions || [])].sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const futureExceptions = sortedExceptions.filter(exception =>
    isAfter(new Date(exception.date), startOfDay(new Date()))
  );

  const pastExceptions = sortedExceptions.filter(exception =>
    isBefore(new Date(exception.date), startOfDay(new Date()))
  );

  if (loading && exceptions.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Date Exceptions</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add Exception
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Typography variant="body2" color="text.secondary" paragraph>
        Set exceptions to your regular working hours for specific dates, such as holidays or special events.
      </Typography>

      <Divider sx={{ mb: 3 }} />

      {futureExceptions.length === 0 && pastExceptions.length === 0 ? (
        <Alert severity="info">
          You don't have any date exceptions yet. Click "Add Exception" to create one.
        </Alert>
      ) : (
        <>
          {futureExceptions.length > 0 && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Upcoming Exceptions
              </Typography>
              <Grid container spacing={2} sx={{ mb: 4 }}>
                {futureExceptions.map((exception) => (
                  <Grid item xs={12} sm={6} md={4} key={exception._id}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle1">
                            {format(new Date(exception.date), 'MMMM d, yyyy')}
                          </Typography>
                          <Chip
                            icon={exception.available ? <EventAvailableIcon /> : <EventBusyIcon />}
                            label={exception.available ? 'Available' : 'Unavailable'}
                            color={exception.available ? 'success' : 'error'}
                            size="small"
                          />
                        </Box>

                        {exception.reason && (
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                            {exception.reason}
                          </Typography>
                        )}

                        {exception.available && exception.timeSlots.length > 0 && (
                          <Box sx={{ mt: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                              Available Times:
                            </Typography>
                            {exception.timeSlots.map((slot, index) => (
                              <Typography key={index} variant="body2">
                                {slot.start} - {slot.end}
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          startIcon={<EditIcon />}
                          onClick={() => handleOpenDialog(exception)}
                        >
                          Edit
                        </Button>
                        <Button
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDelete(exception._id)}
                        >
                          Delete
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {pastExceptions.length > 0 && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Past Exceptions
              </Typography>
              <Grid container spacing={2}>
                {pastExceptions.map((exception) => (
                  <Grid item xs={12} sm={6} md={4} key={exception._id}>
                    <Card variant="outlined" sx={{ opacity: 0.7 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle1">
                            {format(new Date(exception.date), 'MMMM d, yyyy')}
                          </Typography>
                          <Chip
                            icon={exception.available ? <EventAvailableIcon /> : <EventBusyIcon />}
                            label={exception.available ? 'Available' : 'Unavailable'}
                            color={exception.available ? 'success' : 'error'}
                            size="small"
                          />
                        </Box>

                        {exception.reason && (
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                            {exception.reason}
                          </Typography>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDelete(exception._id)}
                        >
                          Delete
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      )}

      {/* Exception Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingException ? 'Edit Date Exception' : 'Add Date Exception'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={formValues.date}
                  onChange={handleDateChange}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.available}
                    onChange={handleAvailabilityChange}
                    color="primary"
                  />
                }
                label={formValues.available ? 'Available' : 'Unavailable'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Reason (optional)"
                value={formValues.reason}
                onChange={handleReasonChange}
                placeholder="e.g., Holiday, Vacation, Special Event"
              />
            </Grid>

            {formValues.available && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Available Time Slots
                </Typography>
                {formValues.timeSlots.map((slot, index) => (
                  <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                    <Grid item xs={5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Start Time"
                          value={parse(slot.start, 'HH:mm', new Date())}
                          onChange={(date) => handleTimeChange(index, 'start', date)}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="End Time"
                          value={parse(slot.end, 'HH:mm', new Date())}
                          onChange={(date) => handleTimeChange(index, 'end', date)}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveTimeSlot(index)}
                        disabled={formValues.timeSlots.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddTimeSlot}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  Add Time Slot
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={submitting}
            startIcon={submitting ? <CircularProgress size={20} /> : null}
          >
            {submitting ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DateExceptionsManager;