import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Box,
  CardActionArea,
  CardActions,
  Tooltip,
} from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';

export interface ProjectCardProps {
  id: string;
  image: string;
  title: string;
  designer: string;
  likes: number;
  isSaved?: boolean;
  onSave?: () => void;
  onShare?: () => void;
  onClick?: () => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  id,
  image,
  title,
  designer,
  likes,
  isSaved = false,
  onSave,
  onShare,
  onClick,
}) => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
        },
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia
          component="img"
          height="200"
          image={image}
          alt={title}
          sx={{
            transition: 'transform 0.5s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />
        <CardContent>
          <Typography variant="h6" component="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            By {designer}
          </Typography>
          <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
            {likes} likes
          </Typography>
        </CardContent>
      </CardActionArea>
      <Box sx={{ mt: 'auto' }}>
        <CardActions disableSpacing>
          <Tooltip title={isSaved ? "Saved to ideabook" : "Save to ideabook"}>
            <IconButton
              aria-label="save to ideabook"
              onClick={(e) => {
                e.stopPropagation();
                if (onSave) onSave();
              }}
              color={isSaved ? "primary" : "default"}
            >
              {isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Share">
            <IconButton
              aria-label="share"
              onClick={(e) => {
                e.stopPropagation();
                if (onShare) onShare();
              }}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Box>
    </Card>
  );
};

export default ProjectCard;