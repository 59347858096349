import React from 'react';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
} from '@mui/material';
import ServiceCard from './ServiceCard';
import { IService } from '../../types/models';
import { SearchResultsProps } from '../../types/search';

const SearchResults: React.FC<SearchResultsProps> = ({
  services = [], // Provide default empty array
  loading = false,
  error,
}) => {
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!services || services.length === 0) {
    return (
      <Paper sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          No services found
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Try adjusting your search criteria or browse our categories
        </Typography>
      </Paper>
    );
  }

  return (
    <Grid container spacing={3}>
      {services.map((service: IService) => (
        <Grid item xs={12} sm={6} md={4} key={service._id}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SearchResults;
