import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProtectedRoute from '../common/ProtectedRoute';
import { RootState } from '../../redux/store';

interface AdminRouteProps {
  children: React.ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);

  // First check if user is authenticated using ProtectedRoute
  return (
    <ProtectedRoute>
      {/* Then check if authenticated user is an admin */}
      {user?.role === 'admin' ? (
        children
      ) : (
        <Navigate
          to="/login"
          state={{ from: location }}
          replace
        />
      )}
    </ProtectedRoute>
  );
};

export default AdminRoute;