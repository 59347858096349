import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Alert,
  Divider,
  Paper,
  ImageList,
  ImageListItem,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  BookmarkBorder as BookmarkBorderIcon,
  Share as ShareIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';
import { ideaService, IdeaProject } from '../../services/ideaService';

const ProviderProjectDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [project, setProject] = useState<IdeaProject | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProject = async () => {
      if (!id) return;

      setLoading(true);
      setError(null);

      try {
        const projectData = await ideaService.getProviderProjectById(id);
        setProject(projectData);
      } catch (err) {
        console.error('Error fetching project:', err);
        setError('Failed to load project details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  const handleSaveProject = () => {
    // TODO: Implement save to ideabook functionality
    console.log('Save project:', id);
  };

  const handleShareProject = () => {
    // TODO: Implement share functionality
    console.log('Share project:', id);
  };

  const handleViewProvider = () => {
    if (project?.providerId) {
      navigate(`/pro/${project.providerId}`);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <IdeasNavigation activeSection="provider-projects" />
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !project) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <IdeasNavigation activeSection="provider-projects" />
        <Alert severity="error" sx={{ mb: 4 }}>
          {error || 'Project not found'}
        </Alert>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mt: 2 }}>
          Back to Projects
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="provider-projects" />

      <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 3 }}>
        Back to Projects
      </Button>

      <Grid container spacing={4}>
        {/* Main content */}
        <Grid item xs={12} md={8}>
          {/* Main image */}
          <Card sx={{ mb: 3, borderRadius: 2, overflow: 'hidden' }}>
            <CardMedia
              component="img"
              height="500"
              image={project.image}
              alt={project.title}
              sx={{ objectFit: 'cover' }}
            />
          </Card>

          {/* Image gallery */}
          {project.images && project.images.length > 1 && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Project Gallery
              </Typography>
              <ImageList cols={3} gap={16}>
                {project.images.map((image, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={image}
                      alt={`${project.title} - Image ${index + 1}`}
                      loading="lazy"
                      style={{ borderRadius: 8, height: '100%', objectFit: 'cover' }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          )}

          {/* Project description */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Project Description
            </Typography>
            <Typography variant="body1" paragraph>
              {project.description}
            </Typography>
          </Box>
        </Grid>

        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
            {/* Project title and actions */}
            <Typography variant="h4" component="h1" gutterBottom>
              {project.title}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1" color="text.secondary">
                By {project.designer}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
              <Button
                variant="outlined"
                startIcon={<BookmarkBorderIcon />}
                onClick={handleSaveProject}
              >
                Save
              </Button>
              <Button variant="outlined" startIcon={<ShareIcon />} onClick={handleShareProject}>
                Share
              </Button>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Project details */}
            <Typography variant="h6" gutterBottom>
              Project Details
            </Typography>

            <Box sx={{ mb: 3 }}>
              {project.details?.roomType && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Room Type:
                  </Typography>
                  <Typography variant="body2">
                    {project.details.roomType === 'kitchen'
                      ? 'Kitchen'
                      : project.details.roomType === 'bathroom'
                      ? 'Bathroom'
                      : project.details.roomType === 'living-room'
                      ? 'Living Room'
                      : project.details.roomType === 'bedroom'
                      ? 'Bedroom'
                      : project.details.roomType === 'outdoor'
                      ? 'Outdoor'
                      : project.details.roomType === 'home-office'
                      ? 'Home Office'
                      : project.details.roomType}
                  </Typography>
                </Box>
              )}

              {project.details?.style && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Style:
                  </Typography>
                  <Typography variant="body2">{project.details.style}</Typography>
                </Box>
              )}

              {project.details?.year && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Year:
                  </Typography>
                  <Typography variant="body2">{project.details.year}</Typography>
                </Box>
              )}

              {project.details?.cost && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Cost Range:
                  </Typography>
                  <Typography variant="body2">{project.details.cost}</Typography>
                </Box>
              )}

              {project.details?.building && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Building/Community:
                  </Typography>
                  <Typography variant="body2">{project.details.building}</Typography>
                </Box>
              )}
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Provider info */}
            <Typography variant="h6" gutterBottom>
              Service Provider
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" gutterBottom>
                {project.designer}
              </Typography>
              <Button
                variant="contained"
                startIcon={<PersonIcon />}
                onClick={handleViewProvider}
                fullWidth
                sx={{ mt: 1 }}
              >
                View Provider Profile
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProviderProjectDetailPage;
