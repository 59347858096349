import axios from './axios';
import {
  Notification,
  NotificationPreferences,
  NotificationFilters,
  NotificationCountResponse,
  NotificationListResponse,
  UpdateNotificationRequest,
  UpdateNotificationPreferencesRequest,
} from '../types/notification';

/**
 * Service for handling notification-related API calls
 */
class NotificationService {
  /**
   * Get all notifications for the current user
   * @param page Page number for pagination
   * @param limit Number of items per page
   * @param filters Optional filters for notifications
   * @returns Promise with notification list response
   */
  async getNotifications(
    page = 1,
    limit = 10,
    filters?: NotificationFilters
  ): Promise<NotificationListResponse> {
    const params = {
      page,
      limit,
      ...filters,
    };

    const response = await axios.get('/notifications', { params });
    return response.data;
  }

  /**
   * Get notification counts for the current user
   * @returns Promise with notification count response
   */
  async getNotificationCounts(): Promise<NotificationCountResponse> {
    const response = await axios.get('/notifications/count');
    return response.data;
  }

  /**
   * Get a single notification by ID
   * @param notificationId ID of the notification to retrieve
   * @returns Promise with notification
   */
  async getNotification(notificationId: string): Promise<Notification> {
    const response = await axios.get(`/notifications/${notificationId}`);
    return response.data;
  }

  /**
   * Update a notification (e.g., mark as read)
   * @param notificationId ID of the notification to update
   * @param updateData Data to update
   * @returns Promise with updated notification
   */
  async updateNotification(
    notificationId: string,
    updateData: UpdateNotificationRequest
  ): Promise<Notification> {
    const response = await axios.patch(`/notifications/${notificationId}`, updateData);
    return response.data;
  }

  /**
   * Mark all notifications as read
   * @returns Promise with success message
   */
  async markAllAsRead(): Promise<{ success: boolean; message: string }> {
    const response = await axios.post('/notifications/mark-all-read');
    return response.data;
  }

  /**
   * Delete a notification
   * @param notificationId ID of the notification to delete
   * @returns Promise with success message
   */
  async deleteNotification(notificationId: string): Promise<{ success: boolean; message: string }> {
    const response = await axios.delete(`/notifications/${notificationId}`);
    return response.data;
  }

  /**
   * Get notification preferences for the current user
   * @returns Promise with notification preferences
   */
  async getNotificationPreferences(): Promise<NotificationPreferences> {
    const response = await axios.get('/notifications/preferences');
    return response.data;
  }

  /**
   * Update notification preferences for the current user
   * @param preferences Updated preferences
   * @returns Promise with updated notification preferences
   */
  async updateNotificationPreferences(
    preferences: UpdateNotificationPreferencesRequest
  ): Promise<NotificationPreferences> {
    const response = await axios.patch('/notifications/preferences', preferences);
    return response.data;
  }
}

export default new NotificationService();