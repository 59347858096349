import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useSellerOnboarding } from '../../../contexts/SellerOnboardingContext';
import { CircularProgress, Box } from '@mui/material';

interface SellerOnboardingCheckProps {
  children: React.ReactNode;
}

const SellerOnboardingCheck: React.FC<SellerOnboardingCheckProps> = ({ children }) => {
  const { user } = useAuth();
  const { onboardingStatus, isLoading, fetchOnboardingStatus } = useSellerOnboarding();
  const location = useLocation();

  // Skip onboarding check if we're already in the onboarding flow
  const isOnboardingPath = location.pathname.includes('/seller/onboarding');

  useEffect(() => {
    // Only fetch onboarding status if we're not already in the onboarding flow
    // and the user is a seller
    if (user && user.role === 'seller' && !isOnboardingPath) {
      fetchOnboardingStatus();
    }
  }, [user, isOnboardingPath, fetchOnboardingStatus]);

  // If we're already in the onboarding flow, render children immediately
  if (isOnboardingPath) {
    return <>{children}</>;
  }

  // Show loading state only if we're not in the onboarding flow
  if (isLoading && !isOnboardingPath) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Removed duplicate check for isOnboardingPath

  // If onboarding is not complete and we're not already in the onboarding flow, redirect to onboarding
  if (onboardingStatus && !onboardingStatus.completed && !isOnboardingPath) {
    // Make sure we have a valid step number before redirecting
    const stepToRedirect = onboardingStatus &&
                          onboardingStatus.step !== undefined &&
                          !isNaN(onboardingStatus.step)
                            ? onboardingStatus.step
                            : 0; // Default to step 0 if step is undefined or not a number

    return <Navigate to={`/seller/onboarding/${stepToRedirect}`} replace />;
  }

  // Note: We've removed the verification check since it's not in the OnboardingStatus type
  // If verification status is needed, it should be added to the OnboardingStatus type in SellerOnboardingContext

  // If onboarding is complete and verified, render children
  return <>{children}</>;
};

export default SellerOnboardingCheck;
