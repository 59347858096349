import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';

// Validation schema
const businessProfileSchema = yup.object().shape({
  businessName: yup.string().required('Business name is required'),
  description: yup.string().required('Business description is required'),
  phone: yup.string().required('Phone number is required'),
  logo: yup.string().url('Must be a valid URL').optional(),
  yearEstablished: yup.number().positive('Must be a positive number').integer('Must be a whole number').optional(),
});

const BusinessProfileStep: React.FC = () => {
  const { businessProfile, setBusinessProfile, saveStep, isLoading, error } = useSellerOnboarding();

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(businessProfileSchema),
    defaultValues: {
      businessName: businessProfile?.businessName || '',
      description: businessProfile?.description || '',
      phone: businessProfile?.phone || '',
      logo: businessProfile?.logo || '',
      yearEstablished: businessProfile?.yearEstablished || undefined,
    },
  });

  const onSubmit = async (data: any) => {
    setBusinessProfile(data);
    await saveStep(0, data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Business Profile
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Tell us about your business
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="businessName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Business Name"
                fullWidth
                error={!!errors.businessName}
                helperText={errors.businessName?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Business Description"
                fullWidth
                multiline
                rows={4}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Business Phone"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="yearEstablished"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Year Established"
                type="number"
                fullWidth
                error={!!errors.yearEstablished}
                helperText={errors.yearEstablished?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="logo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Logo URL (Optional)"
                fullWidth
                error={!!errors.logo}
                helperText={errors.logo?.message || "Enter a URL to your business logo"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save & Continue'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default BusinessProfileStep;