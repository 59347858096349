import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';
import authService from '../../services/authService';
import analytics from '../../utils/analytics';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  padding: theme.spacing(1, 3),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
}));

interface GoogleLoginButtonProps {
  onStart?: () => void;
  onError?: (error: Error) => void;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  onStart,
  onError,
}) => {
  const handleClick = async () => {
    try {
      onStart?.();
      // Track Google login attempt
      analytics.trackEvent({
        category: 'User',
        action: 'Login Attempt',
        label: 'Google',
      });
      authService.initiateGoogleAuth();
    } catch (error) {
      onError?.(error instanceof Error ? error : new Error('Failed to initiate Google login'));
    }
  };

  return (
    <StyledButton
      variant="outlined"
      onClick={handleClick}
      startIcon={<GoogleIcon />}
      data-testid="google-login-button"
    >
      Continue with Google
    </StyledButton>
  );
};

export default GoogleLoginButton;