import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';

// Validation schema
const documentsSchema = yup.object().shape({
  'tradeLicense.number': yup.string().required('Trade license number is required'),
  'tradeLicense.expiryDate': yup.string().required('Expiry date is required'),
  'tradeLicense.document': yup.string().url('Must be a valid URL').required('Trade license document is required'),
  'vatNumber': yup.string().required('VAT number is required'),
});

const DocumentsStep: React.FC = () => {
  const { documents, setDocuments, saveStep, completeOnboarding, isLoading, goToStep } = useSellerOnboarding();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(documentsSchema) as any,
    defaultValues: {
      'tradeLicense.number': documents?.tradeLicense?.number || '',
      'tradeLicense.expiryDate': documents?.tradeLicense?.expiryDate || '',
      'tradeLicense.document': documents?.tradeLicense?.document || '',
      'vatNumber': documents?.vatNumber || '',
    },
  });

  const onSubmit = async (data: any) => {
    setError(null);
    setSubmitting(true);

    try {
      const formattedData = {
        tradeLicense: {
          number: data['tradeLicense.number'],
          expiryDate: data['tradeLicense.expiryDate'],
          document: data['tradeLicense.document'],
        },
        vatNumber: data['vatNumber'],
      };

      setDocuments(formattedData);

      // Save documents
      const saveResult = await saveStep(6, formattedData);

      if (saveResult) {
        // Complete onboarding
        const completeResult = await completeOnboarding();

        if (completeResult) {
          // Onboarding completed successfully
          // The user will be redirected to the dashboard by the onboarding context
        } else {
          setError('Failed to complete onboarding. Please try again.');
        }
      } else {
        setError('Failed to save documents. Please try again.');
      }
    } catch (err: any) {
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    goToStep(5);
  };

  // In a real implementation, this would handle file uploads to a server
  // and return a URL. For this example, we'll simulate it.
  const handleFileUpload = () => {
    // Simulate file upload and return a URL
    return 'https://example.com/documents/trade-license.pdf';
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Business Documents
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Provide your business documents for verification
          </Typography>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Trade License Information
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="tradeLicense.number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Trade License Number"
                      fullWidth
                      error={!!errors['tradeLicense.number']}
                      helperText={errors['tradeLicense.number']?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="tradeLicense.expiryDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Expiry Date"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors['tradeLicense.expiryDate']}
                      helperText={errors['tradeLicense.expiryDate']?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="tradeLicense.document"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Trade License Document"
                      fullWidth
                      error={!!errors['tradeLicense.document']}
                      helperText={errors['tradeLicense.document']?.message || "Upload or provide a URL to your trade license document"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                const url = handleFileUpload();
                                field.onChange(url);
                              }}
                            >
                              <CloudUploadIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="vatNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="VAT Number"
                fullWidth
                error={!!errors.vatNumber}
                helperText={errors.vatNumber?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Verification Process
            </Typography>
            <Typography variant="body2" paragraph>
              After submitting your documents:
            </Typography>

            <Box component="ol" sx={{ pl: 2 }}>
              <Box component="li" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Our team will review your documents within 1-2 business days
                </Typography>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  You'll receive an email notification once your account is verified
                </Typography>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  You can start adding products to your store immediately, but they won't be visible to customers until verification is complete
                </Typography>
              </Box>
              <Box component="li">
                <Typography variant="body2">
                  If there are any issues with your documents, we'll contact you for additional information
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading || submitting}
            >
              {isLoading || submitting ? <CircularProgress size={24} /> : 'Complete Registration'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default DocumentsStep;