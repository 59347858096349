/**
 * ImageLoader utility for optimized image loading
 * Features:
 * - Lazy loading
 * - Progressive loading
 * - Preloading
 * - Image caching
 * - Blur-up technique
 * - Pexels API integration
 */
import React from 'react';
import pexelsClient, { PexelsPhoto } from '../services/pexelsService';

// Define types
interface LazyImageOptions {
  placeholder?: string;
  threshold?: number;
  rootMargin?: string;
}

interface LazyImageResult {
  ref: React.RefObject<HTMLImageElement>;
  loaded: boolean;
  error: Error | null;
  src: string;
}

interface OptimalDimensions {
  width: number;
  height: number;
}

// In-memory cache for loaded images
const imageCache = new Map<string, string>();

// Observer for lazy loading
const lazyLoadObserver = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target as HTMLImageElement;
        const src = img.dataset.src;
        if (src) {
          loadImage(src).then((url) => {
            img.src = url;
            observer.unobserve(img);
          });
        }
      }
    });
  },
  {
    rootMargin: '50px 0px', // Start loading when image is 50px from viewport
    threshold: 0.01,
  }
);

/**
 * Load an image with caching
 */
export const loadImage = (src: string): Promise<string> => {
  if (imageCache.has(src)) {
    return Promise.resolve(imageCache.get(src) as string);
  }

  return new Promise<string>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      imageCache.set(src, src);
      resolve(src);
    };

    img.onerror = () => {
      reject(new Error(`Failed to load image: ${src}`));
    };

    img.src = src;
  });
};

/**
 * Preload an array of images
 */
export const preloadImages = (urls: string[]): Promise<string[]> => {
  return Promise.all(urls.map((url) => loadImage(url)));
};

/**
 * Generate a tiny placeholder image
 */
export const generatePlaceholder = (width: number, height: number, color = '#f0f0f0'): string => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, width, height);
    return canvas.toDataURL();
  }
  return '';
};

/**
 * Create a blur-up effect for images
 */
export const createBlurUpEffect = (element: HTMLImageElement, src: string, placeholder: string): void => {
  element.style.filter = 'blur(20px)';
  element.src = placeholder;

  loadImage(src).then((url) => {
    element.src = url;
    element.style.filter = 'none';
    element.style.transition = 'filter 0.3s ease-out';
  });
};

/**
 * Initialize lazy loading for an image element
 */
export const initLazyLoading = (imgElement: HTMLImageElement, src: string): void => {
  imgElement.dataset.src = src;
  imgElement.src = generatePlaceholder(imgElement.width || 300, imgElement.height || 200);
  lazyLoadObserver.observe(imgElement);
};

/**
 * React hook for lazy loading images
 */
export const useLazyImage = (src: string, options: LazyImageOptions = {}): LazyImageResult => {
  const { placeholder, threshold = 0.01, rootMargin = '50px 0px' } = options;

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    if (!imgRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadImage(src)
            .then(() => setLoaded(true))
            .catch((err: Error) => setError(err));
          observer.unobserve(imgRef.current as HTMLImageElement);
        }
      },
      { threshold, rootMargin }
    );

    observer.observe(imgRef.current);

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src, threshold, rootMargin]);

  return {
    ref: imgRef,
    loaded,
    error,
    src: loaded ? src : placeholder || generatePlaceholder(300, 200),
  };
};
/**
 * Pexels API integration
 */
export interface PexelsImageOptions {
  query?: string;
  orientation?: 'landscape' | 'portrait' | 'square';
  size?: 'large' | 'medium' | 'small';
  color?: string;
  perPage?: number;
  page?: number;
}

export interface PexelsImageResult {
  url: string;
  alt: string;
  width: number;
  height: number;
  photographer: string;
  photographerUrl: string;
  source: string;
  originalUrl: string;
}

/**
 * Get random image from Pexels for a specific category
 */
export const getRandomPexelsImage = async (
  category: string,
  options: Omit<PexelsImageOptions, 'query'> = {}
): Promise<PexelsImageResult | null> => {
  try {
    const searchResult = await pexelsClient.searchPhotos(category, {
      per_page: options.perPage || 1,
      page: options.page || Math.floor(Math.random() * 10) + 1,
      orientation: options.orientation,
      size: options.size,
      color: options.color,
    });

    if (searchResult.photos && searchResult.photos.length > 0) {
      const photo = searchResult.photos[0];
      return mapPexelsPhotoToResult(photo);
    }
    return null;
  } catch (error) {
    console.error('Error fetching Pexels image:', error);
    return null;
  }
};

/**
 * Get marketplace product image from Pexels
 * Specialized function for marketplace products that searches for relevant product images
 */
export const getMarketplaceProductImage = async (
  productName: string,
  productCategory: string,
  options: Omit<PexelsImageOptions, 'query'> = {}
): Promise<PexelsImageResult | null> => {
  try {
    // Try to find an image using the product name first
    let searchResult = await pexelsClient.searchPhotos(productName, {
      per_page: 1,
      page: Math.floor(Math.random() * 5) + 1,
      orientation: options.orientation || 'square',
      size: options.size || 'medium',
    });

    // If no results, try with the category
    if (!searchResult.photos || searchResult.photos.length === 0) {
      searchResult = await pexelsClient.searchPhotos(productCategory, {
        per_page: 1,
        page: Math.floor(Math.random() * 5) + 1,
        orientation: options.orientation || 'square',
        size: options.size || 'medium',
      });
    }

    if (searchResult.photos && searchResult.photos.length > 0) {
      const photo = searchResult.photos[0];
      return mapPexelsPhotoToResult(photo);
    }
    return null;
  } catch (error) {
    console.error('Error fetching marketplace product image:', error);
    return null;
  }
};

/**
 * Get marketplace category image from Pexels
 * Specialized function for marketplace categories
 */
export const getMarketplaceCategoryImage = async (
  categoryName: string,
  options: Omit<PexelsImageOptions, 'query'> = {}
): Promise<PexelsImageResult | null> => {
  try {
    const searchResult = await pexelsClient.searchPhotos(categoryName + " home", {
      per_page: 1,
      page: Math.floor(Math.random() * 3) + 1,
      orientation: options.orientation || 'landscape',
      size: options.size || 'medium',
    });

    if (searchResult.photos && searchResult.photos.length > 0) {
      const photo = searchResult.photos[0];
      return mapPexelsPhotoToResult(photo);
    }
    return null;
  } catch (error) {
    console.error('Error fetching marketplace category image:', error);
    return null;
  }
};

/**
 * Search for images on Pexels
 */
export const searchPexelsImages = async (
  query: string,
  options: Omit<PexelsImageOptions, 'query'> = {}
): Promise<PexelsImageResult[]> => {
  try {
    const searchResult = await pexelsClient.searchPhotos(query, {
      per_page: options.perPage || 15,
      page: options.page || 1,
      orientation: options.orientation,
      size: options.size,
      color: options.color,
    });

    if (searchResult.photos && searchResult.photos.length > 0) {
      return searchResult.photos.map(mapPexelsPhotoToResult);
    }
    return [];
  } catch (error) {
    console.error('Error searching Pexels images:', error);
    return [];
  }
};

/**
 * Get curated images from Pexels
 */
export const getCuratedPexelsImages = async (
  options: Pick<PexelsImageOptions, 'perPage' | 'page'> = {}
): Promise<PexelsImageResult[]> => {
  try {
    const result = await pexelsClient.getCuratedPhotos({
      per_page: options.perPage || 15,
      page: options.page || 1,
    });

    if (result.photos && result.photos.length > 0) {
      return result.photos.map(mapPexelsPhotoToResult);
    }
    return [];
  } catch (error) {
    console.error('Error fetching curated Pexels images:', error);
    return [];
  }
};

/**
 * Map Pexels photo to a standardized result
 */
const mapPexelsPhotoToResult = (photo: PexelsPhoto): PexelsImageResult => {
  return {
    url: photo.src.large,
    alt: photo.alt || `Photo by ${photo.photographer}`,
    width: photo.width,
    height: photo.height,
    photographer: photo.photographer,
    photographerUrl: photo.photographer_url,
    source: 'pexels',
    originalUrl: photo.url,
  };
};

/**
 * Image optimization utilities
 */
export const imageUtils = {
  /**
   * Get optimized image URL based on device and container size
   */
  getOptimizedUrl: (url: string, width?: number, height?: number): string => {
    // If it's a Pexels URL, use their sizing parameters
    if (url.includes('images.pexels.com')) {
      // Pexels already provides optimized URLs with w= and h= parameters
      const urlObj = new URL(url);
      if (width) urlObj.searchParams.set('w', width.toString());
      if (height) urlObj.searchParams.set('h', height.toString());
      return urlObj.toString();
    }

    // For other URLs, return as is for now
    return url;
  },

  /**
   * Calculate optimal image dimensions based on container and device
   */
  getOptimalDimensions: (containerWidth: number, containerHeight?: number, imageRatio = 16 / 9): OptimalDimensions => {
    const width = Math.min(containerWidth, window.innerWidth);
    const height = Math.min(containerHeight || width / imageRatio, window.innerHeight);
    return { width, height };
  },

  /**
   * Check if image is cached
   */
  isImageCached: (src: string): boolean => {
    return imageCache.has(src);
  },

  /**
   * Clear image cache
   */
  clearCache: (): void => {
    imageCache.clear();
  },

  /**
   * Get cache size
   */
  getCacheSize: (): number => {
    return imageCache.size;
  },

  /**
   * Pexels API utilities
   */
  pexels: {
    getRandomImage: getRandomPexelsImage,
    searchImages: searchPexelsImages,
    getCuratedImages: getCuratedPexelsImages,
    getMarketplaceProductImage: getMarketplaceProductImage,
    getMarketplaceCategoryImage: getMarketplaceCategoryImage,
  },
};

// Example usage:
/*
import { useLazyImage } from './imageLoader';

const MyComponent = () => {
  const { ref, src, loaded } = useLazyImage('https://example.com/image.jpg', {
    placeholder: 'data:image/jpeg;base64,...', // Optional placeholder
    threshold: 0.1, // Optional intersection threshold
    rootMargin: '100px 0px' // Optional root margin
  });

  return (
    <img
      ref={ref}
      src={src}
      alt="Lazy loaded image"
      style={{
        filter: loaded ? 'none' : 'blur(10px)',
        transition: 'filter 0.3s ease-out'
      }}
    />
  );
};
*/

export default {
  loadImage,
  preloadImages,
  generatePlaceholder,
  createBlurUpEffect,
  initLazyLoading,
  useLazyImage,
  imageUtils,
  getRandomPexelsImage,
  searchPexelsImages,
  getCuratedPexelsImages,
  getMarketplaceProductImage,
  getMarketplaceCategoryImage,
};
