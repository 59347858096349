import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Container, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';
import ProfessionalSearchBar from '../../components/search/ProfessionalSearchBar';
import axios from 'axios';
import { ICategory } from '../../types/models';
import { SearchParams } from '../../types/search';
import imageLoader from '../../utils/imageLoader';

interface CategoryImage {
  id: string;
  url: string;
  photographer: string;
  photographerUrl: string;
}

const ServicesIndex: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [categoryImages, setCategoryImages] = useState<Record<string, CategoryImage>>({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Fetch images for categories
  useEffect(() => {
    const fetchCategoryImages = async () => {
      if (categories.length === 0) return;

      const images: Record<string, CategoryImage> = {};

      for (const category of categories) {
        try {
          // Use a more specific search term for better results
          const searchTerm = `${category.name} home service interior`;
          console.log(`Fetching image for: ${searchTerm}`);

          const result = await imageLoader.getRandomPexelsImage(searchTerm, {
            orientation: 'landscape',
            size: 'medium',
          });

          if (result) {
            console.log(`Found image for ${category.name}:`, result.url);
            images[category._id] = {
              id: category._id,
              url: result.url,
              photographer: result.photographer,
              photographerUrl: result.photographerUrl,
            };
          } else {
            console.log(`No image found for ${category.name}`);
          }
        } catch (error) {
          console.error(`Error fetching image for ${category.name}:`, error);
        }
      }

      console.log('All category images:', images);
      setCategoryImages(images);
    };

    fetchCategoryImages();
  }, []);

  // Handle search submission
  const handleSearch = (params: SearchParams) => {
    // Build the search URL
    const searchParams = new URLSearchParams();
    if (params.query) searchParams.set('service', params.query);
    if (params.location?.address) searchParams.set('emirate', params.location.address);

    // Navigate to the search results page
    navigate(`/find-professionals?${searchParams.toString()}`);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageTitle
        title="Services Directory - Find Home Services in UAE"
        description="Browse our comprehensive directory of home services available in the UAE. Find professionals for all your home improvement and maintenance needs."
        keywords="home services, UAE, Dubai, Abu Dhabi, home improvement, renovation, maintenance, professionals"
        locale="en_AE"
      />

      {/* Search Bar */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Find Home Service Professionals
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph align="center">
          Search for skilled professionals or browse by category
        </Typography>
        <ProfessionalSearchBar
          onSearch={handleSearch}
        />
      </Box>

      <Typography variant="h5" component="h2" gutterBottom>
        Browse Services by Category
      </Typography>

      <Typography variant="body1" color="text.secondary" paragraph>
        Explore our comprehensive directory of home services available in the UAE.
        Find professionals for all your home improvement and maintenance needs.
      </Typography>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={3} lg={2} key={category._id}>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
              },
            }}>
              <CardActionArea
                component={Link}
                to={`/services/category/${category.slug}`}
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
              >
                <CardMedia
                  component="img"
                  height={120}
                  image={categoryImages[category._id]?.url || category.icon || 'https://via.placeholder.com/300x160'}
                  alt={category.name}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                  <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="medium">
                    {category.name}
                  </Typography>
                  {category.serviceCount && (
                    <Typography variant="caption" color="primary" sx={{ display: 'block' }}>
                      {category.serviceCount} services
                    </Typography>
                  )}
                  {categoryImages[category._id] && (
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', fontSize: '0.6rem', mt: 1 }}>
                      Photo by <a href={categoryImages[category._id].photographerUrl} target="_blank" rel="noopener noreferrer">{categoryImages[category._id].photographer}</a> on Pexels
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicesIndex;