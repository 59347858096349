import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useSellerOnboarding } from '../../../contexts/SellerOnboardingContext';
import { useAuth } from '../../../hooks/useAuth';

// Step components
import BusinessProfileStep from './steps/BusinessProfileStep';
import ContactInfoStep from './steps/ContactInfoStep';
import ProductCategoriesStep from './steps/ProductCategoriesStep';
import ProductsStep from './steps/ProductsStep';
import ShippingInfoStep from './steps/ShippingInfoStep';
import PaymentInfoStep from './steps/PaymentInfoStep';
import DocumentsStep from './steps/DocumentsStep';
import OnboardingComplete from './steps/OnboardingComplete';

// Step titles
const steps = [
  'Business Profile',
  'Contact Information',
  'Product Categories',
  'Products',
  'Shipping Information',
  'Payment Information',
  'Documents',
];

const SellerOnboarding: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const { step } = useParams<{ step?: string }>();
  const navigate = useNavigate();

  const {
    currentStep,
    onboardingStatus,
    isLoading,
    error,
    fetchOnboardingStatus,
  } = useSellerOnboarding();

  // Parse step from URL or use currentStep from context
  const stepNumber = step ? parseInt(step, 10) : currentStep;

  // Redirect if not authenticated or not a seller
  useEffect(() => {
    if (!isLoading && isAuthenticated && (!user || user.role !== 'seller')) {
      navigate('/');
    }
  }, [isAuthenticated, user, navigate, isLoading]);

  // Fetch onboarding status on mount
  useEffect(() => {
    fetchOnboardingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  // Show loading state
  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Show error state
  if (error) {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 4 }}>
          <Paper sx={{ p: 3, bgcolor: '#fff9f9' }}>
            <Typography variant="h5" color="error">Error</Typography>
            <Typography>{error}</Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  // If onboarding is complete, show completion page
  if (onboardingStatus?.completed) {
    return <OnboardingComplete />;
  }

  // Render the current step
  const renderStep = () => {
    switch (stepNumber) {
      case 0:
        return <BusinessProfileStep />;
      case 1:
        return <ContactInfoStep />;
      case 2:
        return <ProductCategoriesStep />;
      case 3:
        return <ProductsStep />;
      case 4:
        return <ShippingInfoStep />;
      case 5:
        return <PaymentInfoStep />;
      case 6:
        return <DocumentsStep />;
      default:
        return <BusinessProfileStep />;
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Seller Onboarding
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph>
          Complete your seller profile to start selling on our marketplace
        </Typography>

        <Stepper activeStep={stepNumber} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={onboardingStatus ? onboardingStatus.step > index : false}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStep()}
      </Paper>
    </Container>
  );
};

export default SellerOnboarding;