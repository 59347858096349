import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography, Paper, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateBusinessInfo } from '../../../redux/slices/providerSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import { RootState } from '../../../redux/store';
import { AppDispatch } from '../../../redux/store';

const validationSchema = Yup.object({
  businessName: Yup.string()
    .required('Business name is required')
    .max(100, 'Business name must be less than 100 characters'),
  businessDescription: Yup.string()
    .required('Business description is required')
    .max(1000, 'Business description must be less than 1000 characters'),
  yearsInBusiness: Yup.number()
    .required('Years in business is required')
    .min(0, 'Years must be a positive number')
    .integer('Years must be a whole number'),
  logoUrl: Yup.string().nullable(),
});

const BusinessInfoForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error, profile } = useSelector((state: RootState) => state.provider);
  const [uploadingLogo, setUploadingLogo] = useState(false);

  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploadingLogo(true);
    try {
      // TODO: Implement file upload to cloud storage
      // For now, we'll use a mock URL
      const mockUrl = `https://storage.example.com/logos/${file.name}`;
      formik.setFieldValue('logoUrl', mockUrl);
    } catch (err) {
      console.error('Failed to upload logo:', err);
    } finally {
      setUploadingLogo(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      businessName: profile?.businessName || '',
      businessDescription: profile?.businessDescription || '',
      yearsInBusiness: profile?.yearsInBusiness || 0,
      logoUrl: profile?.logoUrl || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(updateBusinessInfo(values)).unwrap();
      } catch (err) {
        console.error('Failed to update business info:', err);
      }
    },
  });

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Business Information
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
              <Box
                sx={{
                  width: 150,
                  height: 150,
                  borderRadius: '50%',
                  border: '1px dashed #ccc',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {formik.values.logoUrl ? (
                  <Box
                    component="img"
                    src={formik.values.logoUrl}
                    alt="Business Logo"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    onError={(e) => {
                      // If image fails to load, just hide it
                      const target = e.target as HTMLImageElement;
                      target.style.display = 'none';
                    }}
                  />
                ) : (
                  <BusinessIcon sx={{ fontSize: 60, color: 'text.secondary' }} />
                )}
              </Box>
              <Button
                variant="outlined"
                component="label"
                startIcon={<CloudUploadIcon />}
                size="small"
                disabled={uploadingLogo}
              >
                {uploadingLogo ? 'Uploading...' : 'Upload Logo'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleLogoUpload}
                />
              </Button>
              {formik.values.logoUrl && (
                <Button
                  variant="text"
                  color="error"
                  size="small"
                  startIcon={<DeleteIcon />}
                  onClick={() => formik.setFieldValue('logoUrl', '')}
                  sx={{ mt: 1 }}
                >
                  Remove
                </Button>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="businessName"
              name="businessName"
              label="Business Name"
              value={formik.values.businessName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.businessName && Boolean(formik.errors.businessName)}
              helperText={formik.touched.businessName && formik.errors.businessName}
            />

            <TextField
              fullWidth
              id="yearsInBusiness"
              name="yearsInBusiness"
              label="Years in Business"
              type="number"
              value={formik.values.yearsInBusiness}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.yearsInBusiness && Boolean(formik.errors.yearsInBusiness)}
              helperText={formik.touched.yearsInBusiness && formik.errors.yearsInBusiness}
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ mt: 3 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="businessDescription"
              name="businessDescription"
              label="Business Description"
              multiline
              rows={4}
              value={formik.values.businessDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.businessDescription && Boolean(formik.errors.businessDescription)
              }
              helperText={formik.touched.businessDescription && formik.errors.businessDescription}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" type="submit" disabled={loading}>
                {loading ? 'Saving...' : 'Save Changes'}
              </Button>
            </Box>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </Paper>
  );
};

export default BusinessInfoForm;