import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
  useTheme,
  useMediaQuery,
  CardActions,
  Skeleton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import imageLoader from '../../utils/imageLoader';

// Department data structure
interface Department {
  id: string;
  name: string;
  image: string;
  productCount: number;
  featured: boolean;
  imageAttribution?: {
    name: string;
    link: string;
  };
}

// Product data structure
interface Product {
  id: string;
  name: string;
  image: string;
  price: number;
  department: string;
  rating: number;
  reviewCount: number;
  imageAttribution?: {
    name: string;
    link: string;
  };
}

// Initial department data - images will be loaded from Pexels API
const departmentsData: Department[] = [
  {
    id: 'furniture',
    name: 'Furniture',
    image: '',
    productCount: 1245,
    featured: true,
  },
  {
    id: 'lighting',
    name: 'Lighting',
    image: '',
    productCount: 876,
    featured: true,
  },
  {
    id: 'decor',
    name: 'Decor & Accessories',
    image: '',
    productCount: 2134,
    featured: true,
  },
  {
    id: 'kitchen-appliances',
    name: 'Kitchen Appliances',
    image: '',
    productCount: 543,
    featured: true,
  },
  {
    id: 'bathroom',
    name: 'Bathroom',
    image: '',
    productCount: 789,
    featured: true,
  },
  {
    id: 'outdoor',
    name: 'Outdoor',
    image: '',
    productCount: 654,
    featured: true,
  },
];

// Initial featured products - images will be loaded from Pexels API
const featuredProductsData: Product[] = [
  {
    id: 'p1',
    name: 'Modern Sofa',
    image: '',
    price: 899.99,
    department: 'furniture',
    rating: 4.7,
    reviewCount: 124,
  },
  {
    id: 'p2',
    name: 'Pendant Light',
    image: '',
    price: 129.99,
    department: 'lighting',
    rating: 4.8,
    reviewCount: 89,
  },
  {
    id: 'p3',
    name: 'Decorative Vase',
    image: '',
    price: 49.99,
    department: 'decor',
    rating: 4.5,
    reviewCount: 67,
  },
];

// Fallback image in case API fails
const FALLBACK_IMAGE = 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

const DepartmentShop: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [departments, setDepartments] = useState<Department[]>(departmentsData);
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>(featuredProductsData);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch department images from Pexels API
  useEffect(() => {
    const fetchDepartmentImages = async () => {
      try {
        // Create a copy of the departments data to update
        const updatedDepartments = [...departments];

        // Fetch images for each department in parallel
        const promises = updatedDepartments.map(async (dept, index) => {
          try {
            // Get a random image for the department category
            const imageResult = await imageLoader.getRandomPexelsImage(dept.name, {
              orientation: 'landscape',
              size: 'large',
            });

            if (imageResult) {
              // Update the department with the image data
              updatedDepartments[index] = {
                ...dept,
                image: imageResult.url,
                imageAttribution: {
                  name: imageResult.photographer,
                  link: imageResult.photographerUrl,
                },
              };
            } else {
              // Use fallback image if no results
              updatedDepartments[index] = {
                ...dept,
                image: FALLBACK_IMAGE,
                imageAttribution: {
                  name: 'Pexels',
                  link: 'https://www.pexels.com',
                },
              };
            }
          } catch (error) {
            console.error(`Error fetching image for ${dept.id}:`, error);
            // Use fallback image on error
            updatedDepartments[index] = {
              ...dept,
              image: FALLBACK_IMAGE,
              imageAttribution: {
                name: 'Pexels',
                link: 'https://www.pexels.com',
              },
            };
          }
        });

        // Wait for all image fetches to complete
        await Promise.all(promises);

        // Update state with the new department data
        setDepartments(updatedDepartments);
      } catch (error) {
        console.error('Error fetching department images:', error);
      }
    };

    // Fetch product images from Pexels API
    const fetchProductImages = async () => {
      try {
        // Create a copy of the products data to update
        const updatedProducts = [...featuredProducts];

        // Fetch images for each product in parallel
        const promises = updatedProducts.map(async (product, index) => {
          try {
            // Get a random image for the product category
            const imageResult = await imageLoader.getRandomPexelsImage(product.name, {
              orientation: 'landscape',
              size: 'large',
            });

            if (imageResult) {
              // Update the product with the image data
              updatedProducts[index] = {
                ...product,
                image: imageResult.url,
                imageAttribution: {
                  name: imageResult.photographer,
                  link: imageResult.photographerUrl,
                },
              };
            } else {
              // Use fallback image if no results
              updatedProducts[index] = {
                ...product,
                image: FALLBACK_IMAGE,
                imageAttribution: {
                  name: 'Pexels',
                  link: 'https://www.pexels.com',
                },
              };
            }
          } catch (error) {
            console.error(`Error fetching image for ${product.id}:`, error);
            // Use fallback image on error
            updatedProducts[index] = {
              ...product,
              image: FALLBACK_IMAGE,
              imageAttribution: {
                name: 'Pexels',
                link: 'https://www.pexels.com',
              },
            };
          }
        });

        // Wait for all image fetches to complete
        await Promise.all(promises);

        // Update state with the new product data
        setFeaturedProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching product images:', error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch all images
    const fetchAllImages = async () => {
      await fetchDepartmentImages();
      await fetchProductImages();
    };

    fetchAllImages();
  }, []);

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          gutterBottom
          sx={{ mb: 2 }}
        >
          Shop by Department
        </Typography>
        <Typography
          variant="h6"
          component="p"
          align="center"
          color="text.secondary"
          sx={{ mb: 6, maxWidth: 700, mx: 'auto' }}
        >
          Find everything you need for your home improvement projects
        </Typography>

        <Grid container spacing={3}>
          {departments.map((dept) => (
            <Grid item xs={12} sm={6} md={4} key={dept.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                  },
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                {loading ? (
                  <Skeleton variant="rectangular" height={200} animation="wave" />
                ) : (
                  <CardMedia
                    component="img"
                    height="200"
                    image={dept.image || FALLBACK_IMAGE}
                    alt={dept.name}
                    sx={{
                      transition: 'transform 0.5s ease, opacity 0.3s ease, filter 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      opacity: loading ? 0.7 : 1,
                      filter: loading ? 'blur(5px)' : 'none',
                    }}
                  />
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" component="h3" gutterBottom>
                    {dept.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dept.productCount.toLocaleString()} products
                  </Typography>
                  {!loading && dept.imageAttribution && (
                    <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                      Photo by{' '}
                      <a
                        href={dept.imageAttribution.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                      >
                        {dept.imageAttribution.name}
                      </a>
                    </Typography>
                  )}
                </CardContent>
                <CardActions sx={{ p: 2, pt: 0 }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate(`/marketplace/departments/${dept.id}`)}
                    startIcon={<ShoppingCartIcon />}
                    sx={{ borderRadius: 2 }}
                  >
                    Shop Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Featured Products Section */}
        <Box sx={{ mt: 8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography variant="h4" component="h3">
              Featured Products
            </Typography>
            <Button
              variant="text"
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate('/marketplace/featured')}
            >
              View All
            </Button>
          </Box>

          <Grid container spacing={3}>
            {featuredProducts.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                    },
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  {loading ? (
                    <Skeleton variant="rectangular" height={200} animation="wave" />
                  ) : (
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.image || FALLBACK_IMAGE}
                      alt={product.name}
                      sx={{
                        transition: 'transform 0.5s ease, opacity 0.3s ease, filter 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                        opacity: loading ? 0.7 : 1,
                        filter: loading ? 'blur(5px)' : 'none',
                      }}
                    />
                  )}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="h3" gutterBottom>
                      {product.name}
                    </Typography>
                    <Typography variant="h6" color="primary" gutterBottom>
                      ${product.price.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {product.rating} ★ ({product.reviewCount} reviews)
                    </Typography>
                    {!loading && product.imageAttribution && (
                      <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                        Photo by{' '}
                        <a
                          href={product.imageAttribution.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'inherit', textDecoration: 'underline' }}
                        >
                          {product.imageAttribution.name}
                        </a>
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => navigate(`/marketplace/products/${product.id}`)}
                      startIcon={<ShoppingCartIcon />}
                      sx={{ borderRadius: 2 }}
                    >
                      Add to Cart
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/marketplace')}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              fontSize: '1.1rem',
            }}
          >
            Visit Marketplace
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default DepartmentShop;