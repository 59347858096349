import React from 'react';
import ReviewsOnboardingProvider from './ReviewsOnboardingProvider';
import ProReviewsManager from './ProReviewsManager';

interface ReviewsTabWrapperProps {
  providerId?: string;
}

/**
 * Wrapper component that provides the OnboardingContext for the Reviews tab
 * This is needed because the GoogleBusinessConnect and GoogleReviewImport components
 * use the useOnboarding hook, which requires the OnboardingProvider
 */
const ReviewsTabWrapper: React.FC<ReviewsTabWrapperProps> = ({ providerId }) => {
  return (
    <ReviewsOnboardingProvider>
      <ProReviewsManager providerId={providerId} />
    </ReviewsOnboardingProvider>
  );
};

export default ReviewsTabWrapper;