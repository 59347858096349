import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
  Box,
  Chip,
  Tooltip,
  useTheme,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Notification } from '../../../types/notification';
import { useNotifications } from '../../../contexts/NotificationContext';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';

interface NotificationItemProps {
  notification: Notification;
  showActions?: boolean;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, showActions = true }) => {
  const { markAsRead, deleteNotification } = useNotifications();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleMarkAsRead = (e: React.MouseEvent) => {
    e.stopPropagation();
    markAsRead(notification._id);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    deleteNotification(notification._id);
  };

  const handleClick = () => {
    // If notification has a URL, navigate to it
    if (notification.data?.url) {
      navigate(notification.data.url);
    }

    // If notification is unread, mark it as read
    if (!notification.read) {
      markAsRead(notification._id);
    }
  };

  // Get icon based on notification type
  const getNotificationIcon = () => {
    switch (notification.type) {
      case 'lead':
        return <AssignmentIcon />;
      case 'payment':
        return <PaymentIcon />;
      case 'billing':
        return <ReceiptIcon />;
      case 'system':
        return <InfoIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  // Get color based on notification type
  const getNotificationColor = () => {
    switch (notification.type) {
      case 'lead':
        return theme.palette.primary.main;
      case 'payment':
        return theme.palette.success.main;
      case 'billing':
        return theme.palette.warning.main;
      case 'system':
        return theme.palette.info.main;
      default:
        return theme.palette.grey[500];
    }
  };

  // Get type label
  const getTypeLabel = () => {
    switch (notification.type) {
      case 'lead':
        return 'Lead';
      case 'payment':
        return 'Payment';
      case 'billing':
        return 'Billing';
      case 'system':
        return 'System';
      default:
        return 'Notification';
    }
  };

  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        cursor: 'pointer',
        bgcolor: notification.read ? 'transparent' : 'action.hover',
        '&:hover': {
          bgcolor: 'action.selected',
        },
        borderLeft: notification.read ? 'none' : `4px solid ${getNotificationColor()}`,
      }}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: getNotificationColor() }}>{getNotificationIcon()}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
            <Typography variant="subtitle1" component="span" sx={{ fontWeight: notification.read ? 'normal' : 'bold' }}>
              {notification.title}
            </Typography>
            <Chip
              label={getTypeLabel()}
              size="small"
              sx={{
                bgcolor: getNotificationColor(),
                color: 'white',
                height: 20,
                '& .MuiChip-label': { px: 1, py: 0, fontSize: '0.7rem' },
              }}
            />
          </Box>
        }
        secondary={
          <>
            <Typography
              component="span"
              variant="body2"
              color="text.primary"
              sx={{ display: 'block', mb: 1 }}
            >
              {notification.message}
            </Typography>
            <Typography component="span" variant="caption" color="text.secondary">
              {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
            </Typography>
            {notification.data?.actionText && notification.data?.url && (
              <Chip
                label={notification.data.actionText}
                size="small"
                color="primary"
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (notification.data?.url) {
                    navigate(notification.data.url);
                  }
                }}
              />
            )}
          </>
        }
      />
      {showActions && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {!notification.read && (
            <Tooltip title="Mark as read">
              <IconButton edge="end" aria-label="mark as read" onClick={handleMarkAsRead} size="small">
                <MarkEmailReadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <IconButton edge="end" aria-label="delete" onClick={handleDelete} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </ListItem>
  );
};

export default NotificationItem;