import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './useAuth';
import ideaService from '../services/ideaService';

interface UseSaveIdeaResult {
  saveIdea: (ideaId: string) => void;
  saveToIdeabook: (ideaId: string, ideabookId: string) => Promise<void>;
  isModalOpen: boolean;
  openModal: (ideaId: string) => void;
  closeModal: () => void;
  currentIdeaId: string | null;
  isLoading: boolean;
  error: string | null;
}

export const useSaveIdea = (): UseSaveIdeaResult => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIdeaId, setCurrentIdeaId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const openModal = (ideaId: string) => {
    setCurrentIdeaId(ideaId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentIdeaId(null);
  };

  const saveIdea = (ideaId: string) => {
    if (!isAuthenticated) {
      // Store the current location to redirect back after login
      localStorage.setItem('authRedirectUrl', location.pathname);
      navigate('/login');
      return;
    }

    // If authenticated, open the modal to select an ideabook
    openModal(ideaId);
  };

  const saveToIdeabook = async (ideaId: string, ideabookId: string) => {
    try {
      setIsLoading(true);
      setError(null);

      // Call the API service to save the idea to the ideabook
      await ideaService.saveToIdeabook(ideaId, ideabookId);

      closeModal();
    } catch (err) {
      setError('Failed to save to ideabook. Please try again.');
      console.error('Error saving to ideabook:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    saveIdea,
    saveToIdeabook,
    isModalOpen,
    openModal,
    closeModal,
    currentIdeaId,
    isLoading,
    error,
  };
};