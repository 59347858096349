import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Skeleton,
  CardActionArea,
} from '@mui/material';
import { CategoryBrowserProps } from '../../types/search';
import { ICategory } from '../../types/models';

const CategoryBrowser: React.FC<CategoryBrowserProps> = ({
  categories = [], // Provide default empty array
  loading = false,
  isPopular = false,
  onCategorySelect,
}) => {
  if (loading) {
    return (
      <Grid container spacing={3}>
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <Skeleton variant="rectangular" height={140} />
              <CardContent>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="80%" />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!categories || categories.length === 0) {
    return (
      <Card sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          No categories available
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Please try again later
        </Typography>
      </Card>
    );
  }

  const displayCategories = isPopular
    ? categories.filter(category => category.isActive).slice(0, 6)
    : categories;

  return (
    <Grid container spacing={3}>
      {displayCategories.map((category: ICategory) => (
        <Grid item xs={12} sm={6} md={4} key={category._id}>
          <Card>
            <CardActionArea onClick={() => onCategorySelect?.(category)}>
              <CardMedia
                component="img"
                height="140"
                image={category.icon}
                alt={category.name}
                sx={{ objectFit: 'contain', p: 2, bgcolor: 'grey.50' }}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {category.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  {category.description}
                </Typography>
                {category.parentCategory && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      Part of {categories.find(c => c._id === category.parentCategory)?.name}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoryBrowser;
