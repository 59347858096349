import React from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import {
  Box,
  Container,
  Tabs,
  Tab,
  Typography,
  Breadcrumbs,
  Paper,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Article as ArticleIcon,
  Calculate as CalculateIcon,
  MenuBook as GuideIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import PageTitle from '../../components/common/PageTitle';

/**
 * ResourcesLayout component serves as the parent layout for all resources pages
 * It includes navigation tabs and common elements for the resources section
 */
const ResourcesLayout: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine active tab based on current path
  const getActiveTab = () => {
    const path = location.pathname;
    if (path.includes('/resources/stories')) return 0;
    if (path.includes('/resources/estimators')) return 1;
    if (path.includes('/resources/guides')) return 2;
    return 0; // Default to stories tab
  };

  // Get current section name for breadcrumbs and title
  const getCurrentSection = () => {
    const path = location.pathname;
    if (path.includes('/resources/stories')) return 'Home Improvement Stories';
    if (path.includes('/resources/estimators')) return 'Cost Estimators';
    if (path.includes('/resources/guides')) return 'How-To Guides';
    return 'Resources'; // Default
  };

  // Get SEO description based on current section
  const getSeoDescription = () => {
    const path = location.pathname;
    if (path.includes('/resources/stories')) {
      return 'Explore home improvement stories, case studies, and trends from UAE homeowners and professionals.';
    }
    if (path.includes('/resources/estimators')) {
      return 'Calculate the cost of your home improvement projects with our UAE-specific cost estimators.';
    }
    if (path.includes('/resources/guides')) {
      return 'Step-by-step guides for home improvement projects tailored for UAE homes and conditions.';
    }
    return 'Discover home improvement resources, guides, and tools specifically designed for UAE homeowners.';
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageTitle
        title={getCurrentSection()}
        description={getSeoDescription()}
        keywords="home improvement, UAE, Dubai, Abu Dhabi, renovation, DIY, cost calculator"
        locale="en_AE"
      />

      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none' }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        <Typography color="text.primary">Resources</Typography>
        <Typography color="text.primary">{getCurrentSection()}</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4" component="h1" gutterBottom>
        {getCurrentSection()}
      </Typography>

      <Typography variant="body1" color="text.secondary" paragraph>
        Explore our collection of resources designed specifically for UAE homeowners.
        From inspiring stories to practical guides and cost estimators, find everything
        you need for your home improvement journey.
      </Typography>

      {/* Navigation Tabs */}
      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={getActiveTab()}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile ? "fullWidth" : "standard"}
          aria-label="resources navigation tabs"
        >
          <Tab
            icon={<ArticleIcon />}
            label={isMobile ? "" : "Stories"}
            iconPosition="start"
            component={Link}
            to="/resources/stories"
          />
          <Tab
            icon={<CalculateIcon />}
            label={isMobile ? "" : "Cost Estimators"}
            iconPosition="start"
            component={Link}
            to="/resources/estimators"
          />
          <Tab
            icon={<GuideIcon />}
            label={isMobile ? "" : "How-To Guides"}
            iconPosition="start"
            component={Link}
            to="/resources/guides"
          />
        </Tabs>
      </Paper>

      {/* Content Area - Renders child routes */}
      <Box sx={{ py: 2 }}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default ResourcesLayout;