import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  CardActionArea,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Share as ShareIcon,
  Lock as LockIcon,
  Public as PublicIcon,
} from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';

interface Ideabook {
  _id: string;
  title: string;
  description?: string;
  coverImage?: string;
  itemCount: number;
  isPrivate: boolean;
  lastModified: Date;
}

interface IdeabookListProps {
  ideabooks: Ideabook[];
  onCreateNew?: () => void; // Made optional since we moved the button to the header
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onShare: (id: string) => void;
  onView: (id: string) => void;
}

export const IdeabookList: React.FC<IdeabookListProps> = ({
  ideabooks,
  onCreateNew,
  onEdit,
  onDelete,
  onShare,
  onView,
}) => {
  const [menuAnchor, setMenuAnchor] = React.useState<{
    element: HTMLElement;
    ideabookId: string;
  } | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, ideabookId: string) => {
    event.stopPropagation();
    setMenuAnchor({
      element: event.currentTarget,
      ideabookId,
    });
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleAction = (action: (id: string) => void) => {
    if (menuAnchor) {
      action(menuAnchor.ideabookId);
      handleMenuClose();
    }
  };

  return (
    <Grid container spacing={3}>

      {/* Ideabook Cards */}
      {ideabooks.map((ideabook) => (
        <Grid item xs={12} sm={6} md={4} key={ideabook._id}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardActionArea onClick={() => onView(ideabook._id)}>
              <CardMedia
                component="img"
                height="140"
                image={ideabook.coverImage || 'https://images.pexels.com/photos/1571459/pexels-photo-1571459.jpeg?auto=compress&cs=tinysrgb&w=300&h=140&dpr=1'}
                alt={ideabook.title}
              />
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" noWrap>
                      {ideabook.title}
                    </Typography>
                    {ideabook.description && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {ideabook.description}
                      </Typography>
                    )}
                  </Box>
                  <IconButton
                    size="small"
                    onClick={(e) => handleMenuOpen(e, ideabook._id)}
                    sx={{ ml: 1 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>

                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                  {ideabook.isPrivate ? (
                    <LockIcon fontSize="small" color="action" />
                  ) : (
                    <PublicIcon fontSize="small" color="action" />
                  )}
                  <Typography variant="caption" color="text.secondary">
                    {ideabook.itemCount} items
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 'auto' }}>
                    Updated {formatDistanceToNow(new Date(ideabook.lastModified))} ago
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}

      <Menu
        anchorEl={menuAnchor?.element}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleAction(onEdit)}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleAction(onShare)}>
          <ShareIcon fontSize="small" sx={{ mr: 1 }} />
          Share
        </MenuItem>
        <MenuItem
          onClick={() => handleAction(onDelete)}
          sx={{ color: 'error.main' }}
        >
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </Grid>
  );
};