import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  RadioGroup,
  Radio,
  Divider,
  Button,
  Alert,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
} from '@mui/material';
import { useNotifications } from '../../../contexts/NotificationContext';
import { UpdateNotificationPreferencesRequest } from '../../../types/notification';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SaveIcon from '@mui/icons-material/Save';

const NotificationPreferences: React.FC = () => {
  const { preferences, updatePreferences } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [formValues, setFormValues] = useState<UpdateNotificationPreferencesRequest>({
    emailNotifications: {
      leads: preferences?.emailNotifications.leads || false,
      billing: preferences?.emailNotifications.billing || false,
      payments: preferences?.emailNotifications.payments || false,
      system: preferences?.emailNotifications.system || false,
    },
    inAppNotifications: {
      leads: preferences?.inAppNotifications.leads || true,
      billing: preferences?.inAppNotifications.billing || true,
      payments: preferences?.inAppNotifications.payments || true,
      system: preferences?.inAppNotifications.system || true,
    },
    emailFrequency: preferences?.emailFrequency || 'daily',
  });

  const handleEmailSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      emailNotifications: {
        ...formValues.emailNotifications,
        [name]: checked,
      },
    });
  };

  const handleInAppSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      inAppNotifications: {
        ...formValues.inAppNotifications,
        [name]: checked,
      },
    });
  };

  const handleFrequencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      emailFrequency: event.target.value as 'instant' | 'daily' | 'weekly',
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Cast to any to avoid TypeScript errors with partial types
      await updatePreferences(formValues as any);
      setSuccess(true);
    } catch (err) {
      setError('Failed to update notification preferences. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
  };

  if (!preferences) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Email Notifications"
              avatar={<EmailIcon color="primary" />}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <Divider />
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.emailNotifications?.leads}
                      onChange={handleEmailSwitchChange}
                      name="leads"
                      color="primary"
                    />
                  }
                  label="Lead Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive emails about new leads and lead status changes
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.emailNotifications?.billing}
                      onChange={handleEmailSwitchChange}
                      name="billing"
                      color="primary"
                    />
                  }
                  label="Billing Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive emails about billing updates and invoices
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.emailNotifications?.payments}
                      onChange={handleEmailSwitchChange}
                      name="payments"
                      color="primary"
                    />
                  }
                  label="Payment Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive emails about payment receipts and issues
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.emailNotifications?.system}
                      onChange={handleEmailSwitchChange}
                      name="system"
                      color="primary"
                    />
                  }
                  label="System Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive emails about system updates and maintenance
                </Typography>
              </FormGroup>

              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Email Frequency
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="emailFrequency"
                    value={formValues.emailFrequency}
                    onChange={handleFrequencyChange}
                  >
                    <FormControlLabel value="instant" control={<Radio />} label="Instant" />
                    <FormControlLabel value="daily" control={<Radio />} label="Daily Digest" />
                    <FormControlLabel value="weekly" control={<Radio />} label="Weekly Digest" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="In-App Notifications"
              avatar={<NotificationsActiveIcon color="primary" />}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <Divider />
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.inAppNotifications?.leads}
                      onChange={handleInAppSwitchChange}
                      name="leads"
                      color="primary"
                    />
                  }
                  label="Lead Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive in-app notifications about new leads and lead status changes
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.inAppNotifications?.billing}
                      onChange={handleInAppSwitchChange}
                      name="billing"
                      color="primary"
                    />
                  }
                  label="Billing Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive in-app notifications about billing updates and invoices
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.inAppNotifications?.payments}
                      onChange={handleInAppSwitchChange}
                      name="payments"
                      color="primary"
                    />
                  }
                  label="Payment Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive in-app notifications about payment receipts and issues
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.inAppNotifications?.system}
                      onChange={handleInAppSwitchChange}
                      name="system"
                      color="primary"
                    />
                  }
                  label="System Notifications"
                />
                <Typography variant="caption" color="text.secondary" sx={{ ml: 4, mt: -1, mb: 1 }}>
                  Receive in-app notifications about system updates and maintenance
                </Typography>
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          {loading ? 'Saving...' : 'Save Preferences'}
        </Button>
      </Box>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Notification preferences updated successfully"
      />
    </Box>
  );
};

export default NotificationPreferences;