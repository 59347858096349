/**
 * Performance monitoring utility for tracking and analyzing application performance
 */

// Define types for metrics
interface SearchMetrics {
  totalRequests: number;
  cacheHits: number;
  cacheMisses: number;
  averageResponseTime: number;
  responseTimes: number[];
}

interface ImageMetrics {
  totalLoaded: number;
  loadTimes: number[];
  averageLoadTime: number;
  failedLoads: number;
  cacheHits: number;
}

interface RouteMetrics {
  times: number[];
  average: number;
  count: number;
}

interface NavigationMetrics {
  pageLoads: Record<string, RouteMetrics>;
  averageLoadTime: number;
}

interface ResourceMetrics {
  totalSize: number;
  loadTimes: Record<string, number[]>;
}

interface PerformanceMetrics {
  search: SearchMetrics;
  images: ImageMetrics;
  navigation: NavigationMetrics;
  resources: ResourceMetrics;
}

interface SearchLogData {
  responseTime: number;
  isCacheHit: boolean;
  cacheHitRate: number;
}

interface ImageLogData {
  src: string;
  loadTime: number;
  success: boolean;
  failureRate: number;
}

interface NavigationLogData {
  route: string;
  loadTime: number;
  average: number;
}

interface ResourceLogData {
  resource: string;
  size: number;
  loadTime: number;
}

interface PerformanceReport {
  search: {
    totalRequests: number;
    cacheHitRate: number;
    averageResponseTime: number;
  };
  images: {
    totalLoaded: number;
    failureRate: number;
    averageLoadTime: number;
  };
  navigation: {
    pageLoads: Array<{
      route: string;
      averageLoadTime: number;
      loadCount: number;
    }>;
  };
  resources: {
    totalSize: number;
    averageLoadTimes: Array<{
      resource: string;
      averageLoadTime: number;
    }>;
  };
}

class PerformanceMonitor {
  private metrics: PerformanceMetrics;
  private readonly MAX_SAMPLES: number;

  constructor() {
    this.metrics = {
      search: {
        totalRequests: 0,
        cacheHits: 0,
        cacheMisses: 0,
        averageResponseTime: 0,
        responseTimes: [],
      },
      images: {
        totalLoaded: 0,
        loadTimes: [],
        averageLoadTime: 0,
        failedLoads: 0,
        cacheHits: 0,
      },
      navigation: {
        pageLoads: {},
        averageLoadTime: 0,
      },
      resources: {
        totalSize: 0,
        loadTimes: {},
      },
    };

    this.MAX_SAMPLES = 100; // Maximum number of samples to store
  }

  /**
   * Track search performance
   */
  trackSearch(startTime: number, endTime: number, isCacheHit: boolean): void {
    const responseTime = endTime - startTime;

    this.metrics.search.totalRequests++;
    if (isCacheHit) {
      this.metrics.search.cacheHits++;
    } else {
      this.metrics.search.cacheMisses++;
    }

    // Update response times
    this.metrics.search.responseTimes.push(responseTime);
    if (this.metrics.search.responseTimes.length > this.MAX_SAMPLES) {
      this.metrics.search.responseTimes.shift();
    }

    // Update average
    this.metrics.search.averageResponseTime =
      this.metrics.search.responseTimes.reduce((a, b) => a + b, 0) /
      this.metrics.search.responseTimes.length;

    this.logMetric('search', {
      responseTime,
      isCacheHit,
      cacheHitRate: this.getCacheHitRate(),
    });
  }

  /**
   * Track image loading performance
   */
  trackImageLoad(src: string, startTime: number, endTime: number, success = true): void {
    const loadTime = endTime - startTime;

    this.metrics.images.totalLoaded++;
    if (!success) {
      this.metrics.images.failedLoads++;
    }

    // Update load times
    this.metrics.images.loadTimes.push(loadTime);
    if (this.metrics.images.loadTimes.length > this.MAX_SAMPLES) {
      this.metrics.images.loadTimes.shift();
    }

    // Update average
    this.metrics.images.averageLoadTime =
      this.metrics.images.loadTimes.reduce((a, b) => a + b, 0) /
      this.metrics.images.loadTimes.length;

    this.logMetric('image', {
      src,
      loadTime,
      success,
      failureRate: this.getImageFailureRate(),
    });
  }

  /**
   * Track page navigation performance
   */
  trackPageLoad(route: string, loadTime: number): void {
    if (!this.metrics.navigation.pageLoads[route]) {
      this.metrics.navigation.pageLoads[route] = {
        times: [],
        average: 0,
        count: 0,
      };
    }

    const routeMetrics = this.metrics.navigation.pageLoads[route];
    routeMetrics.times.push(loadTime);
    routeMetrics.count++;

    if (routeMetrics.times.length > this.MAX_SAMPLES) {
      routeMetrics.times.shift();
    }

    routeMetrics.average =
      routeMetrics.times.reduce((a, b) => a + b, 0) / routeMetrics.times.length;

    this.logMetric('navigation', {
      route,
      loadTime,
      average: routeMetrics.average,
    });
  }

  /**
   * Track resource loading performance
   */
  trackResourceLoad(resource: string, size: number, loadTime: number): void {
    this.metrics.resources.totalSize += size;

    if (!this.metrics.resources.loadTimes[resource]) {
      this.metrics.resources.loadTimes[resource] = [];
    }

    this.metrics.resources.loadTimes[resource].push(loadTime);
    if (this.metrics.resources.loadTimes[resource].length > this.MAX_SAMPLES) {
      this.metrics.resources.loadTimes[resource].shift();
    }

    this.logMetric('resource', {
      resource,
      size,
      loadTime,
    });
  }

  /**
   * Calculate cache hit rate for search
   */
  getCacheHitRate(): number {
    if (this.metrics.search.totalRequests === 0) return 0;
    return this.metrics.search.cacheHits / this.metrics.search.totalRequests;
  }

  /**
   * Calculate image load failure rate
   */
  getImageFailureRate(): number {
    if (this.metrics.images.totalLoaded === 0) return 0;
    return this.metrics.images.failedLoads / this.metrics.images.totalLoaded;
  }

  /**
   * Get performance report
   */
  getReport(): PerformanceReport {
    return {
      search: {
        totalRequests: this.metrics.search.totalRequests,
        cacheHitRate: this.getCacheHitRate(),
        averageResponseTime: this.metrics.search.averageResponseTime,
      },
      images: {
        totalLoaded: this.metrics.images.totalLoaded,
        failureRate: this.getImageFailureRate(),
        averageLoadTime: this.metrics.images.averageLoadTime,
      },
      navigation: {
        pageLoads: Object.entries(this.metrics.navigation.pageLoads).map(([route, metrics]) => ({
          route,
          averageLoadTime: metrics.average,
          loadCount: metrics.count,
        })),
      },
      resources: {
        totalSize: this.metrics.resources.totalSize,
        averageLoadTimes: Object.entries(this.metrics.resources.loadTimes).map(
          ([resource, times]) => ({
            resource,
            averageLoadTime: times.reduce((a, b) => a + b, 0) / times.length,
          })
        ),
      },
    };
  }

  /**
   * Log metric to console in development
   */
  logMetric(type: string, data: SearchLogData | ImageLogData | NavigationLogData | ResourceLogData): void {
    // Development logging removed
  }

  /**
   * Reset all metrics
   */
  reset(): void {
    this.metrics = {
      search: {
        totalRequests: 0,
        cacheHits: 0,
        cacheMisses: 0,
        averageResponseTime: 0,
        responseTimes: [],
      },
      images: {
        totalLoaded: 0,
        loadTimes: [],
        averageLoadTime: 0,
        failedLoads: 0,
        cacheHits: 0,
      },
      navigation: {
        pageLoads: {},
        averageLoadTime: 0,
      },
      resources: {
        totalSize: 0,
        loadTimes: {},
      },
    };
  }
}

// Create singleton instance
const performanceMonitor = new PerformanceMonitor();

export default performanceMonitor;

// Example usage:
/*
import performanceMonitor from './performanceMonitor';

// Track search performance
const startTime = performance.now();
const results = await searchServices(params);
performanceMonitor.trackSearch(
  startTime,
  performance.now(),
  results.fromCache
);

// Track image load
const imgStartTime = performance.now();
await loadImage(src);
performanceMonitor.trackImageLoad(
  src,
  imgStartTime,
  performance.now()
);

// Track page load
const pageStartTime = performance.now();
// ... page loads ...
performanceMonitor.trackPageLoad(
  '/search',
  performance.now() - pageStartTime
);

// Get performance report
const report = performanceMonitor.getReport();
console.log(report);
*/
