import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useOnboarding } from '../../../contexts/OnboardingContext';

const OnboardingComplete: React.FC = () => {
  const { onboardingStatus } = useOnboarding();

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
          <CheckCircleIcon color="success" sx={{ fontSize: 80, mb: 2 }} />

          <Typography variant="h4" component="h1" gutterBottom>
            Onboarding Complete!
          </Typography>

          <Typography variant="body1" paragraph>
            Congratulations! You have successfully completed the service provider onboarding process.
            Your profile is now set up and you're ready to start receiving leads.
          </Typography>

          <Typography variant="body1" paragraph>
            Your profile is currently under review by our team. You'll be notified once it's verified.
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" gutterBottom align="left">
            Next Steps:
          </Typography>

          <List sx={{ mb: 3 }}>
            <ListItem>
              <ListItemIcon>
                <DashboardIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Visit your dashboard"
                secondary="View your leads, manage your services, and track your performance"
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <SettingsIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Update your profile settings"
                secondary="Keep your business information up to date"
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <AddCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Add more services"
                secondary="Expand your offerings to attract more customers"
              />
            </ListItem>
          </List>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/pro/dashboard"
              startIcon={<DashboardIcon />}
            >
              Go to Dashboard
            </Button>

            <Button
              variant="outlined"
              color="primary"
              component={RouterLink}
              to="/pro/profile"
              startIcon={<SettingsIcon />}
            >
              Edit Profile
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default OnboardingComplete;