import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import IdeabookForm from '../../components/ideas/IdeabookForm';

interface IdeabookFormData {
  title: string;
  description: string;
  isPrivate: boolean;
  tags: string[];
}

const IdeabookEditPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [ideabook, setIdeabook] = useState<IdeabookFormData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIdeabook = async () => {
      try {
        // TODO: Implement API call to fetch ideabook
        // For now, using mock data
        const mockData: IdeabookFormData = {
          title: 'Sample Ideabook',
          description: 'This is a sample ideabook description',
          isPrivate: false,
          tags: ['modern', 'minimalist'],
        };
        setIdeabook(mockData);
      } catch (err) {
        setError('Failed to load ideabook');
        console.error('Error fetching ideabook:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchIdeabook();
  }, [id]);

  const handleSubmit = async (data: IdeabookFormData) => {
    try {
      // TODO: Implement API call to update ideabook
      console.log('Updating ideabook:', data);
      navigate(`/ideas/ideabooks/${id}`);
    } catch (error) {
      console.error('Error updating ideabook:', error);
    }
  };

  const handleCancel = () => {
    navigate(`/ideas/ideabooks/${id}`);
  };

  if (loading) {
    return (
      <Layout showSidebar>
        <Container maxWidth="md">
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
            <CircularProgress />
          </Box>
        </Container>
      </Layout>
    );
  }

  if (error || !ideabook) {
    return (
      <Layout showSidebar>
        <Container maxWidth="md">
          <Box sx={{ py: 8 }}>
            <Typography color="error" align="center">
              {error || 'Ideabook not found'}
            </Typography>
          </Box>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout showSidebar>
      <Container maxWidth="md">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Edit Ideabook
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Update your ideabook details and organization.
          </Typography>
        </Box>
        <IdeabookForm
          initialData={ideabook}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Container>
    </Layout>
  );
};

export default IdeabookEditPage;