import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Divider,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import storyService from '../../services/storyService';

// Define the Story interface
interface Story {
  _id: string; // MongoDB ObjectId
  title: string;
  image: string;
  excerpt: string;
  content: string;
  category: string;
  tags: string[];
  author: {
    name: string;
    avatar: string;
    bio: string;
  };
  date: string;
  readTime: string;
  featured: boolean;
  relatedStories?: Array<{
    id: string;
    title: string;
    image: string;
    excerpt: string;
    category: string;
  }>;
}

// Categories for stories
const CATEGORIES = [
  'Renovation',
  'Kitchen',
  'Bathroom',
  'Landscaping',
  'Smart Home',
  'Sustainability',
  'Small Spaces',
  'Interior Design',
  'Exterior',
];

/**
 * StoryManagement component for admin to manage stories/articles
 */
const StoryManagement: React.FC = () => {
  const navigate = useNavigate();

  // State for stories list and pagination
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // State for story editing
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const [isNewStory, setIsNewStory] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  // State for tag management
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    fetchStories();
  }, []);

  const fetchStories = async () => {
    try {
      setLoading(true);
      const response = await storyService.getStories();

      if (response.success) {
        setStories(response.data);
      } else {
        setError('Failed to load stories');
        setStories([]);
      }
    } catch (err: any) {
      console.error('Error fetching stories:', err);
      setError(err.message || 'Failed to load stories');
      setStories([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewStory = (story: Story) => {
    // Navigate to story details page in the frontend
    window.open(`/resources/stories/${story._id}`, '_blank');
  };

  const handleAddStory = () => {
    // Create a new empty story template with a temporary ID for UI state
    const newStory: Story = {
      _id: 'temp-' + Date.now(), // Temporary ID for UI state only
      title: '',
      image: '',
      excerpt: '',
      content: '',
      category: '',
      tags: [],
      author: {
        name: '',
        avatar: '',
        bio: '',
      },
      date: new Date().toISOString().split('T')[0],
      readTime: '5 min read',
      featured: false,
    };

    setSelectedStory(newStory);
    setIsNewStory(true);
    setEditDialogOpen(true);
  };

  const handleEditStory = (story: Story) => {
    setSelectedStory(story);
    setIsNewStory(false);
    setEditDialogOpen(true);
  };

  const handleDeleteStory = (story: Story) => {
    setSelectedStory(story);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedStory(null);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedStory(null);
  };

  const handleAddTag = () => {
    if (!selectedStory || !newTag.trim()) return;

    if (!selectedStory.tags.includes(newTag.trim())) {
      setSelectedStory({
        ...selectedStory,
        tags: [...selectedStory.tags, newTag.trim()],
      });
    }

    setNewTag('');
  };

  const handleRemoveTag = (tagToRemove: string) => {
    if (!selectedStory) return;

    setSelectedStory({
      ...selectedStory,
      tags: selectedStory.tags.filter((tag) => tag !== tagToRemove),
    });
  };

  const handleSaveStory = async () => {
    if (!selectedStory) return;

    // Validate required fields
    if (!selectedStory.title.trim()) {
      setError('Title is required');
      return;
    }

    if (!selectedStory.excerpt.trim()) {
      setError('Excerpt is required');
      return;
    }

    if (!selectedStory.content.trim()) {
      setError('Content is required');
      return;
    }

    if (!selectedStory.category.trim()) {
      setError('Category is required');
      return;
    }

    try {
      setProcessing(true);
      setError(null);

      let response: { success: boolean; data: Story };

      if (isNewStory) {
        try {
          // Remove the _id field for new stories
          const { _id, ...storyDataWithoutId } = selectedStory;
          response = await storyService.createStory(storyDataWithoutId);
          if (response.success) {
            setStories((prevStories) => [...prevStories, response.data]);
          } else {
            setError('Failed to create story');
          }
        } catch (err: any) {
          console.error('Error creating story:', err);
          setError(err.message || 'Failed to create story');
        }
      } else {
        try {
          response = await storyService.updateStory(selectedStory._id, selectedStory);
          if (response.success) {
            setStories((prevStories) =>
              prevStories.map((story) => (story._id === selectedStory._id ? response.data : story))
            );
          } else {
            setError('Failed to update story');
          }
        } catch (err: any) {
          console.error('Error updating story:', err);
          setError(err.message || 'Failed to update story');
        }
      }

      setSuccess(`Story ${isNewStory ? 'created' : 'updated'} successfully`);
      setEditDialogOpen(false);
      setSelectedStory(null);
    } catch (err: any) {
      console.error('Error saving story:', err);
      setError(err.message || `Failed to ${isNewStory ? 'create' : 'update'} story`);
    } finally {
      setProcessing(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (!selectedStory) return;

    try {
      setProcessing(true);

      try {
        const response = await storyService.deleteStory(selectedStory._id);
        if (response.success) {
          setStories((prevStories) => prevStories.filter((story) => story._id !== selectedStory._id));
        } else {
          setError('Failed to delete story');
        }
      } catch (err: any) {
        console.error('Error deleting story:', err);
        setError(err.message || 'Failed to delete story');
      }

      setSuccess('Story deleted successfully');
      setDeleteDialogOpen(false);
      setSelectedStory(null);
    } catch (err: any) {
      console.error('Error deleting story:', err);
      setError(err.message || 'Failed to delete story');
    } finally {
      setProcessing(false);
    }
  };

  // Function to render the story table
  function renderStoryTable() {
    if (loading && stories.length === 0) {
      return (
        <Box sx={{ py: 4, textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="body2" sx={{ mt: 2 }}>
            Loading stories...
          </Typography>
        </Box>
      );
    }

    if (stories.length === 0) {
      return (
        <Box sx={{ py: 4, textAlign: 'center' }}>
          <Typography variant="body1">No stories found</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddStory}
            sx={{ mt: 2 }}
          >
            Add Your First Story
          </Button>
        </Box>
      );
    }

    // Apply pagination
    const paginatedData = stories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((story) => (
                <TableRow key={story._id}>
                  <TableCell>{story.title}</TableCell>
                  <TableCell>{story.category}</TableCell>
                  <TableCell>{story.author.name}</TableCell>
                  <TableCell>{new Date(story.date).toLocaleDateString()}</TableCell>
                  <TableCell>
                    {story.featured && <Chip label="Featured" color="primary" size="small" />}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => handleViewStory(story)} sx={{ mr: 1 }}>
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleEditStory(story)} sx={{ mr: 1 }}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" color="error" onClick={() => handleDeleteStory(story)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={stories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Story Management
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Add, edit, and manage stories in the resources section
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess(null)}
        message={success}
      />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddStory}>
          Add New Story
        </Button>
      </Box>

      <Paper sx={{ mb: 4 }}>{renderStoryTable()}</Paper>

      {/* Edit Story Dialog */}
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} maxWidth="lg" fullWidth>
        {selectedStory && (
          <>
            <DialogTitle>
              <Typography variant="h6">
                {isNewStory ? 'Add New Story' : `Edit Story: ${selectedStory.title}`}
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={3}>
                {/* Basic Information */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Basic Information
                  </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <TextField
                    label="Title"
                    fullWidth
                    required
                    value={selectedStory.title}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        title: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth required>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                      labelId="category-label"
                      value={selectedStory.category}
                      label="Category"
                      onChange={(e) =>
                        setSelectedStory({
                          ...selectedStory,
                          category: e.target.value,
                        })
                      }
                    >
                      {CATEGORIES.map((category) => (
                        <MenuItem key={category} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Image URL"
                    fullWidth
                    required
                    value={selectedStory.image}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        image: e.target.value,
                      })
                    }
                    helperText="Enter a URL for the story's featured image"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Excerpt"
                    fullWidth
                    required
                    multiline
                    rows={2}
                    value={selectedStory.excerpt}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        excerpt: e.target.value,
                      })
                    }
                    helperText="A brief summary of the story (displayed in listings)"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Read Time"
                    fullWidth
                    value={selectedStory.readTime}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        readTime: e.target.value,
                      })
                    }
                    helperText="e.g., '5 min read'"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Publication Date"
                    type="date"
                    fullWidth
                    value={selectedStory.date}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        date: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="featured-label">Featured</InputLabel>
                    <Select
                      labelId="featured-label"
                      value={selectedStory.featured ? 'true' : 'false'}
                      label="Featured"
                      onChange={(e) =>
                        setSelectedStory({
                          ...selectedStory,
                          featured: e.target.value === 'true',
                        })
                      }
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Author Information */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Author Information
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Author Name"
                    fullWidth
                    required
                    value={selectedStory.author.name}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        author: {
                          ...selectedStory.author,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Author Avatar URL"
                    fullWidth
                    value={selectedStory.author.avatar}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        author: {
                          ...selectedStory.author,
                          avatar: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Author Bio"
                    fullWidth
                    multiline
                    rows={2}
                    value={selectedStory.author.bio}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        author: {
                          ...selectedStory.author,
                          bio: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Tags */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Tags
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', mb: 2 }}>
                    <TextField
                      fullWidth
                      label="Add Tag"
                      value={newTag}
                      onChange={(e) => setNewTag(e.target.value)}
                      sx={{ mr: 1 }}
                    />
                    <Button variant="contained" onClick={handleAddTag} disabled={!newTag.trim()}>
                      Add
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {selectedStory.tags.map((tag) => (
                      <Chip key={tag} label={tag} onDelete={() => handleRemoveTag(tag)} />
                    ))}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Content */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Story Content (HTML)
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Content"
                    fullWidth
                    required
                    multiline
                    rows={15}
                    value={selectedStory.content}
                    onChange={(e) =>
                      setSelectedStory({
                        ...selectedStory,
                        content: e.target.value,
                      })
                    }
                    helperText="Enter the full story content in HTML format"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog}>Cancel</Button>
              <Button
                onClick={handleSaveStory}
                variant="contained"
                startIcon={processing ? <CircularProgress size={20} /> : <SaveIcon />}
                disabled={processing}
              >
                {processing ? 'Saving...' : 'Save Story'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete the story "{selectedStory?.title}"?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            disabled={processing}
          >
            {processing ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StoryManagement;
