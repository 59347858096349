import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import targetPreferenceService from '../../services/targetPreferenceService';
import { Lead, ProviderResponse, CreateLeadDTO, SubmitResponseDTO } from '../../types/lead';
import { CustomerPreference } from '../../types/preference';
import { RootState } from '../store';

interface LeadState {
  leads: Lead[];
  responses: ProviderResponse[];
  matchedProviders: any[]; // Provider profiles
  loading: boolean;
  error: string | null;
}

const initialState: LeadState = {
  leads: [],
  responses: [],
  matchedProviders: [],
  loading: false,
  error: null,
};

// Create a new lead
export const createLead = createAsyncThunk<Lead, CreateLeadDTO, { rejectValue: string }>(
  'lead/createLead',
  async (data, { rejectWithValue }) => {
    try {
      console.log('Creating lead with data:', data);

      // Ensure service ID is present and valid
      if (!data.service) {
        console.error('Service ID is missing in createLead action');
        return rejectWithValue('Service ID is required');
      }

      const response = await axios.post('/leads', data);
      console.log('Lead created successfully:', response.data);
      return response.data;
    } catch (error: any) {
      console.error('Error creating lead:', error.response?.data || error);
      return rejectWithValue(error.response?.data?.message || 'Failed to create lead');
    }
  }
);

// Get all leads for the current user
export const getLeads = createAsyncThunk<Lead[], void, { rejectValue: string }>(
  'lead/getLeads',
  async (_, { rejectWithValue }) => {
    try {
      // Use the correct endpoint for service providers
      // Note: axios baseURL is already set to '/api' in services/axios.ts
      const response = await axios.get('/leads/provider');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch leads');
    }
  }
);

// Submit customer preferences for a lead
export const submitPreferences = createAsyncThunk<
  Lead,
  { leadId: string; preferences: CustomerPreference[] },
  { rejectValue: string }
>('lead/submitPreferences', async ({ leadId, preferences }, { rejectWithValue }) => {
  try {
    const response = await targetPreferenceService.submitCustomerPreferences(leadId, preferences);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || 'Failed to submit preferences');
  }
});

// Get matched providers for a lead
export const getMatchedProviders = createAsyncThunk<any[], string, { rejectValue: string }>(
  'lead/getMatchedProviders',
  async (leadId, { rejectWithValue }) => {
    try {
      const response = await targetPreferenceService.getMatchedProviders(leadId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch matched providers');
    }
  }
);

// Submit a provider response to a lead
export const submitResponse = createAsyncThunk<
  ProviderResponse,
  { leadId: string; data: SubmitResponseDTO },
  { rejectValue: string }
>('lead/submitResponse', async ({ leadId, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/leads/response/${leadId}`, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || 'Failed to submit response');
  }
});

// Update a provider response
export const updateResponse = createAsyncThunk<
  ProviderResponse,
  { responseId: string; data: Partial<SubmitResponseDTO> },
  { rejectValue: string }
>('lead/updateResponse', async ({ responseId, data }, { rejectWithValue }) => {
  try {
    const response = await axios.put(`/leads/response/${responseId}`, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || 'Failed to update response');
  }
});

const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Lead
      .addCase(createLead.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLead.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.loading = false;
        state.leads.push(action.payload);
      })
      .addCase(createLead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create lead';
      })

      // Get Leads
      .addCase(getLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLeads.fulfilled, (state, action: PayloadAction<Lead[]>) => {
        state.loading = false;
        state.leads = action.payload;
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch leads';
      })

      // Submit Preferences
      .addCase(submitPreferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitPreferences.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.loading = false;
        const index = state.leads.findIndex((l) => l._id === action.payload._id);
        if (index !== -1) {
          state.leads[index] = action.payload;
        }
      })
      .addCase(submitPreferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to submit preferences';
      })

      // Get Matched Providers
      .addCase(getMatchedProviders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMatchedProviders.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.matchedProviders = action.payload;
      })
      .addCase(getMatchedProviders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch matched providers';
      })

      // Submit Response
      .addCase(submitResponse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitResponse.fulfilled, (state, action: PayloadAction<ProviderResponse>) => {
        state.loading = false;
        state.responses.push(action.payload);
      })
      .addCase(submitResponse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to submit response';
      })

      // Update Response
      .addCase(updateResponse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateResponse.fulfilled, (state, action: PayloadAction<ProviderResponse>) => {
        state.loading = false;
        const index = state.responses.findIndex((r) => r._id === action.payload._id);
        if (index !== -1) {
          state.responses[index] = action.payload;
        }
      })
      .addCase(updateResponse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update response';
      });
  },
});

// Selectors
export const selectLeads = (state: RootState) => state.lead.leads;
export const selectResponses = (state: RootState) => state.lead.responses;
export const selectMatchedProviders = (state: RootState) => state.lead.matchedProviders;
export const selectLeadLoading = (state: RootState) => state.lead.loading;
export const selectLeadError = (state: RootState) => state.lead.error;

export const { clearError } = leadSlice.actions;

export default leadSlice.reducer;
