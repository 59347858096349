import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
  useTheme,
  useMediaQuery,
  Avatar,
  Chip,
  CardActionArea,
  Tabs,
  Tab,
  Divider,
  Skeleton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VerifiedIcon from '@mui/icons-material/Verified';
import imageLoader from '../../utils/imageLoader';

// Project data structure
interface Project {
  id: string;
  title: string;
  description: string;
  image: string;
  category: string;
  provider: {
    id: string;
    name: string;
    image: string;
    verified: boolean;
  };
  location: string;
  budget: string;
  completionDate: string;
  beforeImage: string;
  imageAttribution?: {
    name: string;
    link: string;
  };
  beforeImageAttribution?: {
    name: string;
    link: string;
  };
}

// Project categories
const projectCategories = [
  { id: 'all', label: 'All Projects' },
  { id: 'kitchen', label: 'Kitchen' },
  { id: 'bathroom', label: 'Bathroom' },
  { id: 'living-room', label: 'Living Room' },
  { id: 'outdoor', label: 'Outdoor' },
];

// Fallback image in case API fails
const FALLBACK_IMAGE = 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

// Initial project data - images will be loaded from Pexels API
const featuredProjectsData: Project[] = [
  {
    id: 'p1',
    title: 'Modern Kitchen Renovation',
    description: 'Complete kitchen remodel with custom cabinetry, quartz countertops, and new appliances.',
    image: '',
    category: 'kitchen',
    provider: {
      id: 'pro1',
      name: 'John Carpenter',
      image: 'https://randomuser.me/api/portraits/men/32.jpg',
      verified: true,
    },
    location: 'San Francisco, CA',
    budget: '$25,000 - $30,000',
    completionDate: '2024-12-15',
    beforeImage: '',
  },
  {
    id: 'p2',
    title: 'Luxury Bathroom Remodel',
    description: 'Master bathroom renovation featuring a walk-in shower, freestanding tub, and heated floors.',
    image: '',
    category: 'bathroom',
    provider: {
      id: 'pro2',
      name: 'Sarah Plumber',
      image: 'https://randomuser.me/api/portraits/women/44.jpg',
      verified: true,
    },
    location: 'Los Angeles, CA',
    budget: '$18,000 - $22,000',
    completionDate: '2024-11-20',
    beforeImage: '',
  },
  {
    id: 'p3',
    title: 'Contemporary Living Room Design',
    description: 'Complete living room transformation with new flooring, custom built-ins, and modern furnishings.',
    image: '',
    category: 'living-room',
    provider: {
      id: 'pro3',
      name: 'Lisa Designer',
      image: 'https://randomuser.me/api/portraits/women/65.jpg',
      verified: false,
    },
    location: 'Seattle, WA',
    budget: '$15,000 - $18,000',
    completionDate: '2024-10-05',
    beforeImage: '',
  },
  {
    id: 'p4',
    title: 'Backyard Patio & Landscaping',
    description: 'Outdoor living space with custom patio, fire pit, and professional landscaping.',
    image: '',
    category: 'outdoor',
    provider: {
      id: 'pro4',
      name: 'David Landscaper',
      image: 'https://randomuser.me/api/portraits/men/75.jpg',
      verified: true,
    },
    location: 'Denver, CO',
    budget: '$20,000 - $25,000',
    completionDate: '2024-09-15',
    beforeImage: '',
  },
];

interface FeaturedProjectsProps {
  maxProjects?: number;
}

const FeaturedProjects: React.FC<FeaturedProjectsProps> = ({ maxProjects = 4 }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState('all');
  const [featuredProjects, setFeaturedProjects] = useState<Project[]>(featuredProjectsData);
  const [loading, setLoading] = useState<boolean>(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  // Filter projects based on active tab
  const filteredProjects = activeTab === 'all'
    ? featuredProjects
    : featuredProjects.filter(project => project.category === activeTab);

  // Limit the number of projects shown
  const displayedProjects = filteredProjects.slice(0, maxProjects);

  // Fetch project images from Pexels API
  useEffect(() => {
    const fetchProjectImages = async () => {
      try {
        // Create a copy of the projects data to update
        const updatedProjects = [...featuredProjects];

        // Fetch images for each project in parallel
        const promises = updatedProjects.map(async (project, index) => {
          try {
            // Get "after" images for the project
            const afterImageResult = await imageLoader.getRandomPexelsImage(`${project.category} ${project.title} after`, {
              orientation: 'landscape',
              size: 'large',
            });

            // Get "before" images for the project
            const beforeImageResult = await imageLoader.getRandomPexelsImage(`${project.category} ${project.title} before renovation`, {
              orientation: 'landscape',
              size: 'large',
            });

            // Update the project with the image data
            if (afterImageResult) {
              updatedProjects[index] = {
                ...project,
                image: afterImageResult.url,
                imageAttribution: {
                  name: afterImageResult.photographer,
                  link: afterImageResult.photographerUrl,
                },
              };
            } else {
              updatedProjects[index] = {
                ...project,
                image: FALLBACK_IMAGE,
                imageAttribution: {
                  name: 'Pexels',
                  link: 'https://www.pexels.com',
                },
              };
            }

            // Update the before image if available
            if (beforeImageResult) {
              updatedProjects[index] = {
                ...updatedProjects[index],
                beforeImage: beforeImageResult.url,
                beforeImageAttribution: {
                  name: beforeImageResult.photographer,
                  link: beforeImageResult.photographerUrl,
                },
              };
            } else {
              updatedProjects[index] = {
                ...updatedProjects[index],
                beforeImage: FALLBACK_IMAGE,
                beforeImageAttribution: {
                  name: 'Pexels',
                  link: 'https://www.pexels.com',
                },
              };
            }
          } catch (error) {
            console.error(`Error fetching images for ${project.id}:`, error);
            // Use fallback image on error
            updatedProjects[index] = {
              ...project,
              image: FALLBACK_IMAGE,
              beforeImage: FALLBACK_IMAGE,
              imageAttribution: {
                name: 'Pexels',
                link: 'https://www.pexels.com',
              },
              beforeImageAttribution: {
                name: 'Pexels',
                link: 'https://www.pexels.com',
              },
            };
          }
        });

        // Wait for all image fetches to complete
        await Promise.all(promises);

        // Update state with the new project data
        setFeaturedProjects(updatedProjects);
      } catch (error) {
        console.error('Error fetching project images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectImages();
  }, []);

  return (
    <Box sx={{ py: 8, bgcolor: theme.palette.background.paper }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4, flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
          <Typography variant="h3" component="h2" gutterBottom={isMobile}>
            Featured Projects
          </Typography>
          <Button
            variant="text"
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate('/ideas/projects')}
          >
            View All Projects
          </Button>
        </Box>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{ mb: 4 }}
        >
          {projectCategories.map(category => (
            <Tab
              key={category.id}
              label={category.label}
              value={category.id}
            />
          ))}
        </Tabs>

        <Grid container spacing={3}>
          {displayedProjects.map((project) => (
            <Grid item xs={12} sm={6} md={6} key={project.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                  },
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <CardActionArea onClick={() => navigate(`/ideas/projects/${project.id}`)}>
                  <Box sx={{ position: 'relative' }}>
                    {loading ? (
                      <Skeleton variant="rectangular" height={300} animation="wave" />
                    ) : (
                      <CardMedia
                        component="img"
                        height="300"
                        image={project.image || FALLBACK_IMAGE}
                        alt={project.title}
                        sx={{
                          transition: 'transform 0.5s ease, opacity 0.3s ease, filter 0.3s ease',
                          opacity: loading ? 0.7 : 1,
                          filter: loading ? 'blur(5px)' : 'none',
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      <Chip
                        label="After"
                        color="primary"
                        size="small"
                        sx={{ bgcolor: 'rgba(33, 150, 243, 0.9)' }}
                      />
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        p: 2,
                      }}
                    >
                      <Typography variant="h6" component="div">
                        {project.title}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <Avatar
                          src={project.provider.image}
                          alt={project.provider.name}
                          sx={{ width: 32, height: 32, mr: 1 }}
                        />
                        <Typography variant="body2">
                          {project.provider.name}
                          {project.provider.verified && (
                            <VerifiedIcon
                              sx={{
                                ml: 0.5,
                                width: 16,
                                height: 16,
                                color: 'primary.main',
                                verticalAlign: 'text-bottom',
                              }}
                            />
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardActionArea>
                <CardContent>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {project.description}
                  </Typography>
                  {!loading && project.imageAttribution && (
                    <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 1 }}>
                      Photo by{' '}
                      <a
                        href={project.imageAttribution.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                      >
                        {project.imageAttribution.name}
                      </a>
                    </Typography>
                  )}
                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Location
                      </Typography>
                      <Typography variant="body2" component="div">
                        {project.location}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Budget
                      </Typography>
                      <Typography variant="body2" component="div">
                        {project.budget}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {displayedProjects.length === 0 && (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h6" color="text.secondary">
              No projects found in this category.
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default FeaturedProjects;