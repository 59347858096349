import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  InputBase,
  IconButton,
  Button,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popper,
  Grow,
  ClickAwayListener,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SearchParams } from '../../types/search';
import useSearchSuggestions from '../../hooks/useSearchSuggestions';

// Styled components
const SearchWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 800,
  margin: '0 auto',
  borderRadius: 16,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    boxShadow: '0 12px 40px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-2px)',
  },
}));

const SearchInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  borderRadius: 12,
  border: `1.5px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 1.5),
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.2s ease',
  '&:focus-within': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 3px ${theme.palette.primary.light}30`,
  },
  '&:hover': {
    borderColor: theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.2)'
      : 'rgba(255, 255, 255, 0.2)',
  },
}));

const SearchControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}));

const SuggestionList = styled(List)(({ theme }) => ({
  padding: 0,
  maxHeight: 320,
  overflow: 'auto',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 12,
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
  border: '1px solid rgba(0, 0, 0, 0.05)',
}));

const SuggestionItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1, 2),
  transition: 'all 0.15s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.light + '20',
  },
}));

// Emirates list for location dropdown
const EMIRATES = [
  'Abu Dhabi',
  'Dubai',
  'Sharjah',
  'Ajman',
  'Umm Al Quwain',
  'Ras Al Khaimah',
  'Fujairah',
];

// Popular services for initial suggestions
const POPULAR_SERVICES = [
  'Architecture Engineering',
  'Villa Renovation',
  'Interior Design',
  'Landscaping',
  'Interior & Exterior Painting',
  'Electrical',
  'Heating, Ventilation & Air Conditioning',
  'Plumbing',
  'Home Automation',
  'Swimming pool repair',
  'Home Cleaning',
  'Property Management',
];

interface ProfessionalSearchBarProps {
  onSearch: (params: SearchParams) => void;
  initialService?: string;
  initialLocation?: string;
}

const ProfessionalSearchBar: React.FC<ProfessionalSearchBarProps> = ({
  onSearch,
  initialService = '',
  initialLocation = '',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State for input values
  const [service, setService] = useState(initialService);
  const [location, setLocation] = useState(initialLocation);

  // State for suggestions
  const [serviceSuggestions, setServiceSuggestions] = useState<string[]>([]);
  const [showServiceSuggestions, setShowServiceSuggestions] = useState(false);
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);

  // State for loading indicators
  const [loadingServiceSuggestions, setLoadingServiceSuggestions] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  // References for popper positioning
  const [serviceAnchorEl, setServiceAnchorEl] = useState<null | HTMLElement>(null);
  const [locationAnchorEl, setLocationAnchorEl] = useState<null | HTMLElement>(null);

  // Filter emirates based on input
  const filteredEmirates = EMIRATES.filter((emirate) =>
    emirate.toLowerCase().includes(location.toLowerCase())
  );

  // Use the search suggestions hook
  const { suggestions: serviceSuggestionsFromAPI, loading: loadingServiceSuggestionsFromAPI } = useSearchSuggestions(service, {
    minLength: 2,
    debounceMs: 300,
    maxSuggestions: 10,
  });

  // Update service suggestions when API results change
  useEffect(() => {
    if (service.length < 2) {
      // Show popular services when query is too short
      setServiceSuggestions(POPULAR_SERVICES);
    } else if (serviceSuggestionsFromAPI.length > 0) {
      // Use API suggestions when available
      setServiceSuggestions(serviceSuggestionsFromAPI);
      setLoadingServiceSuggestions(false);
    } else if (loadingServiceSuggestionsFromAPI) {
      // Show loading state
      setLoadingServiceSuggestions(true);
    } else {
      // Fallback to filtered popular services if API returns no results
      setServiceSuggestions(
        POPULAR_SERVICES.filter((s) => s.toLowerCase().includes(service.toLowerCase()))
      );
      setLoadingServiceSuggestions(false);
    }
  }, [service, serviceSuggestionsFromAPI, loadingServiceSuggestionsFromAPI]);

  // Handle service input change
  const handleServiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setService(value);
    setServiceAnchorEl(event.currentTarget);

    // Show suggestions when typing
    setShowServiceSuggestions(true);

    // The hook will handle fetching suggestions
    // We just need to show the loading state
    if (value.length >= 2) {
      setLoadingServiceSuggestions(true);
    } else {
      setServiceSuggestions(POPULAR_SERVICES);
    }
  };

  // Handle location input change
  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLocation(value);
    setLocationAnchorEl(event.currentTarget);

    if (value.length > 0) {
      setShowLocationSuggestions(true);
    } else {
      setShowLocationSuggestions(true);
    }
  };

  // Handle service suggestion selection
  const handleServiceSelect = (suggestion: string) => {
    setService(suggestion);
    setShowServiceSuggestions(false);
  };

  // Handle location suggestion selection
  const handleLocationSelect = (emirate: string) => {
    setLocation(emirate);
    setShowLocationSuggestions(false);
  };

  // Handle search submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoadingSearch(true);

    onSearch({
      query: service,
      location: location
        ? {
            address: location,
            coordinates: [0, 0], // Placeholder coordinates
            radius: 50, // Default radius
          }
        : undefined,
    });

    setLoadingSearch(false);
    setShowServiceSuggestions(false);
    setShowLocationSuggestions(false);
  };

  // Close suggestions when clicking outside
  const handleClickAway = () => {
    setShowServiceSuggestions(false);
    setShowLocationSuggestions(false);
  };

  // Focus handlers to show suggestions
  const handleServiceFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setServiceAnchorEl(event.currentTarget);
    setShowServiceSuggestions(true);
  };

  const handleLocationFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setLocationAnchorEl(event.currentTarget);
    setShowLocationSuggestions(true);
  };

  // Initialize with popular services
  useEffect(() => {
    setServiceSuggestions(POPULAR_SERVICES);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <form onSubmit={handleSubmit}>
        <SearchWrapper elevation={3}>
          <SearchControls>
            {/* Service Input */}
            <SearchInputWrapper sx={{ flex: 2 }}>
              <SearchIcon sx={{ mr: 1.5, color: theme.palette.primary.main, opacity: 0.7 }} />
              <InputBase
                placeholder="What service do you need?"
                fullWidth
                value={service}
                onChange={handleServiceChange}
                onFocus={handleServiceFocus}
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                  '& input::placeholder': {
                    color: theme.palette.text.secondary,
                    opacity: 0.7,
                  },
                  py: 0.5,
                }}
                endAdornment={
                  loadingServiceSuggestions ?
                    <CircularProgress size={20} sx={{ mr: 1, color: theme.palette.primary.main }} /> :
                    service ?
                      <IconButton
                        size="small"
                        onClick={() => setService('')}
                        sx={{ mr: 0.5, color: theme.palette.text.secondary }}
                      >
                        <Box sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>×</Box>
                      </IconButton> :
                      null
                }
              />

              {/* Service Suggestions Popper */}
              <Popper
                open={showServiceSuggestions}
                anchorEl={serviceAnchorEl}
                placement="bottom-start"
                transition
                style={{ width: serviceAnchorEl?.offsetWidth, zIndex: 1300 }}
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: 'top left' }}>
                    <SuggestionList>
                      {serviceSuggestions.length > 0 ? (
                        <>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: theme.palette.text.secondary,
                                    fontWeight: 600,
                                    fontSize: '0.85rem',
                                  }}
                                >
                                  {service.length > 0 ? 'Suggestions' : 'Popular Services'}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <Divider />
                          {serviceSuggestions.map((suggestion, index) => (
                            <SuggestionItem
                              key={`service-${index}`}
                              onClick={() => handleServiceSelect(suggestion)}
                            >
                              <SearchIcon
                                fontSize="small"
                                sx={{
                                  mr: 1.5,
                                  color: theme.palette.primary.main,
                                  opacity: 0.8,
                                }}
                              />
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontWeight: 500,
                                      color: theme.palette.text.primary,
                                    }}
                                  >
                                    {suggestion}
                                  </Typography>
                                }
                              />
                            </SuggestionItem>
                          ))}
                        </>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontStyle: 'italic',
                                  color: theme.palette.text.secondary,
                                }}
                              >
                                No suggestions found
                              </Typography>
                            }
                          />
                        </ListItem>
                      )}
                    </SuggestionList>
                  </Grow>
                )}
              </Popper>
            </SearchInputWrapper>

            {/* Location Input */}
            <SearchInputWrapper sx={{ flex: 1 }}>
              <LocationOnIcon sx={{ mr: 1.5, color: theme.palette.primary.main, opacity: 0.7 }} />
              <InputBase
                placeholder="Emirate"
                fullWidth
                value={location}
                onChange={handleLocationChange}
                onFocus={handleLocationFocus}
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                  '& input::placeholder': {
                    color: theme.palette.text.secondary,
                    opacity: 0.7,
                  },
                  py: 0.5,
                }}
                endAdornment={
                  location ?
                    <IconButton
                      size="small"
                      onClick={() => setLocation('')}
                      sx={{ mr: 0.5, color: theme.palette.text.secondary }}
                    >
                      <Box sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>×</Box>
                    </IconButton> :
                    null
                }
              />

              {/* Location Suggestions Popper */}
              <Popper
                open={showLocationSuggestions}
                anchorEl={locationAnchorEl}
                placement="bottom-start"
                transition
                style={{ width: locationAnchorEl?.offsetWidth, zIndex: 1300 }}
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: 'top left' }}>
                    <SuggestionList>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: theme.palette.text.secondary,
                                fontWeight: 600,
                                fontSize: '0.85rem',
                              }}
                            >
                              Emirates
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider />
                      {filteredEmirates.length > 0 ? (
                        filteredEmirates.map((emirate, index) => (
                          <SuggestionItem
                            key={`location-${index}`}
                            onClick={() => handleLocationSelect(emirate)}
                          >
                            <LocationOnIcon
                              fontSize="small"
                              sx={{
                                mr: 1.5,
                                color: theme.palette.primary.main,
                                opacity: 0.8,
                              }}
                            />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: 500,
                                    color: theme.palette.text.primary,
                                  }}
                                >
                                  {emirate}
                                </Typography>
                              }
                            />
                          </SuggestionItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontStyle: 'italic',
                                  color: theme.palette.text.secondary,
                                }}
                              >
                                No emirates found
                              </Typography>
                            }
                          />
                        </ListItem>
                      )}
                    </SuggestionList>
                  </Grow>
                )}
              </Popper>
            </SearchInputWrapper>

            {/* Search Button */}
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={loadingSearch}
              sx={{
                px: 3,
                borderRadius: 2.5,
                height: 'auto',
                width: isMobile ? '100%' : 'auto',
                fontWeight: 600,
                fontSize: '1rem',
                textTransform: 'none',
                boxShadow: '0 4px 12px rgba(33, 150, 243, 0.25)',
                transition: 'all 0.2s ease',
                '&:hover': {
                  boxShadow: '0 6px 16px rgba(33, 150, 243, 0.35)',
                  transform: 'translateY(-2px)',
                },
              }}
              startIcon={loadingSearch ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
            >
              Get Started
            </Button>
          </SearchControls>
        </SearchWrapper>
      </form>
    </ClickAwayListener>
  );
};

export default ProfessionalSearchBar;
