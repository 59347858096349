import { useForm, FieldValues } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useEffect, useState } from 'react';
import { OnboardingStepId } from '../types/onboarding/shared';
import { useSaveStep } from './api/useOnboardingApi';
import { useOnboarding } from '../contexts/OnboardingContext';

/**
 * Options for the useStepForm hook
 */
interface UseStepFormOptions {
  /**
   * Whether to enable autosave functionality
   */
  autosave?: boolean;

  /**
   * Delay in milliseconds before triggering autosave
   */
  autosaveDelay?: number;
}

/**
 * Custom hook for managing form state in onboarding steps
 *
 * @param stepId The ID of the current onboarding step
 * @param schema Zod validation schema for the form data
 * @param defaultValues Default values for the form
 * @param options Additional options for form behavior
 */
export function useStepForm<T extends FieldValues>(
  stepId: OnboardingStepId,
  schema: z.ZodType<T>,
  defaultValues: Partial<T>,
  options?: UseStepFormOptions
) {
  const { setStepFormDirty } = useOnboarding();
  const saveStepMutation = useSaveStep();
  const [lastSaved, setLastSaved] = useState<Date | null>(null);

  const formMethods = useForm<T>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues as any,
    mode: 'onChange',
  });

  const { formState, watch, reset, getValues } = formMethods;
  const { isDirty } = formState;

  // Set form dirty state when values change
  useEffect(() => {
    setStepFormDirty(isDirty);
  }, [isDirty, setStepFormDirty]);

  // Setup autosave if enabled
  useEffect(() => {
    if (!options?.autosave) return;

    const delay = options.autosaveDelay || 3000;
    let timeoutId: NodeJS.Timeout;

    const subscription = watch(() => {
      // Only autosave if the form is valid and dirty
      if (formState.isValid && isDirty) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          const currentValues = getValues();
          saveStepMutation.mutate(
            { stepId, data: currentValues },
            {
              onSuccess: () => {
                setLastSaved(new Date());
                reset(currentValues, { keepValues: true });
              },
            }
          );
        }, delay);
      }
    });

    return () => {
      subscription.unsubscribe();
      clearTimeout(timeoutId);
    };
  }, [watch, formState, stepId, options, saveStepMutation, reset, getValues, isDirty]);

  /**
   * Save form data to the server
   */
  const saveForm = async (data: T): Promise<boolean> => {
    try {
      await saveStepMutation.mutateAsync({ stepId, data });
      setLastSaved(new Date());
      reset(data, { keepValues: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    ...formMethods,
    saveForm,
    isSaving: saveStepMutation.isLoading,
    saveError: saveStepMutation.error as Error | null,
    isDirty,
    lastSaved,
  };
}