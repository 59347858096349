import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  Divider,
  Paper,
} from '@mui/material';
import { CartItem } from './CartItem';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

export const CartList: React.FC = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const navigate = useNavigate();

  if (cart.items.length === 0) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Your cart is empty
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Add some products to your cart to get started
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/marketplace')}
          >
            Continue Shopping
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Shopping Cart
      </Typography>

      <Box sx={{ display: 'flex', gap: 3 }}>
        {/* Cart Items */}
        <Box sx={{ flexGrow: 1 }}>
          {cart.items.map((item) => (
            <CartItem key={item.product._id} item={item} />
          ))}
        </Box>

        {/* Order Summary */}
        <Paper sx={{ p: 3, width: 300, alignSelf: 'flex-start', position: 'sticky', top: 20 }}>
          <Typography variant="h6" gutterBottom>
            Order Summary
          </Typography>

          <Box sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body1">Subtotal</Typography>
              <Typography variant="body1">${cart.subtotal.toFixed(2)}</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body1">Tax</Typography>
              <Typography variant="body1">${cart.tax.toFixed(2)}</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Total</Typography>
              <Typography variant="h6" color="primary">
                ${cart.total.toFixed(2)}
              </Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={() => navigate('/marketplace/checkout')}
          >
            Proceed to Checkout
          </Button>

          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => navigate('/marketplace')}
            sx={{ mt: 2 }}
          >
            Continue Shopping
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};