import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, CircularProgress, Alert, Paper, Grid, Card, CardMedia, CardContent, CardActionArea, Chip, Rating } from '@mui/material';
import { LocationOn as LocationIcon } from '@mui/icons-material';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/store';
import { useNavigate } from 'react-router-dom';

interface FavoritedPro {
  _id: string;
  name: string;
  profession: string;
  location: string;
  rating: number;
  reviewCount: number;
  imageUrl: string;
  services: string[];
}

const ProListPage: React.FC = () => {
  const navigate = useNavigate();
  const [favoritedPros, setFavoritedPros] = useState<FavoritedPro[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchFavoritedPros = async () => {
      try {
        setLoading(true);
        // TODO: Replace with actual API call to get user's favorited pros
        // For now, using mock data
        const mockPros: FavoritedPro[] = [
          {
            _id: '1',
            name: 'John Smith',
            profession: 'Interior Designer',
            location: 'Dubai, UAE',
            rating: 4.8,
            reviewCount: 24,
            imageUrl: 'https://images.pexels.com/photos/5668859/pexels-photo-5668859.jpeg?auto=compress&cs=tinysrgb&w=300',
            services: ['Interior Design', 'Space Planning', 'Color Consultation'],
          },
          {
            _id: '2',
            name: 'Sarah Johnson',
            profession: 'Architect',
            location: 'Abu Dhabi, UAE',
            rating: 4.9,
            reviewCount: 36,
            imageUrl: 'https://images.pexels.com/photos/7654586/pexels-photo-7654586.jpeg?auto=compress&cs=tinysrgb&w=300',
            services: ['Architectural Design', 'Home Renovation', 'Project Management'],
          },
          {
            _id: '3',
            name: 'Ahmed Hassan',
            profession: 'Contractor',
            location: 'Sharjah, UAE',
            rating: 4.7,
            reviewCount: 18,
            imageUrl: 'https://images.pexels.com/photos/8961251/pexels-photo-8961251.jpeg?auto=compress&cs=tinysrgb&w=300',
            services: ['General Contracting', 'Kitchen Remodeling', 'Bathroom Renovation'],
          },
        ];

        // Simulate API delay
        setTimeout(() => {
          setFavoritedPros(mockPros);
          setLoading(false);
        }, 1000);

      } catch (err) {
        console.error('Error fetching favorited pros:', err);
        setError('Failed to load your favorited professionals. Please try again.');
        setLoading(false);
      }
    };

    fetchFavoritedPros();
  }, []);

  const handleProClick = (proId: string) => {
    navigate(`/pro/${proId}`);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="pro-finder" />
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Your Favorited Professionals
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          View and connect with home improvement professionals you've saved.
        </Typography>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      ) : favoritedPros.length === 0 ? (
        <Paper sx={{ p: 4, textAlign: 'center', my: 4 }}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            You haven't favorited any professionals yet
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Browse professionals and click the heart icon to add them to your favorites.
          </Typography>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          {favoritedPros.map((pro) => (
            <Grid item xs={12} sm={6} md={4} key={pro._id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardActionArea onClick={() => handleProClick(pro._id)}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={pro.imageUrl}
                    alt={pro.name}
                  />
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {pro.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      {pro.profession}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <LocationIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                      <Typography variant="body2" color="text.secondary">
                        {pro.location}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Rating value={pro.rating} precision={0.1} size="small" readOnly />
                      <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                        ({pro.reviewCount} reviews)
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {pro.services.map((service, index) => (
                        <Chip key={index} label={service} size="small" />
                      ))}
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default ProListPage;