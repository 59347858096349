import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import messageReducer from './slices/messageSlice';
import providerReducer from './slices/providerSlice';
import reviewReducer from './slices/reviewSlice';
import ideabookReducer from './slices/ideabookSlice';
import productReducer from './slices/productSlice';
import cartReducer from './slices/cartSlice';
import sellerReducer from './slices/sellerSlice';
import searchReducer from './slices/searchSlice';
import leadReducer from './slices/leadSlice';
import serviceReducer from './slices/serviceSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    messages: messageReducer,
    provider: providerReducer,
    reviews: reviewReducer,
    ideabooks: ideabookReducer,
    products: productReducer,
    cart: cartReducer,
    seller: sellerReducer,
    search: searchReducer,
    lead: leadReducer,
    service: serviceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['reviews/submit/pending'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: [
          'reviews.reviews',
          'provider.services',
          'messages.conversations',
          'ideabooks.ideabooks',
          'ideabooks.currentIdeabook',
          'lead.leads',
          'lead.responses',
          'lead.matchedProviders',
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
