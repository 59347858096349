import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  Button,
  Divider,
  Chip,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { RootState } from '../../redux/store';
import { clearCart } from '../../redux/slices/cartSlice';

interface OrderInfo {
  orderId: string;
  items: any[];
  subtotal: number;
  tax: number;
  total: number;
  shippingInfo: {
    name: string;
    email: string;
    phone: string;
    street: string;
    city: string;
    emirate: string;
    country: string;
  };
  paymentMethod: string;
  date: string;
}

export const CheckoutConfirmationPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  // Get order info from localStorage
  const orderInfoStr = localStorage.getItem('orderInfo');
  const orderInfo: OrderInfo | null = orderInfoStr ? JSON.parse(orderInfoStr) : null;

  useEffect(() => {
    // Clear the cart after successful order
    if (orderInfo) {
      dispatch(clearCart());
    }

    // Clean up localStorage
    return () => {
      localStorage.removeItem('shippingInfo');
      localStorage.removeItem('orderInfo');
      if (!isAuthenticated) {
        localStorage.removeItem('guestEmail');
      }
    };
  }, [dispatch, isAuthenticated, orderInfo]);

  // If no order info, redirect to home page
  if (!orderInfo) {
    return <Navigate to="/" replace />;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <CheckCircleIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
        <Typography variant="h4" gutterBottom>
          Order Confirmed!
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Thank you for your purchase. Your order has been received and is being processed.
        </Typography>
      </Box>

      <Paper sx={{ p: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5">
            Order #{orderInfo.orderId}
          </Typography>
          <Chip
            label="Order Placed"
            color="success"
            variant="outlined"
          />
        </Box>

        <Typography variant="body2" color="text.secondary" gutterBottom>
          Placed on {formatDate(orderInfo.date)}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              Shipping Address
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {orderInfo.shippingInfo.name}<br />
              {orderInfo.shippingInfo.street}<br />
              {orderInfo.shippingInfo.city}, {orderInfo.shippingInfo.emirate}<br />
              {orderInfo.shippingInfo.country}<br />
              {orderInfo.shippingInfo.phone}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              Payment Method
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {orderInfo.paymentMethod === 'card' ? 'Credit/Debit Card' : 'Cash on Delivery'}
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Order Updates
              </Typography>
              <Typography variant="body2" color="text.secondary">
                You'll receive order updates at:<br />
                {orderInfo.shippingInfo.email}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>

        <List disablePadding>
          {orderInfo.items.map((item) => (
            <ListItem key={item.product._id} sx={{ py: 1, px: 0 }}>
              <ListItemText
                primary={item.product.name}
                secondary={`Quantity: ${item.quantity}`}
              />
              <Typography variant="body2">
                ${(item.product.price.amount * item.quantity).toFixed(2)}
              </Typography>
            </ListItem>
          ))}

          <Divider sx={{ my: 2 }} />

          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Subtotal" />
            <Typography variant="body2">
              ${orderInfo.subtotal.toFixed(2)}
            </Typography>
          </ListItem>

          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Shipping" />
            <Typography variant="body2">
              Free
            </Typography>
          </ListItem>

          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Tax (5% VAT)" />
            <Typography variant="body2">
              ${orderInfo.tax.toFixed(2)}
            </Typography>
          </ListItem>

          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Total" />
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
              ${orderInfo.total.toFixed(2)}
            </Typography>
          </ListItem>
        </List>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          sx={{
            borderColor: '#e0e0e0',
            color: '#333',
            '&:hover': {
              borderColor: '#333',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          Continue Shopping
        </Button>

        {isAuthenticated && (
          <Button
            variant="contained"
            onClick={() => navigate('/profile/orders')}
            sx={{
              backgroundColor: '#333',
              '&:hover': {
                backgroundColor: '#000',
              },
            }}
          >
            View My Orders
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default CheckoutConfirmationPage;