import React, { useState } from 'react';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface CollapsibleSectionProps {
  title: string;
  summary?: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  summary,
  children,
  defaultExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          py: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant="h6">{title}</Typography>
        {!expanded && summary && (
          <Typography variant="body2" color="text.secondary" sx={{ mr: 2, flex: 1, textAlign: 'right' }}>
            {summary}
          </Typography>
        )}
        <IconButton size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={expanded}>
        <Box sx={{ pt: 2 }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapsibleSection;