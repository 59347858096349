import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Chip,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Pagination,
  Divider,
  SelectChangeEvent,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import PageTitle from '../../../components/common/PageTitle';
import storyService from '../../../services/storyService';

// Categories for filtering
const CATEGORIES = [
  'All Categories',
  'Renovation',
  'Kitchen',
  'Bathroom',
  'Landscaping',
  'Smart Home',
  'Sustainability',
  'Small Spaces',
  'Interior Design',
  'Exterior',
];

// Story interface
interface Story {
  _id: string; // MongoDB ObjectId
  title: string;
  image: string;
  excerpt: string;
  category: string;
  author: {
    name: string;
    avatar: string;
  };
  date: string;
  readTime: string;
  featured: boolean;
}

/**
 * StoriesIndex component displays a list of home improvement stories
 * with filtering and pagination capabilities
 */
const StoriesIndex: React.FC = () => {
  // State for filtering and pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('All Categories');
  const [page, setPage] = useState(1);
  const storiesPerPage = 4;
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        setLoading(true);
        const response = await storyService.getStories({
          category: category !== 'All Categories' ? category : undefined,
          page: 1,
          limit: 50 // Get a reasonable number of stories
        });
        
        if (response.success) {
          setStories(response.data);
        } else {
          setError('Failed to load stories');
          setStories([]);
        }
      } catch (err: any) {
        console.error('Error fetching stories:', err);
        setError(err.message || 'Failed to load stories');
        setStories([]);
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, [category]);

  // Filter stories based on search term and category
  const filteredStories = stories.filter((story) => {
    const matchesSearch = story.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         story.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = category === 'All Categories' || story.category === category;
    return matchesSearch && matchesCategory;
  });

  // Get stories for current page
  const indexOfLastStory = page * storiesPerPage;
  const indexOfFirstStory = indexOfLastStory - storiesPerPage;
  const currentStories = filteredStories.slice(indexOfFirstStory, indexOfLastStory);

  // Featured stories (for the top section)
  const featuredStories = stories.filter(story => story.featured);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to first page when search changes
  };

  // Handle category filter change
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    setPage(1); // Reset to first page when category changes
  };

  // Handle pagination change
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (loading && stories.length === 0) {
    return (
      <Box sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>Loading stories...</Typography>
      </Box>
    );
  }

  if (error && stories.length === 0) {
    return (
      <Box sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
        <Typography variant="body1">
          Unable to load stories. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <PageTitle
        title="Home Improvement Stories"
        description="Explore home improvement stories, case studies, and trends from UAE homeowners and professionals."
        keywords="home improvement stories, UAE renovation, Dubai home, Abu Dhabi renovation, case studies"
        locale="en_AE"
      />

      {/* Featured Stories Section */}
      {featuredStories.length > 0 && (
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Featured Stories
          </Typography>
          <Grid container spacing={3}>
            {featuredStories.map((story) => (
              <Grid item xs={12} md={6} key={story._id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="240"
                    image={story.image}
                    alt={story.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Chip
                      label={story.category}
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{ mb: 1 }}
                    />
                    <Typography variant="h5" component="h3" gutterBottom>
                      {story.title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {story.excerpt}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="caption" color="text.secondary">
                        By {story.author.name} • {new Date(story.date).toLocaleDateString('en-AE')} • {story.readTime}
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        component={Link}
                        to={`/resources/stories/${story._id}`}
                      >
                        Read More
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Divider sx={{ mb: 4 }} />

      {/* Filters Section */}
      <Box sx={{ mb: 4, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
        <TextField
          label="Search Stories"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="category-filter-label">Category</InputLabel>
          <Select
            labelId="category-filter-label"
            id="category-filter"
            value={category}
            label="Category"
            onChange={handleCategoryChange}
            startAdornment={
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            }
          >
            {CATEGORIES.map((cat) => (
              <MenuItem key={cat} value={cat}>{cat}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Stories List */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          All Stories {category !== 'All Categories' && `in ${category}`}
        </Typography>

        {currentStories.length === 0 ? (
          <Box sx={{ py: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              No stories found matching your criteria.
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setSearchTerm('');
                setCategory('All Categories');
              }}
              sx={{ mt: 2 }}
            >
              Clear Filters
            </Button>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {currentStories.map((story) => (
              <Grid item xs={12} sm={6} md={6} key={story._id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={story.image}
                    alt={story.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Chip
                      label={story.category}
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{ mb: 1 }}
                    />
                    <Typography variant="h6" component="h3" gutterBottom>
                      {story.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph>
                      {story.excerpt}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="caption" color="text.secondary">
                        By {story.author.name} • {new Date(story.date).toLocaleDateString('en-AE')} • {story.readTime}
                      </Typography>
                      <Button
                        size="small"
                        component={Link}
                        to={`/resources/stories/${story._id}`}
                      >
                        Read More
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Pagination */}
      {filteredStories.length > storiesPerPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
          <Pagination
            count={Math.ceil(filteredStories.length / storiesPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </>
  );
};

export default StoriesIndex;