import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  Grid,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PinterestIcon from '@mui/icons-material/Pinterest';

export interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  itemToShare: {
    id: string;
    title: string;
    image: string;
  } | null;
}

const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  onClose,
  itemToShare,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  if (!itemToShare) return null;

  const shareUrl = `${window.location.origin}/ideas/projects/${itemToShare.id}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSocialShare = (platform: string) => {
    let shareLink = '';
    const encodedUrl = encodeURIComponent(shareUrl);
    const encodedTitle = encodeURIComponent(`Check out this ${itemToShare.title} on Homezy!`);

    switch (platform) {
      case 'facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
        break;
      case 'pinterest':
        shareLink = `https://pinterest.com/pin/create/button/?url=${encodedUrl}&media=${encodeURIComponent(itemToShare.image)}&description=${encodedTitle}`;
        break;
      case 'whatsapp':
        shareLink = `https://api.whatsapp.com/send?text=${encodedTitle} ${encodedUrl}`;
        break;
      case 'email':
        shareLink = `mailto:?subject=${encodedTitle}&body=I thought you might like this: ${encodedUrl}`;
        break;
      default:
        return;
    }

    window.open(shareLink, '_blank');
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="share-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="share-dialog-title">
          Share this idea
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              {itemToShare.title}
            </Typography>
            <Box
              component="img"
              src={itemToShare.image}
              alt={itemToShare.title}
              sx={{
                width: '100%',
                height: 200,
                objectFit: 'cover',
                borderRadius: 1,
                mb: 2,
              }}
            />
          </Box>

          <Typography variant="subtitle1" gutterBottom>
            Share via
          </Typography>

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item>
              <IconButton
                color="primary"
                onClick={() => handleSocialShare('facebook')}
                aria-label="share on Facebook"
                sx={{
                  bgcolor: '#f0f2f5',
                  '&:hover': { bgcolor: '#e4e6eb' },
                }}
              >
                <FacebookIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                onClick={() => handleSocialShare('twitter')}
                aria-label="share on Twitter"
                sx={{
                  bgcolor: '#f0f2f5',
                  '&:hover': { bgcolor: '#e4e6eb' },
                }}
              >
                <TwitterIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="error"
                onClick={() => handleSocialShare('pinterest')}
                aria-label="share on Pinterest"
                sx={{
                  bgcolor: '#f0f2f5',
                  '&:hover': { bgcolor: '#e4e6eb' },
                }}
              >
                <PinterestIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="success"
                onClick={() => handleSocialShare('whatsapp')}
                aria-label="share on WhatsApp"
                sx={{
                  bgcolor: '#f0f2f5',
                  '&:hover': { bgcolor: '#e4e6eb' },
                }}
              >
                <WhatsAppIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="info"
                onClick={() => handleSocialShare('email')}
                aria-label="share via Email"
                sx={{
                  bgcolor: '#f0f2f5',
                  '&:hover': { bgcolor: '#e4e6eb' },
                }}
              >
                <EmailIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Typography variant="subtitle1" gutterBottom>
            Copy link
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              variant="outlined"
              value={shareUrl}
              InputProps={{
                readOnly: true,
                sx: { pr: 0 },
              }}
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCopyLink}
              startIcon={<ContentCopyIcon />}
              sx={{ ml: -1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              Copy
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareModal;