import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import {
  Box,
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  Card,
  CardMedia,
  CardContent,
  Chip,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  ArrowForward as ArrowForwardIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import { ProductList } from '../../components/marketplace/ProductList';
import { ProductCard } from '../../components/marketplace/ProductCard';
import { fetchProducts } from '../../redux/slices/productSlice';
import { Link } from 'react-router-dom';
import imageLoader from '../../utils/imageLoader';

// Featured category data
const featuredCategories = [
  {
    id: 'home-maintenance',
    name: 'Home Maintenance',
    image: 'https://images.pexels.com/photos/4108807/pexels-photo-4108807.jpeg?auto=compress&cs=tinysrgb&w=600',
  },
  {
    id: 'cleaning-services',
    name: 'Cleaning Services',
    image: 'https://images.pexels.com/photos/4108715/pexels-photo-4108715.jpeg?auto=compress&cs=tinysrgb&w=600',
  },
  {
    id: 'landscaping',
    name: 'Landscaping',
    image: 'https://images.pexels.com/photos/589/garden-grass-meadow-green.jpg?auto=compress&cs=tinysrgb&w=600',
  },
  {
    id: 'home-improvement',
    name: 'Home Improvement',
    image: 'https://images.pexels.com/photos/1669754/pexels-photo-1669754.jpeg?auto=compress&cs=tinysrgb&w=600',
  },
];

export const MarketplacePage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch<AppDispatch>();
  const { items: products, loading } = useSelector((state: RootState) => state.products);

  const [featuredProducts, setFeaturedProducts] = useState<any[]>([]);
  const [newArrivals, setNewArrivals] = useState<any[]>([]);
  const [bestSellers, setBestSellers] = useState<any[]>([]);
  const [categoryImages, setCategoryImages] = useState<Record<string, string>>({});
  const [heroImage, setHeroImage] = useState<string>('https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  const [loadingImages, setLoadingImages] = useState<boolean>(true);

  // Store Pexels images for products that don't have images
  const [productImages, setProductImages] = useState<Record<string, string>>({});
  const [loadingProductImages, setLoadingProductImages] = useState<boolean>(false);

  // Add logging to help debug navigation issues
  console.log('MarketplacePage rendered, current path:', window.location.pathname);

  // Check if Pexels API key is set
  console.log('Pexels API key is set:', !!import.meta.env.VITE_PEXELS_API_KEY);

  // We're now using direct Pexels URLs for categories and hero image
  useEffect(() => {
    // Set category images directly from the featuredCategories array
    const images: Record<string, string> = {};
    featuredCategories.forEach(category => {
      images[category.id] = category.image;
    });

    setCategoryImages(images);
    setLoadingImages(false);

    console.log('Using direct Pexels URLs for categories and hero image');
  }, []);

  useEffect(() => {
    // Fetch all products
    dispatch(fetchProducts());
  }, [dispatch]);

  // Process products when they're loaded
  useEffect(() => {
    console.log('Products updated:', products.length);

    if (products && products.length > 0) {
      console.log('Processing products for display sections');

      // Simulate featured products
      setFeaturedProducts(products.slice(0, 4));

      // Simulate new arrivals (sort by date)
      const sortedByDate = [...products].sort((a, b) => {
        const dateA = new Date(b.createdAt).getTime();
        const dateB = new Date(a.createdAt).getTime();
        return dateA - dateB;
      });
      setNewArrivals(sortedByDate.slice(0, 4));

      // Simulate bestsellers (sort by rating)
      const sortedByRating = [...products].sort((a, b) => {
        return b.seller.rating - a.seller.rating;
      });
      setBestSellers(sortedByRating.slice(0, 4));

      console.log('Bestsellers set:', sortedByRating.slice(0, 4).map(p => p.name));
      console.log('New arrivals set:', sortedByDate.slice(0, 4).map(p => p.name));
    }
  }, [products]);

  // Load product images from Pexels for products that don't have images
  useEffect(() => {
    const loadProductImages = async () => {
      // Only proceed if we have products to process
      if (bestSellers.length === 0 && newArrivals.length === 0) {
        console.log('No products to load images for yet');
        return;
      }

      // Create a unique list of products (avoid duplicates between bestsellers and new arrivals)
      const productMap = new Map();
      [...bestSellers, ...newArrivals].forEach(product => {
        if (product && product._id) {
          productMap.set(product._id, product);
        }
      });

      const uniqueProducts = Array.from(productMap.values());
      console.log('Loading product images for', uniqueProducts.length, 'unique products');

      setLoadingProductImages(true);
      const images: Record<string, string> = {};

      // Process each product
      for (const product of uniqueProducts) {
        // Skip products that already have images
        if (product?.images?.[0]?.url) {
          console.log(`Product ${product.name} already has an image, skipping`);
          continue;
        }

        // Skip products we've already processed
        if (productImages[product._id]) {
          console.log(`Product ${product.name} already has a Pexels image, skipping`);
          continue;
        }

        try {
          console.log(`Fetching Pexels image for product: ${product.name}`);

          // Following the example from README-pexels.md
          const result = await imageLoader.getRandomPexelsImage(
            `${product.name} ${product.category || 'furniture'}`,
            {
              orientation: 'square',
              size: 'medium',
            }
          );

          if (result) {
            console.log(`✅ Found image for ${product.name}: ${result.url}`);
            images[product._id] = result.url;
          } else {
            console.log(`❌ No image found for ${product.name}, trying fallback`);

            // Try with a more generic search as fallback
            const fallbackResult = await imageLoader.getRandomPexelsImage(
              product.category || 'furniture',
              { orientation: 'square' }
            );

            if (fallbackResult) {
              console.log(`✅ Found fallback image for ${product.name}: ${fallbackResult.url}`);
              images[product._id] = fallbackResult.url;
            }
          }
        } catch (error) {
          console.error(`Error loading image for ${product.name}:`, error);
        }
      }

      if (Object.keys(images).length > 0) {
        console.log(`Loaded ${Object.keys(images).length} new images from Pexels`);
        setProductImages(prev => ({ ...prev, ...images }));
      } else {
        console.log('No new images were loaded from Pexels');
      }

      setLoadingProductImages(false);
    };

    loadProductImages();
  }, [bestSellers, newArrivals, productImages]);

  // Hero section with featured product
  const renderHero = () => {
    const featuredProduct = featuredProducts[0];

    return (
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${heroImage})`,
          height: 400,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  position: 'relative',
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                  Transform Your Home
                </Typography>
                <Typography variant="h5" color="inherit" paragraph>
                  Discover premium home improvement products from trusted sellers
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/marketplace"
                >
                  Shop Now
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    );
  };

  // Shop by category section
  const renderCategories = () => {
    return (
      <Box sx={{ my: 8 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Shop by Department
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {featuredCategories.map((category) => (
            <Grid item xs={6} sm={4} md={2} key={category.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    boxShadow: 6,
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
                component={Link}
                to={`/marketplace/category/${category.id}`}
                style={{ textDecoration: 'none' }}
              >
                <CardMedia
                  component="img"
                  height={isMobile ? "100" : "140"}
                  image={categoryImages[category.id] || category.image}
                  alt={category.name}
                  onError={(e) => {
                    // If image fails to load, replace with a fallback
                    const target = e.target as HTMLImageElement;
                    target.onerror = null; // Prevent infinite loop
                    target.src = `https://images.pexels.com/photos/1571458/pexels-photo-1571458.jpeg?auto=compress&cs=tinysrgb&w=600`;
                    console.log(`Using fallback image for category: ${category.name}`);
                  }}
                  sx={{
                    opacity: loadingImages ? 0.7 : 1,
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                />
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Typography gutterBottom variant="h6" component="h3">
                    {category.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  // Bestsellers section
  const renderBestsellers = () => {
    if (loading || bestSellers.length === 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={{ my: 8 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h2">
            Bestsellers
          </Typography>
          <Button
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to="/marketplace/bestsellers"
          >
            View All
          </Button>
        </Box>
        <Grid container spacing={3}>
          {bestSellers.map((product) => (
            <Grid item key={product._id} xs={12} sm={6} md={3}>
              <Box sx={{ position: 'relative' }}>
                <ProductCard product={product} />
                <Chip
                  icon={<StarIcon />}
                  label="Bestseller"
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    fontWeight: 'bold',
                    zIndex: 2,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  // New arrivals section
  const renderNewArrivals = () => {
    if (loading || newArrivals.length === 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={{ my: 8 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h2">
            New Arrivals
          </Typography>
          <Button
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to="/marketplace/new-arrivals"
          >
            View All
          </Button>
        </Box>
        <Grid container spacing={3}>
          {newArrivals.map((product) => (
            <Grid item key={product._id} xs={12} sm={6} md={3}>
              <Box sx={{ position: 'relative' }}>
                <ProductCard product={product} />
                <Chip
                  label="New"
                  color="secondary"
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    fontWeight: 'bold',
                    zIndex: 2,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      {/* Hero Section */}
      {renderHero()}

      <Container maxWidth="lg">
        {/* Shop by Category */}
        {renderCategories()}

        {/* Bestsellers */}
        {renderBestsellers()}

        {/* New Arrivals */}
        {renderNewArrivals()}

        {/* All Products with Filters */}
        <Box sx={{ my: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            All Products
          </Typography>
          <ProductList />
        </Box>
      </Container>
    </Box>
  );
};