import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProtectedRoute from '../common/ProtectedRoute';
import { RootState } from '../../redux/store';

interface ProviderRouteProps {
  children: React.ReactNode;
}

const ProviderRoute: React.FC<ProviderRouteProps> = ({ children }) => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);

  // First check if user is authenticated using ProtectedRoute
  return (
    <ProtectedRoute>
      {/* Then check if authenticated user is a provider */}
      {user?.role === 'provider' ? (
        children
      ) : (
        <Navigate
          to="/login"
          state={{ from: location }}
          replace
        />
      )}
    </ProtectedRoute>
  );
};

export default ProviderRoute;