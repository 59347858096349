import axios from 'axios';
import store from '../redux/store';
import { logout } from '../redux/slices/authSlice';
import authService from './authService';

// Always use a relative URL to ensure requests go through our proxy
const baseURL = '/api';
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 15000, // 15 seconds timeout
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    // Always set the Authorization header if token exists
    if (token) {
      // Make sure we're working with a mutable headers object
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Flag to prevent multiple refresh token requests
let isRefreshing = false;
let failedQueue: { resolve: (value?: unknown) => void; reject: (reason?: any) => void }[] = [];

const processQueue = (error: any | null, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      // Network error
      return Promise.reject(new Error('Network error. Please check your connection.'));
    }

    const { status, config: originalRequest } = error.response;

    // Skip token refresh for these cases
    const skipRefresh =
      originalRequest.url?.includes('/auth/refresh-token') || // Refresh token request itself
      originalRequest.url?.includes('/public') || // Public endpoints
      originalRequest.url?.includes('/auth/login') || // Login requests
      originalRequest.headers?.['X-Background-Request'] === 'true' || // Background requests
      status !== 401; // Non-401 errors

    if (skipRefresh) {
      if (status === 401) {
        // Clear auth data and dispatch logout action for auth-related endpoints
        if (!originalRequest.url?.includes('/public')) {
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          store.dispatch(logout());
        }
      }
      return Promise.reject(error);
    }

    // Handle 401 errors with token refresh
    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('Your session has expired. Please log in again.');
        }

        // Attempt to refresh the token
        const response = await authService.refreshToken(refreshToken);
        const { token } = response;

        // Update the token in localStorage and axios headers
        localStorage.setItem('token', token);
        originalRequest.headers.Authorization = `Bearer ${token}`;

        // Process any queued requests
        processQueue(null, token);

        // Retry the original request
        return axios(originalRequest);
      } catch (refreshError) {
        // Token refresh failed
        processQueue(refreshError, null);

        // Store current path for redirect after login
        localStorage.setItem('authRedirectUrl', window.location.pathname);

        // Clear auth data and dispatch logout
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        store.dispatch(logout());

        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // Queue failed requests while token is being refreshed
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    })
      .then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      })
      .catch((err) => Promise.reject(err));
  }
);

export default axiosInstance;
