import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Alert,
  CircularProgress,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProviderServices,
  fetchServiceCategories,
  updateServiceCapabilities,
  selectFilteredServices,
  selectCategories,
  ProviderCapability,
  Service,
} from '../../redux/slices/serviceSlice';
import { RootState, AppDispatch } from '../../redux/store';
import ServiceWithCapabilitiesForm from '../../components/pro/ServiceWithCapabilitiesForm';
import ServiceList from '../../components/pro/ServiceList';

const ServiceManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const services = useSelector(selectFilteredServices);
  const categories = useSelector(selectCategories);
  const { loading, error } = useSelector((state: RootState) => state.service);
  const { isAuthenticated, token, user } = useSelector((state: RootState) => state.auth);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      try {
        await Promise.all([
          dispatch(fetchProviderServices()).unwrap(),
          dispatch(fetchServiceCategories()).unwrap(),
        ]);
      } catch (error) {
        console.error('Failed to load initial data:', error);
      } finally {
        setInitialLoading(false);
      }
    };

    loadData();
  }, [dispatch]);

  // Log services whenever they change
  useEffect(() => {
    console.log('Current services:', services);
  }, [services]);

  const handleCreateService = async (serviceData: Service, capabilities: ProviderCapability[]) => {
    console.log('handleCreateService called with serviceData:', serviceData);
    console.log('handleCreateService called with capabilities:', capabilities);

    // Check if user is authenticated
    if (!isAuthenticated || !token) {
      console.error('Authentication error: Not authenticated or missing token');
      return;
    }

    if (user?.role !== 'provider') {
      console.error('Authentication error: User is not a provider');
      return;
    }

    try {
      // The service is already created in the ServiceWithCapabilitiesForm component
      // We just need to update the capabilities and refresh the service list
      if (serviceData && serviceData._id) {
        console.log('Service has valid ID:', serviceData._id);

        console.log('Dispatching updateServiceCapabilities with:', {
          serviceId: serviceData._id,
          capabilities,
        });

        console.log('Using token:', token ? token.substring(0, 10) + '...' : 'null');

        // Ensure the token is in localStorage
        if (token && !localStorage.getItem('token')) {
          localStorage.setItem('token', token);
          console.log('Token restored to localStorage');
        }

        const result = await dispatch(
          updateServiceCapabilities({
            serviceId: serviceData._id,
            capabilities,
          })
        ).unwrap();

        console.log('updateServiceCapabilities result:', result);

        // Refresh the service list to show the new service
        console.log('Refreshing service list');
        dispatch(fetchProviderServices());
      } else {
        console.error('Service data is missing ID:', serviceData);
      }

      setCreateDialogOpen(false);
    } catch (error: any) {
      console.error('Failed to update service capabilities:', error);
      if (error.response) {
        console.error('Error response:', error.response.status, error.response.data);
      }
    }
  };

  const handleRefresh = () => {
    dispatch(fetchProviderServices());
    dispatch(fetchServiceCategories());
  };

  if (initialLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Services
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Manage your service listings and offerings
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip title="Refresh">
            <IconButton onClick={handleRefresh} disabled={loading}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              console.log('Add New Service button clicked');
              setCreateDialogOpen(true);
              console.log('createDialogOpen set to true:', true);
            }}
          >
            Add New Service
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Service Stats */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="h4" color="primary">
              {services.length}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Total Services
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="h4" color="success.main">
              {services.filter((s) => s.isActive).length}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Active Services
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="h4" color="text.secondary">
              {categories.length}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Available Categories
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Paper sx={{ p: 3 }}>
        {services.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <Typography variant="h6" gutterBottom>
              No Services Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              Start by adding your first service listing
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                console.log('Empty state Add New Service button clicked');
                setCreateDialogOpen(true);
                console.log('createDialogOpen set to true:', true);
              }}
            >
              Add New Service
            </Button>
          </Box>
        ) : (
          <ServiceList services={services} categories={categories} />
        )}
      </Paper>

      <Dialog
        open={createDialogOpen}
        onClose={() => {
          console.log('Dialog close event triggered');
          setCreateDialogOpen(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Create New Service
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Fill in the details to create a new service listing
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {(() => {
              console.log('Rendering ServiceWithCapabilitiesForm with categories:', categories);
              return null;
            })()}
            <ServiceWithCapabilitiesForm
              categories={categories}
              onComplete={handleCreateService}
              onCancel={() => {
                console.log('ServiceWithCapabilitiesForm cancel triggered');
                setCreateDialogOpen(false);
              }}
            />
            {(() => {
              console.log('ServiceWithCapabilitiesForm rendered');
              return null;
            })()}
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ServiceManagement;
