import React from 'react';
import { Helmet } from 'react-helmet-async';

interface StructuredDataProps {
  data: Record<string, any>;
}

/**
 * StructuredData component for adding JSON-LD structured data to pages
 * This helps search engines better understand the content and improves SEO
 *
 * @param {Object} data - The structured data object to be converted to JSON-LD
 * @returns A Helmet component with the JSON-LD script
 *
 * @example
 * // Article structured data
 * <StructuredData
 *   data={{
 *     "@context": "https://schema.org",
 *     "@type": "Article",
 *     "headline": "How to Maintain Your AC System",
 *     "image": "https://example.com/image.jpg",
 *     "author": {
 *       "@type": "Person",
 *       "name": "John Doe"
 *     },
 *     "publisher": {
 *       "@type": "Organization",
 *       "name": "Homezy",
 *       "logo": {
 *         "@type": "ImageObject",
 *         "url": "https://homezy.co/logo.png"
 *       }
 *     },
 *     "datePublished": "2025-03-15",
 *     "dateModified": "2025-03-20"
 *   }}
 * />
 */
const StructuredData: React.FC<StructuredDataProps> = ({ data }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(data)}</script>
    </Helmet>
  );
};

/**
 * Creates structured data for an article
 *
 * @param {Object} article - The article data
 * @returns Structured data object for an article
 */
export const createArticleStructuredData = (article: {
  title: string;
  description: string;
  image: string;
  authorName: string;
  datePublished: string;
  dateModified?: string;
  url: string;
}) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: article.title,
    description: article.description,
    image: article.image,
    author: {
      '@type': 'Person',
      name: article.authorName,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Homezy',
      logo: {
        '@type': 'ImageObject',
        url: 'https://homezy.co/logo512.png',
      },
    },
    datePublished: article.datePublished,
    dateModified: article.dateModified || article.datePublished,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': article.url,
    },
  };
};

/**
 * Creates structured data for a how-to guide
 *
 * @param {Object} guide - The guide data
 * @returns Structured data object for a how-to guide
 */
export const createHowToStructuredData = (guide: {
  title: string;
  description: string;
  image: string;
  totalTime: string;
  estimatedCost: {
    currency: string;
    value: string;
  };
  supply?: string[];
  tool?: string[];
  step: Array<{
    name: string;
    text: string;
    image?: string;
  }>;
}) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'HowTo',
    name: guide.title,
    description: guide.description,
    image: guide.image,
    totalTime: guide.totalTime,
    estimatedCost: {
      '@type': 'MonetaryAmount',
      currency: guide.estimatedCost.currency,
      value: guide.estimatedCost.value,
    },
    ...(guide.supply && {
      supply: guide.supply.map((item) => ({
        '@type': 'HowToSupply',
        name: item,
      })),
    }),
    ...(guide.tool && {
      tool: guide.tool.map((item) => ({
        '@type': 'HowToTool',
        name: item,
      })),
    }),
    step: guide.step.map((step) => ({
      '@type': 'HowToStep',
      name: step.name,
      text: step.text,
      ...(step.image && { image: step.image }),
    })),
  };
};

/**
 * Creates structured data for a local business
 *
 * @param {Object} business - The business data
 * @returns Structured data object for a local business
 */
export const createLocalBusinessStructuredData = (business: {
  name: string;
  description: string;
  image: string;
  telephone: string;
  address: {
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
  };
  geo: {
    latitude: number;
    longitude: number;
  };
  url: string;
  openingHours: string[];
  priceRange: string;
}) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: business.name,
    description: business.description,
    image: business.image,
    telephone: business.telephone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: business.address.streetAddress,
      addressLocality: business.address.addressLocality,
      addressRegion: business.address.addressRegion,
      postalCode: business.address.postalCode,
      addressCountry: business.address.addressCountry,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: business.geo.latitude,
      longitude: business.geo.longitude,
    },
    url: business.url,
    openingHoursSpecification: business.openingHours.map((hours) => ({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: hours.split(' ')[0],
      opens: hours.split(' ')[1].split('-')[0],
      closes: hours.split(' ')[1].split('-')[1],
    })),
    priceRange: business.priceRange,
  };
};

/**
 * Creates structured data for a product
 *
 * @param {Object} product - The product data
 * @returns Structured data object for a product
 */
export const createProductStructuredData = (product: {
  name: string;
  description: string;
  image: string[];
  brand: string;
  offers: {
    price: number;
    priceCurrency: string;
    availability: string;
    url: string;
  };
  review?: Array<{
    author: string;
    datePublished: string;
    reviewBody: string;
    ratingValue: number;
  }>;
  aggregateRating?: {
    ratingValue: number;
    reviewCount: number;
  };
}) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.name,
    description: product.description,
    image: product.image,
    brand: {
      '@type': 'Brand',
      name: product.brand,
    },
    offers: {
      '@type': 'Offer',
      price: product.offers.price,
      priceCurrency: product.offers.priceCurrency,
      availability: product.offers.availability,
      url: product.offers.url,
    },
    ...(product.review && {
      review: product.review.map((review) => ({
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: review.author,
        },
        datePublished: review.datePublished,
        reviewBody: review.reviewBody,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.ratingValue,
        },
      })),
    }),
    ...(product.aggregateRating && {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: product.aggregateRating.ratingValue,
        reviewCount: product.aggregateRating.reviewCount,
      },
    }),
  };
};

/**
 * Creates structured data for a FAQ page
 *
 * @param {Object} faq - The FAQ data
 * @returns Structured data object for a FAQ page
 */
export const createFAQStructuredData = (faq: {
  questions: Array<{
    question: string;
    answer: string;
  }>;
}) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faq.questions.map((item) => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer,
      },
    })),
  };
};

export default StructuredData;
