import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Chip,
  Stack,
  CardActionArea,
  Skeleton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IService } from '../../types/models';
import imageLoader from '../../utils/imageLoader';

interface ServiceCardProps {
  service: IService;
}

interface ImageData {
  url: string;
  photographer: string;
  photographerUrl: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
  const navigate = useNavigate();
  const [imageData, setImageData] = useState<ImageData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        // Use the service title and category to find a relevant image
        const searchTerm = `${service.title} ${service.category?.name || ''} home service interior`;
        console.log(`Fetching image for service: ${searchTerm}`);

        const result = await imageLoader.getRandomPexelsImage(searchTerm, {
          orientation: 'landscape',
          size: 'medium',
        });

        if (result) {
          console.log(`Found image for service ${service.title}:`, result.url);
          setImageData({
            url: result.url,
            photographer: result.photographer,
            photographerUrl: result.photographerUrl,
          });
        } else {
          console.log(`No image found for service ${service.title}`);
        }
      } catch (error) {
        console.error(`Error fetching image for ${service.title}:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [service.title, service.category?.name]);

  const handleClick = () => {
    navigate(`/services/${service._id}`);
  };

  return (
    <Card sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 2,
      overflow: 'hidden',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      transition: 'transform 0.2s, box-shadow 0.2s',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
      },
    }}>
      <CardActionArea onClick={handleClick} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        {loading ? (
          <Skeleton variant="rectangular" height={140} animation="wave" />
        ) : (
          <CardMedia
            component="img"
            height={140}
            image={imageData?.url || service.images?.[0] || '/placeholder-service.jpg'}
            alt={service.title}
            sx={{ objectFit: 'cover' }}
          />
        )}
        <CardContent sx={{ flexGrow: 1, p: 2 }}>
          <Typography variant="subtitle1" gutterBottom noWrap fontWeight="medium">
            {service.title}
          </Typography>

          <Stack direction="row" spacing={0.5} flexWrap="wrap" sx={{ mb: 1 }}>
            <Chip
              label={service.category?.name || 'Uncategorized'}
              size="small"
              color="primary"
              sx={{ borderRadius: 1, height: 24, fontSize: '0.7rem' }}
            />
          </Stack>

          {imageData && (
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', fontSize: '0.6rem', mt: 1 }}>
              Photo by <a href={imageData.photographerUrl} target="_blank" rel="noopener noreferrer">{imageData.photographer}</a> on Pexels
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ServiceCard;