import React from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';

// Room data - in a real app, this would come from an API or CMS
const rooms = [
  {
    id: 'kitchen',
    name: 'Kitchen',
    image: 'https://images.pexels.com/photos/1599791/pexels-photo-1599791.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Cooking & dining spaces',
    projectCount: 1245,
  },
  {
    id: 'bathroom',
    name: 'Bathroom',
    image: 'https://images.pexels.com/photos/1457847/pexels-photo-1457847.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Bathrooms & powder rooms',
    projectCount: 987,
  },
  {
    id: 'living-room',
    name: 'Living Room',
    image: 'https://images.pexels.com/photos/1571459/pexels-photo-1571459.jpeg?auto=compress&cs=tinysrgb&w=1227&h=750&dpr=1',
    description: 'Family & entertainment spaces',
    projectCount: 1432,
  },
  {
    id: 'bedroom',
    name: 'Bedroom',
    image: 'https://images.pexels.com/photos/1743229/pexels-photo-1743229.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Bedrooms & closets',
    projectCount: 1089,
  },
  {
    id: 'outdoor',
    name: 'Outdoor',
    image: 'https://images.pexels.com/photos/2132227/pexels-photo-2132227.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Patios, decks & landscapes',
    projectCount: 876,
  },
  {
    id: 'home-office',
    name: 'Home Office',
    image: 'https://images.pexels.com/photos/1957477/pexels-photo-1957477.jpeg?auto=compress&cs=tinysrgb&w=1171&h=750&dpr=1',
    description: 'Workspaces & studies',
    projectCount: 654,
  },
];

const RoomsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="rooms" />

      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{ mb: 2 }}
      >
        Browse Ideas by Room
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        sx={{ mb: 6 }}
      >
        Discover design ideas, find professionals, and shop products for every space in your home
      </Typography>

      <Grid container spacing={4}>
        {rooms.map((room) => (
          <Grid item xs={12} sm={6} md={4} key={room.id}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                },
                borderRadius: 2,
                overflow: 'hidden',
              }}
            >
              <CardActionArea onClick={() => navigate(`/ideas/rooms/${room.id}`)}>
                <CardMedia
                  component="img"
                  height="240"
                  image={room.image}
                  alt={room.name}
                  sx={{
                    transition: 'transform 0.5s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                />
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {room.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {room.description}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {room.projectCount.toLocaleString()} projects
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default RoomsPage;