import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';
import {
  Store as StoreIcon,
  ShoppingCart as CartIcon,
  Star as StarIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchSellerProfile } from '../../../redux/slices/sellerSlice';

const StatCard: React.FC<{
  title: string;
  value: string | number;
  icon: React.ReactNode;
  color: string;
}> = ({ title, value, icon, color }) => (
  <Paper sx={{ p: 3 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Box
        sx={{
          backgroundColor: `${color}20`,
          borderRadius: '50%',
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {React.cloneElement(icon as React.ReactElement, { sx: { color } })}
      </Box>
      <Typography variant="h6" sx={{ ml: 2 }}>
        {title}
      </Typography>
    </Box>
    <Typography variant="h4">{value}</Typography>
  </Paper>
);

export const SellerDashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { profile, loading, error } = useSelector((state: RootState) => state.seller);

  useEffect(() => {
    dispatch(fetchSellerProfile());
  }, [dispatch]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!profile) {
    return (
      <Container maxWidth="sm" sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Welcome to Seller Portal
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Start selling your products by creating a seller profile
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/seller/onboarding/0"
        >
          Create Seller Profile
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Welcome back, {profile.businessName}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Here's an overview of your store's performance
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Products"
            value={profile.totalProducts}
            icon={<StoreIcon />}
            color="#2196f3"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Orders"
            value="0" // TODO: Add orders count
            icon={<CartIcon />}
            color="#4caf50"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Rating"
            value={profile.rating.toFixed(1)}
            icon={<StarIcon />}
            color="#ff9800"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Reviews"
            value={profile.totalReviews}
            icon={<TrendingUpIcon />}
            color="#f44336"
          />
        </Grid>
      </Grid>

      {/* Quick Actions */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Quick Actions
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              href="/seller/dashboard/products/new"
            >
              Add New Product
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              href="/seller/dashboard/products"
            >
              Manage Products
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              href="/seller/dashboard/profile"
            >
              Edit Profile
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};