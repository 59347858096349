import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';
import authService from '../../services/authService';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  padding: theme.spacing(1, 3),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
}));

interface GoogleSignupButtonProps {
  role: 'customer' | 'provider';
  onStart?: () => void;
  onError?: (error: Error) => void;
}

const GoogleSignupButton: React.FC<GoogleSignupButtonProps> = ({
  role,
  onStart,
  onError,
}) => {
  const handleClick = async () => {
    try {
      onStart?.();
      authService.initiateGoogleAuth(role);
    } catch (error) {
      onError?.(error instanceof Error ? error : new Error('Failed to initiate Google signup'));
    }
  };

  return (
    <StyledButton
      variant="outlined"
      onClick={handleClick}
      startIcon={<GoogleIcon />}
      data-testid="google-signup-button"
    >
      Sign up with Google
    </StyledButton>
  );
};

export default GoogleSignupButton;