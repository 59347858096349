import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Paper,
} from '@mui/material';
import { PreferenceQuestion, CustomerPreference } from '../../../types/preference';
import PreferenceQuestions from '../PreferenceQuestions';
import CollapsibleSection from '../CollapsibleSection';

interface TimelineStepProps {
  formData: {
    timeline?: string;
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
  serviceQuestions: PreferenceQuestion[];
  preferences: CustomerPreference[];
  onPreferencesChange: (preferences: CustomerPreference[]) => void;
}

const TimelineStep: React.FC<TimelineStepProps> = ({
  formData,
  onChange,
  onNext,
  onBack,
  serviceQuestions,
  preferences,
  onPreferencesChange,
}) => {
  // Get selected options for summary
  const getSelectedOptionsSummary = (questions: PreferenceQuestion[]): string => {
    if (!questions.length) return '';

    const selectedOptions = questions.flatMap(q => {
      const pref = preferences.find(p => p.questionId === q._id);
      if (!pref || pref.selectedOptions.length === 0) return [];

      return pref.selectedOptions.map(opt => {
        const option = q.options.find(o => o.value === opt);
        return option ? option.optionText : opt;
      });
    });

    return selectedOptions.join(' • ');
  };

  // Filter timeline questions
  const timelineQuestions = serviceQuestions.filter(
    q => q.questionText.toLowerCase().includes('timeline') ||
         q.questionText.toLowerCase().includes('when') ||
         q.questionText.toLowerCase().includes('start') ||
         q.questionText.toLowerCase().includes('time')
  );

  // Check if form is valid
  const isValid = () => {
    // If we have timeline questions, check if all required ones have answers
    if (timelineQuestions.length > 0) {
      const requiredQuestions = timelineQuestions.filter(q => q.isRequired);

      for (const question of requiredQuestions) {
        const preference = preferences.find(p => p.questionId === question._id);

        if (!preference ||
            (preference.selectedOptions.length === 0 && !preference.customAnswer)) {
          return false;
        }
      }

      return true;
    }

    // If no timeline questions, check if the default timeline is selected
    return !!formData.timeline;
  };

  return (
    <Paper elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
      <Typography variant="h5" gutterBottom>
        What&apos;s your timeline?
      </Typography>

      {timelineQuestions.length > 0 ? (
        <CollapsibleSection
          title="Timeline"
          summary={getSelectedOptionsSummary(timelineQuestions)}
          defaultExpanded={true}
        >
          <PreferenceQuestions
            questions={timelineQuestions}
            preferences={preferences}
            onPreferencesChange={onPreferencesChange}
          />
        </CollapsibleSection>
      ) : (
        <FormControl component="fieldset" fullWidth margin="normal">
          <RadioGroup
            value={formData.timeline || ''}
            onChange={(e) => onChange('timeline', e.target.value)}
          >
            <FormControlLabel
              value="urgent"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">Urgent — need a pro right away</Typography>
                  <Typography variant="body2" color="text.secondary">Within 48 hours</Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="soon"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">Ready to hire, but not in a hurry</Typography>
                  <Typography variant="body2" color="text.secondary">Within 7 days</Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="flexible"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">Still researching</Typography>
                  <Typography variant="body2" color="text.secondary">No timeline in mind</Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      )}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onNext}
          disabled={!isValid()}
        >
          Next
        </Button>
      </Box>
    </Paper>
  );
};

export default TimelineStep;