import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';

// Validation schema
const paymentInfoSchema = yup.object().shape({
  'bankDetails.accountName': yup.string().required('Account name is required'),
  'bankDetails.iban': yup.string()
    .matches(/^AE\d{21}$/, 'Must be a valid UAE IBAN (format: AE + 21 digits)')
    .required('IBAN is required'),
  'bankDetails.bank': yup.string().required('Bank name is required'),
  'bankDetails.swift': yup.string().required('SWIFT code is required'),
});

const PaymentInfoStep: React.FC = () => {
  const { paymentInfo, setPaymentInfo, saveStep, isLoading, goToStep } = useSellerOnboarding();

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(paymentInfoSchema) as any,
    defaultValues: {
      'bankDetails.accountName': paymentInfo?.bankDetails?.accountName || '',
      'bankDetails.iban': paymentInfo?.bankDetails?.iban || 'AE',
      'bankDetails.bank': paymentInfo?.bankDetails?.bank || '',
      'bankDetails.swift': paymentInfo?.bankDetails?.swift || '',
    },
  });

  const onSubmit = async (data: any) => {
    const formattedData = {
      bankDetails: {
        accountName: data['bankDetails.accountName'],
        iban: data['bankDetails.iban'],
        bank: data['bankDetails.bank'],
        swift: data['bankDetails.swift'],
      },
      hasPaymentMethod: true,
    };

    setPaymentInfo(formattedData);
    await saveStep(5, formattedData);
  };

  const handleBack = () => {
    goToStep(4);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Payment Information
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Provide your bank details for receiving payments
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Bank Details
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="bankDetails.accountName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Account Name"
                      fullWidth
                      error={!!errors['bankDetails.accountName']}
                      helperText={errors['bankDetails.accountName']?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="bankDetails.iban"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="IBAN"
                      fullWidth
                      error={!!errors['bankDetails.iban']}
                      helperText={errors['bankDetails.iban']?.message || "Format: AE + 21 digits"}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="bankDetails.bank"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Bank Name"
                      fullWidth
                      error={!!errors['bankDetails.bank']}
                      helperText={errors['bankDetails.bank']?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="bankDetails.swift"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="SWIFT Code"
                      fullWidth
                      error={!!errors['bankDetails.swift']}
                      helperText={errors['bankDetails.swift']?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Payment Terms
            </Typography>
            <Typography variant="body2" paragraph>
              By providing your bank details, you agree to our payment terms:
            </Typography>

            <Box component="ul" sx={{ pl: 2 }}>
              <Box component="li" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Payments are processed within 7 business days after order completion
                </Typography>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  A platform fee of 5% will be deducted from each transaction
                </Typography>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Minimum payout amount is AED 100
                </Typography>
              </Box>
              <Box component="li">
                <Typography variant="body2">
                  You are responsible for any taxes applicable to your earnings
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save & Continue'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentInfoStep;