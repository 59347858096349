import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Divider,
  Chip,
  Rating,
  CircularProgress,
  Alert,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Snackbar,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Business as BusinessIcon,
  LocationOn as LocationIcon,
  AccessTime as TimeIcon,
  VerifiedUser as VerifiedIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Work as WorkIcon,
  Share as ShareIcon,
  ContentCopy as ContentCopyIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

interface ProviderProfile {
  _id: string;
  businessName: string;
  businessDescription: string;
  yearsInBusiness: number;
  serviceAreas: Array<{
    zipCode: string;
    city: string;
    state: string;
    radius: number;
  }>;
  certifications: Array<{
    name: string;
    issuingAuthority: string;
    issueDate: string;
    expiryDate: string;
  }>;
  workingHours: Array<{
    day: string;
    isOpen: boolean;
    openTime: string;
    closeTime: string;
  }>;
  portfolio: Array<{
    title: string;
    description: string;
    images: string[];
    completionDate: string;
  }>;
  isVerified: boolean;
  averageRating: number;
  totalReviews: number;
  completedJobs: number;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    avatar: string;
  };
  services: Array<{
    _id: string;
    title: string;
    description: string;
    price: {
      type: string;
      amount: number;
      currency: string;
    };
    category: {
      _id: string;
      name: string;
    };
  }>;
}

const PublicProviderProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [profile, setProfile] = useState<ProviderProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Share functionality
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleShareClose = () => {
    setShareAnchorEl(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setSnackbarMessage('Link copied to clipboard');
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error('Failed to copy link:', err);
        setSnackbarMessage('Failed to copy link');
        setSnackbarOpen(true);
      });
    handleShareClose();
  };

  const handleEmailShare = () => {
    const url = window.location.href;
    const subject = `Check out ${profile?.businessName} on Homezy`;
    const body = `I found this service provider on Homezy and thought you might be interested: ${url}`;
    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
    handleShareClose();
  };

  const handleSocialShare = (platform: 'facebook' | 'twitter') => {
    const url = window.location.href;
    const text = `Check out ${profile?.businessName} on Homezy`;

    let shareUrl = '';
    if (platform === 'facebook') {
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    } else if (platform === 'twitter') {
      shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text
      )}&url=${encodeURIComponent(url)}`;
    }

    window.open(shareUrl, '_blank', 'width=600,height=400');
    handleShareClose();
  };

  // Maximum number of retry attempts
  const MAX_RETRIES = 3;
  // Cache key for local storage
  const CACHE_KEY = `provider-profile-${id}`;
  // Cache expiration time (5 minutes)
  const CACHE_EXPIRATION = 5 * 60 * 1000;

  useEffect(() => {
    // Check for cached data first
    const checkCache = () => {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        try {
          const { data, timestamp } = JSON.parse(cachedData);
          const now = new Date().getTime();

          // Use cached data if it's less than 5 minutes old
          if (now - timestamp < CACHE_EXPIRATION) {
            console.log('Using cached provider profile data');
            setProfile(data);
            setLoading(false);
            return true;
          } else {
            console.log('Cached data expired, fetching fresh data');
            localStorage.removeItem(CACHE_KEY);
          }
        } catch (err) {
          console.error('Error parsing cached data:', err);
          localStorage.removeItem(CACHE_KEY);
        }
      }
      return false;
    };

    // Fetch provider profile with retry mechanism
    const fetchProviderProfileWithRetry = async (retryCount = 0) => {
      // Check cache first (only on initial attempt)
      if (retryCount === 0 && checkCache()) {
        return;
      }

      console.log(
        `Fetching provider profile for ID: ${id} (Attempt ${retryCount + 1}/${MAX_RETRIES + 1})`
      );
      try {
        setLoading(true);

        // Use native fetch API instead of axiosInstance to avoid token refresh issues
        // for unauthenticated users
        console.log('Making API request to:', `/api/pro/public/profile/${id}`);

        // Fetch the provider profile
        const response = await fetch(`/api/pro/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Cache-Control': 'no-cache', // Force fresh data
          },
        });

        console.log('Received response:', response.status, response.statusText);

        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Response data received');

        if (!data) {
          throw new Error('Empty response data');
        }

        // Cache the data in localStorage
        try {
          localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({
              data,
              timestamp: new Date().getTime(),
            })
          );
        } catch (err) {
          console.error('Error caching provider profile:', err);
          // Continue even if caching fails
        }

        setProfile(data);
        setError(null);
        setLoading(false);
      } catch (err: any) {
        console.error('Error fetching provider profile:', err);

        // Handle error types
        if (retryCount < MAX_RETRIES) {
          console.log(`Request failed. Retrying (${retryCount + 1}/${MAX_RETRIES})...`);
          // Retry with exponential backoff
          setTimeout(() => {
            fetchProviderProfileWithRetry(retryCount + 1);
          }, 1000 * Math.pow(2, retryCount)); // 1s, 2s, 4s backoff
          return;
        } else {
          // If we have cached data, use it even if expired as fallback
          if (localStorage.getItem(CACHE_KEY)) {
            try {
              const { data } = JSON.parse(localStorage.getItem(CACHE_KEY) || '');
              if (data) {
                console.log('Using expired cached data as fallback');
                setProfile(data);
                setError('Using cached data. The server is experiencing high traffic.');
                setLoading(false);
                return;
              }
            } catch (cacheErr) {
              console.error('Error parsing fallback cache:', cacheErr);
            }
          }
          setError(err.message || 'Failed to load provider profile after multiple attempts');
        }
        setLoading(false);
      }
    };

    fetchProviderProfileWithRetry();

    // No fallback timer needed now that the profile route is public
    return () => {};
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 8,
        }}
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading provider profile...
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Provider ID: {id}
        </Typography>
      </Box>
    );
  }

  // Create a fallback profile if needed
  const createFallbackProfile = () => {
    return {
      _id: id || 'unknown',
      businessName: 'Professional Service',
      businessDescription:
        'This provider profile is currently unavailable. Please try again later.',
      yearsInBusiness: 0,
      serviceAreas: [],
      certifications: [],
      workingHours: [],
      portfolio: [],
      isVerified: false,
      averageRating: 0,
      totalReviews: 0,
      completedJobs: 0,
      user: {
        firstName: 'Provider',
        lastName: '',
        email: '',
        phone: '',
        avatar: '',
      },
      services: [],
    } as ProviderProfile;
  };

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </Container>
    );
  }

  if (!profile) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="info">Provider profile not found</Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </Container>
    );
  }

  // Format working hours for display
  const formatWorkingHours = () => {
    const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const sortedHours = [...(profile.workingHours || [])].sort(
      (a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day)
    );

    return sortedHours.map((hours) => (
      <ListItem key={hours.day}>
        <ListItemIcon>
          <TimeIcon />
        </ListItemIcon>
        <ListItemText
          primary={hours.day}
          secondary={hours.isOpen ? `${hours.openTime} - ${hours.closeTime}` : 'Closed'}
        />
      </ListItem>
    ));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Provider Header */}
      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: 200,
                height: 200,
                backgroundColor: '#e0e0e0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  textAlign: 'center',
                  padding: 2,
                  color: '#555',
                  zIndex: 1,
                }}
              >
                {profile.businessName.charAt(0)}
              </Typography>
              {profile.user?.avatar && (
                <Box
                  component="img"
                  src={profile.user.avatar}
                  alt={profile.businessName}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 0,
                  }}
                  style={{ opacity: 0.8 }}
                  onError={(e) => {
                    // If image fails to load, just hide it
                    const target = e.target as HTMLImageElement;
                    target.style.display = 'none';
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" component="h1">
                  {profile.businessName}
                </Typography>
                {profile.isVerified && (
                  <Chip
                    icon={<VerifiedIcon />}
                    label="Verified"
                    color="primary"
                    size="small"
                    sx={{ ml: 2 }}
                  />
                )}
              </Box>
              <Tooltip title="Share this profile">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<ShareIcon />}
                  onClick={handleShareClick}
                  size="small"
                >
                  Share
                </Button>
              </Tooltip>
              <Menu
                anchorEl={shareAnchorEl}
                open={Boolean(shareAnchorEl)}
                onClose={handleShareClose}
              >
                <MenuItem onClick={handleCopyLink}>
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Copy share link</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleEmailShare}>
                  <ListItemIcon>
                    <EmailIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Email</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleSocialShare('facebook')}>
                  <ListItemIcon>
                    <FacebookIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Facebook</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleSocialShare('twitter')}>
                  <ListItemIcon>
                    <TwitterIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Twitter</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Rating value={profile.averageRating} precision={0.5} readOnly />
              <Typography variant="body2" sx={{ ml: 1 }}>
                ({profile.totalReviews} reviews)
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <Typography variant="body2">{profile.completedJobs} jobs completed</Typography>
            </Box>
            <Typography variant="body1" paragraph>
              {profile.businessDescription}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
              <Chip
                icon={<BusinessIcon />}
                label={`${profile.yearsInBusiness} years in business`}
                variant="outlined"
              />
              {profile.serviceAreas.map((area, index) => (
                <Chip
                  key={index}
                  icon={<LocationIcon />}
                  label={`${area.city}, ${area.state}`}
                  variant="outlined"
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={4}>
        {/* Left Column */}
        <Grid item xs={12} md={4}>
          {/* Contact Information */}
          <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <List>
              {profile.user?.phone && (
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Phone" secondary={profile.user.phone} />
                </ListItem>
              )}
              {profile.user?.email && (
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Email" secondary={profile.user.email} />
                </ListItem>
              )}
            </List>
          </Paper>

          {/* Working Hours */}
          <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Working Hours
            </Typography>
            <List>{formatWorkingHours()}</List>
          </Paper>

          {/* Documentation */}
          {profile.certifications && profile.certifications.length > 0 && (
            <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Documentation
              </Typography>
              <List>
                {profile.certifications.map((cert, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <VerifiedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={cert.name}
                      secondary={`Issued by ${cert.issuingAuthority}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={8}>
          {/* Services */}
          {profile.services && profile.services.length > 0 && (
            <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Services Offered
              </Typography>
              <Grid container spacing={2}>
                {profile.services.map((service) => (
                  <Grid item xs={12} key={service._id}>
                    <Card sx={{ display: 'flex', height: '100%' }}>
                      <Box
                        sx={{
                          width: 120,
                          backgroundColor: '#e0e0e0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <WorkIcon sx={{ fontSize: 40, color: '#555' }} />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <CardContent>
                          <Typography variant="h6" component="div">
                            {service.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            {service.category?.name}
                          </Typography>
                          <Typography variant="body2" paragraph>
                            {service.description}
                          </Typography>
                          <Typography variant="h6" color="primary">
                            {service.price?.type === 'fixed'
                              ? `${service.price.currency} ${service.price.amount}`
                              : `Starting at ${service.price?.currency} ${service.price?.amount}`}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}

          {/* Projects */}
          {profile.portfolio && profile.portfolio.length > 0 && (
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Projects
              </Typography>
              <Grid container spacing={2}>
                {profile.portfolio.map((item, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card>
                      <Box
                        sx={{
                          height: 200,
                          backgroundColor: '#e0e0e0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            textAlign: 'center',
                            padding: 2,
                            color: '#555',
                            zIndex: 1,
                          }}
                        >
                          {item.title}
                        </Typography>
                        {item.images && item.images.length > 0 && (
                          <Box
                            component="img"
                            src={item.images[0]}
                            alt={item.title}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: 0,
                            }}
                            style={{ opacity: 0.8 }}
                            onError={(e) => {
                              // If image fails to load, just hide it
                              const target = e.target as HTMLImageElement;
                              target.style.display = 'none';
                            }}
                          />
                        )}
                      </Box>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {item.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          {new Date(item.completionDate).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2">{item.description}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default PublicProviderProfile;
