import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from '../redux/store';
import { IUser } from '../types/models';
import { loginSuccess, logout as logoutAction } from '../redux/slices/authSlice';

export interface UseAuthResult {
  user: IUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  getAuthToken: () => string | null;
  login: (token: string, user: IUser) => void;
  logout: () => void;
}

export const useAuth = (): UseAuthResult => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state: RootState) => state.auth);

  const getAuthToken = useCallback((): string | null => {
    return localStorage.getItem('token');
  }, []);

  const login = useCallback(
    (token: string, userData: IUser) => {
      dispatch(loginSuccess({ user: userData, token }));
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return {
    user,
    isAuthenticated: !!user,
    isLoading: loading,
    error,
    getAuthToken,
    login,
    logout,
  };
};
