import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cart, CartItem, Product } from '../../types/marketplace';

const calculateCartTotals = (items: CartItem[]) => {
  const subtotal = items.reduce((total, item) => {
    return total + item.product.price.amount * item.quantity;
  }, 0);

  const tax = subtotal * 0.05; // 5% tax
  const total = subtotal + tax;

  return { subtotal, tax, total };
};

// Load cart from localStorage if available
const loadCartFromStorage = (): Cart => {
  try {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart) as Cart;
      return parsedCart;
    }
  } catch (error) {
    console.error('Failed to load cart from localStorage:', error);
  }

  return {
    items: [],
    subtotal: 0,
    tax: 0,
    total: 0,
  };
};

const initialState: Cart = loadCartFromStorage();

// Helper function to save cart to localStorage
const saveCartToStorage = (cart: Cart) => {
  try {
    localStorage.setItem('cart', JSON.stringify(cart));
  } catch (error) {
    console.error('Failed to save cart to localStorage:', error);
  }
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<{ product: Product; quantity: number }>) => {
      const { product, quantity } = action.payload;
      const existingItem = state.items.find(item => item.product._id === product._id);

      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        state.items.push({ product, quantity });
      }

      const totals = calculateCartTotals(state.items);
      state.subtotal = totals.subtotal;
      state.tax = totals.tax;
      state.total = totals.total;

      // Save to localStorage
      saveCartToStorage(state);
    },

    removeFromCart: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      state.items = state.items.filter(item => item.product._id !== productId);

      const totals = calculateCartTotals(state.items);
      state.subtotal = totals.subtotal;
      state.tax = totals.tax;
      state.total = totals.total;

      // Save to localStorage
      saveCartToStorage(state);
    },

    updateQuantity: (state, action: PayloadAction<{ productId: string; quantity: number }>) => {
      const { productId, quantity } = action.payload;
      const item = state.items.find(item => item.product._id === productId);

      if (item) {
        if (quantity <= 0) {
          state.items = state.items.filter(item => item.product._id !== productId);
        } else {
          item.quantity = quantity;
        }

        const totals = calculateCartTotals(state.items);
        state.subtotal = totals.subtotal;
        state.tax = totals.tax;
        state.total = totals.total;

        // Save to localStorage
        saveCartToStorage(state);
      }
    },

    clearCart: (state) => {
      state.items = [];
      state.subtotal = 0;
      state.tax = 0;
      state.total = 0;

      // Save to localStorage
      saveCartToStorage(state);
    },
  },
});

export const { addToCart, removeFromCart, updateQuantity, clearCart } = cartSlice.actions;

export default cartSlice.reducer;