import React, { useState, useEffect, useCallback, ReactNode, createContext, useContext } from 'react';
import { useAuth } from '../../hooks/useAuth';
import axios from '../../services/axios';

// Create a context with the same shape as OnboardingContext
const ReviewsOnboardingContext = createContext<any>(undefined);

// Custom hook to use the reviews onboarding context
export const useReviewsOnboarding = () => {
  const context = useContext(ReviewsOnboardingContext);
  if (context === undefined) {
    throw new Error('useReviewsOnboarding must be used within a ReviewsOnboardingProvider');
  }
  return context;
};

interface ReviewsOnboardingProviderProps {
  children: ReactNode;
}

/**
 * A simplified version of the OnboardingProvider that doesn't include navigation logic
 * This prevents the redirection to the dashboard when used in the provider profile
 */
const ReviewsOnboardingProvider: React.FC<ReviewsOnboardingProviderProps> = ({ children }) => {
  const { user, getAuthToken } = useAuth();

  // State for Google Business Profile
  const [googleBusinessConnected, setGoogleBusinessConnected] = useState<boolean>(false);
  const [googleLocations, setGoogleLocations] = useState<any[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [importedReviews, setImportedReviews] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Google Business Profile functions
  const connectGoogleBusiness = useCallback(async () => {
    if (!user || user.role !== 'provider') return;

    const token = getAuthToken();
    if (!token) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get('/provider/google/auth', {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.location.href = response.data.authUrl;
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to connect Google Business');
      console.error('Error connecting Google Business:', err);
    } finally {
      setIsLoading(false);
    }
  }, [user, getAuthToken]);

  const handleGoogleCallback = useCallback(async (code: string) => {
    if (!user || user.role !== 'provider') return false;

    const token = getAuthToken();
    if (!token) return false;

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(`/provider/google/callback?code=${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGoogleBusinessConnected(true);
      fetchGoogleLocations();
      return true;
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to complete Google Business connection');
      console.error('Error handling Google callback:', err);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [user, getAuthToken]);

  const fetchGoogleLocations = useCallback(async () => {
    if (!user || user.role !== 'provider') return;

    const token = getAuthToken();
    if (!token) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get('/provider/google/locations', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGoogleLocations(response.data.locations);

      // If there's only one location, select it automatically
      if (response.data.locations.length === 1) {
        setSelectedLocation(response.data.locations[0].id);
      }
    } catch (err: any) {
      // If we get a 404, it means the profile is not connected yet
      if (err.response?.status === 404) {
        // Profile not found, set not connected state
        setGoogleBusinessConnected(false);
        setGoogleLocations([]);
      } else if (err.response?.status === 500) {
        // Handle 500 error - likely due to missing Google credentials or token issues
        setError('Google Business API configuration issue. Please contact support.');
        console.error('Error fetching Google locations:', err);
        setGoogleBusinessConnected(false);
      } else {
        setError(err.response?.data?.message || 'Failed to fetch Google locations');
        console.error('Error fetching Google locations:', err);
      }
    } finally {
      setIsLoading(false);
    }
  }, [user, getAuthToken]);

  const importGoogleReviews = useCallback(async () => {
    if (!user || user.role !== 'provider' || !selectedLocation) return false;

    const token = getAuthToken();
    if (!token) return false;

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('/provider/google/import-reviews', {
        locationId: selectedLocation,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setImportedReviews(response.data.reviews || []);
      return true;
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to import reviews');
      console.error('Error importing Google reviews:', err);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [user, getAuthToken, selectedLocation]);

  const disconnectGoogleBusiness = useCallback(async () => {
    if (!user || user.role !== 'provider') return false;

    const token = getAuthToken();
    if (!token) return false;

    setIsLoading(true);
    setError(null);

    try {
      await axios.post('/provider/google/disconnect', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setGoogleBusinessConnected(false);
      setGoogleLocations([]);
      setSelectedLocation(null);
      setImportedReviews([]);

      return true;
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to disconnect Google Business');
      console.error('Error disconnecting Google Business:', err);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [user, getAuthToken]);

  // Check if already connected on mount
  useEffect(() => {
    if (user && user.role === 'provider') {
      fetchGoogleLocations();
    }
  }, [user, fetchGoogleLocations]);

  // Create context value with only the properties needed for reviews
  const value = {
    // We provide empty/default values for properties not used in the reviews tab
    currentStep: 0,
    onboardingStatus: null,
    isLoading,
    error,
    businessProfile: null,
    availability: null,
    serviceSelection: null,
    targetPreferences: null,
    serviceAreas: null,
    budgetSettings: null,
    paymentInfo: null,
    // These methods are intentionally no-op implementations as they're not used in the reviews tab
    // but are required to maintain API compatibility with the full OnboardingContext
    setBusinessProfile: (data: unknown) => {
      console.log('setBusinessProfile not implemented in ReviewsOnboardingProvider', data);
      return;
    },
    setAvailability: (data: unknown) => {
      console.log('setAvailability not implemented in ReviewsOnboardingProvider', data);
      return;
    },
    setServiceSelection: (data: unknown) => {
      console.log('setServiceSelection not implemented in ReviewsOnboardingProvider', data);
      return;
    },
    addTargetPreference: (data: unknown) => {
      console.log('addTargetPreference not implemented in ReviewsOnboardingProvider', data);
      return;
    },
    setServiceAreas: (data: unknown) => {
      console.log('setServiceAreas not implemented in ReviewsOnboardingProvider', data);
      return;
    },
    setBudgetSettings: (data: unknown) => {
      console.log('setBudgetSettings not implemented in ReviewsOnboardingProvider', data);
      return;
    },
    setPaymentInfo: (data: unknown) => {
      console.log('setPaymentInfo not implemented in ReviewsOnboardingProvider', data);
      return;
    },
    saveStep: async () => {
      console.log('saveStep not implemented in ReviewsOnboardingProvider');
      return false;
    },
    completeOnboarding: async () => {
      console.log('completeOnboarding not implemented in ReviewsOnboardingProvider');
      return false;
    },
    goToStep: (step: number) => {
      console.log('goToStep not implemented in ReviewsOnboardingProvider', step);
      return;
    },
    fetchOnboardingStatus: async () => {
      console.log('fetchOnboardingStatus not implemented in ReviewsOnboardingProvider');
      return;
    },

    // Google Business Profile
    googleBusinessConnected,
    setGoogleBusinessConnected,
    googleLocations,
    setGoogleLocations,
    selectedLocation,
    setSelectedLocation,
    importedReviews,
    setImportedReviews,
    connectGoogleBusiness,
    handleGoogleCallback,
    fetchGoogleLocations,
    importGoogleReviews,
    disconnectGoogleBusiness,
  };

  return (
    <ReviewsOnboardingContext.Provider value={value}>
      {children}
    </ReviewsOnboardingContext.Provider>
  );
};

export default ReviewsOnboardingProvider;