import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const benefits = [
  'Connect with thousands of potential customers in your area',
  'Showcase your portfolio and expertise to stand out',
  'Manage your schedule and availability with our easy-to-use tools',
  'Receive job requests and send quotes directly through the platform',
  'Build your reputation with verified customer reviews',
  'Grow your business with our marketing tools and insights',
];

const EnhancedCTA: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        py: 8,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'url(https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.15,
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom sx={{ fontWeight: 700, fontSize: { xs: '2rem', md: '2.75rem' } }}>
              Are You a Home Service Professional?
            </Typography>
            <Typography variant="h5" sx={{ mb: 4, fontWeight: 400, opacity: 0.9 }}>
              Join our platform and grow your business with qualified leads
            </Typography>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => navigate('/pro/register')}
              endIcon={<ArrowForwardIcon />}
              sx={{
                py: 1.5,
                px: 4,
                fontSize: '1.1rem',
                borderRadius: 2,
                boxShadow: '0 4px 14px rgba(0, 0, 0, 0.25)',
              }}
            >
              Join as a Pro
            </Button>
            <Typography variant="body2" sx={{ mt: 2, opacity: 0.8 }}>
              Already a member? <Button color="inherit" onClick={() => navigate('/login')}>Sign in</Button>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={6}
              sx={{
                p: 4,
                borderRadius: 4,
                bgcolor: 'rgba(255, 255, 255, 0.95)',
                color: 'text.primary',
                transform: { xs: 'none', md: 'rotate(2deg)' },
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: -15,
                  left: -15,
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  bgcolor: 'secondary.main',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1,
                },
              }}
            >
              <BusinessCenterIcon
                sx={{
                  position: 'absolute',
                  top: -2,
                  left: 0,
                  color: 'white',
                  zIndex: 2,
                  fontSize: 32,
                }}
              />
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: 'primary.main', mb: 3 }}>
                Benefits for Service Providers
              </Typography>

              <List disablePadding>
                {benefits.map((benefit, index) => (
                  <ListItem key={index} disableGutters sx={{ py: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <CheckCircleIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={benefit} />
                  </ListItem>
                ))}
              </List>

              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Typography variant="h6" color="primary.dark" gutterBottom>
                  Join over 5,000+ professionals on our platform
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate('/pro/how-it-works')}
                  sx={{ mt: 1 }}
                >
                  Learn How It Works
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default EnhancedCTA;