import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import targetPreferenceService from '../../services/targetPreferenceService';
import { RootState } from '../store';

// Define types
export interface Category {
  _id: string;
  name: string;
  description?: string;
  parent?: string;
  children?: Category[];
}

export interface Service {
  _id: string;
  title: string;
  description: string;
  price?: number;
  category: Category;
  isActive: boolean;
  images: string[];
  createdAt: string;
  updatedAt: string;
  pricing: {
    type: 'fixed' | 'hourly' | 'quote-based';
    rate?: number;
    currency?: string;
  };
  features?: string[];
  tags?: string[];
}

// Import the ProviderCapability type from targetPreferenceService
export interface ProviderCapability {
  questionId: string;
  supportedOptions: string[];
}

export interface ServiceState {
  services: Service[];
  categories: Category[];
  loading: boolean;
  error: string | null;
  filters: {
    status: 'all' | 'active' | 'inactive';
    category: string;
    search: string;
  };
}

interface ServiceData {
  title: string;
  description: string;
  category: string;
  isActive?: boolean;
  images?: string[];
  features?: string[];
  tags?: string[];
  // Price and pricing fields are now removed as requested
}

interface UpdateServiceParams {
  id: string;
  data: ServiceData;
}

interface UpdateCapabilitiesParams {
  serviceId: string;
  capabilities: ProviderCapability[];
}

// Async thunks
export const fetchProviderServices = createAsyncThunk<
  Service[],
  void,
  { rejectValue: string }
>(
  'service/fetchProviderServices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/services/provider/services');
      console.log('Provider services response:', response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch services');
    }
  }
);

export const createService = createAsyncThunk<
  Service,
  ServiceData,
  { rejectValue: string }
>(
  'service/createService',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/services', data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to create service');
    }
  }
);

export const updateService = createAsyncThunk<
  Service,
  UpdateServiceParams,
  { rejectValue: string }
>(
  'service/updateService',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/services/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update service');
    }
  }
);

export const deleteService = createAsyncThunk<
  string,
  string,
  { rejectValue: string }
>(
  'service/deleteService',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/services/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete service');
    }
  }
);

export const updateServiceCapabilities = createAsyncThunk<
  { serviceId: string; capabilities: ProviderCapability[] },
  UpdateCapabilitiesParams,
  { rejectValue: string }
>(
  'service/updateServiceCapabilities',
  async ({ serviceId, capabilities }, { rejectWithValue }) => {
    try {
      console.log('updateServiceCapabilities thunk called with:', { serviceId, capabilities });

      // Get token from localStorage
      const token = localStorage.getItem('token');
      console.log('Token from localStorage:', token ? token.substring(0, 10) + '...' : 'null');

      if (!token) {
        return rejectWithValue('Authentication token is missing');
      }

      await targetPreferenceService.updateProviderCapabilities(serviceId, capabilities);
      return { serviceId, capabilities };
    } catch (error: any) {
      console.error('Error in updateServiceCapabilities thunk:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to update service capabilities');
    }
  }
);

export const fetchServiceCategories = createAsyncThunk<
  Category[],
  void,
  { rejectValue: string }
>(
  'service/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/categories/tree');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch categories');
    }
  }
);

// Initial state
const initialState: ServiceState = {
  services: [],
  categories: [],
  loading: false,
  error: null,
  filters: {
    status: 'all',
    category: 'all',
    search: '',
  },
};

// Slice
const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<ServiceState['filters']>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Provider Services
      .addCase(fetchProviderServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProviderServices.fulfilled, (state, action: PayloadAction<Service[]>) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchProviderServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch services';
      })

      // Create Service
      .addCase(createService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createService.fulfilled, (state, action: PayloadAction<Service>) => {
        state.loading = false;
        state.services.unshift(action.payload);
      })
      .addCase(createService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create service';
      })

      // Update Service
      .addCase(updateService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateService.fulfilled, (state, action: PayloadAction<Service>) => {
        state.loading = false;
        const index = state.services.findIndex((s) => s._id === action.payload._id);
        if (index !== -1) {
          state.services[index] = action.payload;
        }
      })
      .addCase(updateService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update service';
      })

      // Delete Service
      .addCase(deleteService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteService.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.services = state.services.filter((s) => s._id !== action.payload);
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete service';
      })

      // Fetch Categories
      .addCase(fetchServiceCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceCategories.fulfilled, (state, action: PayloadAction<Category[]>) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchServiceCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch categories';
      })

      // Update Service Capabilities
      .addCase(updateServiceCapabilities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateServiceCapabilities.fulfilled, (state) => {
        state.loading = false;
        // We don't need to update the state here as the capabilities are stored separately
      })
      .addCase(updateServiceCapabilities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update service capabilities';
      });
  },
});

// Selectors
export const selectFilteredServices = (state: RootState): Service[] => {
  const { services, filters } = state.service;

  // Ensure services is an array before calling filter
  if (!Array.isArray(services)) {
    return [];
  }

  return services.filter((service) => {
    const matchesStatus =
      filters.status === 'all' ||
      (filters.status === 'active' ? service.isActive : !service.isActive);

    const matchesCategory =
      filters.category === 'all' ||
      (service.category && service.category._id === filters.category);

    const matchesSearch =
      !filters.search ||
      service.title.toLowerCase().includes(filters.search.toLowerCase()) ||
      service.description.toLowerCase().includes(filters.search.toLowerCase());

    return matchesStatus && matchesCategory && matchesSearch;
  });
};

export const selectCategories = (state: RootState): Category[] => {
  return state.service.categories;
};

export const { setFilters, clearError } = serviceSlice.actions;

export default serviceSlice.reducer;
