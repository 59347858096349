import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  PhotoLibrary as IdeabookIcon,
  ShoppingCart as ProductListIcon,
  Search as ProFinderIcon,
  Calculate as CalculatorIcon,
  CheckBox as ChecklistIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { IUser } from '../../types/models';

interface SidebarProps {
  activeSection: 'ideabook' | 'product-list' | 'pro-finder' | 'calculator' | 'checklist';
  user: IUser;
}

const DRAWER_WIDTH = 240;

const Sidebar: React.FC<SidebarProps> = ({ activeSection, user }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    {
      label: 'Ideabooks',
      icon: <IdeabookIcon />,
      path: '/ideas/ideabooks',
      section: 'ideabook',
    },
    {
      label: 'Product List',
      icon: <ProductListIcon />,
      path: '/ideas/products',
      section: 'product-list',
    },
    {
      label: 'Find a Pro',
      icon: <ProFinderIcon />,
      path: '/ideas/find-pro',
      section: 'pro-finder',
    },
    {
      label: 'Cost Calculator',
      icon: <CalculatorIcon />,
      path: '/ideas/calculator',
      section: 'calculator',
    },
    {
      label: 'Project Checklist',
      icon: <ChecklistIcon />,
      path: '/ideas/checklist',
      section: 'checklist',
    },
  ];

  const drawer = (
    <>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.section}
            selected={activeSection === item.section}
            onClick={() => navigate(item.path)}
            sx={{
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.light,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: activeSection === item.section ? theme.palette.primary.main : 'inherit',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              sx={{
                color: activeSection === item.section ? theme.palette.primary.main : 'inherit',
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );

  if (isMobile) {
    return null; // Mobile navigation is handled by the Header component
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          marginTop: '64px', // Height of AppBar
          height: 'calc(100% - 64px)',
        },
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default Sidebar;