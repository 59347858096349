import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  IconButton,
  Popover,
  TextField,
  Slider,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { debounce } from '../../utils/debounce';
import { useGoogleMaps } from '../../hooks/useGoogleMaps';
import { Location } from '../../types/search';

interface LocationPickerProps {
  value: Location | null;
  onChange: (location: Location | null) => void;
  disabled?: boolean;
}

const LocationPicker = React.forwardRef<HTMLDivElement, LocationPickerProps>(({
  value,
  onChange,
  disabled = false,
}, ref) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [address, setAddress] = useState(value?.address || '');
  const [radius, setRadius] = useState(value?.radius || 10);
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [loading, setLoading] = useState(false);
  const [gettingCurrentLocation, setGettingCurrentLocation] = useState(false);

  const { isLoaded, geocoder, placesService, autocompleteService } = useGoogleMaps();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const debouncedGetSuggestions = useCallback(
    debounce(async (input: string) => {
      if (!input || !autocompleteService) return;

      try {
        const response = await autocompleteService.getPlacePredictions({
          input,
          componentRestrictions: { country: 'us' },
          types: ['geocode'],
        });

        setSuggestions(response.predictions);
      } catch (error) {
        console.error('Error getting place suggestions:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [autocompleteService]
  );

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setAddress(input);
    setLoading(true);
    debouncedGetSuggestions(input);
  };

  const handleSuggestionSelect = async (suggestion: google.maps.places.AutocompletePrediction) => {
    if (!placesService) return;

    try {
      setLoading(true);
      const result = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        placesService.getDetails(
          { placeId: suggestion.place_id },
          (result: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && result) {
              resolve(result);
            } else {
              reject(new Error('Failed to get place details'));
            }
          }
        );
      });

      if (result.geometry?.location) {
        const location: Location = {
          coordinates: [result.geometry.location.lng(), result.geometry.location.lat()],
          address: result.formatted_address || suggestion.description,
          radius,
        };
        onChange(location);
        setAddress(location.address);
        handleClose();
      }
    } catch (error) {
      console.error('Error getting place details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetCurrentLocation = () => {
    if (!geocoder) return;

    setGettingCurrentLocation(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const results = await geocoder.geocode({
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });

          if (results.results[0]) {
            const location: Location = {
              coordinates: [position.coords.longitude, position.coords.latitude],
              address: results.results[0].formatted_address,
              radius,
            };
            onChange(location);
            setAddress(location.address);
            handleClose();
          }
        } catch (error) {
          console.error('Error getting address:', error);
        } finally {
          setGettingCurrentLocation(false);
        }
      },
      (error) => {
        console.error('Error getting current location:', error);
        setGettingCurrentLocation(false);
      }
    );
  };

  const handleRadiusChange = (_event: Event, newValue: number | number[]) => {
    const newRadius = newValue as number;
    setRadius(newRadius);
    if (value) {
      onChange({ ...value, radius: newRadius });
    }
  };

  const handleClear = () => {
    setAddress('');
    setRadius(10);
    onChange(null);
    handleClose();
  };

  useEffect(() => {
    if (value) {
      setAddress(value.address);
      setRadius(value.radius);
    }
  }, [value]);

  if (!isLoaded) return null;

  return (
    <div ref={ref}>
      <IconButton
        onClick={handleClick}
        color={value ? 'primary' : 'default'}
        disabled={disabled}
      >
        <LocationOnIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { width: 300, p: 2 },
        }}
      >
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Location"
            value={address}
            onChange={handleAddressChange}
            disabled={loading || gettingCurrentLocation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleGetCurrentLocation}
                    disabled={loading || gettingCurrentLocation}
                  >
                    {gettingCurrentLocation ? (
                      <CircularProgress size={20} />
                    ) : (
                      <MyLocationIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {suggestions.length > 0 && (
            <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
              {suggestions.map((suggestion) => (
                <Button
                  key={suggestion.place_id}
                  fullWidth
                  onClick={() => handleSuggestionSelect(suggestion)}
                  sx={{ justifyContent: 'flex-start', textAlign: 'left', py: 1 }}
                >
                  <Typography noWrap>
                    {suggestion.description}
                  </Typography>
                </Button>
              ))}
            </Box>
          )}

          <Box>
            <Typography gutterBottom>
              Search Radius: {radius}km
            </Typography>
            <Slider
              value={radius}
              onChange={handleRadiusChange}
              min={1}
              max={50}
              step={1}
              marks={[
                { value: 1, label: '1km' },
                { value: 25, label: '25km' },
                { value: 50, label: '50km' },
              ]}
            />
          </Box>

          <Button
            variant="outlined"
            onClick={handleClear}
            disabled={!value}
          >
            Clear Location
          </Button>
        </Stack>
      </Popover>
    </div>
  );
});

LocationPicker.displayName = 'LocationPicker';

export default LocationPicker;