import { z } from 'zod';
import { OnboardingStepId } from '../types/onboarding/shared';

/**
 * Business Profile validation schema
 */
export const businessProfileSchema = z.object({
  businessName: z.string().min(1, 'Business name is required'),
  businessDescription: z.string().min(20, 'Description must be at least 20 characters'),
  yearEstablished: z.number().positive().optional(),
  website: z
    .string()
    .transform((val) => {
      if (val && !val.match(/^https?:\/\//)) {
        return `https://${val}`;
      }
      return val;
    })
    .pipe(z.string().url('Please enter a valid URL').optional().or(z.literal(''))),
  address: z
    .object({
      street: z.string().optional(),
      emirate: z.string().optional(),
      locality: z.string().optional(),
      country: z.string().optional(),
    })
    .optional(),
});

/**
 * Business Hours validation schema
 */
export const businessHoursSchema = z.object({
  day: z.number().min(0).max(6),
  isOpen: z.boolean(),
  openTime: z
    .string()
    .regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format (HH:MM)')
    .optional(),
  closeTime: z
    .string()
    .regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format (HH:MM)')
    .optional(),
});

/**
 * Availability validation schema
 */
export const availabilitySchema = z.object({
  businessHours: z.array(businessHoursSchema),
  exceptions: z
    .array(
      z.object({
        date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)'),
        isOpen: z.boolean(),
        openTime: z
          .string()
          .regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format (HH:MM)')
          .optional(),
        closeTime: z
          .string()
          .regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format (HH:MM)')
          .optional(),
      })
    )
    .optional(),
  advanceBookingDays: z.number().nonnegative().optional(),
  minimumLeadTime: z.number().nonnegative().optional(),
});

/**
 * Service validation schema
 */
export const serviceSchema = z.object({
  serviceId: z.string().min(1, 'Service ID is required'),
  pricing: z.object({
    type: z.enum(['fixed', 'hourly', 'quote']),
    amount: z.number().nonnegative().optional(),
    currency: z.string().min(1, 'Currency is required'),
  }),
  description: z.string().optional(),
});

/**
 * Target Preference validation schema
 */
export const targetPreferenceSchema = z.object({
  serviceId: z.string().min(1, 'Service ID is required'),
  preferences: z.record(z.any()),
});

/**
 * Service Area validation schema
 */
export const serviceAreaSchema = z
  .object({
    type: z.enum(['radius', 'locality']), // Removed 'city' as it's the same as emirate in UAE
    center: z
      .object({
        lat: z.number(),
        lng: z.number(),
      })
      .optional(),
    radius: z.number().positive().optional(),
    localities: z.array(z.string()).optional(), // Changed from zipCodes to localities
  })
  .refine(
    (data: {
      type: 'radius' | 'locality';
      center?: { lat: number; lng: number };
      radius?: number;
      localities?: string[]; // Changed from zipCodes to localities
    }) => {
      // Ensure that the appropriate fields are provided based on the type
      if (data.type === 'radius') {
        return !!data.center && !!data.radius;
      } else if (data.type === 'locality') { // Changed from 'zip' to 'locality'
        return !!data.localities && data.localities.length > 0; // Changed from zipCodes to localities
      }
      return false;
    },
    {
      message: 'Required fields missing for the selected service area type',
      path: ['type'],
    }
  );

/**
 * Budget Settings validation schema
 */
export const budgetSettingsSchema = z.object({
  monthlyBudget: z.number().positive('Monthly budget must be greater than 0'),
  maxPerLeadBudget: z.number().positive('Maximum per lead budget must be greater than 0'),
  currency: z.string().min(1, 'Currency is required'),
});

/**
 * Payment Information validation schema
 */
export const paymentInformationSchema = z.object({
  paymentMethodId: z.string().min(1, 'Payment method ID is required'),
});

/**
 * Step data validation schema based on step ID
 */
export const getStepValidationSchema = (stepId: OnboardingStepId) => {
  switch (stepId) {
    case OnboardingStepId.BUSINESS_PROFILE:
      return businessProfileSchema;
    case OnboardingStepId.AVAILABILITY:
      return availabilitySchema;
    case OnboardingStepId.SERVICES:
      return z.array(z.string().min(1)).min(1, 'At least one service is required');
    case OnboardingStepId.TARGET_PREFERENCES:
      return z.array(targetPreferenceSchema).optional();
    case OnboardingStepId.SERVICE_AREAS:
      return z.array(serviceAreaSchema).min(1, 'At least one service area is required');
    case OnboardingStepId.BUDGET_SETTINGS:
      return budgetSettingsSchema;
    case OnboardingStepId.PAYMENT_INFORMATION:
      return paymentInformationSchema;
    default:
      throw new Error(`No validation schema defined for step: ${stepId}`);
  }
};

// Export types from schemas
export type BusinessProfile = z.infer<typeof businessProfileSchema>;
export type Availability = z.infer<typeof availabilitySchema>;
export type Service = z.infer<typeof serviceSchema>;
export type TargetPreference = z.infer<typeof targetPreferenceSchema>;
export type ServiceArea = z.infer<typeof serviceAreaSchema>;
export type BudgetSettings = z.infer<typeof budgetSettingsSchema>;
export type PaymentInformation = z.infer<typeof paymentInformationSchema>;
