/**
 * Shared type definitions for the provider onboarding process.
 * These types are used by both frontend and backend to ensure consistency.
 */

/**
 * Enum for onboarding step IDs with string values for better readability in URLs
 */
export enum OnboardingStepId {
  BUSINESS_PROFILE = 'business-profile',
  AVAILABILITY = 'availability',
  SERVICES = 'services',
  TARGET_PREFERENCES = 'target-preferences',
  SERVICE_AREAS = 'service-areas',
  BUDGET_SETTINGS = 'budget-settings',
  PAYMENT_INFORMATION = 'payment-information'
}

/**
 * Maps step IDs to their numeric index for ordering
 */
export const STEP_ORDER: Record<OnboardingStepId, number> = {
  [OnboardingStepId.BUSINESS_PROFILE]: 0,
  [OnboardingStepId.AVAILABILITY]: 1,
  [OnboardingStepId.SERVICES]: 2,
  [OnboardingStepId.TARGET_PREFERENCES]: 3,
  [OnboardingStepId.SERVICE_AREAS]: 4,
  [OnboardingStepId.BUDGET_SETTINGS]: 5,
  [OnboardingStepId.PAYMENT_INFORMATION]: 6,
};

/**
 * Onboarding status interface
 */
export interface OnboardingStatus {
  currentStepId: OnboardingStepId;
  completedSteps: OnboardingStepId[];
  isComplete: boolean;
  lastUpdated: string; // ISO date string
}

/**
 * Business profile interface
 */
export interface BusinessProfile {
  businessName: string;
  businessDescription: string; // Renamed from "about" for consistency
  yearEstablished?: number;
  website?: string;
  address?: {
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
}

/**
 * Availability interface
 */
export interface Availability {
  businessHours: Array<{
    day: number; // 0-6 for Sunday-Saturday
    isOpen: boolean;
    openTime?: string; // HH:MM format
    closeTime?: string; // HH:MM format
  }>;
  exceptions?: Array<{
    date: string; // YYYY-MM-DD
    isOpen: boolean;
    openTime?: string;
    closeTime?: string;
  }>;
  advanceBookingDays?: number;
  minimumLeadTime?: number; // hours
}

/**
 * Service interface
 */
export interface Service {
  serviceId: string;
  pricing: {
    type: 'fixed' | 'hourly' | 'quote';
    amount?: number;
    currency: string;
  };
  description?: string;
}

/**
 * Target preferences interface
 */
export interface TargetPreference {
  serviceId: string;
  preferences: Record<string, any>; // Dynamic based on service type
}

/**
 * Service area interface
 */
export interface ServiceArea {
  type: 'radius' | 'zip' | 'city';
  center?: {
    lat: number;
    lng: number;
  };
  radius?: number; // in km
  zipCodes?: string[];
  cities?: string[];
}

/**
 * Budget settings interface
 */
export interface BudgetSettings {
  monthlyBudget: number;
  maxPerLeadBudget: number;
  currency: string;
}

/**
 * Payment information interface
 */
export interface PaymentInformation {
  paymentMethodId: string;
}

/**
 * Complete onboarding data interface
 */
export interface OnboardingData {
  businessProfile?: BusinessProfile;
  availability?: Availability;
  services?: Service[];
  targetPreferences?: TargetPreference[];
  serviceAreas?: ServiceArea[];
  budgetSettings?: BudgetSettings;
  paymentInformation?: PaymentInformation;
}

/**
 * Step configuration interface
 */
export interface StepConfig {
  id: OnboardingStepId;
  label: string;
  optional: boolean;
  description: string;
}

/**
 * Configuration for all onboarding steps
 */
export const ONBOARDING_STEPS: StepConfig[] = [
  {
    id: OnboardingStepId.BUSINESS_PROFILE,
    label: 'Business Profile',
    optional: false,
    description: 'Tell us about your business',
  },
  {
    id: OnboardingStepId.AVAILABILITY,
    label: 'Availability',
    optional: true,
    description: 'Set your working hours',
  },
  {
    id: OnboardingStepId.SERVICES,
    label: 'Services',
    optional: false,
    description: 'Select the services you offer',
  },
  {
    id: OnboardingStepId.TARGET_PREFERENCES,
    label: 'Target Preferences',
    optional: true,
    description: 'Set preferences for lead matching',
  },
  {
    id: OnboardingStepId.SERVICE_AREAS,
    label: 'Service Areas',
    optional: false,
    description: 'Define your service coverage areas',
  },
  {
    id: OnboardingStepId.BUDGET_SETTINGS,
    label: 'Budget Settings',
    optional: false,
    description: 'Set your lead generation budget',
  },
  {
    id: OnboardingStepId.PAYMENT_INFORMATION,
    label: 'Payment Information',
    optional: false,
    description: 'Add your payment method',
  },
];