import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, CircularProgress, Alert, Button, Paper } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { IdeabookList } from '../../components/ideas/IdeabookList';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';
import ideaService from '../../services/ideaService';

const IdeabooksPage: React.FC = () => {
  const navigate = useNavigate();
  const [ideabooks, setIdeabooks] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIdeabooks = async () => {
      try {
        setLoading(true);
        const data = await ideaService.getIdeabooks();
        setIdeabooks(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching ideabooks:', err);
        setError('Failed to load ideabooks. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchIdeabooks();
  }, []);

  const handleCreateNew = () => {
    navigate('/ideas/ideabooks/new');
  };

  const handleEdit = (id: string) => {
    navigate(`/ideas/ideabooks/${id}/edit`);
  };

  const handleDelete = (id: string) => {
    // TODO: Implement delete functionality
    console.log('Delete ideabook:', id);
  };

  const handleShare = (id: string) => {
    // TODO: Implement share functionality
    console.log('Share ideabook:', id);
  };

  const handleView = (id: string) => {
    navigate(`/ideas/ideabooks/${id}`);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="ideabooks" />
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom>
            Ideas & Inspiration
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Discover and save home improvement ideas, create your own ideabooks, and find inspiration for your next project.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateNew}
          sx={{ mt: 1 }}
        >
          Create New
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      ) : ideabooks.length === 0 ? (
        <Paper sx={{ p: 4, textAlign: 'center', my: 4 }}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            You don't have any ideabooks yet
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Create your first ideabook to start collecting and organizing your home improvement ideas.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateNew}
            sx={{ mt: 2 }}
          >
            Create Your First Ideabook
          </Button>
        </Paper>
      ) : (
        <IdeabookList
          ideabooks={ideabooks}
          onCreateNew={handleCreateNew}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onShare={handleShare}
          onView={handleView}
        />
      )}
    </Container>
  );
};

export default IdeabooksPage;