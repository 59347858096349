import React, { useState, useEffect } from 'react';
import { Container, Box, CircularProgress, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import IdeabookDetail from '../../components/ideas/IdeabookDetail';

interface IdeabookItem {
  id: string;
  type: 'photo' | 'product' | 'professional' | 'story' | 'discussion';
  content: any;
  note?: string;
  addedAt: Date;
}

interface Ideabook {
  id: string;
  title: string;
  description?: string;
  isPrivate: boolean;
  items: IdeabookItem[];
  tags: string[];
  createdAt: Date;
  updatedAt: Date;
}

const IdeabookDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [ideabook, setIdeabook] = useState<Ideabook | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIdeabook = async () => {
      try {
        // TODO: Implement API call to fetch ideabook
        // For now, using mock data
        const mockData: Ideabook = {
          id: id || '1',
          title: 'Modern Living Room Ideas',
          description: 'Collection of modern and minimalist living room designs',
          isPrivate: false,
          items: [
            {
              id: '1',
              type: 'photo',
              content: 'https://images.pexels.com/photos/1571459/pexels-photo-1571459.jpeg?auto=compress&cs=tinysrgb&w=400&h=300&dpr=1',
              note: 'Love the minimalist design',
              addedAt: new Date('2024-02-20'),
            },
            {
              id: '2',
              type: 'product',
              content: {
                name: 'Modern Sofa',
                price: 999,
                image: 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=200&h=200&dpr=1',
              },
              note: 'Perfect for the living room',
              addedAt: new Date('2024-02-21'),
            },
          ],
          tags: ['modern', 'minimalist', 'living room'],
          createdAt: new Date('2024-02-20'),
          updatedAt: new Date('2024-02-21'),
        };
        setIdeabook(mockData);
      } catch (err) {
        setError('Failed to load ideabook');
        console.error('Error fetching ideabook:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchIdeabook();
  }, [id]);

  const handleEditItem = async (itemId: string) => {
    // TODO: Implement item editing
    console.log('Editing item:', itemId);
  };

  const handleDeleteItem = async (itemId: string) => {
    try {
      // TODO: Implement API call to delete item
      console.log('Deleting item:', itemId);
      if (ideabook) {
        setIdeabook({
          ...ideabook,
          items: ideabook.items.filter(item => item.id !== itemId),
        });
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleAddItem = async (item: any) => {
    try {
      // TODO: Implement API call to add item
      console.log('Adding item:', item);
      if (ideabook) {
        const newItem: IdeabookItem = {
          id: Date.now().toString(), // Temporary ID
          type: item.type,
          content: item.content,
          note: item.note,
          addedAt: new Date(),
        };
        setIdeabook({
          ...ideabook,
          items: [...ideabook.items, newItem],
        });
      }
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleShare = () => {
    // TODO: Implement sharing functionality
    console.log('Sharing ideabook:', id);
  };

  const handleInvite = () => {
    // TODO: Implement invite functionality
    console.log('Inviting collaborators to ideabook:', id);
  };

  if (loading) {
    return (
      <Layout showSidebar>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
            <CircularProgress />
          </Box>
        </Container>
      </Layout>
    );
  }

  if (error || !ideabook) {
    return (
      <Layout showSidebar>
        <Container maxWidth="lg">
          <Box sx={{ py: 8 }}>
            <Typography color="error" align="center">
              {error || 'Ideabook not found'}
            </Typography>
          </Box>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout showSidebar>
      <Container maxWidth="lg">
        <IdeabookDetail
          title={ideabook.title}
          description={ideabook.description}
          items={ideabook.items}
          tags={ideabook.tags}
          isPrivate={ideabook.isPrivate}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
          onAddItem={handleAddItem}
          onShare={handleShare}
          onInvite={handleInvite}
        />
      </Container>
    </Layout>
  );
};

export default IdeabookDetailPage;