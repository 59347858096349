import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  Chip,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Pagination,
} from '@mui/material';
import {
  Search as SearchIcon,
  AccessTime as AccessTimeIcon,
  AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material';
import PageTitle from '../../../components/common/PageTitle';

// Mock data for guides
const MOCK_GUIDES = [
  {
    id: 'ac-maintenance',
    title: 'How to Maintain Your AC System in UAE Climate',
    image: 'https://images.pexels.com/photos/4792733/pexels-photo-4792733.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'A complete guide to maintaining your air conditioning system for optimal performance in UAE climate.',
    category: 'Maintenance',
    difficulty: 'beginner',
    timeRequired: '1-2 hours',
    costRange: { min: 100, max: 300, currency: 'AED' },
    featured: true,
  },
  {
    id: 'bathroom-renovation',
    title: 'DIY Bathroom Renovation Guide',
    image: 'https://images.pexels.com/photos/6585757/pexels-photo-6585757.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'Step-by-step instructions for renovating your bathroom, from planning to execution.',
    category: 'Renovation',
    difficulty: 'advanced',
    timeRequired: '1-2 weeks',
    costRange: { min: 5000, max: 15000, currency: 'AED' },
    featured: true,
  },
  {
    id: 'home-waterproofing',
    title: 'DIY Home Waterproofing for UAE Humidity',
    image: 'https://images.pexels.com/photos/5691622/pexels-photo-5691622.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'Learn how to protect your home from humidity and water damage in the UAE climate.',
    category: 'Maintenance',
    difficulty: 'intermediate',
    timeRequired: '2-3 days',
    costRange: { min: 500, max: 2000, currency: 'AED' },
    featured: false,
  },
  {
    id: 'heat-resistant-paint',
    title: 'Guide to Selecting Heat-Resistant Exterior Paint',
    image: 'https://images.pexels.com/photos/6444367/pexels-photo-6444367.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'Everything you need to know about choosing the right exterior paint for UAE weather conditions.',
    category: 'Painting',
    difficulty: 'beginner',
    timeRequired: '1 day',
    costRange: { min: 1000, max: 3000, currency: 'AED' },
    featured: false,
  },
  {
    id: 'water-efficient-garden',
    title: 'How to Create a Water-Efficient Garden in UAE',
    image: 'https://images.pexels.com/photos/2132227/pexels-photo-2132227.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'Design and maintain a beautiful garden that conserves water in the desert climate.',
    category: 'Landscaping',
    difficulty: 'intermediate',
    timeRequired: '1-2 weeks',
    costRange: { min: 2000, max: 8000, currency: 'AED' },
    featured: false,
  },
  {
    id: 'home-security',
    title: 'Home Security System Installation Guide for UAE',
    image: 'https://images.pexels.com/photos/60504/security-home-safety-smart-60504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'A comprehensive guide to installing and configuring a home security system in the UAE.',
    category: 'Security',
    difficulty: 'intermediate',
    timeRequired: '1-2 days',
    costRange: { min: 1500, max: 5000, currency: 'AED' },
    featured: false,
  },
  {
    id: 'apartment-soundproofing',
    title: 'Soundproofing Your Apartment in UAE High-Rises',
    image: 'https://images.pexels.com/photos/3951355/pexels-photo-3951355.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'Effective techniques for reducing noise in high-rise apartments without major renovations.',
    category: 'Improvement',
    difficulty: 'intermediate',
    timeRequired: '2-3 days',
    costRange: { min: 1000, max: 4000, currency: 'AED' },
    featured: false,
  },
  {
    id: 'desert-landscaping',
    title: 'Guide to Desert-Friendly Landscaping in UAE',
    image: 'https://images.pexels.com/photos/2566865/pexels-photo-2566865.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    excerpt: 'Learn how to create a beautiful outdoor space using desert-friendly plants and materials.',
    category: 'Landscaping',
    difficulty: 'intermediate',
    timeRequired: '1-3 weeks',
    costRange: { min: 3000, max: 10000, currency: 'AED' },
    featured: false,
  },
];

// Categories for filtering
const CATEGORIES = ['All Categories', 'Maintenance', 'Renovation', 'Painting', 'Landscaping', 'Security', 'Improvement'];

// Difficulty levels for filtering
const DIFFICULTY_LEVELS = ['All Levels', 'Beginner', 'Intermediate', 'Advanced'];

/**
 * GuidesIndex component displays a list of how-to guides
 * with filtering and pagination capabilities
 */
const GuidesIndex: React.FC = () => {
  // State for filtering and pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('All Categories');
  const [difficulty, setDifficulty] = useState('All Levels');
  const [page, setPage] = useState(1);
  const guidesPerPage = 6;

  // Filter guides based on search term, category, and difficulty
  const filteredGuides = MOCK_GUIDES.filter((guide) => {
    const matchesSearch = guide.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         guide.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = category === 'All Categories' || guide.category === category;
    const matchesDifficulty = difficulty === 'All Levels' ||
                             guide.difficulty.toLowerCase() === difficulty.toLowerCase();
    return matchesSearch && matchesCategory && matchesDifficulty;
  });

  // Get guides for current page
  const indexOfLastGuide = page * guidesPerPage;
  const indexOfFirstGuide = indexOfLastGuide - guidesPerPage;
  const currentGuides = filteredGuides.slice(indexOfFirstGuide, indexOfLastGuide);

  // Featured guides (for the top section)
  const featuredGuides = MOCK_GUIDES.filter(guide => guide.featured);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to first page when search changes
  };

  // Handle category filter change
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    setPage(1); // Reset to first page when category changes
  };

  // Handle difficulty filter change
  const handleDifficultyChange = (event: SelectChangeEvent) => {
    setDifficulty(event.target.value);
    setPage(1); // Reset to first page when difficulty changes
  };

  // Handle pagination change
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Format difficulty level for display
  const formatDifficulty = (level: string) => {
    return level.charAt(0).toUpperCase() + level.slice(1);
  };

  // Format cost range for display
  const formatCostRange = (range: { min: number, max: number, currency: string }) => {
    return `${range.currency} ${range.min.toLocaleString()} - ${range.max.toLocaleString()}`;
  };

  return (
    <>
      <PageTitle
        title="How-To Guides"
        description="Step-by-step guides for home improvement projects tailored for UAE homes and conditions."
        keywords="DIY guides, home improvement UAE, Dubai home guides, Abu Dhabi renovation, how-to"
        locale="en_AE"
      />

      {/* Introduction Section */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          mb: 6,
          borderRadius: 2,
          backgroundImage: 'linear-gradient(to right, #f5f7fa, #e4e8f0)',
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h4" component="h1" gutterBottom>
              UAE Home Improvement Guides
            </Typography>
            <Typography variant="body1" paragraph>
              Our step-by-step guides are specifically designed for UAE homes and climate conditions.
              Whether you're a DIY enthusiast or a homeowner looking to understand a project before hiring professionals,
              our guides provide clear instructions, material lists, and local considerations.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="DIY Home Improvement"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Featured Guides Section */}
      {featuredGuides.length > 0 && (
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Featured Guides
          </Typography>
          <Grid container spacing={3}>
            {featuredGuides.map((guide) => (
              <Grid item xs={12} md={6} key={guide.id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="240"
                    image={guide.image}
                    alt={guide.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Chip
                        label={guide.category}
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label={formatDifficulty(guide.difficulty)}
                        size="small"
                        color={
                          guide.difficulty === 'beginner' ? 'success' :
                          guide.difficulty === 'intermediate' ? 'warning' :
                          'error'
                        }
                        variant="outlined"
                      />
                    </Box>
                    <Typography variant="h5" component="h3" gutterBottom>
                      {guide.title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {guide.excerpt}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                        <Typography variant="body2" color="text.secondary">
                          {guide.timeRequired}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AttachMoneyIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                        <Typography variant="body2" color="text.secondary">
                          {formatCostRange(guide.costRange)}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      component={Link}
                      to={`/resources/guides/${guide.id}`}
                      fullWidth
                    >
                      View Guide
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Divider sx={{ mb: 4 }} />

      {/* Filters Section */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Search Guides"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="category-filter-label">Category</InputLabel>
              <Select
                labelId="category-filter-label"
                id="category-filter"
                value={category}
                label="Category"
                onChange={handleCategoryChange}
              >
                {CATEGORIES.map((cat) => (
                  <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="difficulty-filter-label">Difficulty</InputLabel>
              <Select
                labelId="difficulty-filter-label"
                id="difficulty-filter"
                value={difficulty}
                label="Difficulty"
                onChange={handleDifficultyChange}
              >
                {DIFFICULTY_LEVELS.map((level) => (
                  <MenuItem key={level} value={level}>{level}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* Guides List */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          All Guides {category !== 'All Categories' && `in ${category}`}
          {difficulty !== 'All Levels' && ` (${difficulty} Level)`}
        </Typography>

        {currentGuides.length === 0 ? (
          <Box sx={{ py: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              No guides found matching your criteria.
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setSearchTerm('');
                setCategory('All Categories');
                setDifficulty('All Levels');
              }}
              sx={{ mt: 2 }}
            >
              Clear Filters
            </Button>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {currentGuides.map((guide) => (
              <Grid item xs={12} sm={6} md={4} key={guide.id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="180"
                    image={guide.image}
                    alt={guide.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Chip
                        label={guide.category}
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label={formatDifficulty(guide.difficulty)}
                        size="small"
                        color={
                          guide.difficulty === 'beginner' ? 'success' :
                          guide.difficulty === 'intermediate' ? 'warning' :
                          'error'
                        }
                        variant="outlined"
                      />
                    </Box>
                    <Typography variant="h6" component="h3" gutterBottom>
                      {guide.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph>
                      {guide.excerpt}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                        <Typography variant="body2" color="text.secondary">
                          {guide.timeRequired}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AttachMoneyIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                        <Typography variant="body2" color="text.secondary">
                          {formatCostRange(guide.costRange)}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="outlined"
                      component={Link}
                      to={`/resources/guides/${guide.id}`}
                      fullWidth
                    >
                      View Guide
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Pagination */}
      {filteredGuides.length > guidesPerPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
          <Pagination
            count={Math.ceil(filteredGuides.length / guidesPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}

      {/* Call to Action */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          textAlign: 'center',
          mt: 4,
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Need Professional Help?
        </Typography>
        <Typography variant="body1" paragraph>
          While our guides provide DIY instructions, some projects are best handled by professionals.
          Connect with trusted experts in your area for a consultation or quote.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/find-professionals"
          sx={{ mt: 1 }}
        >
          Find Professionals
        </Button>
      </Paper>
    </>
  );
};

export default GuidesIndex;