import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Card,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AppDispatch } from '../../redux/store';
import { removeFromCart, updateQuantity } from '../../redux/slices/cartSlice';
import { CartItem as CartItemType } from '../../types/marketplace';

interface CartItemProps {
  item: CartItemType;
}

export const CartItem: React.FC<CartItemProps> = ({ item }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { product, quantity } = item;

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      dispatch(updateQuantity({ productId: product._id, quantity: newQuantity }));
    }
  };

  const handleRemove = () => {
    dispatch(removeFromCart(product._id));
  };

  return (
    <Card sx={{ display: 'flex', mb: 2, p: 2 }}>
      <Box
        sx={{
          width: 100,
          height: 100,
          backgroundColor: '#e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            textAlign: 'center',
            padding: 1,
            color: '#555',
            zIndex: 1,
          }}
        >
          {product.name}
        </Typography>
        {/* Try to load the image in the background if available */}
        {product.images[0]?.url && (
          <Box
            component="img"
            src={product.images[0].url}
            alt={product.name}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: 0,
            }}
            style={{ opacity: 0.8 }}
            onError={(e) => {
              // If image fails to load, just hide it
              const target = e.target as HTMLImageElement;
              target.style.display = 'none';
            }}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, ml: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" component="div">
            {product.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Seller: {product.seller.name}
          </Typography>
          <Typography variant="h6" color="primary">
            {product.price.currency} {product.price.amount.toFixed(2)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            type="number"
            size="small"
            value={quantity}
            onChange={handleQuantityChange}
            inputProps={{ min: 1, max: product.inventory.quantity }}
            sx={{ width: 80 }}
          />
          <IconButton onClick={handleRemove} color="error">
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};