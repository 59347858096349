import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  InputAdornment,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Alert,
  Tooltip,
  IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SubmitResponseDTO } from '../../../types/lead';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveIcon from '@mui/icons-material/Save';
import TemplateIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';
import ResponseTemplates from './ResponseTemplates';

interface LeadResponseFormProps {
  leadId: string;
  onSubmit: (values: SubmitResponseDTO) => Promise<void>;
  onCancel?: () => void;
  leadDetails?: {
    service?: string;
    budget?: { min: number; max: number };
  };
}

const LeadResponseForm: React.FC<LeadResponseFormProps> = ({
  leadId,
  onSubmit,
  onCancel,
  leadDetails,
}) => {
  const [showTemplates, setShowTemplates] = useState(false);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);

  const validationSchema = Yup.object({
    price: Yup.number()
      .required('Price is required')
      .min(1, 'Price must be greater than 0'),
    description: Yup.string()
      .required('Description is required')
      .min(10, 'Description must be at least 10 characters'),
    availableDates: Yup.array()
      .min(1, 'At least one available date is required')
      .of(Yup.string().required()),
    estimatedDuration: Yup.string()
      .required('Estimated duration is required'),
    terms: Yup.string()
      .required('Terms and conditions are required'),
  });

  const formik = useFormik<SubmitResponseDTO>({
    initialValues: {
      price: leadDetails?.budget ? Math.floor((leadDetails.budget.min + leadDetails.budget.max) / 2) : 0,
      description: '',
      availableDates: [new Date().toISOString()],
      estimatedDuration: '',
      terms: '',
      attachments: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await onSubmit(values);

        // If user wants to save as template, we would handle that here
        // In a real implementation, this would call an API to save the template
        if (saveAsTemplate) {
          console.log('Saving as template:', values);
          // This would be an API call in a real implementation
        }
      } catch (error) {
        console.error('Error submitting response:', error);
      }
    },
  });

  const handleDateChange = (date: Date | null, index: number) => {
    if (date) {
      const newDates = [...formik.values.availableDates];
      newDates[index] = date.toISOString();
      formik.setFieldValue('availableDates', newDates);
    }
  };

  const addAvailableDate = () => {
    const newDates = [...formik.values.availableDates, new Date().toISOString()];
    formik.setFieldValue('availableDates', newDates);
  };

  const removeAvailableDate = (index: number) => {
    const newDates = [...formik.values.availableDates];
    newDates.splice(index, 1);
    formik.setFieldValue('availableDates', newDates);
  };

  const handleApplyTemplate = (template: {
    title: string;
    description: string;
    estimatedDuration: string;
    terms: string;
  }) => {
    formik.setFieldValue('description', template.description);
    formik.setFieldValue('estimatedDuration', template.estimatedDuration);
    formik.setFieldValue('terms', template.terms);
    setShowTemplates(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Respond to Lead Request
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          Provide your quote and availability for this service request.
        </Typography>

        {leadDetails && (
          <Alert severity="info" sx={{ mb: 2 }}>
            <Typography variant="body2">
              <strong>Service:</strong> {leadDetails.service}
              {leadDetails.budget && (
                <span> | <strong>Budget Range:</strong> ${leadDetails.budget.min} - ${leadDetails.budget.max}</span>
              )}
            </Typography>
          </Alert>
        )}
      </Box>

      <Accordion
        expanded={showTemplates}
        onChange={() => setShowTemplates(!showTemplates)}
        sx={{ mb: 3 }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TemplateIcon sx={{ mr: 1 }} />
            <Typography>Response Templates</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ResponseTemplates onSelectTemplate={handleApplyTemplate} />
        </AccordionDetails>
      </Accordion>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Price Quote
        </Typography>
        <TextField
          fullWidth
          id="price"
          name="price"
          label="Your Price"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={formik.values.price}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
          sx={{ mb: 3 }}
        />

        <Typography variant="subtitle1" gutterBottom>
          Service Description
        </Typography>
        <TextField
          fullWidth
          id="description"
          name="description"
          label="Describe your service offering"
          multiline
          rows={4}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          placeholder="Describe what you'll provide, including any specific details about how you'll address the customer's needs."
          sx={{ mb: 3 }}
        />

        <Typography variant="subtitle1" gutterBottom>
          Estimated Duration
        </Typography>
        <TextField
          fullWidth
          id="estimatedDuration"
          name="estimatedDuration"
          label="How long will this service take?"
          value={formik.values.estimatedDuration}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.estimatedDuration && Boolean(formik.errors.estimatedDuration)}
          helperText={formik.touched.estimatedDuration && formik.errors.estimatedDuration}
          placeholder="e.g., 2 hours, 3 days, 1 week"
          sx={{ mb: 3 }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            Attachments
          </Typography>
          <Tooltip title="Add files like images, PDFs, or documents to support your response">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        <Button
          variant="outlined"
          startIcon={<AttachFileIcon />}
          sx={{ mb: 3 }}
        >
          Add Attachments
        </Button>
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Available Dates
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {formik.values.availableDates.map((date, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <DatePicker
                label={`Available Date ${index + 1}`}
                value={new Date(date)}
                onChange={(newDate) => handleDateChange(newDate, index)}
                sx={{ flexGrow: 1, mr: 2 }}
              />
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => removeAvailableDate(index)}
                disabled={formik.values.availableDates.length <= 1}
              >
                Remove
              </Button>
            </Box>
          ))}
        </LocalizationProvider>

        <Button
          variant="outlined"
          size="small"
          onClick={addAvailableDate}
          sx={{ mt: 1 }}
        >
          Add Another Date
        </Button>

        {formik.touched.availableDates && formik.errors.availableDates && (
          <FormHelperText error>
            {formik.errors.availableDates as string}
          </FormHelperText>
        )}
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Terms and Conditions
        </Typography>
        <TextField
          fullWidth
          id="terms"
          name="terms"
          label="Terms and conditions"
          multiline
          rows={3}
          value={formik.values.terms}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.terms && Boolean(formik.errors.terms)}
          helperText={formik.touched.terms && formik.errors.terms}
          placeholder="Include any terms, conditions, or policies that apply to your service."
          sx={{ mb: 2 }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            label="Save as template"
            color={saveAsTemplate ? "primary" : "default"}
            onClick={() => setSaveAsTemplate(!saveAsTemplate)}
            icon={<SaveIcon />}
            variant={saveAsTemplate ? "filled" : "outlined"}
            sx={{ mr: 1 }}
          />
          <Typography variant="caption" color="text.secondary">
            Save this response as a template for future use
          </Typography>
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
        {onCancel && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? 'Submitting...' : 'Submit Response'}
        </Button>
      </Box>
    </form>
  );
};

export default LeadResponseForm;