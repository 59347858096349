import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { OnboardingProvider } from '../../../contexts/OnboardingContext';
import { OnboardingStepId } from '../../../types/onboarding/shared';
import { useOnboardingStatus } from '../../../hooks/api/useOnboardingApi';
import { QueryClient, QueryClientProvider } from 'react-query';

// Import step components
import BusinessProfileStep from './steps/BusinessProfileStep';
import AvailabilityStep from './steps/AvailabilityStep';
import ServicesStep from './steps/ServicesStep';
import TargetPreferencesStep from './steps/TargetPreferencesStep';
import ServiceAreasStep from './steps/ServiceAreasStep';
import BudgetSettingsStep from './steps/BudgetSettingsStep';
import PaymentInfoStep from './steps/PaymentInfoStep';
import OnboardingComplete from './OnboardingComplete';

// Create a client for React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

/**
 * Wrapper component that provides the OnboardingContext
 */
const OnboardingWrapper: React.FC = () => {
  const { data: onboardingStatus, isLoading } = useOnboardingStatus();

  // If onboarding is complete, show completion page
  if (!isLoading && onboardingStatus?.isComplete) {
    return <OnboardingComplete />;
  }

  return (
    <Routes>
      <Route path={OnboardingStepId.BUSINESS_PROFILE} element={<BusinessProfileStep />} />
      <Route path={OnboardingStepId.AVAILABILITY} element={<AvailabilityStep />} />
      <Route path={OnboardingStepId.SERVICES} element={<ServicesStep />} />
      <Route path={OnboardingStepId.TARGET_PREFERENCES} element={<TargetPreferencesStep />} />
      <Route path={OnboardingStepId.SERVICE_AREAS} element={<ServiceAreasStep />} />
      <Route path={OnboardingStepId.BUDGET_SETTINGS} element={<BudgetSettingsStep />} />
      <Route path={OnboardingStepId.PAYMENT_INFORMATION} element={<PaymentInfoStep />} />

      {/* Redirect to default step if none specified */}
      <Route
        path="*"
        element={
          <Navigate
            to={onboardingStatus?.currentStepId || OnboardingStepId.BUSINESS_PROFILE}
            replace
          />
        }
      />
    </Routes>
  );
};

/**
 * Main component that wraps the OnboardingWrapper with the OnboardingProvider
 */
const Onboarding: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <OnboardingProvider>
        <OnboardingWrapper />
      </OnboardingProvider>
    </QueryClientProvider>
  );
};

export default Onboarding;