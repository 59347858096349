import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Divider,
  Chip,
  Stack,
  Paper,
  Avatar,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';
import ProjectCard from '../../components/ideas/ProjectCard';
import ShareModal from '../../components/ideas/ShareModal';
import SaveToIdeabookModal from '../../components/ideas/SaveToIdeabookModal';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';
import { useSaveIdea } from '../../hooks/useSaveIdea';

// Mock data for projects - in a real app, this would come from an API
const mockProjects = [
  {
    id: 'p1',
    title: 'Modern White Kitchen',
    image: 'https://images.pexels.com/photos/2724749/pexels-photo-2724749.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    designer: 'Interior Elegance',
    designerAvatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    likes: 342,
    description: 'A sleek, modern kitchen with white cabinetry, marble countertops, and stainless steel appliances. The open concept design creates a seamless flow between the kitchen and dining area.',
    details: {
      style: 'Modern',
      size: 'Medium',
      budget: '$$$',
      timeframe: '6 weeks',
      location: 'New York, NY',
    },
    tags: ['Modern', 'White', 'Open Concept', 'Marble', 'Stainless Steel'],
    roomType: 'kitchen',
    roomName: 'Kitchen',
    relatedProjects: ['p2', 'p3'],
  },
  {
    id: 'p2',
    title: 'Rustic Farmhouse Kitchen',
    image: 'https://images.pexels.com/photos/1080721/pexels-photo-1080721.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    designer: 'Countryside Designs',
    designerAvatar: 'https://randomuser.me/api/portraits/men/32.jpg',
    likes: 256,
    description: 'A warm and inviting farmhouse kitchen featuring wood beams, shaker cabinets, and a large farmhouse sink. The rustic elements create a cozy atmosphere perfect for family gatherings.',
    details: {
      style: 'Farmhouse',
      size: 'Large',
      budget: '$$',
      timeframe: '8 weeks',
      location: 'Austin, TX',
    },
    tags: ['Farmhouse', 'Rustic', 'Wood', 'Shaker', 'Cozy'],
    roomType: 'kitchen',
    roomName: 'Kitchen',
    relatedProjects: ['p1', 'p3'],
  },
  {
    id: 'p3',
    title: 'Industrial Loft Kitchen',
    image: 'https://images.pexels.com/photos/2062426/pexels-photo-2062426.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    designer: 'Urban Spaces',
    designerAvatar: 'https://randomuser.me/api/portraits/women/68.jpg',
    likes: 189,
    description: 'An industrial-style kitchen with exposed brick walls, concrete countertops, and black metal fixtures. The open shelving and pendant lighting add to the urban loft aesthetic.',
    details: {
      style: 'Industrial',
      size: 'Medium',
      budget: '$$',
      timeframe: '5 weeks',
      location: 'Chicago, IL',
    },
    tags: ['Industrial', 'Urban', 'Brick', 'Concrete', 'Metal'],
    roomType: 'kitchen',
    roomName: 'Kitchen',
    relatedProjects: ['p1', 'p2'],
  },
  {
    id: 'p4',
    title: 'Luxury Spa Bathroom',
    image: 'https://images.pexels.com/photos/1910472/pexels-photo-1910472.jpeg?auto=compress&cs=tinysrgb&w=687&h=1024&dpr=1',
    designer: 'Luxury Interiors',
    designerAvatar: 'https://randomuser.me/api/portraits/women/90.jpg',
    likes: 278,
    description: 'A luxurious bathroom designed to create a spa-like experience at home. Features include a freestanding soaking tub, walk-in shower with rainfall head, and elegant marble throughout.',
    details: {
      style: 'Luxury',
      size: 'Large',
      budget: '$$$$',
      timeframe: '10 weeks',
      location: 'Los Angeles, CA',
    },
    tags: ['Spa', 'Luxury', 'Marble', 'Soaking Tub', 'Walk-in Shower'],
    roomType: 'bathroom',
    roomName: 'Bathroom',
    relatedProjects: ['p5', 'p6'],
  },
];

const ProjectDetailPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const [project, setProject] = useState<any>(null);
  const [relatedProjects, setRelatedProjects] = useState<any[]>([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { saveIdea, isModalOpen, closeModal, currentIdeaId, isLoading, error } = useSaveIdea();
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    // In a real app, this would be an API call
    const foundProject = mockProjects.find(p => p.id === projectId);
    if (foundProject) {
      setProject(foundProject);

      // Get related projects
      if (foundProject.relatedProjects && foundProject.relatedProjects.length > 0) {
        const related = mockProjects.filter(p =>
          foundProject.relatedProjects.includes(p.id) && p.id !== projectId
        );
        setRelatedProjects(related);
      }
    } else {
      // Handle project not found
      navigate('/ideas/rooms');
    }
  }, [projectId, navigate]);

  const handleSaveClick = () => {
    if (project) {
      if (isSaved) {
        // If already saved, we could implement unsave functionality
        console.log(`Unsaved project ${project.id}`);
        setIsSaved(false);
      } else {
        saveIdea(project.id);
      }
    }
  };

  const handleShareClick = () => {
    setIsShareModalOpen(true);
  };

  const handleSaveToIdeabook = (ideabookId: string, projectId: string) => {
    // In a real app, this would be an API call
    console.log(`Saving project ${projectId} to ideabook ${ideabookId}`);
    setIsSaved(true);
    closeModal();
  };

  if (!project) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="rooms" />

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box
            component="img"
            src={project.image}
            alt={project.title}
            sx={{
              width: '100%',
              borderRadius: 2,
              mb: 3,
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h4" component="h1">
              {project.title}
            </Typography>

            <Box>
              <Button
                variant="outlined"
                startIcon={isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                onClick={handleSaveClick}
                sx={{ mr: 1 }}
                color={isSaved ? "primary" : "inherit"}
              >
                {isSaved ? "Saved" : "Save"}
              </Button>
              <Button
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={handleShareClick}
              >
                Share
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Avatar
              src={project.designerAvatar}
              alt={project.designer}
              sx={{ mr: 2 }}
            />
            <Box>
              <Typography variant="subtitle1">
                Designed by {project.designer}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {project.likes} people like this
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Typography variant="h6" gutterBottom>
            About This Project
          </Typography>
          <Typography variant="body1" paragraph>
            {project.description}
          </Typography>

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Project Details
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(project.details).map(([key, value]) => (
                <Grid item xs={6} sm={4} key={key}>
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </Typography>
                    <Typography variant="body1">
                      {value as string}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Tags
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              {project.tags.map((tag: string) => (
                <Chip
                  key={tag}
                  label={tag}
                  clickable
                  sx={{ m: 0.5 }}
                  onClick={() => console.log(`Filter by ${tag}`)}
                />
              ))}
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Similar Projects
          </Typography>
          <Stack spacing={3}>
            {relatedProjects.map((relatedProject) => (
              <ProjectCard
                key={relatedProject.id}
                id={relatedProject.id}
                image={relatedProject.image}
                title={relatedProject.title}
                designer={relatedProject.designer}
                likes={relatedProject.likes}
                isSaved={false} // In a real app, this would be determined by checking if the user has saved this project
                onSave={() => saveIdea(relatedProject.id)}
                onShare={() => {
                  setProject(relatedProject);
                  setIsShareModalOpen(true);
                }}
                onClick={() => navigate(`/ideas/projects/${relatedProject.id}`)}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>

      {/* Share Modal */}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        itemToShare={project ? {
          id: project.id,
          title: project.title,
          image: project.image,
        } : null}
      />

      {/* Save to Ideabook Modal */}
      <SaveToIdeabookModal
        isOpen={isModalOpen}
        onClose={closeModal}
        projectToSave={project ? {
          id: project.id,
          title: project.title,
          image: project.image,
        } : null}
        onSave={handleSaveToIdeabook}
        isLoading={isLoading}
        error={error}
      />
    </Container>
  );
};

export default ProjectDetailPage;