import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  MenuItem,
  Chip,
  IconButton,
  ImageList,
  ImageListItem,
  FormHelperText,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FileUpload as FileUploadIcon,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Define types for the form values
interface ServiceFormValues {
  category: string;
  title: string;
  description: string;
  features: string[];
  images: string[];
  tags: string[];
}

// Define types for the component props
interface ServiceFormProps {
  initialValues?: ServiceFormValues;
  onSubmit: (values: ServiceFormValues) => Promise<void>;
  categories: Array<{ _id: string; name: string }>;
}

// Create a more lenient validation schema for debugging
const validationSchema = Yup.object({
  category: Yup.string().required('Category is required'),
  title: Yup.string()
    .required('Title is required')
    .max(100, 'Title must be less than 100 characters'),
  description: Yup.string()
    .required('Description is required')
    .max(2000, 'Description must be less than 2000 characters'),
  // Make features and tags optional for debugging
  features: Yup.array().of(Yup.string()),
  // Make images optional for debugging
  images: Yup.array().of(
    Yup.string().test('is-valid-image-url', 'Invalid image URL', (value) => {
      // Accept both regular URLs and data URLs
      return !value || /^https?:\/\//.test(value) || /^data:image\/[a-z]+;base64,/.test(value);
    })
  ),
  tags: Yup.array().of(Yup.string()),
});

const ServiceForm: React.FC<ServiceFormProps> = ({ initialValues, onSubmit, categories }) => {
  const [newFeature, setNewFeature] = useState('');
  const [newTag, setNewTag] = useState('');
  const [uploadingImages, setUploadingImages] = useState(false);

  const formik = useFormik<ServiceFormValues>({
    initialValues: initialValues || {
      category: '',
      title: '',
      description: '',
      features: [],
      images: [],
      tags: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.error('Error in parent onSubmit:', error);
      }
    },
  });

  const handleAddFeature = () => {
    if (newFeature.trim()) {
      formik.setFieldValue('features', [...formik.values.features, newFeature.trim()]);
      setNewFeature('');
    }
  };

  const handleRemoveFeature = (index: number) => {
    const newFeatures = formik.values.features.filter((_, i: number) => i !== index);
    formik.setFieldValue('features', newFeatures);
  };

  const handleAddTag = () => {
    if (newTag.trim()) {
      formik.setFieldValue('tags', [...formik.values.tags, newTag.trim().toLowerCase()]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (index: number) => {
    const newTags = formik.values.tags.filter((_, i: number) => i !== index);
    formik.setFieldValue('tags', newTags);
  };

  const handleImagesUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length === 0) return;

    setUploadingImages(true);
    try {
      // Create data URLs for the images instead of mock URLs
      const dataUrls = await Promise.all(
        files.map((file: File) => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        })
      );

      formik.setFieldValue('images', [...formik.values.images, ...dataUrls]);
    } catch (err) {
      console.error('Failed to upload images:', err);
    } finally {
      setUploadingImages(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        formik.handleSubmit(e);
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            id="category"
            name="category"
            label="Category"
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.category && Boolean(formik.errors.category)}
            helperText={
              formik.touched.category && formik.errors.category
                ? String(formik.errors.category)
                : undefined
            }
          >
            {categories.map((category: { _id: string; name: string }) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="title"
            name="title"
            label="Service Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={
              formik.touched.title && formik.errors.title ? String(formik.errors.title) : undefined
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            id="description"
            name="description"
            label="Service Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={
              formik.touched.description && formik.errors.description
                ? String(formik.errors.description)
                : undefined
            }
          />
        </Grid>

        {/* Pricing fields removed as requested */}

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Features
          </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              value={newFeature}
              onChange={(e) => setNewFeature(e.target.value)}
              placeholder="Add a feature"
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleAddFeature}
                    disabled={!newFeature.trim()}
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {formik.values.features.map((feature: string, index: number) => (
              <Chip key={index} label={feature} onDelete={() => handleRemoveFeature(index)} />
            ))}
          </Box>
          {formik.touched.features && formik.errors.features && (
            <FormHelperText error>
              {typeof formik.errors.features === 'string'
                ? formik.errors.features
                : 'Please add valid features'}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Images
          </Typography>
          <Button
            variant="outlined"
            component="label"
            startIcon={<FileUploadIcon />}
            disabled={uploadingImages}
            sx={{ mb: 2 }}
          >
            {uploadingImages ? 'Uploading...' : 'Upload Images'}
            <input type="file" hidden accept="image/*" multiple onChange={handleImagesUpload} />
          </Button>
          {formik.values.images.length > 0 && (
            <ImageList cols={3} rowHeight={200} sx={{ mb: 2 }}>
              {formik.values.images.map((image: string, index: number) => (
                <ImageListItem key={index}>
                  <img
                    src={image}
                    alt={`Service ${index + 1}`}
                    loading="lazy"
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
                    }}
                    onClick={() => {
                      const newImages = formik.values.images.filter(
                        (_: string, i: number) => i !== index
                      );
                      formik.setFieldValue('images', newImages);
                    }}
                  >
                    <DeleteIcon sx={{ color: 'white' }} />
                  </IconButton>
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {formik.touched.images && formik.errors.images && (
            <FormHelperText error>
              {typeof formik.errors.images === 'string'
                ? formik.errors.images
                : 'Please add at least one image'}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Tags
          </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="Add a tag"
              InputProps={{
                endAdornment: (
                  <Button onClick={handleAddTag} disabled={!newTag.trim()} startIcon={<AddIcon />}>
                    Add
                  </Button>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {formik.values.tags.map((tag: string, index: number) => (
              <Chip key={index} label={tag} onDelete={() => handleRemoveTag(index)} />
            ))}
          </Box>
          {formik.touched.tags && formik.errors.tags && (
            <FormHelperText error>
              {typeof formik.errors.tags === 'string'
                ? formik.errors.tags
                : 'Please add valid tags'}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
              onClick={() => {
                // The form's onSubmit will handle the actual submission
              }}
            >
              {formik.isSubmitting
                ? 'Saving...'
                : initialValues
                ? 'Update Service'
                : 'Create Service'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ServiceForm;
