import React, { useEffect, useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchBar from '../../components/search/SearchBar';
import SearchResults from '../../components/search/SearchResults';
import CategoryBrowser from '../../components/search/CategoryBrowser';
import {
  searchServices,
  fetchPopularCategories,
  setSearchFilters,
  selectSearchResults,
  selectSearchLoading,
} from '../../redux/slices/searchSlice';
import searchCache from '../../utils/searchCache';
import withPerformanceTracking from '../../hocs/withPerformanceTracking';
import { RootState } from '../../types/store';
import { AppDispatch } from '../../redux/store';
import { IService } from '../../types/models';

// Import the SearchFilters type from search.ts
import { SearchFilters } from '../../types/search';

// Define the SearchParams type expected by searchCache
interface CacheSearchParams {
  query?: string;
  category?: string;
  priceRange?: [number, number];
  pricingType?: string;
  rating?: number;
  sortBy?: string;
  page?: number;
  location?: {
    lat: number;
    lng: number;
    radius: number;
  };
}

// Convert our SearchFilters to the format expected by searchCache
const convertToCacheParams = (filters: SearchFilters): CacheSearchParams => {
  return {
    query: filters.query,
    category: filters.category ? filters.category._id : undefined,
    priceRange: filters.priceRange ? [filters.priceRange.min, filters.priceRange.max] : undefined,
    rating: filters.rating,
    page: filters.page,
    location: filters.location ? {
      lat: filters.location.coordinates[1],
      lng: filters.location.coordinates[0],
      radius: filters.location.radius,
    } : undefined,
  };
};

interface SearchPageProps {}

const SearchPage: React.FC<SearchPageProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const searchParams = new URLSearchParams(location.search);
  const currentView = searchParams.get('view') || 'search';
  const categoryId = searchParams.get('category');

  const results = useSelector(selectSearchResults);
  const loading = useSelector(selectSearchLoading);
  const filters = useSelector((state: RootState) => state.search.filters);

  // Memoized search function with caching
  const performSearch = useCallback(async () => {
    // Convert filters to the format expected by searchCache
    const cacheParams = convertToCacheParams(filters);
    const cacheKey = searchCache.generateKey(cacheParams);
    const cachedResults = searchCache.get(cacheKey);

    if (cachedResults) {
      return cachedResults;
    }

    // Use the filters directly for the search
    const results = await dispatch(searchServices(filters)).unwrap();
    searchCache.set(cacheKey, results);

    // Prefetch next page and common variations
    searchCache.prefetch(cacheParams, async (cacheSearchParams: CacheSearchParams) => {
      // We need to convert back to our app's SearchFilters format for the search
      // This is a simplified conversion just for the prefetch function
      const searchFilters = { ...filters };
      if (cacheSearchParams.page) {
        searchFilters.page = cacheSearchParams.page;
      }
      const results = await dispatch(searchServices(searchFilters)).unwrap();
      return results;
    });

    return results;
  }, [dispatch, filters]);

  // Initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await dispatch(fetchPopularCategories()).unwrap();
      } catch (error) {
        console.error('Failed to load initial data:', error);
      }
    };

    loadInitialData();
  }, [dispatch]);

  // Get categories from store
  const categories = useSelector((state: RootState) => state.search.categories);

  // Update filters when category changes in URL or categories are loaded
  useEffect(() => {
    if (categoryId && categories.length > 0) {
      // Find the category object from the categories array
      const categoryObj = categories.find(c => c._id === categoryId);

      if (categoryObj) {
        dispatch(setSearchFilters({ category: categoryObj }));
      } else {
        console.log('Category not found:', categoryId);
      }
    }
  }, [categoryId, categories, dispatch]);

  // Perform search when filters change
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (filters.query || filters.category || filters.location) {
        performSearch();
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [filters, performSearch]);

  // Preload images for visible results
  useEffect(() => {
    if (results.length > 0) {
      // Get all image URLs from visible results
      const imageUrls = results.reduce((urls: string[], service: IService) => {
        if (service.images.length > 0) {
          urls.push(service.images[0]);
        }
        return urls;
      }, []);

      // Preload images
      if (imageUrls.length > 0) {
        imageUrls.forEach((url: string) => {
          const img = new Image();
          img.src = url;
        });
      }
    }
  }, [results]);

  const handleViewChange = (_event: React.SyntheticEvent, newValue: string) => {
    const params = new URLSearchParams(location.search);
    params.set('view', newValue);
    navigate({ search: params.toString() });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Search Header */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Find Local Services
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Search from thousands of trusted service providers in your area
        </Typography>
      </Box>

      {/* Search Bar */}
      <SearchBar onSearch={performSearch} categories={[]} />

      {/* View Tabs */}
      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={currentView}
          onChange={handleViewChange}
          variant={isMobile ? 'fullWidth' : 'standard'}
          sx={{ px: 2 }}
        >
          <Tab
            value="search"
            label={`Search Results ${results.length ? `(${results.length})` : ''}`}
          />
          <Tab value="categories" label="Browse Categories" />
        </Tabs>
      </Paper>

      {/* Main Content */}
      {currentView === 'search' ? (
        <Box>
          {/* Active Filters Summary */}
          {(filters.query || filters.category || filters.location) && (
            <Paper sx={{ p: 2, mb: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Active Filters:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {filters.query && (
                  <Typography variant="body2">Search: "{filters.query}"</Typography>
                )}
                {filters.category && (
                  <Typography variant="body2">Category: {typeof filters.category === 'string' ? filters.category : filters.category.name || 'Selected Category'}</Typography>
                )}
                {filters.location && (
                  <Typography variant="body2">
                    Location: Within {filters.location.radius}km of your location
                  </Typography>
                )}
              </Box>
            </Paper>
          )}

          {/* Search Results */}
          <SearchResults />
        </Box>
      ) : (
        <CategoryBrowser loading={loading} />
      )}

      {/* Location-based Services */}
      {filters.location && results.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom>
            Services Near You
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Showing services within {filters.location.radius}km of your location
          </Typography>
          <Box sx={{ mt: 2 }}>{/* Map view could be added here */}</Box>
        </Box>
      )}

      {/* Popular Categories */}
      {currentView === 'search' && results.length === 0 && !loading && (
        <Box sx={{ mt: 4 }}>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom>
            Popular Categories
          </Typography>
          <CategoryBrowser />
        </Box>
      )}
    </Container>
  );
};

// Export with performance tracking
export default withPerformanceTracking(SearchPage, {
  trackPageLoad: true,
  trackImageLoads: true,
  trackSearches: true,
  componentName: 'SearchPage',
});
