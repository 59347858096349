import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Paper,
  Chip,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';
import { useAuth } from '../../../../hooks/useAuth';

// Validation schema
const productCategoriesSchema = yup.object().shape({
  categories: yup.array().min(1, 'Select at least one category').required('Categories are required'),
});

interface Category {
  _id: string;
  name: string;
  description?: string;
  parentCategory?: string;
}

const ProductCategoriesStep: React.FC = () => {
  const { productCategories, setProductCategories, saveStep, isLoading, goToStep } = useSellerOnboarding();
  const { getAuthToken } = useAuth();

  const [categories, setCategories] = useState<Category[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(productCategoriesSchema),
    defaultValues: {
      categories: productCategories?.categories || [],
    },
  });

  // Fetch categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingCategories(true);
      setError(null);

      try {
        const token = getAuthToken();
        const response = await axios.get('/api/categories', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setCategories(response.data.categories);

      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to fetch categories');
        console.error('Error fetching categories:', err);
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [getAuthToken]);

  const onSubmit = async (data: any) => {
    setProductCategories(data);
    await saveStep(2, data);
  };

  const handleBack = () => {
    goToStep(1);
  };

  const handleCategoryChange = (categoryId: string, checked: boolean) => {
    const currentCategories = productCategories?.categories || [];
    let newCategories: string[];

    if (checked) {
      newCategories = [...currentCategories, categoryId];
    } else {
      newCategories = currentCategories.filter(id => id !== categoryId);
    }

    setValue('categories', newCategories);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Product Categories
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Select the categories that best describe your products
          </Typography>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Controller
            name="categories"
            control={control}
            render={({ field }) => (
              <FormControl
                component="fieldset"
                error={!!errors.categories}
                fullWidth
              >
                <FormLabel component="legend">Categories</FormLabel>
                {loadingCategories ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Paper variant="outlined" sx={{ p: 3, mt: 2 }}>
                    <FormGroup>
                      <Grid container spacing={2}>
                        {categories.map((category) => (
                          <Grid item xs={12} sm={6} md={4} key={category._id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value.includes(category._id)}
                                  onChange={(e) => handleCategoryChange(category._id, e.target.checked)}
                                />
                              }
                              label={category.name}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>

                    {field.value.length > 0 && (
                      <Box sx={{ mt: 3 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Selected Categories:
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {field.value.map((categoryId: string) => {
                            const category = categories.find(c => c._id === categoryId);
                            return category ? (
                              <Chip
                                key={categoryId}
                                label={category.name}
                                onDelete={() => handleCategoryChange(categoryId, false)}
                              />
                            ) : null;
                          })}
                        </Box>
                      </Box>
                    )}

                    {errors.categories && (
                      <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
                        {errors.categories.message}
                      </Typography>
                    )}
                  </Paper>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading || loadingCategories}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save & Continue'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductCategoriesStep;