import { useState, useEffect, useCallback } from 'react';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export interface GoogleMapsHook {
  isLoaded: boolean;
  geocoder: google.maps.Geocoder | null;
  placesService: google.maps.places.PlacesService | null;
  autocompleteService: google.maps.places.AutocompleteService | null;
  geocodeAddress: (address: string) => Promise<[number, number] | null>;
}

export const useGoogleMaps = (): GoogleMapsHook => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);

  useEffect(() => {
    // Initialize services when Google Maps is loaded
    if (window.google && window.google.maps) {
      setIsLoaded(true);
      setGeocoder(new google.maps.Geocoder());
      setAutocompleteService(new google.maps.places.AutocompleteService());

      // PlacesService requires a DOM element
      const placesDiv = document.createElement('div');
      setPlacesService(new google.maps.places.PlacesService(placesDiv));
    }
  }, []);

  const geocodeAddress = useCallback(async (address: string): Promise<[number, number] | null> => {
    if (!geocoder) return null;

    try {
      const result = await geocoder.geocode({ address });

      if (result.results[0]?.geometry?.location) {
        const location = result.results[0].geometry.location;
        return [location.lng(), location.lat()]; // Return [longitude, latitude] to match our Location type
      }

      return null;
    } catch (error) {
      console.error('Geocoding error:', error);
      return null;
    }
  }, [geocoder]);

  return {
    isLoaded,
    geocoder,
    placesService,
    autocompleteService,
    geocodeAddress,
  };
};