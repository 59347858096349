import React, { useRef, useCallback } from 'react';
import {
  List,
  Typography,
  Box,
  Button,
  CircularProgress,
  Divider,
  Alert,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
} from '@mui/material';
import { useNotifications } from '../../../contexts/NotificationContext';
import NotificationItem from './NotificationItem';
import { NotificationType } from '../../../types/notification';

interface NotificationListProps {
  maxHeight?: number | string;
  showFilters?: boolean;
  emptyMessage?: string;
  showActions?: boolean;
  compact?: boolean;
}

const NotificationList: React.FC<NotificationListProps> = ({
  maxHeight = 400,
  showFilters = true,
  emptyMessage = "You don't have any notifications yet.",
  showActions = true,
  compact = false,
}) => {
  const {
    notifications,
    loading,
    error,
    hasMore,
    fetchNotifications,
    markAllAsRead,
    filters,
    setFilters,
  } = useNotifications();

  const observer = useRef<IntersectionObserver | null>(null);

  // Set up intersection observer for infinite scrolling
  const lastNotificationRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchNotifications();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, fetchNotifications]
  );

  // Handle filter changes
  const handleTypeFilterChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFilters({
      ...filters,
      type: value === 'all' ? 'all' : (value as NotificationType),
    });
  };

  const handleReadFilterChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFilters({
      ...filters,
      read: value === 'all' ? 'all' : value === 'true',
    });
  };

  // Handle mark all as read
  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  // If there are no notifications
  if (!loading && !error && notifications.length === 0) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="body1" color="text.secondary">
          {emptyMessage}
        </Typography>
      </Paper>
    );
  }

  return (
    <Box>
      {showFilters && (
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="notification-type-filter-label">Type</InputLabel>
                <Select
                  labelId="notification-type-filter-label"
                  id="notification-type-filter"
                  value={filters.type?.toString() || 'all'}
                  label="Type"
                  onChange={handleTypeFilterChange}
                >
                  <MenuItem value="all">All Types</MenuItem>
                  <MenuItem value="lead">Leads</MenuItem>
                  <MenuItem value="payment">Payments</MenuItem>
                  <MenuItem value="billing">Billing</MenuItem>
                  <MenuItem value="system">System</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="notification-read-filter-label">Status</InputLabel>
                <Select
                  labelId="notification-read-filter-label"
                  id="notification-read-filter"
                  value={filters.read?.toString() || 'all'}
                  label="Status"
                  onChange={handleReadFilterChange}
                >
                  <MenuItem value="all">All Status</MenuItem>
                  <MenuItem value="false">Unread</MenuItem>
                  <MenuItem value="true">Read</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Button
                variant="outlined"
                onClick={handleMarkAllAsRead}
                fullWidth
                disabled={notifications.every((n) => n.read)}
              >
                Mark All as Read
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper
        sx={{
          maxHeight,
          overflow: 'auto',
          ...(compact ? { p: 0 } : { p: 1 }),
        }}
      >
        <List disablePadding={compact}>
          {notifications.map((notification, index) => {
            const isLastItem = index === notifications.length - 1;

            return (
              <React.Fragment key={notification._id}>
                <Box ref={isLastItem ? lastNotificationRef : null}>
                  <NotificationItem notification={notification} showActions={showActions} />
                </Box>
                {!isLastItem && <Divider component="li" />}
              </React.Fragment>
            );
          })}

          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default NotificationList;