import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Snackbar,
  Tabs,
  Tab,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import axiosInstance from '../../services/axios';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`service-content-tabpanel-${index}`}
      aria-labelledby={`service-content-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface ServiceContent {
  id: string;
  title: string;
  benefits: string[];
  faqs: Array<{ question: string; answer: string }>;
  whatToExpect: string;
  relatedServices: string[];
}

const ServiceContentManager: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [serviceContents, setServiceContents] = useState<ServiceContent[]>([]);
  const [selectedContent, setSelectedContent] = useState<ServiceContent | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingField, setEditingField] = useState<string>('');
  const [editingValue, setEditingValue] = useState<string>('');
  const [editingFAQ, setEditingFAQ] = useState<{ question: string; answer: string }>({
    question: '',
    answer: '',
  });
  const [editingBenefit, setEditingBenefit] = useState<string>('');
  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch service content
        const contentResponse = await axiosInstance.get('/service-content');
        setServiceContents(contentResponse.data.serviceContent || []);

        // Fetch categories for mapping
        const categoriesResponse = await axiosInstance.get('/categories');
        setCategories(categoriesResponse.data || []);
      } catch (err: any) {
        console.error('Error fetching data:', err);
        setError(err.message || 'Failed to load service content');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleEditContent = (content: ServiceContent) => {
    setSelectedContent(content);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedContent(null);
  };

  const handleSaveContent = async () => {
    if (!selectedContent) return;

    try {
      setLoading(true);
      // In a real implementation, you would call an API to save the content
      // await axiosInstance.put(`/service-content/${selectedContent.id}`, selectedContent);

      // For now, just update the local state
      setServiceContents(prevContents =>
        prevContents.map(content =>
          content.id === selectedContent.id ? selectedContent : content
        )
      );

      setSuccess('Service content updated successfully');
      setEditDialogOpen(false);
    } catch (err: any) {
      console.error('Error saving content:', err);
      setError(err.message || 'Failed to save service content');
    } finally {
      setLoading(false);
    }
  };

  const handleAddBenefit = () => {
    if (!selectedContent || !editingBenefit.trim()) return;

    setSelectedContent({
      ...selectedContent,
      benefits: [...selectedContent.benefits, editingBenefit.trim()],
    });

    setEditingBenefit('');
  };

  const handleRemoveBenefit = (index: number) => {
    if (!selectedContent) return;

    setSelectedContent({
      ...selectedContent,
      benefits: selectedContent.benefits.filter((_, i) => i !== index),
    });
  };

  const handleAddFAQ = () => {
    if (!selectedContent || !editingFAQ.question.trim() || !editingFAQ.answer.trim()) return;

    setSelectedContent({
      ...selectedContent,
      faqs: [...selectedContent.faqs, { ...editingFAQ }],
    });

    setEditingFAQ({ question: '', answer: '' });
  };

  const handleRemoveFAQ = (index: number) => {
    if (!selectedContent) return;

    setSelectedContent({
      ...selectedContent,
      faqs: selectedContent.faqs.filter((_, i) => i !== index),
    });
  };

  const handleUpdateWhatToExpect = (value: string) => {
    if (!selectedContent) return;

    setSelectedContent({
      ...selectedContent,
      whatToExpect: value,
    });
  };

  if (loading && serviceContents.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>Loading service content...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Service Content Manager
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Manage the static content for different service types
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess(null)}
        message={success}
      />

      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Service Content" />
          <Tab label="Categories Mapping" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={3}>
            {serviceContents.map((content) => (
              <Grid item xs={12} md={6} key={content.id}>
                <Paper sx={{ p: 3, height: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h6">{content.title}</Typography>
                    <Button
                      startIcon={<EditIcon />}
                      onClick={() => handleEditContent(content)}
                    >
                      Edit
                    </Button>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  <Typography variant="subtitle1" gutterBottom>
                    Benefits: {content.benefits.length}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    FAQs: {content.faqs.length}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Related Services: {content.relatedServices.length}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Typography variant="h6" gutterBottom>
            Category to Service Content Mapping
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            This feature is coming soon. It will allow you to map categories to specific service content.
          </Typography>
        </TabPanel>
      </Paper>

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        maxWidth="md"
        fullWidth
      >
        {selectedContent && (
          <>
            <DialogTitle>
              <Typography variant="h6">Edit {selectedContent.title} Content</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Benefits
                </Typography>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <TextField
                    fullWidth
                    label="Add Benefit"
                    value={editingBenefit}
                    onChange={(e) => setEditingBenefit(e.target.value)}
                    sx={{ mr: 1 }}
                  />
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddBenefit}
                    disabled={!editingBenefit.trim()}
                  >
                    Add
                  </Button>
                </Box>
                <List>
                  {selectedContent.benefits.map((benefit, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={benefit} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveBenefit(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>

              <Divider sx={{ my: 3 }} />

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  What to Expect
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={selectedContent.whatToExpect}
                  onChange={(e) => handleUpdateWhatToExpect(e.target.value)}
                />
              </Box>

              <Divider sx={{ my: 3 }} />

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  FAQs
                </Typography>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Question"
                      value={editingFAQ.question}
                      onChange={(e) =>
                        setEditingFAQ({ ...editingFAQ, question: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Answer"
                      value={editingFAQ.answer}
                      onChange={(e) =>
                        setEditingFAQ({ ...editingFAQ, answer: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleAddFAQ}
                      disabled={!editingFAQ.question.trim() || !editingFAQ.answer.trim()}
                      sx={{ height: '100%' }}
                    >
                      Add FAQ
                    </Button>
                  </Grid>
                </Grid>
                <List>
                  {selectedContent.faqs.map((faq, index) => (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={faq.question}
                        secondary={faq.answer}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveFAQ(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog}>Cancel</Button>
              <Button
                onClick={handleSaveContent}
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
};

export default ServiceContentManager;