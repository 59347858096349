import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/layout/Layout';
import theme from './theme';
import ProtectedRoute from './components/common/ProtectedRoute';
import RoleBasedRedirect from './components/common/RoleBasedRedirect';
import AuthInitializer from './components/auth/AuthInitializer';
import AdminRoute from './components/admin/AdminRoute';
import analytics from './utils/analytics';
import { NotificationProvider } from './contexts/NotificationContext';

// Pages
import Home from './pages/Home';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import VerifyEmail from './pages/auth/VerifyEmail';
import ResendVerification from './pages/auth/ResendVerification';
import Dashboard from './pages/dashboard/Dashboard';
import ProfileSetup from './pages/profile/ProfileSetup';

// Provider routes
import ProviderRoute from './components/pro/ProviderRoute';
import SellerRoute from './components/seller/providers/SellerRoute';
import ProviderRegister from './pages/pro/register';
import ProviderOnboarding from './components/pro/onboarding/Onboarding';
import ProviderDashboard from './pages/pro/dashboard/Dashboard';
import ProviderProfile from './pages/profile/ProProfile';
import PublicProviderProfile from './pages/pro/PublicProviderProfile';
import ServiceManagement from './pages/pro/ServiceManagement';
import Search from './pages/search/Search';
import SearchOptimized from './pages/search/SearchOptimized';
import FindProfessionals from './pages/search/FindProfessionals';
import ServiceDetails from './pages/service/ServiceDetails';
import EnhancedCreateLeadPage from './pages/service/EnhancedCreateLeadPage';
import LeadRequests from './pages/pro/LeadRequests';
import LeadDetailPage from './pages/pro/LeadDetailPage';
import Messages from './pages/messages/Messages';
import NotificationsPage from './pages/pro/notifications/NotificationsPage';
import CalendarPage from './pages/pro/calendar/CalendarPage';
import ServiceContentManager from './pages/admin/ServiceContentManager';
import AdminDashboard from './pages/admin/Dashboard';
import ProviderManagement from './pages/admin/ProviderManagement';
import ProviderDetail from './pages/admin/ProviderDetail';
import StoryManagement from './pages/admin/StoryManagement';

// Ideas Pages
import {
  IdeabooksPage,
  IdeabookDetailPage,
  IdeabookCreatePage,
  IdeabookEditPage,
  RoomsPage,
  RoomDetailPage,
  ProjectDetailPage,
  ProviderProjectsPage,
  ProviderProjectDetailPage,
  ProductListPage,
  ProListPage,
} from './pages/ideas';

// Resources Pages
import ResourcesIndex from './pages/resources/ResourcesIndex';
import ResourcesLayout from './pages/resources/ResourcesLayout';
import StoriesIndex from './pages/resources/stories/StoriesIndex';
import StoryDetail from './pages/resources/stories/StoryDetail';
import EstimatorsIndex from './pages/resources/estimators/EstimatorsIndex';
import KitchenEstimator from './pages/resources/estimators/KitchenEstimator';
import GuidesIndex from './pages/resources/guides/GuidesIndex';

// Services Pages
import ServicesIndex from './pages/services/ServicesIndex';
import ServiceCategoryPage from './pages/services/ServiceCategoryPage';

// Marketplace Pages
import { MarketplacePage } from './pages/marketplace/MarketplacePage';
import { CartPage } from './pages/marketplace/CartPage';
import ProductPage from './pages/marketplace/ProductPage';
import CheckoutPage from './pages/marketplace/CheckoutPage';
import CheckoutShippingPage from './pages/marketplace/CheckoutShippingPage';
import CheckoutPaymentPage from './pages/marketplace/CheckoutPaymentPage';
import CheckoutConfirmationPage from './pages/marketplace/CheckoutConfirmationPage';
import { SellerPortalPage } from './pages/marketplace/seller/SellerPortalPage';

// Seller Pages
import SellerRegister from './pages/seller/register/RegisterPage';
import SellerOnboarding from './pages/seller/onboarding/SellerOnboarding';
import SellerOnboardingProvider from './components/seller/providers/SellerOnboardingProvider';
import SellerOnboardingCheck from './components/seller/providers/SellerOnboardingCheck';

// Auth Components
import GoogleAuthCallback from './components/auth/GoogleAuthCallback';

// Route logger component to help debug routing and track page views
const RouteLogger: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    // Track page view in analytics
    analytics.trackPageView({
      path: location.pathname,
      title: document.title,
    });
  }, [location]);

  return null;
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthInitializer>
            <NotificationProvider>
              <RouteLogger />
              <Routes>
                {/* Public Routes */}
                <Route
                  path="/"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route
                    index
                    element={
                      <RoleBasedRedirect>
                        <Home />
                      </RoleBasedRedirect>
                    }
                  />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="search" element={<Search />} />
                  <Route path="search-optimized" element={<SearchOptimized />} />
                  <Route path="find-professionals" element={<FindProfessionals />} />
                  <Route path="services/:id" element={<ServiceDetails />} />
                </Route>

                {/* Auth Routes */}
                <Route path="/auth">
                  <Route path="google/callback" element={<GoogleAuthCallback />} />
                  <Route path="error" element={<GoogleAuthCallback />} />
                  <Route path="verify-email" element={<VerifyEmail />} />
                  <Route path="resend-verification" element={<ResendVerification />} />
                </Route>

                {/* Pro Routes */}
                <Route
                  path="/pro"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  {/* Public pro routes */}
                  <Route path="register" element={<ProviderRegister />} />
                  {/* Redirect /pro/login to the main login page */}
                  <Route path="login" element={<Navigate to="/login" replace />} />

                  {/* Protected pro routes */}
                  <Route
                    element={
                      <ProviderRoute>
                        <Outlet />
                      </ProviderRoute>
                    }
                  >
                    {/* Keep only the ProviderOnboarding route (from components directory) */}
                    <Route path="onboarding/*" element={<ProviderOnboarding />} />
                    <Route path="dashboard" element={<ProviderDashboard />} />
                    <Route path="profile" element={<ProviderProfile />} />
                    <Route path="services" element={<ServiceManagement />} />
                    <Route path="leads" element={<LeadRequests />} />
                    <Route path="leads/:leadId" element={<LeadDetailPage />} />
                    <Route path="messages" element={<Messages />} />
                    <Route path="notifications" element={<NotificationsPage />} />
                    <Route path="calendar" element={<CalendarPage />} />
                    <Route path="payment" element={<ProfileSetup />} />
                    {/* Remove the duplicate OnboardingPage route */}
                  </Route>
                </Route>
                {/* Public provider profile route - in a separate route to avoid conflicts */}
                <Route
                  path="/pro/:id"
                  element={
                    <Layout>
                      <PublicProviderProfile />
                    </Layout>
                  }
                />

                {/* Protected Routes */}
                <Route
                  path="/dashboard"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route
                    element={
                      <ProtectedRoute>
                        <Outlet />
                      </ProtectedRoute>
                    }
                  >
                    <Route index element={<Dashboard />} />
                    <Route path="profile-setup" element={<ProfileSetup />} />
                    <Route path="settings" element={<ProfileSetup />} />
                  </Route>
                </Route>

                {/* Admin Routes */}
                <Route
                  path="/admin"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route
                    element={
                      <AdminRoute>
                        <Outlet />
                      </AdminRoute>
                    }
                  >
                    <Route index element={<Navigate to="/admin/dashboard" replace />} />
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="providers" element={<ProviderManagement />} />
                    <Route path="providers/:providerId" element={<ProviderDetail />} />
                    <Route path="service-content" element={<ServiceContentManager />} />
                    <Route path="stories" element={<StoryManagement />} />
                  </Route>
                </Route>

                {/* Service Lead Routes */}
                <Route
                  path="/services"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  {/* Lead creation routes */}
                  <Route path=":serviceId/enhanced-lead" element={<EnhancedCreateLeadPage />} />
                  <Route path="enhanced-lead" element={<EnhancedCreateLeadPage />} />
                </Route>

                {/* Marketplace Routes */}
                <Route
                  path="/marketplace"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  {/* Public marketplace routes */}
                  <Route index element={<MarketplacePage />} />
                  <Route path="products/:id" element={<ProductPage />} />
                  <Route path="category/:categoryId" element={<MarketplacePage />} />
                  <Route path="bestsellers" element={<MarketplacePage />} />
                  <Route path="new-arrivals" element={<MarketplacePage />} />
                  <Route path="cart" element={<CartPage />} />
                  <Route path="checkout" element={<CheckoutPage />} />
                  <Route path="checkout/shipping" element={<CheckoutShippingPage />} />
                  <Route path="checkout/payment" element={<CheckoutPaymentPage />} />
                  <Route path="checkout/confirmation" element={<CheckoutConfirmationPage />} />

                  {/* Protected marketplace routes */}
                  <Route
                    element={
                      <ProtectedRoute>
                        <Outlet />
                      </ProtectedRoute>
                    }
                  >
                    {/* Redirect old seller routes to new location */}
                    <Route path="seller/*" element={<Navigate to="/seller/dashboard" replace />} />
                  </Route>
                </Route>

                {/* Seller Routes */}
                <Route
                  path="/seller"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route path="register" element={<SellerRegister />} />

                  {/* Protected seller routes */}
                  <Route
                    element={
                      <SellerRoute>
                        <SellerOnboardingProvider>
                          <SellerOnboardingCheck>
                            <Outlet />
                          </SellerOnboardingCheck>
                        </SellerOnboardingProvider>
                      </SellerRoute>
                    }
                  >
                    <Route path="onboarding/:step" element={<SellerOnboarding />} />
                    <Route path="dashboard/*" element={<SellerPortalPage />} />
                    <Route
                      path="products/*"
                      element={<Navigate to="/seller/dashboard/products" replace />}
                    />
                    <Route
                      path="profile/*"
                      element={<Navigate to="/seller/dashboard/profile" replace />}
                    />
                  </Route>
                </Route>

                {/* Ideas Routes */}
                <Route
                  path="/ideas"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  {/* Public ideas routes */}
                  <Route index element={<RoomsPage />} />
                  <Route path="rooms" element={<RoomsPage />} />
                  <Route path="rooms/:roomId" element={<RoomDetailPage />} />
                  <Route path="projects/:projectId" element={<ProjectDetailPage />} />
                  <Route path="provider-projects" element={<ProviderProjectsPage />} />
                  <Route path="provider-projects/:id" element={<ProviderProjectDetailPage />} />

                  {/* Protected ideas routes */}
                  <Route
                    element={
                      <ProtectedRoute>
                        <Outlet />
                      </ProtectedRoute>
                    }
                  >
                    <Route path="ideabooks" element={<IdeabooksPage />} />
                    <Route path="ideabooks/new" element={<IdeabookCreatePage />} />
                    <Route path="ideabooks/:id" element={<IdeabookDetailPage />} />
                    <Route path="ideabooks/:id/edit" element={<IdeabookEditPage />} />
                    <Route path="products-list" element={<ProductListPage />} />
                    <Route path="pro-list" element={<ProListPage />} />
                  </Route>
                </Route>

                {/* Resources Routes */}
                <Route
                  path="/resources"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  {/* Main resources routes */}
                  <Route index element={<ResourcesIndex />} />

                  {/* Resources layout with tabs */}
                  <Route element={<ResourcesLayout />}>
                    {/* Stories routes */}
                    <Route path="stories" element={<StoriesIndex />} />
                    <Route path="stories/:storyId" element={<StoryDetail />} />

                    {/* Estimators routes */}
                    <Route path="estimators" element={<EstimatorsIndex />} />
                    <Route path="estimators/kitchen-renovation" element={<KitchenEstimator />} />

                    {/* Guides routes */}
                    <Route path="guides" element={<GuidesIndex />} />
                  </Route>
                </Route>

                {/* Services Routes */}
                <Route
                  path="/services"
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route index element={<ServicesIndex />} />
                  <Route path="category/:categorySlug" element={<ServiceCategoryPage />} />
                  {/* Keep the existing service detail route */}
                  <Route path=":id" element={<ServiceDetails />} />
                </Route>
              </Routes>
            </NotificationProvider>
          </AuthInitializer>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
