import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Alert,
  Button,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Store as StoreIcon,
  Assignment as AssignmentIcon,
  Settings as SettingsIcon,
  VerifiedUser as VerifiedUserIcon,
  PendingActions as PendingActionsIcon,
  LocalOffer as LocalOfferIcon,
  Article as ArticleIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import axiosInstance from '../../services/axios';

interface VerificationStats {
  total: number;
  verified: number;
  unverified: number;
  active: number;
  onboarded: number;
  pending: number;
}

interface DashboardCounts {
  providers: number;
  sellers: number;
  customers: number;
  leads: number;
  products: number;
}

const AdminDashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [verificationStats, setVerificationStats] = useState<VerificationStats | null>(null);
  const [counts, setCounts] = useState<DashboardCounts>({
    providers: 0,
    sellers: 0,
    customers: 0,
    leads: 0,
    products: 0,
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        
        // Fetch verification stats
        const statsResponse = await axiosInstance.get('/admin/stats/verification');
        setVerificationStats(statsResponse.data);
        
        // In a real implementation, you would fetch all these counts
        // For now, we'll just use the verification stats for providers
        if (statsResponse.data) {
          setCounts({
            ...counts,
            providers: statsResponse.data.total || 0,
          });
        }
        
      } catch (err: any) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message || 'Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>Loading dashboard data...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Dashboard
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Quick Stats */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Providers
              </Typography>
              <Typography variant="h3">{counts.providers}</Typography>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" color="text.secondary">
                  Verified: {verificationStats?.verified || 0}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Pending: {verificationStats?.pending || 0}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Sellers
              </Typography>
              <Typography variant="h3">{counts.sellers}</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Manage product listings and seller accounts
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Customers
              </Typography>
              <Typography variant="h3">{counts.customers}</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  View and manage customer accounts
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Admin Menu */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Admin Menu
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              <ListItem button component={RouterLink} to="/admin/dashboard">
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem button component={RouterLink} to="/admin/providers">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Providers" />
              </ListItem>
              <ListItem button component={RouterLink} to="/admin/sellers">
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Sellers" />
              </ListItem>
              <ListItem button component={RouterLink} to="/admin/customers">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Customers" />
              </ListItem>
              <ListItem button component={RouterLink} to="/admin/leads">
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Leads" />
              </ListItem>
              <ListItem button component={RouterLink} to="/admin/products">
                <ListItemIcon>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Products" />
              </ListItem>
              <ListItem button component={RouterLink} to="/admin/service-content">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Service Content" />
              </ListItem>
              <ListItem button component={RouterLink} to="/admin/stories">
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Stories" />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Recent Activity */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Pending Approvals
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardHeader 
                    avatar={<VerifiedUserIcon color="primary" />}
                    title="Provider Verifications"
                  />
                  <CardContent>
                    <Typography variant="h4" align="center">
                      {verificationStats?.pending || 0}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                      Pending provider verifications
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <Button 
                        variant="contained" 
                        component={RouterLink} 
                        to="/admin/providers?filter=pending"
                      >
                        Review
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardHeader 
                    avatar={<PendingActionsIcon color="primary" />}
                    title="Seller Approvals"
                  />
                  <CardContent>
                    <Typography variant="h4" align="center">
                      0
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                      Pending seller approvals
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <Button 
                        variant="contained" 
                        component={RouterLink} 
                        to="/admin/sellers?filter=pending"
                      >
                        Review
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Recent Activity
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body2" color="text.secondary" sx={{ py: 2 }}>
                Recent activity will be displayed here. This feature is coming soon.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;