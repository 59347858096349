import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useOnboarding } from '../../../../contexts/OnboardingContext';
import { OnboardingStepId } from '../../../../types/onboarding/shared';
import { useOnboardingStepData, useSaveStep } from '../../../../hooks/api/useOnboardingApi';
import { OnboardingLayout } from '../OnboardingLayout';
import axios from '../../../../services/axios';

interface Service {
  _id: string;
  title: string;
  description: string;
  category: {
    _id: string;
    name: string;
  };
}

/**
 * Services step component
 */
const ServicesStep: React.FC = () => {
  const navigate = useNavigate();
  const { getNextStep, getPreviousStep } = useOnboarding();
  const { data: stepData, isLoading: isDataLoading } = useOnboardingStepData<string[]>(
    OnboardingStepId.SERVICES
  );
  const { mutateAsync: saveStepAsync } = useSaveStep();

  const [services, setServices] = useState<Service[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingServices, setLoadingServices] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  // Load existing data if available
  useEffect(() => {
    if (stepData && stepData.length > 0) {
      setSelectedServices(stepData);
    }
  }, [stepData]);

  // Fetch available services
  useEffect(() => {
    const fetchServices = async () => {
      setLoadingServices(true);
      setError(null);

      try {
        // Fetch services from the API
        const response = await axios.get('/services');

        if (response.data && response.data.services) {
          setServices(response.data.services);
        } else {
          setError('Failed to load services. Unexpected API response format.');
        }
      } catch (err: any) {
        setError('Failed to load services. Please try again.');
        console.error('Error fetching services:', err);
      } finally {
        setLoadingServices(false);
      }
    };

    fetchServices();
  }, []);

  const handleToggleService = (serviceId: string) => {
    setSelectedServices((prev) => {
      if (prev.includes(serviceId)) {
        return prev.filter((id) => id !== serviceId);
      } else {
        return [...prev, serviceId];
      }
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const filteredServices = services.filter(
    (service) =>
      service.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBack = () => {
    const prevStep = getPreviousStep(OnboardingStepId.SERVICES);
    if (prevStep) {
      navigate(`/pro/onboarding/${prevStep}`);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (selectedServices.length === 0) {
      setError('Please select at least one service');
      return;
    }

    setIsSaving(true);
    setError(null);

    try {
      // Save to server
      const result = await saveStepAsync({
        stepId: OnboardingStepId.SERVICES,
        data: selectedServices,
      });

      if (result.success) {
        const nextStep = getNextStep(OnboardingStepId.SERVICES);
        if (nextStep) {
          navigate(`/pro/onboarding/${nextStep}`);
        }
      } else {
        setError('Failed to save services. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while saving. Please try again.');
      console.error('Error saving services:', err);
    } finally {
      setIsSaving(false);
    }
  };

  if (isDataLoading || loadingServices) {
    return (
      <OnboardingLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title="Services Offered"
      description="Select the services you offer to customers. You can add more services later."
    >
      <Box component="form" onSubmit={handleSubmit} noValidate>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <TextField
          fullWidth
          placeholder="Search services..."
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 3 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch} edge="end" size="small">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Paper variant="outlined" sx={{ mb: 3, maxHeight: 400, overflow: 'auto' }}>
          {filteredServices.length === 0 ? (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography color="text.secondary">
                {searchTerm.length > 0
                  ? 'No services found matching your search.'
                  : 'No services available. Please try again later.'}
              </Typography>
            </Box>
          ) : (
            <List>
              {filteredServices.map((service, index) => (
                <React.Fragment key={service._id}>
                  <ListItem
                    button
                    onClick={() => handleToggleService(service._id)}
                    disabled={isSaving}
                  >
                    <Checkbox
                      checked={selectedServices.includes(service._id)}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText
                      primary={service.title}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            {service.category.name}
                          </Typography>
                          {' — '}
                          {service.description}
                        </>
                      }
                    />
                  </ListItem>
                  {index < filteredServices.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          )}
        </Paper>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Selected Services ({selectedServices.length})
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selectedServices.length === 0 ? (
              <Typography color="text.secondary">No services selected</Typography>
            ) : (
              selectedServices.map((serviceId) => {
                const service = services.find((s) => s._id === serviceId);
                return (
                  <Chip
                    key={serviceId}
                    label={service?.title || serviceId}
                    onDelete={() => handleToggleService(serviceId)}
                    disabled={isSaving}
                  />
                );
              })
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleBack} disabled={isSaving}>
            Back
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={selectedServices.length === 0 || isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {isSaving ? 'Saving...' : 'Continue'}
          </Button>
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default ServicesStep;
