import { ICategory } from './models';

export interface Location {
  address: string;
  coordinates: [number, number];  // [longitude, latitude]
  radius: number;
}

export interface AdvancedFilters {
  priceRange?: [number, number];
  rating?: number;
  availability?: {
    date: Date;
    timeSlot?: string;
  };
  startTime?: string;
  projectScope?: string[];
  electricalRelocation?: string;
  plumbingRelocation?: string;
  professionalCategory?: string[];
  projectType?: string[];
  minRating?: number;
  specificDate?: Date | null;
}

export interface SearchFilters {
  query?: string;
  category?: ICategory;
  location?: Location;
  priceRange?: {
    min: number;
    max: number;
  };
  rating?: number;
  availability?: {
    date: Date;
    timeSlot?: string;
  };
  sortBy?: 'price' | 'rating' | 'distance' | 'relevance';
  sortOrder?: 'asc' | 'desc';
  page?: number;
  limit?: number;
}

export interface SearchParams {
  query?: string;
  category?: ICategory;
  location?: Location;
  filters?: AdvancedFilters;
}

export interface SearchSuggestion {
  type: 'service' | 'category' | 'provider';
  id: string;
  title: string;
  subtitle?: string;
  image?: string;
}

export interface SearchAnalytics {
  query: string;
  timestamp: number;
  resultCount: number;
  filters: SearchFilters;
  userLocation?: Location;
}

export const convertFiltersToParams = (filters: SearchFilters): SearchParams => {
  const params: SearchParams = {};

  if (filters.query) {
    params.query = filters.query;
  }

  if (filters.category) {
    params.category = filters.category;
  }

  if (filters.location) {
    params.location = filters.location;
  }

  if (filters.priceRange || filters.rating || filters.availability) {
    params.filters = {};

    if (filters.priceRange) {
      params.filters.priceRange = [filters.priceRange.min, filters.priceRange.max];
    }

    if (filters.rating) {
      params.filters.rating = filters.rating;
    }

    if (filters.availability) {
      params.filters.availability = filters.availability;
    }
  }

  return params;
};

export const convertParamsToFilters = (params: SearchParams): SearchFilters => {
  const filters: SearchFilters = {};

  if (params.query) {
    filters.query = params.query;
  }

  if (params.category) {
    filters.category = params.category;
  }

  if (params.location) {
    filters.location = params.location;
  }

  if (params.filters) {
    if (params.filters.priceRange) {
      filters.priceRange = {
        min: params.filters.priceRange[0],
        max: params.filters.priceRange[1],
      };
    }

    if (params.filters.rating) {
      filters.rating = params.filters.rating;
    }

    if (params.filters.availability) {
      filters.availability = params.filters.availability;
    }
  }

  return filters;
};