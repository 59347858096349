import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Box,
  Typography,
  Button,
  LinearProgress,
  Chip,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { OnboardingStepId, STEP_ORDER } from '../../../types/onboarding/shared';

// Updated props interface
interface OnboardingBannerProps {
  currentStep?: OnboardingStepId;
  onDismiss?: () => void;
  showOnDashboard?: boolean;
}

const OnboardingBanner: React.FC<OnboardingBannerProps> = ({
  currentStep: propCurrentStep,
  onDismiss,
  showOnDashboard = true,
}) => {
  const navigate = useNavigate();

  // Initialize with default values
  const currentStepId = propCurrentStep !== undefined ? propCurrentStep : OnboardingStepId.BUSINESS_PROFILE;

  // Only check if we're on the dashboard
  const isDashboard = window.location.pathname.includes('/dashboard');
  if (!isDashboard && !showOnDashboard) {
    return null;
  }

  // We've simplified the logic - the banner will always show unless explicitly dismissed
  // The showOnDashboard prop is now used to determine if the banner should be shown on the dashboard
  // For non-dashboard pages, we always show the banner

  // Calculate progress percentage
  const totalSteps = Object.keys(STEP_ORDER).length; // Use the length of STEP_ORDER object for total steps
  const currentStepOrder = STEP_ORDER[currentStepId];
  const progress = Math.min(Math.round((currentStepOrder / (totalSteps - 1)) * 100), 100); // Ensure progress doesn't exceed 100%

  // Get step name
  const getStepName = () => {
    switch (currentStepId) {
      case OnboardingStepId.BUSINESS_PROFILE:
        return 'Business Profile';
      case OnboardingStepId.AVAILABILITY:
        return 'Availability';
      case OnboardingStepId.SERVICES:
        return 'Services';
      case OnboardingStepId.TARGET_PREFERENCES:
        return 'Target Preferences';
      case OnboardingStepId.SERVICE_AREAS:
        return 'Service Areas';
      case OnboardingStepId.BUDGET_SETTINGS:
        return 'Budget Settings';
      case OnboardingStepId.PAYMENT_INFORMATION:
        return 'Payment Information';
      default:
        return 'Onboarding';
    }
  };

  // Handle continue button click
  const handleContinue = () => {
    navigate(`/pro/onboarding/${currentStepId}`);
  };

  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        mb: 4,
        position: 'relative',
        borderLeft: '4px solid',
        borderColor: 'primary.main',
      }}
    >
      {onDismiss && (
        <Box sx={{ position: 'absolute', top: 12, right: 12 }}>
          <Button
            size="small"
            onClick={onDismiss}
            sx={{ minWidth: 'auto', p: 0.5 }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        </Box>
      )}

      <Typography variant="h6" gutterBottom>
        Complete Your Profile Setup
      </Typography>

      <Typography variant="body2" color="text.secondary" paragraph>
        You're on your way to receiving leads! Continue setting up your profile to start connecting with customers.
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box sx={{ flexGrow: 1, mr: 2 }}>
          <LinearProgress variant="determinate" value={progress} sx={{ height: 8, borderRadius: 4 }} />
        </Box>
        <Typography variant="body2" color="text.secondary">
          {progress}% Complete
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="body2" color="text.secondary">
            Next step:
          </Typography>
          <Chip
            label={getStepName()}
            color="primary"
            variant="outlined"
            size="small"
            sx={{ mt: 0.5 }}
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleContinue}
        >
          Continue Setup
        </Button>
      </Box>
    </Paper>
  );
};

export default OnboardingBanner;