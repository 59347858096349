import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import notificationService from '../services/notificationService';
import {
  Notification,
  NotificationCountResponse,
  NotificationFilters,
  NotificationPreferences,
} from '../types/notification';

interface NotificationContextType {
  notifications: Notification[];
  counts: NotificationCountResponse | null;
  preferences: NotificationPreferences | null;
  loading: boolean;
  error: string | null;
  hasMore: boolean;
  page: number;
  filters: NotificationFilters;
  fetchNotifications: (reset?: boolean) => Promise<void>;
  fetchCounts: () => Promise<void>;
  fetchPreferences: () => Promise<void>;
  markAsRead: (notificationId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  deleteNotification: (notificationId: string) => Promise<void>;
  updatePreferences: (preferences: Partial<NotificationPreferences>) => Promise<void>;
  setFilters: (newFilters: NotificationFilters) => void;
}

const defaultCounts: NotificationCountResponse = {
  total: 0,
  unread: 0,
  byType: {
    lead: 0,
    billing: 0,
    payment: 0,
    system: 0,
  },
};

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotifications = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [counts, setCounts] = useState<NotificationCountResponse | null>(null);
  const [preferences, setPreferences] = useState<NotificationPreferences | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<NotificationFilters>({
    type: 'all',
    read: 'all',
  });

  const fetchNotifications = useCallback(
    async (reset = false) => {
      if (!isAuthenticated) return;

      try {
        setLoading(true);
        setError(null);

        const currentPage = reset ? 1 : page;
        const response = await notificationService.getNotifications(currentPage, 10, filters);

        if (reset) {
          setNotifications(response.notifications);
        } else {
          setNotifications((prev) => [...prev, ...response.notifications]);
        }

        setHasMore(response.pagination.hasMore);
        setPage(reset ? 2 : page + 1);
      } catch (err) {
        console.error('Error fetching notifications:', err);
        setError('Failed to load notifications. Please try again later.');
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated, page, filters]
  );

  const fetchCounts = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const response = await notificationService.getNotificationCounts();
      setCounts(response);
    } catch (err) {
      console.error('Error fetching notification counts:', err);
      // Don't set error here to avoid disrupting the UI
    }
  }, [isAuthenticated]);

  const fetchPreferences = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const response = await notificationService.getNotificationPreferences();
      setPreferences(response);
    } catch (err) {
      console.error('Error fetching notification preferences:', err);
      // Don't set error here to avoid disrupting the UI
    }
  }, [isAuthenticated]);

  const markAsRead = useCallback(
    async (notificationId: string) => {
      try {
        await notificationService.updateNotification(notificationId, { read: true });

        // Update local state
        setNotifications((prev) =>
          prev.map((notification) =>
            notification._id === notificationId ? { ...notification, read: true } : notification
          )
        );

        // Update counts
        await fetchCounts();
      } catch (err) {
        console.error('Error marking notification as read:', err);
        setError('Failed to update notification. Please try again later.');
      }
    },
    [fetchCounts]
  );

  const markAllAsRead = useCallback(async () => {
    try {
      await notificationService.markAllAsRead();

      // Update local state
      setNotifications((prev) => prev.map((notification) => ({ ...notification, read: true })));

      // Update counts
      await fetchCounts();
    } catch (err) {
      console.error('Error marking all notifications as read:', err);
      setError('Failed to update notifications. Please try again later.');
    }
  }, [fetchCounts]);

  const deleteNotification = useCallback(
    async (notificationId: string) => {
      try {
        await notificationService.deleteNotification(notificationId);

        // Update local state
        setNotifications((prev) => prev.filter((notification) => notification._id !== notificationId));

        // Update counts
        await fetchCounts();
      } catch (err) {
        console.error('Error deleting notification:', err);
        setError('Failed to delete notification. Please try again later.');
      }
    },
    [fetchCounts]
  );

  const updatePreferences = useCallback(async (newPreferences: Partial<NotificationPreferences>) => {
    try {
      const response = await notificationService.updateNotificationPreferences(newPreferences as any);
      setPreferences(response);
    } catch (err) {
      console.error('Error updating notification preferences:', err);
      setError('Failed to update notification preferences. Please try again later.');
    }
  }, []);

  const handleSetFilters = useCallback((newFilters: NotificationFilters) => {
    setFilters(newFilters);
    // Reset pagination when filters change
    setPage(1);
  }, []);

  // Initial data fetch
  useEffect(() => {
    if (isAuthenticated) {
      fetchNotifications(true);
      fetchCounts();
      fetchPreferences();
    } else {
      // Reset state when user logs out
      setNotifications([]);
      setCounts(defaultCounts);
      setPreferences(null);
    }
  }, [isAuthenticated, fetchNotifications, fetchCounts, fetchPreferences]);

  // Refetch when filters change
  useEffect(() => {
    if (isAuthenticated) {
      fetchNotifications(true);
    }
  }, [filters, isAuthenticated, fetchNotifications]);

  // Set up polling for notification counts
  useEffect(() => {
    if (!isAuthenticated) return;

    const interval = setInterval(() => {
      fetchCounts();
    }, 60000); // Poll every minute

    return () => clearInterval(interval);
  }, [isAuthenticated, fetchCounts]);

  const value = {
    notifications,
    counts,
    preferences,
    loading,
    error,
    hasMore,
    page,
    filters,
    fetchNotifications,
    fetchCounts,
    fetchPreferences,
    markAsRead,
    markAllAsRead,
    deleteNotification,
    updatePreferences,
    setFilters: handleSetFilters,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};