import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';

// Validation schema
const contactInfoSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  'address.street': yup.string().required('Street address is required'),
  'address.city': yup.string().required('City is required'),
  'address.emirate': yup.string().required('Emirate is required'),
  'address.country': yup.string().required('Country is required'),
});

const ContactInfoStep: React.FC = () => {
  const { contactInfo, setContactInfo, saveStep, isLoading, goToStep } = useSellerOnboarding();

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(contactInfoSchema),
    defaultValues: {
      email: contactInfo?.email || '',
      phone: contactInfo?.phone || '',
      'address.street': contactInfo?.address?.street || '',
      'address.city': contactInfo?.address?.city || '',
      'address.emirate': contactInfo?.address?.emirate || 'Dubai',
      'address.country': contactInfo?.address?.country || 'United Arab Emirates',
    },
  });

  const emirates = [
    'Abu Dhabi',
    'Dubai',
    'Sharjah',
    'Ajman',
    'Umm Al Quwain',
    'Ras Al Khaimah',
    'Fujairah',
  ];

  const onSubmit = async (data: any) => {
    const formattedData = {
      email: data.email,
      phone: data.phone,
      address: {
        street: data['address.street'],
        city: data['address.city'],
        emirate: data['address.emirate'],
        country: data['address.country'],
      },
    };

    setContactInfo(formattedData);
    await saveStep(1, formattedData);
  };

  const handleBack = () => {
    goToStep(0);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Provide contact details for your business
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contact Email"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contact Phone"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Address
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="address.street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Street Address"
                fullWidth
                error={!!errors['address.street']}
                helperText={errors['address.street']?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="address.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="City"
                fullWidth
                error={!!errors['address.city']}
                helperText={errors['address.city']?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="address.emirate"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors['address.emirate']}>
                <InputLabel>Emirate</InputLabel>
                <Select {...field} label="Emirate">
                  {emirates.map((emirate) => (
                    <MenuItem key={emirate} value={emirate}>
                      {emirate}
                    </MenuItem>
                  ))}
                </Select>
                {errors['address.emirate'] && (
                  <FormHelperText>{errors['address.emirate']?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="address.country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Country"
                fullWidth
                error={!!errors['address.country']}
                helperText={errors['address.country']?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save & Continue'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactInfoStep;