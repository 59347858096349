import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Chip,
  Button,
  Rating,
  Avatar,
  Divider,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tab,
  Tabs,
} from '@mui/material';
import {
  LocationOn as LocationIcon,
  Check as CheckIcon,
  ExpandMore as ExpandMoreIcon,
  Verified as VerifiedIcon,
  Info as InfoIcon,
  QuestionAnswer as FAQIcon,
  People as PeopleIcon,
  Image as ImageIcon,
} from '@mui/icons-material';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`service-tabpanel-${index}`}
      aria-labelledby={`service-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ServiceDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [service, setService] = useState<any>(null);
  const [topProviders, setTopProviders] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        setLoading(true);

        // Fetch service details
        const serviceResponse = await axios.get(`/api/services/${id}`);
        setService(serviceResponse.data);

        // Fetch top providers for this service
        const providersResponse = await axios.get(`/api/services/${id}/top-providers`);
        setTopProviders(providersResponse.data || []);

        // Fetch dynamic content for this service
        const contentResponse = await axios.get(`/api/service-content/service/${id}`);

        // Update service with dynamic content
        if (contentResponse.data) {
          setService((prevService: any) => ({
            ...prevService,
            benefits: contentResponse.data.benefits || prevService.benefits,
            faqs: contentResponse.data.faqs || prevService.faqs,
            whatToExpect: contentResponse.data.whatToExpect || prevService.whatToExpect,
            relatedServices: contentResponse.data.relatedServices || prevService.relatedServices,
          }));
        }
      } catch (err: any) {
        console.error('Error fetching service data:', err);
        setError(err.message || 'Failed to load service information');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchServiceData();
    }
  }, [id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>Loading service information...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={() => navigate(-1)}>
              Go Back
            </Button>
          }
        >
          {error}
        </Alert>
      </Container>
    );
  }

  if (!service) return null;

  // Use benefits from the service data or fallback to defaults
  const benefits: string[] = service.benefits && service.benefits.length > 0
    ? service.benefits
    : [
        "Professional service with guaranteed quality",
        "Experienced and vetted professionals",
        "Competitive pricing with transparent quotes",
        "Convenient scheduling and timely service",
        "Customer satisfaction guarantee",
      ];

  // Use FAQs from the service data or fallback to defaults
  const faqs: Array<{question: string, answer: string}> = service.faqs && service.faqs.length > 0
    ? service.faqs
    : [
        {
          question: "How much does this service typically cost?",
          answer: "The cost varies depending on the scope of work, materials required, and your specific requirements. Our professionals provide detailed quotes after assessing your needs.",
        },
        {
          question: "How quickly can I get this service?",
          answer: "Most of our professionals can schedule appointments within 2-3 business days, with emergency services often available same-day.",
        },
        {
          question: "Are the professionals licensed and insured?",
          answer: "Yes, all professionals on our platform are verified, licensed where applicable, and carry appropriate insurance coverage.",
        },
        {
          question: "What if I'm not satisfied with the service?",
          answer: "We have a satisfaction guarantee. If you're not happy with the service, please contact us within 7 days and we'll work to resolve the issue.",
        },
        {
          question: "Do I need to provide any materials or equipment?",
          answer: "No, our professionals bring all necessary tools and equipment. Any required materials will be listed in your quote.",
        },
      ];

  // Sample images for the service
  const serviceImages = service.images && service.images.length > 0
    ? service.images
    : [
        "https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg",
        "https://images.pexels.com/photos/4792733/pexels-photo-4792733.jpeg",
        "https://images.pexels.com/photos/6585757/pexels-photo-6585757.jpeg",
      ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Breadcrumbs and title section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          {service.title || "Service Information"}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" paragraph>
          {service.description || "Learn about this service and find top professionals in your area."}
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          <Chip
            label={service.category?.name || "Home Service"}
            color="primary"
          />
          {service.price && (
            <Chip
              label={`Starting from $${service.price.amount || 0}`}
              color="secondary"
              variant="outlined"
            />
          )}
        </Box>
      </Box>

      {/* Tabs for different sections */}
      <Box sx={{ width: '100%', mb: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="service information tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab icon={<InfoIcon />} iconPosition="start" label="Overview" />
            <Tab icon={<ImageIcon />} iconPosition="start" label="Gallery" />
            <Tab icon={<PeopleIcon />} iconPosition="start" label="Top Providers" />
            <Tab icon={<FAQIcon />} iconPosition="start" label="FAQs" />
          </Tabs>
        </Box>

        {/* Overview Tab */}
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" gutterBottom>
                About This Service
              </Typography>
              <Typography paragraph>
                {service.description || `
                  This service provides professional assistance for your home needs. Our vetted professionals
                  ensure high-quality work with customer satisfaction guaranteed. Whether you need routine
                  maintenance or a complete overhaul, our experts have the skills and experience to deliver
                  exceptional results.
                `}
              </Typography>

              <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                Benefits
              </Typography>
              <List>
                {benefits.map((benefit, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary={benefit} />
                  </ListItem>
                ))}
              </List>

              <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                What to Expect
              </Typography>
              <Typography paragraph>
                {service.whatToExpect || `
                  When you book this service, a professional will contact you to discuss your specific needs
                  and provide a detailed quote. Once confirmed, they'll arrive at the scheduled time with all
                  necessary equipment and materials. The work will be completed to your satisfaction, and
                  you'll have an opportunity to review the results before finalizing payment.
                `}
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ mb: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={serviceImages[0]}
                  alt={service.title}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Ready to Get Started?
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    Connect with top-rated professionals in your area for this service.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => navigate('/find-professionals', {
                      state: { serviceQuery: service.title, categoryId: service.category?._id },
                    })}
                  >
                    Find Professionals
                  </Button>
                </CardContent>
              </Card>

              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Popular in Your Area
                </Typography>
                <List dense>
                  {service.relatedServices && service.relatedServices.length > 0
                    ? service.relatedServices.map((relatedService: any, index: number) => (
                        <ListItem key={index} button component={Link} to={`/services/${relatedService._id}`}>
                          <ListItemText primary={relatedService.title} />
                        </ListItem>
                      ))
                    : ["AC Maintenance", "Plumbing", "Electrical Repairs"].map((relatedService: string, index: number) => (
                        <ListItem key={index} button component={Link} to={`/services?query=${encodeURIComponent(relatedService)}`}>
                          <ListItemText primary={relatedService} />
                        </ListItem>
                      ))
                  }
                </List>
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>

        {/* Gallery Tab */}
        <TabPanel value={tabValue} index={1}>
          <Typography variant="h5" gutterBottom>
            Service Gallery
          </Typography>
          <Grid container spacing={2}>
            {serviceImages.map((image: string, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    height="240"
                    image={image}
                    alt={`Service image ${index + 1}`}
                    sx={{ objectFit: 'cover' }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        {/* Top Providers Tab */}
        <TabPanel value={tabValue} index={2}>
          <Typography variant="h5" gutterBottom>
            Top Providers for {service.title}
          </Typography>

          {topProviders.length > 0 ? (
            <Grid container spacing={3}>
              {topProviders.map((provider, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{ height: '100%' }}>
                    <CardActionArea component={Link} to={`/pro/${provider._id}`}>
                      <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          src={provider.avatar}
                          sx={{ width: 64, height: 64, mr: 2 }}
                        />
                        <Box>
                          <Typography variant="h6">
                            {provider.businessName}
                            {provider.isVerified && (
                              <VerifiedIcon color="primary" fontSize="small" sx={{ ml: 0.5 }} />
                            )}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Rating
                              value={provider.rating || 0}
                              precision={0.5}
                              size="small"
                              readOnly
                            />
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              ({provider.reviewCount || 0})
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                          <LocationIcon fontSize="small" color="action" sx={{ mt: 0.3, mr: 1 }} />
                          <Typography variant="body2" color="text.secondary">
                            {provider.location?.address || "Service area not specified"}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <CheckIcon fontSize="small" color="success" sx={{ mr: 1 }} />
                          <Typography variant="body2">
                            {provider.completedJobs || 0} jobs completed
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {provider.description?.substring(0, 100) || "Professional service provider"}
                          {provider.description?.length > 100 ? "..." : ""}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="h6" color="text.secondary">
                No providers found for this service
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => navigate('/find-professionals')}
              >
                Find Professionals
              </Button>
            </Box>
          )}
        </TabPanel>

        {/* FAQs Tab */}
        <TabPanel value={tabValue} index={3}>
          <Typography variant="h5" gutterBottom>
            Frequently Asked Questions
          </Typography>

          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ mb: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-content-${index}`}
                id={`faq-header-${index}`}
              >
                <Typography variant="subtitle1">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Box sx={{ mt: 4, p: 3, bgcolor: 'primary.light', borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>
              Have more questions?
            </Typography>
            <Typography paragraph>
              Our professionals can provide detailed information specific to your needs.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/find-professionals', {
                state: { serviceQuery: service.title, categoryId: service.category?._id },
              })}
            >
              Connect with a Professional
            </Button>
          </Box>
        </TabPanel>
      </Box>

      {/* Call to action section */}
      <Paper sx={{ p: 4, mt: 4, bgcolor: 'secondary.light', borderRadius: 2 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              Ready to get started with {service.title}?
            </Typography>
            <Typography variant="body1" paragraph>
              Connect with top-rated professionals in your area and get quotes for your project.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate('/find-professionals', {
                state: { serviceQuery: service.title, categoryId: service.category?._id },
              })}
            >
              Find Professionals Now
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ServiceDetails;
