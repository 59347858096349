import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import { FormProvider, Controller } from 'react-hook-form';
import { useOnboarding } from '../../../../contexts/OnboardingContext';
import { useStepForm } from '../../../../hooks/useStepForm';
import { FormTextField } from '../../../../components/forms/FormTextField';
import { businessProfileSchema } from '../../../../schemas/onboarding';
import { OnboardingStepId } from '../../../../types/onboarding/shared';
import { useOnboardingStepData } from '../../../../hooks/api/useOnboardingApi';
import { OnboardingLayout } from '../OnboardingLayout';

/**
 * Business Profile step component
 */
const BusinessProfileStep: React.FC = () => {
  const navigate = useNavigate();
  const { getNextStep } = useOnboarding();
  const { data: stepData, isLoading: isDataLoading } = useOnboardingStepData<any>(
    OnboardingStepId.BUSINESS_PROFILE
  );

  const formMethods = useStepForm(
    OnboardingStepId.BUSINESS_PROFILE,
    businessProfileSchema,
    stepData || {
      businessName: '',
      businessDescription: '',
      yearEstablished: undefined,
      website: '',
      address: {
        street: '',
        emirate: '', // Changed from state to emirate
        locality: '', // Changed from zip to locality
        country: 'United Arab Emirates', // Default to UAE
      },
    },
    { autosave: true, autosaveDelay: 2000 }
  );

  // Extract necessary properties from formMethods
  const { handleSubmit, formState, saveForm, isSaving, control } = formMethods;
  const { isValid } = formState;

  const onSubmit = async (data: any) => {
    // Convert yearEstablished from string to number if it exists
    if (data.yearEstablished) {
      data.yearEstablished = Number(data.yearEstablished);
    }
    
    const success = await saveForm(data);
    if (success) {
      const nextStep = getNextStep(OnboardingStepId.BUSINESS_PROFILE);
      if (nextStep) {
        navigate(`/pro/onboarding/${nextStep}`);
      }
    }
  };

  if (isDataLoading) {
    return (
      <OnboardingLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title="Business Profile"
      description="Tell us about your business. This information will be visible to potential customers."
    >
      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTextField
                required
                fullWidth
                label="Business Name"
                name="businessName"
                disabled={isSaving}
              />
            </Grid>

            <Grid item xs={12}>
              <FormTextField
                required
                fullWidth
                label="Description"
                name="businessDescription"
                multiline
                rows={3}
                disabled={isSaving}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormTextField
                fullWidth
                label="Website (Optional)"
                name="website"
                placeholder="www.example.com"
                disabled={isSaving}
                helperText="Protocol (https://) will be added automatically if not provided"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="yearEstablished"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Year Established (YYYY)"
                    type="number"
                    disabled={isSaving}
                    inputProps={{ min: 1900, max: new Date().getFullYear() }}
                    value={field.value || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value === '' ? undefined : Number(value));
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormTextField
                fullWidth
                label="Street Address"
                name="address.street"
                disabled={isSaving}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormTextField
                fullWidth
                label="Locality"
                name="address.locality"
                disabled={isSaving}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormTextField
                fullWidth
                label="Emirate"
                name="address.emirate"
                disabled={isSaving}
              />
            </Grid>

            <Grid item xs={12}>
              <FormTextField
                fullWidth
                label="Country"
                name="address.country"
                disabled={isSaving}
                defaultValue="United Arab Emirates"
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || isSaving}
              startIcon={isSaving ? <CircularProgress size={20} /> : null}
            >
              {isSaving ? 'Saving...' : 'Continue'}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </OnboardingLayout>
  );
};

export default BusinessProfileStep;
