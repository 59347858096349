import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import api from '../../services/axios';
import { Review, ReviewState } from '../../types/review';

const initialState: ReviewState = {
  reviews: {},
  loading: false,
  error: null,
};

// Async thunks
export const submitReview = createAsyncThunk(
  'reviews/submit',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await api.post<Review>('/reviews', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data || 'Failed to submit review');
    }
  }
);

export const fetchServiceReviews = createAsyncThunk(
  'reviews/fetchByService',
  async (serviceId: string, { rejectWithValue }) => {
    try {
      const response = await api.get<{ reviews: Review[] }>(
        `/reviews/service/${serviceId}`
      );
      return { serviceId, reviews: response.data.reviews };
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data || 'Failed to fetch reviews');
    }
  }
);

export const markReviewHelpful = createAsyncThunk(
  'reviews/markHelpful',
  async (reviewId: string, { rejectWithValue }) => {
    try {
      const response = await api.post<{ helpful: Review['helpful'] }>(
        `/reviews/${reviewId}/helpful`
      );
      return { reviewId, helpful: response.data.helpful };
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data || 'Failed to mark review as helpful');
    }
  }
);

const reviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    clearReviews: (state) => {
      state.reviews = {};
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Submit review
      .addCase(submitReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        state.loading = false;
        const review = action.payload;
        if (!state.reviews[review.service]) {
          state.reviews[review.service] = [];
        }
        state.reviews[review.service].unshift(review);
      })
      .addCase(submitReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Fetch service reviews
      .addCase(fetchServiceReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceReviews.fulfilled, (state, action) => {
        state.loading = false;
        const { serviceId, reviews } = action.payload;
        state.reviews[serviceId] = reviews;
      })
      .addCase(fetchServiceReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Mark review helpful
      .addCase(markReviewHelpful.fulfilled, (state, action) => {
        const { reviewId, helpful } = action.payload;
        Object.values(state.reviews).forEach((reviews) => {
          const review = reviews.find((r) => r._id === reviewId);
          if (review) {
            review.helpful = helpful;
          }
        });
      });
  },
});

export const { clearReviews } = reviewSlice.actions;
export default reviewSlice.reducer;