import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  LinearProgress,
  Chip,
  Avatar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HandymanIcon from '@mui/icons-material/Handyman';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PaymentIcon from '@mui/icons-material/Payment';
import MessageIcon from '@mui/icons-material/Message';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import { useAuth } from '../../../hooks/useAuth';
import { OnboardingProvider, useOnboarding } from '../../../contexts/OnboardingContext';
import OnboardingBanner from '../../../components/pro/ui/OnboardingBanner';

interface DashboardStats {
  totalLeads: number;
  newLeads: number;
  respondedLeads: number;
  conversionRate: number;
  totalEarnings: number;
  monthlyBudget: number;
  budgetSpent: number;
  averageRating: number;
  responseTime: number;
  leadCost: number;
}

interface Lead {
  _id: string;
  customerName: string;
  service: string;
  date: string;
  status: 'new' | 'viewed' | 'responded' | 'won' | 'lost';
  budget?: number;
}

interface Notification {
  _id: string;
  type: 'lead' | 'payment' | 'system';
  message: string;
  date: string;
  read: boolean;
}

interface ProfileCompletion {
  percentage: number;
  missingItems: string[];
}

// Dashboard content component that uses the OnboardingContext
const DashboardContent: React.FC = () => {
  const { user } = useAuth();
  const {
    onboardingStatus,
    currentStepId,
    fetchOnboardingStatus,
    isLoading: onboardingLoading,
  } = useOnboarding();

  const [stats, setStats] = useState<DashboardStats | null>(null);
  // Always show the onboarding banner by default, but allow user to dismiss it
  const [showOnboardingBanner, setShowOnboardingBanner] = useState<boolean>(true);
  const [recentLeads, setRecentLeads] = useState<Lead[]>([]);
  const [recentNotifications, setRecentNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Effect to fetch onboarding status
  useEffect(() => {
    console.log('Fetching onboarding status for dashboard');
    fetchOnboardingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch dashboard data only once when the component mounts
  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      setError(null);

      try {
        console.log('Fetching dashboard data');

        // In a real implementation, these would be actual API calls
        // For this example, we'll use mock data

        // Mock stats data
        setStats({
          totalLeads: 24,
          newLeads: 5,
          respondedLeads: 18,
          conversionRate: 42,
          totalEarnings: 1250,
          monthlyBudget: 500,
          budgetSpent: 320,
          averageRating: 4.7,
          responseTime: 2.5, // hours
          leadCost: 15.75,
        });

        // Mock leads data
        setRecentLeads([
          {
            _id: '1',
            customerName: 'John Smith',
            service: 'Plumbing Repair',
            date: '2025-03-05',
            status: 'new',
            budget: 120,
          },
          {
            _id: '2',
            customerName: 'Sarah Johnson',
            service: 'Bathroom Renovation',
            date: '2025-03-04',
            status: 'responded',
            budget: 3500,
          },
          {
            _id: '3',
            customerName: 'Michael Brown',
            service: 'Kitchen Sink Installation',
            date: '2025-03-03',
            status: 'won',
            budget: 450,
          },
        ]);

        // Mock notifications data
        setRecentNotifications([
          {
            _id: 'n1',
            type: 'lead',
            message: 'New lead received: Plumbing Repair',
            date: '2025-03-05',
            read: false,
          },
          {
            _id: 'n2',
            type: 'payment',
            message: 'Payment processed: $120.00',
            date: '2025-03-04',
            read: true,
          },
          {
            _id: 'n3',
            type: 'system',
            message: 'Your profile has been verified',
            date: '2025-03-02',
            read: true,
          },
        ]);
      } catch (err: any) {
        setError('Failed to load dashboard data');
        console.error('Error fetching dashboard data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
    // We intentionally don't include getAuthToken in the dependency array
    // because it would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show loading state if either dashboard data or onboarding status is loading
  if (loading || onboardingLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Show error state
  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Paper sx={{ p: 3, maxWidth: 500 }}>
          <Typography variant="h6" color="error" gutterBottom>
            Error Loading Dashboard
          </Typography>
          <Typography>{error}</Typography>
        </Paper>
      </Box>
    );
  }

  // Calculate budget percentage
  const budgetPercentage = stats ? (stats.budgetSpent / stats.monthlyBudget) * 100 : 0;

  return (
    <Box sx={{ py: 4 }}>
      <Container>
        {/* Onboarding Banner - Show if onboarding is not complete and user hasn't dismissed it */}
        {showOnboardingBanner && onboardingStatus && !onboardingStatus.isComplete && (
          <OnboardingBanner
            currentStep={currentStepId}
            onDismiss={() => setShowOnboardingBanner(false)}
            showOnDashboard={true}
          />
        )}

        {/* Dashboard Header */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={8}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                Pro Dashboard
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Welcome back, {user?.name}
              </Typography>
            </Box>
          </Grid>

          {/* Quick Actions */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Quick Actions
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to="/pro/services/new"
                    sx={{ height: '100%' }}
                  >
                    Add Service
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<VisibilityIcon />}
                    component={RouterLink}
                    to={`/pro/${user?._id}`}
                    target="_blank"
                    sx={{ height: '100%' }}
                  >
                    View Profile
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<SettingsIcon />}
                    component={RouterLink}
                    to="/pro/settings"
                    sx={{ height: '100%' }}
                  >
                    Settings
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<MessageIcon />}
                    component={RouterLink}
                    to="/pro/messages"
                    sx={{ height: '100%' }}
                  >
                    Messages
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* Stats Section */}
        <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>
          Performance Overview
        </Typography>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {/* Lead Statistics */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="subtitle1" gutterBottom>
                Lead Statistics
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Box>
                  <Typography variant="h4" color="primary">
                    {stats?.totalLeads || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Leads
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h4" color="warning.main">
                    {stats?.newLeads || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    New Leads
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  Conversion Rate
                </Typography>
                <Typography variant="body1" fontWeight="bold" color="success.main">
                  {stats?.conversionRate || 0}%
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  Response Time
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {stats?.responseTime || 0} hours
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Budget & Spending */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="subtitle1" gutterBottom>
                Budget & Spending
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Typography variant="body2">Monthly Budget</Typography>
                  <Typography variant="body1" fontWeight="bold">
                    ${stats?.monthlyBudget || 0}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Typography variant="body2">Spent This Month</Typography>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color={budgetPercentage > 90 ? 'error.main' : 'inherit'}
                  >
                    ${stats?.budgetSpent || 0}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={Math.min(budgetPercentage, 100)}
                  sx={{ height: 8, borderRadius: 4, mb: 1 }}
                  color={budgetPercentage > 90 ? 'error' : 'primary'}
                />
                <Typography variant="caption" color="text.secondary">
                  {budgetPercentage.toFixed(0)}% of budget used
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  Average Lead Cost
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  ${stats?.leadCost.toFixed(2) || 0}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Performance Metrics */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="subtitle1" gutterBottom>
                Performance Metrics
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ mr: 2 }}>
                  <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>
                    <StarIcon fontSize="large" />
                  </Avatar>
                </Box>
                <Box>
                  <Typography variant="h4" color="primary">
                    {stats?.averageRating || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Average Rating
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <TrendingUpIcon color="success" sx={{ mr: 1 }} />
                  <Typography variant="body2">Total Earnings</Typography>
                </Box>
                <Typography variant="body1" fontWeight="bold">
                  ${stats?.totalEarnings || 0}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                  <Typography variant="body2">Completed Jobs</Typography>
                </Box>
                <Typography variant="body1" fontWeight="bold">
                  {stats?.respondedLeads || 0}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {/* Recent Activity */}
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 0, height: '100%', overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', borderBottom: '1px solid #eee' }}>
                <Box
                  sx={{
                    p: 2,
                    width: '50%',
                    borderBottom: '2px solid #1976d2',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Recent Leads
                </Box>
                <Box
                  sx={{
                    p: 2,
                    width: '50%',
                    borderBottom: '1px solid #eee',
                    color: 'text.secondary',
                    textAlign: 'center',
                  }}
                >
                  Notifications
                </Box>
              </Box>

              <Box sx={{ p: 2 }}>
                {recentLeads.length === 0 ? (
                  <Typography color="text.secondary" sx={{ py: 2, textAlign: 'center' }}>
                    No leads yet. Complete your profile to start receiving leads.
                  </Typography>
                ) : (
                  <List>
                    {recentLeads.map((lead) => (
                      <React.Fragment key={lead._id}>
                        <ListItem sx={{ px: 1 }}>
                          <ListItemIcon>
                            <AssignmentIcon
                              color={
                                lead.status === 'new'
                                  ? 'warning'
                                  : lead.status === 'won'
                                  ? 'success'
                                  : 'primary'
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body1">{lead.customerName}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                  ${lead.budget}
                                </Typography>
                              </Box>
                            }
                            secondary={
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary">
                                  {lead.service}
                                </Typography>
                                <Chip
                                  label={lead.status}
                                  size="small"
                                  color={
                                    lead.status === 'new'
                                      ? 'warning'
                                      : lead.status === 'won'
                                      ? 'success'
                                      : 'primary'
                                  }
                                  variant="outlined"
                                />
                              </Box>
                            }
                          />
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ ml: 2 }}
                            component={RouterLink}
                            to={`/pro/leads/${lead._id}`}
                          >
                            View
                          </Button>
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    ))}
                  </List>
                )}
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button component={RouterLink} to="/pro/leads" color="primary">
                    View All Leads
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Profile Completion */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Profile Management
              </Typography>
              <List>
                <ListItem button component={RouterLink} to="/pro/profile">
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Business Profile" />
                </ListItem>

                <ListItem button component={RouterLink} to="/pro/availability">
                  <ListItemIcon>
                    <EventAvailableIcon />
                  </ListItemIcon>
                  <ListItemText primary="Availability" />
                </ListItem>

                <ListItem button component={RouterLink} to="/pro/services">
                  <ListItemIcon>
                    <HandymanIcon />
                  </ListItemIcon>
                  <ListItemText primary="Services" />
                </ListItem>

                <ListItem button component={RouterLink} to="/pro/service-areas">
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Service Areas" />
                </ListItem>

                <ListItem button component={RouterLink} to="/pro/payment">
                  <ListItemIcon>
                    <PaymentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Payment Settings" />
                </ListItem>

                <ListItem button component={RouterLink} to="/pro/messages">
                  <ListItemIcon>
                    <MessageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Messages" />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

// Main Dashboard component that wraps the content with OnboardingProvider
const ProDashboard: React.FC = () => {
  return (
    <OnboardingProvider>
      <DashboardContent />
    </OnboardingProvider>
  );
};

export default ProDashboard;
