/**
 * Creates a debounced function that delays invoking the provided function
 * until after `wait` milliseconds have elapsed since the last time it was invoked.
 *
 * @param func The function to debounce
 * @param wait The number of milliseconds to delay
 * @returns A debounced version of the provided function
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout | undefined;

  return function debouncedFn(this: any, ...args: Parameters<T>) {
    const context = this;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

/**
 * Creates a debounced function that returns a Promise which resolves with
 * the result of the provided async function.
 *
 * @param func The async function to debounce
 * @param wait The number of milliseconds to delay
 * @returns A debounced version of the provided async function
 */
export function debounceAsync<T extends (...args: any[]) => Promise<any>>(
  func: T,
  wait: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timeoutId: NodeJS.Timeout | undefined;
  let pendingPromise: Promise<ReturnType<T>> | undefined;

  return function debouncedFn(this: any, ...args: Parameters<T>): Promise<ReturnType<T>> {
    const context = this;

    // If there's a pending promise, return it
    if (pendingPromise) {
      return pendingPromise;
    }

    // Create a new promise that resolves with the debounced function result
    pendingPromise = new Promise((resolve, reject) => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(async () => {
        try {
          const result = await func.apply(context, args);
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          pendingPromise = undefined;
        }
      }, wait);
    });

    return pendingPromise;
  };
}

/**
 * Creates a throttled function that only invokes the provided function
 * at most once per every `wait` milliseconds.
 *
 * @param func The function to throttle
 * @param wait The number of milliseconds to throttle invocations to
 * @returns A throttled version of the provided function
 */
export function throttle<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let lastCall = 0;
  let timeoutId: NodeJS.Timeout | undefined;

  return function throttledFn(this: any, ...args: Parameters<T>) {
    const context = this;
    const now = Date.now();

    if (now - lastCall >= wait) {
      func.apply(context, args);
      lastCall = now;
    } else {
      // If there's a pending timeout, clear it
      clearTimeout(timeoutId);

      // Schedule a new call after the remaining time
      timeoutId = setTimeout(() => {
        if (Date.now() - lastCall >= wait) {
          func.apply(context, args);
          lastCall = Date.now();
        }
      }, wait - (now - lastCall));
    }
  };
}