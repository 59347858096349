import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  InputAdornment,
  FormHelperText,
  Slider,
  Paper,
} from '@mui/material';
import { useOnboarding } from '../../../../contexts/OnboardingContext';
import { OnboardingStepId } from '../../../../types/onboarding/shared';
import { useOnboardingStepData, useSaveStep } from '../../../../hooks/api/useOnboardingApi';
import { OnboardingLayout } from '../OnboardingLayout';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { budgetSettingsSchema } from '../../../../schemas/onboarding';
import { FormTextField } from '../../../../components/forms/FormTextField';

/**
 * Budget Settings step component
 */
const BudgetSettingsStep: React.FC = () => {
  const navigate = useNavigate();
  const { getNextStep, getPreviousStep } = useOnboarding();
  const { data: stepData, isLoading: isDataLoading } = useOnboardingStepData<any>(
    OnboardingStepId.BUDGET_SETTINGS
  );
  const { mutateAsync: saveStepAsync } = useSaveStep();

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Initialize form with default values
  const methods = useForm({
    resolver: zodResolver(budgetSettingsSchema),
    defaultValues: stepData || {
      monthlyBudget: 500,
      maxPerLeadBudget: 50,
      // Currency is now handled by the server and always set to AED
    },
  });

  const { control, handleSubmit, watch, formState } = methods;
  const { errors } = formState;

  const monthlyBudget = watch('monthlyBudget');
  const maxPerLeadBudget = watch('maxPerLeadBudget');

  const handleBack = () => {
    const prevStep = getPreviousStep(OnboardingStepId.BUDGET_SETTINGS);
    if (prevStep) {
      navigate(`/pro/onboarding/${prevStep}`);
    }
  };

  const onSubmit = async (data: any) => {
    console.log('BudgetSettingsStep: onSubmit called with data', data);
    setIsSaving(true);
    setError(null);

    try {
      // Ensure data has valid numeric values
      const validatedData = {
        monthlyBudget: Number(data.monthlyBudget) || 500,
        maxPerLeadBudget: Number(data.maxPerLeadBudget) || 50,
        currency: 'AED', // Explicitly set currency to AED
      };

      console.log('BudgetSettingsStep: Sending data to server', validatedData);

      // Save to server
      const result = await saveStepAsync({
        stepId: OnboardingStepId.BUDGET_SETTINGS,
        data: validatedData,
      });

      console.log('BudgetSettingsStep: Received result from server', result);

      if (result.success) {
        // Force navigation to payment information step
        console.log('BudgetSettingsStep: Navigating to payment-information');
        navigate('/pro/onboarding/payment-information');
      } else {
        console.error('BudgetSettingsStep: Server returned success=false');
        setError('Failed to save budget settings. Please try again.');
      }
    } catch (err: any) {
      console.error('BudgetSettingsStep: Error caught in onSubmit:', err);

      // More detailed error message
      let errorMessage = 'An error occurred while saving. Please try again.';
      if (err.response && err.response.data && err.response.data.message) {
        errorMessage = `Server error: ${err.response.data.message}`;
      } else if (err.message) {
        errorMessage = `Error: ${err.message}`;
      }

      setError(errorMessage);
      console.error('Error saving budget settings:', err);
    } finally {
      console.log('BudgetSettingsStep: Setting isSaving to false');
      setIsSaving(false);
    }
  };

  if (isDataLoading) {
    return (
      <OnboardingLayout title="Budget Settings" description="Loading...">
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title="Budget Settings"
      description="Set your lead generation budget to control your spending."
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            console.log('BudgetSettingsStep: Form submitted');

            // Get form values directly
            const formValues = methods.getValues();
            console.log('BudgetSettingsStep: Form values', formValues);

            // Call onSubmit directly
            onSubmit(formValues);
          }}
          noValidate
        >
          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Monthly Budget
            </Typography>

            <Typography variant="body2" color="text.secondary" paragraph>
              Set the maximum amount you want to spend on leads each month.
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="monthlyBudget"
                  control={control}
                  render={({ field }) => (
                    <Box>
                      <Typography id="monthly-budget-slider" gutterBottom>
                        Monthly Budget: AED {field.value}
                      </Typography>
                      <Slider
                        value={typeof field.value === 'number' ? field.value : 500}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        aria-labelledby="monthly-budget-slider"
                        valueLabelDisplay="auto"
                        step={100}
                        marks
                        min={100}
                        max={5000}
                        disabled={isSaving}
                      />
                      <FormTextField
                        {...field}
                        label="Monthly Budget"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">AED</InputAdornment>,
                        }}
                        disabled={isSaving}
                      />
                      {errors.monthlyBudget && (
                        <FormHelperText error>
                          {errors.monthlyBudget.message as string}
                        </FormHelperText>
                      )}
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Maximum Per Lead Budget
            </Typography>

            <Typography variant="body2" color="text.secondary" paragraph>
              Set the maximum amount you're willing to pay for a single lead.
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="maxPerLeadBudget"
                  control={control}
                  render={({ field }) => (
                    <Box>
                      <Typography id="max-per-lead-slider" gutterBottom>
                        Max Per Lead: AED {field.value}
                      </Typography>
                      <Slider
                        value={typeof field.value === 'number' ? field.value : 50}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        aria-labelledby="max-per-lead-slider"
                        valueLabelDisplay="auto"
                        step={5}
                        marks
                        min={5}
                        max={200}
                        disabled={isSaving}
                      />
                      <FormTextField
                        {...field}
                        label="Max Per Lead Budget"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">AED</InputAdornment>,
                        }}
                        disabled={isSaving}
                      />
                      {errors.maxPerLeadBudget && (
                        <FormHelperText error>
                          {errors.maxPerLeadBudget.message as string}
                        </FormHelperText>
                      )}
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
          {/* Currency section removed - AED is now the default currency */}

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleBack} disabled={isSaving}>
              Back
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSaving}
              startIcon={isSaving ? <CircularProgress size={20} /> : null}
              onClick={() => console.log('BudgetSettingsStep: Continue button clicked')}
            >
              {isSaving ? 'Saving...' : 'Continue'}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </OnboardingLayout>
  );
};

export default BudgetSettingsStep;
