import axios from './axios';
import {
  CalendarSettings,
  CalendarEvent,
  CalendarSettingsUpdateRequest,
  DateExceptionCreateRequest,
  DateExceptionUpdateRequest,
  CalendarEventCreateRequest,
  CalendarEventUpdateRequest,
  AvailabilityCheckRequest,
  AvailabilityCheckResponse,
  DateException,
} from '../types/calendar';

/**
 * Service for handling calendar-related API calls
 */
class CalendarService {
  /**
   * Get calendar settings for the current user
   * @returns Promise with calendar settings
   */
  async getCalendarSettings(): Promise<CalendarSettings> {
    const response = await axios.get('/calendar/settings');
    return response.data;
  }

  /**
   * Update calendar settings for the current user
   * @param settings Updated settings
   * @returns Promise with updated calendar settings
   */
  async updateCalendarSettings(
    settings: CalendarSettingsUpdateRequest
  ): Promise<CalendarSettings> {
    const response = await axios.patch('/calendar/settings', settings);
    return response.data;
  }

  /**
   * Get all date exceptions for the current user
   * @returns Promise with date exceptions
   */
  async getDateExceptions(): Promise<DateException[]> {
    const response = await axios.get('/calendar/exceptions');
    return response.data;
  }

  /**
   * Create a new date exception
   * @param exception Date exception to create
   * @returns Promise with created date exception
   */
  async createDateException(exception: DateExceptionCreateRequest): Promise<DateException> {
    const response = await axios.post('/calendar/exceptions', exception);
    return response.data;
  }

  /**
   * Update a date exception
   * @param exceptionId ID of the exception to update
   * @param exception Updated exception data
   * @returns Promise with updated date exception
   */
  async updateDateException(
    exceptionId: string,
    exception: DateExceptionUpdateRequest
  ): Promise<DateException> {
    const response = await axios.patch(`/calendar/exceptions/${exceptionId}`, exception);
    return response.data;
  }

  /**
   * Delete a date exception
   * @param exceptionId ID of the exception to delete
   * @returns Promise with success message
   */
  async deleteDateException(exceptionId: string): Promise<{ success: boolean; message: string }> {
    const response = await axios.delete(`/calendar/exceptions/${exceptionId}`);
    return response.data;
  }

  /**
   * Get calendar events for a date range
   * @param start Start date (ISO string)
   * @param end End date (ISO string)
   * @returns Promise with calendar events
   */
  async getCalendarEvents(start: string, end: string): Promise<CalendarEvent[]> {
    const response = await axios.get('/calendar/events', {
      params: { start, end },
    });
    return response.data;
  }

  /**
   * Create a new calendar event
   * @param event Event to create
   * @returns Promise with created calendar event
   */
  async createCalendarEvent(event: CalendarEventCreateRequest): Promise<CalendarEvent> {
    const response = await axios.post('/calendar/events', event);
    return response.data;
  }

  /**
   * Update a calendar event
   * @param eventId ID of the event to update
   * @param event Updated event data
   * @returns Promise with updated calendar event
   */
  async updateCalendarEvent(
    eventId: string,
    event: CalendarEventUpdateRequest
  ): Promise<CalendarEvent> {
    const response = await axios.patch(`/calendar/events/${eventId}`, event);
    return response.data;
  }

  /**
   * Delete a calendar event
   * @param eventId ID of the event to delete
   * @returns Promise with success message
   */
  async deleteCalendarEvent(eventId: string): Promise<{ success: boolean; message: string }> {
    const response = await axios.delete(`/calendar/events/${eventId}`);
    return response.data;
  }

  /**
   * Check availability for a date range
   * @param request Availability check request
   * @returns Promise with available slots
   */
  async checkAvailability(request: AvailabilityCheckRequest): Promise<AvailabilityCheckResponse> {
    const response = await axios.post('/calendar/check-availability', request);
    return response.data;
  }

  /**
   * Get lead timeline (upcoming and past leads)
   * @param status Filter by status (optional)
   * @returns Promise with calendar events for leads
   */
  async getLeadTimeline(status?: string): Promise<CalendarEvent[]> {
    const response = await axios.get('/calendar/lead-timeline', {
      params: { status },
    });
    return response.data;
  }
}

export default new CalendarService();