import axios from './axios';

export interface IdeaProject {
  id: string;
  title: string;
  image: string;
  designer: string;
  designerAvatar?: string;
  likes: number;
  description?: string;
  details?: {
    style: string;
    size?: string;
    budget?: string;
    timeframe?: string;
    location?: string;
    building?: string;
    cost?: string;
    year?: string;
    roomType?: string;
    providerId?: string;
  };
  tags?: string[];
  roomType?: string;
  roomName?: string;
  relatedProjects?: string[];
  images?: string[];
  providerId?: string;
}

export interface Room {
  id: string;
  name: string;
  image: string;
  description: string;
  longDescription: string;
  projectCount: number;
  popularTags: string[];
  featuredProjects: IdeaProject[];
}

export interface Ideabook {
  _id: string;
  title: string;
  description?: string;
  coverImage?: string;
  itemCount: number;
  isPrivate: boolean;
  lastModified?: Date;
  createdAt: string;
  updatedAt: string;
}

export interface IdeabookItem {
  id: string;
  ideabookId: string;
  projectId: string;
  project: IdeaProject;
  addedAt: string;
  notes?: string;
}

export interface FilterOptions {
  style?: string;
  year?: string;
  cost?: string;
  roomType?: string;
}

export const ideaService = {
  /**
   * Get all rooms
   */
  getRooms: async (): Promise<Room[]> => {
    try {
      const response = await axios.get('/api/ideas/rooms');
      return response.data;
    } catch (error) {
      console.error('Error fetching rooms:', error);
      throw error;
    }
  },

  /**
   * Get a specific room by ID
   */
  getRoomById: async (roomId: string): Promise<Room> => {
    try {
      const response = await axios.get(`/api/ideas/rooms/${roomId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching room ${roomId}:`, error);
      throw error;
    }
  },

  /**
   * Get ideas/projects by room
   */
  getIdeasByRoom: async (roomId: string, filters?: Record<string, any>): Promise<IdeaProject[]> => {
    try {
      const response = await axios.get(`/api/ideas/rooms/${roomId}/projects`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching ideas for room ${roomId}:`, error);
      throw error;
    }
  },

  /**
   * Get a specific project by ID
   */
  getProjectById: async (projectId: string): Promise<IdeaProject> => {
    try {
      const response = await axios.get(`/api/ideas/projects/${projectId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching project ${projectId}:`, error);
      throw error;
    }
  },

  /**
   * Get related projects
   */
  getRelatedProjects: async (projectId: string): Promise<IdeaProject[]> => {
    try {
      const response = await axios.get(`/api/ideas/projects/${projectId}/related`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching related projects for ${projectId}:`, error);
      throw error;
    }
  },

  /**
   * Get user's ideabooks
   */
  getIdeabooks: async (): Promise<Ideabook[]> => {
    try {
      const response = await axios.get('/ideabooks');
      return response.data;
    } catch (error) {
      console.error('Error fetching ideabooks:', error);
      throw error;
    }
  },

  /**
   * Get a specific ideabook by ID
   */
  getIdeabookById: async (ideabookId: string): Promise<Ideabook> => {
    try {
      const response = await axios.get(`/ideabooks/${ideabookId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching ideabook ${ideabookId}:`, error);
      throw error;
    }
  },

  /**
   * Get items in an ideabook
   */
  getIdeabookItems: async (ideabookId: string): Promise<IdeabookItem[]> => {
    try {
      const response = await axios.get(`/ideabooks/${ideabookId}/items`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching items for ideabook ${ideabookId}:`, error);
      throw error;
    }
  },

  /**
   * Create a new ideabook
   */
  createIdeabook: async (data: { title: string; description?: string; isPrivate?: boolean }): Promise<Ideabook> => {
    try {
      // No need to transform the data, as the backend expects the same field names
      const response = await axios.post('/ideabooks', data);
      return response.data;
    } catch (error) {
      console.error('Error creating ideabook:', error);
      throw error;
    }
  },

  /**
   * Update an ideabook
   */
  updateIdeabook: async (ideabookId: string, data: { title?: string; description?: string; isPrivate?: boolean }): Promise<Ideabook> => {
    try {
      const response = await axios.put(`/ideabooks/${ideabookId}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error updating ideabook ${ideabookId}:`, error);
      throw error;
    }
  },

  /**
   * Delete an ideabook
   */
  deleteIdeabook: async (ideabookId: string): Promise<void> => {
    try {
      await axios.delete(`/ideabooks/${ideabookId}`);
    } catch (error) {
      console.error(`Error deleting ideabook ${ideabookId}:`, error);
      throw error;
    }
  },

  /**
   * Save a project to an ideabook
   */
  saveToIdeabook: async (projectId: string, ideabookId: string, notes?: string): Promise<IdeabookItem> => {
    try {
      const response = await axios.post(`/ideabooks/${ideabookId}/items`, {
        projectId,
        notes,
      });
      return response.data;
    } catch (error) {
      console.error(`Error saving project ${projectId} to ideabook ${ideabookId}:`, error);
      throw error;
    }
  },

  /**
   * Remove a project from an ideabook
   */
  removeFromIdeabook: async (ideabookId: string, itemId: string): Promise<void> => {
    try {
      await axios.delete(`/ideabooks/${ideabookId}/items/${itemId}`);
    } catch (error) {
      console.error(`Error removing item ${itemId} from ideabook ${ideabookId}:`, error);
      throw error;
    }
  },

  /**
   * Share a project (generate a shareable link or record share analytics)
   */
  shareProject: async (projectId: string, platform?: string): Promise<{ shareUrl: string }> => {
    try {
      const response = await axios.post(`/api/ideas/projects/${projectId}/share`, { platform });
      return response.data;
    } catch (error) {
      console.error(`Error sharing project ${projectId}:`, error);
      throw error;
    }
  },

  /**
   * Share an ideabook (generate a shareable link or record share analytics)
   */
  shareIdeabook: async (ideabookId: string, platform?: string): Promise<{ shareUrl: string }> => {
    try {
      const response = await axios.post(`/ideabooks/${ideabookId}/share`, { platform });
      return response.data;
    } catch (error) {
      console.error(`Error sharing ideabook ${ideabookId}:`, error);
      throw error;
    }
  },

  /**
   * Get provider projects for the ideas section
   */
  getProviderProjects: async (filters?: FilterOptions): Promise<IdeaProject[]> => {
    try {
      const response = await axios.get('/ideas/provider-projects', { params: filters });
      return response.data;
    } catch (error) {
      console.error('Error fetching provider projects:', error);
      throw error;
    }
  },

  /**
   * Get a specific provider project by ID
   */
  getProviderProjectById: async (projectId: string): Promise<IdeaProject> => {
    try {
      const response = await axios.get(`/ideas/provider-projects/${projectId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching provider project ${projectId}:`, error);
      throw error;
    }
  },

  /**
   * Get all available styles for filtering
   */
  getStyles: async (): Promise<string[]> => {
    try {
      const response = await axios.get('/ideas/styles');
      return response.data;
    } catch (error) {
      console.error('Error fetching styles:', error);
      throw error;
    }
  },

  /**
   * Get all available years for filtering
   */
  getYears: async (): Promise<string[]> => {
    try {
      const response = await axios.get('/ideas/years');
      return response.data;
    } catch (error) {
      console.error('Error fetching years:', error);
      throw error;
    }
  },

  /**
   * Get all available cost ranges for filtering
   */
  getCostRanges: async (): Promise<string[]> => {
    try {
      const response = await axios.get('/ideas/cost-ranges');
      return response.data;
    } catch (error) {
      console.error('Error fetching cost ranges:', error);
      throw error;
    }
  },

  /**
   * Get all available room types for filtering
   */
  getRoomTypes: async (): Promise<string[]> => {
    try {
      const response = await axios.get('/ideas/room-types');
      return response.data;
    } catch (error) {
      console.error('Error fetching room types:', error);
      throw error;
    }
  },
};

export default ideaService;