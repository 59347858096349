import React, { useEffect, useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Divider,
  useTheme,
  useMediaQuery,
  Chip,
  Stack,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchBar from '../../components/search/SearchBar';
import SearchResults from '../../components/search/SearchResults';
import CategoryBrowser from '../../components/search/CategoryBrowser';
import {
  searchServices,
  fetchPopularCategories,
  fetchServiceCategories,
  setSearchFilters,
  selectSearchResults,
  selectSearchLoading,
  selectSearchFilters,
  selectCategories,
  selectSearchError,
} from '../../redux/slices/searchSlice';
import { AppDispatch } from '../../redux/store';
import { SearchParams, convertParamsToFilters } from '../../types/search';
import { ICategory } from '../../types/models';

const Search = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Parse URL parameters
  const searchParams = new URLSearchParams(location.search);
  const currentView = searchParams.get('view') || 'search';
  const categoryId = searchParams.get('category');
  const query = searchParams.get('q') || '';
  const lat = searchParams.get('lat');
  const lng = searchParams.get('lng');
  const radius = searchParams.get('radius');
  const address = searchParams.get('address');
  const minPrice = searchParams.get('minPrice');
  const maxPrice = searchParams.get('maxPrice');
  const rating = searchParams.get('rating');

  const results = useSelector(selectSearchResults);
  const loading = useSelector(selectSearchLoading);
  const filters = useSelector(selectSearchFilters);
  const categories = useSelector(selectCategories) || [];
  const error = useSelector(selectSearchError);

  // Sync URL with filters
  const updateUrl = useCallback((newFilters: typeof filters) => {
    const params = new URLSearchParams(location.search);

    // Update search parameters
    if (newFilters.query) params.set('q', newFilters.query);
    else params.delete('q');

    if (newFilters.category?._id) params.set('category', newFilters.category._id);
    else params.delete('category');

    if (newFilters.location) {
      params.set('lat', String(newFilters.location.coordinates[1]));
      params.set('lng', String(newFilters.location.coordinates[0]));
      params.set('radius', String(newFilters.location.radius));
      params.set('address', newFilters.location.address);
    } else {
      params.delete('lat');
      params.delete('lng');
      params.delete('radius');
      params.delete('address');
    }

    if (newFilters.priceRange) {
      params.set('minPrice', String(newFilters.priceRange.min));
      params.set('maxPrice', String(newFilters.priceRange.max));
    } else {
      params.delete('minPrice');
      params.delete('maxPrice');
    }

    if (newFilters.rating) params.set('rating', String(newFilters.rating));
    else params.delete('rating');

    navigate({ search: params.toString() }, { replace: true });
  }, [location.search, navigate]);

  // Initialize filters from URL
  useEffect(() => {
    const initialFilters = {
      query: query || undefined,
      category: categoryId ? categories.find(c => c._id === categoryId) : undefined,
      location: lat && lng && radius && address ? {
        coordinates: [Number(lng), Number(lat)] as [number, number], // Cast to tuple
        radius: Number(radius),
        address,
      } : undefined,
      priceRange: minPrice && maxPrice ? {
        min: Number(minPrice),
        max: Number(maxPrice),
      } : undefined,
      rating: rating ? Number(rating) : undefined,
    };

    // Only update if we have URL parameters
    if (query || categoryId || lat || minPrice || rating) {
      dispatch(setSearchFilters(initialFilters));
    }
  }, [dispatch, categoryId, query, lat, lng, radius, address, minPrice, maxPrice, rating]);

  useEffect(() => {
    // Load categories on mount
    dispatch(fetchServiceCategories());
    dispatch(fetchPopularCategories());
  }, [dispatch]);

  useEffect(() => {
    // Update URL when filters change
    updateUrl(filters);
  }, [filters, updateUrl]);

  const handleSearch = (params: SearchParams) => {
    const searchFilters = convertParamsToFilters(params);
    dispatch(setSearchFilters(searchFilters));
    dispatch(searchServices(searchFilters));
  };

  const handleViewChange = (_event: React.SyntheticEvent, newValue: string) => {
    const params = new URLSearchParams(location.search);
    params.set('view', newValue);
    navigate({ search: params.toString() });
  };

  const handleCategorySelect = (category: ICategory) => {
    dispatch(setSearchFilters({ category }));
    navigate({ search: '?view=search' });
  };

  const currentSearchParams: SearchParams = {
    query: filters.query,
    category: filters.category,
    location: filters.location,
    filters: {
      priceRange: filters.priceRange ? [filters.priceRange.min, filters.priceRange.max] : undefined,
      rating: filters.rating,
      availability: filters.availability,
    },
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Search Header */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom fontWeight="bold">
          Find Local Services
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Search from thousands of trusted service providers in your area
        </Typography>
      </Box>

      {/* Error Alert */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Search Bar */}
      <SearchBar
        onSearch={handleSearch}
        categories={categories}
        loading={loading}
        initialQuery={currentSearchParams.query}
        initialCategory={currentSearchParams.category}
        initialLocation={currentSearchParams.location}
        initialFilters={currentSearchParams.filters}
      />

      {/* View Tabs */}
      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={currentView}
          onChange={handleViewChange}
          variant={isMobile ? 'fullWidth' : 'standard'}
          sx={{ px: 2 }}
        >
          <Tab
            value="search"
            label={`Search Results ${results.length ? `(${results.length})` : ''}`}
          />
          <Tab value="categories" label="Browse Categories" />
        </Tabs>
      </Paper>

      {/* Main Content */}
      {currentView === 'search' ? (
        <Box>
          {/* Active Filters Summary */}
          {(filters.query || filters.category || filters.location) && (
            <Paper sx={{ p: 2, mb: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Active Filters:
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {filters.query && (
                  <Chip
                    label={`Search: "${filters.query}"`}
                    onDelete={() => dispatch(setSearchFilters({ ...filters, query: undefined }))}
                  />
                )}
                {filters.category && (
                  <Chip
                    label={`Category: ${filters.category.name}`}
                    onDelete={() => dispatch(setSearchFilters({ ...filters, category: undefined }))}
                  />
                )}
                {filters.location && (
                  <Chip
                    label={`Location: Within ${filters.location.radius}km`}
                    onDelete={() => dispatch(setSearchFilters({ ...filters, location: undefined }))}
                  />
                )}
              </Stack>
            </Paper>
          )}

          {/* Search Results */}
          <SearchResults services={results} loading={loading} />
        </Box>
      ) : (
        <CategoryBrowser
          categories={categories}
          loading={loading}
          onCategorySelect={handleCategorySelect}
        />
      )}

      {/* Location-based Services */}
      {filters.location && results.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom fontWeight="bold">
            Services Near You
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Showing services within {filters.location.radius}km of your location
          </Typography>
          <Box sx={{ mt: 2 }}>{/* Map view could be added here */}</Box>
        </Box>
      )}

      {/* Popular Categories */}
      {currentView === 'search' && results.length === 0 && !loading && (
        <Box sx={{ mt: 4 }}>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom fontWeight="bold">
            Popular Categories
          </Typography>
          <CategoryBrowser
            categories={categories}
            loading={loading}
            isPopular
            onCategorySelect={handleCategorySelect}
          />
        </Box>
      )}
    </Container>
  );
};

export default Search;
