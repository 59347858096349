import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Tooltip,
  Chip,
  Alert,
  Snackbar,
  Grid,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as CopyIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { useAuth } from '../../../hooks/useAuth';

interface Template {
  _id: string;
  title: string;
  description: string;
  estimatedDuration: string;
  terms: string;
  createdAt: string;
  updatedAt: string;
}

interface ResponseTemplatesProps {
  onSelectTemplate: (template: Omit<Template, '_id' | 'createdAt' | 'updatedAt'>) => void;
}

const ResponseTemplates: React.FC<ResponseTemplatesProps> = ({ onSelectTemplate }) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<Template | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { getAuthToken } = useAuth();

  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    estimatedDuration: '',
    terms: '',
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const token = getAuthToken();

      if (!token) {
        setError('Authentication required');
        setLoading(false);
        return;
      }

      // In a real implementation, this would be an actual API call
      // For this example, we'll use mock data
      const mockTemplates: Template[] = [
        {
          _id: '1',
          title: 'Standard Service',
          description: 'I can provide this service with high quality and attention to detail. Based on your requirements, I will ensure the work is completed to your satisfaction.',
          estimatedDuration: '3-5 business days',
          terms: 'Payment: 50% upfront, 50% upon completion. Revisions: Up to 2 free revisions included.',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        {
          _id: '2',
          title: 'Premium Package',
          description: 'This premium service includes additional features and priority support. I will work closely with you to ensure all your requirements are met and exceeded.',
          estimatedDuration: '1-2 business days',
          terms: 'Payment: 100% upfront. Revisions: Unlimited revisions for 7 days after delivery.',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        {
          _id: '3',
          title: 'Budget Option',
          description: 'This is a cost-effective solution that covers the essential requirements. Perfect for those with budget constraints who still need quality work.',
          estimatedDuration: '5-7 business days',
          terms: 'Payment: 100% upon completion. Revisions: 1 free revision included.',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      ];

      setTemplates(mockTemplates);
      setError(null);
    } catch (err) {
      console.error('Error fetching templates:', err);
      setError('Failed to load response templates');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (template?: Template) => {
    if (template) {
      setEditingTemplate(template);
      setFormValues({
        title: template.title,
        description: template.description,
        estimatedDuration: template.estimatedDuration,
        terms: template.terms,
      });
    } else {
      setEditingTemplate(null);
      setFormValues({
        title: '',
        description: '',
        estimatedDuration: '',
        terms: '',
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveTemplate = async () => {
    try {
      const token = getAuthToken();

      if (!token) {
        setError('Authentication required');
        return;
      }

      // In a real implementation, this would be an actual API call
      // For this example, we'll simulate saving a template
      if (editingTemplate) {
        // Update existing template
        const updatedTemplate = {
          ...editingTemplate,
          ...formValues,
          updatedAt: new Date().toISOString(),
        };

        setTemplates((prev) =>
          prev.map((t) => (t._id === editingTemplate._id ? updatedTemplate : t))
        );

        setSnackbarMessage('Template updated successfully');
      } else {
        // Create new template
        const newTemplate: Template = {
          _id: `temp-${Date.now()}`,
          ...formValues,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };

        setTemplates((prev) => [...prev, newTemplate]);
        setSnackbarMessage('Template created successfully');
      }

      setSnackbarOpen(true);
      handleCloseDialog();
    } catch (err) {
      console.error('Error saving template:', err);
      setError('Failed to save template');
    }
  };

  const handleDeleteTemplate = async (templateId: string) => {
    try {
      const token = getAuthToken();

      if (!token) {
        setError('Authentication required');
        return;
      }

      // In a real implementation, this would be an actual API call
      // For this example, we'll simulate deleting a template
      setTemplates((prev) => prev.filter((t) => t._id !== templateId));
      setSnackbarMessage('Template deleted successfully');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting template:', err);
      setError('Failed to delete template');
    }
  };

  const handleUseTemplate = (template: Template) => {
    const { _id, createdAt, updatedAt, ...templateData } = template;
    onSelectTemplate(templateData);
    setSnackbarMessage('Template applied to response');
    setSnackbarOpen(true);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Response Templates</Typography>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          size="small"
        >
          Create Template
        </Button>
      </Box>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      {templates.length === 0 ? (
        <Alert severity="info" sx={{ mb: 2 }}>
          You don't have any response templates yet. Create templates to respond to leads more efficiently.
        </Alert>
      ) : (
        <List>
          {templates.map((template) => (
            <Paper key={template._id} sx={{ mb: 2, overflow: 'hidden' }}>
              <ListItem sx={{ bgcolor: 'background.default' }}>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle1">{template.title}</Typography>
                      <Chip
                        label={template.estimatedDuration}
                        size="small"
                        sx={{ ml: 1 }}
                        color="primary"
                        variant="outlined"
                      />
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Use Template">
                    <IconButton edge="end" onClick={() => handleUseTemplate(template)}>
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Template">
                    <IconButton edge="end" onClick={() => handleOpenDialog(template)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Template">
                    <IconButton edge="end" onClick={() => handleDeleteTemplate(template._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Typography variant="body2" paragraph>
                  {template.description}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  <strong>Terms:</strong> {template.terms}
                </Typography>
              </Box>
            </Paper>
          ))}
        </List>
      )}

      {/* Template Form Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingTemplate ? 'Edit Response Template' : 'Create Response Template'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Template Title"
                name="title"
                value={formValues.title}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                multiline
                rows={4}
                required
                placeholder="Describe your service offering in detail"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Estimated Duration"
                name="estimatedDuration"
                value={formValues.estimatedDuration}
                onChange={handleInputChange}
                required
                placeholder="e.g., 3-5 business days"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Terms and Conditions"
                name="terms"
                value={formValues.terms}
                onChange={handleInputChange}
                multiline
                rows={3}
                required
                placeholder="Payment terms, revision policy, etc."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSaveTemplate}
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Save Template
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ResponseTemplates;