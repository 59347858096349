/**
 * Analytics utility for tracking page views and events with Google Tag Manager
 */

// Define types for the dataLayer
interface DataLayerEvent {
  event: string;
  [key: string]: any;
}

// Define types for event tracking
interface EventOptions {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  [key: string]: any;
}

// Define types for page view tracking
interface PageViewOptions {
  title?: string;
  path?: string;
  [key: string]: any;
}

/**
 * Analytics class for tracking events and page views
 */
class Analytics {
  /**
   * Initialize the dataLayer array if it doesn't exist
   */
  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  /**
   * Push an event to the dataLayer
   * @param data The data to push to the dataLayer
   */
  private pushToDataLayer(data: DataLayerEvent): void {
    window.dataLayer.push(data);
  }

  /**
   * Track a page view
   * @param options Page view options
   */
  trackPageView(options: PageViewOptions = {}): void {
    const { title = document.title, path = window.location.pathname, ...rest } = options;

    this.pushToDataLayer({
      event: 'pageview',
      page: {
        title,
        path,
        ...rest,
      },
    });
  }

  /**
   * Track an event
   * @param options Event options
   */
  trackEvent(options: EventOptions): void {
    const {
      category,
      action,
      label = undefined,
      value = undefined,
      nonInteraction = false,
      ...rest
    } = options;

    this.pushToDataLayer({
      event: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteraction,
      ...rest,
    });
  }

  /**
   * Track a user login
   * @param method The login method used (e.g., 'email', 'google', 'facebook')
   * @param userId Optional user ID
   */
  trackLogin(method: string, userId?: string): void {
    this.trackEvent({
      category: 'User',
      action: 'Login',
      label: method,
      userId,
    });
  }

  /**
   * Track a user registration
   * @param method The registration method used (e.g., 'email', 'google', 'facebook')
   * @param userId Optional user ID
   */
  trackSignup(method: string, userId?: string): void {
    this.trackEvent({
      category: 'User',
      action: 'Signup',
      label: method,
      userId,
    });
  }

  /**
   * Track a search
   * @param query The search query
   * @param resultsCount The number of results
   */
  trackSearch(query: string, resultsCount: number): void {
    this.trackEvent({
      category: 'Search',
      action: 'Search',
      label: query,
      value: resultsCount,
    });
  }

  /**
   * Track a product view
   * @param productId The product ID
   * @param name The product name
   * @param price The product price
   * @param category The product category
   */
  trackProductView(productId: string, name: string, price: number, category: string): void {
    this.pushToDataLayer({
      event: 'productView',
      ecommerce: {
        detail: {
          products: [{
            id: productId,
            name,
            price,
            category,
          }],
        },
      },
    });
  }

  /**
   * Track adding a product to cart
   * @param productId The product ID
   * @param name The product name
   * @param price The product price
   * @param quantity The quantity added
   * @param category The product category
   */
  trackAddToCart(productId: string, name: string, price: number, quantity: number, category: string): void {
    this.pushToDataLayer({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: [{
            id: productId,
            name,
            price,
            quantity,
            category,
          }],
        },
      },
    });
  }
}

// Declare dataLayer on the window object
declare global {
  interface Window {
    dataLayer: any[];
  }
}

// Create singleton instance
const analytics = new Analytics();

export default analytics;