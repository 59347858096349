import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Box, Breadcrumbs, CircularProgress, Alert } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../components/common/PageTitle';
import ProfessionalSearchBar from '../../components/search/ProfessionalSearchBar';
import ServiceCard from '../../components/search/ServiceCard';
import axios from 'axios';
import { IService, ICategory } from '../../types/models';
import { SearchParams } from '../../types/search';

const ServiceCategoryPage: React.FC = () => {
  const { categorySlug } = useParams<{ categorySlug: string }>();
  const [category, setCategory] = useState<ICategory | null>(null);
  const [services, setServices] = useState<IService[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch all categories for the search bar
        const categoriesResponse = await axios.get('/api/categories');
        setCategories(categoriesResponse.data);

        // Fetch services for this category
        const response = await axios.get(`/api/services/category/${categorySlug}`);
        setCategory(response.data.category);
        setServices(response.data.services);
      } catch (error) {
        console.error('Error fetching category services:', error);
        setError('Failed to load services for this category');
      } finally {
        setLoading(false);
      }
    };

    if (categorySlug) {
      fetchData();
    }
  }, [categorySlug]);

  // Handle search submission
  const handleSearch = (params: SearchParams) => {
    // Build the search URL
    const searchParams = new URLSearchParams();
    if (params.query) searchParams.set('service', params.query);
    if (params.location?.address) searchParams.set('emirate', params.location.address);

    // Navigate to the search results page
    navigate(`/find-professionals?${searchParams.toString()}`);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !category) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h5" color="error" align="center">
          {error || 'Category not found'}
        </Typography>
      </Container>
    );
  }

  // Create structured data for SEO
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    'itemListElement': services.map((service, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'item': {
        '@type': 'Service',
        'name': service.title,
        'description': service.description,
        'provider': {
          '@type': 'Organization',
          'name': service.providerData?.businessName || 'Service Provider',
        },
        'url': `https://www.homezy.co/services/${service._id}`,
      },
    })),
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageTitle
        title={`${category.name} Services in UAE - Homezy`}
        description={`Find professional ${category.name.toLowerCase()} services in the UAE. Browse our directory of verified professionals for your ${category.name.toLowerCase()} needs.`}
        keywords={`${category.name.toLowerCase()}, services, UAE, Dubai, Abu Dhabi, professionals, home improvement`}
        locale="en_AE"
      />

      {/* Structured data for SEO */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />

      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none' }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        <Link to="/services" style={{ color: 'inherit', textDecoration: 'none' }}>
          Services
        </Link>
        <Typography color="text.primary">{category.name}</Typography>
      </Breadcrumbs>

      {/* Search Bar */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Find {category.name} Professionals
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph align="center">
          Search for skilled {category.name.toLowerCase()} professionals in your area
        </Typography>
        <ProfessionalSearchBar
          onSearch={handleSearch}
          initialService={category.name}
        />
      </Box>

      <Typography variant="h5" component="h2" gutterBottom>
        {category.name} Services
      </Typography>

      <Typography variant="body1" color="text.secondary" paragraph sx={{ mb: 3 }}>
        {category.description}
      </Typography>

      {services.length > 0 ? (
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {services.map((service) => (
            <Grid item xs={12} sm={6} md={3} lg={2} key={service._id}>
              <ServiceCard service={service} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ py: 4, textAlign: 'center' }}>
          <Alert severity="info" sx={{ maxWidth: 500, mx: 'auto' }}>
            <Typography variant="h6">No services found in this category</Typography>
            <Typography variant="body2">
              Please check back later or explore other categories
            </Typography>
          </Alert>
        </Box>
      )}
    </Container>
  );
};

export default ServiceCategoryPage;