import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Paper,
  Alert,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { env } from '../../config';

interface PreferenceOption {
  _id: string;
  optionText: string;
  value: string;
}

interface PreferenceQuestion {
  _id: string;
  questionText: string;
  questionType: 'single_select' | 'multi_select' | 'text';
  options: PreferenceOption[];
  isRequired: boolean;
  displayOrder: number;
}

interface ServicePreferenceTemplate {
  _id: string;
  service?: string;
  category?: string;
  questions: PreferenceQuestion[];
  isActive: boolean;
}

interface ProviderCapability {
  questionId: string;
  supportedOptions: string[];
}

interface ServiceCapabilitiesFormProps {
  serviceId: string;
  onSubmit: (capabilities: ProviderCapability[]) => Promise<void>;
  onCancel?: () => void;
  existingCapabilities?: ProviderCapability[];
}

const ServiceCapabilitiesForm: React.FC<ServiceCapabilitiesFormProps> = ({
  serviceId,
  onSubmit,
  onCancel,
  existingCapabilities = [],
}) => {
  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState<ServicePreferenceTemplate | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Define a type for our capabilities object
  type CapabilitiesMap = Record<string, string[] | string>;

  // Map existing capabilities to a more usable format
  const initialCapabilities = existingCapabilities.reduce((acc, capability) => {
    acc[capability.questionId] = capability.supportedOptions;
    return acc;
  }, {} as CapabilitiesMap);

  const validationSchema = Yup.object().shape({
    capabilities: Yup.object().test(
      'required-capabilities',
      'Please answer all required questions',
      (capabilities: any) => {
        if (!template) return true;

        // Check if all required questions have answers
        return template.questions
          .filter(q => q.isRequired)
          .every(q => {
            const answers = capabilities[q._id] as string | string[] | undefined;
            return answers && (Array.isArray(answers) ? answers.length > 0 : answers.trim() !== '');
          });
      }
    ),
  });

  const formik = useFormik<{ capabilities: CapabilitiesMap }>({
    initialValues: {
      capabilities: initialCapabilities,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        // Convert capabilities object to array format expected by API
        const formattedCapabilities = Object.entries(values.capabilities).map(([questionId, supportedOptions]) => ({
          questionId,
          supportedOptions: Array.isArray(supportedOptions) ? supportedOptions : [supportedOptions],
        }));

        await onSubmit(formattedCapabilities);
      } catch (err) {
        console.error('Error submitting capabilities:', err);
        setError('Failed to save capabilities. Please try again.');
      }
    },
  });

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${env.API_URL}/target-preferences/templates/service/${serviceId}`);
        setTemplate(response.data);

        // Initialize form values for questions that don't have existing capabilities
        if (response.data && response.data.questions) {
          const newCapabilities = { ...formik.values.capabilities };

          response.data.questions.forEach((question: PreferenceQuestion) => {
            if (!newCapabilities[question._id]) {
              if (question.questionType === 'multi_select') {
                newCapabilities[question._id] = [];
              } else if (question.questionType === 'single_select') {
                newCapabilities[question._id] = '';
              } else {
                newCapabilities[question._id] = '';
              }
            }
          });

          formik.setValues({ capabilities: newCapabilities });
        }
      } catch (err) {
        console.error('Error fetching service preference template:', err);
        setError('Failed to load service preferences. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [serviceId]);

  const handleCheckboxChange = (questionId: string, optionValue: string) => {
    const currentValues = formik.values.capabilities[questionId] || [];
    let newValues;

    if (Array.isArray(currentValues)) {
      if (currentValues.includes(optionValue)) {
        newValues = currentValues.filter((value: string) => value !== optionValue);
      } else {
        newValues = [...currentValues, optionValue];
      }
    } else {
      // Handle the case where currentValues is not an array (should not happen for checkboxes)
      newValues = [optionValue];
    }

    formik.setFieldValue(`capabilities.${questionId}`, newValues);
  };

  const handleRadioChange = (questionId: string, optionValue: string) => {
    formik.setFieldValue(`capabilities.${questionId}`, optionValue);
  };

  const handleTextChange = (questionId: string, value: string) => {
    formik.setFieldValue(`capabilities.${questionId}`, value);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!template || !template.questions || template.questions.length === 0) {
    return (
      <Alert severity="info" sx={{ mb: 2 }}>
        No preference questions are available for this service.
      </Alert>
    );
  }

  return (
    <form onSubmit={(e) => {
      formik.handleSubmit(e);
    }}>
      <Typography variant="h6" gutterBottom>
        Service Capabilities
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        Select the capabilities you can provide for this service. This will help match you with customers who need these specific services.
      </Typography>

      {template.questions
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((question) => (
          <Paper key={question._id} sx={{ p: 3, mb: 3 }}>
            <FormControl
              component="fieldset"
              fullWidth
              error={formik.touched.capabilities && Boolean(formik.errors.capabilities)}
            >
              <FormLabel component="legend" sx={{ fontWeight: 'bold', mb: 1 }}>
                {question.questionText}
                {question.isRequired && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>

              {question.questionType === 'multi_select' && (
                <FormGroup>
                  {question.options.map((option) => (
                    <FormControlLabel
                      key={option._id}
                      control={
                        <Checkbox
                          checked={(formik.values.capabilities[question._id] || []).includes(option.value)}
                          onChange={() => handleCheckboxChange(question._id, option.value)}
                        />
                      }
                      label={option.optionText}
                    />
                  ))}
                </FormGroup>
              )}

              {question.questionType === 'single_select' && (
                <RadioGroup
                  value={formik.values.capabilities[question._id] || ''}
                  onChange={(e) => handleRadioChange(question._id, e.target.value)}
                >
                  {question.options.map((option) => (
                    <FormControlLabel
                      key={option._id}
                      value={option.value}
                      control={<Radio />}
                      label={option.optionText}
                    />
                  ))}
                </RadioGroup>
              )}

              {question.questionType === 'text' && (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={formik.values.capabilities[question._id] || ''}
                  onChange={(e) => handleTextChange(question._id, e.target.value)}
                  placeholder="Enter your response"
                />
              )}
            </FormControl>
          </Paper>
        ))}

      {formik.touched.capabilities && formik.errors.capabilities && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {formik.errors.capabilities.toString()}
        </Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
        {onCancel && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
          onClick={() => {
            // The form's onSubmit will handle the actual submission
          }}
        >
          {formik.isSubmitting ? 'Saving...' : 'Save Capabilities'}
        </Button>
      </Box>
    </form>
  );
};

export default ServiceCapabilitiesForm;