import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import performanceMonitor from '../utils/performanceMonitor';

interface PerformanceTrackingOptions {
  trackPageLoad?: boolean;
  trackImageLoads?: boolean;
  trackSearches?: boolean;
  componentName?: string;
}

/**
 * HOC that adds performance tracking to a component
 * @param {React.ComponentType} WrappedComponent - Component to wrap
 * @param {Object} options - Configuration options
 * @returns {React.ComponentType} - Wrapped component with performance tracking
 */
const withPerformanceTracking = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options: PerformanceTrackingOptions = {}
) => {
  const {
    trackPageLoad = true,
    trackImageLoads = true,
    trackSearches = true,
    componentName = WrappedComponent.displayName || WrappedComponent.name || 'Component',
  } = options;

  const WithPerformanceTracking: React.FC<P> = (props) => {
    const location = useLocation();
    const mountTime = React.useRef(performance.now());

    useEffect(() => {
      // Track initial page load
      if (trackPageLoad) {
        const loadTime = performance.now() - mountTime.current;
        performanceMonitor.trackPageLoad(location.pathname, loadTime);
      }

      // Track image loads
      if (trackImageLoads) {
        const observer = new PerformanceObserver((list) => {
          list.getEntries().forEach((entry) => {
            if (entry instanceof PerformanceResourceTiming && entry.initiatorType === 'img') {
              performanceMonitor.trackImageLoad(
                entry.name,
                entry.startTime,
                entry.responseEnd,
                entry.transferSize > 0
              );
            }
          });
        });

        observer.observe({ entryTypes: ['resource'] });
        return () => observer.disconnect();
      }
    }, [location.pathname]);

    // Wrap search functions to track performance
    const wrapSearchFunction = (searchFn: Function) => {
      return async (...args: any[]) => {
        if (!trackSearches) return searchFn(...args);

        const startTime = performance.now();
        try {
          const result = await searchFn(...args);
          performanceMonitor.trackSearch(startTime, performance.now(), result.fromCache || false);
          return result;
        } catch (error) {
          performanceMonitor.trackSearch(startTime, performance.now(), false);
          throw error;
        }
      };
    };

    // Wrap props that look like search functions
    const wrappedProps = Object.entries(props).reduce<Partial<P>>((acc, [key, value]) => {
      if (
        typeof value === 'function' &&
        (key.toLowerCase().includes('search') ||
          key.toLowerCase().includes('fetch') ||
          key.toLowerCase().includes('load'))
      ) {
        acc[key as keyof P] = wrapSearchFunction(value) as P[keyof P];
      } else {
        acc[key as keyof P] = value as P[keyof P];
      }
      return acc;
    }, {});

    return <WrappedComponent {...(wrappedProps as P)} />;
  };

  WithPerformanceTracking.displayName = `withPerformanceTracking(${componentName})`;

  return WithPerformanceTracking;
};

export default withPerformanceTracking;

// Utility function to wrap multiple components
export const wrapWithPerformanceTracking = <T extends { [key: string]: React.ComponentType<any> }>(
  components: T,
  options: PerformanceTrackingOptions = {}
) => {
  return Object.entries(components).reduce((acc, [name, component]) => {
    const wrappedComponent = withPerformanceTracking(component, {
      ...options,
      componentName: name,
    });
    return {
      ...acc,
      [name]: wrappedComponent,
    };
  }, {} as { [K in keyof T]: React.ComponentType<React.ComponentProps<T[K]>> });
};