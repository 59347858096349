import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import houseLogo from '../../assets/house-logo.svg';

interface LogoProps {
  variant?: 'full' | 'icon' | 'text';
  size?: 'small' | 'medium' | 'large';
  showTagline?: boolean;
}

const Logo: React.FC<LogoProps> = ({ variant = 'full', size = 'medium', showTagline = false }) => {
  const theme = useTheme();

  // Size mappings
  const sizeMap = {
    small: {
      icon: 24,
      text: '1rem',
      tagline: '0.7rem',
    },
    medium: {
      icon: 32,
      text: '1.5rem',
      tagline: '0.8rem',
    },
    large: {
      icon: 48,
      text: '2rem',
      tagline: '1rem',
    },
  };

  return (
    <Box
      component={RouterLink}
      to="/"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: variant === 'full' ? 'flex-start' : 'center',
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {(variant === 'full' || variant === 'icon') && (
          <Box
            component="img"
            src={houseLogo}
            alt="Homezy Logo"
            sx={{
              width: sizeMap[size].icon,
              height: sizeMap[size].icon,
              paddingBottom: '3px',
            }}
          />
        )}

        {(variant === 'full' || variant === 'text') && (
          <Typography
            variant="h6"
            component="span"
            fontWeight="bold"
            fontFamily={'Quicksand'}
            sx={{
              ml: variant === 'full' ? 0.5 : 0,
              fontSize: `calc(${sizeMap[size].text} + 0.3rem)`,
            }}
          >
            homezy
          </Typography>
        )}
      </Box>

      {showTagline && (
        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            fontSize: sizeMap[size].tagline,
            opacity: 0.8,
          }}
        >
          home made easy
        </Typography>
      )}
    </Box>
  );
};

export default Logo;
