import React from 'react';
import { Helmet } from 'react-helmet-async';

interface PageTitleProps {
  title: string;
  description?: string;
  image?: string;
  canonicalUrl?: string;
  keywords?: string;
  locale?: string;
  alternateLanguages?: { locale: string; url: string }[];
  type?: string;
  noIndex?: boolean;
}

/**
 * Enhanced PageTitle component with comprehensive SEO meta tags
 * Includes Open Graph, Twitter Card, canonical URLs, and hreflang tags
 */
const PageTitle: React.FC<PageTitleProps> = ({
  title,
  description,
  image,
  canonicalUrl,
  keywords,
  locale = 'en_AE',
  alternateLanguages,
  type = 'website',
  noIndex = false,
}) => {
  const fullTitle = `${title} | Homezy - Home Made Easy`;
  const siteUrl = 'https://homezy.co'; // Base URL for the site
  const defaultImage = `${siteUrl}/logo512.png`; // Default image for sharing

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {noIndex && <meta name="robots" content="noindex, nofollow" />}

      {/* Canonical URL */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={fullTitle} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:type" content={type} />
      <meta property="og:image" content={image || defaultImage} />
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:site_name" content="Homezy" />
      <meta property="og:locale" content={locale} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      {description && <meta name="twitter:description" content={description} />}
      <meta name="twitter:image" content={image || defaultImage} />

      {/* Hreflang Tags for Internationalization */}
      {alternateLanguages?.map((lang) => (
        <link key={lang.locale} rel="alternate" hrefLang={lang.locale} href={lang.url} />
      ))}

      {/* Default hreflang for current page */}
      {canonicalUrl && <link rel="alternate" hrefLang={locale} href={canonicalUrl} />}

      {/* UAE-specific meta tags */}
      <meta name="geo.region" content="AE" />
      <meta name="geo.placename" content="United Arab Emirates" />
    </Helmet>
  );
};

export default PageTitle;
