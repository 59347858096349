import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container, Paper, Tabs, Tab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { SellerDashboard } from '../../../components/seller/ui/SellerDashboard';
import { SellerProducts } from '../../../components/seller/ui/SellerProducts';
import { SellerProfile } from '../../../components/seller/ui/SellerProfile';

export const SellerPortalPage: React.FC = () => {
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop() || 'dashboard';

  const tabs = [
    { label: 'Dashboard', value: 'dashboard', path: '/seller/dashboard' },
    { label: 'Products', value: 'products', path: '/seller/dashboard/products' },
    { label: 'Profile', value: 'profile', path: '/seller/dashboard/profile' },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={currentTab}
          variant="fullWidth"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              component={Link}
              to={tab.path}
            />
          ))}
        </Tabs>
      </Paper>

      <Routes>
        <Route path="/" element={<SellerDashboard />} />
        <Route path="products" element={<SellerProducts />} />
        <Route path="profile" element={<SellerProfile />} />
        <Route path="*" element={<Navigate to="/seller/dashboard" replace />} />
      </Routes>
    </Container>
  );
};