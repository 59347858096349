import React from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Chip,
} from '@mui/material';
import { PreferenceQuestion, CustomerPreference } from '../../../types/preference';

interface ReviewStepProps {
  formData: {
    service: string;
    projectType?: string;
    description?: string;
    timeline?: string;
    propertyType?: string;
    spaces?: string[];
    contactInfo?: {
      name: string;
      email: string;
      phone?: string;
    };
  };
  onBack: () => void;
  onSubmit: () => void;
  serviceQuestions: PreferenceQuestion[];
  preferences: CustomerPreference[];
  isAuthenticated: boolean;
}

const ReviewStep: React.FC<ReviewStepProps> = ({
  formData,
  onBack,
  onSubmit,
  serviceQuestions,
  preferences,
  isAuthenticated,
}) => {
  // Group preferences by category/step
  const groupedPreferences = {
    projectType: serviceQuestions.filter(
      q => q.questionText.toLowerCase().includes('project') ||
           q.questionText.toLowerCase().includes('type') ||
           q.questionText.toLowerCase().includes('option')
    ),
    projectDetails: serviceQuestions.filter(
      q => q.questionText.toLowerCase().includes('device') ||
           q.questionText.toLowerCase().includes('smart') ||
           q.questionText.toLowerCase().includes('system') ||
           q.questionText.toLowerCase().includes('brand') ||
           q.questionText.toLowerCase().includes('prefer')
    ),
    timeline: serviceQuestions.filter(
      q => q.questionText.toLowerCase().includes('timeline') ||
           q.questionText.toLowerCase().includes('when') ||
           q.questionText.toLowerCase().includes('start') ||
           q.questionText.toLowerCase().includes('time')
    ),
    propertyType: serviceQuestions.filter(
      q => q.questionText.toLowerCase().includes('property') ||
           q.questionText.toLowerCase().includes('home') ||
           q.questionText.toLowerCase().includes('house') ||
           q.questionText.toLowerCase().includes('space') ||
           q.questionText.toLowerCase().includes('room') ||
           q.questionText.toLowerCase().includes('area') ||
           q.questionText.toLowerCase().includes('renovated')
    ),
    additionalDetails: serviceQuestions.filter(
      q => q.questionText.toLowerCase().includes('additional') ||
           q.questionText.toLowerCase().includes('other') ||
           q.questionText.toLowerCase().includes('details') ||
           q.questionType === 'text'
    ),
  };

  // Get preference value for a question
  const getPreferenceValue = (question: PreferenceQuestion): string => {
    const preference = preferences.find(p => p.questionId === question._id);

    if (!preference) return 'Not specified';

    if (preference.customAnswer) {
      return preference.customAnswer;
    }

    if (preference.selectedOptions.length === 0) {
      return 'Not specified';
    }

    return preference.selectedOptions.map(opt => {
      const option = question.options.find(o => o.value === opt);
      return option ? option.optionText : opt;
    }).join(', ');
  };

  // Render a section of preferences
  const renderPreferenceSection = (title: string, questions: PreferenceQuestion[]) => {
    if (questions.length === 0) return null;

    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <List>
          {questions.map(question => (
            <React.Fragment key={question._id}>
              <ListItem>
                <ListItemText
                  primary={question.questionText}
                  secondary={getPreferenceValue(question)}
                />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
      </Box>
    );
  };

  // Render default form data if no service-specific questions
  const renderDefaultFormData = () => {
    return (
      <>
        {formData.projectType && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Project Type
            </Typography>
            <Typography>
              {formData.projectType === 'new_existing' && 'New installation in an existing property'}
              {formData.projectType === 'new_construction' && 'New installation in a new construction'}
              {formData.projectType === 'service_existing' && 'Service existing system'}
              {formData.projectType === 'augment_existing' && 'Augment existing system'}
              {formData.projectType === 'other' && 'Other'}
            </Typography>
          </Box>
        )}

        {formData.timeline && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Timeline
            </Typography>
            <Typography>
              {formData.timeline === 'urgent' && 'Urgent — need a pro right away (Within 48 hours)'}
              {formData.timeline === 'soon' && 'Ready to hire, but not in a hurry (Within 7 days)'}
              {formData.timeline === 'flexible' && 'Still researching (No timeline in mind)'}
            </Typography>
          </Box>
        )}

        {formData.propertyType && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Property Type
            </Typography>
            <Typography>
              {formData.propertyType === 'apartment' && 'Apartment'}
              {formData.propertyType === 'villa' && 'Villa'}
              {formData.propertyType === 'office' && 'Office'}
              {formData.propertyType === 'commercial' && 'Commercial space'}
              {formData.propertyType === 'other' && 'Other'}
            </Typography>
          </Box>
        )}

        {formData.spaces && formData.spaces.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Spaces to be Renovated
            </Typography>
            <Grid container spacing={1}>
              {formData.spaces.map(space => (
                <Grid item key={space}>
                  <Chip label={space} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </>
    );
  };

  return (
    <Paper elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
      <Typography variant="h5" gutterBottom>
        Review Your Request
      </Typography>

      {/* Render service-specific preferences */}
      {renderPreferenceSection('Project Type', groupedPreferences.projectType)}
      {renderPreferenceSection('Project Details', groupedPreferences.projectDetails)}
      {renderPreferenceSection('Timeline', groupedPreferences.timeline)}
      {renderPreferenceSection('Property Details', groupedPreferences.propertyType)}
      {renderPreferenceSection('Additional Details', groupedPreferences.additionalDetails)}

      {/* Render default form data if no service-specific questions */}
      {serviceQuestions.length === 0 && renderDefaultFormData()}

      {/* Always show description */}
      {formData.description && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Project Description
          </Typography>
          <Typography>{formData.description}</Typography>
        </Box>
      )}

      {/* Show contact info for non-authenticated users */}
      {!isAuthenticated && formData.contactInfo && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Name" secondary={formData.contactInfo.name} />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Email" secondary={formData.contactInfo.email} />
            </ListItem>
            {formData.contactInfo.phone && (
              <>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Phone" secondary={formData.contactInfo.phone} />
                </ListItem>
              </>
            )}
          </List>
        </Box>
      )}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Submit Request
        </Button>
      </Box>
    </Paper>
  );
};

export default ReviewStep;