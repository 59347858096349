import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Tabs,
  Tab,
  Divider,
  Button,
  Chip,
  Stack,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShareIcon from '@mui/icons-material/Share';
import ProjectCard from '../../components/ideas/ProjectCard';
import ShareModal from '../../components/ideas/ShareModal';
import SaveToIdeabookModal from '../../components/ideas/SaveToIdeabookModal';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';
import { useSaveIdea } from '../../hooks/useSaveIdea';
import { ideaService, IdeaProject } from '../../services/ideaService';

// Room data - in a real app, this would come from an API or CMS
const rooms = [
  {
    id: 'kitchen',
    name: 'Kitchen',
    image: 'https://images.pexels.com/photos/1599791/pexels-photo-1599791.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Cooking & dining spaces',
    longDescription: 'The kitchen is the heart of the home, where cooking, dining, and gathering happen. From modern minimalist designs to cozy traditional styles, find inspiration for your perfect kitchen.',
    projectCount: 1245,
    popularTags: ['Modern', 'Traditional', 'Farmhouse', 'Industrial', 'Small Space', 'Open Concept'],
    featuredProjects: [
      {
        id: 'p1',
        title: 'Modern White Kitchen',
        image: 'https://images.pexels.com/photos/2724749/pexels-photo-2724749.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'Interior Elegance',
        likes: 342,
      },
      {
        id: 'p2',
        title: 'Rustic Farmhouse Kitchen',
        image: 'https://images.pexels.com/photos/1080721/pexels-photo-1080721.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'Countryside Designs',
        likes: 256,
      },
      {
        id: 'p3',
        title: 'Industrial Loft Kitchen',
        image: 'https://images.pexels.com/photos/2062426/pexels-photo-2062426.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'Urban Spaces',
        likes: 189,
      },
    ],
  },
  {
    id: 'bathroom',
    name: 'Bathroom',
    image: 'https://images.pexels.com/photos/1457847/pexels-photo-1457847.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Bathrooms & powder rooms',
    longDescription: 'Transform your bathroom into a personal spa retreat. Explore designs ranging from luxurious master baths to space-efficient powder rooms.',
    projectCount: 987,
    popularTags: ['Spa-like', 'Modern', 'Traditional', 'Small Space', 'Luxury', 'Budget-friendly'],
    featuredProjects: [
      {
        id: 'p4',
        title: 'Luxury Spa Bathroom',
        image: 'https://images.pexels.com/photos/1910472/pexels-photo-1910472.jpeg?auto=compress&cs=tinysrgb&w=687&h=1024&dpr=1',
        designer: 'Luxury Interiors',
        likes: 278,
      },
      {
        id: 'p5',
        title: 'Modern Minimalist Bathroom',
        image: 'https://images.pexels.com/photos/1454804/pexels-photo-1454804.jpeg?auto=compress&cs=tinysrgb&w=1169&h=750&dpr=1',
        designer: 'Minimalist Design Co',
        likes: 215,
      },
      {
        id: 'p6',
        title: 'Small Space Bathroom Solution',
        image: 'https://images.pexels.com/photos/1909791/pexels-photo-1909791.jpeg?auto=compress&cs=tinysrgb&w=687&h=1024&dpr=1',
        designer: 'Small Space Solutions',
        likes: 167,
      },
    ],
  },
  {
    id: 'living-room',
    name: 'Living Room',
    image: 'https://images.pexels.com/photos/1571459/pexels-photo-1571459.jpeg?auto=compress&cs=tinysrgb&w=1227&h=750&dpr=1',
    description: 'Family & entertainment spaces',
    longDescription: 'The living room is where family and friends gather. Find inspiration for comfortable, stylish spaces that reflect your lifestyle and personality.',
    projectCount: 1432,
    popularTags: ['Modern', 'Traditional', 'Eclectic', 'Minimalist', 'Cozy', 'Open Concept'],
    featuredProjects: [
      {
        id: 'p7',
        title: 'Contemporary Open Concept Living Room',
        image: 'https://images.pexels.com/photos/1643383/pexels-photo-1643383.jpeg?auto=compress&cs=tinysrgb&w=1074&h=750&dpr=1',
        designer: 'Modern Living Designs',
        likes: 312,
      },
      {
        id: 'p8',
        title: 'Cozy Traditional Living Room',
        image: 'https://images.pexels.com/photos/1668860/pexels-photo-1668860.jpeg?auto=compress&cs=tinysrgb&w=1332&h=750&dpr=1',
        designer: 'Classic Home Interiors',
        likes: 287,
      },
      {
        id: 'p9',
        title: 'Minimalist Scandinavian Living Room',
        image: 'https://images.pexels.com/photos/1918291/pexels-photo-1918291.jpeg?auto=compress&cs=tinysrgb&w=687&h=1024&dpr=1',
        designer: 'Nordic Style Studio',
        likes: 245,
      },
    ],
  },
  {
    id: 'bedroom',
    name: 'Bedroom',
    image: 'https://images.pexels.com/photos/1743229/pexels-photo-1743229.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Bedrooms & closets',
    longDescription: 'Create a peaceful retreat in your bedroom. From master suites to guest rooms and kids\' spaces, find ideas for comfortable and stylish bedrooms.',
    projectCount: 1089,
    popularTags: ['Master Suite', 'Guest Room', 'Kids Room', 'Small Space', 'Storage Solutions', 'Luxury'],
    featuredProjects: [
      {
        id: 'p10',
        title: 'Serene Master Bedroom',
        image: 'https://images.pexels.com/photos/1329711/pexels-photo-1329711.jpeg?auto=compress&cs=tinysrgb&w=1171&h=750&dpr=1',
        designer: 'Tranquil Spaces',
        likes: 298,
      },
      {
        id: 'p11',
        title: 'Colorful Kids Bedroom',
        image: 'https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&w=1167&h=750&dpr=1',
        designer: 'Playful Interiors',
        likes: 234,
      },
      {
        id: 'p12',
        title: 'Compact Guest Bedroom',
        image: 'https://images.pexels.com/photos/1648768/pexels-photo-1648768.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'Smart Space Design',
        likes: 176,
      },
    ],
  },
  {
    id: 'outdoor',
    name: 'Outdoor',
    image: 'https://images.pexels.com/photos/2132227/pexels-photo-2132227.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
    description: 'Patios, decks & landscapes',
    longDescription: 'Extend your living space outdoors with beautiful patios, decks, and landscaping. Find inspiration for outdoor kitchens, gardens, and relaxation areas.',
    projectCount: 876,
    popularTags: ['Patio', 'Deck', 'Garden', 'Outdoor Kitchen', 'Pool', 'Small Space'],
    featuredProjects: [
      {
        id: 'p13',
        title: 'Modern Outdoor Living Space',
        image: 'https://images.pexels.com/photos/1643389/pexels-photo-1643389.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'Outdoor Elegance',
        likes: 267,
      },
      {
        id: 'p14',
        title: 'Cozy Backyard Patio',
        image: 'https://images.pexels.com/photos/1643384/pexels-photo-1643384.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'Backyard Bliss',
        likes: 223,
      },
      {
        id: 'p15',
        title: 'Urban Garden Oasis',
        image: 'https://images.pexels.com/photos/2132227/pexels-photo-2132227.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'City Gardens',
        likes: 198,
      },
    ],
  },
  {
    id: 'home-office',
    name: 'Home Office',
    image: 'https://images.pexels.com/photos/1957477/pexels-photo-1957477.jpeg?auto=compress&cs=tinysrgb&w=1171&h=750&dpr=1',
    description: 'Workspaces & studies',
    longDescription: 'Create a productive and inspiring home office space. Whether you have a dedicated room or a small nook, find ideas for functional and stylish workspaces.',
    projectCount: 654,
    popularTags: ['Productivity', 'Small Space', 'Ergonomic', 'Built-in', 'Multi-purpose', 'Storage'],
    featuredProjects: [
      {
        id: 'p16',
        title: 'Elegant Home Office',
        image: 'https://images.pexels.com/photos/1957478/pexels-photo-1957478.jpeg?auto=compress&cs=tinysrgb&w=1074&h=750&dpr=1',
        designer: 'Executive Interiors',
        likes: 245,
      },
      {
        id: 'p17',
        title: 'Compact Corner Office',
        image: 'https://images.pexels.com/photos/1957488/pexels-photo-1957488.jpeg?auto=compress&cs=tinysrgb&w=687&h=1024&dpr=1',
        designer: 'Small Space Solutions',
        likes: 198,
      },
      {
        id: 'p18',
        title: 'Creative Studio Workspace',
        image: 'https://images.pexels.com/photos/1957477/pexels-photo-1957477.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1',
        designer: 'Creative Spaces',
        likes: 176,
      },
    ],
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`room-tabpanel-${index}`}
      aria-labelledby={`room-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
const RoomDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { roomId } = useParams<{ roomId: string }>();
  const [tabValue, setTabValue] = useState(0);
  const [room, setRoom] = useState<any>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [projectToShare, setProjectToShare] = useState<any>(null);
  const { saveIdea, isModalOpen, closeModal, currentIdeaId, isLoading, error } = useSaveIdea();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  // New state for provider projects
  const [providerProjects, setProviderProjects] = useState<IdeaProject[]>([]);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [projectsError, setProjectsError] = useState<string | null>(null);

  useEffect(() => {
    // Fetch mock room data
    const foundRoom = rooms.find(r => r.id === roomId);
    if (foundRoom) {
      setRoom(foundRoom);
    } else {
      // Handle room not found
      navigate('/ideas/rooms');
    }

    // Fetch real provider projects for this room type
    const fetchProviderProjects = async () => {
      setLoadingProjects(true);
      setProjectsError(null);

      try {
        const projects = await ideaService.getProviderProjects({ roomType: roomId });
        setProviderProjects(projects);
      } catch (err) {
        console.error('Error fetching provider projects:', err);
        setProjectsError('Failed to load provider projects');
      } finally {
        setLoadingProjects(false);
      }
    };

    fetchProviderProjects();
  }, [roomId, navigate]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleShareProject = (project: any) => {
    setProjectToShare(project);
    setIsShareModalOpen(true);
  };

  const handleSaveToIdeabook = (ideabookId: string, projectId: string) => {
    // In a real app, this would be an API call
    console.log(`Saving project ${projectId} to ideabook ${ideabookId}`);
    closeModal();
  };

  const handleFilterToggle = (tag: string) => {
    setSelectedFilters(prev => {
      if (prev.includes(tag)) {
        return prev.filter(t => t !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  const filteredProjects = room?.featuredProjects.filter((project: any) => {
    // If no filters are selected, show all projects
    if (selectedFilters.length === 0) return true;

    // Check if the project matches any of the selected filters
    // In a real app, projects would have tags or attributes to filter by
    return selectedFilters.some(filter =>
      project.title.toLowerCase().includes(filter.toLowerCase())
    );
  });

  if (!room) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="rooms" />

      <Box sx={{ mb: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h1" gutterBottom>
              {room.name} Ideas
            </Typography>
            <Typography variant="body1" paragraph>
              {room.longDescription}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Browse {room.projectCount.toLocaleString()} {room.name.toLowerCase()} projects for inspiration
            </Typography>

            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
              <Button variant="contained" color="primary">
                Find a Professional
              </Button>
              <Button variant="outlined" startIcon={<BookmarkBorderIcon />}>
                Save
              </Button>
              <Button variant="outlined" startIcon={<ShareIcon />}>
                Share
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={room.image}
              alt={room.name}
              sx={{
                width: '100%',
                height: 300,
                objectFit: 'cover',
                borderRadius: 2,
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Popular {room.name} Styles
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap sx={{ mb: 4 }}>
          {room.popularTags.map((tag: string) => (
            <Chip
              key={tag}
              label={tag}
              clickable
              color={selectedFilters.includes(tag) ? "primary" : "default"}
              sx={{ m: 0.5 }}
              onClick={() => handleFilterToggle(tag)}
            />
          ))}
        </Stack>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="room content tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Featured Projects" />
            <Tab label="Photos" />
            <Tab label="Products" />
            <Tab label="Professionals" />
            <Tab label="Articles" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {/* Provider Projects Section */}
          {providerProjects.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 3 }}>
                Projects from Local Providers
              </Typography>
              <Grid container spacing={3} sx={{ mb: 6 }}>
                {providerProjects.map((project) => (
                  <Grid item xs={12} sm={6} md={4} key={project.id}>
                    <ProjectCard
                      id={project.id}
                      image={project.image}
                      title={project.title}
                      designer={project.designer}
                      likes={project.likes || 0}
                      onSave={() => saveIdea(project.id)}
                      onShare={() => handleShareProject(project)}
                      onClick={() => navigate(`/ideas/provider-projects/${project.id}`)}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {loadingProjects && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          )}

          {projectsError && (
            <Typography color="error" sx={{ my: 2 }}>
              {projectsError}
            </Typography>
          )}

          {/* Featured Projects Section (Mock Data) */}
          <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 3 }}>
            Featured Projects
          </Typography>
          <Grid container spacing={3}>
            {filteredProjects.map((project: any) => (
              <Grid item xs={12} sm={6} md={4} key={project.id}>
                <ProjectCard
                  id={project.id}
                  image={project.image}
                  title={project.title}
                  designer={project.designer}
                  likes={project.likes}
                  onSave={() => saveIdea(project.id)}
                  onShare={() => handleShareProject(project)}
                  onClick={() => navigate(`/ideas/projects/${project.id}`)}
                />
              </Grid>
            ))}
            {filteredProjects.length === 0 && (
              <Grid item xs={12}>
                <Typography variant="body1" align="center" sx={{ py: 4 }}>
                  No projects match your selected filters. Try adjusting your filters or browse all projects.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedFilters([])}
                  >
                    Clear Filters
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Typography variant="body1">
            Photo gallery coming soon...
          </Typography>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Typography variant="body1">
            Products for this room coming soon...
          </Typography>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Typography variant="body1">
            Professionals specializing in {room.name.toLowerCase()} design coming soon...
          </Typography>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Typography variant="body1">
            Articles about {room.name.toLowerCase()} design coming soon...
          </Typography>
        </TabPanel>
      </Box>

      {/* Share Modal */}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        itemToShare={projectToShare ? {
          id: projectToShare.id,
          title: projectToShare.title,
          image: projectToShare.image,
        } : null}
      />

      {/* Save to Ideabook Modal */}
      <SaveToIdeabookModal
        isOpen={isModalOpen}
        onClose={closeModal}
        projectToSave={currentIdeaId ? {
          id: currentIdeaId,
          title: room.featuredProjects.find((p: any) => p.id === currentIdeaId)?.title || '',
          image: room.featuredProjects.find((p: any) => p.id === currentIdeaId)?.image || '',
        } : null}
        onSave={handleSaveToIdeabook}
        isLoading={isLoading}
        error={error}
      />
    </Container>
  );
};

export default RoomDetailPage;