import React, { useState, useRef, ChangeEvent } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Alert,
  Grid,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadService from '../../services/uploadService';

interface FileUploadProps {
  /**
   * The type of upload (provider-logo, project-photos, product-images, document, avatar)
   */
  uploadType: 'provider-logo' | 'project-photos' | 'product-images' | 'document' | 'avatar';

  /**
   * The ID of the related entity (project, product, etc.)
   * Required for project-photos and product-images
   */
  entityId?: string;

  /**
   * Document type (for document uploads)
   */
  documentType?: string;

  /**
   * Whether to allow multiple file selection
   */
  multiple?: boolean;

  /**
   * Maximum number of files that can be uploaded
   */
  maxFiles?: number;

  /**
   * Accepted file types
   */
  accept?: string;

  /**
   * Callback function called when upload is complete
   */
  onUploadComplete?: (urls: string[]) => void;

  /**
   * Callback function called when an error occurs
   */
  onError?: (error: Error) => void;

  /**
   * Button label
   */
  buttonLabel?: string;

  /**
   * Whether to set the first image as primary (for product images)
   */
  setPrimaryImage?: boolean;
}

/**
 * A reusable file upload component that handles uploading files to GCP Cloud Storage
 */
const FileUpload: React.FC<FileUploadProps> = ({
  uploadType,
  entityId,
  documentType = 'general',
  multiple = false,
  maxFiles = 10,
  accept = 'image/*',
  onUploadComplete,
  onError,
  buttonLabel,
  setPrimaryImage = false,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const uploadService = UploadService.useUploadService();

  // Generate button label based on upload type if not provided
  const getButtonLabel = () => {
    if (buttonLabel) return buttonLabel;

    switch (uploadType) {
      case 'provider-logo':
        return 'Upload Logo';
      case 'project-photos':
        return 'Upload Project Photos';
      case 'product-images':
        return 'Upload Product Images';
      case 'document':
        return 'Upload Document';
      case 'avatar':
        return 'Upload Avatar';
      default:
        return 'Upload Files';
    }
  };

  // Handle file selection
  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const selectedFiles = Array.from(event.target.files);

    // Check if adding these files would exceed the maximum
    if (multiple && selectedFiles.length + files.length > maxFiles) {
      setError(`You can only upload a maximum of ${maxFiles} files`);
      return;
    }

    // Clear error if there was one
    setError(null);

    // If not multiple, replace existing files
    const newFiles = multiple ? [...files, ...selectedFiles] : selectedFiles;
    setFiles(newFiles);

    // Generate previews for images
    const newPreviews = newFiles.map(file => {
      if (file.type.startsWith('image/')) {
        return URL.createObjectURL(file);
      }
      // For non-image files, return a placeholder or icon representation
      return file.type.includes('pdf')
        ? '/assets/pdf-icon.png'
        : '/assets/file-icon.png';
    });

    setPreviews(newPreviews);
  };

  // Remove a file
  const removeFile = (index: number) => {
    const newFiles = [...files];
    const newPreviews = [...previews];

    // Revoke the object URL to avoid memory leaks
    if (newPreviews[index] && newPreviews[index].startsWith('blob:')) {
      URL.revokeObjectURL(newPreviews[index]);
    }

    newFiles.splice(index, 1);
    newPreviews.splice(index, 1);

    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  // Handle upload
  const handleUpload = async () => {
    if (files.length === 0) {
      setError('Please select files to upload');
      return;
    }

    // Validate required props based on upload type
    if ((uploadType === 'project-photos' || uploadType === 'product-images') && !entityId) {
      setError(`entityId is required for ${uploadType}`);
      return;
    }

    if (uploadType === 'document' && !documentType) {
      setError('documentType is required for document uploads');
      return;
    }

    setUploading(true);
    setUploadProgress(0);
    setError(null);

    try {
      let urls: string[] = [];

      // Simulate progress updates
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => {
          const newProgress = prev + 5;
          return newProgress > 90 ? 90 : newProgress;
        });
      }, 300);

      // Perform the upload based on the upload type
      switch (uploadType) {
        case 'provider-logo': {
          const logoUrl = await uploadService.uploadProviderLogo(files[0]);
          urls = [logoUrl];
          break;
        }

        case 'project-photos':
          // Check if this is a temporary project (for preview before creating the project)
          if (entityId && entityId.startsWith('temp-')) {
            urls = await uploadService.uploadTempProjectPhotos(files);
          } else if (entityId) {
            urls = await uploadService.uploadProjectPhotos(entityId, files);
          }
          break;

        case 'product-images':
          if (entityId) {
            urls = await uploadService.uploadProductImages(entityId, files, setPrimaryImage);
          }
          break;

        case 'document': {
          const docUrl = await uploadService.uploadDocument(files[0], documentType);
          urls = [docUrl];
          break;
        }

        case 'avatar': {
          const avatarUrl = await uploadService.uploadAvatar(files[0]);
          urls = [avatarUrl];
          break;
        }
      }

      // Clear the progress interval
      clearInterval(progressInterval);
      setUploadProgress(100);

      // Store the uploaded URLs
      setUploadedUrls(urls);

      // Call the onUploadComplete callback
      if (onUploadComplete) {
        onUploadComplete(urls);
      }

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

    } catch (err) {
      console.error('Upload error:', err);
      setError(err instanceof Error ? err.message : 'An error occurred during upload');

      if (onError && err instanceof Error) {
        onError(err);
      }
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* File Input */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
        accept={accept}
        multiple={multiple}
      />

      {/* Upload Button */}
      <Button
        variant="contained"
        startIcon={<CloudUploadIcon />}
        onClick={() => fileInputRef.current?.click()}
        disabled={uploading}
        fullWidth
        sx={{ mb: 2 }}
      >
        {getButtonLabel()}
      </Button>

      {/* Error Message */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* File Previews */}
      {previews.length > 0 && (
        <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Selected Files ({files.length})
          </Typography>

          <Grid container spacing={2}>
            {previews.map((preview, index) => (
              <Grid item xs={6} sm={4} md={3} key={index}>
                <Paper
                  elevation={1}
                  sx={{
                    p: 1,
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {/* Preview Image */}
                  <Box
                    sx={{
                      height: 120,
                      backgroundImage: `url(${preview})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      mb: 1,
                      borderRadius: 1,
                    }}
                  />

                  {/* File Name */}
                  <Typography variant="caption" noWrap>
                    {files[index].name}
                  </Typography>

                  {/* Delete Button */}
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => removeFile(index)}
                    sx={{
                      position: 'absolute',
                      top: 4,
                      right: 4,
                      bgcolor: 'rgba(255,255,255,0.7)',
                      '&:hover': {
                        bgcolor: 'rgba(255,255,255,0.9)',
                      },
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {/* Upload Progress */}
      {uploading && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CircularProgress size={24} sx={{ mr: 1 }} />
          <Typography variant="body2">
            Uploading... {uploadProgress}%
          </Typography>
        </Box>
      )}

      {/* Upload Success */}
      {uploadedUrls.length > 0 && !uploading && (
        <Alert severity="success" sx={{ mb: 2 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <CheckCircleIcon color="success" />
            <Typography>
              {uploadedUrls.length} {uploadedUrls.length === 1 ? 'file' : 'files'} uploaded successfully
            </Typography>
          </Stack>
        </Alert>
      )}

      {/* Upload Button */}
      {files.length > 0 && !uploading && uploadedUrls.length === 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={uploading}
          sx={{ mt: 1 }}
        >
          Upload {files.length} {files.length === 1 ? 'File' : 'Files'}
        </Button>
      )}
    </Box>
  );
};

export default FileUpload;