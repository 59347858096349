/**
 * Pexels API Service
 * Documentation: https://www.pexels.com/api/documentation/
 */

import { PEXELS_CONFIG } from '../config';

// Types
export interface PexelsPhoto {
  id: number;
  width: number;
  height: number;
  url: string;
  photographer: string;
  photographer_url: string;
  photographer_id: number;
  avg_color: string;
  src: {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
  };
  liked: boolean;
  alt: string;
}

export interface PexelsVideo {
  id: number;
  width: number;
  height: number;
  url: string;
  image: string;
  duration: number;
  user: {
    id: number;
    name: string;
    url: string;
  };
  video_files: {
    id: number;
    quality: string;
    file_type: string;
    width: number;
    height: number;
    link: string;
  }[];
  video_pictures: {
    id: number;
    picture: string;
    nr: number;
  }[];
}

export interface PexelsSearchResult<T> {
  total_results: number;
  page: number;
  per_page: number;
  photos?: T[];
  videos?: T[];
  next_page?: string;
  prev_page?: string;
}

// Cache for API responses
const cache = new Map<string, { data: any; timestamp: number }>();
const CACHE_DURATION = 1000 * 60 * 60; // 1 hour

/**
 * Base Pexels API client
 */
class PexelsClient {
  private apiKey: string;
  private baseUrl: string;
  private useProxy: boolean;

  constructor() {
    this.apiKey = PEXELS_CONFIG.API_KEY || '';
    this.baseUrl = 'https://api.pexels.com/v1';
    this.useProxy = true; // Always use proxy to avoid CORS issues

    if (!this.apiKey && !this.useProxy) {
      console.warn('Pexels API key is not set. Please add VITE_PEXELS_API_KEY to your environment variables.');
    }
  }

  /**
   * Make a request to the Pexels API
   */
  private async request<T>(endpoint: string, params: Record<string, string> = {}): Promise<T> {
    const queryParams = new URLSearchParams(params).toString();

    // Use proxy in production, direct API in development
    let url: string;
    let headers: Record<string, string> = {};

    if (this.useProxy) {
      // Use our backend proxy
      url = `/api/pexels${endpoint}${queryParams ? `?${queryParams}` : ''}`;
    } else {
      // Direct API call (for development)
      url = `${this.baseUrl}${endpoint}${queryParams ? `?${queryParams}` : ''}`;
      headers = {
        Authorization: `${this.apiKey}`,
      };
    }

    // Check cache first
    const cacheKey = url;
    const cachedResponse = cache.get(cacheKey);

    if (cachedResponse && Date.now() - cachedResponse.timestamp < CACHE_DURATION) {
      return cachedResponse.data as T;
    }

    try {
      const response = await fetch(url, {
        headers,
      });

      if (!response.ok) {
        throw new Error(`Pexels API error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      // Cache the response
      cache.set(cacheKey, { data, timestamp: Date.now() });

      return data as T;
    } catch (error) {
      console.error('Error fetching from Pexels API:', error);
      throw error;
    }
  }

  /**
   * Search for photos
   */
  async searchPhotos(
    query: string,
    options: {
      page?: number;
      per_page?: number;
      orientation?: 'landscape' | 'portrait' | 'square';
      size?: 'large' | 'medium' | 'small';
      color?: string;
      locale?: string;
    } = {}
  ): Promise<PexelsSearchResult<PexelsPhoto>> {
    const params: Record<string, string> = {
      query,
      ...Object.fromEntries(
        Object.entries(options).map(([key, value]) => [key, String(value)])
      ),
    };

    return this.request<PexelsSearchResult<PexelsPhoto>>('/search', params);
  }

  /**
   * Get curated photos
   */
  async getCuratedPhotos(
    options: {
      page?: number;
      per_page?: number;
    } = {}
  ): Promise<PexelsSearchResult<PexelsPhoto>> {
    const params: Record<string, string> = {
      ...Object.fromEntries(
        Object.entries(options).map(([key, value]) => [key, String(value)])
      ),
    };

    return this.request<PexelsSearchResult<PexelsPhoto>>('/curated', params);
  }

  /**
   * Get a specific photo by ID
   */
  async getPhoto(id: number): Promise<PexelsPhoto> {
    return this.request<PexelsPhoto>(`/photos/${id}`);
  }

  /**
   * Search for videos
   */
  async searchVideos(
    query: string,
    options: {
      page?: number;
      per_page?: number;
      orientation?: 'landscape' | 'portrait' | 'square';
      size?: 'large' | 'medium' | 'small';
    } = {}
  ): Promise<PexelsSearchResult<PexelsVideo>> {
    const params: Record<string, string> = {
      query,
      ...Object.fromEntries(
        Object.entries(options).map(([key, value]) => [key, String(value)])
      ),
    };

    return this.request<PexelsSearchResult<PexelsVideo>>('/videos/search', params);
  }

  /**
   * Get popular videos
   */
  async getPopularVideos(
    options: {
      page?: number;
      per_page?: number;
      min_width?: number;
      min_height?: number;
      min_duration?: number;
      max_duration?: number;
    } = {}
  ): Promise<PexelsSearchResult<PexelsVideo>> {
    const params: Record<string, string> = {
      ...Object.fromEntries(
        Object.entries(options).map(([key, value]) => [key, String(value)])
      ),
    };

    return this.request<PexelsSearchResult<PexelsVideo>>('/videos/popular', params);
  }

  /**
   * Get a specific video by ID
   */
  async getVideo(id: number): Promise<PexelsVideo> {
    return this.request<PexelsVideo>(`/videos/${id}`);
  }

  /**
   * Clear the cache
   */
  clearCache(): void {
    cache.clear();
  }
}

// Create a singleton instance
const pexelsClient = new PexelsClient();

export default pexelsClient;