import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Paper } from '@mui/material';
import ServiceForm from './ServiceForm';
import ServiceCapabilitiesForm from './ServiceCapabilitiesForm';
import axios from 'axios';
import { env } from '../../config';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface ProviderCapability {
  questionId: string;
  supportedOptions: string[];
}

interface ServiceWithCapabilitiesFormProps {
  initialValues?: any;
  categories: any[];
  onComplete: (serviceData: any, capabilities: ProviderCapability[]) => Promise<void>;
  onCancel?: () => void;
}

const ServiceWithCapabilitiesForm: React.FC<ServiceWithCapabilitiesFormProps> = ({
  initialValues,
  categories,
  onComplete,
  onCancel,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [serviceData, setServiceData] = useState<any>(null);
  const [serviceId, setServiceId] = useState<string | null>(initialValues?._id || null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [existingCapabilities, setExistingCapabilities] = useState<ProviderCapability[]>([]);
  const [authChecked, setAuthChecked] = useState(false);

  // Get authentication state from Redux
  const { isAuthenticated, token, user } = useSelector((state: RootState) => state.auth);

  // Check authentication on component mount
  useEffect(() => {
    const checkAuth = () => {
      if (!isAuthenticated || !token) {
        setError('You must be logged in to create a service');
      } else if (user?.role !== 'provider') {
        setError('Only service providers can create services');
      } else {
        // Ensure the token is in localStorage
        if (token && !localStorage.getItem('token')) {
          localStorage.setItem('token', token);
        }
      }
      setAuthChecked(true);
    };

    checkAuth();
  }, [isAuthenticated, token, user]);

  const steps = ['Service Details', 'Service Capabilities'];

  const handleServiceSubmit = async (values: any) => {
    setLoading(true);

    // Check if user is authenticated
    if (!isAuthenticated || !token) {
      setError('You must be logged in to create a service');
      setLoading(false);
      return;
    }

    try {
      // If we're editing an existing service, we already have the ID
      if (serviceId) {
        setServiceData(values);
        // Fetch existing capabilities if we haven't already
        if (existingCapabilities.length === 0) {
          try {
            const response = await axios.get(
              `${env.API_URL}/target-preferences/provider/capabilities/service/${serviceId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.data && response.data.capabilities) {
              setExistingCapabilities(response.data.capabilities);
            }
          } catch (err) {
            console.error('Error fetching existing capabilities:', err);
            // Continue even if we couldn't fetch capabilities
          }
        }
        setActiveStep(1);
      } else {
        // For a new service, we need to save it first to get the real ID

        // Add default values for required fields
        const serviceData = {
          ...values,
          // Add any other required fields here
        };

        setServiceData(serviceData);

        try {
          // Create the service in the database and get the real ID

          // Add default values for required fields
          const serviceData = {
            ...values,
            // Add any other required fields here
          };

          const response = await axios.post(`${env.API_URL}/services`, serviceData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.data && response.data._id) {
            // Set the real service ID returned from the API
            const newServiceId = response.data._id;
            setServiceId(newServiceId);
            setActiveStep(1);
          } else {
            throw new Error('Service created but no ID was returned');
          }
        } catch (apiErr: any) {
          console.error('Error creating service:', apiErr);
          if (apiErr.response) {
            console.error('Error response:', apiErr.response.status, apiErr.response.data);
            setError(
              `Failed to create service: ${
                apiErr.response.data?.message || apiErr.response.statusText
              }`
            );
          } else {
            setError('Failed to create service. Please try again.');
          }
        }
      }
    } catch (err) {
      console.error('Error in service submission process:', err);
      setError('Failed to process service. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCapabilitiesSubmit = async (capabilities: ProviderCapability[]) => {
    setLoading(true);

    // Check if user is authenticated
    if (!isAuthenticated || !token) {
      setError('You must be logged in to update service capabilities');
      setLoading(false);
      return;
    }

    try {
      // For new services, we need to pass the service object with the ID
      if (serviceId && serviceData) {
        // Make sure we include the ID in the service data
        const serviceWithId = {
          ...serviceData,
          _id: serviceId,
        };

        await onComplete(serviceWithId, capabilities);
      } else {
        throw new Error('Missing service data or ID');
      }
    } catch (err: any) {
      if (err.response) {
        setError(
          `Failed to save capabilities: ${err.response.data?.message || err.response.statusText}`
        );
      } else {
        setError('Failed to save capabilities. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {error && (
        <Paper sx={{ p: 2, mb: 3, bgcolor: 'error.light', color: 'error.contrastText' }}>
          <Typography>{error}</Typography>
        </Paper>
      )}

      {activeStep === 0 && (
        <ServiceForm
          initialValues={initialValues}
          categories={categories}
          onSubmit={handleServiceSubmit}
        />
      )}

      {activeStep === 1 && serviceId && (
        <Box>
          <ServiceCapabilitiesForm
            serviceId={serviceId}
            onSubmit={handleCapabilitiesSubmit}
            onCancel={handleBack}
            existingCapabilities={existingCapabilities}
          />
        </Box>
      )}

      {activeStep === 0 && onCancel && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ServiceWithCapabilitiesForm;
