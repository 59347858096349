import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
  Rating,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Skeleton,
  SelectChangeEvent,
  Avatar,
  useTheme,
  useMediaQuery,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import axiosInstance from '../../services/axios';

interface Professional {
  id: string;
  name: string;
  businessName: string;
  profileImage: string;
  rating: number;
  reviewCount: number;
  distance: number;
  topServices: string[];
  shortDescription: string;
  verified: boolean;
  location?: string; // Optional location string
}

interface NearbyProfessionalsProps {
  initialLocation?: string;
}

const NearbyProfessionals: React.FC<NearbyProfessionalsProps> = ({ initialLocation }) => {
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [serviceType, setServiceType] = useState('all');
  const [location, setLocation] = useState(initialLocation || 'Your Area');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get rating text based on rating value
  const getRatingText = (rating: number) => {
    if (rating >= 4.5) return 'Excellent';
    if (rating >= 4.0) return 'Great';
    if (rating >= 3.5) return 'Good';
    if (rating >= 3.0) return 'Average';
    return 'Fair';
  };

  // Generate location-based description for professionals
  const getLocationBasedDescription = (pro: Professional) => {
    // Map business names to specific locations based on the screenshot
    const locationMap: Record<string, string> = {
      'Johnson Home Services': 'Abu Dhabi',
      'Qassemi Design Studio': 'Ras Al Khaimah',
      'Marzooqi Construction': 'Fujairah',
      'Suwaidi Interior Design': 'Dubai',
      'Al Mansouri Home Services': 'Sharjah',
      'Dhaheri Maintenance': 'Ajman',
    };

    // Get location based on business name or fallback to a default
    const location = pro.location || locationMap[pro.businessName] || 'your area';

    return `${
      pro.businessName || pro.name
    } provides high-quality services in ${location}. We specialize in home improvement and maintenance services.`;
  };

  // Fetch professionals from the API
  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        setLoading(true);
        setError(null);

        // Add service type filter if not 'all'
        const params = serviceType !== 'all' ? { serviceType } : {};

        const response = await axiosInstance.get('/pro/nearby', { params });
        setProfessionals(response.data);
      } catch (err) {
        console.error('Error fetching professionals:', err);
        setError('Failed to load professionals. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfessionals();
  }, [serviceType]);

  const handleServiceChange = (event: SelectChangeEvent) => {
    setServiceType(event.target.value);
  };

  // In a real app, this would use the browser's geolocation API
  useEffect(() => {
    if (navigator.geolocation && !initialLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Here you would make an API call to get the location name based on coordinates
          // For now, we'll just set a placeholder
          setLocation('Your Current Location');
        },
        () => {
          // Fallback if geolocation fails
          setLocation('Your Area');
        }
      );
    }
  }, [initialLocation]);

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
          }}
        >
          <Typography variant="h3" component="h2" gutterBottom={isMobile}>
            Top Professionals Near {location}
          </Typography>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="service-type-label">Service Type</InputLabel>
            <Select
              labelId="service-type-label"
              id="service-type"
              value={serviceType}
              label="Service Type"
              onChange={handleServiceChange}
            >
              <MenuItem value="all">All Services</MenuItem>
              <MenuItem value="kitchen">Kitchen</MenuItem>
              <MenuItem value="bathroom">Bathroom</MenuItem>
              <MenuItem value="plumbing">Plumbing</MenuItem>
              <MenuItem value="electrical">Electrical</MenuItem>
              <MenuItem value="painting">Painting</MenuItem>
              <MenuItem value="landscape">Landscaping</MenuItem>
              <MenuItem value="cleaning">Cleaning</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={6}>
          {loading ? (
            // Skeleton loading state
            Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`} sx={{ mb: 4 }}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Skeleton variant="rectangular" height={200} />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Skeleton variant="text" height={32} width="60%" />
                    <Skeleton variant="text" height={24} width="40%" />
                    <Skeleton variant="text" height={24} width="30%" />
                    <Box sx={{ my: 1 }}>
                      <Skeleton variant="rectangular" height={32} width="80%" />
                    </Box>
                    <Skeleton variant="text" height={80} />
                  </CardContent>
                  <Box sx={{ p: 2, pt: 0 }}>
                    <Skeleton variant="rectangular" height={36} />
                  </Box>
                </Card>
              </Grid>
            ))
          ) : professionals.length > 0 ? (
            professionals.map((pro) => (
              <Grid item xs={12} sm={6} md={4} key={pro.id} sx={{ mb: 4 }}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    transition: 'box-shadow 0.2s',
                    '&:hover': {
                      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
                    },
                    overflow: 'hidden',
                    pt: { xs: 3, sm: 4 },
                    px: { xs: 2, sm: 3 },
                    pb: { xs: 2, sm: 3 },
                    position: 'relative',
                    maxHeight: '450px',
                  }}
                >
                  {/* Verified badge at top right */}
                  {pro.verified && (
                    <Chip
                      label="Verified"
                      color="primary"
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 12,
                        right: 12,
                        backgroundColor: theme.palette.success.main,
                        color: '#fff',
                        fontWeight: 600,
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                        zIndex: 0,
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.7rem',
                        },
                      }}
                    />
                  )}

                  {/* Header: Avatar, Name, Rating */}
                  <Box sx={{ display: 'flex', mb: 1.5 }}>
                    <Box sx={{ mr: 2 }}>
                      <Avatar
                        src={
                          pro.profileImage ||
                          `https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=300&h=300&dpr=1`
                        }
                        alt={pro.businessName || pro.name}
                        sx={{
                          width: 80,
                          height: 80,
                          border: '3px solid white',
                          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.12)',
                          borderRadius: '50%',
                          backgroundColor: '#f5f5f5',
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        component="h2"
                        sx={{
                          fontWeight: 700,
                          color: theme.palette.text.primary,
                          mb: 0.75,
                          lineHeight: 1.2,
                        }}
                      >
                        {pro.businessName || pro.name}
                      </Typography>

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: '#4CAF50',
                            fontWeight: 600,
                            mr: 1,
                          }}
                        >
                          {getRatingText(pro.rating)} {pro.rating.toFixed(1)}
                        </Typography>
                        <Rating
                          value={pro.rating}
                          precision={0.5}
                          readOnly
                          size="small"
                          sx={{
                            ml: 0.5,
                            '& .MuiRating-iconFilled': {
                              color: theme.palette.warning.main,
                            },
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            ml: 0.5,
                            color: theme.palette.text.secondary,
                          }}
                        >
                          ({pro.reviewCount})
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* Info section */}
                  <Box sx={{ mb: 1.5 }}>
                    {/* Distance */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <LocationOnIcon
                        fontSize="small"
                        sx={{
                          color: theme.palette.text.secondary,
                          mr: 1,
                          fontSize: '1rem',
                        }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {pro.distance} miles away
                      </Typography>
                    </Box>

                    {/* Response time */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        component="span"
                        sx={{
                          mr: 1,
                          color: theme.palette.text.secondary,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 20,
                        }}
                      >
                        💬
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        Responds within <strong>a day</strong>
                      </Typography>
                    </Box>
                  </Box>

                  {/* Services section */}
                  <Box sx={{ mb: 1.5 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 600,
                        mb: 0.5,
                      }}
                    >
                      Services Offered:
                    </Typography>
                    <Box>
                      {pro.topServices && pro.topServices.length > 0 ? (
                        pro.topServices.map((service, index) => (
                          <Chip
                            key={`${service}-${index}`}
                            label={service}
                            size="small"
                            sx={{
                              mr: 0.5,
                              mb: 0.5,
                              borderRadius: '16px',
                              backgroundColor: theme.palette.primary.light,
                              color: theme.palette.primary.contrastText,
                              fontWeight: 500,
                              '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                              },
                            }}
                            onClick={() => navigate(`/find-professionals?service=${service}`)}
                          />
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontStyle: 'italic' }}
                        >
                          No services listed
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {/* Description */}
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                    {pro.shortDescription || getLocationBasedDescription(pro)}
                  </Typography>

                  {/* Action buttons */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 'auto',
                      pt: 0.5,
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: '8px',
                        py: 0.75,
                        px: { xs: 1.5, sm: 2 },
                        fontWeight: 600,
                        borderWidth: '1.5px',
                        flex: 1,
                        mr: 1,
                        fontSize: { xs: '0.8rem', sm: '0.875rem' },
                        '&:hover': {
                          borderWidth: '1.5px',
                          backgroundColor: 'rgba(33, 150, 243, 0.08)',
                        },
                      }}
                      onClick={() => navigate(`/pro/${pro.id}`)}
                    >
                      View Profile
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        borderRadius: '8px',
                        py: 0.75,
                        px: { xs: 1.5, sm: 2 },
                        fontWeight: 600,
                        flex: 1,
                        fontSize: { xs: '0.8rem', sm: '0.875rem' },
                        boxShadow: '0 2px 8px rgba(33, 150, 243, 0.25)',
                        '&:hover': {
                          boxShadow: '0 4px 12px rgba(33, 150, 243, 0.35)',
                        },
                      }}
                      onClick={() => navigate(`/services/enhanced-lead?providerId=${pro.id}`)}
                    >
                      Send Message
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Alert severity="info">
                No professionals found. Try adjusting your filters or try again later.
              </Alert>
            </Grid>
          )}
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="outlined" size="large" onClick={() => navigate('/find-professionals')}>
            View All Nearby Professionals
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NearbyProfessionals;
