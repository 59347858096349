import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IMessage, IConversation, CreateMessageDTO } from '../../types/models';
import axiosInstance from '../../services/axios';

interface MessageState {
  conversations: IConversation[];
  messages: Record<string, IMessage[]>;
  loading: boolean;
  error: string | null;
}

const initialState: MessageState = {
  conversations: [],
  messages: {},
  loading: false,
  error: null,
};

export const getConversations = createAsyncThunk(
  'messages/getConversations',
  async () => {
    const response = await axiosInstance.get('/messages/conversations');
    return response.data;
  }
);

export const getMessages = createAsyncThunk(
  'messages/getMessages',
  async (conversationId: string) => {
    const response = await axiosInstance.get(`/messages/conversations/${conversationId}/messages`);
    return { conversationId, messages: response.data };
  }
);

export const sendMessage = createAsyncThunk(
  'messages/sendMessage',
  async ({ conversationId, data }: { conversationId: string; data: CreateMessageDTO }) => {
    const response = await axiosInstance.post(`/messages/conversations/${conversationId}/messages`, data);
    return response.data;
  }
);

export const markAsRead = createAsyncThunk(
  'messages/markAsRead',
  async (conversationId: string) => {
    await axiosInstance.put(`/messages/conversations/${conversationId}/read`);
    return conversationId;
  }
);

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    messageReceived: (state, action: PayloadAction<IMessage>) => {
      const { conversation: conversationId } = action.payload;
      if (!state.messages[conversationId]) {
        state.messages[conversationId] = [];
      }
      state.messages[conversationId].push(action.payload);
    },
    conversationUpdated: (state, action: PayloadAction<IConversation>) => {
      const index = state.conversations.findIndex(c => c._id === action.payload._id);
      if (index !== -1) {
        state.conversations[index] = action.payload;
      } else {
        state.conversations.push(action.payload);
      }
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Conversations
      .addCase(getConversations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.loading = false;
        state.conversations = action.payload;
      })
      .addCase(getConversations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch conversations';
      })
      // Get Messages
      .addCase(getMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages[action.payload.conversationId] = action.payload.messages;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch messages';
      })
      // Send Message
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        const { conversation: conversationId } = action.payload;
        if (!state.messages[conversationId]) {
          state.messages[conversationId] = [];
        }
        state.messages[conversationId].push(action.payload);
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to send message';
      });
  },
});

export const { messageReceived, conversationUpdated, clearError } = messageSlice.actions;
export default messageSlice.reducer;