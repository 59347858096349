import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Divider,
  IconButton,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Facebook, Twitter, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import Logo from '../Logo';

const MainFooter: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: theme.palette.mode === 'light' ? 'grey.100' : 'grey.900',
        pt: 8,
        pb: 6,
        mt: 'auto',
        borderTop: '1px solid',
        borderColor: 'divider',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {/* Company Info Section */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'flex-start' },
                mb: { xs: 4, md: 0 },
              }}
            >
              <Logo showTagline size="medium" />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 2,
                  mb: 3,
                  maxWidth: '300px',
                  lineHeight: 1.6,
                }}
              >
                Your one-stop solution for all home improvement needs.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                }}
              >
                <IconButton
                  aria-label="facebook"
                  size="small"
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.light',
                      color: 'white',
                    },
                    transition: 'all 0.2s',
                  }}
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  aria-label="twitter"
                  size="small"
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.light',
                      color: 'white',
                    },
                    transition: 'all 0.2s',
                  }}
                >
                  <Twitter />
                </IconButton>
                <IconButton
                  aria-label="instagram"
                  href="https://www.instagram.com/homezyco/"
                  target="_blank"
                  size="small"
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.light',
                      color: 'white',
                    },
                    transition: 'all 0.2s',
                  }}
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  aria-label="linkedin"
                  href="https://www.linkedin.com/company/homezyco"
                  target="_blank"
                  size="small"
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.light',
                      color: 'white',
                    },
                    transition: 'all 0.2s',
                  }}
                >
                  <LinkedIn />
                </IconButton>
                <IconButton
                  aria-label="youtube"
                  size="small"
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.light',
                      color: 'white',
                    },
                    transition: 'all 0.2s',
                  }}
                >
                  <YouTube />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={6} sm={3} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: '40px',
                  height: '3px',
                  bgcolor: 'primary.main',
                },
              }}
            >
              Quick Links
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Home
              </Link>
              <Link
                component={RouterLink}
                to="/search"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Find Services
              </Link>
              <Link
                component={RouterLink}
                to="/marketplace"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Marketplace
              </Link>
              <Link
                component={RouterLink}
                to="/ideas/ideabooks"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Ideas & Inspiration
              </Link>
            </Box>
          </Grid>

          {/* Services Section */}
          <Grid item xs={6} sm={3} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: '40px',
                  height: '3px',
                  bgcolor: 'primary.main',
                },
              }}
            >
              Services
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Link
                component={RouterLink}
                to="/search?category=cleaning"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Cleaning
              </Link>
              <Link
                component={RouterLink}
                to="/search?category=plumbing"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Plumbing
              </Link>
              <Link
                component={RouterLink}
                to="/search?category=electrical"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Electrical
              </Link>
              <Link
                component={RouterLink}
                to="/search?category=renovation"
                color="inherit"
                display="block"
                sx={{
                  mb: 1.5,
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    pl: 0.5,
                    transition: 'all 0.2s',
                  },
                }}
              >
                Renovation
              </Link>
            </Box>
          </Grid>

          {/* Contact Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: '40px',
                  height: '3px',
                  bgcolor: 'primary.main',
                },
              }}
            >
              Contact
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 3,
                '&:hover': {
                  color: 'primary.main',
                  transition: 'all 0.2s',
                },
              }}
            >
              <Typography variant="body1">support@homezy.com</Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 6, mb: 4 }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Homezy. All rights reserved.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              mt: { xs: 3, sm: 0 },
              gap: 2,
            }}
          >
            <Link
              href="#"
              color="inherit"
              sx={{
                '&:hover': {
                  color: 'primary.main',
                  textDecoration: 'none',
                },
              }}
            >
              <Typography variant="body2">Privacy Policy</Typography>
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                '&:hover': {
                  color: 'primary.main',
                  textDecoration: 'none',
                },
              }}
            >
              <Typography variant="body2">Terms of Service</Typography>
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                '&:hover': {
                  color: 'primary.main',
                  textDecoration: 'none',
                },
              }}
            >
              <Typography variant="body2">Cookie Policy</Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MainFooter;
