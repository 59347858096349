import React, { ReactNode } from 'react';
import { Box, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './Header';
import Sidebar from './Sidebar';
import { MainFooter, FlatFooter } from './footer';
import { RootState } from '../../types/store';

interface LayoutProps {
  children: ReactNode;
  showSidebar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, showSidebar = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);

  // Determine if we should show the flat footer (for authenticated/dashboard pages)
  const isAuthenticatedPage = () => {
    const path = location.pathname;
    return path.includes('/dashboard') ||
           path.includes('/pro/') ||
           path.includes('/marketplace/seller') ||
           path.includes('/services/') ||
           (path.includes('/ideas/') && user);
  };

  // Determine active section based on current path
  const getActiveSection = () => {
    const path = location.pathname;
    if (path.includes('/ideas/ideabooks')) return 'ideabook';
    if (path.includes('/ideas/products')) return 'product-list';
    if (path.includes('/ideas/find-pro')) return 'pro-finder';
    if (path.includes('/ideas/calculator')) return 'calculator';
    if (path.includes('/ideas/checklist')) return 'checklist';
    return 'ideabook'; // default section
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      {/* Toolbar component here creates space equal to header height */}
      <Toolbar />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {showSidebar && !isMobile && user && (
          <Sidebar activeSection={getActiveSection()} user={user} />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3 },
            backgroundColor: (theme) => theme.palette.grey[50],
            marginLeft: showSidebar && !isMobile ? '240px' : 0, // Account for sidebar width
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
      {isAuthenticatedPage() ? <FlatFooter /> : <MainFooter />}
    </Box>
  );
};

export default Layout;
