import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  Checkbox,
  Divider,
  Alert,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Kitchen as KitchenIcon,
  Print as PrintIcon,
  Share as ShareIcon,
  Save as SaveIcon,
  NavigateNext as NextIcon,
  NavigateBefore as BackIcon,
} from '@mui/icons-material';
import PageTitle from '../../../components/common/PageTitle';

// Define the steps for the estimator
const steps = ['Basic Information', 'Layout & Size', 'Materials & Finishes', 'Appliances', 'Results'];

// Kitchen size options
const kitchenSizes = [
  { value: 'small', label: 'Small (Less than 10 sqm)', multiplier: 1 },
  { value: 'medium', label: 'Medium (10-15 sqm)', multiplier: 1.5 },
  { value: 'large', label: 'Large (15-25 sqm)', multiplier: 2 },
  { value: 'xlarge', label: 'Extra Large (25+ sqm)', multiplier: 2.5 },
];

// Layout complexity options
const layoutComplexity = [
  { value: 'simple', label: 'Simple (I-shaped or L-shaped)', multiplier: 1 },
  { value: 'moderate', label: 'Moderate (U-shaped)', multiplier: 1.3 },
  { value: 'complex', label: 'Complex (Island or Peninsula)', multiplier: 1.6 },
];

// Cabinet quality options
const cabinetQuality = [
  { value: 'basic', label: 'Basic (Laminate)', pricePerUnit: 800 },
  { value: 'mid', label: 'Mid-range (Veneer)', pricePerUnit: 1200 },
  { value: 'high', label: 'High-end (Solid Wood)', pricePerUnit: 2000 },
];

// Countertop options
const countertopOptions = [
  { value: 'laminate', label: 'Laminate', pricePerSqm: 300 },
  { value: 'solid_surface', label: 'Solid Surface', pricePerSqm: 700 },
  { value: 'granite', label: 'Granite', pricePerSqm: 1000 },
  { value: 'quartz', label: 'Quartz', pricePerSqm: 1200 },
];

// Appliance package options
const appliancePackages = [
  { value: 'basic', label: 'Basic Package', price: 5000 },
  { value: 'mid', label: 'Mid-range Package', price: 12000 },
  { value: 'high', label: 'High-end Package', price: 25000 },
];

// Additional features
const additionalFeatures = [
  { id: 'island', label: 'Kitchen Island', price: 5000 },
  { id: 'lighting', label: 'Premium Lighting Package', price: 3000 },
  { id: 'plumbing', label: 'Plumbing Relocation', price: 4000 },
  { id: 'electrical', label: 'Electrical Upgrades', price: 3500 },
];

// Emirate price adjustment factors
const emirateFactors = {
  'dubai': 1.1,
  'abu_dhabi': 1.15,
  'sharjah': 0.95,
  'ajman': 0.9,
  'ras_al_khaimah': 0.85,
};

/**
 * KitchenEstimator component provides a step-by-step calculator for estimating
 * kitchen renovation costs in the UAE
 */
const KitchenEstimator: React.FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  // Form state
  const [formData, setFormData] = useState({
    renovationType: 'full',
    propertyType: 'apartment',
    kitchenSize: 'medium',
    layoutComplexity: 'moderate',
    customSqm: 12,
    cabinetQuality: 'mid',
    countertopMaterial: 'solid_surface',
    appliancePackage: 'mid',
    additionalFeatures: [] as string[],
    emirate: 'dubai',
  });

  // Results state
  const [results, setResults] = useState({
    cabinetsPrice: 0,
    countertopsPrice: 0,
    appliancesPrice: 0,
    additionalFeaturesPrice: 0,
    subtotal: 0,
    laborCost: 0,
    designFees: 0,
    permitFees: 0,
    totalCost: 0,
    lowEstimate: 0,
    highEstimate: 0,
  });

  // Calculate results whenever form data changes
  useEffect(() => {
    if (activeStep === steps.length - 1) {
      calculateResults();
    }
  }, [activeStep, formData]);

  // Handle form field changes
  const handleChange = (field: string, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  // Handle checkbox changes for additional features
  const handleFeatureChange = (featureId: string, checked: boolean) => {
    const currentFeatures = [...formData.additionalFeatures];

    if (checked) {
      currentFeatures.push(featureId);
    } else {
      const index = currentFeatures.indexOf(featureId);
      if (index > -1) {
        currentFeatures.splice(index, 1);
      }
    }

    setFormData({
      ...formData,
      additionalFeatures: currentFeatures,
    });
  };

  // Calculate the renovation cost based on form inputs
  const calculateResults = () => {
    // Get size multiplier
    const sizeOption = kitchenSizes.find(size => size.value === formData.kitchenSize);
    const sizeMultiplier = sizeOption ? sizeOption.multiplier : 1;

    // Get layout complexity multiplier
    const layoutOption = layoutComplexity.find(layout => layout.value === formData.layoutComplexity);
    const layoutMultiplier = layoutOption ? layoutOption.multiplier : 1;

    // Get cabinet price
    const cabinetOption = cabinetQuality.find(cabinet => cabinet.value === formData.cabinetQuality);
    const cabinetPrice = cabinetOption ? cabinetOption.pricePerUnit * (formData.customSqm * 0.8) : 0;

    // Get countertop price
    const countertopOption = countertopOptions.find(countertop => countertop.value === formData.countertopMaterial);
    const countertopPrice = countertopOption ? countertopOption.pricePerSqm * (formData.customSqm * 0.4) : 0;

    // Get appliances price
    const packageOption = appliancePackages.find(pkg => pkg.value === formData.appliancePackage);
    const appliancesPrice = packageOption ? packageOption.price : 0;

    // Calculate additional features price
    const additionalFeaturesPrice = formData.additionalFeatures.reduce((total, featureId) => {
      const feature = additionalFeatures.find(f => f.id === featureId);
      return total + (feature ? feature.price : 0);
    }, 0);

    // Calculate subtotal
    const subtotal = cabinetPrice + countertopPrice + appliancesPrice + additionalFeaturesPrice;

    // Calculate labor cost (typically 30-40% of materials)
    const laborCost = subtotal * 0.35;

    // Design fees (typically 10-15% for full renovations)
    const designFees = formData.renovationType === 'full' ? subtotal * 0.12 : 0;

    // Permit fees (fixed amount based on emirate)
    const permitFees = 2000;

    // Calculate total before emirate adjustment
    const totalBeforeAdjustment = subtotal + laborCost + designFees + permitFees;

    // Apply emirate adjustment factor
    const emirateFactor = emirateFactors[formData.emirate as keyof typeof emirateFactors] || 1;
    const totalCost = totalBeforeAdjustment * emirateFactor;

    // Set low and high estimates (±10%)
    const lowEstimate = totalCost * 0.9;
    const highEstimate = totalCost * 1.1;

    // Update results state
    setResults({
      cabinetsPrice: cabinetPrice,
      countertopsPrice: countertopPrice,
      appliancesPrice: appliancesPrice,
      additionalFeaturesPrice: additionalFeaturesPrice,
      subtotal: subtotal,
      laborCost: laborCost,
      designFees: designFees,
      permitFees: permitFees,
      totalCost: totalCost,
      lowEstimate: lowEstimate,
      highEstimate: highEstimate,
    });
  };

  // Handle next button click
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 2) {
      calculateResults();
    }
  };

  // Handle back button click
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Handle reset button click
  const handleReset = () => {
    setActiveStep(0);
    // Reset form to default values
    setFormData({
      renovationType: 'full',
      propertyType: 'apartment',
      kitchenSize: 'medium',
      layoutComplexity: 'moderate',
      customSqm: 12,
      cabinetQuality: 'mid',
      countertopMaterial: 'solid_surface',
      appliancePackage: 'mid',
      additionalFeatures: [],
      emirate: 'dubai',
    });
  };

  // Format currency
  const formatCurrency = (amount: number) => {
    return `AED ${amount.toLocaleString('en-AE', { maximumFractionDigits: 0 })}`;
  };

  // Render step content based on active step
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Basic Information
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Renovation Type</FormLabel>
                  <RadioGroup
                    name="renovationType"
                    value={formData.renovationType}
                    onChange={(e) => handleChange('renovationType', e.target.value)}
                  >
                    <FormControlLabel value="full" control={<Radio />} label="Full Renovation" />
                    <FormControlLabel value="partial" control={<Radio />} label="Partial Renovation" />
                    <FormControlLabel value="cosmetic" control={<Radio />} label="Cosmetic Update" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Property Type</FormLabel>
                  <RadioGroup
                    name="propertyType"
                    value={formData.propertyType}
                    onChange={(e) => handleChange('propertyType', e.target.value)}
                  >
                    <FormControlLabel value="apartment" control={<Radio />} label="Apartment" />
                    <FormControlLabel value="villa" control={<Radio />} label="Villa" />
                    <FormControlLabel value="townhouse" control={<Radio />} label="Townhouse" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Emirate</FormLabel>
                  <RadioGroup
                    name="emirate"
                    value={formData.emirate}
                    onChange={(e) => handleChange('emirate', e.target.value)}
                    row
                  >
                    <FormControlLabel value="dubai" control={<Radio />} label="Dubai" />
                    <FormControlLabel value="abu_dhabi" control={<Radio />} label="Abu Dhabi" />
                    <FormControlLabel value="sharjah" control={<Radio />} label="Sharjah" />
                    <FormControlLabel value="ajman" control={<Radio />} label="Ajman" />
                    <FormControlLabel value="ras_al_khaimah" control={<Radio />} label="Ras Al Khaimah" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Layout & Size
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Kitchen Size</FormLabel>
                  <RadioGroup
                    name="kitchenSize"
                    value={formData.kitchenSize}
                    onChange={(e) => handleChange('kitchenSize', e.target.value)}
                  >
                    {kitchenSizes.map((size) => (
                      <FormControlLabel
                        key={size.value}
                        value={size.value}
                        control={<Radio />}
                        label={size.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Layout Complexity</FormLabel>
                  <RadioGroup
                    name="layoutComplexity"
                    value={formData.layoutComplexity}
                    onChange={(e) => handleChange('layoutComplexity', e.target.value)}
                  >
                    {layoutComplexity.map((layout) => (
                      <FormControlLabel
                        key={layout.value}
                        value={layout.value}
                        control={<Radio />}
                        label={layout.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel component="legend">
                    Custom Size (Square Meters)
                  </FormLabel>
                  <TextField
                    type="number"
                    value={formData.customSqm}
                    onChange={(e) => handleChange('customSqm', Number(e.target.value))}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">sqm</InputAdornment>,
                    }}
                    inputProps={{
                      min: 5,
                      max: 50,
                      step: 0.5,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Materials & Finishes
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Cabinet Quality</FormLabel>
                  <RadioGroup
                    name="cabinetQuality"
                    value={formData.cabinetQuality}
                    onChange={(e) => handleChange('cabinetQuality', e.target.value)}
                  >
                    {cabinetQuality.map((cabinet) => (
                      <FormControlLabel
                        key={cabinet.value}
                        value={cabinet.value}
                        control={<Radio />}
                        label={`${cabinet.label} (${formatCurrency(cabinet.pricePerUnit)}/unit)`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Countertop Material</FormLabel>
                  <RadioGroup
                    name="countertopMaterial"
                    value={formData.countertopMaterial}
                    onChange={(e) => handleChange('countertopMaterial', e.target.value)}
                  >
                    {countertopOptions.map((countertop) => (
                      <FormControlLabel
                        key={countertop.value}
                        value={countertop.value}
                        control={<Radio />}
                        label={`${countertop.label} (${formatCurrency(countertop.pricePerSqm)}/sqm)`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Appliances & Additional Features
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Appliance Package</FormLabel>
                  <RadioGroup
                    name="appliancePackage"
                    value={formData.appliancePackage}
                    onChange={(e) => handleChange('appliancePackage', e.target.value)}
                  >
                    {appliancePackages.map((pkg) => (
                      <FormControlLabel
                        key={pkg.value}
                        value={pkg.value}
                        control={<Radio />}
                        label={`${pkg.label} (${formatCurrency(pkg.price)})`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                  Additional Features
                </Typography>
                <Grid container spacing={2}>
                  {additionalFeatures.map((feature) => (
                    <Grid item xs={12} sm={6} key={feature.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.additionalFeatures.includes(feature.id)}
                            onChange={(e) => handleFeatureChange(feature.id, e.target.checked)}
                          />
                        }
                        label={`${feature.label} (${formatCurrency(feature.price)})`}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography variant="h5" gutterBottom align="center">
              Your Kitchen Renovation Estimate
            </Typography>

            <Alert severity="info" sx={{ mb: 3 }}>
              This estimate is based on current UAE market rates and may vary based on specific requirements, material availability, and market fluctuations.
            </Alert>

            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h4" align="center" gutterBottom>
                {formatCurrency(results.totalCost)}
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" gutterBottom>
                Estimated Cost Range: {formatCurrency(results.lowEstimate)} - {formatCurrency(results.highEstimate)}
              </Typography>

              <Divider sx={{ my: 3 }} />

              <Typography variant="h6" gutterBottom>
                Cost Breakdown
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography>Cabinets</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.cabinetsPrice)}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>Countertops</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.countertopsPrice)}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>Appliances</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.appliancesPrice)}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>Additional Features</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.additionalFeaturesPrice)}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>

                <Grid item xs={8}>
                  <Typography>Materials Subtotal</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.subtotal)}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>Labor Cost</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.laborCost)}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>Design Fees</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.designFees)}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>Permit Fees</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right">{formatCurrency(results.permitFees)}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="h6">Total Estimated Cost</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" align="right">{formatCurrency(results.totalCost)}</Typography>
                </Grid>
              </Grid>
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 4 }}>
              <Button
                variant="outlined"
                startIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print Estimate
              </Button>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
              >
                Save Estimate
              </Button>
              <Button
                variant="outlined"
                startIcon={<ShareIcon />}
              >
                Share Estimate
              </Button>
            </Box>

            <Paper sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Next Steps
              </Typography>
              <Typography paragraph>
                Ready to start your kitchen renovation project? Connect with trusted professionals in your area who can help bring your vision to life.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/find-professionals"
                fullWidth
              >
                Find Kitchen Renovation Professionals
              </Button>
            </Paper>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <>
      <PageTitle
        title="Kitchen Renovation Cost Calculator"
        description="Estimate the cost of your kitchen renovation in the UAE with our detailed calculator."
        keywords="kitchen renovation cost, UAE kitchen remodel, Dubai kitchen cost, Abu Dhabi kitchen renovation"
        locale="en_AE"
      />

      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/resources/estimators')}
        sx={{ mb: 2 }}
      >
        Back to Estimators
      </Button>

      <Paper sx={{ p: { xs: 2, md: 4 }, mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <KitchenIcon color="primary" sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h4" component="h1">
            Kitchen Renovation Cost Calculator
          </Typography>
        </Box>

        <Typography paragraph>
          Plan your kitchen renovation budget with our UAE-specific cost calculator. This tool provides estimates based on current market rates in the UAE, helping you make informed decisions about your project.
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 4, mb: 2 }}>
          {getStepContent(activeStep)}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            startIcon={<BackIcon />}
          >
            Back
          </Button>

          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleReset}
            >
              Start New Estimate
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              endIcon={<NextIcon />}
            >
              {activeStep === steps.length - 2 ? 'Calculate' : 'Next'}
            </Button>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default KitchenEstimator;
