import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
} from '@mui/material';
import { PreferenceQuestion, CustomerPreference } from '../../../types/preference';
import PreferenceQuestions from '../PreferenceQuestions';
import CollapsibleSection from '../CollapsibleSection';

interface AdditionalDetailsStepProps {
  formData: {
    description?: string;
    contactInfo?: {
      name: string;
      email: string;
      phone?: string;
    };
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
  serviceQuestions: PreferenceQuestion[];
  preferences: CustomerPreference[];
  onPreferencesChange: (preferences: CustomerPreference[]) => void;
  isAuthenticated: boolean;
}

const AdditionalDetailsStep: React.FC<AdditionalDetailsStepProps> = ({
  formData,
  onChange,
  onNext,
  onBack,
  serviceQuestions,
  preferences,
  onPreferencesChange,
  isAuthenticated,
}) => {
  // Get selected options for summary
  const getSelectedOptionsSummary = (questions: PreferenceQuestion[]): string => {
    if (!questions.length) return '';

    const selectedOptions = questions.flatMap(q => {
      const pref = preferences.find(p => p.questionId === q._id);
      if (!pref || pref.selectedOptions.length === 0) return [];

      return pref.selectedOptions.map(opt => {
        const option = q.options.find(o => o.value === opt);
        return option ? option.optionText : opt;
      });
    });

    return selectedOptions.join(' • ');
  };

  // Filter additional details questions
  const additionalQuestions = serviceQuestions.filter(
    q => q.questionText.toLowerCase().includes('additional') ||
         q.questionText.toLowerCase().includes('other') ||
         q.questionText.toLowerCase().includes('details') ||
         q.questionType === 'text'
  );

  // Handle contact info change
  const handleContactInfoChange = (field: string, value: string) => {
    onChange('contactInfo', {
      ...formData.contactInfo,
      [field]: value,
    });
  };

  // Check if form is valid
  const isValid = () => {
    // Check if all required questions have answers
    const requiredQuestions = additionalQuestions.filter(q => q.isRequired);

    for (const question of requiredQuestions) {
      const preference = preferences.find(p => p.questionId === question._id);

      if (!preference ||
          (preference.selectedOptions.length === 0 && !preference.customAnswer)) {
        return false;
      }
    }

    // If user is not authenticated, check contact info
    if (!isAuthenticated) {
      const contactInfo = formData.contactInfo || { name: '', email: '', phone: '' };
      if (!contactInfo.name || !contactInfo.email) {
        return false;
      }

      // Simple email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(contactInfo.email)) {
        return false;
      }
    }

    return true;
  };

  return (
    <Paper elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
      <Typography variant="h5" gutterBottom>
        Additional Details
      </Typography>

      {additionalQuestions.length > 0 && (
        <CollapsibleSection
          title="Additional information"
          summary={getSelectedOptionsSummary(additionalQuestions)}
          defaultExpanded={true}
        >
          <PreferenceQuestions
            questions={additionalQuestions}
            preferences={preferences}
            onPreferencesChange={onPreferencesChange}
          />
        </CollapsibleSection>
      )}

      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Project description
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Provide any additional details about your project that will help professionals understand your needs better."
          value={formData.description || ''}
          onChange={(e) => onChange('description', e.target.value)}
        />
      </Box>

      {!isAuthenticated && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" gutterBottom>
            Contact Information
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              label="Name"
              required
              value={formData.contactInfo?.name || ''}
              onChange={(e) => handleContactInfoChange('name', e.target.value)}
              error={!formData.contactInfo?.name}
              helperText={!formData.contactInfo?.name ? 'Name is required' : ''}
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              required
              value={formData.contactInfo?.email || ''}
              onChange={(e) => handleContactInfoChange('email', e.target.value)}
              error={!formData.contactInfo?.email}
              helperText={!formData.contactInfo?.email ? 'Email is required' : ''}
            />
            <TextField
              fullWidth
              label="Phone (optional)"
              type="tel"
              value={formData.contactInfo?.phone || ''}
              onChange={(e) => handleContactInfoChange('phone', e.target.value)}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onNext}
          disabled={!isValid()}
        >
          Next
        </Button>
      </Box>
    </Paper>
  );
};

export default AdditionalDetailsStep;