import React, { useState, useCallback } from 'react';
import {
  Paper,
  IconButton,
  Divider,
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Collapse,
  Chip,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CategoryIcon from '@mui/icons-material/Category';
import TuneIcon from '@mui/icons-material/Tune';
import { ICategory } from '../../types/models';
import { debounce } from '../../utils/debounce';
import LocationPicker from './LocationPicker';
import AdvancedSearchFilters from './AdvancedSearchFilters';
import useSearchSuggestions from '../../hooks/useSearchSuggestions';
import { Location, SearchParams, AdvancedFilters } from '../../types/search';

const SearchWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 800,
  margin: '0 auto',
}));

const SearchInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const SearchControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

const CategoryInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

interface SearchBarProps {
  categories: ICategory[];
  onSearch: (params: SearchParams) => void;
  loading?: boolean;
  initialQuery?: string;
  initialCategory?: ICategory;
  initialLocation?: Location;
  initialFilters?: AdvancedFilters;
}

const SearchBar: React.FC<SearchBarProps> = ({
  categories,
  onSearch,
  loading = false,
  initialQuery = '',
  initialCategory,
  initialLocation,
  initialFilters = {},
}) => {
  const [query, setQuery] = useState(initialQuery);
  const [category, setCategory] = useState<ICategory | null>(initialCategory || null);
  const [location, setLocation] = useState<Location | null>(initialLocation || null);
  const [filters, setFilters] = useState<AdvancedFilters>(initialFilters);
  const [showFilters, setShowFilters] = useState(false);
  const [debouncedSearching, setDebouncedSearching] = useState(false);
  const [instantSearchEnabled, setInstantSearchEnabled] = useState(true);

  const { suggestions, loading: suggestionsLoading } = useSearchSuggestions(query);

  // Increased debounce time to 500ms for better performance
  const debouncedSearch = useCallback(
    debounce((searchParams: SearchParams) => {
      setDebouncedSearching(false);
      if (instantSearchEnabled) {
        onSearch(searchParams);
      }
    }, 500),
    [onSearch, instantSearchEnabled]
  );

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    setDebouncedSearching(true);
    debouncedSearch({
      query: newQuery,
      category: category || undefined,
      location: location || undefined,
      filters,
    });
  };

  const handleCategoryChange = (_event: React.SyntheticEvent, value: ICategory | null) => {
    setCategory(value);
    if (instantSearchEnabled) {
      debouncedSearch({
        query,
        category: value || undefined,
        location: location || undefined,
        filters,
      });
    }
  };

  const handleLocationChange = (newLocation: Location | null) => {
    setLocation(newLocation);
    if (instantSearchEnabled) {
      debouncedSearch({
        query,
        category: category || undefined,
        location: newLocation || undefined,
        filters,
      });
    }
  };

  const handleFiltersChange = (newFilters: AdvancedFilters) => {
    setFilters(newFilters);
    if (instantSearchEnabled) {
      debouncedSearch({
        query,
        category: category || undefined,
        location: location || undefined,
        filters: newFilters,
      });
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setInstantSearchEnabled(false);
    onSearch({
      query,
      category: category || undefined,
      location: location || undefined,
      filters,
    });
    // Re-enable instant search after form submission
    setTimeout(() => setInstantSearchEnabled(true), 1000);
  };

  const activeFiltersCount = Object.keys(filters).length;

  return (
    <form onSubmit={handleSubmit}>
      <SearchWrapper elevation={3}>
        <SearchControls>
          <SearchInputWrapper>
            <IconButton disabled>
              <SearchIcon />
            </IconButton>
            <Autocomplete
              freeSolo
              fullWidth
              options={suggestions}
              inputValue={query}
              onInputChange={(_, value) => setQuery(value)}
              loading={suggestionsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search for services..."
                  variant="standard"
                  fullWidth
                  disabled={loading}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {(loading || debouncedSearching) && (
                          <CircularProgress size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </SearchInputWrapper>

          <CategoryInputWrapper>
            <IconButton disabled>
              <CategoryIcon />
            </IconButton>
            <Autocomplete
              fullWidth
              options={categories}
              getOptionLabel={(option) => option.name}
              value={category}
              onChange={handleCategoryChange}
              disabled={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Category"
                  variant="standard"
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {option.icon && (
                      <img
                        src={option.icon}
                        alt=""
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: 8,
                          objectFit: 'contain',
                        }}
                      />
                    )}
                    <Box>
                      <Typography variant="body1">
                        {option.name}
                      </Typography>
                      {option.description && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                          }}
                        >
                          {option.description}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            />
          </CategoryInputWrapper>

          <LocationPicker
            value={location}
            onChange={handleLocationChange}
            disabled={loading}
          />

          <IconButton
            onClick={() => setShowFilters(!showFilters)}
            color={activeFiltersCount > 0 ? 'primary' : 'default'}
          >
            <TuneIcon />
            {activeFiltersCount > 0 && (
              <Chip
                label={activeFiltersCount}
                size="small"
                sx={{
                  position: 'absolute',
                  top: -8,
                  right: -8,
                  height: 20,
                  minWidth: 20,
                }}
              />
            )}
          </IconButton>

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            Search
          </Button>
        </SearchControls>

        <Collapse in={showFilters}>
          <Divider sx={{ my: 1 }} />
          <AdvancedSearchFilters
            value={filters}
            onChange={handleFiltersChange}
            disabled={loading}
          />
        </Collapse>

        {(location || activeFiltersCount > 0) && (
          <>
            <Divider sx={{ my: 1 }} />
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ p: 1 }}>
              {location && (
                <Chip
                  icon={<LocationOnIcon />}
                  label={`${location.address} (${location.radius}km)`}
                  onDelete={() => handleLocationChange(null)}
                />
              )}
              {filters.priceRange && (
                <Chip
                  label={`$${filters.priceRange[0]} - $${filters.priceRange[1]}`}
                  onDelete={() => handleFiltersChange({ ...filters, priceRange: undefined })}
                />
              )}
              {filters.rating && (
                <Chip
                  label={`${filters.rating}+ Stars`}
                  onDelete={() => handleFiltersChange({ ...filters, rating: undefined })}
                />
              )}
              {filters.availability && (
                <Chip
                  label={`Available ${filters.availability.date.toLocaleDateString()}${
                    filters.availability.timeSlot ? ` at ${filters.availability.timeSlot}` : ''
                  }`}
                  onDelete={() => handleFiltersChange({ ...filters, availability: undefined })}
                />
              )}
            </Stack>
          </>
        )}
      </SearchWrapper>
    </form>
  );
};

export default SearchBar;
