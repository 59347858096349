import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { deleteService, updateService, Service, Category } from '../../redux/slices/serviceSlice';
import ServiceForm from './ServiceForm';
import { format } from 'date-fns';
import { AppDispatch } from '../../redux/store';

interface ServiceListProps {
  services: Service[];
  categories: Category[];
  onEdit?: (service: Service) => void;
}

interface FilterState {
  status: 'all' | 'active' | 'inactive';
  category: string;
  search: string;
}

// Removed PricingType interface as pricing is no longer used

const ServiceList: React.FC<ServiceListProps> = ({ services, categories, onEdit }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [filters, setFilters] = useState<FilterState>({
    status: 'all',
    category: 'all',
    search: '',
  });
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleFilterChange = (field: keyof FilterState, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const filteredServices = services.filter((service) => {
    const matchesStatus =
      filters.status === 'all' ||
      (filters.status === 'active' ? service.isActive : !service.isActive);
    const matchesCategory = filters.category === 'all' || service.category._id === filters.category;
    const matchesSearch =
      service.title.toLowerCase().includes(filters.search.toLowerCase()) ||
      service.description.toLowerCase().includes(filters.search.toLowerCase());

    return matchesStatus && matchesCategory && matchesSearch;
  });

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, service: Service) => {
    setMenuAnchor(event.currentTarget);
    setSelectedService(service);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedService(null);
  };

  const handleEditClick = () => {
    handleMenuClose();
    setEditDialogOpen(true);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setDeleteDialogOpen(true);
  };

  const handleToggleStatus = async () => {
    handleMenuClose();
    if (!selectedService) return;

    try {
      await dispatch(
        updateService({
          id: selectedService._id,
          data: {
            title: selectedService.title,
            description: selectedService.description,
            category: selectedService.category._id,
            isActive: !selectedService.isActive,
          },
        })
      ).unwrap();
    } catch (error) {
      console.error('Failed to toggle service status:', error);
    }
  };

  const handleDelete = async () => {
    if (!selectedService) return;

    try {
      await dispatch(deleteService(selectedService._id)).unwrap();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Failed to delete service:', error);
    }
  };

  // Removed formatPrice function as pricing is no longer used

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Search Services"
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status}
              label="Status"
              onChange={(e) => handleFilterChange('status', e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={filters.category}
              label="Category"
              onChange={(e) => handleFilterChange('category', e.target.value)}
            >
              <MenuItem value="all">All Categories</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {filteredServices.map((service) => (
          <Grid item xs={12} sm={6} md={4} key={service._id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={
                  service.images && service.images.length > 0
                    ? service.images[0]
                    : 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=400&h=200&dpr=1'
                }
                alt={service.title}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {service.title}
                  </Typography>
                  <IconButton onClick={(e) => handleMenuOpen(e, service)}>
                    <MoreVertIcon />
                  </IconButton>
                </Box>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {service.description.substring(0, 150)}
                  {service.description.length > 150 ? '...' : ''}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                  <Chip
                    label={service.category.name}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  {/* Removed pricing chip as pricing is no longer used */}
                </Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Chip
                    label={service.isActive ? 'Active' : 'Inactive'}
                    size="small"
                    color={service.isActive ? 'success' : 'default'}
                  />
                  <Typography variant="caption" color="text.secondary">
                    Updated {format(new Date(service.updatedAt), 'MMM dd, yyyy')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {filteredServices.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          No services found matching your filters
        </Alert>
      )}

      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEditClick}>
          <EditIcon sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={handleToggleStatus}>
          {selectedService?.isActive ? (
            <>
              <VisibilityOffIcon sx={{ mr: 1 }} /> Deactivate
            </>
          ) : (
            <>
              <VisibilityIcon sx={{ mr: 1 }} /> Activate
            </>
          )}
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
          <DeleteIcon sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Service</DialogTitle>
        <DialogContent>
          {selectedService && (
            <ServiceForm
              initialValues={{
                category: selectedService.category._id,
                title: selectedService.title,
                description: selectedService.description,
                features: selectedService.features || [],
                images: selectedService.images || [],
                tags: selectedService.tags || [],
              }}
              categories={categories}
              onSubmit={async (values: any) => {
                try {
                  await dispatch(
                    updateService({
                      id: selectedService._id,
                      data: values,
                    })
                  ).unwrap();
                  setEditDialogOpen(false);
                } catch (error) {
                  console.error('Failed to update service:', error);
                }
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Service</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this service? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ServiceList;
