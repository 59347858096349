import React, { ReactNode } from 'react';
import { Container, Box, Paper, Typography, Alert, CircularProgress } from '@mui/material';
import { StepNavigation } from './StepNavigation';
import { useOnboarding } from '../../../contexts/OnboardingContext';

/**
 * Props for OnboardingLayout component
 */
interface OnboardingLayoutProps {
  /**
   * Title for the current step
   */
  title?: string;

  /**
   * Description for the current step
   */
  description?: string;

  /**
   * Children components
   */
  children: ReactNode;
}

/**
 * Layout component for onboarding steps
 */
export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  title,
  description,
  children,
}) => {
  const {
    isStepFormDirty,
    onboardingStatus,
    isLoading,
    error,
    currentStepId,
  } = useOnboarding();

  // Get step info from current step ID
  const currentStep = onboardingStatus?.currentStepId === currentStepId;

  // Get title and description from props or current step
  const stepTitle = title || `Step ${currentStepId}: ${currentStep}`;
  const stepDescription = description || '';

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Service Provider Onboarding
        </Typography>

        {isStepFormDirty && (
          <Alert severity="info" sx={{ mb: 2 }}>
            You have unsaved changes. Make sure to save before moving to the next step.
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error instanceof Error ? error.message : 'An error occurred'}
          </Alert>
        )}

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 4 }}>
              <StepNavigation />
            </Box>

            <Paper elevation={3} sx={{ p: 4 }}>
              {title && (
                <Typography variant="h5" gutterBottom>
                  {title}
                </Typography>
              )}

              {description && (
                <Typography variant="body1" color="text.secondary" paragraph>
                  {description}
                </Typography>
              )}

              {children}
            </Paper>
          </>
        )}
      </Box>
    </Container>
  );
};