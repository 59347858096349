import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Socket, io } from 'socket.io-client';
import { IMessage } from '../../types/models';
import { RootState } from '../../types/store';
import MessageList from './MessageList';
import MessageComposer from './MessageComposer';
import { getMessages, sendMessage, messageReceived } from '../../redux/slices/messageSlice';
import { AppDispatch } from '../../redux/store';
import { useAuth } from '../../hooks/useAuth';

interface ChatWindowProps {
  conversationId: string;
  participant: {
    _id: string;
    name: string;
    avatar?: string;
  };
}

interface ServerToClientEvents {
  messageReceived: (message: IMessage) => void;
  typing: (data: { user: string; conversation: string }) => void;
}

interface ClientToServerEvents {
  joinConversation: (conversationId: string) => void;
  leaveConversation: (conversationId: string) => void;
  startTyping: (conversationId: string) => void;
  stopTyping: (conversationId: string) => void;
}

// Using correct backend port
const SOCKET_URL = 'http://localhost:8000';

const ChatWindow: React.FC<ChatWindowProps> = ({ conversationId, participant }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, getAuthToken } = useAuth();
  const messages = useSelector((state: RootState) =>
    state.messages.messages[conversationId] || []
  );
  const loading = useSelector((state: RootState) => state.messages.loading);

  const [isTyping, setIsTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const socketRef = useRef<Socket<ServerToClientEvents, ClientToServerEvents>>();

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(SOCKET_URL, {
      auth: { token: getAuthToken() },
    });

    const socket = socketRef.current;

    // Join conversation room
    socket.emit('joinConversation', conversationId);

    // Listen for new messages
    socket.on('messageReceived', (message: IMessage) => {
      if (message.conversation === conversationId) {
        dispatch(messageReceived(message));
      }
    });

    // Listen for typing indicators
    socket.on('typing', (data: { user: string; conversation: string }) => {
      if (data.conversation === conversationId && data.user !== user?._id) {
        setIsTyping(true);
        // Clear typing indicator after 3 seconds
        setTimeout(() => setIsTyping(false), 3000);
      }
    });

    // Load existing messages
    dispatch(getMessages(conversationId));

    return () => {
      if (socket) {
        socket.emit('leaveConversation', conversationId);
        socket.disconnect();
      }
    };
  }, [conversationId, dispatch, user?._id, getAuthToken]);

  const handleSendMessage = async (content: string, attachments?: File[]) => {
    if (!user) return;

    try {
      // TODO: Handle file uploads
      await dispatch(sendMessage({
        conversationId,
        data: { content },
      })).unwrap();
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const handleTyping = () => {
    if (!socketRef.current) return;

    socketRef.current.emit('startTyping', conversationId);

    // Clear existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set new timeout to stop typing indicator
    const timeout = setTimeout(() => {
      if (socketRef.current) {
        socketRef.current.emit('stopTyping', conversationId);
      }
    }, 3000);

    setTypingTimeout(timeout);
  };

  if (!user) return null;

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow">
      {/* Chat header */}
      <div className="p-4 border-b flex items-center space-x-3">
        {participant.avatar ? (
          <img
            src={participant.avatar}
            alt={participant.name}
            className="w-10 h-10 rounded-full"
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
            <span className="text-gray-500 text-lg">
              {participant.name.charAt(0).toUpperCase()}
            </span>
          </div>
        )}
        <div>
          <h3 className="font-medium">{participant.name}</h3>
          {isTyping && (
            <p className="text-sm text-gray-500">Typing...</p>
          )}
        </div>
      </div>

      {/* Messages */}
      {loading ? (
        <div className="flex-1 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
        </div>
      ) : (
        <MessageList
          messages={messages}
          currentUser={user._id}
        />
      )}

      {/* Message composer */}
      <MessageComposer
        onSend={handleSendMessage}
        onTyping={handleTyping}
        disabled={loading}
      />
    </div>
  );
};

export default ChatWindow;
