import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Paper,
  Divider,
} from '@mui/material';
import {
  Article as ArticleIcon,
  Calculate as CalculateIcon,
  MenuBook as GuideIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import PageTitle from '../../components/common/PageTitle';

/**
 * ResourcesIndex component serves as the landing page for the resources section
 * It provides an overview of all resource types available
 */
const ResourcesIndex: React.FC = () => {
  // Featured content for each section
  const featuredContent = {
    stories: [
      {
        id: 'story-1',
        title: 'Modern Villa Renovation in Dubai',
        image: 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        excerpt: 'See how this 20-year-old villa was transformed into a modern oasis with smart home features and energy-efficient design.',
      },
      {
        id: 'story-2',
        title: 'Creating a Desert-Friendly Garden',
        image: 'https://images.pexels.com/photos/2132227/pexels-photo-2132227.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        excerpt: 'Learn how this Abu Dhabi homeowner created a beautiful garden that thrives in the desert climate while conserving water.',
      },
    ],
    estimators: [
      {
        id: 'estimator-1',
        title: 'Kitchen Renovation Cost Calculator',
        icon: <CalculateIcon fontSize="large" />,
        description: 'Estimate the cost of your kitchen renovation based on your specific requirements and preferences.',
      },
      {
        id: 'estimator-2',
        title: 'Home Painting Cost Estimator',
        icon: <CalculateIcon fontSize="large" />,
        description: 'Calculate how much it will cost to paint your home interior or exterior in the UAE.',
      },
    ],
    guides: [
      {
        id: 'guide-1',
        title: 'How to Maintain Your AC System',
        image: 'https://images.pexels.com/photos/4792733/pexels-photo-4792733.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        excerpt: 'A complete guide to maintaining your air conditioning system for optimal performance in UAE climate.',
      },
      {
        id: 'guide-2',
        title: 'DIY Bathroom Renovation Guide',
        image: 'https://images.pexels.com/photos/6585757/pexels-photo-6585757.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        excerpt: 'Step-by-step instructions for renovating your bathroom, from planning to execution.',
      },
    ],
  };

  return (
    <>
      <PageTitle
        title="Home Improvement Resources"
        description="Discover home improvement resources, guides, and tools specifically designed for UAE homeowners."
        keywords="home improvement, UAE, Dubai, Abu Dhabi, renovation, DIY, cost calculator, guides"
        locale="en_AE"
      />

      {/* Hero Section */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          mb: 6,
          borderRadius: 2,
          backgroundImage: 'linear-gradient(to right, #f5f7fa, #e4e8f0)',
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h3" component="h1" gutterBottom>
              Home Improvement Resources
            </Typography>
            <Typography variant="h6" color="text.secondary" paragraph>
              Everything you need to plan, budget, and execute your home improvement projects in the UAE
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/resources/stories"
                sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
              >
                Explore Stories
              </Button>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/resources/estimators"
              >
                Try Cost Estimators
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="https://images.pexels.com/photos/1669799/pexels-photo-1669799.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Home improvement"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Stories Section */}
      <Box sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ArticleIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h5" component="h2">
              Home Improvement Stories
            </Typography>
          </Box>
          <Button
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to="/resources/stories"
          >
            View All
          </Button>
        </Box>
        <Grid container spacing={3}>
          {featuredContent.stories.map((story) => (
            <Grid item xs={12} sm={6} key={story.id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={story.image}
                  alt={story.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {story.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {story.excerpt}
                  </Typography>
                  <Button
                    size="small"
                    component={Link}
                    to={`/resources/stories/${story.id}`}
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider sx={{ mb: 6 }} />

      {/* Cost Estimators Section */}
      <Box sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CalculateIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h5" component="h2">
              Cost Estimators
            </Typography>
          </Box>
          <Button
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to="/resources/estimators"
          >
            View All
          </Button>
        </Box>
        <Grid container spacing={3}>
          {featuredContent.estimators.map((estimator) => (
            <Grid item xs={12} sm={6} key={estimator.id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Box sx={{
                      p: 2,
                      borderRadius: '50%',
                      bgcolor: 'primary.light',
                      color: 'primary.contrastText',
                    }}>
                      {estimator.icon}
                    </Box>
                  </Box>
                  <Typography variant="h6" component="h3" align="center" gutterBottom>
                    {estimator.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" align="center" paragraph>
                    {estimator.description}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="outlined"
                      size="small"
                      component={Link}
                      to={`/resources/estimators/${estimator.id}`}
                    >
                      Try Calculator
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider sx={{ mb: 6 }} />

      {/* Guides Section */}
      <Box sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <GuideIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h5" component="h2">
              How-To Guides
            </Typography>
          </Box>
          <Button
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to="/resources/guides"
          >
            View All
          </Button>
        </Box>
        <Grid container spacing={3}>
          {featuredContent.guides.map((guide) => (
            <Grid item xs={12} sm={6} key={guide.id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={guide.image}
                  alt={guide.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {guide.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {guide.excerpt}
                  </Typography>
                  <Button
                    size="small"
                    component={Link}
                    to={`/resources/guides/${guide.id}`}
                  >
                    View Guide
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Call to Action */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Can't find what you're looking for?
        </Typography>
        <Typography variant="body1" paragraph>
          Our team of home improvement experts is here to help you with your specific needs.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/find-professionals"
          sx={{ mt: 1 }}
        >
          Connect with Professionals
        </Button>
      </Paper>
    </>
  );
};

export default ResourcesIndex;