import React from 'react';
import { SellerOnboardingProvider as ContextProvider } from '../../../contexts/SellerOnboardingContext';

interface SellerOnboardingProviderProps {
  children: React.ReactNode;
}

const SellerOnboardingProvider: React.FC<SellerOnboardingProviderProps> = ({ children }) => {
  return <ContextProvider>{children}</ContextProvider>;
};

export default SellerOnboardingProvider;