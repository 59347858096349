import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Tab,
  Tabs,
  Grid,
} from '@mui/material';
import {
  Close as CloseIcon,
  CloudUpload as UploadIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

interface AddItemModalProps {
  type: 'photo' | 'product' | 'professional' | 'story' | 'discussion';
  open: boolean;
  onClose: () => void;
  onAdd: (item: any) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const AddItemModal: React.FC<AddItemModalProps> = ({ type, open, onClose, onAdd }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [note, setNote] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    // Create item based on type
    const item = {
      type,
      note,
      content: type === 'photo' ? selectedFile : null,
      // Additional fields based on type would go here
    };
    onAdd(item);
    onClose();
  };

  const renderContent = () => {
    switch (type) {
      case 'photo':
        return (
          <>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab label="Upload" />
              <Tab label="Search" />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="upload-photo"
                  type="file"
                  onChange={handleFileSelect}
                />
                <label htmlFor="upload-photo">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<UploadIcon />}
                  >
                    Choose Photo
                  </Button>
                </label>
                {previewUrl && (
                  <Box sx={{ mt: 2, maxWidth: '100%', maxHeight: 300 }}>
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '300px',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <TextField
                fullWidth
                placeholder="Search photos..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon color="action" />,
                }}
                sx={{ mb: 2 }}
              />
              <Grid container spacing={2}>
                {/* Search results would go here */}
              </Grid>
            </TabPanel>
          </>
        );

      case 'product':
        return (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon color="action" />,
              }}
              sx={{ mb: 2 }}
            />
            <Grid container spacing={2}>
              {/* Product search results would go here */}
            </Grid>
          </Box>
        );

      case 'professional':
        return (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              placeholder="Search professionals..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon color="action" />,
              }}
              sx={{ mb: 2 }}
            />
            <Grid container spacing={2}>
              {/* Professional search results would go here */}
            </Grid>
          </Box>
        );

      case 'story':
      case 'discussion':
        return (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder={`Write your ${type}...`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            Add {type.charAt(0).toUpperCase() + type.slice(1)}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {renderContent()}
        <TextField
          fullWidth
          multiline
          rows={2}
          label="Add a note (optional)"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={type === 'photo' && !selectedFile && activeTab === 0}
        >
          Add to Ideabook
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemModal;