// Environment variables with type checking
interface Env {
  NODE_ENV: 'development' | 'production' | 'test';
  API_URL: string;
  GOOGLE_MAPS_API_KEY: string;
  STRIPE_PUBLIC_KEY: string;
  SENTRY_DSN?: string;
  ANALYTICS_ID?: string;
  PEXELS_API_KEY?: string;
}

export const env: Env = {
  NODE_ENV: (import.meta.env.MODE as Env['NODE_ENV']) || 'development',
  // Always use a relative URL to ensure requests go through our proxy
  API_URL: '/api',
  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '',
  STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY || '',
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  ANALYTICS_ID: import.meta.env.VITE_ANALYTICS_ID,
  PEXELS_API_KEY: import.meta.env.VITE_PEXELS_API_KEY || '',
};

// Pexels API Configuration
export const PEXELS_CONFIG = {
  API_KEY: env.PEXELS_API_KEY,
  DEFAULT_PER_PAGE: 15,
  MAX_PER_PAGE: 80,
  CACHE_DURATION: 60 * 60 * 1000, // 1 hour
} as const;

// API Configuration
export const API_CONFIG = {
  TIMEOUT: 30000, // 30 seconds
  RETRY_ATTEMPTS: 3,
  RETRY_DELAY: 1000, // 1 second
  CACHE_DURATION: 5 * 60 * 1000, // 5 minutes
  MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
  ALLOWED_FILE_TYPES: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'],
} as const;

// Authentication Configuration
export const AUTH_CONFIG = {
  TOKEN_KEY: 'homezy_token',
  REFRESH_TOKEN_KEY: 'homezy_refresh_token',
  TOKEN_EXPIRY: 7 * 24 * 60 * 60 * 1000, // 7 days
  REFRESH_TOKEN_EXPIRY: 30 * 24 * 60 * 60 * 1000, // 30 days
  MIN_PASSWORD_LENGTH: 8,
  MAX_PASSWORD_LENGTH: 128,
  PASSWORD_PATTERN: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
} as const;

// Pagination Configuration
export const PAGINATION_CONFIG = {
  DEFAULT_PAGE_SIZE: 10,
  MAX_PAGE_SIZE: 100,
  PAGE_SIZE_OPTIONS: [10, 25, 50, 100] as const,
} as const;

// Search Configuration
export const SEARCH_CONFIG = {
  MIN_SEARCH_LENGTH: 3,
  DEBOUNCE_DELAY: 300, // 300ms
  MAX_RECENT_SEARCHES: 5,
  DEFAULT_SEARCH_RADIUS: 10, // 10km
  MAX_SEARCH_RADIUS: 100, // 100km
} as const;

// Date Format Configuration
export const DATE_CONFIG = {
  DEFAULT_FORMAT: 'YYYY-MM-DD',
  DISPLAY_FORMAT: 'MMM DD, YYYY',
  TIME_FORMAT: 'HH:mm',
  DATETIME_FORMAT: 'MMM DD, YYYY HH:mm',
  TIMEZONE: 'UTC',
} as const;

// Currency Configuration
export const CURRENCY_CONFIG = {
  DEFAULT_CURRENCY: 'USD',
  SUPPORTED_CURRENCIES: ['USD', 'EUR', 'GBP', 'AED'] as const,
  DECIMAL_PLACES: 2,
} as const;

// Service Categories
export const SERVICE_CATEGORIES = {
  CLEANING: 'cleaning',
  PLUMBING: 'plumbing',
  ELECTRICAL: 'electrical',
  HVAC: 'hvac',
  PAINTING: 'painting',
  LANDSCAPING: 'landscaping',
  HANDYMAN: 'handyman',
  MOVING: 'moving',
} as const;

// Service Status
export const SERVICE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  SUSPENDED: 'suspended',
} as const;

// Booking Status
export const BOOKING_STATUS = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
} as const;

// Error Messages
export const ERROR_MESSAGES = {
  NETWORK_ERROR: 'Unable to connect to the server. Please check your internet connection.',
  UNAUTHORIZED: 'You are not authorized to perform this action.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again.',
  INVALID_CREDENTIALS: 'Invalid email or password.',
  REQUIRED_FIELD: 'This field is required.',
  INVALID_EMAIL: 'Please enter a valid email address.',
  INVALID_PASSWORD:
    'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match.',
  FILE_TOO_LARGE: 'File size exceeds the maximum limit.',
  INVALID_FILE_TYPE: 'File type not supported.',
  LOCATION_REQUIRED: 'Please enable location services to use this feature.',
} as const;

// Success Messages
export const SUCCESS_MESSAGES = {
  PROFILE_UPDATED: 'Profile updated successfully.',
  PASSWORD_CHANGED: 'Password changed successfully.',
  SERVICE_CREATED: 'Service created successfully.',
  SERVICE_UPDATED: 'Service updated successfully.',
  BOOKING_CONFIRMED: 'Booking confirmed successfully.',
  REVIEW_SUBMITTED: 'Review submitted successfully.',
} as const;

// Export types
export type ServiceCategory = (typeof SERVICE_CATEGORIES)[keyof typeof SERVICE_CATEGORIES];
export type ServiceStatus = (typeof SERVICE_STATUS)[keyof typeof SERVICE_STATUS];
export type BookingStatus = (typeof BOOKING_STATUS)[keyof typeof BOOKING_STATUS];
export type SupportedCurrency = (typeof CURRENCY_CONFIG.SUPPORTED_CURRENCIES)[number];
export type PageSize = (typeof PAGINATION_CONFIG.PAGE_SIZE_OPTIONS)[number];
