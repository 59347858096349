import axiosInstance from './axios';
import { isAxiosError } from 'axios';
import store from '../redux/store';
import {
  googleAuthSuccess,
  loginSuccess,
  loginFailure,
  loginStart,
  logout,
  registerSuccess,
} from '../redux/slices/authSlice';

interface UserData {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  role: 'customer' | 'provider' | 'admin';
}

interface ProviderData extends UserData {
  phone: string;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface ProfileData {
  name?: string;
  email?: string;
  avatar?: string;
}

interface User {
  _id: string;
  name: string;
  email: string;
  role: 'customer' | 'provider' | 'admin';
  avatar?: string;
  googleId?: string;
}

interface AuthResponse {
  token: string;
  refreshToken: string;
  user: User;
}

interface ApiError {
  message: string;
}

class AuthService {
  private initializationPromise: Promise<void> | null = null;

  constructor() {
    // Don't immediately initialize, wait for first auth check
    this.initializationPromise = null;
  }

  async ensureInitialized() {
    if (!this.initializationPromise) {
      this.initializationPromise = this.initializeAuthState();
    }
    return this.initializationPromise;
  }

  private async initializeAuthState() {
    store.dispatch(loginStart());

    try {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      const userId = localStorage.getItem('userId');

      if (!token || !refreshToken || !userId) {
        store.dispatch(loginFailure('No stored credentials'));
        return;
      }

      // Set the token in axios headers
      this.setAuthToken(token, refreshToken);

      // Get the user profile
      const user = await this.getProfile();

      if (!this.isValidUser(user)) {
        throw new Error('Invalid user data received');
      }

      // Update Redux store with the user and token
      store.dispatch(loginSuccess({ user, token }));
    } catch (error) {
      // If there's an error, clear the invalid tokens and update Redux state
      this.clearAuthToken();
      store.dispatch(
        loginFailure(error instanceof Error ? error.message : 'Authentication failed')
      );
    }
  }

  private setAuthToken(token: string, refreshToken: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);

    // Set the token in axios defaults
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  private clearAuthToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');

    // Clear the token from axios defaults
    delete axiosInstance.defaults.headers.common['Authorization'];
  }

  private isValidAuthResponse(response: AuthResponse): boolean {
    return !!(
      response &&
      response.token &&
      response.refreshToken &&
      response.user &&
      response.user._id &&
      response.user.email
    );
  }

  private isValidUser(user: User): boolean {
    return !!(user && user._id && user.email);
  }

  private handleError(error: unknown): never {
    if (isAxiosError<{ message: string }>(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('An unexpected error occurred');
  }

  async refreshToken(refreshToken: string): Promise<AuthResponse> {
    try {
      const response = await axiosInstance.post<AuthResponse>('/auth/refresh-token', {
        refreshToken,
      });

      if (!this.isValidAuthResponse(response.data)) {
        throw new Error('Invalid response data');
      }

      this.setAuthToken(response.data.token, response.data.refreshToken);
      store.dispatch(loginSuccess(response.data));
      return response.data;
    } catch (error) {
      this.clearAuthToken();
      throw this.handleError(error);
    }
  }

  initiateGoogleAuth(role?: 'customer' | 'provider'): void {
    // Store the current URL to redirect back after auth
    localStorage.setItem('authRedirectUrl', window.location.pathname);
    // Store the role if provided (for signup flow)
    if (role) {
      localStorage.setItem('googleAuthRole', role);
    }
    // Add role as query parameter if it's a signup flow
    // Always use a relative URL to ensure requests go through our proxy
    const apiUrl = '/api';
    const baseUrl = window.location.origin + apiUrl;

    const authUrl = role ? `${baseUrl}/auth/google?role=${role}` : `${baseUrl}/auth/google`;

    // Clear any existing auth data before initiating new auth
    this.clearAuthToken();
    store.dispatch(loginFailure('Initiating Google authentication'));

    window.location.href = authUrl;
  }

  async handleGoogleCallback(token: string, userId: string): Promise<AuthResponse> {
    try {
      if (!token || !userId) {
        throw new Error('Invalid authentication response');
      }

      // Generate a refresh token by calling the refresh token endpoint
      const refreshToken = token; // Use the same token as refresh token for now

      // Set the auth token
      this.setAuthToken(token, refreshToken);
      localStorage.setItem('userId', userId);

      // Get the user profile
      const user = await this.getProfile();

      if (!this.isValidUser(user)) {
        throw new Error('Invalid profile data received');
      }

      // Update Redux store
      store.dispatch(googleAuthSuccess({ user, token, refreshToken }));

      return { token, refreshToken, user };
    } catch (error) {
      this.clearAuthToken();
      store.dispatch(
        loginFailure(error instanceof Error ? error.message : 'Google authentication failed')
      );
      throw error;
    }
  }

  async handleGoogleError(error: string): Promise<never> {
    this.clearAuthToken();
    store.dispatch(loginFailure(decodeURIComponent(error)));
    throw new Error(decodeURIComponent(error));
  }

  async register(userData: UserData): Promise<AuthResponse> {
    try {
      const response = await axiosInstance.post<AuthResponse>('/auth/register', userData);

      if (!this.isValidAuthResponse(response.data)) {
        throw new Error('Invalid response data');
      }

      this.setAuthToken(response.data.token, response.data.refreshToken);
      store.dispatch(registerSuccess(response.data));
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async registerProvider(providerData: ProviderData): Promise<AuthResponse> {
    try {
      // These fields will be collected during the onboarding process
      const dataToSend = {
        ...providerData,
      };

      const response = await axiosInstance.post<AuthResponse>(
        '/auth/register/provider',
        dataToSend
      );

      if (!this.isValidAuthResponse(response.data)) {
        throw new Error('Invalid response data');
      }

      this.setAuthToken(response.data.token, response.data.refreshToken);
      store.dispatch(registerSuccess(response.data));
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async login(credentials: LoginCredentials): Promise<AuthResponse> {
    try {
      const response = await axiosInstance.post<AuthResponse>('/auth/login', credentials);

      if (!this.isValidAuthResponse(response.data)) {
        throw new Error('Invalid response data');
      }

      this.setAuthToken(response.data.token, response.data.refreshToken);
      store.dispatch(loginSuccess(response.data));
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async logout(): Promise<void> {
    this.clearAuthToken();
    store.dispatch(logout());
  }

  async forgotPassword(email: string): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.post<{ message: string }>('/auth/forgot-password', {
        email,
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async resetPassword(token: string, newPassword: string): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.post<{ message: string }>('/auth/reset-password', {
        token,
        newPassword,
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getProfile(): Promise<User> {
    try {
      const response = await axiosInstance.get<User>('/profile', {
        headers: {
          'X-Background-Request': 'true',
        },
      });

      if (!this.isValidUser(response.data)) {
        throw new Error('Invalid profile data');
      }

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async updateProfile(profileData: ProfileData): Promise<User> {
    try {
      const response = await axiosInstance.put<User>('/profile', profileData);

      if (!this.isValidUser(response.data)) {
        throw new Error('Invalid profile data');
      }

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async updateAvatar(formData: FormData): Promise<{ avatar: string }> {
    try {
      const response = await axiosInstance.post<{ avatar: string }>('/profile/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data.avatar) {
        throw new Error('Invalid avatar data');
      }

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  // Check if user is authenticated
  isAuthenticated(): boolean {
    return !!(localStorage.getItem('token') && localStorage.getItem('userId'));
  }

  // Get stored auth token
  getAuthToken(): string | null {
    return localStorage.getItem('token');
  }
}

export default new AuthService();
