import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Rating,
  Divider,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';

export interface FilterOptions {
  startTime?: string;
  projectScope?: string[];
  electricalRelocation?: string;
  plumbingRelocation?: string;
  professionalCategory?: string[];
  projectType?: string[];
  minRating?: number;
  specificDate?: Date | null;
}

interface ProfessionalFiltersProps {
  onFilterChange: (filters: FilterOptions) => void;
  initialFilters?: FilterOptions;
  serviceType?: string;
}

const ProfessionalFilters: React.FC<ProfessionalFiltersProps> = ({
  onFilterChange,
  initialFilters = {},
  serviceType = '',
}) => {
  const [filters, setFilters] = useState<FilterOptions>(initialFilters);
  const [categorySearch, setCategorySearch] = useState('');
  const [projectTypeSearch, setProjectTypeSearch] = useState('');

  // Define filter options based on service type
  // For now, we'll use kitchen remodeling options as shown in the screenshots
  const startTimeOptions = [
    { value: 'within_48_hours', label: 'Within 48 hours' },
    { value: 'within_a_week', label: 'Within a week' },
    { value: 'flexible', label: 'Flexible on timeline' },
    { value: 'specific_date', label: 'Specific date(s)' },
  ];

  const projectScopeOptions = [
    { value: 'repair_tiling_flooring', label: 'Repair of existing tiling or flooring' },
    { value: 'repair_cabinetry_fixtures', label: 'Repair of existing cabinetry or fixtures' },
    { value: 'install_tiling_flooring', label: 'Installation of new tiling or flooring' },
    { value: 'install_cabinetry_fixtures', label: 'Installation of new cabinetry or fixtures' },
    { value: 'full_remodel', label: 'Full remodel of existing kitchen' },
  ];

  const electricalRelocationOptions = [
    { value: 'no', label: 'No, electrical will not need to be relocated' },
    { value: 'yes', label: 'Yes, electrical will need to be relocated' },
    { value: 'not_sure', label: 'I\'m not sure, need professional recommendation' },
  ];

  const plumbingRelocationOptions = [
    { value: 'no', label: 'No, plumbing will not need to be relocated' },
    { value: 'yes', label: 'Yes, plumbing will need to be relocated' },
    { value: 'not_sure', label: 'I\'m not sure, need professional recommendation' },
  ];

  const professionalCategoryOptions = [
    { value: 'architects', label: 'Architects & Building Designers' },
    { value: 'design_build', label: 'Design-Build Firms' },
    { value: 'general_contractors', label: 'General Contractors' },
    { value: 'home_builders', label: 'Home Builders' },
  ];

  const projectTypeOptions = [
    { value: 'kitchen_remodeling', label: 'Kitchen Remodeling' },
    { value: 'bathroom_remodeling', label: 'Bathroom Remodeling' },
    { value: 'home_renovation', label: 'Home Renovation' },
    { value: 'interior_design', label: 'Interior Design' },
    { value: 'custom_cabinets', label: 'Custom Cabinets' },
    { value: 'flooring_installation', label: 'Flooring Installation' },
  ];

  // Filter options based on search
  const filteredCategoryOptions = professionalCategoryOptions.filter(option =>
    option.label.toLowerCase().includes(categorySearch.toLowerCase())
  );

  const filteredProjectTypeOptions = projectTypeOptions.filter(option =>
    option.label.toLowerCase().includes(projectTypeSearch.toLowerCase())
  );

  // Handle filter changes
  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = { ...filters, startTime: event.target.value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleSpecificDateChange = (date: Date | null) => {
    const newFilters = { ...filters, specificDate: date };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleProjectScopeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const currentScope = filters.projectScope || [];

    const newScope = event.target.checked
      ? [...currentScope, value]
      : currentScope.filter(item => item !== value);

    const newFilters = { ...filters, projectScope: newScope };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleElectricalRelocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = { ...filters, electricalRelocation: event.target.value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handlePlumbingRelocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = { ...filters, plumbingRelocation: event.target.value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleProfessionalCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const currentCategories = filters.professionalCategory || [];

    const newCategories = event.target.checked
      ? [...currentCategories, value]
      : currentCategories.filter(item => item !== value);

    const newFilters = { ...filters, professionalCategory: newCategories };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleProjectTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const currentTypes = filters.projectType || [];

    const newTypes = event.target.checked
      ? [...currentTypes, value]
      : currentTypes.filter(item => item !== value);

    const newFilters = { ...filters, projectType: newTypes };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleRatingChange = (_event: React.SyntheticEvent, value: number | null) => {
    const newFilters = { ...filters, minRating: value || undefined };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const clearAllFilters = () => {
    setFilters({});
    onFilterChange({});
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 1, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" fontWeight="bold">
          Filters
        </Typography>
        <Button size="small" onClick={clearAllFilters}>
          Clear All
        </Button>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* When do you want to start? */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          When do you want to start?
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={filters.startTime || ''}
            onChange={handleStartTimeChange}
          >
            {startTimeOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {filters.startTime === 'specific_date' && (
          <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Date"
                value={filters.specificDate}
                onChange={handleSpecificDateChange}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Box>
        )}
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Project scope */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Project scope
        </Typography>
        <FormControl component="fieldset">
          {projectScopeOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={filters.projectScope?.includes(option.value) || false}
                  onChange={handleProjectScopeChange}
                  value={option.value}
                />
              }
              label={option.label}
            />
          ))}
        </FormControl>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Electrical to be relocated */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Electrical to be relocated
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={filters.electricalRelocation || ''}
            onChange={handleElectricalRelocationChange}
          >
            {electricalRelocationOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Plumbing to be relocated */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Plumbing to be relocated
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={filters.plumbingRelocation || ''}
            onChange={handlePlumbingRelocationChange}
          >
            {plumbingRelocationOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Professional Category */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" fontWeight="bold">
            Professional Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            size="small"
            placeholder="Search categories"
            value={categorySearch}
            onChange={(e) => setCategorySearch(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: categorySearch ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setCategorySearch('')}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <FormControl component="fieldset">
            {filteredCategoryOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={filters.professionalCategory?.includes(option.value) || false}
                    onChange={handleProfessionalCategoryChange}
                    value={option.value}
                  />
                }
                label={option.label}
              />
            ))}
          </FormControl>
        </AccordionDetails>
      </Accordion>

      {/* Project Type */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" fontWeight="bold">
            Project Type
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            size="small"
            placeholder="Search project types"
            value={projectTypeSearch}
            onChange={(e) => setProjectTypeSearch(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: projectTypeSearch ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setProjectTypeSearch('')}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <FormControl component="fieldset">
            {filteredProjectTypeOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={filters.projectType?.includes(option.value) || false}
                    onChange={handleProjectTypeChange}
                    value={option.value}
                  />
                }
                label={option.label}
              />
            ))}
          </FormControl>
        </AccordionDetails>
      </Accordion>

      {/* Rating */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" fontWeight="bold">
            Rating
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Rating
              value={filters.minRating || 0}
              onChange={handleRatingChange}
              precision={1}
            />
            <Typography variant="body2" sx={{ ml: 1 }}>
              {filters.minRating ? `${filters.minRating}+ stars` : 'Any rating'}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ProfessionalFilters;