import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import authService from '../../services/authService';
import axios from '../../services/axios';
import {
  googleAuthStart,
  googleAuthSuccess,
  googleAuthFailure,
} from '../../redux/slices/authSlice';
import analytics from '../../utils/analytics';

const GoogleAuthCallback: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        dispatch(googleAuthStart());

        const params = new URLSearchParams(search);
        const token = params.get('token');
        const userId = params.get('userId');
        const errorMessage = params.get('message');

        if (errorMessage) {
          throw new Error(decodeURIComponent(errorMessage));
        }

        if (!token || !userId) {
          throw new Error('Invalid authentication response');
        }

        const authResponse = await authService.handleGoogleCallback(token, userId);

        if (!authResponse) {
          throw new Error('Failed to get authentication response');
        }

        dispatch(
          googleAuthSuccess({
            user: authResponse.user,
            token: authResponse.token,
          })
        );

        // Track successful Google login
        analytics.trackLogin('google', authResponse.user._id);

        // Get role from state parameter and redirect URL
        const state = params.get('state');
        let role = '';
        if (state) {
          try {
            const stateData = JSON.parse(Buffer.from(state, 'base64').toString());
            role = stateData.role;
          } catch (error) {
            console.error('Error parsing state parameter:', error);
          }
        }

        const redirectUrl = localStorage.getItem('authRedirectUrl') || '/dashboard';
        localStorage.removeItem('authRedirectUrl');

        // Handle redirects based on role and whether it's a new signup
        if (role === 'provider') {
          navigate('/pro/onboarding/0', { replace: true });
        } else if (authResponse.user.role === 'provider') {
          // Check if existing provider has completed onboarding
          try {
            const onboardingResponse = await axios.get('/onboarding/status', {
              headers: { Authorization: `Bearer ${token}` },
            });
            const onboardingData = onboardingResponse.data;

            if (!onboardingData.onboardingStatus.completed) {
              // Redirect to onboarding if not completed
              navigate('/pro/onboarding/0', { replace: true });
              return;
            }
          } catch (error) {
            console.error('Error checking onboarding status:', error);
            // Default to onboarding if we can't check status
            navigate('/pro/onboarding/0', { replace: true });
            return;
          }
          navigate('/pro/dashboard', { replace: true });
        } else if (authResponse.user.role === 'customer') {
          navigate('/dashboard', { replace: true });
        } else {
          // For existing users, use the stored redirect URL or default to dashboard
          navigate(redirectUrl, { replace: true });
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Authentication failed';
        setError(errorMessage);
        dispatch(googleAuthFailure(errorMessage));
      } finally {
        setIsLoading(false);
      }
    };

    handleCallback();
  }, [search, navigate, dispatch]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        gap={2}
      >
        <CircularProgress />
        <Typography variant="body1" color="textSecondary">
          Completing authentication...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        padding={2}
      >
        <Alert
          severity="error"
          action={
            <button
              onClick={() => {
                const role = localStorage.getItem('googleAuthRole');
                localStorage.removeItem('googleAuthRole');
                localStorage.removeItem('authRedirectUrl');
                navigate(role === 'provider' ? '/pro/register' : '/register');
              }}
              style={{
                background: 'none',
                border: 'none',
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'inherit',
              }}
            >
              Try Again
            </button>
          }
        >
          {error}
        </Alert>
      </Box>
    );
  }

  return null;
};

export default GoogleAuthCallback;
