import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useOnboarding } from '../../../../contexts/OnboardingContext';
import { OnboardingStepId } from '../../../../types/onboarding/shared';
import { useOnboardingStepData, useSaveStep } from '../../../../hooks/api/useOnboardingApi';
import { OnboardingLayout } from '../OnboardingLayout';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

// Define the schema for a service area
const serviceAreaSchema = z.object({
  type: z.enum(['radius', 'locality']), // Removed 'city' as it's the same as emirate in UAE
  center: z.object({
    lat: z.number().optional(),
    lng: z.number().optional(),
  }).optional(),
  radius: z.number().positive().optional(),
  localities: z.array(z.string()).optional(), // Changed from zipCodes to localities
});

// Define the schema for the form
const formSchema = z.object({
  areas: z.array(serviceAreaSchema).min(1, 'At least one service area is required'),
});

type FormValues = z.infer<typeof formSchema>;

/**
 * Service Areas step component
 */
const ServiceAreasStep: React.FC = () => {
  const navigate = useNavigate();
  const { getNextStep, getPreviousStep } = useOnboarding();
  const { data: stepData, isLoading: isDataLoading } = useOnboardingStepData<any[]>(
    OnboardingStepId.SERVICE_AREAS
  );
  const { mutateAsync: saveStepAsync } = useSaveStep();

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Initialize form with default values
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      areas: stepData || [
        {
          type: 'locality',
          localities: [''],
        },
      ],
    },
  });

  const { control, handleSubmit, watch, setValue, getValues } = methods;
  const areas = watch('areas');

  const addServiceArea = () => {
    const currentAreas = getValues('areas') || [];
    setValue('areas', [
      ...currentAreas,
      {
        type: 'locality',
        localities: [''],
      },
    ]);
  };

  const removeServiceArea = (index: number) => {
    const currentAreas = getValues('areas') || [];
    setValue(
      'areas',
      currentAreas.filter((_, i) => i !== index)
    );
  };

  const addLocality = (areaIndex: number) => {
    const currentAreas = getValues('areas') || [];
    const currentLocalities = currentAreas[areaIndex]?.localities || [];

    const updatedAreas = [...currentAreas];
    updatedAreas[areaIndex] = {
      ...updatedAreas[areaIndex],
      localities: [...currentLocalities, ''],
    };

    setValue('areas', updatedAreas);
  };

  const removeLocality = (areaIndex: number, localityIndex: number) => {
    const currentAreas = getValues('areas') || [];
    const currentLocalities = currentAreas[areaIndex]?.localities || [];

    const updatedAreas = [...currentAreas];
    updatedAreas[areaIndex] = {
      ...updatedAreas[areaIndex],
      localities: currentLocalities.filter((_, i) => i !== localityIndex),
    };

    setValue('areas', updatedAreas);
  };

  const handleBack = () => {
    const prevStep = getPreviousStep(OnboardingStepId.SERVICE_AREAS);
    if (prevStep) {
      navigate(`/pro/onboarding/${prevStep}`);
    }
  };

  const onSubmit = async (data: FormValues) => {
    setIsSaving(true);
    setError(null);

    try {
      // Save to server
      const result = await saveStepAsync({
        stepId: OnboardingStepId.SERVICE_AREAS,
        data: data.areas,
      });

      if (result.success) {
        const nextStep = getNextStep(OnboardingStepId.SERVICE_AREAS);
        if (nextStep) {
          navigate(`/pro/onboarding/${nextStep}`);
        }
      } else {
        setError('Failed to save service areas. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while saving. Please try again.');
      console.error('Error saving service areas:', err);
    } finally {
      setIsSaving(false);
    }
  };

  if (isDataLoading) {
    return (
      <OnboardingLayout title="Service Areas" description="Loading...">
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title="Service Areas"
      description="Define the areas where you provide your services."
    >
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          {areas?.map((area, areaIndex) => (
            <Paper
              key={areaIndex}
              variant="outlined"
              sx={{ mb: 3, p: 3, position: 'relative' }}
            >
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                {areas.length > 1 && (
                  <IconButton
                    onClick={() => removeServiceArea(areaIndex)}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>

              <Typography variant="h6" gutterBottom>
                Service Area {areaIndex + 1}
              </Typography>

              <Controller
                name={`areas.${areaIndex}.type`}
                control={control}
                render={({ field }) => (
                  <FormControl component="fieldset" sx={{ mb: 3 }}>
                    <FormLabel component="legend">Area Type</FormLabel>
                    <RadioGroup
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);

                        // Reset area-specific fields when type changes
                        const newType = e.target.value;
                        const updatedAreas = [...areas];

                        if (newType === 'locality') {
                          updatedAreas[areaIndex] = {
                            type: 'locality',
                            localities: [''],
                          };
                        } else if (newType === 'radius') {
                          updatedAreas[areaIndex] = {
                            type: 'radius',
                            center: { lat: 0, lng: 0 },
                            radius: 10,
                          };
                        }

                        setValue('areas', updatedAreas);
                      }}
                    >
                      <FormControlLabel
                        value="locality"
                        control={<Radio />}
                        label="Localities"
                        disabled={isSaving}
                      />
                      <FormControlLabel
                        value="radius"
                        control={<Radio />}
                        label="Radius"
                        disabled={isSaving}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />

              <Divider sx={{ my: 2 }} />

              {area.type === 'locality' && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Localities
                  </Typography>

                  <Grid container spacing={2}>
                    {area.localities?.map((locality, localityIndex) => (
                      <Grid item xs={12} sm={6} md={4} key={localityIndex}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Controller
                            name={`areas.${areaIndex}.localities.${localityIndex}`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label={`Locality ${localityIndex + 1}`}
                                fullWidth
                                disabled={isSaving}
                              />
                            )}
                          />

                          <IconButton
                            onClick={() => removeLocality(areaIndex, localityIndex)}
                            color="error"
                            disabled={area.localities?.length === 1}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => addLocality(areaIndex)}
                    sx={{ mt: 2 }}
                    disabled={isSaving}
                  >
                    Add Locality
                  </Button>
                </Box>
              )}

              {area.type === 'radius' && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Radius
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name={`areas.${areaIndex}.center.lat`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Latitude"
                            type="number"
                            fullWidth
                            disabled={isSaving}
                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        name={`areas.${areaIndex}.center.lng`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Longitude"
                            type="number"
                            fullWidth
                            disabled={isSaving}
                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name={`areas.${areaIndex}.radius`}
                        control={control}
                        render={({ field }) => (
                          <Box>
                            <Typography id={`radius-slider-${areaIndex}`} gutterBottom>
                              Radius (km): {field.value || 10}
                            </Typography>
                            <Box sx={{ px: 2 }}>
                              <input
                                type="range"
                                min="1"
                                max="100"
                                step="1"
                                value={field.value || 10}
                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                style={{ width: '100%' }}
                                disabled={isSaving}
                              />
                            </Box>
                          </Box>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          ))}

          <Button
            startIcon={<AddIcon />}
            onClick={addServiceArea}
            sx={{ mb: 3 }}
            disabled={isSaving}
          >
            Add Service Area
          </Button>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleBack} disabled={isSaving}>
              Back
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSaving}
              startIcon={isSaving ? <CircularProgress size={20} /> : null}
            >
              {isSaving ? 'Saving...' : 'Continue'}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </OnboardingLayout>
  );
};

export default ServiceAreasStep;
