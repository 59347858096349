import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import SettingsIcon from '@mui/icons-material/Settings';
import { CalendarProvider } from '../../../contexts/CalendarContext';
import CalendarView from '../../../components/pro/ui/CalendarView';
import WorkingHoursScheduler from '../../../components/pro/ui/WorkingHoursScheduler';
import DateExceptionsManager from '../../../components/pro/ui/DateExceptionsManager';
import CalendarSettings from '../../../components/pro/ui/CalendarSettings';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`calendar-tabpanel-${index}`}
      aria-labelledby={`calendar-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `calendar-tab-${index}`,
    'aria-controls': `calendar-tabpanel-${index}`,
  };
};

const CalendarPage: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <CalendarProvider>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 2 }}
        >
          <Link component={RouterLink} to="/pro/dashboard" color="inherit">
            Dashboard
          </Link>
          <Typography color="text.primary">Calendar</Typography>
        </Breadcrumbs>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Calendar & Availability
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Manage your calendar, working hours, and availability for leads.
          </Typography>
        </Box>

        <Paper sx={{ mb: 4 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="calendar tabs"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab
              icon={<CalendarMonthIcon />}
              label="Calendar"
              iconPosition="start"
              {...a11yProps(0)}
            />
            <Tab
              icon={<AccessTimeIcon />}
              label="Working Hours"
              iconPosition="start"
              {...a11yProps(1)}
            />
            <Tab
              icon={<EventBusyIcon />}
              label="Date Exceptions"
              iconPosition="start"
              {...a11yProps(2)}
            />
            <Tab
              icon={<SettingsIcon />}
              label="Settings"
              iconPosition="start"
              {...a11yProps(3)}
            />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Box sx={{ p: 2 }}>
              <CalendarView />
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ p: 2 }}>
              <WorkingHoursScheduler />
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Box sx={{ p: 2 }}>
              <DateExceptionsManager />
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <Box sx={{ p: 2 }}>
              <CalendarSettings />
            </Box>
          </TabPanel>
        </Paper>
      </Container>
    </CalendarProvider>
  );
};

export default CalendarPage;