import { useState, useEffect } from 'react';
import { debounce } from '../utils/debounce';
import axios from '../services/axios';

interface UseSuggestionsOptions {
  minLength?: number;
  debounceMs?: number;
  maxSuggestions?: number;
}

interface ServiceSuggestion {
  _id: string;
  title: string;
  category?: {
    name: string;
  };
}

interface CategorySuggestion {
  _id: string;
  name: string;
}

interface SuggestionsResponse {
  suggestions: {
    popular: string[];
    categories: CategorySuggestion[];
    services: ServiceSuggestion[];
  };
}

export function useSearchSuggestions(
  query: string,
  options: UseSuggestionsOptions = {}
) {
  const {
    minLength = 2,
    debounceMs = 300,
    maxSuggestions = 5,
  } = options;

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSuggestions = debounce(async (searchQuery: string) => {
      if (!searchQuery || searchQuery.length < minLength) {
        setSuggestions([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get<SuggestionsResponse>(
          `/search/suggestions?q=${encodeURIComponent(searchQuery)}&limit=${maxSuggestions}`
        );

        // Extract suggestions from the complex response
        const allSuggestions: string[] = [];

        // Add popular search suggestions
        if (response.data.suggestions.popular && response.data.suggestions.popular.length > 0) {
          allSuggestions.push(...response.data.suggestions.popular);
        }

        // Add category name suggestions
        if (response.data.suggestions.categories && response.data.suggestions.categories.length > 0) {
          allSuggestions.push(
            ...response.data.suggestions.categories.map(category => category.name)
          );
        }

        // Add service title suggestions
        if (response.data.suggestions.services && response.data.suggestions.services.length > 0) {
          allSuggestions.push(
            ...response.data.suggestions.services.map(service => service.title)
          );
        }

        // Remove duplicates and limit to maxSuggestions
        const uniqueSuggestions = [...new Set(allSuggestions)].slice(0, maxSuggestions);
        setSuggestions(uniqueSuggestions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      } finally {
        setLoading(false);
      }
    }, debounceMs);

    getSuggestions(query);

    return () => {
      setSuggestions([]);
      setLoading(false);
    };
  }, [query, minLength, debounceMs, maxSuggestions]);

  return { suggestions, loading };
}

export default useSearchSuggestions;