  import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';

// Define the types for seller onboarding data
export interface BusinessProfileData {
  businessName: string;
  description: string;
  phone: string;
  logo?: string;
  yearEstablished?: number;
}

export interface ContactInfoData {
  email: string;
  phone: string;
  address: {
    street: string;
    city: string;
    emirate: string;
    country: string;
  };
}

export interface ProductCategoryData {
  categories: string[]; // Category IDs
}

export interface ProductData {
  name: string;
  description: string;
  price: number;
  category: string;
  images: string[];
  inventory: number;
  specifications: {
    [key: string]: any;
  };
}

export interface ShippingInfoData {
  methods: Array<{
    name: string;
    price: number;
    estimatedDelivery: string;
  }>;
  freeShippingThreshold?: number;
  internationalShipping: boolean;
}

export interface PaymentInfoData {
  hasPaymentMethod: boolean;
  stripeAccountId?: string;
  bankDetails: {
    accountName: string;
    iban: string;
    bank: string;
    swift: string;
  };
}

export interface DocumentsData {
  tradeLicense: {
    number: string;
    expiryDate: string;
    document: string;
  };
  vatNumber: string;
}

export interface OnboardingStatus {
  step: number;
  completed: boolean;
  lastUpdated: Date;
}

// Define the context type
interface SellerOnboardingContextType {
  currentStep: number;
  onboardingStatus: OnboardingStatus | null;
  isLoading: boolean;
  error: string | null;
  businessProfile: BusinessProfileData | null;
  contactInfo: ContactInfoData | null;
  productCategories: ProductCategoryData | null;
  products: ProductData[] | null;
  shippingInfo: ShippingInfoData | null;
  paymentInfo: PaymentInfoData | null;
  documents: DocumentsData | null;
  setBusinessProfile: (data: BusinessProfileData) => void;
  setContactInfo: (data: ContactInfoData) => void;
  setProductCategories: (data: ProductCategoryData) => void;
  addProduct: (data: ProductData) => void;
  setShippingInfo: (data: ShippingInfoData) => void;
  setPaymentInfo: (data: PaymentInfoData) => void;
  setDocuments: (data: DocumentsData) => void;
  saveStep: (step: number, data: any) => Promise<boolean>;
  completeOnboarding: () => Promise<boolean>;
  goToStep: (step: number) => void;
  fetchOnboardingStatus: () => Promise<void>;
}

// Create the context with a default value
const SellerOnboardingContext = createContext<SellerOnboardingContextType | undefined>(undefined);

// Provider component
interface SellerOnboardingProviderProps {
  children: ReactNode;
}

export const SellerOnboardingProvider: React.FC<SellerOnboardingProviderProps> = ({ children }) => {
  const { user, getAuthToken } = useAuth();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Step data
  const [businessProfile, setBusinessProfile] = useState<BusinessProfileData | null>(null);
  const [contactInfo, setContactInfo] = useState<ContactInfoData | null>(null);
  const [productCategories, setProductCategories] = useState<ProductCategoryData | null>(null);
  const [products, setProducts] = useState<ProductData[] | null>(null);
  const [shippingInfo, setShippingInfo] = useState<ShippingInfoData | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoData | null>(null);
  const [documents, setDocuments] = useState<DocumentsData | null>(null);

  // Add a product
  const addProduct = (data: ProductData) => {
    setProducts(prev => {
      if (!prev) return [data];

      // Replace if exists, otherwise add
      const exists = prev.findIndex(p => p.name === data.name);
      if (exists >= 0) {
        const updated = [...prev];
        updated[exists] = data;
        return updated;
      }

      return [...prev, data];
    });
  };

  // Fetch onboarding status with timeout
  const fetchOnboardingStatus = async () => {
    if (!user || user.role !== 'seller') return;

    const token = getAuthToken();
    if (!token) return;

    // Set a timeout to prevent infinite loading
    let timeoutId: NodeJS.Timeout | undefined;
    const timeoutPromise = new Promise<void>((_, reject) => {
      timeoutId = setTimeout(() => {
        reject(new Error('Request timed out'));
      }, 10000); // 10 second timeout
    });

    setIsLoading(true);
    setError(null);

    try {
      // Race between the actual request and the timeout
      const requestPromise = axios.get('/api/seller/onboarding/status', {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Use Promise.race to implement timeout
      const response = await Promise.race([
        requestPromise,
        timeoutPromise,
      ]) as any;

      if (timeoutId) clearTimeout(timeoutId);

      // If we have valid data, update the state
      if (response && response.data && response.data.onboardingStatus) {
        setOnboardingStatus(response.data.onboardingStatus);
        setCurrentStep(response.data.onboardingStatus.step);
      } else {
        // If we don't have valid data, use a fallback
        console.warn('Invalid onboarding status response, using fallback');
        setOnboardingStatus({
          step: 0,
          completed: false,
          lastUpdated: new Date(),
        });
        setCurrentStep(0);
      }

    } catch (err: any) {
      // Clear the timeout if there's an error
      if (timeoutId) clearTimeout(timeoutId);

      console.error('Error fetching onboarding status:', err);

      // Set a user-friendly error message
      if (err.message === 'Request timed out') {
        setError('Request timed out. Please try again later.');
      } else {
        setError(err.response?.data?.message || 'Failed to fetch onboarding status');
      }

      // Use fallback data to allow the form to be displayed
      setOnboardingStatus({
        step: 0,
        completed: false,
        lastUpdated: new Date(),
      });
      setCurrentStep(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Save step data with timeout
  const saveStep = async (step: number, data: any): Promise<boolean> => {
    if (!user || user.role !== 'seller') return false;

    const token = getAuthToken();
    if (!token) return false;

    // Set a timeout to prevent infinite loading
    let timeoutId: NodeJS.Timeout | undefined;
    const timeoutPromise = new Promise<void>((_, reject) => {
      timeoutId = setTimeout(() => {
        reject(new Error('Request timed out'));
      }, 10000); // 10 second timeout
    });

    setIsLoading(true);
    setError(null);

    try {
      // Race between the actual request and the timeout
      const requestPromise = axios.post('/api/seller/onboarding/save', {
        step,
        data,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Use Promise.race to implement timeout
      const response = await Promise.race([
        requestPromise,
        timeoutPromise,
      ]) as any;

      if (timeoutId) clearTimeout(timeoutId);

      setOnboardingStatus(response.data.onboardingStatus);
      setCurrentStep(response.data.onboardingStatus.step);
      return true;

    } catch (err: any) {
      // Clear the timeout if there's an error
      if (timeoutId) clearTimeout(timeoutId);

      console.error('Error saving onboarding progress:', err);

      // Set a user-friendly error message
      if (err.message === 'Request timed out') {
        setError('Request timed out. Please try again later.');
      } else {
        setError(err.response?.data?.message || 'Failed to save onboarding progress');
      }

      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // Complete onboarding with timeout
  const completeOnboarding = async (): Promise<boolean> => {
    if (!user || user.role !== 'seller') return false;

    const token = getAuthToken();
    if (!token) return false;

    // Set a timeout to prevent infinite loading
    let timeoutId: NodeJS.Timeout | undefined;
    const timeoutPromise = new Promise<void>((_, reject) => {
      timeoutId = setTimeout(() => {
        reject(new Error('Request timed out'));
      }, 10000); // 10 second timeout
    });

    setIsLoading(true);
    setError(null);

    try {
      // Race between the actual request and the timeout
      const requestPromise = axios.post('/api/seller/onboarding/complete', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Use Promise.race to implement timeout
      const response = await Promise.race([
        requestPromise,
        timeoutPromise,
      ]) as any;

      if (timeoutId) clearTimeout(timeoutId);

      setOnboardingStatus({
        ...response.data.onboardingStatus,
        completed: true,
      });

      return true;

    } catch (err: any) {
      // Clear the timeout if there's an error
      if (timeoutId) clearTimeout(timeoutId);

      console.error('Error completing onboarding:', err);

      // Set a user-friendly error message
      if (err.message === 'Request timed out') {
        setError('Request timed out. Please try again later.');
      } else {
        setError(err.response?.data?.message || 'Failed to complete onboarding');
      }

      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // Navigate to a specific step
  const goToStep = (step: number) => {
    setCurrentStep(step);
    navigate(`/seller/onboarding/${step}`);
  };

  // Fetch onboarding status on mount
  useEffect(() => {
    if (user && user.role === 'seller') {
      fetchOnboardingStatus();
    }
  }, [user]);

  // Redirect to appropriate step if needed
  useEffect(() => {
    if (onboardingStatus) {
      // Only redirect if we're in the onboarding flow
      if (window.location.pathname.includes('/seller/onboarding')) {
        if (onboardingStatus.completed) {
          // If onboarding is completed, redirect to dashboard
          console.log('Onboarding completed, redirecting to dashboard');
          navigate('/seller/dashboard');
        } else {
          // Extract step from URL
          const match = window.location.pathname.match(/\/seller\/onboarding\/(\d+)/);
          const urlStep = match ? parseInt(match[1], 10) : null;

          // If URL step doesn't match current step, redirect to the correct step
          // Only redirect if urlStep is not null (we're on a valid step page)
          // and it doesn't match the current step
          if (urlStep !== null && urlStep !== currentStep) {
            console.log(`URL step (${urlStep}) doesn't match current step (${currentStep}), redirecting`);
            navigate(`/seller/onboarding/${currentStep}`);
          }
        }
      }
    }
  }, [onboardingStatus, currentStep, navigate]);

  const value = {
    currentStep,
    onboardingStatus,
    isLoading,
    error,
    businessProfile,
    contactInfo,
    productCategories,
    products,
    shippingInfo,
    paymentInfo,
    documents,
    setBusinessProfile,
    setContactInfo,
    setProductCategories,
    addProduct,
    setShippingInfo,
    setPaymentInfo,
    setDocuments,
    saveStep,
    completeOnboarding,
    goToStep,
    fetchOnboardingStatus,
  };

  return (
    <SellerOnboardingContext.Provider value={value}>
      {children}
    </SellerOnboardingContext.Provider>
  );
};

// Custom hook to use the seller onboarding context
export const useSellerOnboarding = () => {
  const context = useContext(SellerOnboardingContext);
  if (context === undefined) {
    throw new Error('useSellerOnboarding must be used within a SellerOnboardingProvider');
  }
  return context;
};