import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useOnboarding } from '../../../../contexts/OnboardingContext';
import { useStepForm } from '../../../../hooks/useStepForm';
import { FormTextField } from '../../../../components/forms/FormTextField';
import { availabilitySchema } from '../../../../schemas/onboarding';
import { OnboardingStepId } from '../../../../types/onboarding/shared';
import { useOnboardingStepData } from '../../../../hooks/api/useOnboardingApi';
import { OnboardingLayout } from '../OnboardingLayout';

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

/**
 * Availability step component
 */
const AvailabilityStep: React.FC = () => {
  const navigate = useNavigate();
  const { getNextStep, getPreviousStep } = useOnboarding();
  const { data: stepData, isLoading: isDataLoading } = useOnboardingStepData<any>(
    OnboardingStepId.AVAILABILITY
  );

  const formMethods = useStepForm(
    OnboardingStepId.AVAILABILITY,
    availabilitySchema,
    stepData || {
      businessHours: DAYS_OF_WEEK.map((_, index) => ({
        day: index,
        isOpen: index !== 0, // Closed on Sunday by default
        openTime: '09:00',
        closeTime: '17:00',
      })),
    },
    { autosave: true, autosaveDelay: 2000 }
  );

  // Extract necessary properties from formMethods
  const { handleSubmit, formState, saveForm, isSaving, getValues, setValue, watch } = formMethods;
  const { isValid } = formState;

  // Watch business hours for changes
  const businessHours = watch('businessHours');

  const onSubmit = async (data: any) => {
    const success = await saveForm(data);
    if (success) {
      const nextStep = getNextStep(OnboardingStepId.AVAILABILITY);
      if (nextStep) {
        navigate(`/pro/onboarding/${nextStep}`);
      }
    }
  };

  const handleBack = () => {
    const prevStep = getPreviousStep(OnboardingStepId.AVAILABILITY);
    if (prevStep) {
      navigate(`/pro/onboarding/${prevStep}`);
    }
  };

  const handleToggleDay = (index: number) => {
    const currentHours = getValues('businessHours');
    const updatedHours = [...currentHours];
    updatedHours[index] = {
      ...updatedHours[index],
      isOpen: !updatedHours[index].isOpen,
    };
    setValue('businessHours', updatedHours, { shouldDirty: true, shouldValidate: true });
  };

  if (isDataLoading) {
    return (
      <OnboardingLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title="Business Hours"
      description="Set your regular business hours. This helps customers know when you're available."
    >
      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant="subtitle1" gutterBottom>
            Regular Business Hours
          </Typography>

          <Grid container spacing={2}>
            {businessHours?.map((hour: any, index: number) => (
              <Grid item xs={12} key={index}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  mb: 1,
                  bgcolor: hour.isOpen ? 'background.paper' : 'action.disabledBackground',
                }}>
                  <Box sx={{ width: '30%' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={hour.isOpen}
                          onChange={() => handleToggleDay(index)}
                          color="primary"
                        />
                      }
                      label={DAYS_OF_WEEK[hour.day]}
                    />
                  </Box>

                  {hour.isOpen ? (
                    <>
                      <Box sx={{ width: '30%', mx: 1 }}>
                        <FormTextField
                          name={`businessHours.${index}.openTime`}
                          label="Open"
                          type="time"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          disabled={isSaving || !hour.isOpen}
                        />
                      </Box>
                      <Box sx={{ width: '30%', mx: 1 }}>
                        <FormTextField
                          name={`businessHours.${index}.closeTime`}
                          label="Close"
                          type="time"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          disabled={isSaving || !hour.isOpen}
                        />
                      </Box>
                    </>
                  ) : (
                    <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                      Closed
                    </Typography>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={isSaving}
            >
              Back
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || isSaving}
              startIcon={isSaving ? <CircularProgress size={20} /> : null}
            >
              {isSaving ? 'Saving...' : 'Continue'}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </OnboardingLayout>
  );
};

export default AvailabilityStep;
