import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  Button,
  Divider,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
} from '@mui/material';
import { RootState } from '../../redux/store';

// Mock payment method component
const PaymentMethodCard: React.FC<{
  selected: boolean;
  onClick: () => void;
  title: string;
  description: string;
}> = ({ selected, onClick, title, description }) => (
  <Card
    variant="outlined"
    sx={{
      mb: 2,
      cursor: 'pointer',
      border: selected ? '2px solid #4caf50' : '1px solid #e0e0e0',
      backgroundColor: selected ? 'rgba(76, 175, 80, 0.05)' : 'transparent',
    }}
    onClick={onClick}
  >
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">{description}</Typography>
        </Box>
        <Box sx={{
          width: 20,
          height: 20,
          borderRadius: '50%',
          border: '2px solid #4caf50',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {selected && (
            <Box sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: '#4caf50',
            }} />
          )}
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export const CheckoutPaymentPage: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const cart = useSelector((state: RootState) => state.cart);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    cardName: '',
    expiry: '',
    cvv: '',
  });
  const [saveCard, setSaveCard] = useState(false);

  // Get guest email and shipping info from localStorage if not authenticated
  const guestEmail = !isAuthenticated ? localStorage.getItem('guestEmail') : null;
  const shippingInfoStr = localStorage.getItem('shippingInfo');
  const shippingInfo = shippingInfoStr ? JSON.parse(shippingInfoStr) : null;

  // If cart is empty, redirect to cart page
  if (cart.items.length === 0) {
    return <Navigate to="/marketplace/cart" replace />;
  }

  // If no shipping info, redirect to shipping page
  if (!shippingInfo) {
    return <Navigate to="/marketplace/checkout/shipping" replace />;
  }

  const handleCardInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCardInfo({
      ...cardInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // In a real implementation, we would process the payment
    // For now, we'll just simulate a delay and then navigate to the confirmation page
    setTimeout(() => {
      // Store order info in localStorage
      const orderInfo = {
        orderId: 'ORD' + Math.floor(Math.random() * 1000000),
        items: cart.items,
        subtotal: cart.subtotal,
        tax: cart.tax,
        total: cart.total,
        shippingInfo,
        paymentMethod,
        date: new Date().toISOString(),
      };
      localStorage.setItem('orderInfo', JSON.stringify(orderInfo));
      navigate('/marketplace/checkout/confirmation');
      setLoading(false);
    }, 2000);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Payment
      </Typography>

      <Grid container spacing={4}>
        {/* Left Column - Payment Form */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 4 }}>
            <Box component="form" onSubmit={handleSubmit}>
              <Typography variant="h6" gutterBottom>
                Payment Method
              </Typography>

              <PaymentMethodCard
                selected={paymentMethod === 'card'}
                onClick={() => setPaymentMethod('card')}
                title="Credit / Debit Card"
                description="Pay with Visa, Mastercard, or American Express"
              />

              <PaymentMethodCard
                selected={paymentMethod === 'cod'}
                onClick={() => setPaymentMethod('cod')}
                title="Cash on Delivery"
                description="Pay when you receive your order"
              />

              {paymentMethod === 'card' && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Card Details
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Card Number"
                        name="cardNumber"
                        value={cardInfo.cardNumber}
                        onChange={handleCardInfoChange}
                        placeholder="1234 5678 9012 3456"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Name on Card"
                        name="cardName"
                        value={cardInfo.cardName}
                        onChange={handleCardInfoChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Expiry Date"
                        name="expiry"
                        value={cardInfo.expiry}
                        onChange={handleCardInfoChange}
                        placeholder="MM/YY"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="CVV"
                        name="cvv"
                        value={cardInfo.cvv}
                        onChange={handleCardInfoChange}
                        placeholder="123"
                      />
                    </Grid>

                    {isAuthenticated && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={saveCard}
                              onChange={(e) => setSaveCard(e.target.checked)}
                              color="primary"
                            />
                          }
                          label="Save card for future purchases"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}

              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Billing Address
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  {shippingInfo.name}<br />
                  {shippingInfo.street}<br />
                  {shippingInfo.city}, {shippingInfo.emirate}<br />
                  {shippingInfo.country}
                </Typography>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disabled={loading || (paymentMethod === 'card' && (!cardInfo.cardNumber || !cardInfo.cardName || !cardInfo.expiry || !cardInfo.cvv))}
                sx={{
                  py: 1.5,
                  mt: 4,
                  backgroundColor: '#333',
                  '&:hover': {
                    backgroundColor: '#000',
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  `Place Order - ${cart.total.toFixed(2)} AED`
                )}
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Right Column - Order Summary */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 4 }}>
            <Typography variant="h6" gutterBottom>
              Order Summary
            </Typography>

            <Box sx={{ my: 2 }}>
              {cart.items.map((item) => (
                <Box key={item.product._id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">
                    {item.quantity} x {item.product.name}
                  </Typography>
                  <Typography variant="body2">
                    ${(item.product.price.amount * item.quantity).toFixed(2)}
                  </Typography>
                </Box>
              ))}

              <Divider sx={{ my: 2 }} />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body1">Subtotal</Typography>
                <Typography variant="body1">${cart.subtotal.toFixed(2)}</Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body1">Shipping</Typography>
                <Typography variant="body1">Free</Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body1">Tax (5% VAT)</Typography>
                <Typography variant="body1">${cart.tax.toFixed(2)}</Typography>
              </Box>

              <Divider sx={{ my: 2 }} />

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6" color="primary">
                  ${cart.total.toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Typography variant="subtitle1" gutterBottom>
                Shipping To
              </Typography>

              <Typography variant="body2" color="text.secondary">
                {shippingInfo.name}<br />
                {shippingInfo.street}<br />
                {shippingInfo.city}, {shippingInfo.emirate}<br />
                {shippingInfo.country}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckoutPaymentPage;