import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';
import ProjectCard from '../../components/ideas/ProjectCard';
import { ideaService, IdeaProject, FilterOptions } from '../../services/ideaService';

const ProviderProjectsPage: React.FC = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<IdeaProject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Filter options
  const [styles, setStyles] = useState<string[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [costRanges, setCostRanges] = useState<string[]>([]);
  const [roomTypes, setRoomTypes] = useState<string[]>([]);

  // Selected filters
  const [selectedStyle, setSelectedStyle] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedCost, setSelectedCost] = useState<string>('');
  const [selectedRoomType, setSelectedRoomType] = useState<string>('');

  // Fetch filter options
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const [stylesData, yearsData, costRangesData, roomTypesData] = await Promise.all([
          ideaService.getStyles(),
          ideaService.getYears(),
          ideaService.getCostRanges(),
          ideaService.getRoomTypes(),
        ]);

        setStyles(stylesData);
        setYears(yearsData);
        setCostRanges(costRangesData);
        setRoomTypes(roomTypesData);
      } catch (err) {
        console.error('Error fetching filter options:', err);
        setError('Failed to load filter options. Please try again later.');
      }
    };

    fetchFilterOptions();
  }, []);

  // Fetch projects with filters
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      setError(null);

      try {
        const filters: FilterOptions = {};

        if (selectedStyle) filters.style = selectedStyle;
        if (selectedYear) filters.year = selectedYear;
        if (selectedCost) filters.cost = selectedCost;
        if (selectedRoomType) filters.roomType = selectedRoomType;

        const projectsData = await ideaService.getProviderProjects(filters);
        setProjects(projectsData);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('Failed to load projects. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [selectedStyle, selectedYear, selectedCost, selectedRoomType]);

  // Handle filter changes
  const handleStyleChange = (event: SelectChangeEvent) => {
    setSelectedStyle(event.target.value);
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value);
  };

  const handleCostChange = (event: SelectChangeEvent) => {
    setSelectedCost(event.target.value);
  };

  const handleRoomTypeChange = (event: SelectChangeEvent) => {
    setSelectedRoomType(event.target.value);
  };

  // Handle project click
  const handleProjectClick = (projectId: string) => {
    navigate(`/ideas/provider-projects/${projectId}`);
  };

  // Handle save to ideabook
  const handleSaveProject = (projectId: string) => {
    // TODO: Implement save to ideabook functionality
    console.log('Save project:', projectId);
  };

  // Handle share
  const handleShareProject = (projectId: string) => {
    // TODO: Implement share functionality
    console.log('Share project:', projectId);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="provider-projects" />

      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{ mb: 2 }}
      >
        Provider Projects
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        sx={{ mb: 4 }}
      >
        Discover real projects from our verified service providers
      </Typography>

      {/* Filters */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4, flexWrap: 'wrap' }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="room-type-filter-label">Room Type</InputLabel>
          <Select
            labelId="room-type-filter-label"
            id="room-type-filter"
            value={selectedRoomType}
            label="Room Type"
            onChange={handleRoomTypeChange}
          >
            <MenuItem value="">All Room Types</MenuItem>
            {roomTypes.map((roomType) => (
              <MenuItem key={roomType} value={roomType}>
                {roomType === 'kitchen' ? 'Kitchen' :
                 roomType === 'bathroom' ? 'Bathroom' :
                 roomType === 'living-room' ? 'Living Room' :
                 roomType === 'bedroom' ? 'Bedroom' :
                 roomType === 'outdoor' ? 'Outdoor' :
                 roomType === 'home-office' ? 'Home Office' :
                 roomType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="style-filter-label">Style</InputLabel>
          <Select
            labelId="style-filter-label"
            id="style-filter"
            value={selectedStyle}
            label="Style"
            onChange={handleStyleChange}
          >
            <MenuItem value="">All Styles</MenuItem>
            {styles.map((style) => (
              <MenuItem key={style} value={style}>{style}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="year-filter-label">Year</InputLabel>
          <Select
            labelId="year-filter-label"
            id="year-filter"
            value={selectedYear}
            label="Year"
            onChange={handleYearChange}
          >
            <MenuItem value="">All Years</MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="cost-filter-label">Cost Range</InputLabel>
          <Select
            labelId="cost-filter-label"
            id="cost-filter"
            value={selectedCost}
            label="Cost Range"
            onChange={handleCostChange}
          >
            <MenuItem value="">All Cost Ranges</MenuItem>
            {costRanges.map((cost) => (
              <MenuItem key={cost} value={cost}>{cost}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Error message */}
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {/* Loading indicator */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Projects grid */}
          {projects.length > 0 ? (
            <Grid container spacing={4}>
              {projects.map((project) => (
                <Grid item xs={12} sm={6} md={4} key={project.id}>
                  <ProjectCard
                    id={project.id}
                    image={project.image}
                    title={project.title}
                    designer={project.designer}
                    likes={project.likes}
                    onClick={() => handleProjectClick(project.id)}
                    onSave={() => handleSaveProject(project.id)}
                    onShare={() => handleShareProject(project.id)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box sx={{ textAlign: 'center', my: 8 }}>
              <Typography variant="h6" color="text.secondary">
                No projects found matching your criteria.
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                Try adjusting your filters or check back later for new projects.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default ProviderProjectsPage;