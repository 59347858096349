import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  TextField,
  CircularProgress,
  Alert,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../hooks/useAuth';

// Mock data for ideabooks - in a real app, this would come from an API
const mockIdeabooks = [
  { id: '1', name: 'Dream Home', itemCount: 24, coverImage: 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' },
  { id: '2', name: 'Kitchen Ideas', itemCount: 12, coverImage: 'https://images.pexels.com/photos/2724749/pexels-photo-2724749.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1' },
  { id: '3', name: 'Bathroom Renovation', itemCount: 8, coverImage: 'https://images.pexels.com/photos/1457847/pexels-photo-1457847.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1' },
];

export interface SaveToIdeabookModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectToSave: {
    id: string;
    title: string;
    image: string;
  } | null;
  onSave: (ideabookId: string, projectId: string) => void;
  isLoading?: boolean;
  error?: string | null;
}

const SaveToIdeabookModal: React.FC<SaveToIdeabookModalProps> = ({
  isOpen,
  onClose,
  projectToSave,
  onSave,
  isLoading = false,
  error = null,
}) => {
  const { isAuthenticated } = useAuth();
  const [ideabooks, setIdeabooks] = useState<any[]>([]);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newIdeabookName, setNewIdeabookName] = useState('');
  const [newIdeabookError, setNewIdeabookError] = useState<string | null>(null);

  useEffect(() => {
    // In a real app, this would be an API call
    setIdeabooks(mockIdeabooks);
  }, []);

  const handleCreateNewIdeabook = () => {
    if (!newIdeabookName.trim()) {
      setNewIdeabookError('Please enter a name for your ideabook');
      return;
    }

    // In a real app, this would be an API call to create a new ideabook
    const newId = `new-${Date.now()}`;
    const newIdeabook = {
      id: newId,
      name: newIdeabookName,
      itemCount: 0,
      coverImage: projectToSave?.image || 'https://via.placeholder.com/150',
    };

    setIdeabooks([...ideabooks, newIdeabook]);
    setIsCreatingNew(false);
    setNewIdeabookName('');

    // Save to the newly created ideabook
    if (projectToSave) {
      onSave(newId, projectToSave.id);
    }
  };

  if (!isAuthenticated || !projectToSave) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="save-to-ideabook-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="save-to-ideabook-dialog-title">
        Save to Ideabook
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            {projectToSave.title}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box
                component="img"
                src={projectToSave.image}
                alt={projectToSave.title}
                sx={{
                  width: '100%',
                  aspectRatio: '1/1',
                  objectFit: 'cover',
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="text.secondary">
                Select an existing ideabook or create a new one to save this idea.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {isCreatingNew ? (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Create New Ideabook
            </Typography>
            <TextField
              fullWidth
              label="Ideabook Name"
              variant="outlined"
              value={newIdeabookName}
              onChange={(e) => {
                setNewIdeabookName(e.target.value);
                if (newIdeabookError) setNewIdeabookError(null);
              }}
              error={!!newIdeabookError}
              helperText={newIdeabookError}
              autoFocus
              sx={{ mb: 2 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsCreatingNew(false);
                  setNewIdeabookName('');
                  setNewIdeabookError(null);
                }}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleCreateNewIdeabook}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Create & Save'}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Your Ideabooks
            </Typography>

            <List sx={{ mb: 2 }}>
              {ideabooks.map((ideabook) => (
                <React.Fragment key={ideabook.id}>
                  <ListItem
                    button
                    onClick={() => {
                      if (projectToSave) {
                        onSave(ideabook.id, projectToSave.id);
                      }
                    }}
                    disabled={isLoading}
                  >
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        src={ideabook.coverImage}
                        alt={ideabook.name}
                      >
                        <CollectionsBookmarkIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={ideabook.name}
                      secondary={`${ideabook.itemCount} items`}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>

            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setIsCreatingNew(true)}
              fullWidth
            >
              Create New Ideabook
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveToIdeabookModal;