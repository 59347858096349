/**
 * SearchCache utility for caching and managing search results
 * Implements LRU (Least Recently Used) caching strategy
 */

interface SearchParams {
  query?: string;
  category?: string;
  priceRange?: [number, number];
  pricingType?: string;
  rating?: number;
  sortBy?: string;
  page?: number;
  location?: {
    lat: number;
    lng: number;
    radius: number;
  };
}

export class SearchCache {
  private maxSize: number;
  private cache: Map<string, any>;
  private expiryTimes: Map<string, number>;
  private defaultTTL: number;

  constructor(maxSize = 50) {
    this.maxSize = maxSize;
    this.cache = new Map();
    this.expiryTimes = new Map();
    this.defaultTTL = 5 * 60 * 1000; // 5 minutes
  }

  /**
   * Generate a cache key from search parameters
   */
  generateKey(params: SearchParams): string {
    const {
      query = '',
      category = 'all',
      priceRange = [0, 1000],
      pricingType = 'all',
      rating = 0,
      sortBy = 'relevance',
      page = 1,
      location = null,
    } = params;

    return JSON.stringify({
      q: query.toLowerCase().trim(),
      cat: category,
      price: priceRange,
      type: pricingType,
      rate: rating,
      sort: sortBy,
      p: page,
      loc: location ? `${location.lat},${location.lng},${location.radius}` : null,
    });
  }

  /**
   * Set a cache entry with optional TTL
   */
  set(key: string, value: any, ttl = this.defaultTTL): void {
    // Remove oldest entry if cache is full
    if (this.cache.size >= this.maxSize) {
      const oldestKey = this.cache.keys().next().value;
      if (oldestKey) {
        this.cache.delete(oldestKey);
        this.expiryTimes.delete(oldestKey);
      }
    }

    this.cache.set(key, value);
    this.expiryTimes.set(key, Date.now() + ttl);

    // Schedule cleanup
    setTimeout(() => this.delete(key), ttl);
  }

  /**
   * Get a cache entry if it exists and is not expired
   */
  get(key: string): any | null {
    if (!this.cache.has(key)) return null;

    const expiryTime = this.expiryTimes.get(key);
    if (!expiryTime || Date.now() > expiryTime) {
      this.delete(key);
      return null;
    }

    // Move entry to end (most recently used)
    const value = this.cache.get(key);
    this.cache.delete(key);
    this.cache.set(key, value);

    return value;
  }

  /**
   * Delete a cache entry
   */
  delete(key: string): void {
    this.cache.delete(key);
    this.expiryTimes.delete(key);
  }

  /**
   * Clear all cache entries
   */
  clear(): void {
    this.cache.clear();
    this.expiryTimes.clear();
  }

  /**
   * Get cache size
   */
  size(): number {
    return this.cache.size;
  }

  /**
   * Check if key exists in cache and is not expired
   */
  has(key: string): boolean {
    if (!this.cache.has(key)) return false;

    const expiryTime = this.expiryTimes.get(key);
    if (!expiryTime || Date.now() > expiryTime) {
      this.delete(key);
      return false;
    }

    return true;
  }

  /**
   * Prefetch search results for common variations of the current search
   */
  async prefetch(currentParams: SearchParams, fetchFn: (params: SearchParams) => Promise<any>): Promise<void> {
    const variations = this.generateVariations(currentParams);

    for (const params of variations) {
      const key = this.generateKey(params);
      if (!this.has(key)) {
        try {
          const results = await fetchFn(params);
          this.set(key, results);
        } catch (error) {
          console.error('Prefetch failed:', error);
        }
      }
    }
  }

  /**
   * Generate common variations of search parameters for prefetching
   */
  private generateVariations(params: SearchParams): SearchParams[] {
    const variations: SearchParams[] = [];
    const { page = 1 } = params;

    // Next page
    if (page < 5) {
      // Only prefetch up to page 5
      variations.push({ ...params, page: page + 1 });
    }

    // Different sort options
    const sortOptions = ['relevance', 'rating', 'price_low', 'price_high'];
    sortOptions.forEach((sortBy) => {
      if (sortBy !== params.sortBy) {
        variations.push({ ...params, sortBy, page: 1 });
      }
    });

    // Different price ranges
    if (params.priceRange) {
      const [min, max] = params.priceRange;
      const midpoint = (min + max) / 2;
      variations.push({
        ...params,
        priceRange: [min, midpoint],
        page: 1,
      });
      variations.push({
        ...params,
        priceRange: [midpoint, max],
        page: 1,
      });
    }

    return variations;
  }
}

// Create singleton instance
const searchCache = new SearchCache();

export default searchCache;
