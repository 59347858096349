import React from 'react';
import { Box, Tabs, Tab, useTheme, useMediaQuery, Menu, MenuItem } from '@mui/material';
import {
  PhotoLibrary as IdeabookIcon,
  ShoppingCart as ProductListIcon,
  Handyman as ProListIcon,
  Calculate as CalculatorIcon,
  CheckBox as ChecklistIcon,
  MoreHoriz as MoreIcon,
  Work as ProjectsIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/store';

interface IdeasNavigationProps {
  activeSection?: string;
}

const IdeasNavigation: React.FC<IdeasNavigationProps> = ({ activeSection = 'ideabooks' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useSelector((state: RootState) => state.auth);

  // If user is not authenticated, don't show the navigation
  if (!user) {
    return null;
  }

  const menuItems = [
    {
      label: 'Ideabooks',
      icon: <IdeabookIcon fontSize="small" />,
      path: '/ideas/ideabooks',
      section: 'ideabooks',
    },
    {
      label: 'Provider Projects',
      icon: <ProjectsIcon fontSize="small" />,
      path: '/ideas/provider-projects',
      section: 'provider-projects',
    },
    {
      label: 'Product List',
      icon: <ProductListIcon fontSize="small" />,
      path: '/ideas/products-list',
      section: 'product-list',
    },
    {
      label: 'Pro List',
      icon: <ProListIcon fontSize="small" />,
      path: '/ideas/pro-list',
      section: 'pro-finder',
    },
    {
      label: 'Cost Calculator',
      icon: <CalculatorIcon fontSize="small" />,
      path: '/ideas/calculator',
      section: 'calculator',
    },
    {
      label: 'Project Checklist',
      icon: <ChecklistIcon fontSize="small" />,
      path: '/ideas/checklist',
      section: 'checklist',
    },
  ];

  // For mobile view, show only the first 3 items and put the rest in a "More" dropdown
  const visibleItems = isMobile ? menuItems.slice(0, 3) : menuItems;
  const moreItems = isMobile ? menuItems.slice(3) : [];

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    handleMoreClose();
  };

  return (
    <Box sx={{ width: '100%', mb: 3, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={activeSection}
        onChange={(_, newValue) => {
          const item = menuItems.find((item) => item.section === newValue);
          if (item) navigate(item.path);
        }}
        variant={isMobile ? 'scrollable' : 'standard'}
        scrollButtons="auto"
        allowScrollButtonsMobile
        aria-label="ideas navigation tabs"
      >
        {visibleItems.map((item) => (
          <Tab
            key={item.section}
            value={item.section}
            label={item.label}
            icon={item.icon}
            iconPosition="start"
          />
        ))}

        {isMobile && moreItems.length > 0 && (
          <Tab
            icon={<MoreIcon />}
            aria-owns={moreAnchorEl ? 'more-menu' : undefined}
            aria-haspopup="true"
            onClick={handleMoreClick}
            label="More"
            iconPosition="start"
          />
        )}
      </Tabs>

      <Menu
        id="more-menu"
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={handleMoreClose}
      >
        {moreItems.map((item) => (
          <MenuItem
            key={item.section}
            onClick={() => handleNavigate(item.path)}
            selected={activeSection === item.section}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 1 }}>{item.icon}</Box>
              {item.label}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default IdeasNavigation;
