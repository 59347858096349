import axios from './axios';

interface PreferenceOption {
  _id: string;
  optionText: string;
  value: string;
}

interface PreferenceQuestion {
  _id: string;
  questionText: string;
  questionType: 'single_select' | 'multi_select' | 'text';
  options: PreferenceOption[];
  isRequired: boolean;
  displayOrder: number;
}

interface ServicePreferenceTemplate {
  _id: string;
  service?: string;
  category?: string;
  questions: PreferenceQuestion[];
  isActive: boolean;
}

interface ProviderCapability {
  questionId: string;
  supportedOptions: string[];
}

interface CustomerPreference {
  questionId: string;
  selectedOptions: string[];
  customAnswer?: string;
}

/**
 * Get the preference template for a service
 * @param serviceId The ID of the service
 * @returns The service preference template
 */
export const getServicePreferenceTemplate = async (serviceId: string): Promise<ServicePreferenceTemplate> => {
  try {
    const response = await axios.get(`/target-preferences/templates/service/${serviceId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching service preference template:', error);
    throw error;
  }
};

/**
 * Get the preference template for a category
 * @param categoryId The ID of the category
 * @returns The category preference template
 */
export const getCategoryPreferenceTemplate = async (categoryId: string): Promise<ServicePreferenceTemplate> => {
  try {
    const response = await axios.get(`/target-preferences/templates/category/${categoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category preference template:', error);
    throw error;
  }
};

/**
 * Get the provider's capabilities for a service
 * @param serviceId The ID of the service
 * @returns The provider's capabilities
 */
export const getProviderCapabilities = async (serviceId: string): Promise<ProviderCapability[]> => {
  try {
    const response = await axios.get(`/target-preferences/provider/capabilities/service/${serviceId}`);
    return response.data.capabilities || [];
  } catch (error) {
    console.error('Error fetching provider capabilities:', error);
    throw error;
  }
};

/**
 * Update the provider's capabilities for a service
 * @param serviceId The ID of the service
 * @param capabilities The provider's capabilities
 * @returns The updated provider profile
 */
export const updateProviderCapabilities = async (serviceId: string, capabilities: ProviderCapability[]): Promise<any> => {
  try {
    console.log('updateProviderCapabilities called with:', { serviceId, capabilities });

    // Get token from localStorage
    const token = localStorage.getItem('token');
    console.log('Token from localStorage:', token ? token.substring(0, 10) + '...' : 'null');

    // Make the request with explicit headers
    const response = await axios.post(`/target-preferences/provider/capabilities`, {
      serviceId,
      capabilities,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('updateProviderCapabilities response:', response.data);
    return response.data;
  } catch (error: any) {
    console.error('Error updating provider capabilities:', error);
    if (error.response) {
      console.error('Error response:', error.response.status, error.response.data);
    }
    throw error;
  }
};

/**
 * Submit customer preferences for a lead
 * @param requestId The ID of the lead
 * @param preferences The customer's preferences
 * @returns The updated lead with matched providers
 */
export const submitCustomerPreferences = async (requestId: string, preferences: CustomerPreference[]): Promise<any> => {
  try {
    // For lead preferences, we don't need authentication token
    const response = await axios.post(`/target-preferences/customer/leads/${requestId}/preferences`, {
      preferences,
    });
    return response.data;
  } catch (error: any) {
    console.error('Error submitting customer preferences:', error);
    // Provide more specific error message based on the error
    if (error.response) {
      if (error.response.status === 404) {
        throw new Error('Lead not found. Please check the lead ID and try again.');
      } else if (error.response.status === 400) {
        throw new Error('Invalid preference data. Please check your inputs and try again.');
      } else {
        throw new Error(`Server error: ${error.response.data?.message || 'Unknown error'}`);
      }
    } else if (error.request) {
      throw new Error('Network error. Please check your connection and try again.');
    } else {
      throw error;
    }
  }
};

/**
 * Get matched providers for a lead
 * @param requestId The ID of the lead
 * @returns The matched providers
 */
export const getMatchedProviders = async (requestId: string): Promise<any[]> => {
  try {
    // For matched providers, we don't need authentication token
    const response = await axios.get(`/target-preferences/customer/leads/${requestId}/matched-providers`);
    return response.data;
  } catch (error: any) {
    console.error('Error fetching matched providers:', error);
    // Provide more specific error message based on the error
    if (error.response) {
      if (error.response.status === 404) {
        throw new Error('Lead not found. Please check the lead ID and try again.');
      } else {
        throw new Error(`Server error: ${error.response.data?.message || 'Unknown error'}`);
      }
    } else if (error.request) {
      throw new Error('Network error. Please check your connection and try again.');
    } else {
      throw error;
    }
  }
};

export default {
  getServicePreferenceTemplate,
  getCategoryPreferenceTemplate,
  getProviderCapabilities,
  updateProviderCapabilities,
  submitCustomerPreferences,
  getMatchedProviders,
};