import { Box } from '@mui/material';
import PageTitle from '../components/common/PageTitle';

// Import enhanced homepage components
import EnhancedHeroSection from '../components/home/EnhancedHeroSection';
import NearbyProfessionals from '../components/home/NearbyProfessionals';
import RoomBrowser from '../components/home/RoomBrowser';
import DepartmentShop from '../components/home/DepartmentShop';
import FeaturedProjects from '../components/home/FeaturedProjects';
import SeasonalSuggestions from '../components/home/SeasonalSuggestions';
import Testimonials from '../components/home/Testimonials';
import EnhancedCTA from '../components/home/EnhancedCTA';

// Mock categories data matching ICategory interface
const mockCategories = [
  {
    _id: '1',
    name: 'Home Cleaning',
    description: 'Professional home cleaning services',
    icon: '/logo192.png',
    isActive: true,
    order: 1,
    slug: 'home-cleaning',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    _id: '2',
    name: 'Plumbing',
    description: 'Expert plumbing services',
    icon: '/logo192.png',
    isActive: true,
    order: 2,
    slug: 'plumbing',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    _id: '3',
    name: 'Electrical',
    description: 'Professional electrical services',
    icon: '/logo192.png',
    isActive: true,
    order: 3,
    slug: 'electrical',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    _id: '4',
    name: 'Painting',
    description: 'Professional painting services',
    icon: '/logo192.png',
    isActive: true,
    order: 4,
    slug: 'painting',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    _id: '5',
    name: 'Landscaping',
    description: 'Professional landscaping services',
    icon: '/logo192.png',
    isActive: true,
    order: 5,
    slug: 'landscaping',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    _id: '6',
    name: 'Renovation',
    description: 'Complete home renovation services',
    icon: '/logo192.png',
    isActive: true,
    order: 6,
    slug: 'renovation',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
];

const Home = () => {
  return (
    <Box>
      <PageTitle
        title="Home"
        description="Homezy - Your one-stop solution for all home improvement needs. Find services, products, and inspiration to make your home better."
      />
      {/* Enhanced Hero Section */}
      <EnhancedHeroSection categories={mockCategories} />

      {/* Nearby Professionals Section */}
      <NearbyProfessionals />

      {/* Room Browser Section */}
      <RoomBrowser />

      {/* Featured Projects Section */}
      <FeaturedProjects />

      {/* Department Shop Section */}
      <DepartmentShop />

      {/* Seasonal Suggestions Section */}
      <SeasonalSuggestions />

      {/* Testimonials Section */}
      <Testimonials />

      {/* Enhanced CTA Section */}
      <EnhancedCTA />
    </Box>
  );
};

export default Home;
