import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from '../../services/axios';
import { OnboardingStepId, OnboardingStatus, OnboardingData } from '../../types/onboarding/shared';

/**
 * Hook to fetch onboarding status
 */
export const useOnboardingStatus = () => {
  return useQuery<OnboardingStatus, Error>(
    'onboardingStatus',
    async () => {
      const response = await axios.get('/onboarding/status');
      return response.data.onboardingStatus;
    },
    {
      staleTime: 60000, // 1 minute
      retry: 2,
      refetchOnWindowFocus: true,
    }
  );
};

/**
 * Hook to fetch data for a specific onboarding step
 */
export const useOnboardingStepData = <T>(stepId: OnboardingStepId) => {
  return useQuery<T, Error>(
    ['stepData', stepId],
    async () => {
      const response = await axios.get(`/onboarding/step/${stepId}`);
      return response.data.data;
    },
    {
      staleTime: 60000, // 1 minute
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
};

/**
 * Hook to save data for a specific onboarding step
 */
export const useSaveStep = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { success: boolean; onboardingStatus: OnboardingStatus },
    Error,
    { stepId: OnboardingStepId; data: any }
  >(
    async ({ stepId, data }) => {
      const response = await axios.post(`/onboarding/step/${stepId}`, { data });
      return response.data;
    },
    {
      // When mutate is called:
      onMutate: async ({ stepId, data }) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries('onboardingStatus');
        await queryClient.cancelQueries(['stepData', stepId]);

        // Snapshot the previous values
        const previousStatus = queryClient.getQueryData<OnboardingStatus>('onboardingStatus');
        const previousData = queryClient.getQueryData<any>(['stepData', stepId]);

        // Optimistically update
        queryClient.setQueryData(['stepData', stepId], data);

        // Return a context object with the snapshotted value
        return { previousStatus, previousData };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context: any) => {
        if (context?.previousStatus) {
          queryClient.setQueryData('onboardingStatus', context.previousStatus);
        }
        if (context?.previousData) {
          queryClient.setQueryData(['stepData', variables.stepId], context.previousData);
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries('onboardingStatus');
        queryClient.invalidateQueries(['stepData', variables.stepId]);
      },
    }
  );
};

/**
 * Hook to complete the onboarding process
 */
export const useCompleteOnboarding = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { success: boolean; onboardingStatus: OnboardingStatus },
    Error
  >(
    async () => {
      const response = await axios.post('/onboarding/complete');
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('onboardingStatus');
      },
    }
  );
};

/**
 * Hook to fetch all onboarding data at once
 */
export const useAllOnboardingData = () => {
  return useQuery<OnboardingData, Error>(
    'allOnboardingData',
    async () => {
      const response = await axios.get('/onboarding/data');
      return response.data.data;
    },
    {
      staleTime: 60000, // 1 minute
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
};