import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  Chip,
  Paper,
} from '@mui/material';
import {
  Search as SearchIcon,
  Calculate as CalculateIcon,
  Brush as BrushIcon,
  Kitchen as KitchenIcon,
  Bathtub as BathtubIcon,
  Pool as PoolIcon,
  AcUnit as AcUnitIcon,
  Deck as DeckIcon,
  ElectricalServices as ElectricalIcon,
  Plumbing as PlumbingIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import PageTitle from '../../../components/common/PageTitle';

// Mock data for estimators
const MOCK_ESTIMATORS = [
  {
    id: 'kitchen-renovation',
    title: 'Kitchen Renovation Cost Calculator',
    description: 'Estimate the cost of your kitchen renovation based on your specific requirements and preferences.',
    icon: <KitchenIcon fontSize="large" />,
    category: 'Interior',
    popularityScore: 95,
    estimatedTime: '2-3 min',
    featured: true,
  },
  {
    id: 'home-painting',
    title: 'Home Painting Cost Estimator',
    description: 'Calculate how much it will cost to paint your home interior or exterior in the UAE.',
    icon: <BrushIcon fontSize="large" />,
    category: 'Interior',
    popularityScore: 90,
    estimatedTime: '1-2 min',
    featured: true,
  },
  {
    id: 'bathroom-remodel',
    title: 'Bathroom Remodeling Calculator',
    description: 'Plan your bathroom renovation budget with our detailed cost estimator tailored for UAE standards.',
    icon: <BathtubIcon fontSize="large" />,
    category: 'Interior',
    popularityScore: 85,
    estimatedTime: '2-3 min',
    featured: false,
  },
  {
    id: 'ac-installation',
    title: 'AC Installation & Maintenance Calculator',
    description: 'Estimate costs for installing or maintaining air conditioning systems in the UAE climate.',
    icon: <AcUnitIcon fontSize="large" />,
    category: 'Systems',
    popularityScore: 88,
    estimatedTime: '1-2 min',
    featured: false,
  },
  {
    id: 'swimming-pool',
    title: 'Swimming Pool Installation Estimator',
    description: 'Calculate the cost of installing a swimming pool in your UAE property with ongoing maintenance costs.',
    icon: <PoolIcon fontSize="large" />,
    category: 'Exterior',
    popularityScore: 75,
    estimatedTime: '3-4 min',
    featured: false,
  },
  {
    id: 'flooring-installation',
    title: 'Flooring Installation Calculator',
    description: 'Estimate costs for different flooring options available in the UAE market.',
    icon: <DeckIcon fontSize="large" />,
    category: 'Interior',
    popularityScore: 80,
    estimatedTime: '2 min',
    featured: false,
  },
  {
    id: 'electrical-work',
    title: 'Electrical Work Cost Estimator',
    description: 'Calculate the cost of electrical upgrades, installations, and repairs for your UAE home.',
    icon: <ElectricalIcon fontSize="large" />,
    category: 'Systems',
    popularityScore: 70,
    estimatedTime: '2-3 min',
    featured: false,
  },
  {
    id: 'plumbing-work',
    title: 'Plumbing Work Cost Calculator',
    description: 'Estimate plumbing costs for repairs, installations, and upgrades in UAE properties.',
    icon: <PlumbingIcon fontSize="large" />,
    category: 'Systems',
    popularityScore: 72,
    estimatedTime: '2 min',
    featured: false,
  },
];

// Categories for filtering
const CATEGORIES = ['All', 'Interior', 'Exterior', 'Systems'];

/**
 * EstimatorsIndex component displays a list of cost estimator tools
 * with filtering capabilities
 */
const EstimatorsIndex: React.FC = () => {
  // State for filtering
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('All');

  // Filter estimators based on search term and category
  const filteredEstimators = MOCK_ESTIMATORS.filter((estimator) => {
    const matchesSearch = estimator.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         estimator.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = activeCategory === 'All' || estimator.category === activeCategory;
    return matchesSearch && matchesCategory;
  });

  // Featured estimators (for the top section)
  const featuredEstimators = MOCK_ESTIMATORS.filter(estimator => estimator.featured);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <PageTitle
        title="Cost Estimators"
        description="Calculate the cost of your home improvement projects with our UAE-specific cost estimators."
        keywords="cost calculator, renovation cost, UAE home improvement, Dubai renovation cost, Abu Dhabi home cost"
        locale="en_AE"
      />

      {/* Introduction Section */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          mb: 6,
          borderRadius: 2,
          backgroundImage: 'linear-gradient(to right, #f5f7fa, #e4e8f0)',
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="h1" gutterBottom>
              UAE Home Improvement Cost Estimators
            </Typography>
            <Typography variant="body1" paragraph>
              Plan your budget with confidence using our cost calculators specifically calibrated for the UAE market.
              Our estimators consider local material costs, labor rates, and regional factors to provide you with
              accurate estimates for your home improvement projects.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <InfoIcon color="primary" />
              <Typography variant="body2" color="text.secondary">
                All estimates are based on current UAE market rates and updated quarterly.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CalculateIcon sx={{ fontSize: 120, color: 'primary.light', opacity: 0.8 }} />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Search and Filter Section */}
      <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search for estimators..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 3 }}
        />

        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {CATEGORIES.map((category) => (
            <Chip
              key={category}
              label={category}
              onClick={() => setActiveCategory(category)}
              color={activeCategory === category ? 'primary' : 'default'}
              variant={activeCategory === category ? 'filled' : 'outlined'}
            />
          ))}
        </Box>
      </Box>

      {/* Featured Estimators Section */}
      {featuredEstimators.length > 0 && (
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Popular Estimators
          </Typography>
          <Grid container spacing={3}>
            {featuredEstimators.map((estimator) => (
              <Grid item xs={12} md={6} key={estimator.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderTop: 4,
                    borderColor: 'primary.main',
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box
                        sx={{
                          p: 1.5,
                          borderRadius: '50%',
                          bgcolor: 'primary.light',
                          color: 'primary.contrastText',
                          mr: 2,
                        }}
                      >
                        {estimator.icon}
                      </Box>
                      <Typography variant="h5" component="h3">
                        {estimator.title}
                      </Typography>
                    </Box>

                    <Typography variant="body1" paragraph>
                      {estimator.description}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Chip
                          label={estimator.category}
                          size="small"
                          color="primary"
                          variant="outlined"
                        />
                        <Typography variant="caption" color="text.secondary">
                          {estimator.estimatedTime}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/resources/estimators/${estimator.id}`}
                      >
                        Start Estimating
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* All Estimators Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          All Estimators {activeCategory !== 'All' && `in ${activeCategory}`}
        </Typography>

        {filteredEstimators.length === 0 ? (
          <Box sx={{ py: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              No estimators found matching your criteria.
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setSearchTerm('');
                setActiveCategory('All');
              }}
              sx={{ mt: 2 }}
            >
              Clear Filters
            </Button>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {filteredEstimators
              .filter(estimator => !estimator.featured || activeCategory !== 'All' || searchTerm)
              .map((estimator) => (
                <Grid item xs={12} sm={6} md={4} key={estimator.id}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Box
                          sx={{
                            p: 1,
                            borderRadius: '50%',
                            bgcolor: 'grey.100',
                            color: 'primary.main',
                            mr: 2,
                          }}
                        >
                          {estimator.icon}
                        </Box>
                        <Typography variant="h6" component="h3">
                          {estimator.title}
                        </Typography>
                      </Box>

                      <Typography variant="body2" color="text.secondary" paragraph>
                        {estimator.description}
                      </Typography>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <Typography variant="caption" color="text.secondary">
                          {estimator.estimatedTime}
                        </Typography>
                        <Button
                          variant="outlined"
                          size="small"
                          component={Link}
                          to={`/resources/estimators/${estimator.id}`}
                        >
                          Open Calculator
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
      </Box>

      {/* How It Works Section */}
      <Paper sx={{ p: 3, mb: 6, borderRadius: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          How Our Estimators Work
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Box sx={{
                  width: 50,
                  height: 50,
                  borderRadius: '50%',
                  bgcolor: 'primary.main',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}>
                  1
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Enter Your Details
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Provide information about your project, such as dimensions, materials, and preferences.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Box sx={{
                  width: 50,
                  height: 50,
                  borderRadius: '50%',
                  bgcolor: 'primary.main',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}>
                  2
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Get Your Estimate
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our calculator processes your inputs using UAE-specific pricing data to generate an accurate estimate.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Box sx={{
                  width: 50,
                  height: 50,
                  borderRadius: '50%',
                  bgcolor: 'primary.main',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}>
                  3
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Connect with Professionals
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Save your estimate and connect with qualified professionals who can help with your project.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Call to Action */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Need a Custom Estimate?
        </Typography>
        <Typography variant="body1" paragraph>
          Our calculators provide general estimates. For a detailed quote tailored to your specific project, connect with our verified professionals.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/find-professionals"
          sx={{ mt: 1 }}
        >
          Get a Custom Quote
        </Button>
      </Paper>
    </>
  );
};

export default EstimatorsIndex;