import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import axios from '../../services/axios';

const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const verifyEmail = async () => {
      // Get token from URL query params
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');

      if (!token) {
        setError('Verification token is missing');
        setLoading(false);
        return;
      }

      try {
        // Call the API to verify the email
        const response = await axios.post('/auth/verify-email', { token });
        setSuccess(true);
      } catch (err: any) {
        console.error('Error verifying email:', err);
        setError(err.response?.data?.message || 'Failed to verify email');
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [location.search]);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleResendVerification = () => {
    navigate('/auth/resend-verification');
  };

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Email Verification
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          {loading ? (
            <CircularProgress />
          ) : success ? (
            <Alert severity="success" sx={{ width: '100%' }}>
              Your email has been successfully verified! You can now log in to your account.
            </Alert>
          ) : (
            <Alert severity="error" sx={{ width: '100%' }}>
              {error || 'Failed to verify your email. Please try again.'}
            </Alert>
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
          {success ? (
            <Button variant="contained" color="primary" onClick={handleLogin}>
              Log In
            </Button>
          ) : (
            <>
              <Button variant="outlined" onClick={handleResendVerification}>
                Resend Verification Email
              </Button>
              <Button variant="contained" color="primary" onClick={handleLogin}>
                Back to Login
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default VerifyEmail;