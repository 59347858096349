import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardMedia,
  Divider,
  Chip,
  Rating,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfessionalSearchBar from '../../components/search/ProfessionalSearchBar';
import ProfessionalFilters, { FilterOptions } from '../../components/search/ProfessionalFilters';
import {
  searchServices,
  fetchServiceCategories,
  selectSearchResults,
  selectSearchLoading,
  selectSearchError,
} from '../../redux/slices/searchSlice';
import { AppDispatch } from '../../redux/store';
import { SearchParams } from '../../types/search';
import { IProviderWithServices, IProviderProfile } from '../../types/models';

// Mock data for popular service categories
const POPULAR_CATEGORIES = [
  {
    name: 'Home Remodeling',
    count: 245,
    image:
      'https://images.pexels.com/photos/4352247/pexels-photo-4352247.jpeg?auto=compress&cs=tinysrgb&w=300&h=200&dpr=1',
  },
  {
    name: 'Kitchen Renovation',
    count: 189,
    image:
      'https://images.pexels.com/photos/1599791/pexels-photo-1599791.jpeg?auto=compress&cs=tinysrgb&w=300&h=200&dpr=1',
  },
  {
    name: 'Bathroom Remodeling',
    count: 156,
    image:
      'https://images.pexels.com/photos/1457847/pexels-photo-1457847.jpeg?auto=compress&cs=tinysrgb&w=300&h=200&dpr=1',
  },
  {
    name: 'Plumbing',
    count: 132,
    image:
      'https://images.pexels.com/photos/4115195/pexels-photo-4115195.jpeg?auto=compress&cs=tinysrgb&w=300&h=200&dpr=1',
  },
  {
    name: 'Electrical',
    count: 118,
    image:
      'https://images.pexels.com/photos/8005368/pexels-photo-8005368.jpeg?auto=compress&cs=tinysrgb&w=300&h=200&dpr=1',
  },
  {
    name: 'Painting',
    count: 97,
    image:
      'https://images.pexels.com/photos/6474475/pexels-photo-6474475.jpeg?auto=compress&cs=tinysrgb&w=300&h=200&dpr=1',
  },
];

const FindProfessionals: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Parse URL parameters
  const searchParams = new URLSearchParams(location.search);
  const serviceQuery = searchParams.get('service') || '';
  const emirate = searchParams.get('emirate') || '';

  // Redux state
  const results = useSelector(selectSearchResults);
  const loading = useSelector(selectSearchLoading);
  const error = useSelector(selectSearchError);

  // Local state
  const [hasSearched, setHasSearched] = useState(false);
  const [filters, setFilters] = useState<FilterOptions>({});
  const [showFilters, setShowFilters] = useState(false);

  // Load categories on mount
  useEffect(() => {
    dispatch(fetchServiceCategories());
  }, [dispatch]);

  // Perform search if URL has parameters
  useEffect(() => {
    if (serviceQuery || emirate) {
      const searchParams: SearchParams = {
        query: serviceQuery,
        location: emirate
          ? {
              address: emirate,
              coordinates: [0, 0] as [number, number], // Placeholder coordinates
              radius: 50, // Default radius
            }
          : undefined,
      };

      dispatch(searchServices(searchParams));
      setHasSearched(true);
    }
  }, [dispatch, serviceQuery, emirate]);

  // Handle filter changes
  const handleFilterChange = (newFilters: FilterOptions) => {
    setFilters(newFilters);

    // Apply filters to search results
    const searchParams: SearchParams = {
      query: serviceQuery,
      location: emirate
        ? {
            address: emirate,
            coordinates: [0, 0] as [number, number],
            radius: 50,
          }
        : undefined,
      filters: newFilters,
    };

    dispatch(searchServices(searchParams));
  };

  // Handle search submission
  const handleSearch = (params: SearchParams) => {
    // Update URL with search parameters
    const urlParams = new URLSearchParams();
    if (params.query) urlParams.set('service', params.query);
    if (params.location?.address) urlParams.set('emirate', params.location.address);

    navigate({ search: urlParams.toString() });

    // Reset filters when performing a new search
    setFilters({});

    // Perform search with filters
    const searchParamsWithFilters: SearchParams = {
      ...params,
      filters: filters,
    };
    dispatch(searchServices(searchParamsWithFilters));
    setHasSearched(true);

    // Show filters on mobile after search
    if (isMobile) {
      setShowFilters(true);
    }
  };

  // Render a professional card
  const renderProfessionalCard = (providerProfile: IProviderWithServices) => {
    // Add null checks to prevent errors
    if (!providerProfile) return null;

    const services = providerProfile.services || [];
    const businessName = providerProfile.businessName || 'Professional Service';
    const hasRating = providerProfile.rating && providerProfile.rating > 0;
    const rating = providerProfile.rating || 0;
    const reviewCount = providerProfile.reviewCount || 0;
    // Use any available job-related data or default to 0
    const completedJobs =
      (providerProfile as any).completedJobs ||
      (providerProfile as any).jobsCompleted ||
      (providerProfile as any).completedProjects ||
      0;

    // Get rating text based on rating value
    const getRatingText = (rating: number) => {
      if (rating >= 4.5) return 'Excellent';
      if (rating >= 4.0) return 'Great';
      if (rating >= 3.5) return 'Good';
      if (rating >= 3.0) return 'Average';
      return 'Fair';
    };

    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRadius: 2,
          transition: 'box-shadow 0.2s',
          '&:hover': {
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
          },
          overflow: 'hidden',
          pt: { xs: 3, sm: 4 },
          px: { xs: 2, sm: 3 },
          pb: { xs: 2, sm: 3 },
          position: 'relative',
          maxHeight: '450px',
        }}
      >
        {/* Verified badge at top right */}
        <Chip
          label="Verified"
          color="primary"
          size="small"
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            backgroundColor: theme.palette.success.main,
            color: '#fff',
            fontWeight: 600,
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
            zIndex: 0,
            '& .MuiChip-label': {
              px: 1,
              fontSize: '0.7rem',
            },
          }}
        />
        {/* First row: Logo, Company name, and Reviews */}
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, alignItems: 'center' }}>
          {/* Company logo - left side */}
          <Box sx={{ display: 'flex', alignItems: 'center', mr: { xs: 2, sm: 3 } }}>
            <Box
              sx={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                overflow: 'hidden',
                border: '3px solid white',
                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.12)',
                position: 'relative',
                backgroundColor: '#f5f5f5',
                flexShrink: 0,
              }}
            >
              <CardMedia
                component="img"
                height="100%"
                image={
                  providerProfile.avatar ||
                  (typeof services[0] !== 'string' &&
                    services[0]?.images &&
                    services[0].images[0]) ||
                  `https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=300&h=300&dpr=1`
                }
                alt={businessName}
                sx={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
          </Box>

          {/* Company name and Rating - right side of first row */}
          <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}
          >
            {/* Company name */}
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontWeight: 700,
                color: theme.palette.text.primary,
                mb: 0.75,
                lineHeight: 1.2,
              }}
            >
              {businessName}
            </Typography>

            {/* Rating */}
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {hasRating ? (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#4CAF50',
                      fontWeight: 600,
                      mr: 1,
                    }}
                  >
                    {getRatingText(rating)} {rating.toFixed(1)}
                  </Typography>
                  <Rating
                    value={rating}
                    precision={0.5}
                    readOnly
                    size="small"
                    sx={{
                      '& .MuiRating-iconFilled': {
                        color: theme.palette.warning.main,
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      ml: 1,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    ({reviewCount})
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.disabled,
                    fontStyle: 'italic',
                  }}
                >
                  (No reviews yet)
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        {/* Second row: All other content */}
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {/* Stats with icons */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.75, mb: 1.5 }}>
            {/* Jobs completed */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="span"
                sx={{
                  mr: 1.5,
                  color: theme.palette.text.secondary,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 24,
                }}
              >
                🏆
              </Box>
              <Typography variant="body2" color="text.secondary">
                {completedJobs > 0 ? `${completedJobs} jobs completed` : 'New provider'}
              </Typography>
            </Box>

            {/* Location */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnIcon
                fontSize="small"
                sx={{
                  color: theme.palette.text.secondary,
                  mr: 1.5,
                  fontSize: '1.2rem',
                  width: 24,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  fontStyle: providerProfile.location?.address ? 'normal' : 'italic',
                }}
              >
                {providerProfile.location?.address
                  ? `Serves ${providerProfile.location.address}`
                  : 'Location not provided'}
              </Typography>
            </Box>

            {/* Response time */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="span"
                sx={{
                  mr: 1.5,
                  color: theme.palette.text.secondary,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 24,
                }}
              >
                💬
              </Box>
              <Typography variant="body2" color="text.secondary">
                Responds within <strong>a day</strong>
              </Typography>
            </Box>
          </Box>

          {/* Services offered */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                mb: 0.75,
              }}
            >
              Services Offered:
            </Typography>
            <Box>
              {services.map((service, index) => {
                // Handle both string and IService types
                const serviceId = typeof service === 'string' ? service : service._id;
                const serviceTitle =
                  typeof service === 'string'
                    ? `Service ${index + 1}`
                    : service.title || `Service ${index + 1}`;

                // Use the providerId field directly from the provider profile
                const providerId = providerProfile.providerId;

                // Only proceed if we have a valid providerId
                if (!providerId) {
                  return null; // Skip rendering this service chip
                }

                return (
                  <Chip
                    key={index}
                    label={serviceTitle}
                    size="small"
                    sx={{
                      mr: 0.75,
                      mb: 0.75,
                      borderRadius: '16px',
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.contrastText,
                      fontWeight: 500,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    onClick={() =>
                      providerId &&
                      serviceId &&
                      handleServiceSelect(providerId.toString(), serviceId.toString())
                    }
                  />
                );
              })}
              {services.length === 0 && (
                <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                  No services listed
                </Typography>
              )}
            </Box>
          </Box>

          {/* Action buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 'auto',
              width: '100%',
              pt: 0.5,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: '8px',
                py: 0.75,
                px: { xs: 1.5, sm: 2 },
                fontWeight: 600,
                borderWidth: '1.5px',
                flex: 1,
                mr: 1,
                fontSize: { xs: '0.8rem', sm: '0.875rem' },
                '&:hover': {
                  borderWidth: '1.5px',
                  backgroundColor: 'rgba(33, 150, 243, 0.08)',
                },
              }}
              onClick={() => {
                // Use the providerId field directly from the provider profile
                const providerId = providerProfile.providerId;

                // Only navigate if we have a valid providerId
                if (providerId) {
                  navigate(`/pro/${providerId}`);
                } else {
                  // Show an error message to the user
                  alert('Unable to view provider profile. Please try again later.');
                }
              }}
            >
              View Profile
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: '8px',
                py: 0.75,
                px: { xs: 1.5, sm: 2 },
                fontWeight: 600,
                flex: 1,
                fontSize: { xs: '0.8rem', sm: '0.875rem' },
                boxShadow: '0 2px 8px rgba(33, 150, 243, 0.25)',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(33, 150, 243, 0.35)',
                },
              }}
              onClick={() => {
                // Use the providerId field directly from the provider profile
                const providerId = providerProfile.providerId;

                // Only proceed if we have a valid providerId
                if (!providerId) {
                  // Show an error message to the user
                  alert('Unable to send message to provider. Please try again later.');
                  return;
                }

                // If there are services, show a dialog to select a service
                if (services.length > 0) {
                  // For now, just use the first service
                  const service = services[0];
                  const serviceId = typeof service === 'string' ? service : service._id;

                  if (serviceId) {
                    handleServiceSelect(providerId.toString(), serviceId.toString());
                  } else {
                    navigate(`/services/enhanced-lead?providerId=${providerId}`);
                  }
                } else {
                  // Fallback to using just the provider ID if no services
                  navigate(`/services/enhanced-lead?providerId=${providerId}`);
                }
              }}
            >
              Send Message
            </Button>
          </Box>
        </Box>
      </Card>
    );
  };

  // Handler for service selection
  const handleServiceSelect = (providerId: string, serviceId: string) => {
    // Validate that we have a proper database ID (not a generated fallback)
    if (!providerId || providerId.startsWith('fallback-') || providerId.startsWith('provider-')) {
      alert('Unable to create lead with this provider. Please try again later.');
      return;
    }

    if (providerId && serviceId) {
      navigate(`/services/${serviceId}/enhanced-lead?providerId=${providerId}`);
    } else if (providerId) {
      navigate(`/services/enhanced-lead?providerId=${providerId}`);
    } else {
      alert('Unable to create lead. Please try again later.');
    }
  };

  return (
    <Box sx={{ width: '100%', py: 4 }}>
      {/* Hero Section */}
      <Box sx={{ mb: 6, textAlign: 'center', maxWidth: '1200px', mx: 'auto' }}>
        <Typography variant="h3" component="h1" gutterBottom fontWeight="bold">
          Find Trusted Professionals
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Connect with top-rated service providers in your area
        </Typography>

        {/* Search Bar */}
        <Box sx={{ mt: 4 }}>
          <ProfessionalSearchBar
            onSearch={handleSearch}
            initialService={serviceQuery}
            initialLocation={emirate}
          />
        </Box>
      </Box>

      {/* Search Results with Filters */}
      {hasSearched && (
        <Grid container spacing={3} sx={{ maxWidth: '1400px', mx: 'auto' }}>
          {/* Filters Sidebar */}
          <Grid item xs={12} md={3} lg={2.5}>
            <Box
              sx={{
                display: { xs: showFilters ? 'block' : 'none', md: 'block' },
                position: { xs: 'fixed', md: 'relative' },
                top: { xs: 0, md: 'auto' },
                left: { xs: 0, md: 'auto' },
                width: { xs: '100%', md: 'auto' },
                height: { xs: '100vh', md: 'auto' },
                zIndex: { xs: 1200, md: 1 },
                bgcolor: 'background.paper',
                p: 2,
                overflowY: 'auto',
              }}
            >
              {isMobile && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                  <Button variant="outlined" size="small" onClick={() => setShowFilters(false)}>
                    Close
                  </Button>
                </Box>
              )}
              <ProfessionalFilters
                onFilterChange={handleFilterChange}
                initialFilters={filters}
                serviceType={serviceQuery}
              />
            </Box>

            {/* Mobile Filter Toggle Button */}
            {isMobile && !showFilters && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setShowFilters(true)}
                sx={{ mb: 2 }}
              >
                Show Filters
              </Button>
            )}
          </Grid>

          {/* Results */}
          <Grid item xs={12} md={9} lg={9.5}>
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" gutterBottom fontWeight="bold">
                {loading
                  ? 'Searching for Professionals...'
                  : `${results.length} Professional${results.length === 1 ? '' : 's'} Found`}
              </Typography>

              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                  <CircularProgress />
                </Box>
              ) : results.length > 0 ? (
                <Grid container spacing={6}>
                  {results.map((providerProfile: IProviderProfile, index) => {
                    // Skip rendering if the provider profile is completely invalid
                    if (!providerProfile) {
                      return null;
                    }

                    // Use providerId as the primary identifier if _id is not available
                    if (!providerProfile._id && !providerProfile.providerId) {
                      return null;
                    }

                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        key={providerProfile._id || providerProfile.providerId || index}
                        sx={{ mb: 4 }}
                      >
                        <Box sx={{ height: '100%' }}>
                          {renderProfessionalCard(providerProfile as IProviderWithServices)}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    No professionals found matching your criteria
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Try adjusting your search or filters, or browse our popular categories below
                  </Typography>
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Popular Categories Section */}
      {(!hasSearched || results.length === 0) && (
        <Box sx={{ mb: 6, maxWidth: '1400px', mx: 'auto' }}>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            Popular Service Categories
          </Typography>
          <Grid container spacing={3}>
            {POPULAR_CATEGORIES.map((category, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    position: 'relative',
                    height: 200,
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: 6,
                    },
                  }}
                  onClick={() => handleSearch({ query: category.name })}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={category.image}
                    alt={category.name}
                    sx={{ filter: 'brightness(0.7)' }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      bgcolor: 'rgba(0, 0, 0, 0.6)',
                      color: 'white',
                      padding: 2,
                    }}
                  >
                    <Typography variant="h6" component="div">
                      {category.name}
                    </Typography>
                    <Typography variant="body2">{category.count} professionals</Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* How It Works Section */}
      <Box sx={{ mb: 6, maxWidth: '1400px', mx: 'auto' }}>
        <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
          How It Works
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                1. Search
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Enter the service you need and your location to find qualified professionals
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                2. Compare
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Review profiles, ratings, and previous work to find the right match
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                3. Connect
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Contact professionals directly and get your project started
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FindProfessionals;
