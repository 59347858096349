import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Paper,
  CircularProgress,
  Alert,
  Button,
  Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviderProfile } from '../../redux/slices/providerSlice';
import { RootState } from '../../redux/store';
import { AppDispatch } from '../../redux/store';
import BusinessInfoForm from '../../components/pro/forms/BusinessInfoForm';
import ServiceAreasManager from '../../components/pro/forms/ServiceAreasManager';
import WorkingHoursScheduler from '../../components/pro/forms/WorkingHoursScheduler';
import DocumentationUploader from '../../components/pro/forms/DocumentationUploader';
import ProjectsManager from '../../components/pro/ui/ProjectsManager';
import ReviewsTabWrapper from '../../components/pro/ui/ReviewsTabWrapper';

interface TabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
  [key: string]: any;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`profile-tabpanel-${index}`}
    aria-labelledby={`profile-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
  </div>
);

const a11yProps = (index: number) => ({
  id: `profile-tab-${index}`,
  'aria-controls': `profile-tabpanel-${index}`,
});

const ProProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, loading, error } = useSelector((state: RootState) => state.provider);
  const [activeTab, setActiveTab] = useState(0);
  const [googleAuthStatus, setGoogleAuthStatus] = useState<{
    success: boolean;
    error: string | null;
  }>({
    success: false,
    error: null,
  });
  const [profileStatus, setProfileStatus] = useState<{
    isComplete: boolean;
    missingItems: string[];
  }>({
    isComplete: false,
    missingItems: [],
  });

  const handleViewPublicProfile = () => {
    if (profile && profile._id) {
      window.open(`/pro/${profile._id}`, '_blank');
    }
  };

  // Check for Google OAuth callback parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const googleConnected = params.get('google_connected');
    const googleError = params.get('google_error');

    if (googleConnected === 'true') {
      setGoogleAuthStatus({
        success: true,
        error: null,
      });
      // Set active tab to Reviews (assuming it's tab index 4)
      setActiveTab(4);
      // Remove the query parameters from the URL
      navigate('/pro/profile', { replace: true });
    } else if (googleError) {
      setGoogleAuthStatus({
        success: false,
        error: decodeURIComponent(googleError),
      });
      // Set active tab to Reviews (assuming it's tab index 4)
      setActiveTab(4);
      // Remove the query parameters from the URL
      navigate('/pro/profile', { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    dispatch(fetchProviderProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const missingItems = [];

      // Check business info
      if (!profile.businessName || !profile.businessDescription) {
        missingItems.push('Business Information');
      }

      // Check service areas
      if (!profile.serviceAreas || profile.serviceAreas.length === 0) {
        missingItems.push('Service Areas');
      }

      // Check working hours
      if (!profile.workingHours || profile.workingHours.length === 0) {
        missingItems.push('Working Hours');
      }

      setProfileStatus({
        isComplete: missingItems.length === 0,
        missingItems,
      });
    }
  }, [profile]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (loading && !profile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button variant="contained" onClick={() => dispatch(fetchProviderProfile())}>
          Retry
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {googleAuthStatus.success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Google Business Profile connected successfully! You can now import your reviews.
        </Alert>
      )}

      {googleAuthStatus.error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Failed to connect Google Business Profile: {googleAuthStatus.error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Tooltip title="View how your profile appears to customers">
          <Button
            variant="outlined"
            color="primary"
            startIcon={<VisibilityIcon />}
            onClick={handleViewPublicProfile}
            disabled={!profile || !profile._id}
          >
            View Public Profile
          </Button>
        </Tooltip>
      </Box>

      {!profileStatus.isComplete && (
        <Alert severity="warning" sx={{ mb: 4 }}>
          <Typography variant="subtitle1" gutterBottom>
            Please complete your profile to start receiving service requests
          </Typography>
          {profileStatus.missingItems.length > 0 && (
            <Typography variant="body2">
              Missing information: {profileStatus.missingItems.join(', ')}
            </Typography>
          )}
        </Alert>
      )}

      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab label="Business Info" {...a11yProps(0)} />
          <Tab label="Service Areas" {...a11yProps(1)} />
          <Tab label="Working Hours" {...a11yProps(2)} />
          <Tab label="Documentation" {...a11yProps(3)} />
          <Tab label="Projects" {...a11yProps(4)} />
          <Tab label="Reviews" {...a11yProps(5)} />
        </Tabs>

        <Box sx={{ px: 3 }}>
          <TabPanel value={activeTab} index={0}>
            <BusinessInfoForm />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <ServiceAreasManager />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <WorkingHoursScheduler />
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <DocumentationUploader />
          </TabPanel>
          <TabPanel value={activeTab} index={4}>
            <ProjectsManager />
          </TabPanel>
          <TabPanel value={activeTab} index={5}>
            <ReviewsTabWrapper providerId={profile?._id} />
          </TabPanel>
        </Box>
      </Paper>

      {profileStatus.isComplete && (
        <Alert severity="success">
          <Typography variant="subtitle1">
            Your profile is complete! You can now receive service requests.
          </Typography>
        </Alert>
      )}
    </Container>
  );
};

export default ProProfile;