import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
  useTheme,
  useMediaQuery,
  CardActionArea,
  Chip,
  Skeleton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EventIcon from '@mui/icons-material/Event';
import imageLoader from '../../utils/imageLoader';

// Helper function to determine current season
const getCurrentSeason = (): 'spring' | 'summer' | 'fall' | 'winter' => {
  const now = new Date();
  const month = now.getMonth();

  if (month >= 2 && month <= 4) return 'spring';
  if (month >= 5 && month <= 7) return 'summer';
  if (month >= 8 && month <= 10) return 'fall';
  return 'winter';
};

// Suggestion data structure
interface Suggestion {
  id: string;
  title: string;
  description: string;
  image: string;
  category: string;
  priority: 'high' | 'medium' | 'low';
  imageAttribution?: {
    name: string;
    link: string;
  };
}

// Season data structure
interface SeasonData {
  title: string;
  description: string;
  image: string;
  suggestions: Suggestion[];
  imageAttribution?: {
    name: string;
    link: string;
  };
}

// Fallback image in case API fails
const FALLBACK_IMAGE = 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

// Initial seasonal data - images will be loaded from Pexels API
const seasonalData: Record<string, SeasonData> = {
  spring: {
    title: 'Spring Home Improvement',
    description: 'Get your home ready for the warmer months with these spring projects and maintenance tasks.',
    image: '',
    suggestions: [
      {
        id: 's1',
        title: 'Exterior Painting',
        description: 'Spring is the perfect time to refresh your home\'s exterior with a new coat of paint.',
        image: '',
        category: 'painting',
        priority: 'high',
      },
      {
        id: 's2',
        title: 'Landscape Refresh',
        description: 'Revitalize your garden with new plants, mulch, and seasonal flowers.',
        image: '',
        category: 'landscaping',
        priority: 'medium',
      },
      {
        id: 's3',
        title: 'Gutter Cleaning',
        description: 'Clear winter debris from gutters to prevent water damage during spring rains.',
        image: '',
        category: 'maintenance',
        priority: 'high',
      },
      {
        id: 's4',
        title: 'Deck Restoration',
        description: 'Clean, repair, and reseal your deck for the outdoor entertaining season.',
        image: '',
        category: 'outdoor',
        priority: 'medium',
      },
    ],
  },
  summer: {
    title: 'Summer Home Improvement',
    description: 'Make the most of the warm weather with these summer projects and upgrades.',
    image: '',
    suggestions: [
      {
        id: 's1',
        title: 'Outdoor Kitchen',
        description: 'Create an outdoor cooking and dining space for summer entertaining.',
        image: '',
        category: 'outdoor',
        priority: 'medium',
      },
      {
        id: 's2',
        title: 'HVAC Maintenance',
        description: 'Ensure your air conditioning system is running efficiently for the hot months ahead.',
        image: '',
        category: 'maintenance',
        priority: 'high',
      },
      {
        id: 's3',
        title: 'Window Replacement',
        description: 'Upgrade to energy-efficient windows to keep your home cool and reduce utility bills.',
        image: '',
        category: 'renovation',
        priority: 'medium',
      },
      {
        id: 's4',
        title: 'Pool Installation',
        description: 'Beat the heat with a new swimming pool or spa for your backyard.',
        image: '',
        category: 'outdoor',
        priority: 'low',
      },
    ],
  },
  fall: {
    title: 'Fall Home Improvement',
    description: 'Prepare your home for the cooler months with these essential fall projects.',
    image: '',
    suggestions: [
      {
        id: 's1',
        title: 'Heating System Tune-up',
        description: 'Ensure your furnace or heating system is ready for the cold weather ahead.',
        image: '',
        category: 'maintenance',
        priority: 'high',
      },
      {
        id: 's2',
        title: 'Roof Inspection',
        description: 'Check for and repair any roof damage before winter weather arrives.',
        image: '',
        category: 'maintenance',
        priority: 'high',
      },
      {
        id: 's3',
        title: 'Weatherproofing',
        description: 'Seal windows and doors to prevent drafts and improve energy efficiency.',
        image: '',
        category: 'maintenance',
        priority: 'medium',
      },
      {
        id: 's4',
        title: 'Fireplace Maintenance',
        description: 'Clean and inspect your fireplace or wood stove for safe operation during winter.',
        image: '',
        category: 'maintenance',
        priority: 'medium',
      },
    ],
  },
  winter: {
    title: 'Winter Home Improvement',
    description: 'Make the most of the indoor season with these winter home improvement projects.',
    image: '',
    suggestions: [
      {
        id: 's1',
        title: 'Kitchen Remodeling',
        description: 'Winter is the perfect time for indoor projects like updating your kitchen.',
        image: '',
        category: 'renovation',
        priority: 'medium',
      },
      {
        id: 's2',
        title: 'Bathroom Renovation',
        description: 'Upgrade your bathroom with new fixtures, tile, and modern amenities.',
        image: '',
        category: 'renovation',
        priority: 'medium',
      },
      {
        id: 's3',
        title: 'Insulation Upgrade',
        description: 'Improve your home\'s energy efficiency with better insulation in attics and walls.',
        image: '',
        category: 'maintenance',
        priority: 'high',
      },
      {
        id: 's4',
        title: 'Interior Painting',
        description: 'Refresh your living spaces with new paint colors during the indoor months.',
        image: '',
        category: 'painting',
        priority: 'low',
      },
    ],
  },
};

const SeasonalSuggestions: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine current season
  const currentSeason = getCurrentSeason();
  const [seasonData, setSeasonData] = useState<SeasonData>(seasonalData[currentSeason]);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch images from Pexels API
  useEffect(() => {
    const fetchSeasonalImages = async () => {
      try {
        // Create a copy of the season data to update
        const updatedSeasonData = { ...seasonData };

        // Fetch main season image
        try {
          const seasonImageResult = await imageLoader.getRandomPexelsImage(`${currentSeason} season`, {
            orientation: 'landscape',
            size: 'large',
          });

          if (seasonImageResult) {
            updatedSeasonData.image = seasonImageResult.url;
            updatedSeasonData.imageAttribution = {
              name: seasonImageResult.photographer,
              link: seasonImageResult.photographerUrl,
            };
          } else {
            updatedSeasonData.image = FALLBACK_IMAGE;
            updatedSeasonData.imageAttribution = {
              name: 'Pexels',
              link: 'https://www.pexels.com',
            };
          }
        } catch (error) {
          console.error(`Error fetching main season image:`, error);
          updatedSeasonData.image = FALLBACK_IMAGE;
          updatedSeasonData.imageAttribution = {
            name: 'Pexels',
            link: 'https://www.pexels.com',
          };
        }

        // Fetch images for each suggestion in parallel
        const updatedSuggestions = [...updatedSeasonData.suggestions];
        const promises = updatedSuggestions.map(async (suggestion, index) => {
          try {
            // Get a random image for the suggestion category
            const imageResult = await imageLoader.getRandomPexelsImage(`${suggestion.title} ${suggestion.category}`, {
              orientation: 'landscape',
              size: 'large',
            });

            if (imageResult) {
              // Update the suggestion with the image data
              updatedSuggestions[index] = {
                ...suggestion,
                image: imageResult.url,
                imageAttribution: {
                  name: imageResult.photographer,
                  link: imageResult.photographerUrl,
                },
              };
            } else {
              // Use fallback image if no results
              updatedSuggestions[index] = {
                ...suggestion,
                image: FALLBACK_IMAGE,
                imageAttribution: {
                  name: 'Pexels',
                  link: 'https://www.pexels.com',
                },
              };
            }
          } catch (error) {
            console.error(`Error fetching image for ${suggestion.id}:`, error);
            // Use fallback image on error
            updatedSuggestions[index] = {
              ...suggestion,
              image: FALLBACK_IMAGE,
              imageAttribution: {
                name: 'Pexels',
                link: 'https://www.pexels.com',
              },
            };
          }
        });

        // Wait for all image fetches to complete
        await Promise.all(promises);

        // Update the suggestions in the season data
        updatedSeasonData.suggestions = updatedSuggestions;

        // Update state with the new season data
        setSeasonData(updatedSeasonData);
      } catch (error) {
        console.error('Error fetching seasonal images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSeasonalImages();
  }, [currentSeason]);

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4, flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
          <Typography variant="h3" component="h2" gutterBottom={isMobile}>
            {seasonData.title}
          </Typography>
          <Button
            variant="text"
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate('/ideas/seasonal')}
          >
            View All Seasonal Projects
          </Button>
        </Box>

        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={{ mb: 6, maxWidth: 800 }}
        >
          {seasonData.description}
        </Typography>

        <Grid container spacing={3}>
          {seasonData.suggestions.map((suggestion) => (
            <Grid item xs={12} sm={6} md={3} key={suggestion.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                  },
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <CardActionArea onClick={() => navigate(`/ideas/seasonal/${suggestion.id}`)}>
                  <Box sx={{ position: 'relative' }}>
                    {loading ? (
                      <Skeleton variant="rectangular" height={180} animation="wave" />
                    ) : (
                      <CardMedia
                        component="img"
                        height="180"
                        image={suggestion.image || FALLBACK_IMAGE}
                        alt={suggestion.title}
                        sx={{
                          transition: 'transform 0.5s ease, opacity 0.3s ease, filter 0.3s ease',
                          opacity: loading ? 0.7 : 1,
                          filter: loading ? 'blur(5px)' : 'none',
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 12,
                        right: 12,
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      <Chip
                        label={suggestion.priority === 'high' ? 'Priority' : suggestion.priority === 'medium' ? 'Recommended' : 'Optional'}
                        color={suggestion.priority === 'high' ? 'error' : suggestion.priority === 'medium' ? 'primary' : 'default'}
                        size="small"
                        sx={{
                          bgcolor: suggestion.priority === 'high'
                            ? 'rgba(211, 47, 47, 0.9)'
                            : suggestion.priority === 'medium'
                              ? 'rgba(33, 150, 243, 0.9)'
                              : 'rgba(158, 158, 158, 0.9)',
                          color: 'white',
                        }}
                      />
                    </Box>
                  </Box>
                  <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom>
                      {suggestion.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {suggestion.description}
                    </Typography>
                    {!loading && suggestion.imageAttribution && (
                      <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                        Photo by{' '}
                        <a
                          href={suggestion.imageAttribution.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'inherit', textDecoration: 'underline' }}
                        >
                          {suggestion.imageAttribution.name}
                        </a>
                      </Typography>
                    )}
                  </CardContent>
                </CardActionArea>
                <Box sx={{ p: 2, pt: 0, mt: 'auto' }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate(`/search?category=${suggestion.category}`)}
                    size="small"
                  >
                    Find Professionals
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
          <Chip
            icon={<EventIcon />}
            label={`${currentSeason.charAt(0).toUpperCase() + currentSeason.slice(1)} Season`}
            color="primary"
            variant="outlined"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default SeasonalSuggestions;