import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Paper,
  Alert,
} from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useOnboarding } from '../../../../contexts/OnboardingContext';
import { OnboardingStepId } from '../../../../types/onboarding/shared';
import { useOnboardingStepData, useCompleteOnboarding, useSaveStep } from '../../../../hooks/api/useOnboardingApi';
import { OnboardingLayout } from '../OnboardingLayout';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField } from '../../../../components/forms/FormTextField';
import { z } from 'zod';

/**
 * Payment method form schema for client-side validation
 */
const paymentMethodFormSchema = z.object({
  cardNumber: z.string()
    .min(16, 'Card number must be at least 16 digits')
    .max(19, 'Card number must be at most 19 digits')
    .regex(/^\d+$/, 'Card number must contain only digits'),
  expiryDate: z.string()
    .regex(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Expiry date must be in MM/YY format'),
  cvv: z.string()
    .min(3, 'CVV must be at least 3 digits')
    .max(4, 'CVV must be at most 4 digits')
    .regex(/^\d+$/, 'CVV must contain only digits'),
  cardholderName: z.string()
    .min(3, 'Cardholder name must be at least 3 characters'),
});

// Type for the form data
type PaymentMethodFormData = z.infer<typeof paymentMethodFormSchema>;

/**
 * Payment Information step component
 */
const PaymentInfoStep: React.FC = () => {
  const navigate = useNavigate();
  const { getPreviousStep } = useOnboarding();
  const { data: stepData, isLoading: isDataLoading } = useOnboardingStepData(
    OnboardingStepId.PAYMENT_INFORMATION
  );
  const { mutateAsync: completeOnboardingAsync, isLoading: isCompleting } = useCompleteOnboarding();
  const { mutateAsync: saveStepAsync, isLoading: isSaving } = useSaveStep();

  const [error, setError] = useState<string | null>(null);

  // Initialize form with React Hook Form directly
  const formMethods = useForm<PaymentMethodFormData>({
    resolver: zodResolver(paymentMethodFormSchema),
    defaultValues: {
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      cardholderName: '',
    },
    mode: 'onChange',
  });

  const { handleSubmit, formState } = formMethods;
  const { isValid } = formState;

  const handleBack = () => {
    const prevStep = getPreviousStep(OnboardingStepId.PAYMENT_INFORMATION);
    if (prevStep) {
      navigate(`/pro/onboarding/${prevStep}`);
    }
  };

  const onSubmit = async (data: PaymentMethodFormData) => {
    setError(null);

    try {
      // In a real app, this would create a payment method with a payment processor
      // and return a payment method ID
      const paymentMethodId = `pm_${Date.now()}`;

      // Save payment method to server
      const saveResult = await saveStepAsync({
        stepId: OnboardingStepId.PAYMENT_INFORMATION,
        data: {
          paymentMethodId,
          // Store a reference to the payment method, not the actual data
          _paymentMethod: {
            lastFour: data.cardNumber.slice(-4),
            expiryDate: data.expiryDate,
            cardholderName: data.cardholderName,
          },
        },
      });

      if (saveResult.success) {
        // Complete onboarding
        const completeResult = await completeOnboardingAsync();

        if (completeResult.success) {
          // Navigate to dashboard or completion page
          navigate('/pro/dashboard');
        } else {
          setError('Failed to complete onboarding. Please try again.');
        }
      } else {
        setError('Failed to save payment information. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while saving. Please try again.');
      console.error('Error saving payment information:', err);
    }
  };

  if (isDataLoading) {
    return (
      <OnboardingLayout title="Payment Information" description="Loading...">
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title="Payment Information"
      description="Add a payment method to complete your onboarding. You'll only be charged when you receive leads."
    >
      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Alert severity="info" sx={{ mb: 3 }}>
            This is a demo implementation. In a production environment, you would integrate with a
            payment processor like Stripe or PayPal.
          </Alert>

          <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Credit Card Information
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextField
                  label="Card Number"
                  name="cardNumber"
                  fullWidth
                  placeholder="1234 5678 9012 3456"
                  disabled={isSaving || isCompleting}
                  required
                  inputProps={{ maxLength: 19 }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField
                  label="Expiry Date"
                  name="expiryDate"
                  fullWidth
                  placeholder="MM/YY"
                  disabled={isSaving || isCompleting}
                  required
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField
                  label="CVV"
                  name="cvv"
                  fullWidth
                  placeholder="123"
                  disabled={isSaving || isCompleting}
                  required
                  inputProps={{ maxLength: 4 }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormTextField
                  label="Cardholder Name"
                  name="cardholderName"
                  fullWidth
                  placeholder="John Doe"
                  disabled={isSaving || isCompleting}
                  required
                />
              </Grid>
            </Grid>
          </Paper>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={isSaving || isCompleting}
            >
              Back
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || isSaving || isCompleting}
              startIcon={
                (isSaving || isCompleting) ? <CircularProgress size={20} /> : null
              }
            >
              {isCompleting
                ? 'Completing Onboarding...'
                : isSaving
                ? 'Saving...'
                : 'Complete Onboarding'}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </OnboardingLayout>
  );
};

export default PaymentInfoStep;
