import React, { useState } from 'react';
import { Container, Typography, Box, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import IdeabookForm from '../../components/ideas/IdeabookForm';
import ideaService from '../../services/ideaService';

interface IdeabookFormData {
  title: string;
  description: string;
  isPrivate: boolean;
  tags: string[];
}

const IdeabookCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: IdeabookFormData) => {
    try {
      // Call the API to create the ideabook
      const createdIdeabook = await ideaService.createIdeabook({
        title: data.title,
        description: data.description,
        isPrivate: data.isPrivate,
      });
      console.log('Ideabook created:', createdIdeabook);

      // After successful creation, navigate to the ideabooks list
      navigate('/ideas/ideabooks');
    } catch (error) {
      console.error('Error creating ideabook:', error);
      setError('Failed to create ideabook. Please try again.');
    }
  };

  const handleCancel = () => {
    navigate('/ideas/ideabooks');
  };

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <Layout showSidebar>
      <Container maxWidth="md">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Create New Ideabook
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Create a new ideabook to collect and organize your home improvement ideas.
          </Typography>
        </Box>
        <IdeabookForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />

        {/* Error Snackbar */}
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
};

export default IdeabookCreatePage;