import React, { FC, useState, ChangeEvent } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Chip,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  FileUpload as FileUploadIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addCertification, deleteCertification, CertificationForm, Certification } from '../../../redux/slices/providerSlice';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../redux/store';
import { AppDispatch } from '../../../redux/store';

const validationSchema = Yup.object({
  name: Yup.string().required('Document name is required').max(100, 'Name is too long'),
  issuingAuthority: Yup.string()
    .required('Issuing authority is required')
    .max(100, 'Issuing authority name is too long'),
  issueDate: Yup.date()
    .required('Issue date is required')
    .max(new Date(), 'Issue date cannot be in the future'),
  expiryDate: Yup.date()
    .min(Yup.ref('issueDate'), 'Expiry date must be after issue date')
    .nullable(),
  documentUrl: Yup.string().required('Document is required').url('Invalid document URL'),
});

const DocumentationUploader: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.provider);
  const certifications = useSelector((state: RootState) => state.provider.profile?.certifications || []);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);

  const formik = useFormik<CertificationForm>({
    initialValues: {
      name: '',
      issuingAuthority: '',
      issueDate: new Date(),
      expiryDate: null,
      documentUrl: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await dispatch(addCertification(values)).unwrap();
        resetForm();
        setIsDialogOpen(false);
      } catch (err) {
        console.error('Failed to add documentation:', err);
      }
    },
  });

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploadingFile(true);
    try {
      // TODO: Implement file upload to cloud storage
      // For now, we'll use a mock URL
      const mockUrl = `https://storage.example.com/documents/${file.name}`;
      formik.setFieldValue('documentUrl', mockUrl);
    } catch (err) {
      console.error('Failed to upload file:', err);
    } finally {
      setUploadingFile(false);
    }
  };

  const handleDelete = async (certId: string) => {
    try {
      await dispatch(deleteCertification(certId)).unwrap();
    } catch (err) {
      console.error('Failed to delete documentation:', err);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Documentation & Licenses</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsDialogOpen(true)}
          >
            Add Document
          </Button>
        </Box>

        <List>
          {certifications.map((cert: Certification, index: number) => (
            <React.Fragment key={cert._id}>
              <ListItem
                secondaryAction={
                  <Box>
                    <IconButton
                      edge="end"
                      aria-label="open document"
                      onClick={() => window.open(cert.documentUrl, '_blank')}
                      sx={{ mr: 1 }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(cert._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                }
              >
                <ListItemText
                  primary={cert.name}
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2" color="text.primary">
                        {cert.issuingAuthority}
                      </Typography>
                      <br />
                      {`Issued: ${format(new Date(cert.issueDate), 'MMM dd, yyyy')}`}
                      {cert.expiryDate && (
                        <Chip
                          size="small"
                          label={`Expires: ${format(new Date(cert.expiryDate), 'MMM dd, yyyy')}`}
                          color={new Date(cert.expiryDate) < new Date() ? 'error' : 'default'}
                          sx={{ ml: 1 }}
                        />
                      )}
                    </React.Fragment>
                  }
                />
              </ListItem>
              {index < certifications.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>

        {certifications.length === 0 && (
          <Typography color="textSecondary" align="center" sx={{ py: 3 }}>
            No documentation added yet
          </Typography>
        )}

        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="sm" fullWidth>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Add Documentation</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Document Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="issuingAuthority"
                    name="issuingAuthority"
                    label="Issuing Authority"
                    value={formik.values.issuingAuthority}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.issuingAuthority && Boolean(formik.errors.issuingAuthority)
                    }
                    helperText={formik.touched.issuingAuthority && formik.errors.issuingAuthority}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Issue Date"
                    value={formik.values.issueDate}
                    onChange={(value) => formik.setFieldValue('issueDate', value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: formik.touched.issueDate && Boolean(formik.errors.issueDate),
                        helperText: formik.touched.issueDate && String(formik.errors.issueDate),
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Expiry Date (Optional)"
                    value={formik.values.expiryDate}
                    onChange={(value) => formik.setFieldValue('expiryDate', value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: formik.touched.expiryDate && Boolean(formik.errors.expiryDate),
                        helperText: formik.touched.expiryDate && String(formik.errors.expiryDate),
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<FileUploadIcon />}
                    disabled={uploadingFile}
                    fullWidth
                  >
                    {uploadingFile ? 'Uploading...' : 'Upload Document'}
                    <input
                      type="file"
                      hidden
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {formik.values.documentUrl && (
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      File uploaded:
                      <Link href={formik.values.documentUrl} target="_blank" sx={{ ml: 1 }}>
                        View Document
                      </Link>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || uploadingFile}
              >
                {loading ? 'Adding...' : 'Add'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Paper>
    </LocalizationProvider>
  );
};

export default DocumentationUploader;