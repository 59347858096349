import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateServiceAreas, ServiceArea } from '../../../redux/slices/providerSlice';
import { RootState } from '../../../redux/store';
import { AppDispatch } from '../../../redux/store';

const validationSchema = Yup.object({
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(/^\d{5}(-\d{4})?$/, 'Invalid zip code format'),
  city: Yup.string().required('City is required').max(100, 'City name is too long'),
  state: Yup.string().required('State is required').max(50, 'State name is too long'),
  radius: Yup.number()
    .required('Service radius is required')
    .min(1, 'Minimum radius is 1 km')
    .max(100, 'Maximum radius is 100 km'),
});

const ServiceAreasManager: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.provider);
  const serviceAreas = useSelector((state: RootState) => state.provider.profile?.serviceAreas || []);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const formik = useFormik<ServiceArea>({
    initialValues: {
      zipCode: '',
      city: '',
      state: '',
      radius: 10,
    },
    validationSchema,
    onSubmit: async (values: ServiceArea, { resetForm }) => {
      try {
        const updatedAreas = [...serviceAreas, values];
        await dispatch(updateServiceAreas(updatedAreas)).unwrap();
        resetForm();
        setIsDialogOpen(false);
      } catch (err) {
        console.error('Failed to add service area:', err);
      }
    },
  });

  const handleDelete = async (index: number): Promise<void> => {
    try {
      const updatedAreas = serviceAreas.filter((_, i) => i !== index);
      await dispatch(updateServiceAreas(updatedAreas)).unwrap();
    } catch (err) {
      console.error('Failed to remove service area:', err);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Service Areas</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsDialogOpen(true)}
        >
          Add Service Area
        </Button>
      </Box>

      <List>
        {serviceAreas.map((area, index) => (
          <React.Fragment key={`area-${index}`}>
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(index)}
                  disabled={loading}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${area.city}, ${area.state} ${area.zipCode}`}
                secondary={`Service radius: ${area.radius} km`}
              />
            </ListItem>
            {index < serviceAreas.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>

      {serviceAreas.length === 0 && (
        <Typography color="textSecondary" align="center" sx={{ py: 3 }}>
          No service areas added yet
        </Typography>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Add Service Area</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="zipCode"
                  name="zipCode"
                  label="Zip Code"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="city"
                  name="city"
                  label="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="state"
                  name="state"
                  label="State"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="radius"
                  name="radius"
                  label="Service Radius (km)"
                  type="number"
                  value={formik.values.radius}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.radius && Boolean(formik.errors.radius)}
                  helperText={formik.touched.radius && formik.errors.radius}
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'Adding...' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Paper>
  );
};

export default ServiceAreasManager;