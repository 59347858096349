import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../services/axios';

interface IdeabookItem {
  _id: string;
  type: 'photo' | 'product' | 'professional' | 'story' | 'discussion';
  content: any;
  note?: string;
  tags: string[];
  addedAt: Date;
}

interface Collaborator {
  user: {
    _id: string;
    name: string;
    email: string;
    avatar?: string;
  };
  role: 'viewer' | 'editor';
  addedAt: Date;
}

interface Ideabook {
  _id: string;
  user: string;
  title: string;
  description?: string;
  isPrivate: boolean;
  collaborators: Collaborator[];
  items: IdeabookItem[];
  coverImage?: string;
  itemCount: number;
  lastModified: Date;
  createdAt: Date;
  updatedAt: Date;
}

interface IdeabookState {
  ideabooks: Ideabook[];
  currentIdeabook: Ideabook | null;
  loading: boolean;
  error: string | null;
}

const initialState: IdeabookState = {
  ideabooks: [],
  currentIdeabook: null,
  loading: false,
  error: null,
};

// Async thunks
export const fetchUserIdeabooks = createAsyncThunk(
  'ideabook/fetchUserIdeabooks',
  async () => {
    const response = await axios.get('/ideabooks');
    return response.data;
  }
);

export const fetchIdeabook = createAsyncThunk(
  'ideabook/fetchIdeabook',
  async (id: string) => {
    const response = await axios.get(`/ideabooks/${id}`);
    return response.data;
  }
);

export const createIdeabook = createAsyncThunk(
  'ideabook/createIdeabook',
  async (data: { title: string; description?: string; isPrivate: boolean }) => {
    const response = await axios.post('/ideabooks', data);
    return response.data;
  }
);

export const updateIdeabook = createAsyncThunk(
  'ideabook/updateIdeabook',
  async ({ id, data }: { id: string; data: Partial<Ideabook> }) => {
    const response = await axios.put(`/ideabooks/${id}`, data);
    return response.data;
  }
);

export const deleteIdeabook = createAsyncThunk(
  'ideabook/deleteIdeabook',
  async (id: string) => {
    await axios.delete(`/ideabooks/${id}`);
    return id;
  }
);

export const addIdeabookItem = createAsyncThunk(
  'ideabook/addItem',
  async ({ id, data }: { id: string; data: Omit<IdeabookItem, '_id' | 'addedAt'> }) => {
    const response = await axios.post(`/ideabooks/${id}/items`, data);
    return response.data;
  }
);

export const removeIdeabookItem = createAsyncThunk(
  'ideabook/removeItem',
  async ({ id, itemId }: { id: string; itemId: string }) => {
    const response = await axios.delete(`/ideabooks/${id}/items/${itemId}`);
    return response.data;
  }
);

export const addCollaborator = createAsyncThunk(
  'ideabook/addCollaborator',
  async ({ id, userId, role }: { id: string; userId: string; role: 'viewer' | 'editor' }) => {
    const response = await axios.post(`/ideabooks/${id}/collaborators`, { userId, role });
    return response.data;
  }
);

export const removeCollaborator = createAsyncThunk(
  'ideabook/removeCollaborator',
  async ({ id, userId }: { id: string; userId: string }) => {
    const response = await axios.delete(`/ideabooks/${id}/collaborators/${userId}`);
    return response.data;
  }
);

const ideabookSlice = createSlice({
  name: 'ideabook',
  initialState,
  reducers: {
    clearCurrentIdeabook: (state) => {
      state.currentIdeabook = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch user ideabooks
      .addCase(fetchUserIdeabooks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserIdeabooks.fulfilled, (state, action: PayloadAction<Ideabook[]>) => {
        state.loading = false;
        state.ideabooks = action.payload;
      })
      .addCase(fetchUserIdeabooks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch ideabooks';
      })

      // Fetch single ideabook
      .addCase(fetchIdeabook.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIdeabook.fulfilled, (state, action: PayloadAction<Ideabook>) => {
        state.loading = false;
        state.currentIdeabook = action.payload;
      })
      .addCase(fetchIdeabook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch ideabook';
      })

      // Create ideabook
      .addCase(createIdeabook.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createIdeabook.fulfilled, (state, action: PayloadAction<Ideabook>) => {
        state.loading = false;
        state.ideabooks.push(action.payload);
      })
      .addCase(createIdeabook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create ideabook';
      })

      // Update ideabook
      .addCase(updateIdeabook.fulfilled, (state, action: PayloadAction<Ideabook>) => {
        const index = state.ideabooks.findIndex(book => book._id === action.payload._id);
        if (index !== -1) {
          state.ideabooks[index] = action.payload;
        }
        if (state.currentIdeabook?._id === action.payload._id) {
          state.currentIdeabook = action.payload;
        }
      })

      // Delete ideabook
      .addCase(deleteIdeabook.fulfilled, (state, action: PayloadAction<string>) => {
        state.ideabooks = state.ideabooks.filter(book => book._id !== action.payload);
        if (state.currentIdeabook?._id === action.payload) {
          state.currentIdeabook = null;
        }
      })

      // Add/Remove items and collaborators
      .addCase(addIdeabookItem.fulfilled, (state, action: PayloadAction<Ideabook>) => {
        if (state.currentIdeabook?._id === action.payload._id) {
          state.currentIdeabook = action.payload;
        }
      })
      .addCase(removeIdeabookItem.fulfilled, (state, action: PayloadAction<Ideabook>) => {
        if (state.currentIdeabook?._id === action.payload._id) {
          state.currentIdeabook = action.payload;
        }
      })
      .addCase(addCollaborator.fulfilled, (state, action: PayloadAction<Ideabook>) => {
        if (state.currentIdeabook?._id === action.payload._id) {
          state.currentIdeabook = action.payload;
        }
      })
      .addCase(removeCollaborator.fulfilled, (state, action: PayloadAction<Ideabook>) => {
        if (state.currentIdeabook?._id === action.payload._id) {
          state.currentIdeabook = action.payload;
        }
      });
  },
});

export const { clearCurrentIdeabook, clearError } = ideabookSlice.actions;
export default ideabookSlice.reducer;