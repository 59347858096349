import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import storyService from '../../../services/storyService';
import {
  Box,
  Typography,
  Breadcrumbs,
  Chip,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Avatar,
  Paper,
  IconButton,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';
import PageTitle from '../../../components/common/PageTitle';
import StructuredData, {
  createArticleStructuredData,
} from '../../../components/common/StructuredData';

// Story interface
interface Story {
  _id: string; // MongoDB ObjectId
  title: string;
  image: string;
  excerpt: string;
  content: string;
  category: string;
  tags: string[];
  author: {
    name: string;
    avatar: string;
    bio: string;
  };
  date: string;
  readTime: string;
  featured: boolean;
  relatedStories?: Array<{
    id: string;
    title: string;
    image: string;
    excerpt: string;
    category: string;
  }>;
}

/**
 * StoryDetail component displays a single story with full content
 */
const StoryDetail: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();
  const navigate = useNavigate();
  const [story, setStory] = useState<Story | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    const fetchStory = async () => {
      if (storyId) {
        try {
          setLoading(true);
          const response = await storyService.getStoryById(storyId);
          
          if (response.success) {
            setStory(response.data);
            
            // Set page title and meta tags once story is loaded
            document.title = `${response.data.title} | Homezy Resources`;
          } else {
            setError('Story not found');
          }
        } catch (err: any) {
          console.error('Error fetching story:', err);
          setError(err.message || 'Failed to load story');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStory();
  }, [storyId]);

  if (loading) {
    return (
      <Box sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h6">Loading story...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
        <Button variant="contained" onClick={() => navigate('/resources/stories')} sx={{ mt: 2 }}>
          Back to Stories
        </Button>
      </Box>
    );
  }

  if (!story) {
    return (
      <Box sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h6">Story not found</Typography>
        <Button variant="contained" onClick={() => navigate('/resources/stories')} sx={{ mt: 2 }}>
          Back to Stories
        </Button>
      </Box>
    );
  }

  // Function to handle social sharing
  const handleShare = (platform: string) => {
    const url = window.location.href;
    const text = `Check out this article: ${story.title}`;

    let shareUrl = '';
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(text)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`;
        break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, '_blank');
    }
  };

  // Create structured data for the article
  const articleStructuredData = createArticleStructuredData({
    title: story.title,
    description: story.excerpt,
    image: story.image,
    authorName: story.author.name,
    datePublished: story.date,
    url: window.location.href,
  });

  return (
    <>
      <PageTitle
        title={story.title}
        description={story.excerpt}
        keywords={story.tags.join(', ')}
        image={story.image}
        locale="en_AE"
        type="article"
      />

      {/* Add structured data for better SEO */}
      <StructuredData data={articleStructuredData} />

      {/* Back Button */}
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/resources/stories')}
        sx={{ mb: 2 }}
      >
        Back to Stories
      </Button>

      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          Home
        </Link>
        <Link to="/resources" style={{ textDecoration: 'none', color: 'inherit' }}>
          Resources
        </Link>
        <Link to="/resources/stories" style={{ textDecoration: 'none', color: 'inherit' }}>
          Stories
        </Link>
        <Typography color="text.primary">{story.title}</Typography>
      </Breadcrumbs>

      {/* Story Header */}
      <Box sx={{ mb: 4 }}>
        <Chip label={story.category} color="primary" variant="outlined" sx={{ mb: 2 }} />
        <Typography variant="h3" component="h1" gutterBottom>
          {story.title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" paragraph>
          {story.excerpt}
        </Typography>

        {/* Author and Date */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar src={story.author.avatar} alt={story.author.name} sx={{ mr: 2 }} />
          <Box>
            <Typography variant="subtitle2">{story.author.name}</Typography>
            <Typography variant="caption" color="text.secondary">
              {new Date(story.date).toLocaleDateString('en-AE')} • {story.readTime}
            </Typography>
          </Box>
        </Box>

        {/* Action Buttons */}
        <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
          <IconButton
            onClick={() => setIsBookmarked(!isBookmarked)}
            color={isBookmarked ? 'primary' : 'default'}
            aria-label={isBookmarked ? 'Remove bookmark' : 'Bookmark this story'}
          >
            {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
          </IconButton>
          <IconButton onClick={() => handleShare('facebook')} aria-label="Share on Facebook">
            <FacebookIcon />
          </IconButton>
          <IconButton onClick={() => handleShare('twitter')} aria-label="Share on Twitter">
            <TwitterIcon />
          </IconButton>
          <IconButton onClick={() => handleShare('linkedin')} aria-label="Share on LinkedIn">
            <LinkedInIcon />
          </IconButton>
          <IconButton onClick={() => handleShare('whatsapp')} aria-label="Share on WhatsApp">
            <WhatsAppIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Featured Image */}
      <Box
        component="img"
        src={story.image}
        alt={story.title}
        sx={{
          width: '100%',
          height: 'auto',
          maxHeight: '500px',
          objectFit: 'cover',
          borderRadius: 2,
          mb: 4,
        }}
      />

      {/* Story Content */}
      <Box
        className="story-content"
        sx={{
          mb: 6,
          '& h1': {
            fontSize: '2rem',
            fontWeight: 700,
            mt: 4,
            mb: 3,
            color: 'primary.main',
          },
          '& h2': {
            fontSize: '1.75rem',
            fontWeight: 600,
            mt: 4,
            mb: 2,
            color: 'primary.main',
          },
          '& h3': {
            fontSize: '1.5rem',
            fontWeight: 600,
            mt: 3,
            mb: 2,
          },
          '& p': {
            mb: 2,
            lineHeight: 1.7,
            fontSize: '1rem',
          },
          '& ul, & ol': {
            pl: 4,
            mb: 2,
          },
          '& li': {
            mb: 1,
          },
          '& blockquote, & .author-quote': {
            fontStyle: 'italic',
            borderLeft: '4px solid',
            borderColor: 'primary.main',
            paddingLeft: 3,
            marginLeft: 0,
            marginBottom: 3,
            color: 'text.secondary',
          },
          '& img': {
            maxWidth: '100%',
            height: 'auto',
            borderRadius: 1,
            mb: 2,
          },
          '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          '& .highlight': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            padding: 2,
            borderRadius: 1,
            marginBottom: 3,
          },
          // Remove any conflicting styles from embedded HTML
          '& style': {
            display: 'none',
          },
        }}
        dangerouslySetInnerHTML={{ __html: story.content }}
      />

      {/* Tags */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle2" gutterBottom>
          Tags:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {story.tags.map((tag: string) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              variant="outlined"
              component={Link}
              to={`/resources/stories?tag=${tag}`}
              sx={{ textDecoration: 'none' }}
            />
          ))}
        </Box>
      </Box>

      {/* Author Bio */}
      <Paper sx={{ p: 3, mb: 6, borderRadius: 2, bgcolor: 'grey.50' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar
            src={story.author.avatar}
            alt={story.author.name}
            sx={{ width: 64, height: 64, mr: 2 }}
          />
          <Box>
            <Typography variant="h6">{story.author.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {story.author.bio}
            </Typography>
          </Box>
        </Box>
      </Paper>

      {/* Related Stories */}
      {story.relatedStories && story.relatedStories.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Related Stories
          </Typography>
          <Grid container spacing={3}>
            {story.relatedStories.map((relatedStory: any) => (
              <Grid item xs={12} sm={6} key={relatedStory.id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={relatedStory.image}
                    alt={relatedStory.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Chip
                      label={relatedStory.category}
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{ mb: 1 }}
                    />
                    <Typography variant="h6" component="h3" gutterBottom>
                      {relatedStory.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {relatedStory.excerpt}
                    </Typography>
                    <Button
                      size="small"
                      component={Link}
                      to={`/resources/stories/${relatedStory.id}`}
                      sx={{ mt: 2 }}
                    >
                      Read More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Call to Action */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Looking for more home improvement ideas?
        </Typography>
        <Typography variant="body1" paragraph>
          Explore our collection of stories, guides, and resources for UAE homeowners.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/resources/stories"
          sx={{ mt: 1 }}
        >
          Browse More Stories
        </Button>
      </Paper>
    </>
  );
};

export default StoryDetail;
