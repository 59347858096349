import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Divider,
  Alert,
  CircularProgress,
  Tooltip,
  Slider,
  InputAdornment,
  Snackbar,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import { useCalendar } from '../../../contexts/CalendarContext';

const CalendarSettings: React.FC = () => {
  const { settings, loading, error, updateSettings } = useCalendar();
  const [formValues, setFormValues] = useState({
    bufferTime: 15,
    leadPreparationTime: 30,
    maxDailyLeads: 5,
    autoAccept: false,
  });
  const [success, setSuccess] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (settings) {
      setFormValues({
        bufferTime: settings.bufferTime,
        leadPreparationTime: settings.leadPreparationTime,
        maxDailyLeads: settings.maxDailyLeads,
        autoAccept: settings.autoAccept,
      });
    }
  }, [settings]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : Number(value),
    }));
  };

  const handleSliderChange = (name: string) => (event: Event, newValue: number | number[]) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: newValue as number,
    }));
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      await updateSettings(formValues);
      setSuccess(true);
    } catch (err) {
      console.error('Error saving calendar settings:', err);
    } finally {
      setSaving(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
  };

  if (loading && !settings) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Calendar Settings</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Typography variant="body2" color="text.secondary" paragraph>
        Configure your calendar settings to manage your availability and lead scheduling preferences.
      </Typography>

      <Divider sx={{ mb: 3 }} />

      <Paper sx={{ p: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              Buffer Time
              <Tooltip title="Time between appointments to allow for travel or preparation">
                <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
              </Tooltip>
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={formValues.bufferTime}
                  onChange={handleSliderChange('bufferTime')}
                  aria-labelledby="buffer-time-slider"
                  valueLabelDisplay="auto"
                  step={5}
                  marks
                  min={0}
                  max={60}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="bufferTime"
                  value={formValues.bufferTime}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">min</InputAdornment>,
                  }}
                  inputProps={{
                    step: 5,
                    min: 0,
                    max: 60,
                    type: 'number',
                  }}
                  sx={{ width: 100 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              Lead Preparation Time
              <Tooltip title="Time needed to prepare for a lead before the scheduled time">
                <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
              </Tooltip>
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={formValues.leadPreparationTime}
                  onChange={handleSliderChange('leadPreparationTime')}
                  aria-labelledby="lead-preparation-time-slider"
                  valueLabelDisplay="auto"
                  step={15}
                  marks
                  min={0}
                  max={120}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="leadPreparationTime"
                  value={formValues.leadPreparationTime}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">min</InputAdornment>,
                  }}
                  inputProps={{
                    step: 15,
                    min: 0,
                    max: 120,
                    type: 'number',
                  }}
                  sx={{ width: 100 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              Maximum Daily Leads
              <Tooltip title="Maximum number of leads you can handle per day">
                <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
              </Tooltip>
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={formValues.maxDailyLeads}
                  onChange={handleSliderChange('maxDailyLeads')}
                  aria-labelledby="max-daily-leads-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={10}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="maxDailyLeads"
                  value={formValues.maxDailyLeads}
                  onChange={handleInputChange}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 10,
                    type: 'number',
                  }}
                  sx={{ width: 80 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.autoAccept}
                    onChange={handleInputChange}
                    name="autoAccept"
                    color="primary"
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Auto-Accept Leads
                    <Tooltip title="Automatically accept leads that fit your calendar availability">
                      <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                    </Tooltip>
                  </Box>
                }
              />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, ml: 4 }}>
                When enabled, leads that fit within your available time slots will be automatically accepted.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Calendar settings saved successfully"
      />
    </Box>
  );
};

export default CalendarSettings;