import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, CircularProgress, Alert, Link, Paper } from '@mui/material';
import { useReviewsOnboarding } from '../ReviewsOnboardingProvider';
import InfoIcon from '@mui/icons-material/Info';

/**
 * Component for connecting a provider's Google Business Profile
 * Used in the onboarding flow to import existing Google reviews
 */
const GoogleBusinessConnect: React.FC = () => {
  const {
    googleBusinessConnected,
    setGoogleBusinessConnected,
    fetchGoogleLocations,
    connectGoogleBusiness,
    isLoading,
    error,
  } = useReviewsOnboarding();

  const [localError, setLocalError] = useState<string | null>(null);
  const [connectionAttempted, setConnectionAttempted] = useState(false);

  // Check if already connected on mount
  useEffect(() => {
    const checkConnection = async () => {
      try {
        await fetchGoogleLocations();
      } catch (error) {
        // Not connected yet, that's fine
      }
    };

    checkConnection();
  }, [fetchGoogleLocations]);

  const handleConnect = async () => {
    setLocalError(null);
    setConnectionAttempted(true);

    try {
      await connectGoogleBusiness();
    } catch (error: any) {
      setLocalError('Failed to initiate Google Business connection');
      console.error('Connection error:', error);
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Connect to Google Business Profile
      </Typography>
      <Typography variant="body1" paragraph>
        Import your existing Google reviews to enhance your profile credibility. This allows
        potential customers to see your established reputation right from the start.
      </Typography>

      {(error || localError) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error || localError}
          {error?.includes('configuration') && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              The Google Business API integration may not be properly configured. Please ensure your Google OAuth credentials are set up correctly.
            </Typography>
          )}
        </Alert>
      )}

      {connectionAttempted && !googleBusinessConnected && !isLoading && !error && !localError && (
        <Paper sx={{ p: 2, mb: 2, bgcolor: '#f8f9fa', display: 'flex', alignItems: 'flex-start' }}>
          <InfoIcon color="info" sx={{ mr: 1, mt: 0.5 }} />
          <Box>
            <Typography variant="body2">
              If the Google authentication window didn't open, please check if pop-ups are blocked in your browser.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              You'll need to allow pop-ups for this site and try again.
            </Typography>
          </Box>
        </Paper>
      )}

      {googleBusinessConnected ? (
        <Alert severity="success">
          Your Google Business Profile is connected! You can now import your reviews.
        </Alert>
      ) : (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnect}
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            Connect Google Business Profile
          </Button>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Note: You'll need to have a Google Business Profile to use this feature.
            If you don't have one yet, <Link href="https://business.google.com/" target="_blank" rel="noopener">create one here</Link> first.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GoogleBusinessConnect;