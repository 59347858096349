import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Alert,
  Grid,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Divider,
} from '@mui/material';
import GoogleSignupButton from '../../components/auth/GoogleSignupButton';
import { registerStart, registerSuccess, registerFailure } from '../../redux/slices/authSlice';
import authService from '../../services/authService';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  firstName: Yup.string()
    .required('First name is required')
    .min(2, 'First name must be at least 2 characters'),
  lastName: Yup.string()
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
  agreeToTerms: Yup.boolean()
    .oneOf([true], 'You must agree to the terms and conditions')
    .required('You must agree to the terms and conditions'),
});

const ProviderRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState('');

  interface FormValues {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    agreeToTerms: boolean;
  }

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      dispatch(registerStart());
      const response = await authService.registerProvider({
        ...values,
        role: 'provider',
      });
      dispatch(registerSuccess(response));
      navigate('/pro/onboarding/0'); // Navigate to onboarding after registration with step 0
    } catch (error: any) {
      console.error('Registration error:', error);

      // Better error extraction
      let errorMessage = 'Registration failed';

      if (error?.response?.data?.message) {
        // Server returned an error message
        errorMessage = error.response.data.message;
      } else if (error?.message) {
        // Error object has a message property
        errorMessage = error.message;
      }

      // Check for common errors
      if (errorMessage.includes('already registered') || errorMessage.includes('already exists')) {
        errorMessage = 'This email is already registered. Please use a different email or login to your existing account.';
      }

      setServerError(errorMessage);
      dispatch(registerFailure(errorMessage));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        py: 4,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: '100%',
          maxWidth: 500,
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom align="center">
          Join Homezy as a Service Provider
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center" color="textSecondary">
          Start growing your business with Homezy
        </Typography>

        <Box sx={{ my: 2 }}>
          <GoogleSignupButton
            role="provider"
            onStart={() => dispatch(registerStart())}
            onError={(error) => {
              const errorMessage = error.message || 'Google signup failed';
              setServerError(errorMessage);
              dispatch(registerFailure(errorMessage));
            }}
          />
          <Divider sx={{ my: 2 }}>or</Divider>
        </Box>

        {serverError && (
          <Alert
            severity="error"
            sx={{
              mb: 2,
              '& .MuiAlert-message': {
                width: '100%',
              },
            }}
          >
            <Typography variant="body1" gutterBottom>{serverError}</Typography>
            {serverError.includes('already registered') && (
              <Button
                component={Link}
                to="/pro/login"
                color="error"
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
              >
                Go to Login
              </Button>
            )}
          </Alert>
        )}

        <Formik<FormValues>
          initialValues={{
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phone: '',
            agreeToTerms: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, handleBlur, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    name="agreeToTerms"
                    checked={values.agreeToTerms}
                    onChange={handleChange}
                  />
                }
                label="I agree to the Terms of Service and Privacy Policy"
                sx={{ mt: 2 }}
              />
              {touched.agreeToTerms && errors.agreeToTerms && (
                <FormHelperText error>{errors.agreeToTerms}</FormHelperText>
              )}

              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Create Professional Account
              </Button>

              <Box sx={{ textAlign: 'center' }}>
                <Link to="/pro/login" style={{ textDecoration: 'none' }}>
                  <Typography color="primary">Already have an account? Login</Typography>
                </Link>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default ProviderRegister;