import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Store as StoreIcon,
  Inventory as InventoryIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';

const OnboardingComplete: React.FC = () => {
  const navigate = useNavigate();
  const { onboardingStatus } = useSellerOnboarding();

  const handleGoToDashboard = () => {
    navigate('/seller/dashboard');
  };

  const handleAddProducts = () => {
    navigate('/seller/products/new');
  };

  const handleManageStore = () => {
    navigate('/seller/store');
  };

  return (
    <Box sx={{ textAlign: 'center', py: 4 }}>
      <CheckCircleIcon color="success" sx={{ fontSize: 80, mb: 2 }} />

      <Typography variant="h4" gutterBottom>
        Congratulations!
      </Typography>

      <Typography variant="h6" color="text.secondary" paragraph>
        Your seller account setup is complete
      </Typography>

      <Divider sx={{ my: 4 }} />

      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Next Steps
            </Typography>

            <List>
              <ListItem>
                <ListItemIcon>
                  <InventoryIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Add Products"
                  secondary="Start adding products to your store inventory"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <StoreIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Customize Your Store"
                  secondary="Personalize your storefront to attract customers"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <SettingsIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Configure Settings"
                  secondary="Set up shipping, payment, and notification preferences"
                />
              </ListItem>
            </List>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<InventoryIcon />}
                onClick={handleAddProducts}
                sx={{ mr: 2 }}
              >
                Add Products
              </Button>

              <Button
                variant="outlined"
                startIcon={<StoreIcon />}
                onClick={handleManageStore}
              >
                Manage Store
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper variant="outlined" sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Account Status
            </Typography>

            <Box sx={{ textAlign: 'left', mb: 3 }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Registration Date:</strong> {new Date(onboardingStatus?.lastUpdated || Date.now()).toLocaleDateString()}
              </Typography>

              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Verification Status:</strong> Pending Review
              </Typography>

              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Store Status:</strong> Active (Limited)
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Your account is currently under review. You can add products and set up your store, but they won't be visible to customers until verification is complete (typically 1-2 business days).
              </Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle2" gutterBottom>
              Need Help?
            </Typography>

            <Typography variant="body2" paragraph>
              Our seller support team is available to assist you with any questions.
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<DashboardIcon />}
              onClick={handleGoToDashboard}
              fullWidth
            >
              Go to Seller Dashboard
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnboardingComplete;