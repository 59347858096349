import axios from 'axios';
import { useAuth } from '../hooks/useAuth';

/**
 * Service for handling file uploads to GCP Cloud Storage
 */
class UploadService {
  /**
   * Upload a provider logo
   * @param file - The logo file to upload
   * @returns Promise resolving to the uploaded file URL
   */
  async uploadProviderLogo(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('logo', file);

    const response = await axios.post('/api/upload/provider/logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.fileUrl;
  }

  /**
   * Upload photos for a provider's portfolio item
   * @param projectId - The ID of the portfolio item
   * @param files - The photo files to upload
   * @returns Promise resolving to an array of uploaded file URLs
   */
  async uploadProjectPhotos(projectId: string, files: File[]): Promise<string[]> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('photos', file);
    });

    const response = await axios.post(`/api/upload/provider/portfolio/${projectId}/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.fileUrls;
  }

  /**
   * Upload temporary photos for a provider's portfolio item (before creating the item)
   * @param files - The photo files to upload
   * @returns Promise resolving to an array of uploaded file URLs
   */
  async uploadTempProjectPhotos(files: File[]): Promise<string[]> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('photos', file);
    });

    const response = await axios.post('/api/upload/provider/temp-photos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.fileUrls;
  }

  /**
   * Upload images for a marketplace product
   * @param productId - The ID of the product
   * @param files - The image files to upload
   * @param setPrimaryImage - Whether to set the first image as primary
   * @returns Promise resolving to an array of uploaded file URLs
   */
  async uploadProductImages(productId: string, files: File[], setPrimaryImage = false): Promise<string[]> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('images', file);
    });

    if (setPrimaryImage) {
      formData.append('setPrimaryImage', 'true');
    }

    const response = await axios.post(`/api/upload/marketplace/product/${productId}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.fileUrls;
  }

  /**
   * Upload a document file
   * @param file - The document file to upload
   * @param type - The type of document (e.g., 'certificate', 'license', etc.)
   * @returns Promise resolving to the uploaded file URL
   */
  async uploadDocument(file: File, type: string): Promise<string> {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('type', type);

    const response = await axios.post('/api/upload/documents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.fileUrl;
  }

  /**
   * Upload a user avatar
   * @param file - The avatar image file to upload
   * @returns Promise resolving to the uploaded file URL
   */
  async uploadAvatar(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('avatar', file);

    const response = await axios.post('/api/upload/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.fileUrl;
  }

  /**
   * Delete a file from storage
   * @param fileUrl - The URL of the file to delete
   * @returns Promise resolving when the file is deleted
   */
  async deleteFile(fileUrl: string): Promise<void> {
    await axios.delete('/api/upload/file', {
      data: { fileUrl },
    });
  }

  /**
   * Create a React hook for using the upload service
   * @returns The upload service methods with authentication
   */
  static useUploadService() {
    const { getAuthToken } = useAuth();

    // Create a new instance of the service with authentication
    const service = new UploadService();

    // Set the authorization header for all requests
    const token = getAuthToken();
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return service;
  }
}

export default UploadService;