import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  Paper,
  Rating,
  Tabs,
  Tab,
  List,
  ListItem,
  Chip,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardMedia,
  TextField,
} from '@mui/material';
import {
  ShoppingCart as CartIcon,
  Favorite as FavoriteIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { RootState, AppDispatch } from '../../redux/store';
import { fetchProducts } from '../../redux/slices/productSlice';
import { addToCart } from '../../redux/slices/cartSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      aria-labelledby={`product-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ProductPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { items, loading, error } = useSelector((state: RootState) => state.products);
  const currentProduct = items.find((p) => p._id === id);

  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // Fetch all products
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleAddToCart = () => {
    if (currentProduct) {
      dispatch(addToCart({ product: currentProduct, quantity }));
      // Show success message or navigate to cart
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Simulated product data (would come from API in real implementation)
  const product = {
    _id: '1',
    name: 'Modern Teak Bathroom Vanity',
    description:
      'Elegant bathroom vanity made from sustainable teak wood with a modern design. Features soft-close drawers and a premium ceramic sink.',
    price: {
      amount: 1299.99,
      currency: 'AED',
      compareAt: 1599.99,
    },
    brand: 'Luxe Home',
    images: [
      {
        url: 'https://source.unsplash.com/featured/800x600/?bathroom-vanity,modern-bathroom',
        isPrimary: true,
        tags: ['main'],
      },
      {
        url: 'https://source.unsplash.com/featured/800x600/?bathroom-vanity-detail,sink',
        isPrimary: false,
        tags: ['detail'],
      },
      {
        url: 'https://source.unsplash.com/featured/800x600/?bathroom-interior,luxury-bathroom',
        isPrimary: false,
        tags: ['lifestyle'],
      },
    ],
    variants: [
      {
        name: 'Size',
        options: [
          { value: '24 inch', price: 1099.99 },
          { value: '30 inch', price: 1299.99 },
          { value: '36 inch', price: 1499.99 },
        ],
      },
      {
        name: 'Finish',
        options: [
          { value: 'Natural', price: 0 },
          { value: 'Walnut', price: 100 },
          { value: 'Espresso', price: 100 },
        ],
      },
    ],
    specifications: [
      { name: 'Material', value: 'Teak Wood, Ceramic' },
      { name: 'Dimensions', value: '30" W x 22" D x 34" H' },
      { name: 'Style', value: 'Modern' },
      { name: 'Installation', value: 'Freestanding' },
      { name: 'Number of Drawers', value: '2' },
    ],
    ratings: {
      average: 4.7,
      count: 28,
    },
    seller: {
      _id: '1',
      name: 'Dubai Furniture',
      rating: 4.8,
    },
    inventory: {
      quantity: 15,
      sku: 'TVB-001',
      lowStockThreshold: 5,
      allowBackorder: false,
    },
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {/* Product Images */}
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
            {/* In a real implementation, this would be a proper image gallery component */}
            <Box
              sx={{
                width: '100%',
                height: 400,
                backgroundColor: '#e0e0e0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  textAlign: 'center',
                  padding: 4,
                  color: '#555',
                  zIndex: 1,
                }}
              >
                {product.name}
              </Typography>
              {/* Try to load the image in the background if available */}
              {product.images[0]?.url && (
                <Box
                  component="img"
                  src={product.images[0].url}
                  alt={product.name}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 0,
                  }}
                  style={{ opacity: 0.8 }}
                  onError={(e) => {
                    // If image fails to load, just hide it
                    const target = e.target as HTMLImageElement;
                    target.style.display = 'none';
                  }}
                />
              )}
            </Box>
            <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
              {product.images.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 80,
                    height: 80,
                    backgroundColor: '#e0e0e0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    cursor: 'pointer',
                    border: index === 0 ? '2px solid #1976d2' : '2px solid transparent',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: 'center',
                      color: '#555',
                      zIndex: 1,
                    }}
                  >
                    {index + 1}
                  </Typography>
                  {/* Try to load the image in the background if available */}
                  {image.url && (
                    <Box
                      component="img"
                      src={image.url}
                      alt={`${product.name} thumbnail ${index + 1}`}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 0,
                      }}
                      style={{ opacity: 0.8 }}
                      onError={(e) => {
                        // If image fails to load, just hide it
                        const target = e.target as HTMLImageElement;
                        target.style.display = 'none';
                      }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>

        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h4" component="h1" gutterBottom>
              {product.name}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Rating value={product.ratings.average} precision={0.1} readOnly />
              <Typography variant="body2" sx={{ ml: 1 }}>
                ({product.ratings.count} reviews)
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <Typography variant="body2">Sold by: {product.seller.name}</Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography variant="h5" color="primary" component="span">
                {product.price.currency} {product.price.amount.toFixed(2)}
              </Typography>
              {product.price.compareAt && (
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ ml: 2, textDecoration: 'line-through', color: 'text.secondary' }}
                >
                  {product.price.currency} {product.price.compareAt.toFixed(2)}
                </Typography>
              )}
            </Box>

            <Typography variant="body1" paragraph>
              {product.description}
            </Typography>

            <Divider sx={{ my: 3 }} />

            {/* Variants */}
            {product.variants &&
              product.variants.map((variant, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {variant.name}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {variant.options.map((option, optIndex) => (
                      <Chip
                        key={optIndex}
                        label={`${option.value}${option.price > 0 ? ` (+${option.price})` : ''}`}
                        onClick={() => {}}
                        variant="outlined"
                        color="primary"
                      />
                    ))}
                  </Box>
                </Box>
              ))}

            {/* Quantity */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Quantity
              </Typography>
              <TextField
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                InputProps={{ inputProps: { min: 1, max: product.inventory?.quantity || 0 } }}
                size="small"
                sx={{ width: 100 }}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                {product.inventory?.quantity || 0} available
              </Typography>
            </Box>

            {/* Action Buttons */}
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <Button
                variant="contained"
                size="large"
                startIcon={<CartIcon />}
                onClick={handleAddToCart}
                fullWidth
              >
                Add to Cart
              </Button>
              <Button variant="outlined" size="large" startIcon={<FavoriteIcon />}>
                Wishlist
              </Button>
              <Button variant="outlined" size="large" startIcon={<ShareIcon />}>
                Share
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Product Tabs */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ mt: 4 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="product information tabs"
              variant="fullWidth"
            >
              <Tab label="Specifications" />
              <Tab label="Reviews" />
              <Tab label="Shipping" />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              <List>
                {product.specifications.map((spec, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2">{spec.name}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">{spec.value}</Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {index < product.specifications.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Typography variant="h6" gutterBottom>
                Customer Reviews
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Typography variant="h3" component="span">
                  {product.ratings.average.toFixed(1)}
                </Typography>
                <Box sx={{ ml: 2 }}>
                  <Rating value={product.ratings.average} precision={0.1} readOnly size="large" />
                  <Typography variant="body2">Based on {product.ratings.count} reviews</Typography>
                </Box>
              </Box>

              {/* This would be replaced with actual reviews in a real implementation */}
              <Alert severity="info">
                Reviews would be displayed here in a real implementation.
              </Alert>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Typography variant="h6" gutterBottom>
                Shipping Information
              </Typography>
              <Typography variant="body1" paragraph>
                Standard delivery: 3-5 business days
              </Typography>
              <Typography variant="body1" paragraph>
                Express delivery: 1-2 business days (additional charges apply)
              </Typography>
              <Typography variant="body1" paragraph>
                Free shipping on orders over AED 500
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Shipping policies may vary based on location and product size.
              </Typography>
            </TabPanel>
          </Paper>
        </Grid>

        {/* Related Products */}
        <Grid item xs={12}>
          <Box sx={{ mt: 6, mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              You Might Also Like
            </Typography>
            <Grid container spacing={3}>
              {/* This would be populated with actual related products in a real implementation */}
              {[1, 2, 3, 4].map((item) => (
                <Grid item key={item} xs={12} sm={6} md={3}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={`https://placehold.co/300x200/random/png?text=Related+Product+${item}`}
                      alt={`Related product ${item}`}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="h3" noWrap>
                        Related Product {item}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        AED {(Math.random() * 1000 + 500).toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductPage;
