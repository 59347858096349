import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { RootState, SearchState } from '../../types/store';
import { ICategory, IProviderWithServices } from '../../types/models';
import { SearchFilters } from '../../types/search';

const initialState: SearchState = {
  results: [],
  categories: [],
  filters: {
    query: '',
    category: undefined,
    location: undefined,
    priceRange: undefined,
    rating: undefined,
    availability: undefined,
  },
  loading: false,
  error: null,
  lastSearchTimestamp: null,
};

// Async thunks
export const searchServices = createAsyncThunk<IProviderWithServices[], SearchFilters>(
  'search/searchServices',
  async (filters) => {
    // Use the providers search endpoint to get professionals instead of services
    const params = new URLSearchParams();
    if (filters.query) params.append('q', filters.query);
    if (filters.category?._id) params.append('category', filters.category._id);

    // For location filtering
    if (filters.location) {
      // If we have coordinates, use them for nearby search
      if (filters.location.coordinates && filters.location.coordinates.length === 2) {
        const [lng, lat] = filters.location.coordinates;
        params.append('lat', lat.toString());
        params.append('lng', lng.toString());
        params.append('radius', (filters.location.radius || 50).toString());
      }
      // Otherwise use the address for text-based filtering
      else if (filters.location.address) {
        params.append('location', filters.location.address);
      }
    }

    try {
      // Use the correct endpoint for searching providers
      const response = await axios.get<{ providers: any[] }>(
        `/search/providers?${params.toString()}`
      );

      console.log('Raw provider search results:', response.data.providers);

      // Transform provider data to match the IProviderProfile interface
      return response.data.providers.map((provider) => {
        // Map all services from the provider
        const services =
          provider.services && provider.services.length > 0
            ? provider.services.map((service: any) => ({
                _id: service._id,
                title: service.title || 'Service',
                description: service.description || 'No description available',
                category: service.category || {
                  _id: 'unknown',
                  name: 'Professional Services',
                  slug: 'services',
                },
                price: service.price || { type: 'fixed', amount: 0 },
                images: service.images || [],
                providerId: provider.user?._id || provider._id, // Use user ID as provider reference
                rating: service.rating || 0,
                reviewCount: service.reviewCount || 0,
                availability: service.availability || { days: [], hours: { start: '', end: '' } },
                createdAt: service.createdAt || new Date(),
                updatedAt: service.updatedAt || new Date(),
              }))
            : [];

        // Create a proper IProviderWithServices object
        const providerProfile: IProviderWithServices = {
          _id: provider.providerId || provider._id || `provider-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Use providerId as _id since server removes _id
          providerId: provider.providerId || provider._id || `provider-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Use providerId or fallback to _id or generate a unique ID
          services: services,
          location: provider.location || provider.provider?.businessAddress || {
            address: 'Location not specified',
            coordinates: [0, 0] as [number, number],
          },
          rating: provider.rating || provider.avgRating || 0,
          reviewCount: provider.reviewCount || 0,
          serviceCount: provider.serviceCount || services.length || 0,
          businessName: provider.businessName || provider.provider?.businessName || 'Professional Service',
          avatar: provider.avatar || provider.provider?.avatar,
          createdAt: provider.createdAt || new Date(),
          updatedAt: provider.updatedAt || new Date(),
        };

        console.log('Transformed provider profile:', providerProfile);
        return providerProfile;
      });
    } catch (error) {
      console.warn('Provider search failed:', error);

      // Return empty array if provider search fails
      return [];
    }
  }
);

export const fetchServiceCategories = createAsyncThunk<ICategory[]>(
  'search/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<{ data: ICategory[] }>('/categories');
      return response.data.data;
    } catch (error) {
      console.warn('Categories not available:', error);
      return rejectWithValue([]);
    }
  }
);

export const fetchPopularCategories = createAsyncThunk<ICategory[]>(
  'search/fetchPopularCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<{ data: ICategory[] }>('/categories/popular');
      return response.data.data;
    } catch (error) {
      console.warn('Popular categories not available:', error);
      return rejectWithValue([]);
    }
  }
);

// Slice
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchFilters: (state, action: PayloadAction<Partial<SearchFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearSearch: (state) => {
      state.results = [];
      state.filters = initialState.filters;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Search Services
      .addCase(searchServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchServices.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
        state.lastSearchTimestamp = Date.now();
      })
      .addCase(searchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to search services';
      })
      // Fetch Categories
      .addCase(fetchServiceCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchServiceCategories.rejected, (state, action) => {
        state.loading = false;
        state.categories = []; // Provide empty array as fallback
        // Don't set error state since we're handling it gracefully
      })
      // Fetch Popular Categories
      .addCase(fetchPopularCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPopularCategories.fulfilled, (state, action) => {
        state.loading = false;
        // Always update with popular categories
        state.categories = action.payload;
      })
      .addCase(fetchPopularCategories.rejected, (state, action) => {
        state.loading = false;
        // Don't set error state since we're handling it gracefully
      });
  },
});

// Actions
export const { setSearchFilters, clearSearch } = searchSlice.actions;

// Selectors
export const selectSearchResults = (state: RootState) => state.search.results;
export const selectSearchLoading = (state: RootState) => state.search.loading;
export const selectSearchFilters = (state: RootState) => state.search.filters;
export const selectCategories = (state: RootState) => state.search.categories;
export const selectSearchError = (state: RootState) => state.search.error;

export default searchSlice.reducer;
