import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnboardingStatus, useAllOnboardingData } from '../hooks/api/useOnboardingApi';
import {
  OnboardingStepId,
  OnboardingStatus,
  OnboardingData,
  STEP_ORDER,
  ONBOARDING_STEPS,
} from '../types/onboarding/shared';

/**
 * Onboarding context type definition
 */
interface OnboardingContextType {
  // Step navigation
  currentStepId: OnboardingStepId;
  setCurrentStepId: (stepId: OnboardingStepId) => void;

  // UI state
  isStepFormDirty: boolean;
  setStepFormDirty: (isDirty: boolean) => void;

  // Navigation helpers
  canAccessStep: (stepId: OnboardingStepId) => boolean;
  getNextStep: (currentStepId: OnboardingStepId) => OnboardingStepId | null;
  getPreviousStep: (currentStepId: OnboardingStepId) => OnboardingStepId | null;

  // Data
  onboardingStatus: OnboardingStatus | null;
  onboardingData: OnboardingData;
  isLoading: boolean;
  error: Error | null;

  // Data fetching
  fetchOnboardingStatus: () => Promise<void>;
}

/**
 * Onboarding provider props
 */
interface OnboardingProviderProps {
  children: ReactNode;
}

// Create the context
const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

/**
 * Provider component for the onboarding context
 */
export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  // Fetch onboarding status and data
  const {
    data: onboardingStatus,
    isLoading: isStatusLoading,
    error: statusError,
    refetch: refetchOnboardingStatus,
  } = useOnboardingStatus();

  const {
    data: onboardingData,
    isLoading: isDataLoading,
    error: dataError,
  } = useAllOnboardingData();

  // Function to manually fetch onboarding status
  const fetchOnboardingStatus = async () => {
    console.log('Manually fetching onboarding status');
    await refetchOnboardingStatus();
  };

  // Local state
  const [currentStepId, setCurrentStepId] = useState<OnboardingStepId>(
    OnboardingStepId.BUSINESS_PROFILE
  );
  const [isStepFormDirty, setStepFormDirty] = useState(false);

  // Update current step when onboarding status changes
  useEffect(() => {
    if (onboardingStatus) {
      setCurrentStepId(onboardingStatus.currentStepId);
    }
  }, [onboardingStatus]);

  // Helper functions for step navigation
  const canAccessStep = (stepId: OnboardingStepId): boolean => {
    if (!onboardingStatus) return stepId === OnboardingStepId.BUSINESS_PROFILE;

    // If onboarding is complete, all steps can be accessed
    if (onboardingStatus.isComplete) return true;

    // If the step is already completed, it can be accessed
    if (onboardingStatus.completedSteps.includes(stepId)) return true;

    // If it's the current step or an optional step before the current step, it can be accessed
    const stepOrder = STEP_ORDER[stepId];
    const currentStepOrder = STEP_ORDER[onboardingStatus.currentStepId];

    if (stepOrder === currentStepOrder) return true;

    if (stepOrder < currentStepOrder) {
      const stepConfig = ONBOARDING_STEPS.find((step) => step.id === stepId);
      return stepConfig?.optional || false;
    }

    return false;
  };

  const getNextStep = (stepId: OnboardingStepId): OnboardingStepId | null => {
    const currentOrder = STEP_ORDER[stepId];
    const nextStepConfig = ONBOARDING_STEPS.find(
      (step) => STEP_ORDER[step.id] === currentOrder + 1
    );
    return nextStepConfig?.id || null;
  };

  const getPreviousStep = (stepId: OnboardingStepId): OnboardingStepId | null => {
    const currentOrder = STEP_ORDER[stepId];
    if (currentOrder === 0) return null;

    const prevStepConfig = ONBOARDING_STEPS.find(
      (step) => STEP_ORDER[step.id] === currentOrder - 1
    );
    return prevStepConfig?.id || null;
  };

  // Combine loading states and errors
  const isLoading = isStatusLoading || isDataLoading;
  const error = statusError || dataError || null;

  return (
    <OnboardingContext.Provider
      value={{
        currentStepId,
        setCurrentStepId,
        isStepFormDirty,
        setStepFormDirty,
        canAccessStep,
        getNextStep,
        getPreviousStep,
        onboardingStatus: onboardingStatus || null,
        onboardingData: onboardingData || {},
        isLoading,
        error,
        fetchOnboardingStatus,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

/**
 * Hook to use the onboarding context
 */
export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};
