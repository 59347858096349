import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
  Divider,
  Alert,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse, addMinutes } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useCalendar } from '../../../contexts/CalendarContext';
import { RegularHours, TimeSlot, DayOfWeek } from '../../../types/calendar';

const DAYS_OF_WEEK: DayOfWeek[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const DAY_LABELS: Record<DayOfWeek, string> = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
};

const DEFAULT_WORKING_HOURS: RegularHours = {
  monday: [{ start: '09:00', end: '17:00' }],
  tuesday: [{ start: '09:00', end: '17:00' }],
  wednesday: [{ start: '09:00', end: '17:00' }],
  thursday: [{ start: '09:00', end: '17:00' }],
  friday: [{ start: '09:00', end: '17:00' }],
  saturday: [],
  sunday: [],
};

interface DayScheduleProps {
  day: DayOfWeek;
  timeSlots: TimeSlot[];
  enabled: boolean;
  onToggleDay: (day: DayOfWeek, enabled: boolean) => void;
  onAddTimeSlot: (day: DayOfWeek) => void;
  onRemoveTimeSlot: (day: DayOfWeek, index: number) => void;
  onUpdateTimeSlot: (day: DayOfWeek, index: number, field: 'start' | 'end', value: string) => void;
}

const DaySchedule: React.FC<DayScheduleProps> = ({
  day,
  timeSlots,
  enabled,
  onToggleDay,
  onAddTimeSlot,
  onRemoveTimeSlot,
  onUpdateTimeSlot,
}) => {
  const handleTimeChange = (
    index: number,
    field: 'start' | 'end',
    date: Date | null
  ) => {
    if (date) {
      const timeString = format(date, 'HH:mm');
      onUpdateTimeSlot(day, index, field, timeString);
    }
  };

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1">{DAY_LABELS[day]}</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={(e) => onToggleDay(day, e.target.checked)}
              color="primary"
            />
          }
          label={enabled ? 'Available' : 'Unavailable'}
        />
      </Box>

      {enabled && (
        <>
          {timeSlots.map((slot, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Start Time"
                    value={parse(slot.start, 'HH:mm', new Date())}
                    onChange={(date) => handleTimeChange(index, 'start', date)}
                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="End Time"
                    value={parse(slot.end, 'HH:mm', new Date())}
                    onChange={(date) => handleTimeChange(index, 'end', date)}
                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  color="error"
                  onClick={() => onRemoveTimeSlot(day, index)}
                  disabled={timeSlots.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Button
            startIcon={<AddIcon />}
            onClick={() => onAddTimeSlot(day)}
            variant="outlined"
            size="small"
            sx={{ mt: 1 }}
          >
            Add Time Slot
          </Button>
        </>
      )}
    </Paper>
  );
};

const WorkingHoursScheduler: React.FC = () => {
  const { settings, loading, error, fetchSettings, updateSettings } = useCalendar();
  const [workingHours, setWorkingHours] = useState<RegularHours>(DEFAULT_WORKING_HOURS);
  const [success, setSuccess] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (settings?.workingHours) {
      setWorkingHours(settings.workingHours);
    }
  }, [settings]);

  const handleToggleDay = (day: DayOfWeek, enabled: boolean) => {
    setWorkingHours((prev) => ({
      ...prev,
      [day]: enabled
        ? prev[day].length > 0
          ? prev[day]
          : [{ start: '09:00', end: '17:00' }]
        : [],
    }));
  };

  const handleAddTimeSlot = (day: DayOfWeek) => {
    setWorkingHours((prev) => {
      const lastSlot = prev[day][prev[day].length - 1];
      const startTime = lastSlot.end;
      const endTime = format(
        addMinutes(parse(startTime, 'HH:mm', new Date()), 60),
        'HH:mm'
      );

      return {
        ...prev,
        [day]: [...prev[day], { start: startTime, end: endTime }],
      };
    });
  };

  const handleRemoveTimeSlot = (day: DayOfWeek, index: number) => {
    setWorkingHours((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index),
    }));
  };

  const handleUpdateTimeSlot = (
    day: DayOfWeek,
    index: number,
    field: 'start' | 'end',
    value: string
  ) => {
    setWorkingHours((prev) => ({
      ...prev,
      [day]: prev[day].map((slot, i) =>
        i === index ? { ...slot, [field]: value } : slot
      ),
    }));
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      await updateSettings({ workingHours });
      setSuccess(true);
    } catch (err) {
      console.error('Error saving working hours:', err);
    } finally {
      setSaving(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
  };

  if (loading && !settings) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Working Hours</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Typography variant="body2" color="text.secondary" paragraph>
        Set your regular working hours for each day of the week. Customers will be able to request
        services during these hours.
      </Typography>

      <Divider sx={{ mb: 3 }} />

      {DAYS_OF_WEEK.map((day) => (
        <DaySchedule
          key={day}
          day={day}
          timeSlots={workingHours[day]}
          enabled={workingHours[day].length > 0}
          onToggleDay={handleToggleDay}
          onAddTimeSlot={handleAddTimeSlot}
          onRemoveTimeSlot={handleRemoveTimeSlot}
          onUpdateTimeSlot={handleUpdateTimeSlot}
        />
      ))}

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Working hours saved successfully"
      />
    </Box>
  );
};

export default WorkingHoursScheduler;