import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Paper,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';
import { useAuth } from '../../../../hooks/useAuth';

// Validation schema for a single product
const productSchema = yup.object().shape({
  name: yup.string().required('Product name is required'),
  description: yup.string().required('Description is required'),
  price: yup.number().positive('Price must be positive').required('Price is required'),
  category: yup.string().required('Category is required'),
  images: yup.array().min(1, 'At least one image is required'),
  inventory: yup.number().integer('Must be a whole number').min(0, 'Cannot be negative').required('Inventory is required'),
  specifications: yup.object(),
});

interface Category {
  _id: string;
  name: string;
}

interface ProductFormData {
  name: string;
  description: string;
  price: number;
  category: string;
  images: string[];
  inventory: number;
  specifications: {
    material?: string;
    dimensions?: string;
    weight?: string;
    color?: string;
    [key: string]: any;
  };
}

const ProductsStep: React.FC = () => {
  const { products, addProduct, saveStep, isLoading, goToStep } = useSellerOnboarding();
  const { getAuthToken } = useAuth();

  const [categories, setCategories] = useState<Category[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingProduct, setEditingProduct] = useState<ProductFormData | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [productsList, setProductsList] = useState<ProductFormData[]>(products || []);

  const { control, handleSubmit, reset, formState: { errors } } = useForm<ProductFormData>({
    resolver: yupResolver(productSchema) as any,
    defaultValues: {
      name: '',
      description: '',
      price: 0,
      category: '',
      images: [''],
      inventory: 0,
      specifications: {
        material: '',
        dimensions: '',
        weight: '',
        color: '',
      },
    },
  });

  const { fields: imageFields, append: appendImage, remove: removeImage } = useFieldArray({
    control,
    name: 'images' as any,
  });

  // Fetch categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingCategories(true);
      setError(null);

      try {
        const token = getAuthToken();
        const response = await axios.get('/api/categories', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setCategories(response.data.categories);

      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to fetch categories');
        console.error('Error fetching categories:', err);
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [getAuthToken]);

  const onSubmit = async () => {
    if (productsList.length === 0) {
      setError('Please add at least one product');
      return;
    }

    try {
      // Save each product
      for (const product of productsList) {
        await saveStep(3, product);
      }

      // Move to next step
      goToStep(4);
    } catch (err) {
      console.error('Error saving products:', err);
    }
  };

  const handleBack = () => {
    goToStep(2);
  };

  const handleAddProduct = (data: ProductFormData) => {
    if (editingIndex !== null) {
      // Update existing product
      const updatedProducts = [...productsList];
      updatedProducts[editingIndex] = data;
      setProductsList(updatedProducts);
      addProduct(data);
    } else {
      // Add new product
      setProductsList([...productsList, data]);
      addProduct(data);
    }

    setOpenDialog(false);
    reset();
    setEditingProduct(null);
    setEditingIndex(null);
  };

  const handleEditProduct = (product: ProductFormData, index: number) => {
    setEditingProduct(product);
    setEditingIndex(index);
    setOpenDialog(true);
  };

  const handleDeleteProduct = (index: number) => {
    const updatedProducts = [...productsList];
    updatedProducts.splice(index, 1);
    setProductsList(updatedProducts);
  };

  const handleOpenDialog = () => {
    reset();
    setEditingProduct(null);
    setEditingIndex(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingProduct(null);
    setEditingIndex(null);
  };

  useEffect(() => {
    if (editingProduct) {
      reset(editingProduct);
    }
  }, [editingProduct, reset]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Add products you want to sell on our marketplace
          </Typography>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenDialog}
            >
              Add Product
            </Button>
          </Box>

          {productsList.length === 0 ? (
            <Paper sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="body1" color="text.secondary">
                No products added yet. Click "Add Product" to get started.
              </Typography>
            </Paper>
          ) : (
            <Grid container spacing={2}>
              {productsList.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    {product.images && product.images.length > 0 && (
                      <CardMedia
                        component="img"
                        height="140"
                        image={product.images[0]}
                        alt={product.name}
                      />
                    )}
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {product.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" noWrap>
                        {product.description}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        AED {product.price.toFixed(2)}
                      </Typography>
                      <Typography variant="body2">
                        In Stock: {product.inventory}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton onClick={() => handleEditProduct(product, index)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteProduct(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={isLoading || productsList.length === 0}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save & Continue'}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Product Form Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingProduct ? 'Edit Product' : 'Add New Product'}
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Product Name"
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.category}>
                      <InputLabel>Category</InputLabel>
                      <Select
                        {...field}
                        label="Category"
                        disabled={loadingCategories}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.category && (
                        <FormHelperText>{errors.category.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      fullWidth
                      multiline
                      rows={4}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Price (AED)"
                      type="number"
                      fullWidth
                      error={!!errors.price}
                      helperText={errors.price?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="inventory"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Inventory"
                      type="number"
                      fullWidth
                      error={!!errors.inventory}
                      helperText={errors.inventory?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Product Images
                </Typography>

                {imageFields.map((field, index) => (
                  <Box key={field.id} sx={{ display: 'flex', mb: 2 }}>
                    <Controller
                      name={`images.${index}`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={`Image URL ${index + 1}`}
                          fullWidth
                          error={!!errors.images?.[index]}
                          helperText={errors.images?.[index]?.message}
                        />
                      )}
                    />
                    <IconButton
                      onClick={() => removeImage(index)}
                      disabled={imageFields.length === 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}

                <Button
                  startIcon={<AddIcon />}
                  onClick={() => appendImage('')}
                  variant="outlined"
                  size="small"
                >
                  Add Image
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Specifications (Optional)
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Add key specifications for your product
                </Typography>

                <Controller
                  name="specifications.material"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Material"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}
                />

                <Controller
                  name="specifications.dimensions"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Dimensions"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}
                />

                <Controller
                  name="specifications.weight"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Weight"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}
                />

                <Controller
                  name="specifications.color"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Color"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit(handleAddProduct)}
            variant="contained"
          >
            {editingProduct ? 'Update Product' : 'Add Product'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductsStep;