import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import { Google as GoogleIcon, Apple as AppleIcon } from '@mui/icons-material';
import { RootState } from '../../redux/store';
import { loginStart, loginFailure } from '../../redux/slices/authSlice';

export const CheckoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const cart = useSelector((state: RootState) => state.cart);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [guestEmail, setGuestEmail] = useState('');

  useEffect(() => {
    // Force a brief authentication check to ensure loading state is shown
    const checkAuth = async () => {
      dispatch(loginStart());

      // Simulate a brief delay to ensure the loading state is visible
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Reset the loading state
      dispatch(loginFailure(''));
      setCheckingAuth(false);
    };

    checkAuth();
  }, [dispatch]);

  // If cart is empty, redirect to cart page
  if (cart.items.length === 0) {
    return <Navigate to="/marketplace/cart" replace />;
  }

  // Show loading indicator while checking auth
  if (checkingAuth) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={8}>
          <CircularProgress />
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
            Checking authentication...
          </Typography>
        </Box>
      </Container>
    );
  }

  // If already authenticated, redirect to shipping page
  if (isAuthenticated) {
    return <Navigate to="/marketplace/checkout/shipping" replace />;
  }

  const handleGuestContinue = () => {
    if (guestEmail) {
      // Store guest email in localStorage or state management
      localStorage.setItem('guestEmail', guestEmail);
      navigate('/marketplace/checkout/shipping');
    }
  };

  const handleEmailSignIn = () => {
    navigate('/login', { state: { from: '/marketplace/checkout' } });
  };

  const handleGoogleSignIn = () => {
    // Implement Google sign-in
    navigate('/auth/google/callback', { state: { from: '/marketplace/checkout' } });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Checkout
      </Typography>

      <Grid container spacing={4}>
        {/* Left Column - Guest Checkout */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: { xs: 2, md: 4 }, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Typography variant="h5" gutterBottom>
              Checkout as a Guest
            </Typography>

            <Box component="form" sx={{ mt: 3 }}>
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                value={guestEmail}
                onChange={(e) => setGuestEmail(e.target.value)}
                margin="normal"
                type="email"
                required
                sx={{ mb: 3 }}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={handleGuestContinue}
                disabled={!guestEmail}
                sx={{
                  py: 1.5,
                  backgroundColor: '#333',
                  '&:hover': {
                    backgroundColor: '#000',
                  },
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Right Column - Sign In */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: { xs: 2, md: 4 }, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Typography variant="h5" gutterBottom>
              Sign In / Register
            </Typography>

            <Typography variant="body2" color="text.secondary" paragraph>
              Sign in to your Homezy account for faster checkout
            </Typography>

            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={handleEmailSignIn}
              sx={{
                py: 1.5,
                mb: 2,
                borderColor: '#e0e0e0',
                color: '#333',
                '&:hover': {
                  borderColor: '#333',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              Sign In with Email
            </Button>

            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={handleGoogleSignIn}
              startIcon={<GoogleIcon />}
              sx={{
                py: 1.5,
                mb: 2,
                borderColor: '#e0e0e0',
                color: '#333',
                '&:hover': {
                  borderColor: '#333',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              Continue with Google
            </Button>

            <Button
              fullWidth
              variant="outlined"
              size="large"
              startIcon={<AppleIcon />}
              sx={{
                py: 1.5,
                borderColor: '#e0e0e0',
                color: '#333',
                '&:hover': {
                  borderColor: '#333',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              Continue with Apple
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Order Summary - Hidden on this page but will be shown on shipping page */}
      <Box sx={{ display: 'none' }}>
        <Paper sx={{ p: 4, mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Order Summary
          </Typography>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1">
              Total Items: {cart.items.length}
            </Typography>
            <Typography variant="body1">
              Subtotal: ${cart.subtotal.toFixed(2)}
            </Typography>
            <Typography variant="body1">
              Shipping: Free
            </Typography>
            <Typography variant="body1">
              Tax: ${cart.tax.toFixed(2)}
            </Typography>
            <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
              Total: ${cart.total.toFixed(2)}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default CheckoutPage;