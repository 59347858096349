import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Paper,
  Grid,
  Checkbox,
  FormGroup,
} from '@mui/material';
import { PreferenceQuestion, CustomerPreference } from '../../../types/preference';
import PreferenceQuestions from '../PreferenceQuestions';
import CollapsibleSection from '../CollapsibleSection';

interface PropertyTypeStepProps {
  formData: {
    service?: string;
    projectType?: string;
    propertyType?: string;
    spaces?: string[];
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
  serviceQuestions: PreferenceQuestion[];
  preferences: CustomerPreference[];
  onPreferencesChange: (preferences: CustomerPreference[]) => void;
}

const PropertyTypeStep: React.FC<PropertyTypeStepProps> = ({
  formData,
  onChange,
  onNext,
  onBack,
  serviceQuestions,
  preferences,
  onPreferencesChange,
}) => {
  // Get selected options for summary
  const getSelectedOptionsSummary = (questions: PreferenceQuestion[]): string => {
    if (!questions.length) return '';

    const selectedOptions = questions.flatMap(q => {
      const pref = preferences.find(p => p.questionId === q._id);
      if (!pref || pref.selectedOptions.length === 0) return [];

      return pref.selectedOptions.map(opt => {
        const option = q.options.find(o => o.value === opt);
        return option ? option.optionText : opt;
      });
    });

    return selectedOptions.join(' • ');
  };

  // Filter property type questions
  const propertyTypeQuestions = serviceQuestions.filter(
    q => q.questionText.toLowerCase().includes('property') ||
         q.questionText.toLowerCase().includes('home') ||
         q.questionText.toLowerCase().includes('house')
  );

  // Filter space questions - specifically for renovation-related services
  const spaceQuestions = serviceQuestions.filter(
    q => (q.questionText.toLowerCase().includes('space') ||
         q.questionText.toLowerCase().includes('room') ||
         q.questionText.toLowerCase().includes('area')) &&
         // Only include renovation-related questions
         (q.questionText.toLowerCase().includes('renovate') ||
          q.questionText.toLowerCase().includes('renovation') ||
          q.questionText.toLowerCase().includes('remodel'))
  );

  // Handle space selection
  const handleSpaceChange = (space: string, checked: boolean) => {
    const currentSpaces = formData.spaces || [];
    let newSpaces;

    if (checked) {
      newSpaces = [...currentSpaces, space];
    } else {
      newSpaces = currentSpaces.filter(s => s !== space);
    }

    onChange('spaces', newSpaces);
  };

  // Check if form is valid
  const isValid = () => {
    // If we have property questions, check if all required ones have answers
    const allQuestions = [...propertyTypeQuestions, ...spaceQuestions];

    if (allQuestions.length > 0) {
      const requiredQuestions = allQuestions.filter(q => q.isRequired);

      for (const question of requiredQuestions) {
        const preference = preferences.find(p => p.questionId === question._id);

        if (!preference ||
            (preference.selectedOptions.length === 0 && !preference.customAnswer)) {
          return false;
        }
      }

      return true;
    }

    // If no property questions, check if the default property type is selected
    return !!formData.propertyType;
  };

  return (
    <Paper elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
      <Typography variant="h5" gutterBottom>
        Property Details
      </Typography>

      {propertyTypeQuestions.length > 0 ? (
        <CollapsibleSection
          title="Property type"
          summary={getSelectedOptionsSummary(propertyTypeQuestions)}
          defaultExpanded={true}
        >
          <PreferenceQuestions
            questions={propertyTypeQuestions}
            preferences={preferences}
            onPreferencesChange={onPreferencesChange}
          />
        </CollapsibleSection>
      ) : (
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography variant="subtitle1" gutterBottom>
            What type of property do you have?
          </Typography>
          <RadioGroup
            value={formData.propertyType || ''}
            onChange={(e) => onChange('propertyType', e.target.value)}
          >
            <FormControlLabel
              value="apartment"
              control={<Radio />}
              label="Apartment"
            />
            <FormControlLabel
              value="villa"
              control={<Radio />}
              label="Villa"
            />
            <FormControlLabel
              value="office"
              control={<Radio />}
              label="Office"
            />
            <FormControlLabel
              value="commercial"
              control={<Radio />}
              label="Commercial space"
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="Other"
            />
          </RadioGroup>
        </FormControl>
      )}

      {(() => {
        // Check if this is a renovation service by looking at the service ID
        const isRenovationService = formData.service?.toLowerCase().includes('renovat') ||
                                   formData.service?.toLowerCase().includes('remodel');

        if (spaceQuestions.length > 0) {
          return (
            <CollapsibleSection
              title={spaceQuestions.some(q =>
                q.questionText.toLowerCase().includes('renovate') ||
                q.questionText.toLowerCase().includes('renovation') ||
                q.questionText.toLowerCase().includes('remodel')
              ) ? "What spaces will be renovated?" : "Which spaces are involved?"}
              summary={getSelectedOptionsSummary(spaceQuestions)}
              defaultExpanded={propertyTypeQuestions.length === 0}
            >
              <PreferenceQuestions
                questions={spaceQuestions}
                preferences={preferences}
                onPreferencesChange={onPreferencesChange}
              />
            </CollapsibleSection>
          );
        } else if (isRenovationService) {
          return (
            <Box sx={{ mt: 4 }}>
              <Typography variant="subtitle1" gutterBottom>
                What spaces will be renovated?
              </Typography>
              <FormGroup>
                <Grid container spacing={2}>
                  {['Living room', 'Bedroom', 'Kitchen', 'Bathroom', 'Closet', 'Basement', 'Attic'].map((space) => (
                    <Grid item xs={12} sm={6} key={space}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(formData.spaces || []).includes(space.toLowerCase())}
                            onChange={(e) => handleSpaceChange(space.toLowerCase(), e.target.checked)}
                          />
                        }
                        label={space}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </Box>
          );
        } else {
          return null;
        }
      })()}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onNext}
          disabled={!isValid()}
        >
          Next
        </Button>
      </Box>
    </Paper>
  );
};

export default PropertyTypeStep;