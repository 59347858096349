import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Slider,
  Divider,
} from '@mui/material';
import { useOnboarding } from '../../../../contexts/OnboardingContext';
import { OnboardingStepId } from '../../../../types/onboarding/shared';
import { useOnboardingStepData, useSaveStep } from '../../../../hooks/api/useOnboardingApi';
import { OnboardingLayout } from '../OnboardingLayout';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`service-tabpanel-${index}`}
      aria-labelledby={`service-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

// Standard preference options for all services
const standardPreferenceOptions = [
  {
    type: 'radio',
    name: 'jobSize',
    label: 'Preferred Job Size',
    options: [
      { value: 'Small', label: 'Small' },
      { value: 'Medium', label: 'Medium' },
      { value: 'Large', label: 'Large' },
      { value: 'Any', label: 'Any' },
    ],
  },
  {
    type: 'checkbox',
    name: 'availability',
    label: 'Availability',
    options: [
      { value: 'Weekdays', label: 'Weekdays' },
      { value: 'Evenings', label: 'Evenings' },
      { value: 'Weekends', label: 'Weekends' },
      { value: 'Holidays', label: 'Holidays' },
    ],
  },
  {
    type: 'slider',
    name: 'maxDistance',
    label: 'Maximum Travel Distance (km)',
    min: 5,
    max: 50,
    step: 5,
  },
  {
    type: 'select',
    name: 'responseTime',
    label: 'Target Response Time',
    options: [
      { value: 'Within 1 hour', label: 'Within 1 hour' },
      { value: 'Same day', label: 'Same day' },
      { value: 'Within 24 hours', label: 'Within 24 hours' },
      { value: 'Within 48 hours', label: 'Within 48 hours' },
    ],
  },
];

/**
 * Target Preferences step component
 */
const TargetPreferencesStep: React.FC = () => {
  const navigate = useNavigate();
  const { getNextStep, getPreviousStep } = useOnboarding();

  // Get target preferences data
  const { data: stepData, isLoading: isDataLoading } = useOnboardingStepData<any[]>(
    OnboardingStepId.TARGET_PREFERENCES
  );

  // Get services data
  const { data: servicesData, isLoading: isServicesLoading } = useOnboardingStepData<string[]>(
    OnboardingStepId.SERVICES
  );

  const { mutateAsync: saveStepAsync } = useSaveStep();

  const [services, setServices] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [preferences, setPreferences] = useState<Record<string, any>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Process services data when it's loaded
  useEffect(() => {
    if (servicesData && servicesData.length > 0) {
      // Format services for the component
      const formattedServices = servicesData.map((serviceId, index) => ({
        _id: serviceId,
        name: `Service ${index + 1}`,
        preferenceOptions: standardPreferenceOptions,
      }));

      setServices(formattedServices);
    } else if (servicesData && !isServicesLoading) {
      // If no services and data is loaded, redirect to services step
      navigate(`/pro/onboarding/${OnboardingStepId.SERVICES}`);
    }
  }, [servicesData, isServicesLoading, navigate]);

  // Load existing preferences
  useEffect(() => {
    if (stepData && stepData.length > 0 && services.length > 0) {
      const newPreferences: Record<string, any> = {};

      stepData.forEach((pref) => {
        newPreferences[pref.serviceId] = pref.preferences;
      });

      setPreferences(newPreferences);
    }
  }, [stepData, services]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handlePreferenceChange = (serviceId: string, name: string, value: any) => {
    setPreferences((prev) => ({
      ...prev,
      [serviceId]: {
        ...(prev[serviceId] || {}),
        [name]: value,
      },
    }));
  };

  const handleBack = () => {
    const prevStep = getPreviousStep(OnboardingStepId.TARGET_PREFERENCES);
    if (prevStep) {
      navigate(`/pro/onboarding/${prevStep}`);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSaving(true);
    setError(null);

    try {
      // Prepare preferences data
      const preferencesData = Object.keys(preferences).map((serviceId) => ({
        serviceId,
        preferences: preferences[serviceId] || {},
      }));

      // Save to server
      const result = await saveStepAsync({
        stepId: OnboardingStepId.TARGET_PREFERENCES,
        data: preferencesData,
      });

      if (result.success) {
        const nextStep = getNextStep(OnboardingStepId.TARGET_PREFERENCES);
        if (nextStep) {
          navigate(`/pro/onboarding/${nextStep}`);
        }
      } else {
        setError('Failed to save preferences. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while saving. Please try again.');
      console.error('Error saving preferences:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const renderPreferenceField = (service: any, option: any) => {
    const serviceId = service._id;
    const currentPreferences = preferences[serviceId] || {};
    const value = currentPreferences[option.name];

    switch (option.type) {
      case 'radio':
        return (
          <FormControl component="fieldset" sx={{ mb: 3 }} key={option.name}>
            <FormLabel component="legend">{option.label}</FormLabel>
            <RadioGroup
              value={value || ''}
              onChange={(e) => handlePreferenceChange(serviceId, option.name, e.target.value)}
            >
              {option.options?.map((opt: any) => (
                <FormControlLabel
                  key={opt.value}
                  value={opt.value}
                  control={<Radio />}
                  label={opt.label}
                  disabled={isSaving}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case 'checkbox':
        return (
          <FormControl component="fieldset" sx={{ mb: 3 }} key={option.name}>
            <FormLabel component="legend">{option.label}</FormLabel>
            <Box sx={{ ml: 3 }}>
              {option.options?.map((opt: any) => (
                <FormControlLabel
                  key={opt.value}
                  control={
                    <Radio
                      checked={value === opt.value}
                      onChange={() => handlePreferenceChange(serviceId, option.name, opt.value)}
                      disabled={isSaving}
                    />
                  }
                  label={opt.label}
                />
              ))}
            </Box>
          </FormControl>
        );

      case 'slider':
        return (
          <Box sx={{ mb: 3 }} key={option.name}>
            <Typography id={`slider-${option.name}`} gutterBottom>
              {option.label}: {value || option.min || 0}
            </Typography>
            <Slider
              value={value || option.min || 0}
              onChange={(_e, newValue) =>
                handlePreferenceChange(serviceId, option.name, newValue)
              }
              aria-labelledby={`slider-${option.name}`}
              valueLabelDisplay="auto"
              step={option.step || 1}
              min={option.min || 0}
              max={option.max || 100}
              disabled={isSaving}
            />
          </Box>
        );

      case 'select':
        return (
          <FormControl fullWidth sx={{ mb: 3 }} key={option.name}>
            <FormLabel>{option.label}</FormLabel>
            <select
              value={value || ''}
              onChange={(e) => handlePreferenceChange(serviceId, option.name, e.target.value)}
              disabled={isSaving}
              style={{
                padding: '8px',
                marginTop: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            >
              <option value="">Select an option</option>
              {option.options?.map((opt: any) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </FormControl>
        );

      case 'text':
      default:
        return (
          <TextField
            key={option.name}
            fullWidth
            label={option.label}
            value={value || ''}
            onChange={(e) => handlePreferenceChange(serviceId, option.name, e.target.value)}
            sx={{ mb: 3 }}
            disabled={isSaving}
          />
        );
    }
  };

  if (isDataLoading || isServicesLoading || services.length === 0) {
    return (
      <OnboardingLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title="Target Preferences"
      description="Set your preferences for each service to help us match you with the right customers."
    >
      <Box component="form" onSubmit={handleSubmit} noValidate>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Paper variant="outlined" sx={{ mb: 3 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            {services.map((service, index) => (
              <Tab key={service._id} label={service.name} id={`service-tab-${index}`} />
            ))}
          </Tabs>

          {services.map((service, index) => (
            <TabPanel key={service._id} value={activeTab} index={index}>
              <Typography variant="h6" gutterBottom>
                {service.name} Preferences
              </Typography>

              {!service.preferenceOptions || service.preferenceOptions.length === 0 ? (
                <Typography color="text.secondary">
                  No specific preferences available for this service.
                </Typography>
              ) : (
                service.preferenceOptions.map((option: any) =>
                  renderPreferenceField(service, option)
                )
              )}

              <Divider sx={{ my: 2 }} />

              <Typography variant="subtitle1" gutterBottom>
                Additional Notes
              </Typography>

              <TextField
                fullWidth
                multiline
                rows={3}
                label="Any other preferences for this service?"
                value={preferences[service._id]?.notes || ''}
                onChange={(e) => handlePreferenceChange(service._id, 'notes', e.target.value)}
                placeholder="E.g., specific equipment you use, specialized techniques, etc."
                disabled={isSaving}
              />
            </TabPanel>
          ))}
        </Paper>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleBack} disabled={isSaving}>
            Back
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {isSaving ? 'Saving...' : 'Continue'}
          </Button>
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default TargetPreferencesStep;
