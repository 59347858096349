import React, { useState, useEffect } from 'react';
import imageLoader from '../../utils/imageLoader';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Container,
  useTheme,
  useMediaQuery,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Room data structure
interface Room {
  id: string;
  name: string;
  description: string;
  projectCount: number;
  image: string;
  imageAttribution: {
    name: string;
    username: string;
    link: string;
  };
}

// Default room data (images will be loaded from Pexels API)
const roomsData: Room[] = [
  {
    id: 'kitchen',
    name: 'Kitchen',
    description: 'Cooking & dining spaces',
    projectCount: 1245,
    image: '', // Will be populated from Pexels API
    imageAttribution: {
      name: '',
      username: '',
      link: '',
    },
  },
  {
    id: 'bathroom',
    name: 'Bathroom',
    description: 'Bathrooms & powder rooms',
    projectCount: 987,
    image: '', // Will be populated from Pexels API
    imageAttribution: {
      name: '',
      username: '',
      link: '',
    },
  },
  {
    id: 'living-room',
    name: 'Living Room',
    description: 'Family & entertainment spaces',
    projectCount: 1432,
    image: '', // Will be populated from Pexels API
    imageAttribution: {
      name: '',
      username: '',
      link: '',
    },
  },
  {
    id: 'bedroom',
    name: 'Bedroom',
    description: 'Bedrooms & closets',
    projectCount: 1089,
    image: '', // Will be populated from Pexels API
    imageAttribution: {
      name: '',
      username: '',
      link: '',
    },
  },
  {
    id: 'outdoor',
    name: 'Outdoor',
    description: 'Patios, decks & landscapes',
    projectCount: 876,
    image: '', // Will be populated from Pexels API
    imageAttribution: {
      name: '',
      username: '',
      link: '',
    },
  },
  {
    id: 'home-office',
    name: 'Home Office',
    description: 'Workspaces & studies',
    projectCount: 654,
    image: '', // Will be populated from Pexels API
    imageAttribution: {
      name: '',
      username: '',
      link: '',
    },
  },
];

// Fallback image in case API fails
const FALLBACK_IMAGE = 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

const RoomBrowser: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [rooms, setRooms] = useState<Room[]>(roomsData);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch room images from Pexels API
  useEffect(() => {
    const fetchRoomImages = async () => {
      try {
        // Create a copy of the rooms data to update
        const updatedRooms = [...rooms];

        // Fetch images for each room in parallel
        const promises = updatedRooms.map(async (room, index) => {
          try {
            // Get a random image for the room category
            const imageResult = await imageLoader.getRandomPexelsImage(room.id.replace('-', ' '), {
              orientation: 'landscape',
              size: 'large',
            });

            if (imageResult) {
              // Update the room with the image data
              updatedRooms[index] = {
                ...room,
                image: imageResult.url,
                imageAttribution: {
                  name: imageResult.photographer,
                  username: 'pexels',
                  link: imageResult.photographerUrl,
                },
              };
            } else {
              // Use fallback image if no results
              updatedRooms[index] = {
                ...room,
                image: FALLBACK_IMAGE,
                imageAttribution: {
                  name: 'Pexels',
                  username: 'pexels',
                  link: 'https://www.pexels.com',
                },
              };
            }
          } catch (error) {
            console.error(`Error fetching image for ${room.id}:`, error);
            // Use fallback image on error
            updatedRooms[index] = {
              ...room,
              image: FALLBACK_IMAGE,
              imageAttribution: {
                name: 'Pexels',
                username: 'pexels',
                link: 'https://www.pexels.com',
              },
            };
          }
        });

        // Wait for all image fetches to complete
        await Promise.all(promises);

        // Update state with the new room data
        setRooms(updatedRooms);
      } catch (error) {
        console.error('Error fetching room images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoomImages();
  }, []);

  return (
    <Box sx={{ py: 8, bgcolor: theme.palette.background.paper }}>
      <Container maxWidth="lg">
        <Typography variant="h3" component="h2" align="center" gutterBottom sx={{ mb: 2 }}>
          Browse Ideas by Room
        </Typography>
        <Typography
          variant="h6"
          component="p"
          align="center"
          color="text.secondary"
          sx={{ mb: 6, maxWidth: 700, mx: 'auto' }}
        >
          Discover design ideas, find professionals, and shop products for every space in your home
        </Typography>

        <Grid container spacing={3}>
          {rooms.map((room) => (
            <Grid item xs={12} sm={6} md={4} key={room.id}>
              <Card
                sx={{
                  height: '100%',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                  },
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <CardActionArea onClick={() => navigate(`/ideas/rooms/${room.id}`)}>
                  <CardMedia
                    component="img"
                    height="240"
                    image={room.image || FALLBACK_IMAGE}
                    alt={room.name}
                    sx={{
                      transition: 'transform 0.5s ease, opacity 0.3s ease, filter 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      opacity: loading ? 0.7 : 1,
                      filter: loading ? 'blur(5px)' : 'none',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom>
                      {room.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph>
                      {room.description}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="body2" color="primary">
                        {room.projectCount.toLocaleString()} projects
                      </Typography>
                      {!loading && room.imageAttribution && room.imageAttribution.name && (
                        <Typography variant="caption" color="text.secondary">
                          Photo by{' '}
                          <Link
                            href={room.imageAttribution.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            color="inherit"
                          >
                            {room.imageAttribution.name}
                          </Link>
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
              '&:hover': { textDecoration: 'underline' },
              fontWeight: 500,
            }}
            onClick={() => navigate('/ideas/rooms')}
          >
            View all room categories →
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default RoomBrowser;
