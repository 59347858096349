import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';
import { PreferenceQuestion, CustomerPreference } from '../../types/preference';

interface PreferenceQuestionsProps {
  questions: PreferenceQuestion[];
  preferences: CustomerPreference[];
  onPreferencesChange: (preferences: CustomerPreference[]) => void;
}

const PreferenceQuestions: React.FC<PreferenceQuestionsProps> = ({
  questions,
  preferences,
  onPreferencesChange,
}) => {
  // Find a preference by question ID
  const findPreference = (questionId: string): CustomerPreference | undefined => {
    return preferences.find((p) => p.questionId === questionId);
  };

  // Handle single select change
  const handleSingleSelectChange = (questionId: string, value: string) => {
    const existingPreference = findPreference(questionId);
    const updatedPreferences = [...preferences];

    if (existingPreference) {
      // Update existing preference
      const index = preferences.findIndex((p) => p.questionId === questionId);
      updatedPreferences[index] = {
        ...existingPreference,
        selectedOptions: [value],
      };
    } else {
      // Create new preference
      updatedPreferences.push({
        questionId,
        selectedOptions: [value],
      });
    }

    onPreferencesChange(updatedPreferences);
  };

  // Handle multi select change
  const handleMultiSelectChange = (questionId: string, value: string, checked: boolean) => {
    const existingPreference = findPreference(questionId);
    const updatedPreferences = [...preferences];

    if (existingPreference) {
      // Update existing preference
      const index = preferences.findIndex((p) => p.questionId === questionId);
      let selectedOptions = [...existingPreference.selectedOptions];

      if (checked) {
        // Add option if checked
        selectedOptions.push(value);
      } else {
        // Remove option if unchecked
        selectedOptions = selectedOptions.filter((opt) => opt !== value);
      }

      updatedPreferences[index] = {
        ...existingPreference,
        selectedOptions,
      };
    } else {
      // Create new preference
      updatedPreferences.push({
        questionId,
        selectedOptions: checked ? [value] : [],
      });
    }

    onPreferencesChange(updatedPreferences);
  };

  // Handle text input change
  const handleTextChange = (questionId: string, value: string) => {
    const existingPreference = findPreference(questionId);
    const updatedPreferences = [...preferences];

    if (existingPreference) {
      // Update existing preference
      const index = preferences.findIndex((p) => p.questionId === questionId);
      updatedPreferences[index] = {
        ...existingPreference,
        customAnswer: value,
      };
    } else {
      // Create new preference
      updatedPreferences.push({
        questionId,
        selectedOptions: [],
        customAnswer: value,
      });
    }

    onPreferencesChange(updatedPreferences);
  };

  // Check if an option is selected
  const isOptionSelected = (questionId: string, value: string): boolean => {
    const preference = findPreference(questionId);
    return preference ? preference.selectedOptions.includes(value) : false;
  };

  // Get custom answer for text questions
  const getCustomAnswer = (questionId: string): string => {
    const preference = findPreference(questionId);
    return preference?.customAnswer || '';
  };

  // Render a single select question
  const renderSingleSelect = (question: PreferenceQuestion) => {
    const preference = findPreference(question._id);
    const selectedValue = preference?.selectedOptions[0] || '';

    return (
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel component="legend">{question.questionText}</FormLabel>
        <RadioGroup
          value={selectedValue}
          onChange={(e) => handleSingleSelectChange(question._id, e.target.value)}
        >
          {question.options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.optionText}
            />
          ))}
        </RadioGroup>
        {question.isRequired && !selectedValue && (
          <FormHelperText error>This field is required</FormHelperText>
        )}
      </FormControl>
    );
  };

  // Render a multi select question
  const renderMultiSelect = (question: PreferenceQuestion) => {
    return (
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel component="legend">{question.questionText}</FormLabel>
        <FormGroup>
          {question.options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={isOptionSelected(question._id, option.value)}
                  onChange={(e) =>
                    handleMultiSelectChange(question._id, option.value, e.target.checked)
                  }
                />
              }
              label={option.optionText}
            />
          ))}
        </FormGroup>
        {question.isRequired &&
          (!findPreference(question._id) || findPreference(question._id)?.selectedOptions.length === 0) && (
            <FormHelperText error>This field is required</FormHelperText>
          )}
      </FormControl>
    );
  };

  // Render a text question
  const renderTextQuestion = (question: PreferenceQuestion) => {
    return (
      <FormControl component="fieldset" fullWidth margin="normal">
        <TextField
          label={question.questionText}
          multiline
          rows={4}
          value={getCustomAnswer(question._id)}
          onChange={(e) => handleTextChange(question._id, e.target.value)}
          required={question.isRequired}
          error={question.isRequired && !getCustomAnswer(question._id)}
          helperText={
            question.isRequired && !getCustomAnswer(question._id) ? 'This field is required' : ''
          }
        />
      </FormControl>
    );
  };

  // Render a question based on its type
  const renderQuestion = (question: PreferenceQuestion) => {
    switch (question.questionType) {
      case 'single_select':
        return renderSingleSelect(question);
      case 'multi_select':
        return renderMultiSelect(question);
      case 'text':
        return renderTextQuestion(question);
      default:
        return null;
    }
  };

  if (questions.length === 0) {
    return <Typography>No questions available.</Typography>;
  }

  return (
    <Box>
      {questions.map((question) => (
        <Box key={question._id} sx={{ mb: 3 }}>
          {renderQuestion(question)}
        </Box>
      ))}
    </Box>
  );
};

export default PreferenceQuestions;