import React from 'react';
import { Chip } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import StarIcon from '@mui/icons-material/Star';

interface ReviewSourceBadgeProps {
  source: 'homezy' | 'google' | 'email';
}

/**
 * Component for displaying a badge indicating the source of a review
 * Used in review listings to differentiate between platform reviews and imported reviews
 */
const ReviewSourceBadge: React.FC<ReviewSourceBadgeProps> = ({ source }) => {
  switch (source) {
    case 'google':
      return (
        <Chip
          icon={<GoogleIcon />}
          label="Google"
          size="small"
          variant="outlined"
          color="primary"
          sx={{ ml: 1 }}
        />
      );
    case 'email':
      return (
        <Chip
          icon={<EmailIcon />}
          label="Email Review"
          size="small"
          variant="outlined"
          color="secondary"
          sx={{ ml: 1 }}
        />
      );
    case 'homezy':
    default:
      return (
        <Chip
          icon={<StarIcon />}
          label="Verified"
          size="small"
          variant="outlined"
          color="success"
          sx={{ ml: 1 }}
        />
      );
  }
};

export default ReviewSourceBadge;