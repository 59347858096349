import React from 'react';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  ViewModule as GridIcon,
  ViewList as ListIcon,
  Share as ShareIcon,
  PersonAdd as InviteIcon,
  Add as AddIcon,
  Photo as PhotoIcon,
  ShoppingBag as ProductIcon,
  Person as ProfessionalIcon,
  Article as StoryIcon,
  Forum as DiscussionIcon,
} from '@mui/icons-material';

interface IdeabookToolbarProps {
  onLayoutChange: (layout: 'grid' | 'list') => void;
  onShare: () => void;
  onInvite: () => void;
  onAddNew: () => void;
  currentLayout: 'grid' | 'list';
}

const IdeabookToolbar: React.FC<IdeabookToolbarProps> = ({
  onLayoutChange,
  onShare,
  onInvite,
  onAddNew,
  currentLayout,
}) => {
  const [addMenuAnchor, setAddMenuAnchor] = React.useState<null | HTMLElement>(null);

  const handleAddClick = (event: React.MouseEvent<HTMLElement>) => {
    setAddMenuAnchor(event.currentTarget);
  };

  const handleAddClose = () => {
    setAddMenuAnchor(null);
  };

  const handleAddItem = (type: 'photo' | 'product' | 'professional' | 'story' | 'discussion') => {
    onAddNew();
    handleAddClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2,
        p: 1,
        borderRadius: 1,
        bgcolor: 'background.paper',
        boxShadow: 1,
      }}
    >
      <Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          color="primary"
        >
          Add Item
        </Button>
        <Menu
          anchorEl={addMenuAnchor}
          open={Boolean(addMenuAnchor)}
          onClose={handleAddClose}
        >
          <MenuItem onClick={() => handleAddItem('photo')}>
            <ListItemIcon>
              <PhotoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Photo</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleAddItem('product')}>
            <ListItemIcon>
              <ProductIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Product</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleAddItem('professional')}>
            <ListItemIcon>
              <ProfessionalIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Professional</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleAddItem('story')}>
            <ListItemIcon>
              <StoryIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Story</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleAddItem('discussion')}>
            <ListItemIcon>
              <DiscussionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Discussion</ListItemText>
          </MenuItem>
        </Menu>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Tooltip title="Share">
          <IconButton onClick={onShare} size="small">
            <ShareIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Invite Collaborators">
          <IconButton onClick={onInvite} size="small">
            <InviteIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Tooltip title="Grid View">
          <IconButton
            onClick={() => onLayoutChange('grid')}
            color={currentLayout === 'grid' ? 'primary' : 'default'}
            size="small"
          >
            <GridIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="List View">
          <IconButton
            onClick={() => onLayoutChange('list')}
            color={currentLayout === 'list' ? 'primary' : 'default'}
            size="small"
          >
            <ListIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default IdeabookToolbar;