import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import calendarService from '../services/calendarService';
import {
  CalendarSettings,
  CalendarEvent,
  DateException,
  CalendarSettingsUpdateRequest,
  DateExceptionCreateRequest,
  DateExceptionUpdateRequest,
  CalendarEventCreateRequest,
  CalendarEventUpdateRequest,
  AvailabilityCheckRequest,
  AvailabilityCheckResponse,
} from '../types/calendar';

interface CalendarContextType {
  settings: CalendarSettings | null;
  events: CalendarEvent[];
  exceptions: DateException[];
  loading: boolean;
  error: string | null;
  fetchSettings: () => Promise<void>;
  updateSettings: (settings: CalendarSettingsUpdateRequest) => Promise<void>;
  fetchEvents: (start: string, end: string) => Promise<void>;
  createEvent: (event: CalendarEventCreateRequest) => Promise<CalendarEvent>;
  updateEvent: (eventId: string, event: CalendarEventUpdateRequest) => Promise<CalendarEvent>;
  deleteEvent: (eventId: string) => Promise<void>;
  fetchExceptions: () => Promise<void>;
  createException: (exception: DateExceptionCreateRequest) => Promise<DateException>;
  updateException: (
    exceptionId: string,
    exception: DateExceptionUpdateRequest
  ) => Promise<DateException>;
  deleteException: (exceptionId: string) => Promise<void>;
  checkAvailability: (request: AvailabilityCheckRequest) => Promise<AvailabilityCheckResponse>;
  getLeadTimeline: (status?: string) => Promise<CalendarEvent[]>;
}

const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

export const useCalendar = (): CalendarContextType => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error('useCalendar must be used within a CalendarProvider');
  }
  return context;
};

interface CalendarProviderProps {
  children: React.ReactNode;
}

export const CalendarProvider: React.FC<CalendarProviderProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [settings, setSettings] = useState<CalendarSettings | null>(null);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [exceptions, setExceptions] = useState<DateException[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSettings = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      setLoading(true);
      setError(null);
      const response = await calendarService.getCalendarSettings();
      setSettings(response);
    } catch (err) {
      console.error('Error fetching calendar settings:', err);
      setError('Failed to load calendar settings. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const updateSettings = useCallback(
    async (updatedSettings: CalendarSettingsUpdateRequest) => {
      if (!isAuthenticated) return;

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.updateCalendarSettings(updatedSettings);
        setSettings(response);
      } catch (err) {
        console.error('Error updating calendar settings:', err);
        setError('Failed to update calendar settings. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const fetchEvents = useCallback(
    async (start: string, end: string) => {
      if (!isAuthenticated) return;

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.getCalendarEvents(start, end);
        setEvents(response);
      } catch (err) {
        console.error('Error fetching calendar events:', err);
        setError('Failed to load calendar events. Please try again later.');
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const createEvent = useCallback(
    async (event: CalendarEventCreateRequest) => {
      if (!isAuthenticated) throw new Error('Authentication required');

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.createCalendarEvent(event);
        setEvents((prev) => [...prev, response]);
        return response;
      } catch (err) {
        console.error('Error creating calendar event:', err);
        setError('Failed to create calendar event. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const updateEvent = useCallback(
    async (eventId: string, event: CalendarEventUpdateRequest) => {
      if (!isAuthenticated) throw new Error('Authentication required');

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.updateCalendarEvent(eventId, event);
        setEvents((prev) => prev.map((e) => (e._id === eventId ? response : e)));
        return response;
      } catch (err) {
        console.error('Error updating calendar event:', err);
        setError('Failed to update calendar event. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const deleteEvent = useCallback(
    async (eventId: string) => {
      if (!isAuthenticated) return;

      try {
        setLoading(true);
        setError(null);
        await calendarService.deleteCalendarEvent(eventId);
        setEvents((prev) => prev.filter((e) => e._id !== eventId));
      } catch (err) {
        console.error('Error deleting calendar event:', err);
        setError('Failed to delete calendar event. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const fetchExceptions = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      setLoading(true);
      setError(null);
      const response = await calendarService.getDateExceptions();
      setExceptions(response);
    } catch (err) {
      console.error('Error fetching date exceptions:', err);
      setError('Failed to load date exceptions. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const createException = useCallback(
    async (exception: DateExceptionCreateRequest) => {
      if (!isAuthenticated) throw new Error('Authentication required');

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.createDateException(exception);
        setExceptions((prev) => [...prev, response]);
        return response;
      } catch (err) {
        console.error('Error creating date exception:', err);
        setError('Failed to create date exception. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const updateException = useCallback(
    async (exceptionId: string, exception: DateExceptionUpdateRequest) => {
      if (!isAuthenticated) throw new Error('Authentication required');

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.updateDateException(exceptionId, exception);
        setExceptions((prev) => prev.map((e) => (e._id === exceptionId ? response : e)));
        return response;
      } catch (err) {
        console.error('Error updating date exception:', err);
        setError('Failed to update date exception. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const deleteException = useCallback(
    async (exceptionId: string) => {
      if (!isAuthenticated) return;

      try {
        setLoading(true);
        setError(null);
        await calendarService.deleteDateException(exceptionId);
        setExceptions((prev) => prev.filter((e) => e._id !== exceptionId));
      } catch (err) {
        console.error('Error deleting date exception:', err);
        setError('Failed to delete date exception. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const checkAvailability = useCallback(
    async (request: AvailabilityCheckRequest) => {
      if (!isAuthenticated) throw new Error('Authentication required');

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.checkAvailability(request);
        return response;
      } catch (err) {
        console.error('Error checking availability:', err);
        setError('Failed to check availability. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  const getLeadTimeline = useCallback(
    async (status?: string) => {
      if (!isAuthenticated) throw new Error('Authentication required');

      try {
        setLoading(true);
        setError(null);
        const response = await calendarService.getLeadTimeline(status);
        return response;
      } catch (err) {
        console.error('Error fetching lead timeline:', err);
        setError('Failed to load lead timeline. Please try again later.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isAuthenticated]
  );

  // Initial data fetch
  useEffect(() => {
    if (isAuthenticated) {
      fetchSettings();
      fetchExceptions();

      // Fetch events for the current month
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
      const end = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString();
      fetchEvents(start, end);
    } else {
      // Reset state when user logs out
      setSettings(null);
      setEvents([]);
      setExceptions([]);
    }
  }, [isAuthenticated, fetchSettings, fetchEvents, fetchExceptions]);

  const value = {
    settings,
    events,
    exceptions,
    loading,
    error,
    fetchSettings,
    updateSettings,
    fetchEvents,
    createEvent,
    updateEvent,
    deleteEvent,
    fetchExceptions,
    createException,
    updateException,
    deleteException,
    checkAvailability,
    getLeadTimeline,
  };

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};