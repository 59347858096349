import { FC, useState, useMemo } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ImageList,
  ImageListItem,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPortfolioItem,
  deletePortfolioItem,
  PortfolioItemForm,
} from '../../../redux/slices/providerSlice';
import { RootState } from '../../../redux/store';
import { AppDispatch } from '../../../redux/store';
import FileUpload from '../../common/FileUpload';

// Generate year options (current year to 10 years back)
const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 10; i++) {
    years.push((currentYear - i).toString());
  }
  return years;
};

// Cost ranges in AED
const costRanges = [
  'Under 10,000 AED',
  '10,000 - 25,000 AED',
  '25,000 - 50,000 AED',
  '50,000 - 100,000 AED',
  '100,000 - 250,000 AED',
  '250,000 - 500,000 AED',
  '500,000 - 1,000,000 AED',
  'Over 1,000,000 AED',
];

// Style options
const styleOptions = [
  'Modern',
  'Contemporary',
  'Traditional',
  'Minimalist',
  'Industrial',
  'Scandinavian',
  'Mid-Century Modern',
  'Bohemian',
  'Rustic',
  'Mediterranean',
  'Art Deco',
  'Coastal',
  'Eclectic',
  'Transitional',
  'Farmhouse',
  'Islamic',
  'Arabic',
];

// Room type options
const roomTypeOptions = [
  { id: 'kitchen', name: 'Kitchen' },
  { id: 'bathroom', name: 'Bathroom' },
  { id: 'living-room', name: 'Living Room' },
  { id: 'bedroom', name: 'Bedroom' },
  { id: 'outdoor', name: 'Outdoor' },
  { id: 'home-office', name: 'Home Office' },
];

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required').max(100, 'Title is too long'),
  description: Yup.string().required('Description is required').max(500, 'Description is too long'),
  buildingName: Yup.string().max(100, 'Building name is too long'),
  year: Yup.string().required('Year is required'),
  cost: Yup.string().required('Cost range is required'),
  style: Yup.string().required('Style is required'),
  roomType: Yup.string().required('Room type is required'),
  images: Yup.array()
    .of(
      Yup.string()
        .test(
          'is-valid-image-url',
          'Invalid image URL',
          (value) => {
            if (!value) return false;
            // Accept any string that starts with http:// or https://
            // This is more permissive than the standard URL validation
            return value.startsWith('http://') || value.startsWith('https://');
          }
        )
    )
    .min(1, 'At least one image is required')
    .required('Images are required'),
});

const ProjectsManager: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.provider);
  const portfolio = useSelector((state: RootState) => state.provider.profile?.portfolio || []);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  // We don't need to track uploading state anymore as FileUpload component handles it
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  // Memoize year options to avoid recalculating on every render
  const yearOptions = useMemo(() => generateYearOptions(), []);

  const formik = useFormik<PortfolioItemForm>({
    initialValues: {
      title: '',
      description: '',
      buildingName: '',
      year: yearOptions[0], // Default to current year
      cost: '',
      style: '',
      roomType: 'kitchen', // Default to kitchen
      images: [],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await dispatch(addPortfolioItem(values)).unwrap();
        resetForm();
        setIsDialogOpen(false);
      } catch (err) {
        console.error('Failed to add project:', err);
      }
    },
  });

  /**
   * Handle successful image upload
   * @param urls - Array of uploaded image URLs
   */
  const handleImagesUpload = (urls: string[]) => {
    if (urls.length > 0) {
      // Update the form with the real image URLs
      formik.setFieldValue('images', [...formik.values.images, ...urls]);
    }
  };

  /**
   * Handle upload errors
   * @param error - The error that occurred during upload
   */
  const handleUploadError = (error: Error) => {
    console.error('Failed to upload images:', error);

    // Fallback to placeholder images if upload fails
    const placeholderImages = [
      'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1200',
      'https://images.pexels.com/photos/1571463/pexels-photo-1571463.jpeg?auto=compress&cs=tinysrgb&w=1200',
      'https://images.pexels.com/photos/1571458/pexels-photo-1571458.jpeg?auto=compress&cs=tinysrgb&w=1200',
      'https://images.pexels.com/photos/1643383/pexels-photo-1643383.jpeg?auto=compress&cs=tinysrgb&w=1200',
      'https://images.pexels.com/photos/1643384/pexels-photo-1643384.jpeg?auto=compress&cs=tinysrgb&w=1200',
    ];

    // Add one placeholder image as a fallback
    const imageUrl = placeholderImages[Math.floor(Math.random() * placeholderImages.length)];
    formik.setFieldValue('images', [...formik.values.images, imageUrl]);
  };

  const handleDelete = async (itemId: string): Promise<void> => {
    try {
      await dispatch(deletePortfolioItem(itemId)).unwrap();
    } catch (err) {
      console.error('Failed to delete project:', err);
    }
  };

  const handleImagePreview = (images: string[]): void => {
    setSelectedImages(images);
    setPreviewDialogOpen(true);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Projects</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsDialogOpen(true)}
        >
          Add Project
        </Button>
      </Box>

      <Grid container spacing={3}>
        {portfolio.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item._id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={item.images[0]}
                alt={item.title}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleImagePreview(item.images)}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {item.description}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  <Chip label={`Year: ${item.year}`} size="small" />
                  <Chip label={`Style: ${item.style}`} size="small" />
                  <Chip label={item.cost} size="small" />
                  <Chip
                    label={`Room: ${
                      roomTypeOptions.find((room) => room.id === item.roomType)?.name ||
                      item.roomType
                    }`}
                    size="small"
                    color="primary"
                  />
                  {item.buildingName && (
                    <Chip label={`Building: ${item.buildingName}`} size="small" />
                  )}
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => handleImagePreview(item.images)}
                >
                  View Images ({item.images.length})
                </Button>
                <IconButton size="small" onClick={() => handleDelete(item._id)} sx={{ ml: 'auto' }}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {portfolio.length === 0 && (
        <Typography color="textSecondary" align="center" sx={{ py: 3 }}>
          No projects added yet
        </Typography>
      )}

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="sm" fullWidth>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Add Project</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  label="Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  rows={3}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="buildingName"
                  name="buildingName"
                  label="Building or Community Name (Optional)"
                  value={formik.values.buildingName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.buildingName && Boolean(formik.errors.buildingName)}
                  helperText={formik.touched.buildingName && formik.errors.buildingName}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth error={formik.touched.year && Boolean(formik.errors.year)}>
                  <InputLabel id="year-label">Year</InputLabel>
                  <Select
                    labelId="year-label"
                    id="year"
                    name="year"
                    value={formik.values.year}
                    label="Year"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {yearOptions.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.year && formik.errors.year && (
                    <FormHelperText>{formik.errors.year}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth error={formik.touched.cost && Boolean(formik.errors.cost)}>
                  <InputLabel id="cost-label">Cost Range</InputLabel>
                  <Select
                    labelId="cost-label"
                    id="cost"
                    name="cost"
                    value={formik.values.cost}
                    label="Cost Range"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {costRanges.map((range) => (
                      <MenuItem key={range} value={range}>
                        {range}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.cost && formik.errors.cost && (
                    <FormHelperText>{formik.errors.cost}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth error={formik.touched.style && Boolean(formik.errors.style)}>
                  <InputLabel id="style-label">Style</InputLabel>
                  <Select
                    labelId="style-label"
                    id="style"
                    name="style"
                    value={formik.values.style}
                    label="Style"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {styleOptions.map((style) => (
                      <MenuItem key={style} value={style}>
                        {style}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.style && formik.errors.style && (
                    <FormHelperText>{formik.errors.style}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={formik.touched.roomType && Boolean(formik.errors.roomType)}
                >
                  <InputLabel id="roomType-label">Room Type</InputLabel>
                  <Select
                    labelId="roomType-label"
                    id="roomType"
                    name="roomType"
                    value={formik.values.roomType}
                    label="Room Type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {roomTypeOptions.map((room) => (
                      <MenuItem key={room.id} value={room.id}>
                        {room.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.roomType && formik.errors.roomType && (
                    <FormHelperText>{formik.errors.roomType}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Upload Images
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Photo Guidelines: Upload high-quality images (min 1200x800px). Images will be
                    optimized for display in the ideas section.
                  </Typography>
                </Box>
                <FileUpload
                  uploadType="project-photos"
                  entityId={`temp-${Date.now()}`}
                  multiple={true}
                  maxFiles={10}
                  onUploadComplete={handleImagesUpload}
                  onError={handleUploadError}
                  buttonLabel="Upload Project Images"
                  accept="image/*"
                />
                {formik.touched.images && formik.errors.images && (
                  <FormHelperText error>{formik.errors.images as string}</FormHelperText>
                )}
                {formik.values.images.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <ImageList cols={3} rowHeight={100}>
                      {formik.values.images.map((image, index) => (
                        <ImageListItem key={index}>
                          <img src={image} alt={`Upload ${index + 1}`} loading="lazy" />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Image Gallery</DialogTitle>
        <DialogContent>
          <ImageList cols={2} rowHeight={300}>
            {selectedImages.map((image, index) => (
              <ImageListItem key={index}>
                <img
                  src={image}
                  alt={`Gallery image ${index + 1}`}
                  loading="lazy"
                  style={{ height: '300px', objectFit: 'cover' }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Paper>
  );
};

export default ProjectsManager;