import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, CircularProgress, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CreateLeadDTO } from '../../types/lead';
import { PreferenceQuestion, CustomerPreference } from '../../types/preference';
import { getServicePreferenceTemplate } from '../../services/targetPreferenceService';

// Import step components
import ProjectDetailsStep from './steps/ProjectDetailsStep';
import TimelineStep from './steps/TimelineStep';
import PropertyTypeStep from './steps/PropertyTypeStep';
import AdditionalDetailsStep from './steps/AdditionalDetailsStep';
import ReviewStep from './steps/ReviewStep';

interface LeadFormProps {
  serviceId: string;
  providerId: string | null;
  onComplete: (leadData: CreateLeadDTO, preferences: CustomerPreference[]) => void;
  onCancel: () => void;
}

const LeadForm: React.FC<LeadFormProps> = ({
  serviceId,
  providerId,
  onComplete,
  onCancel,
}) => {
  // Get authentication state from Redux
  const isAuthenticated = useSelector((state: RootState) => !!state.auth.user);
  const user = useSelector((state: RootState) => state.auth.user);

  // Form state
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<{
    service: string;
    projectType?: string;
    description?: string;
    timeline?: string;
    propertyType?: string;
    spaces?: string[];
    contactInfo?: {
      name: string;
      email: string;
      phone?: string;
    };
  }>({
    service: serviceId,
  });

  // Service preference state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [serviceQuestions, setServiceQuestions] = useState<PreferenceQuestion[]>([]);
  const [preferences, setPreferences] = useState<CustomerPreference[]>([]);

  // Steps
  const steps = ['Project Details', 'Timeline', 'Property Details', 'Additional Details', 'Review'];

  // Fetch service preference template
  useEffect(() => {
    const fetchServiceQuestions = async () => {
      if (!serviceId) return;

      setLoading(true);
      setError(null);

      try {
        const template = await getServicePreferenceTemplate(serviceId);
        if (template && template.questions) {
          setServiceQuestions(template.questions);
        }
      } catch (err) {
        console.error('Error fetching service questions:', err);
        setError('Failed to load service-specific questions. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchServiceQuestions();
  }, [serviceId]);

  // Initialize contact info for authenticated users
  useEffect(() => {
    if (isAuthenticated && user) {
      setFormData(prev => ({
        ...prev,
        contactInfo: {
          name: user.name || '',
          email: user.email || '',
          phone: user.phone || '',
        },
      }));
    }
  }, [isAuthenticated, user]);

  // Handle form data change
  const handleFormDataChange = (field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle preferences change
  const handlePreferencesChange = (newPreferences: CustomerPreference[]) => {
    setPreferences(newPreferences);
  };

  // Handle next step
  const handleNext = () => {
    setActiveStep(prev => prev + 1);
  };

  // Handle back step
  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  // Handle form submission
  const handleSubmit = () => {
    // Create lead data
    const leadData: CreateLeadDTO = {
      service: serviceId,
      ...(providerId ? { providerId } : {}),
      details: {
        description: formData.description || '',
        preferredDates: [], // This would be populated from timeline preferences
        location: {
          address: '', // This would be populated from location input
          coordinates: [0, 0], // This would be populated from location input
        },
      },
    };

    // Add contact info for non-authenticated users
    if (!isAuthenticated && formData.contactInfo) {
      leadData.contactInfo = {
        name: formData.contactInfo.name,
        email: formData.contactInfo.email,
        phone: formData.contactInfo.phone,
      };
    }

    // Complete the form
    onComplete(leadData, preferences);
  };

  // Filter questions for each step
  const getQuestionsForStep = (step: string): PreferenceQuestion[] => {
    switch (step) {
      case 'project-details':
        return serviceQuestions.filter(
          q => ((q.questionText.toLowerCase().includes('project') &&
                !q.questionText.toLowerCase().includes('property')) ||
               (q.questionText.toLowerCase().includes('type') &&
                !q.questionText.toLowerCase().includes('property')) ||
               q.questionText.toLowerCase().includes('option') ||
               q.questionText.toLowerCase().includes('device') ||
               q.questionText.toLowerCase().includes('smart') ||
               q.questionText.toLowerCase().includes('system') ||
               q.questionText.toLowerCase().includes('brand') ||
               q.questionText.toLowerCase().includes('prefer'))
        );
      case 'timeline':
        return serviceQuestions.filter(
          q => q.questionText.toLowerCase().includes('timeline') ||
               q.questionText.toLowerCase().includes('when') ||
               q.questionText.toLowerCase().includes('start') ||
               q.questionText.toLowerCase().includes('time')
        );
      case 'property-details': {
        // First, get all property-related questions
        const propertyQuestions = serviceQuestions.filter(
          q => q.questionText.toLowerCase().includes('property') ||
               q.questionText.toLowerCase().includes('home') ||
               q.questionText.toLowerCase().includes('house')
        );

        // Then, get renovation-specific questions only if the service is renovation-related
        const isRenovationService = serviceId.toLowerCase().includes('renovat') ||
                                   serviceId.toLowerCase().includes('remodel');

        const spaceQuestions = isRenovationService ?
          serviceQuestions.filter(
            q => (q.questionText.toLowerCase().includes('space') ||
                 q.questionText.toLowerCase().includes('room') ||
                 q.questionText.toLowerCase().includes('area')) &&
                 (q.questionText.toLowerCase().includes('renovate') ||
                  q.questionText.toLowerCase().includes('renovation') ||
                  q.questionText.toLowerCase().includes('remodel'))
          ) : [];

        return [...propertyQuestions, ...spaceQuestions];
      }
      case 'additional-details':
        return serviceQuestions.filter(
          q => q.questionText.toLowerCase().includes('additional') ||
               q.questionText.toLowerCase().includes('other') ||
               q.questionText.toLowerCase().includes('details') ||
               q.questionType === 'text'
        );
      default:
        return [];
    }
  };

  // Render current step
  const renderStep = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box sx={{ p: 4 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      );
    }

    switch (activeStep) {
      case 0:
        return (
          <ProjectDetailsStep
            formData={formData}
            onChange={handleFormDataChange}
            onNext={handleNext}
            onBack={handleBack}
            serviceQuestions={getQuestionsForStep('project-details')}
            preferences={preferences}
            onPreferencesChange={handlePreferencesChange}
          />
        );
      case 1:
        return (
          <TimelineStep
            formData={formData}
            onChange={handleFormDataChange}
            onNext={handleNext}
            onBack={handleBack}
            serviceQuestions={getQuestionsForStep('timeline')}
            preferences={preferences}
            onPreferencesChange={handlePreferencesChange}
          />
        );
      case 2:
        return (
          <PropertyTypeStep
            formData={{
              ...formData,
              service: serviceId,
            }}
            onChange={handleFormDataChange}
            onNext={handleNext}
            onBack={handleBack}
            serviceQuestions={getQuestionsForStep('property-details')}
            preferences={preferences}
            onPreferencesChange={handlePreferencesChange}
          />
        );
      case 3:
        return (
          <AdditionalDetailsStep
            formData={formData}
            onChange={handleFormDataChange}
            onNext={handleNext}
            onBack={handleBack}
            serviceQuestions={getQuestionsForStep('additional-details')}
            preferences={preferences}
            onPreferencesChange={handlePreferencesChange}
            isAuthenticated={isAuthenticated}
          />
        );
      case 4:
        return (
          <ReviewStep
            formData={formData}
            onBack={handleBack}
            onSubmit={handleSubmit}
            serviceQuestions={serviceQuestions}
            preferences={preferences}
            isAuthenticated={isAuthenticated}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {renderStep()}
    </Box>
  );
};

export default LeadForm;