import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  PhotoCamera as PhotoIcon,
  ShoppingBag as ProductIcon,
  Person as ProfessionalIcon,
  Article as StoryIcon,
  Forum as DiscussionIcon,
} from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import IdeabookToolbar from './IdeabookToolbar';
import AddItemModal from './AddItemModal';

interface IdeabookItem {
  id: string;
  type: 'photo' | 'product' | 'professional' | 'story' | 'discussion';
  content: any;
  note?: string;
  addedAt: Date;
}

interface IdeabookDetailProps {
  title: string;
  description?: string;
  items: IdeabookItem[];
  tags: string[];
  isPrivate: boolean;
  onEditItem: (itemId: string) => void;
  onDeleteItem: (itemId: string) => void;
  onAddItem: (item: any) => void;
  onShare: () => void;
  onInvite: () => void;
}

const IdeabookDetail: React.FC<IdeabookDetailProps> = ({
  title,
  description,
  items,
  tags,
  isPrivate,
  onEditItem,
  onDeleteItem,
  onAddItem,
  onShare,
  onInvite,
}) => {
  const [layout, setLayout] = useState<'grid' | 'list'>('grid');
  const [addItemType, setAddItemType] = useState<'photo' | 'product' | 'professional' | 'story' | 'discussion' | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<{ element: HTMLElement; itemId: string } | null>(null);

  const handleLayoutChange = (newLayout: 'grid' | 'list') => {
    setLayout(newLayout);
  };

  const handleAddNew = () => {
    setAddItemType('photo'); // Default to photo, will be set by toolbar
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, itemId: string) => {
    event.stopPropagation();
    setMenuAnchor({ element: event.currentTarget, itemId });
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const getItemIcon = (type: IdeabookItem['type']) => {
    switch (type) {
      case 'photo':
        return <PhotoIcon />;
      case 'product':
        return <ProductIcon />;
      case 'professional':
        return <ProfessionalIcon />;
      case 'story':
        return <StoryIcon />;
      case 'discussion':
        return <DiscussionIcon />;
    }
  };

  const renderGridView = () => (
    <Grid container spacing={3}>
      {items.map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item.id}>
          <Card>
            <CardActionArea>
              {item.type === 'photo' && (
                <CardMedia
                  component="img"
                  height="200"
                  image={item.content}
                  alt={item.note || 'Ideabook item'}
                />
              )}
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getItemIcon(item.type)}
                    <Typography variant="subtitle1">
                      {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={(e) => handleMenuOpen(e, item.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
                {item.note && (
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {item.note}
                  </Typography>
                )}
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                  Added {formatDistanceToNow(new Date(item.addedAt))} ago
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderListView = () => (
    <List>
      {items.map((item) => (
        <Paper sx={{ mb: 2 }} key={item.id}>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                onClick={(e) => handleMenuOpen(e, item.id)}
              >
                <MoreVertIcon />
              </IconButton>
            }
          >
            <ListItemIcon>{getItemIcon(item.type)}</ListItemIcon>
            <ListItemText
              primary={item.type.charAt(0).toUpperCase() + item.type.slice(1)}
              secondary={
                <>
                  {item.note && (
                    <Typography variant="body2" color="text.secondary">
                      {item.note}
                    </Typography>
                  )}
                  <Typography variant="caption" color="text.secondary">
                    Added {formatDistanceToNow(new Date(item.addedAt))} ago
                  </Typography>
                </>
              }
            />
          </ListItem>
        </Paper>
      ))}
    </List>
  );

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        {description && (
          <Typography variant="body1" color="text.secondary" paragraph>
            {description}
          </Typography>
        )}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {tags.map((tag) => (
            <Chip key={tag} label={tag} size="small" />
          ))}
        </Box>
      </Box>

      <IdeabookToolbar
        onLayoutChange={handleLayoutChange}
        onShare={onShare}
        onInvite={onInvite}
        onAddNew={handleAddNew}
        currentLayout={layout}
      />

      {layout === 'grid' ? renderGridView() : renderListView()}

      <Menu
        anchorEl={menuAnchor?.element}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            if (menuAnchor) {
              onEditItem(menuAnchor.itemId);
              handleMenuClose();
            }
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (menuAnchor) {
              onDeleteItem(menuAnchor.itemId);
              handleMenuClose();
            }
          }}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>

      {addItemType && (
        <AddItemModal
          type={addItemType}
          open={Boolean(addItemType)}
          onClose={() => setAddItemType(null)}
          onAdd={onAddItem}
        />
      )}
    </Box>
  );
};

export default IdeabookDetail;