import React from 'react';
import {
  Box,
  Typography,
  Slider,
  Rating,
  FormControl,
  FormLabel,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export interface AdvancedFilters {
  priceRange?: [number, number];
  rating?: number;
  availability?: {
    date: Date;
    timeSlot?: string;
  };
}

export interface AdvancedSearchFiltersProps {
  value: AdvancedFilters;
  onChange: (filters: AdvancedFilters) => void;
  disabled?: boolean;
}

const AdvancedSearchFilters: React.FC<AdvancedSearchFiltersProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const handlePriceRangeChange = (_event: Event, newValue: number | number[]) => {
    onChange({
      ...value,
      priceRange: newValue as [number, number],
    });
  };

  const handleRatingChange = (_event: React.SyntheticEvent, newValue: number | null) => {
    onChange({
      ...value,
      rating: newValue || undefined,
    });
  };

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      onChange({
        ...value,
        availability: {
          ...value.availability,
          date: newDate,
        },
      });
    } else {
      const { availability, ...rest } = value;
      onChange(rest);
    }
  };

  const handleTimeChange = (newTime: Date | null) => {
    if (newTime && value.availability) {
      onChange({
        ...value,
        availability: {
          ...value.availability,
          timeSlot: newTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        },
      });
    } else if (value.availability) {
      const { timeSlot, ...rest } = value.availability;
      onChange({
        ...value,
        availability: rest,
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} sx={{ p: 2 }}>
        <FormControl disabled={disabled}>
          <FormLabel>Price Range</FormLabel>
          <Box sx={{ px: 2 }}>
            <Slider
              value={value.priceRange || [0, 1000]}
              onChange={handlePriceRangeChange}
              valueLabelDisplay="auto"
              min={0}
              max={1000}
              step={10}
              marks={[
                { value: 0, label: '$0' },
                { value: 1000, label: '$1000+' },
              ]}
            />
            <Typography variant="caption" color="text.secondary">
              {`$${value.priceRange?.[0] || 0} - $${value.priceRange?.[1] || 1000}+`}
            </Typography>
          </Box>
        </FormControl>

        <FormControl disabled={disabled}>
          <FormLabel>Minimum Rating</FormLabel>
          <Rating
            value={value.rating || 0}
            onChange={handleRatingChange}
            precision={0.5}
            size="large"
          />
          {value.rating && (
            <Typography variant="caption" color="text.secondary">
              {value.rating} stars and above
            </Typography>
          )}
        </FormControl>

        <FormControl disabled={disabled}>
          <FormLabel>Availability</FormLabel>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <DatePicker
              label="Date"
              value={value.availability?.date || null}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                  disabled: disabled,
                },
              }}
              minDate={new Date()}
              disablePast
            />
            {value.availability?.date && (
              <TimePicker
                label="Time"
                value={value.availability.timeSlot ? new Date(`1970-01-01T${value.availability.timeSlot}`) : null}
                onChange={handleTimeChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    disabled: disabled,
                  },
                }}
                minutesStep={15}
              />
            )}
          </Stack>
          {value.availability && (
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
              {`Available on ${value.availability.date.toLocaleDateString()}${
                value.availability.timeSlot ? ` at ${value.availability.timeSlot}` : ''
              }`}
            </Typography>
          )}
        </FormControl>
      </Stack>
    </LocalizationProvider>
  );
};

export default AdvancedSearchFilters;