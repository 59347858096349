import { createTheme } from '@mui/material/styles';

// Extend the Material UI theme to include our custom accent color
declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
  }
  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      light: '#333333',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#000000',
      light: '#333333',
      dark: '#000000',
      contrastText: '#fff',
    },
    // Add the new accent color
    accent: {
      main: '#81D8D0',
      light: '#A1E4DE', // Lighter shade
      dark: '#5EBEB5', // Darker shade
      contrastText: '#000000',
    },
    background: {
      default: '#f5f5f5',
      paper: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Quicksand',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          padding: '8px 16px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          },
          // Apply accent color to contained buttons (not text buttons)
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#81D8D0',
            color: '#000000',
            '&:hover': {
              backgroundColor: '#5EBEB5',
            },
          },
        },
        // Use accent color for outlined buttons
        outlined: {
          borderColor: '#81D8D0',
          color: '#000000',
          '&:hover': {
            borderColor: '#5EBEB5',
            backgroundColor: 'rgba(129, 216, 208, 0.04)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
        elevation1: {
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#81D8D0', // Use accent color for hover state
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#81D8D0', // Use accent color for focus state
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&.MuiChip-outlined': {
            borderWidth: 1.5,
            borderColor: '#81D8D0', // Use accent color for outlined chips
            color: '#000000', // Keep text dark
          },
          '&.MuiChip-filled': {
            backgroundColor: '#81D8D0', // Use accent color for filled chips
            color: '#000000', // Keep text dark
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '16px 0',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minWidth: 100,
          '&.Mui-selected': {
            color: '#000000', // Keep text dark
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#81D8D0', // Use accent color for tab indicator
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#81D8D0', // Use accent color for checkbox
          '&.Mui-checked': {
            color: '#81D8D0', // Use accent color for checked state
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#81D8D0', // Use accent color for radio button
          '&.Mui-checked': {
            color: '#81D8D0', // Use accent color for checked state
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#81D8D0', // Use accent color for switch thumb
            '& + .MuiSwitch-track': {
              backgroundColor: '#81D8D0', // Use accent color for switch track
              opacity: 0.5,
            },
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0 2px 8px rgba(0,0,0,0.1)',
    '0 4px 12px rgba(0,0,0,0.15)',
    '0 6px 16px rgba(0,0,0,0.2)',
    '0 8px 20px rgba(0,0,0,0.25)',
    '0 10px 24px rgba(0,0,0,0.3)',
    '0 12px 28px rgba(0,0,0,0.35)',
    '0 14px 32px rgba(0,0,0,0.4)',
    '0 16px 36px rgba(0,0,0,0.45)',
    '0 18px 40px rgba(0,0,0,0.5)',
    '0 20px 44px rgba(0,0,0,0.55)',
    '0 22px 48px rgba(0,0,0,0.6)',
    '0 24px 52px rgba(0,0,0,0.65)',
    '0 26px 56px rgba(0,0,0,0.7)',
    '0 28px 60px rgba(0,0,0,0.75)',
    '0 30px 64px rgba(0,0,0,0.8)',
    '0 32px 68px rgba(0,0,0,0.85)',
    '0 34px 72px rgba(0,0,0,0.9)',
    '0 36px 76px rgba(0,0,0,0.95)',
    '0 38px 80px rgba(0,0,0,1)',
    '0 40px 84px rgba(0,0,0,1)',
    '0 42px 88px rgba(0,0,0,1)',
    '0 44px 92px rgba(0,0,0,1)',
    '0 46px 96px rgba(0,0,0,1)',
    '0 48px 100px rgba(0,0,0,1)',
  ],
});

export default theme;
