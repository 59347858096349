import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, CircularProgress, Alert, Paper, Grid, Card, CardMedia, CardContent, CardActionArea } from '@mui/material';
import IdeasNavigation from '../../components/ideas/IdeasNavigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/store';

interface FavoritedProduct {
  _id: string;
  name: string;
  description: string;
  price: number;
  imageUrl: string;
}

const ProductListPage: React.FC = () => {
  const [favoritedProducts, setFavoritedProducts] = useState<FavoritedProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchFavoritedProducts = async () => {
      try {
        setLoading(true);
        // TODO: Replace with actual API call to get user's favorited products
        // For now, using mock data
        const mockProducts: FavoritedProduct[] = [
          {
            _id: '1',
            name: 'Modern Sofa',
            description: 'Comfortable 3-seater sofa with premium fabric',
            price: 899,
            imageUrl: 'https://images.pexels.com/photos/1866149/pexels-photo-1866149.jpeg?auto=compress&cs=tinysrgb&w=300',
          },
          {
            _id: '2',
            name: 'Dining Table',
            description: 'Solid wood dining table for 6 people',
            price: 599,
            imageUrl: 'https://images.pexels.com/photos/1395967/pexels-photo-1395967.jpeg?auto=compress&cs=tinysrgb&w=300',
          },
          {
            _id: '3',
            name: 'Pendant Light',
            description: 'Modern pendant light for dining area',
            price: 129,
            imageUrl: 'https://images.pexels.com/photos/1123262/pexels-photo-1123262.jpeg?auto=compress&cs=tinysrgb&w=300',
          },
        ];

        // Simulate API delay
        setTimeout(() => {
          setFavoritedProducts(mockProducts);
          setLoading(false);
        }, 1000);

      } catch (err) {
        console.error('Error fetching favorited products:', err);
        setError('Failed to load your favorited products. Please try again.');
        setLoading(false);
      }
    };

    fetchFavoritedProducts();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IdeasNavigation activeSection="product-list" />
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Your Favorited Products
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          View and manage products you've saved for future reference.
        </Typography>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      ) : favoritedProducts.length === 0 ? (
        <Paper sx={{ p: 4, textAlign: 'center', my: 4 }}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            You haven't favorited any products yet
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Browse the marketplace and click the heart icon on products you like to add them to your favorites.
          </Typography>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          {favoritedProducts.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product._id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={product.imageUrl}
                    alt={product.name}
                  />
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {product.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      {product.description}
                    </Typography>
                    <Typography variant="h6" color="primary">
                      ${product.price}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default ProductListPage;