import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { OnboardingStepId, ONBOARDING_STEPS } from '../../../types/onboarding/shared';
import { useOnboarding } from '../../../contexts/OnboardingContext';

/**
 * Props for StepNavigation component
 */
interface StepNavigationProps {
  /**
   * Whether to show optional steps as optional
   */
  showOptionalLabel?: boolean;
}

/**
 * Component for navigating between onboarding steps
 */
export const StepNavigation: React.FC<StepNavigationProps> = ({
  showOptionalLabel = true,
}) => {
  const navigate = useNavigate();
  const {
    currentStepId,
    onboardingStatus,
    isStepFormDirty,
    canAccessStep,
  } = useOnboarding();

  const [pendingStepId, setPendingStepId] = useState<OnboardingStepId | null>(null);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);

  // Get current step index
  const currentStepIndex = ONBOARDING_STEPS.findIndex(step => step.id === currentStepId);

  // Handle step click
  const handleStepClick = (stepId: OnboardingStepId) => {
    // If form is dirty, show warning dialog
    if (isStepFormDirty) {
      setPendingStepId(stepId);
      setShowUnsavedDialog(true);
      return;
    }

    navigateToStep(stepId);
  };

  // Navigate to a step
  const navigateToStep = (stepId: OnboardingStepId) => {
    navigate(`/pro/onboarding/${stepId}`);
  };

  // Handle dialog confirm
  const handleDialogConfirm = () => {
    setShowUnsavedDialog(false);
    if (pendingStepId) {
      navigateToStep(pendingStepId);
      setPendingStepId(null);
    }
  };

  // Handle dialog cancel
  const handleDialogCancel = () => {
    setShowUnsavedDialog(false);
    setPendingStepId(null);
  };

  return (
    <>
      <Stepper nonLinear activeStep={currentStepIndex} alternativeLabel>
        {ONBOARDING_STEPS.map((step) => {
          const stepCompleted = onboardingStatus?.completedSteps.includes(step.id);
          const stepAccessible = canAccessStep(step.id);

          return (
            <Step
              key={step.id}
              completed={stepCompleted}
              disabled={!stepAccessible}
            >
              <StepButton
                onClick={() => stepAccessible && handleStepClick(step.id)}
                disabled={!stepAccessible}
              >
                <StepLabel
                  optional={showOptionalLabel && step.optional ?
                    <Typography variant="caption">Optional</Typography> : undefined}
                >
                  {step.label}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      <Dialog open={showUnsavedDialog} onClose={handleDialogCancel}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes on this step. If you navigate away, these changes will be lost.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Cancel</Button>
          <Button onClick={handleDialogConfirm} color="primary">
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};