import React, { useState } from 'react';
import {
  Box, Button, Typography, TextField, CircularProgress, Alert,
  Paper, Chip, Divider, Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import axios from '../../services/axios';
import { useAuth } from '../../hooks/useAuth';

/**
 * Component for requesting reviews from customers via email
 * Allows providers to enter customer details and send review request emails
 */
const ReviewRequestForm: React.FC = () => {
  const { getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customers, setCustomers] = useState<Array<{ name: string; email: string }>>([]);

  const validateEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAddCustomer = () => {
    if (!customerName.trim() || !customerEmail.trim()) {
      setError('Both name and email are required');
      return;
    }

    if (!validateEmail(customerEmail)) {
      setError('Please enter a valid email address');
      return;
    }

    // Check if this email is already in the list
    if (customers.some(c => c.email.toLowerCase() === customerEmail.toLowerCase())) {
      setError('This email is already in the list');
      return;
    }

    setError(null);
    setCustomers(prev => [...prev, { name: customerName, email: customerEmail }]);
    setCustomerName('');
    setCustomerEmail('');
  };

  const handleRemoveCustomer = (index: number) => {
    setCustomers(prev => prev.filter((_, i) => i !== index));
  };

  const handleSendRequests = async () => {
    if (customers.length === 0) {
      setError('Please add at least one customer');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = getAuthToken();
      if (!token) {
        setError('Authentication required');
        return;
      }

      const response = await axios.post('/reviews/request',
        { customers },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const sentCount = response.data.results.filter((r: any) => r.sent).length;
      setSuccess(`Successfully sent ${sentCount} review requests`);
      setCustomers([]);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to send review requests');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && customerName && customerEmail) {
      e.preventDefault();
      handleAddCustomer();
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Request Reviews from Customers
      </Typography>

      <Typography variant="body2" color="text.secondary" paragraph>
        Enter customer details to send review request emails. They'll receive
        a link to submit a review for your services.
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Customer Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            fullWidth
            margin="normal"
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Customer Email"
            type="email"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            fullWidth
            margin="normal"
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <Button
            variant="outlined"
            onClick={handleAddCustomer}
            disabled={loading}
            fullWidth
          >
            Add
          </Button>
        </Grid>
      </Grid>

      {customers.length > 0 && (
        <Box sx={{ mt: 3, mb: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Customers to request reviews from:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {customers.map((customer, index) => (
              <Chip
                key={index}
                label={`${customer.name} (${customer.email})`}
                onDelete={() => handleRemoveCustomer(index)}
                deleteIcon={<DeleteIcon />}
              />
            ))}
          </Box>
        </Box>
      )}

      <Divider sx={{ my: 3 }} />

      <Button
        variant="contained"
        color="primary"
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
        onClick={handleSendRequests}
        disabled={loading || customers.length === 0}
      >
        Send Review Requests
      </Button>
    </Paper>
  );
};

export default ReviewRequestForm;