import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  Rating,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

// UAE-centric testimonial data - in a real app, this would come from an API
const testimonials = [
  {
    id: 't1',
    name: 'Mohammed Al Hashimi',
    location: 'Dubai Marina, Dubai',
    rating: 5,
    text: 'The AC maintenance service I booked through Homezy was exceptional. The technician arrived promptly, diagnosed the issue quickly, and had my system running efficiently before the summer heat. Very professional service with great attention to detail.',
    project: 'AC Maintenance',
    date: '2024-12-10',
  },
  {
    id: 't2',
    name: 'Fatima Rahman',
    location: 'Al Reem Island, Abu Dhabi',
    rating: 4.5,
    text: 'When our villa needed painting, Homezy connected us with a fantastic team. They were respectful of our home, used high-quality materials, and completed the work ahead of schedule. The villa looks brand new and the price was very reasonable.',
    project: 'Villa Painting',
    date: '2024-11-15',
  },
  {
    id: 't3',
    name: 'Ahmed Khan',
    location: 'Jumeirah, Dubai',
    rating: 5,
    text: 'Our swimming pool needed urgent maintenance before a family gathering. The professional we found through Homezy arrived the next day, fixed all issues, and ensured proper chemical balance. The service was outstanding and the pool has never looked better!',
    project: 'Pool Maintenance',
    date: '2024-10-22',
  },
  {
    id: 't4',
    name: 'Layla Al Mansouri',
    location: 'Al Majaz, Sharjah',
    rating: 4,
    text: 'I needed an interior designer for my new apartment and found the perfect match on Homezy. The designer understood my vision for a modern Arabic style and delivered beyond expectations. The only reason for 4 stars is that some furniture deliveries were delayed.',
    project: 'Interior Design',
    date: '2024-09-30',
  },
  {
    id: 't5',
    name: 'Ravi Patel',
    location: 'Downtown, Dubai',
    rating: 5,
    text: "I've used Homezy for multiple services in my apartment - from electrical work to deep cleaning. Each time, the professionals have been punctual, skilled, and courteous. The platform makes it so easy to find reliable service providers in the UAE.",
    project: 'Multiple Services',
    date: '2024-08-15',
  },
  {
    id: 't6',
    name: 'Sara Al Qassimi',
    location: 'Al Ain',
    rating: 4.5,
    text: 'As a new resident in the UAE, I was concerned about finding trustworthy maintenance services. Homezy made it incredibly simple. The kitchen renovation team I hired through the platform was excellent - they respected our cultural preferences and delivered quality work.',
    project: 'Kitchen Renovation',
    date: '2024-07-20',
  },
];

// UAE-centric stats data
const stats = [
  { label: 'Happy Customers', value: '2,500+' },
  { label: 'Verified Professionals', value: '1,000+' },
  { label: 'Completed Projects', value: '5,000+' },
  { label: 'Emirates Covered', value: '7' },
];

const Testimonials: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  // Number of testimonials to show at once based on screen size
  const testimonialsToShow = isMobile ? 1 : isMedium ? 2 : 3;

  // Calculate max index
  const maxIndex = Math.max(0, testimonials.length - testimonialsToShow);

  // Handle navigation
  const handlePrev = () => {
    setActiveIndex((prev) => Math.max(0, prev - 1));
    setAutoplay(false);
  };

  const handleNext = () => {
    setActiveIndex((prev) => Math.min(maxIndex, prev + 1));
    setAutoplay(false);
  };

  // Autoplay functionality
  useEffect(() => {
    if (!autoplay) return;

    const interval = setInterval(() => {
      setActiveIndex((prev) => {
        const next = prev + 1;
        return next > maxIndex ? 0 : next;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [autoplay, maxIndex]);

  // Visible testimonials
  const visibleTestimonials = testimonials.slice(activeIndex, activeIndex + testimonialsToShow);

  return (
    <Box sx={{ py: 8, bgcolor: theme.palette.background.paper }}>
      <Container maxWidth="lg">
        <Typography variant="h3" component="h2" align="center" gutterBottom sx={{ mb: 2 }}>
          What Our Customers Say
        </Typography>
        <Typography
          variant="h6"
          component="p"
          align="center"
          color="text.secondary"
          sx={{ mb: 6, maxWidth: 700, mx: 'auto' }}
        >
          Thousands of homeowners have found reliable professionals and transformed their homes with
          Homezy
        </Typography>

        <Box sx={{ position: 'relative', mb: 6 }}>
          <IconButton
            onClick={handlePrev}
            disabled={activeIndex === 0}
            sx={{
              position: 'absolute',
              left: -20,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              bgcolor: 'background.paper',
              boxShadow: 2,
              '&:hover': {
                bgcolor: 'background.paper',
              },
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <Grid container spacing={3}>
            {visibleTestimonials.map((testimonial) => (
              <Grid item xs={12} md={12 / testimonialsToShow} key={testimonial.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                    boxShadow: 3,
                    position: 'relative',
                    overflow: 'visible',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: -15,
                      left: 20,
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 1,
                    },
                  }}
                >
                  <FormatQuoteIcon
                    sx={{
                      position: 'absolute',
                      top: -22,
                      left: 24,
                      color: 'white',
                      zIndex: 2,
                      fontSize: 20,
                    }}
                  />
                  <CardContent sx={{ p: 4, flexGrow: 1 }}>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        fontStyle: 'italic',
                        mb: 3,
                        minHeight: { xs: 'auto', md: 150 },
                      }}
                    >
                      &quot;{testimonial.text}&quot;
                    </Typography>

                    <Box sx={{ mb: 1 }}>
                      <Typography variant="h6" component="div">
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.location}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                      }}
                    >
                      <Rating value={testimonial.rating} precision={0.5} readOnly />
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.project}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <IconButton
            onClick={handleNext}
            disabled={activeIndex >= maxIndex}
            sx={{
              position: 'absolute',
              right: -20,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              bgcolor: 'background.paper',
              boxShadow: 2,
              '&:hover': {
                bgcolor: 'background.paper',
              },
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        {/* Mobile navigation */}
        {isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 4 }}>
            <IconButton onClick={handlePrev} disabled={activeIndex === 0} sx={{ boxShadow: 1 }}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton
              onClick={handleNext}
              disabled={activeIndex >= maxIndex}
              sx={{ boxShadow: 1 }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}

        {/* Stats section */}
        <Box sx={{ mt: 8 }}>
          <Grid container spacing={3}>
            {stats.map((stat, index) => (
              <Grid item xs={6} md={3} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    textAlign: 'center',
                    bgcolor: 'rgba(33, 150, 243, 0.05)',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    component="div"
                    color="primary"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: '1.75rem', md: '2.5rem' },
                    }}
                  >
                    {stat.value}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {stat.label}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
