import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Chip,
  Button,
  CircularProgress,
  Alert,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  Badge,
  FormControl,
  InputLabel,
  Select,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { Lead } from '../../../types/lead';
import axios from '../../../services/axios';
import { formatDistanceToNow, isAfter } from 'date-fns';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import StarIcon from '@mui/icons-material/Star';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lead-tabpanel-${index}`}
      aria-labelledby={`lead-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `lead-tab-${index}`,
    'aria-controls': `lead-tabpanel-${index}`,
  };
};

const LeadsList: React.FC = () => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [sortBy, setSortBy] = useState<string>('date-desc');
  const [showFilters, setShowFilters] = useState(false);
  const [dateFilter, setDateFilter] = useState<string>('all');
  const [budgetFilter, setBudgetFilter] = useState<string>('all');
  const [tabValue, setTabValue] = useState(0);
  const { getAuthToken } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        setLoading(true);
        const token = getAuthToken();

        if (!token) {
          setError('Authentication required');
          setLoading(false);
          return;
        }

        const response = await axios.get('/leads/provider', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setLeads(response.data);
        setFilteredLeads(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching lead requests:', err);
        setError('Failed to load lead requests. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, [getAuthToken]);

  useEffect(() => {
    // Apply filters and sorting
    let result = [...leads];

    // Status filter
    if (statusFilter !== 'all') {
      result = result.filter(lead => lead.status === statusFilter);
    }

    // Tab filter (New, Active, Completed)
    if (tabValue === 0) { // New
      result = result.filter(lead => lead.status === 'pending' || lead.status === 'matched');
    } else if (tabValue === 1) { // Active
      result = result.filter(lead => lead.status === 'responded' || lead.status === 'accepted');
    } else if (tabValue === 2) { // Completed/Declined
      result = result.filter(lead => lead.status === 'completed' || lead.status === 'declined');
    }

    // Search term
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(lead =>
        lead.details.description.toLowerCase().includes(term) ||
        lead.details.location.address.toLowerCase().includes(term) ||
        (typeof lead.service === 'object' && lead.service.name.toLowerCase().includes(term))
      );
    }

    // Date filter
    if (dateFilter === 'today') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      result = result.filter(lead => isAfter(new Date(lead.createdAt), today));
    } else if (dateFilter === 'week') {
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);
      result = result.filter(lead => isAfter(new Date(lead.createdAt), weekAgo));
    } else if (dateFilter === 'month') {
      const monthAgo = new Date();
      monthAgo.setMonth(monthAgo.getMonth() - 1);
      result = result.filter(lead => isAfter(new Date(lead.createdAt), monthAgo));
    }

    // Budget filter
    if (budgetFilter === 'low') {
      result = result.filter(lead =>
        lead.details.budget && lead.details.budget.max <= 500
      );
    } else if (budgetFilter === 'medium') {
      result = result.filter(lead =>
        lead.details.budget &&
        lead.details.budget.min > 500 &&
        lead.details.budget.max <= 2000
      );
    } else if (budgetFilter === 'high') {
      result = result.filter(lead =>
        lead.details.budget && lead.details.budget.min > 2000
      );
    }

    // Sorting
    if (sortBy === 'date-desc') {
      result.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    } else if (sortBy === 'date-asc') {
      result.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    } else if (sortBy === 'budget-desc') {
      result.sort((a, b) => {
        const maxA = a.details.budget?.max || 0;
        const maxB = b.details.budget?.max || 0;
        return maxB - maxA;
      });
    } else if (sortBy === 'budget-asc') {
      result.sort((a, b) => {
        const minA = a.details.budget?.min || 0;
        const minB = b.details.budget?.min || 0;
        return minA - minB;
      });
    }

    setFilteredLeads(result);
  }, [leads, searchTerm, statusFilter, sortBy, dateFilter, budgetFilter, tabValue]);

  const handleViewLead = (leadId: string) => {
    navigate(`/pro/leads/${leadId}`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getStatusChip = (status: string) => {
    let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'default';
    let icon = null;

    switch (status) {
      case 'pending':
        color = 'warning';
        icon = <HourglassEmptyIcon fontSize="small" />;
        break;
      case 'matched':
        color = 'info';
        icon = <StarIcon fontSize="small" />;
        break;
      case 'responded':
        color = 'primary';
        icon = <CheckCircleIcon fontSize="small" />;
        break;
      case 'accepted':
        color = 'success';
        icon = <CheckCircleIcon fontSize="small" />;
        break;
      case 'declined':
        color = 'error';
        icon = <CancelIcon fontSize="small" />;
        break;
      case 'completed':
        color = 'secondary';
        icon = <CheckCircleIcon fontSize="small" />;
        break;
    }

    return (
      <Chip
        icon={icon ? icon : undefined}
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        color={color}
        size="small"
        sx={{ fontWeight: 'medium' }}
      />
    );
  };

  const getLeadCounts = () => {
    const newCount = leads.filter(lead =>
      lead.status === 'pending' || lead.status === 'matched'
    ).length;

    const activeCount = leads.filter(lead =>
      lead.status === 'responded' || lead.status === 'accepted'
    ).length;

    const completedCount = leads.filter(lead =>
      lead.status === 'completed' || lead.status === 'declined'
    ).length;

    return { newCount, activeCount, completedCount };
  };

  const { newCount, activeCount, completedCount } = getLeadCounts();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
        {error}
      </Alert>
    );
  }

  if (leads.length === 0) {
    return (
      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Lead Requests
        </Typography>
        <Alert severity="info">
          You don't have any lead requests yet. When customers request your services, they'll appear here.
        </Alert>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
        <Typography variant="h6">
          Lead Requests ({leads.length})
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField
            placeholder="Search leads..."
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: { xs: '100%', sm: 200 } }}
          />
          <IconButton
            color={showFilters ? "primary" : "default"}
            onClick={() => setShowFilters(!showFilters)}
            size="small"
          >
            <FilterListIcon />
          </IconButton>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="sort-label">Sort By</InputLabel>
            <Select
              labelId="sort-label"
              value={sortBy}
              label="Sort By"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value="date-desc">Newest First</MenuItem>
              <MenuItem value="date-asc">Oldest First</MenuItem>
              <MenuItem value="budget-desc">Budget: High to Low</MenuItem>
              <MenuItem value="budget-asc">Budget: Low to High</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {showFilters && (
        <Box sx={{ mb: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Filters
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="status-filter-label">Status</InputLabel>
                <Select
                  labelId="status-filter-label"
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value="all">All Statuses</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="matched">Matched</MenuItem>
                  <MenuItem value="responded">Responded</MenuItem>
                  <MenuItem value="accepted">Accepted</MenuItem>
                  <MenuItem value="declined">Declined</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="date-filter-label">Date</InputLabel>
                <Select
                  labelId="date-filter-label"
                  value={dateFilter}
                  label="Date"
                  onChange={(e) => setDateFilter(e.target.value)}
                >
                  <MenuItem value="all">All Time</MenuItem>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">Last 7 Days</MenuItem>
                  <MenuItem value="month">Last 30 Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="budget-filter-label">Budget</InputLabel>
                <Select
                  labelId="budget-filter-label"
                  value={budgetFilter}
                  label="Budget"
                  onChange={(e) => setBudgetFilter(e.target.value)}
                >
                  <MenuItem value="all">All Budgets</MenuItem>
                  <MenuItem value="low">Low (≤ $500)</MenuItem>
                  <MenuItem value="medium">Medium ($500 - $2000)</MenuItem>
                  <MenuItem value="high">High ({'>'}$2000)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="lead status tabs"
          variant={isMobile ? "fullWidth" : "standard"}
        >
          <Tab
            label={
              <Badge badgeContent={newCount} color="warning" max={99}>
                <Box sx={{ px: 1 }}>New</Box>
              </Badge>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Badge badgeContent={activeCount} color="primary" max={99}>
                <Box sx={{ px: 1 }}>Active</Box>
              </Badge>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Badge badgeContent={completedCount} color="default" max={99}>
                <Box sx={{ px: 1 }}>Completed</Box>
              </Badge>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        {renderLeadsList()}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {renderLeadsList()}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {renderLeadsList()}
      </TabPanel>
    </Paper>
  );

  function renderLeadsList() {
    if (filteredLeads.length === 0) {
      return (
        <Alert severity="info" sx={{ my: 2 }}>
          No leads match your current filters.
        </Alert>
      );
    }

    return (
      <Grid container spacing={2}>
        {filteredLeads.map((lead) => (
          <Grid item xs={12} key={lead._id}>
            <Card variant="outlined" sx={{
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                boxShadow: 3,
              },
            }}>
              <CardContent sx={{ pb: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      {lead.status === 'pending' && (
                        <FiberNewIcon color="warning" sx={{ mr: 1 }} />
                      )}
                      <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'medium' }}>
                        {typeof lead.service === 'object' ? lead.service.name : 'Service Request'}
                      </Typography>
                      <Box sx={{ ml: 'auto', display: { xs: 'none', sm: 'block' } }}>
                        {getStatusChip(lead.status)}
                      </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      {lead.details.description.length > 120
                        ? `${lead.details.description.substring(0, 120)}...`
                        : lead.details.description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocationOnIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                        <Typography variant="body2" color="text.secondary">
                          {lead.details.location.address.length > 25
                            ? `${lead.details.location.address.substring(0, 25)}...`
                            : lead.details.location.address}
                        </Typography>
                      </Box>
                      {lead.details.budget && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <AttachMoneyIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                          <Typography variant="body2" color="text.secondary">
                            ${lead.details.budget.min} - ${lead.details.budget.max}
                          </Typography>
                        </Box>
                      )}
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                        <Typography variant="body2" color="text.secondary">
                          {formatDistanceToNow(new Date(lead.createdAt), { addSuffix: true })}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', sm: 'flex-end' },
                  }}>
                    <Box sx={{ display: { xs: 'block', sm: 'none' }, mb: 1 }}>
                      {getStatusChip(lead.status)}
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleViewLead(lead._id)}
                        sx={{ mt: { xs: 1, sm: 0 } }}
                      >
                        View Details
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
};

export default LeadsList;