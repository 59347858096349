import React, { useState, useRef, useEffect } from 'react';
import {
  IconButton,
  Badge,
  Popover,
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
  List,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../contexts/NotificationContext';
import NotificationItem from './NotificationItem';

interface NotificationDropdownProps {
  maxItems?: number;
  color?: 'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning';
}

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
  maxItems = 5,
  color = 'primary',
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { notifications, counts, loading, error, fetchNotifications, markAllAsRead } = useNotifications();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Fetch notifications when dropdown is opened
  useEffect(() => {
    if (anchorEl) {
      fetchNotifications(true);
    }
  }, [anchorEl, fetchNotifications]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewAll = () => {
    navigate('/pro/notifications');
    handleClose();
  };

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  // Get recent notifications
  const recentNotifications = notifications.slice(0, maxItems);

  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-describedby={id}
        color="inherit"
        onClick={handleClick}
        size="large"
      >
        <Badge badgeContent={counts?.unread || 0} color={color} max={99}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: 400 },
            maxWidth: { xs: '100%', sm: 400 },
            maxHeight: 500,
            overflow: 'hidden',
          },
        }}
      >
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Notifications</Typography>
          {counts && counts.unread > 0 && (
            <Button size="small" onClick={handleMarkAllAsRead}>
              Mark All as Read
            </Button>
          )}
        </Box>

        <Divider />

        <Box sx={{ maxHeight: 350, overflow: 'auto' }}>
          {loading && recentNotifications.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress size={24} />
            </Box>
          ) : error ? (
            <Box sx={{ p: 2 }}>
              <Typography color="error">{error}</Typography>
            </Box>
          ) : recentNotifications.length === 0 ? (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                You don't have any notifications yet.
              </Typography>
            </Box>
          ) : (
            <List disablePadding>
              {recentNotifications.map((notification, index) => (
                <React.Fragment key={notification._id}>
                  <NotificationItem notification={notification} showActions={false} />
                  {index < recentNotifications.length - 1 && <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>

        <Divider />

        <Box sx={{ p: 1.5, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleViewAll} fullWidth>
            View All Notifications
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationDropdown;