import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axiosInstance from '../../services/axios';

// Interfaces
export interface ServiceArea {
  _id?: string;
  zipCode: string;
  city: string;
  state: string;
  radius: number;
}

// API Certification interface (dates as ISO strings)
export interface Certification {
  _id: string;
  name: string;
  issuingAuthority: string;
  issueDate: string; // ISO string in API
  expiryDate: string | null; // ISO string in API
  documentUrl: string;
}

// Form Certification interface (dates as Date objects)
export interface CertificationForm extends Omit<Certification, '_id' | 'issueDate' | 'expiryDate'> {
  issueDate: Date;
  expiryDate: Date | null;
}

export interface InsuranceInfo {
  provider: string;
  policyNumber: string;
  expiryDate: Date;
  coverageType: string;
  documentUrl: string;
}

export interface WorkingHours {
  _id?: string;
  day: string;
  isOpen: boolean;
  openTime: string;
  closeTime: string;
}

// API PortfolioItem interface
export interface PortfolioItem {
  _id: string;
  title: string;
  description: string;
  buildingName?: string;
  year: string;
  cost: string;
  style: string;
  roomType: string;
  images: string[];
}

// Form PortfolioItem interface
export type PortfolioItemForm = Omit<PortfolioItem, '_id'>

export interface ProviderProfile {
  _id: string;
  user?: string;
  businessName: string;
  businessDescription: string;
  yearsInBusiness: number;
  logoUrl?: string;
  serviceAreas: ServiceArea[];
  certifications: Certification[];
  insuranceInfo?: InsuranceInfo;
  workingHours: WorkingHours[];
  portfolio: PortfolioItem[];
  services?: any[];
  isVerified?: boolean;
  averageRating?: number;
  totalReviews?: number;
  completedJobs?: number;
}

interface ProviderState {
  profile: ProviderProfile | null;
  loading: boolean;
  error: string | null;
}

interface ErrorResponse {
  message: string;
}

// Async thunks
export const fetchProviderProfile = createAsyncThunk(
  'provider/fetchProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/pro/profile');
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch profile');
    }
  }
);

export interface BusinessInfoUpdate {
  businessName: string;
  businessDescription: string;
  yearsInBusiness: number;
  logoUrl?: string;
}

export const updateBusinessInfo = createAsyncThunk(
  'provider/updateBusinessInfo',
  async (data: BusinessInfoUpdate, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/pro/business-info', data);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to update business info');
    }
  }
);

export const updateServiceAreas = createAsyncThunk(
  'provider/updateServiceAreas',
  async (serviceAreas: ServiceArea[], { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/pro/service-areas', { serviceAreas });
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to update service areas');
    }
  }
);

export const updateWorkingHours = createAsyncThunk(
  'provider/updateWorkingHours',
  async (workingHours: WorkingHours[], { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/pro/working-hours', { workingHours });
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to update working hours');
    }
  }
);

export const addCertification = createAsyncThunk(
  'provider/addCertification',
  async (data: CertificationForm, { rejectWithValue }) => {
    try {
      const certData = {
        ...data,
        issueDate: data.issueDate.toISOString(),
        expiryDate: data.expiryDate?.toISOString() || null,
      };
      const response = await axiosInstance.post('/pro/certifications', certData);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to add certification');
    }
  }
);

export const deleteCertification = createAsyncThunk(
  'provider/deleteCertification',
  async (certId: string, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/pro/certifications/${certId}`);
      return certId;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to delete certification');
    }
  }
);

export const addPortfolioItem = createAsyncThunk(
  'provider/addPortfolioItem',
  async (data: PortfolioItemForm, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/pro/portfolio', data);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to add portfolio item');
    }
  }
);

export const deletePortfolioItem = createAsyncThunk(
  'provider/deletePortfolioItem',
  async (itemId: string, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/pro/portfolio/${itemId}`);
      return itemId;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to delete portfolio item');
    }
  }
);

export const updateInsurance = createAsyncThunk(
  'provider/updateInsurance',
  async (data: InsuranceInfo, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/pro/insurance', data);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      return rejectWithValue(error.response?.data?.message || 'Failed to update insurance info');
    }
  }
);

// Slice
const initialState: ProviderState = {
  profile: null,
  loading: false,
  error: null,
};

const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Profile
      .addCase(fetchProviderProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProviderProfile.fulfilled, (state, action: PayloadAction<ProviderProfile>) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProviderProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Update Business Info
      .addCase(updateBusinessInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBusinessInfo.fulfilled, (state, action: PayloadAction<ProviderProfile>) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(updateBusinessInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Update Service Areas
      .addCase(updateServiceAreas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateServiceAreas.fulfilled, (state, action: PayloadAction<ProviderProfile>) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(updateServiceAreas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Update Working Hours
      .addCase(updateWorkingHours.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateWorkingHours.fulfilled, (state, action: PayloadAction<ProviderProfile>) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(updateWorkingHours.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Add Certification
      .addCase(addCertification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCertification.fulfilled, (state, action: PayloadAction<ProviderProfile>) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(addCertification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Delete Certification
      .addCase(deleteCertification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCertification.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        if (state.profile) {
          state.profile.certifications = state.profile.certifications.filter(
            (cert) => cert._id !== action.payload
          );
        }
      })
      .addCase(deleteCertification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Add Portfolio Item
      .addCase(addPortfolioItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPortfolioItem.fulfilled, (state, action: PayloadAction<ProviderProfile>) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(addPortfolioItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Delete Portfolio Item
      .addCase(deletePortfolioItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePortfolioItem.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        if (state.profile) {
          state.profile.portfolio = state.profile.portfolio.filter(
            (item) => item._id !== action.payload
          );
        }
      })
      .addCase(deletePortfolioItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Update Insurance
      .addCase(updateInsurance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateInsurance.fulfilled, (state, action: PayloadAction<ProviderProfile>) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(updateInsurance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearError } = providerSlice.actions;

export default providerSlice.reducer;
