import React from 'react';
import { Box, Container, Typography, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const FlatFooter: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'background.paper',
        py: 2,
        mt: 'auto',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Homezy - Home Made Easy. All rights reserved.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            sx={{ mt: { xs: 1, sm: 0 } }}
          >
            <Link component={RouterLink} to="/help" color="inherit">
              <Typography variant="body2">Help</Typography>
            </Link>
            <Link component={RouterLink} to="/contact" color="inherit">
              <Typography variant="body2">Contact</Typography>
            </Link>
            <Link component={RouterLink} to="/privacy" color="inherit">
              <Typography variant="body2">Privacy</Typography>
            </Link>
            <Link component={RouterLink} to="/terms" color="inherit">
              <Typography variant="body2">Terms</Typography>
            </Link>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default FlatFooter;