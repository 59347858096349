import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography, Box, Paper, Alert, Snackbar, Button } from '@mui/material';
import { createLead, submitPreferences, getMatchedProviders } from '../../redux/slices/leadSlice';
import { RootState, AppDispatch } from '../../redux/store';
import { CreateLeadDTO } from '../../types/lead';
import { CustomerPreference } from '../../types/preference';
import LeadForm from '../../components/enquiry/LeadForm';

const EnhancedCreateLeadPage: React.FC = () => {
  const { serviceId: urlServiceId } = useParams<{ serviceId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  // Get provider ID and service ID from query params if available
  const queryParams = new URLSearchParams(location.search);
  const providerId = queryParams.get('providerId') || queryParams.get('provider'); // Support both new and old parameter names
  const queryServiceId = queryParams.get('service');

  // Use service ID from URL params or query params
  const serviceId = urlServiceId || queryServiceId;

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [leadId, setLeadId] = useState<string | null>(null);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const matchedProviders = useSelector((state: RootState) => state.lead.matchedProviders);
  const leadLoading = useSelector((state: RootState) => state.lead.loading);
  const leadError = useSelector((state: RootState) => state.lead.error);

  const handleComplete = async (leadData: CreateLeadDTO, preferences: CustomerPreference[]) => {
    try {
      console.log('Service ID from params:', serviceId);
      console.log('Provider ID from query params:', providerId);

      // Create the lead
      const leadResult = await dispatch(
        createLead({
          ...leadData,
          // Ensure service ID is included if available
          ...(leadData.service ? { service: leadData.service } : {}),
          ...(serviceId ? { service: serviceId } : {}),
          // If we have a providerId, include it in the lead data
          ...(providerId ? { providerId: providerId } : {}),
        })
      ).unwrap();

      // Check if verification email was sent (for guest users)
      // The server may return a message property in the response
      if (
        'message' in leadResult &&
        typeof leadResult.message === 'string' &&
        leadResult.message.includes('verification email')
      ) {
        setVerificationEmailSent(true);
      }

      setLeadId(leadResult._id);

      // Submit preferences
      await dispatch(
        submitPreferences({
          leadId: leadResult._id,
          preferences,
        })
      ).unwrap();

      // Get matched providers
      await dispatch(getMatchedProviders(leadResult._id)).unwrap();

      setSuccess(true);
    } catch (err: any) {
      console.error('Error creating lead with preferences:', err);

      // Extract error message from the response if available
      let errorMessage = 'Failed to create lead with preferences';

      if (err.message) {
        errorMessage = err.message;
      }

      if (err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }

      console.error('Error details:', err.response?.data || err);

      setError(errorMessage);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
  };

  const handleMessageProvider = (providerId: string) => {
    if (leadId) {
      navigate(`/messages/new?leadId=${leadId}&providerId=${providerId}`);
    }
  };

  const handleViewMatches = () => {
    if (leadId) {
      navigate(`/leads/${leadId}/matches`);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Tell Us About Your Project
      </Typography>

      {(error || leadError) && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error || leadError}
        </Alert>
      )}

      {success ? (
        <Paper sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom>
            Thank you for your request!
          </Typography>

          {verificationEmailSent ? (
            <Alert severity="info" sx={{ mb: 3 }}>
              A verification email has been sent to your email address. Please verify your email to
              complete your registration.
            </Alert>
          ) : null}

          <Typography paragraph>
            We've received your project details and are matching you with qualified professionals.
          </Typography>

          {matchedProviders && matchedProviders.length > 0 ? (
            <>
              <Typography variant="h6" gutterBottom>
                We've found {matchedProviders.length} professionals that match your needs!
              </Typography>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
                <Button variant="contained" color="primary" onClick={handleViewMatches}>
                  View Matched Professionals
                </Button>
              </Box>
            </>
          ) : (
            <Typography>We'll notify you when professionals respond to your request.</Typography>
          )}
        </Paper>
      ) : (
        <LeadForm
          serviceId={serviceId || ''}
          providerId={providerId || null}
          onComplete={handleComplete}
          onCancel={() => navigate(-1)}
        />
      )}

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Your request has been submitted successfully!"
      />
    </Container>
  );
};

export default EnhancedCreateLeadPage;
