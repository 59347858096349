import React, { useState, useEffect } from 'react';
import {
  Box, Button, Typography, CircularProgress, Alert,
  FormControl, InputLabel, Select, MenuItem, SelectChangeEvent,
  List, ListItem, ListItemText, ListItemAvatar, Avatar, Rating, Checkbox,
  Paper,
} from '@mui/material';
import { useReviewsOnboarding } from '../ReviewsOnboardingProvider';
import axios from '../../../services/axios';
import { useAuth } from '../../../hooks/useAuth';

interface GoogleBusinessLocation {
  id: string;
  name: string;
  address?: string;
}

/**
 * Component for importing reviews from Google Business Profile
 * Used in the onboarding flow after connecting Google Business Profile
 */
const GoogleReviewImport: React.FC = () => {
  const {
    googleLocations,
    selectedLocation,
    setSelectedLocation,
    importGoogleReviews,
    isLoading,
    error,
  } = useReviewsOnboarding();

  // Type assertion for googleLocations
  const typedLocations = googleLocations as GoogleBusinessLocation[];

  const { getAuthToken } = useAuth();
  const [localLoading, setLocalLoading] = useState(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const [previewReviews, setPreviewReviews] = useState<any[]>([]);
  const [selectedReviews, setSelectedReviews] = useState<string[]>([]);
  const [importSuccess, setImportSuccess] = useState(false);

  useEffect(() => {
    // If we have a selected location and no preview reviews, fetch them
    if (selectedLocation && previewReviews.length === 0 && !localLoading) {
      handleFetchReviewPreview();
    }
  }, [selectedLocation]);

  const handleLocationChange = (event: SelectChangeEvent) => {
    setSelectedLocation(event.target.value);
    setPreviewReviews([]);
    setSelectedReviews([]);
    setImportSuccess(false);
  };

  const handleFetchReviewPreview = async () => {
    if (!selectedLocation) return;

    setLocalLoading(true);
    setLocalError(null);

    try {
      // In a real implementation, this would be a dedicated endpoint
      // For this implementation, we'll use the import endpoint but just preview the data
      const token = getAuthToken();
      if (!token) {
        setLocalError('Authentication required');
        return;
      }

      const response = await axios.post(`/provider/google/import-reviews?preview=true`, {
        locationId: selectedLocation,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPreviewReviews(response.data.reviews || []);

      // Select all reviews by default
      if (response.data.reviews && response.data.reviews.length > 0) {
        setSelectedReviews(response.data.reviews.map((review: any) => review.sourceId));
      } else {
        // No reviews found
        setLocalError('No reviews found for this location. If you believe this is an error, please try reconnecting your Google Business Profile.');
      }
    } catch (error: any) {
      console.error('Error fetching review preview:', error);
      if (error.response?.status === 500) {
        setLocalError('Server error while fetching reviews. The Google Business API integration may not be properly configured.');
      } else {
        setLocalError(error.response?.data?.message || 'Failed to fetch review preview');
      }
    } finally {
      setLocalLoading(false);
    }
  };

  const handleToggleReview = (reviewId: string) => {
    setSelectedReviews(prev => {
      if (prev.includes(reviewId)) {
        return prev.filter(id => id !== reviewId);
      } else {
        return [...prev, reviewId];
      }
    });
  };

  const handleImportSelectedReviews = async () => {
    setLocalLoading(true);
    setLocalError(null);

    try {
      const success = await importGoogleReviews();
      if (success) {
        setImportSuccess(true);
      } else {
        setLocalError('Failed to import reviews');
      }
    } catch (error: any) {
      setLocalError(error.message || 'Failed to import reviews');
    } finally {
      setLocalLoading(false);
    }
  };

  const isLoaded = googleLocations.length > 0;
  const showPreview = selectedLocation && previewReviews.length > 0;

  if (!isLoaded) {
    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Import Google Reviews
        </Typography>
        <Alert severity="info">
          Please connect your Google Business Profile first to import reviews.
        </Alert>
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Import Google Reviews
      </Typography>

      {(error || localError) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error || localError}
        </Alert>
      )}

      {importSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Reviews successfully imported! They will now appear on your profile.
        </Alert>
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Select Business Location</InputLabel>
        <Select
          value={selectedLocation || ''}
          onChange={handleLocationChange}
          disabled={isLoading || localLoading || googleLocations.length === 0}
        >
          {typedLocations.map((location: GoogleBusinessLocation) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name} {location.address ? `(${location.address})` : ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {!showPreview && selectedLocation && (
        <Button
          variant="outlined"
          onClick={handleFetchReviewPreview}
          disabled={!selectedLocation || isLoading || localLoading}
          sx={{ mt: 2, mb: 3 }}
        >
          Preview Reviews
        </Button>
      )}

      {(isLoading || localLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {showPreview && (
        <>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
            Select reviews to import:
          </Typography>

          <List>
            {previewReviews.map(review => (
              <ListItem
                key={review.sourceId}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleToggleReview(review.sourceId)}
                    checked={selectedReviews.includes(review.sourceId)}
                  />
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    {review.customerName ? review.customerName.charAt(0) : 'U'}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle2">
                        {review.customerName || 'Anonymous User'}
                      </Typography>
                      <Rating
                        value={review.rating}
                        readOnly
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    </Box>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="text.primary">
                        {review.content.slice(0, 100)}
                        {review.content.length > 100 ? '...' : ''}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {new Date(review.createdAt || review.importedAt).toLocaleDateString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>

          <Button
            variant="contained"
            color="primary"
            onClick={handleImportSelectedReviews}
            disabled={isLoading || localLoading || selectedReviews.length === 0 || importSuccess}
            sx={{ mt: 2 }}
          >
            {importSuccess ? 'Reviews Imported' : 'Import Selected Reviews'}
          </Button>
        </>
      )}
    </Paper>
  );
};

export default GoogleReviewImport;