import axiosInstance from './axios';

/**
 * Service for interacting with the stories API
 */
const storyService = {
  /**
   * Get all stories with optional filtering
   * @param params Optional query parameters for filtering
   * @returns Promise with stories data
   */
  getStories: async (params?: {
    category?: string;
    tag?: string;
    featured?: boolean;
    page?: number;
    limit?: number;
  }) => {
    const response = await axiosInstance.get('/stories', { params });
    return response.data;
  },

  /**
   * Get a single story by ID
   * @param id Story ID
   * @returns Promise with story data
   */
  getStoryById: async (id: string) => {
    const response = await axiosInstance.get(`/stories/${id}`);
    return response.data;
  },

  /**
   * Search stories by query
   * @param query Search query
   * @param params Optional pagination parameters
   * @returns Promise with search results
   */
  searchStories: async (
    query: string,
    params?: {
      page?: number;
      limit?: number;
    }
  ) => {
    const response = await axiosInstance.get('/stories/search', {
      params: {
        q: query,
        ...params,
      },
    });
    return response.data;
  },

  /**
   * Create a new story (admin only)
   * @param storyData Story data
   * @returns Promise with created story
   */
  createStory: async (storyData: any) => {
    const response = await axiosInstance.post('/stories', storyData);
    return response.data;
  },

  /**
   * Update an existing story (admin only)
   * @param id Story ID
   * @param storyData Updated story data
   * @returns Promise with updated story
   */
  updateStory: async (id: string, storyData: any) => {
    const response = await axiosInstance.put(`/stories/${id}`, storyData);
    return response.data;
  },

  /**
   * Delete a story (admin only)
   * @param id Story ID
   * @returns Promise with success status
   */
  deleteStory: async (id: string) => {
    const response = await axiosInstance.delete(`/stories/${id}`);
    return response.data;
  },
};

export default storyService;