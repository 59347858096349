import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  ButtonGroup,
  Alert,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  ViewDay as DayViewIcon,
  ViewWeek as WeekViewIcon,
  CalendarViewMonth as MonthViewIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { format, addMonths, subMonths, addWeeks, subWeeks, addDays, subDays } from 'date-fns';
import { SelectChangeEvent } from '@mui/material/Select';

// Mock FullCalendar types since we don't have the actual packages installed
// These would be replaced with actual imports when the packages are installed
interface EventInput {
  id?: string;
  title: string;
  start: string;
  end: string;
  allDay?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  extendedProps?: any;
  editable?: boolean;
}

interface DateSelectArg {
  startStr: string;
  endStr: string;
  allDay: boolean;
}

interface EventClickArg {
  event: {
    id: string;
    title: string;
    start: Date;
    end: Date;
  };
}

import { useCalendar } from '../../../contexts/CalendarContext';
import { CalendarEvent, CalendarEventCreateRequest, CalendarEventUpdateRequest } from '../../../types/calendar';

// Mock FullCalendar component and plugins
const FullCalendar = (props: any) => <div>Calendar would render here</div>;
const dayGridPlugin = {};
const timeGridPlugin = {};
const interactionPlugin = {};

type CalendarViewType = 'month' | 'week' | 'day';

interface EventFormValues {
  title: string;
  start: string;
  end: string;
  allDay: boolean;
  description: string;
  location: string;
  status: 'scheduled' | 'completed' | 'cancelled' | 'pending';
}

const initialEventFormValues: EventFormValues = {
  title: '',
  start: '',
  end: '',
  allDay: false,
  description: '',
  location: '',
  status: 'scheduled',
};

const CalendarView: React.FC = () => {
  const { events, loading, error, fetchEvents, createEvent, updateEvent, deleteEvent } = useCalendar();
  const [viewType, setViewType] = useState<CalendarViewType>('month');
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState<CalendarEvent | null>(null);
  const [formValues, setFormValues] = useState<EventFormValues>(initialEventFormValues);
  const [submitting, setSubmitting] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([]);

  // Fetch events for the current view
  const loadEvents = useCallback(() => {
    let start: Date;
    let end: Date;

    switch (viewType) {
      case 'month':
        start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 'week':
        // Get the start of the week (Sunday)
        start = new Date(currentDate);
        start.setDate(currentDate.getDate() - currentDate.getDay());
        // Get the end of the week (Saturday)
        end = new Date(start);
        end.setDate(start.getDate() + 6);
        break;
      case 'day':
        start = new Date(currentDate);
        end = new Date(currentDate);
        break;
      default:
        start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }

    fetchEvents(start.toISOString(), end.toISOString());
  }, [currentDate, viewType, fetchEvents]);

  // Convert events to FullCalendar format
  useEffect(() => {
    if (events) {
      const formattedEvents = events.map((event) => ({
        id: event._id,
        title: event.title,
        start: event.start,
        end: event.end,
        allDay: event.allDay,
        extendedProps: {
          description: event.description,
          location: event.location,
          status: event.status,
          leadId: event.leadId,
          customerId: event.customerId,
          customerName: event.customerName,
        },
        backgroundColor: getEventColor(event.status),
        borderColor: getEventColor(event.status),
        editable: event.editable,
      }));
      setCalendarEvents(formattedEvents);
    }
  }, [events]);

  // Load events when view or date changes
  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  const getEventColor = (status?: string) => {
    switch (status) {
      case 'completed':
        return '#4caf50'; // Green
      case 'cancelled':
        return '#f44336'; // Red
      case 'pending':
        return '#ff9800'; // Orange
      case 'scheduled':
      default:
        return '#2196f3'; // Blue
    }
  };

  const handleViewChange = (newView: CalendarViewType) => {
    setViewType(newView);
  };

  const handlePrevious = () => {
    switch (viewType) {
      case 'month':
        setCurrentDate(subMonths(currentDate, 1));
        break;
      case 'week':
        setCurrentDate(subWeeks(currentDate, 1));
        break;
      case 'day':
        setCurrentDate(subDays(currentDate, 1));
        break;
    }
  };

  const handleNext = () => {
    switch (viewType) {
      case 'month':
        setCurrentDate(addMonths(currentDate, 1));
        break;
      case 'week':
        setCurrentDate(addWeeks(currentDate, 1));
        break;
      case 'day':
        setCurrentDate(addDays(currentDate, 1));
        break;
    }
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  const handleOpenDialog = (event?: CalendarEvent) => {
    if (event) {
      setEditingEvent(event);
      setFormValues({
        title: event.title,
        start: event.start,
        end: event.end,
        allDay: event.allDay,
        description: event.description || '',
        location: event.location || '',
        status: event.status,
      });
    } else {
      setEditingEvent(null);
      setFormValues(initialEventFormValues);
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }> | SelectChangeEvent
  ) => {
    const target = event.target;
    const name = target.name as string;
    const value = target.value;
    const type = (target as HTMLInputElement).type;
    const checked = (target as HTMLInputElement).checked;

    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      const eventData: CalendarEventCreateRequest | CalendarEventUpdateRequest = {
        title: formValues.title,
        start: formValues.start,
        end: formValues.end,
        allDay: formValues.allDay,
        description: formValues.description,
        location: formValues.location,
        status: formValues.status,
      };

      if (editingEvent) {
        await updateEvent(editingEvent._id, eventData);
      } else {
        await createEvent(eventData as CalendarEventCreateRequest);
      }

      handleCloseDialog();
      loadEvents();
    } catch (err) {
      console.error('Error saving event:', err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteEvent = async () => {
    if (!editingEvent) return;

    try {
      setSubmitting(true);
      await deleteEvent(editingEvent._id);
      handleCloseDialog();
      loadEvents();
    } catch (err) {
      console.error('Error deleting event:', err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    setFormValues({
      ...initialEventFormValues,
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      allDay: selectInfo.allDay,
    });
    setEditingEvent(null);
    setDialogOpen(true);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventId = clickInfo.event.id;
    const event = events.find((e) => e._id === eventId);
    if (event) {
      handleOpenDialog(event);
    }
  };

  const getViewTitle = () => {
    switch (viewType) {
      case 'month':
        return format(currentDate, 'MMMM yyyy');
      case 'week':
        const weekStart = new Date(currentDate);
        weekStart.setDate(currentDate.getDate() - currentDate.getDay());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        return `${format(weekStart, 'MMM d')} - ${format(weekEnd, 'MMM d, yyyy')}`;
      case 'day':
        return format(currentDate, 'EEEE, MMMM d, yyyy');
      default:
        return format(currentDate, 'MMMM yyyy');
    }
  };

  if (loading && events.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <ButtonGroup variant="outlined" size="small">
              <Button onClick={handlePrevious}>Previous</Button>
              <Button onClick={handleToday}>Today</Button>
              <Button onClick={handleNext}>Next</Button>
            </ButtonGroup>
          </Grid>

          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">{getViewTitle()}</Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup variant="outlined" size="small">
              <Tooltip title="Month View">
                <Button
                  onClick={() => handleViewChange('month')}
                  variant={viewType === 'month' ? 'contained' : 'outlined'}
                >
                  <MonthViewIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Week View">
                <Button
                  onClick={() => handleViewChange('week')}
                  variant={viewType === 'week' ? 'contained' : 'outlined'}
                >
                  <WeekViewIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Day View">
                <Button
                  onClick={() => handleViewChange('day')}
                  variant={viewType === 'day' ? 'contained' : 'outlined'}
                >
                  <DayViewIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
              sx={{ ml: 2 }}
            >
              Add Event
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ p: 2, height: 'calc(100vh - 300px)', minHeight: '500px' }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={false}
          initialView={
            viewType === 'month'
              ? 'dayGridMonth'
              : viewType === 'week'
                ? 'timeGridWeek'
                : 'timeGridDay'
          }
          events={calendarEvents}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          initialDate={currentDate}
          select={handleDateSelect}
          eventClick={handleEventClick}
          height="100%"
        />
      </Paper>

      {/* Event Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {editingEvent ? 'Edit Event' : 'Add Event'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={formValues.title}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Date & Time"
                name="start"
                type="datetime-local"
                value={formValues.start ? formValues.start.substring(0, 16) : ''}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="End Date & Time"
                name="end"
                type="datetime-local"
                value={formValues.end ? formValues.end.substring(0, 16) : ''}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formValues.status}
                  onChange={handleInputChange}
                  label="Status"
                >
                  <MenuItem value="scheduled">Scheduled</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={formValues.location}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {editingEvent && (
            <Button
              onClick={handleDeleteEvent}
              color="error"
              disabled={submitting}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={submitting}
            startIcon={submitting ? <CircularProgress size={20} /> : null}
          >
            {submitting ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CalendarView;