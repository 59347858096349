import React from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress,
  Paper,
  IconButton,
  Divider,
  InputAdornment,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useSellerOnboarding } from '../../../../contexts/SellerOnboardingContext';

// Validation schema
const shippingMethodSchema = yup.object().shape({
  name: yup.string().required('Shipping method name is required'),
  price: yup.number().min(0, 'Price cannot be negative').required('Price is required'),
  estimatedDelivery: yup.string().required('Estimated delivery time is required'),
});

const shippingInfoSchema = yup.object().shape({
  methods: yup.array().of(shippingMethodSchema).min(1, 'At least one shipping method is required'),
  freeShippingThreshold: yup.number().nullable().transform((value) => (isNaN(value) ? null : value)),
  internationalShipping: yup.boolean(),
});

interface ShippingMethod {
  name: string;
  price: number;
  estimatedDelivery: string;
}

interface ShippingInfoFormData {
  methods: ShippingMethod[];
  freeShippingThreshold?: number;
  internationalShipping: boolean;
}

const ShippingInfoStep: React.FC = () => {
  const { shippingInfo, setShippingInfo, saveStep, isLoading, goToStep } = useSellerOnboarding();

  const { control, handleSubmit, formState: { errors } } = useForm<ShippingInfoFormData>({
    resolver: yupResolver(shippingInfoSchema) as any,
    defaultValues: {
      methods: shippingInfo?.methods || [{ name: '', price: 0, estimatedDelivery: '' }],
      freeShippingThreshold: shippingInfo?.freeShippingThreshold || undefined,
      internationalShipping: shippingInfo?.internationalShipping || false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'methods',
  });

  const onSubmit = async (data: ShippingInfoFormData) => {
    setShippingInfo(data);
    await saveStep(4, data);
  };

  const handleBack = () => {
    goToStep(3);
  };

  const addShippingMethod = () => {
    append({ name: '', price: 0, estimatedDelivery: '' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Shipping Information
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Set up your shipping methods and policies
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Shipping Methods
            </Typography>

            {fields.map((field, index) => (
              <Box key={field.id} sx={{ mb: 3 }}>
                {index > 0 && <Divider sx={{ my: 2 }} />}

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle2">
                        Shipping Method {index + 1}
                      </Typography>

                      {fields.length > 1 && (
                        <IconButton onClick={() => remove(index)} size="small">
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name={`methods.${index}.name`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Method Name"
                          fullWidth
                          error={!!errors.methods?.[index]?.name}
                          helperText={errors.methods?.[index]?.name?.message}
                          placeholder="e.g., Standard Delivery, Express Shipping"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`methods.${index}.price`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Price"
                          type="number"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">AED</InputAdornment>,
                          }}
                          error={!!errors.methods?.[index]?.price}
                          helperText={errors.methods?.[index]?.price?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`methods.${index}.estimatedDelivery`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Estimated Delivery Time"
                          fullWidth
                          error={!!errors.methods?.[index]?.estimatedDelivery}
                          helperText={errors.methods?.[index]?.estimatedDelivery?.message}
                          placeholder="e.g., 2-3 business days"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}

            <Button
              startIcon={<AddIcon />}
              onClick={addShippingMethod}
              variant="outlined"
              size="small"
              sx={{ mt: 1 }}
            >
              Add Shipping Method
            </Button>

            {errors.methods && (
              <Typography color="error" variant="caption" sx={{ display: 'block', mt: 1 }}>
                {errors.methods.message}
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Shipping Policies
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="freeShippingThreshold"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Free Shipping Threshold (Optional)"
                      type="number"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">AED</InputAdornment>,
                      }}
                      error={!!errors.freeShippingThreshold}
                      helperText={errors.freeShippingThreshold?.message || "Orders above this amount qualify for free shipping"}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="internationalShipping"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          {...field}
                        />
                      }
                      label="Offer International Shipping"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save & Continue'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ShippingInfoStep;