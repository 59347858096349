import React, { FC } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
  Divider,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkingHours } from '../../../redux/slices/providerSlice';
import { format, parse } from 'date-fns';
import { RootState } from '../../../redux/store';
import { AppDispatch } from '../../../redux/store';

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;
type DayOfWeek = typeof DAYS_OF_WEEK[number];

const WorkingHoursScheduler: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.provider);
  const workingHours = useSelector(
    (state: RootState) =>
      state.provider.profile?.workingHours ||
      DAYS_OF_WEEK.map((day) => ({
        day,
        isOpen: day !== 'Saturday' && day !== 'Sunday',
        openTime: '09:00',
        closeTime: '17:00',
      }))
  );

  const handleToggleDay = (dayIndex: number): void => {
    const updatedHours = [...workingHours];
    updatedHours[dayIndex] = {
      ...updatedHours[dayIndex],
      isOpen: !updatedHours[dayIndex].isOpen,
    };
    dispatch(updateWorkingHours(updatedHours));
  };

  const handleTimeChange = (dayIndex: number, timeType: 'openTime' | 'closeTime', newTime: Date | null): void => {
    if (!newTime) return;

    const timeString = format(newTime, 'HH:mm');
    const updatedHours = [...workingHours];
    updatedHours[dayIndex] = {
      ...updatedHours[dayIndex],
      [timeType]: timeString,
    };
    dispatch(updateWorkingHours(updatedHours));
  };

  const parseTimeString = (timeString: string): Date => {
    return parse(timeString, 'HH:mm', new Date());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Working Hours
        </Typography>

        <Box sx={{ mt: 3 }}>
          {workingHours.map((daySchedule, index) => (
            <React.Fragment key={daySchedule.day}>
              <Grid container spacing={2} alignItems="center" sx={{ py: 1 }}>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={daySchedule.isOpen}
                        onChange={() => handleToggleDay(index)}
                        color="primary"
                      />
                    }
                    label={daySchedule.day}
                  />
                </Grid>

                {daySchedule.isOpen && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <TimePicker
                        label="Opening Time"
                        value={parseTimeString(daySchedule.openTime)}
                        onChange={(newValue) => handleTimeChange(index, 'openTime', newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TimePicker
                        label="Closing Time"
                        value={parseTimeString(daySchedule.closeTime)}
                        onChange={(newValue) => handleTimeChange(index, 'closeTime', newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

                {!daySchedule.isOpen && (
                  <Grid item xs={12} sm={8}>
                    <Typography color="textSecondary">Closed</Typography>
                  </Grid>
                )}
              </Grid>
              {index < DAYS_OF_WEEK.length - 1 && <Divider sx={{ my: 2 }} />}
            </React.Fragment>
          ))}
        </Box>

        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => dispatch(updateWorkingHours(workingHours))}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </Paper>
    </LocalizationProvider>
  );
};

export default WorkingHoursScheduler;