import React from 'react';
import { Container, Typography, Box, Breadcrumbs, Link } from '@mui/material';
import LeadsList from '../../components/pro/ui/LeadsList';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const LeadRequests: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Link component={RouterLink} to="/pro/dashboard" color="inherit">
          Dashboard
        </Link>
        <Typography color="text.primary">Leads</Typography>
      </Breadcrumbs>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Lead Requests
        </Typography>
        <Typography variant="body1" color="text.secondary">
          View and respond to customer lead requests. Manage your leads efficiently to increase your conversion rate.
        </Typography>
      </Box>

      <LeadsList />
    </Container>
  );
};

export default LeadRequests;
