import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Rating,
  Box,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { addToCart } from '../../redux/slices/cartSlice';
import { Product } from '../../types/marketplace';
import imageLoader from '../../utils/imageLoader';

interface ProductCardProps {
  product: Product;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pexelsImage, setPexelsImage] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  // Load product image from Pexels if not available
  useEffect(() => {
    const loadProductImage = async () => {
      // Only load from Pexels if no product image is available
      if (!product.images?.[0]?.url) {
        setLoadingImage(true);
        try {
          console.log(`ProductCard: Fetching image for ${product.name}`);

          // Following the example from README-pexels.md
          const result = await imageLoader.getRandomPexelsImage(
            `${product.name} ${product.category || 'furniture'}`,
            {
              orientation: 'square',
              size: 'medium',
            }
          );

          if (result) {
            console.log(`ProductCard: Found image for ${product.name}: ${result.url}`);
            setPexelsImage(result.url);
          } else {
            console.log(`ProductCard: No image found for ${product.name}, trying fallback`);

            // Try with a more generic search as fallback
            const fallbackResult = await imageLoader.getRandomPexelsImage(
              product.category || 'furniture',
              { orientation: 'square' }
            );

            if (fallbackResult) {
              console.log(`ProductCard: Found fallback image for ${product.name}: ${fallbackResult.url}`);
              setPexelsImage(fallbackResult.url);
            }
          }
        } catch (error) {
          console.error(`Error loading image for ${product.name}:`, error);
        } finally {
          setLoadingImage(false);
        }
      }
    };

    loadProductImage();
  }, [product.name, product.category, product.images]);

  const handleAddToCart = () => {
    dispatch(addToCart({ product, quantity: 1 }));
  };

  return (
    <Card sx={{ maxWidth: 345, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          height: 200,
          backgroundColor: '#e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
        }}
        onClick={() => navigate(`/marketplace/products/${product._id}`)}
      >
        {loadingImage ? (
          <CircularProgress size={24} sx={{ position: 'absolute', top: 10, right: 10 }} />
        ) : null}

        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            padding: 2,
            color: '#555',
            zIndex: 1,
          }}
        >
          {product.name}
        </Typography>

        {/* Always render the image element but control its visibility with CSS */}
        <Box
          component="img"
          src={product.images?.[0]?.url || pexelsImage || ''}
          alt={product.name}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
            display: (product.images?.[0]?.url || pexelsImage) ? 'block' : 'none',
            transition: 'opacity 0.3s ease-in-out, transform 0.5s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
          style={{ opacity: loadingImage ? 0.5 : 0.8 }}
          onError={(e) => {
            // If image fails to load, just hide it
            const target = e.target as HTMLImageElement;
            target.style.display = 'none';
            console.log('ProductCard: Image failed to load:', product.images?.[0]?.url || pexelsImage);
          }}
        />

        {/* Show attribution for Pexels images */}
        {pexelsImage && (
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              bottom: 5,
              right: 5,
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: '2px 5px',
              borderRadius: 1,
              zIndex: 1,
            }}
          >
            Photo from Pexels
          </Typography>
        )}
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h6" component="div" noWrap>
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}>
          {product.description}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" color="primary">
            {product.price.currency} {product.price.amount.toFixed(2)}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
            <Rating value={product.seller.rating} readOnly size="small" />
            <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
              ({product.seller.rating})
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ p: 2, pt: 0 }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleAddToCart}
          disabled={product.inventory.quantity === 0 && !product.inventory.allowBackorder}
        >
          {product.inventory.quantity > 0 || product.inventory.allowBackorder ? 'Add to Cart' : 'Out of Stock'}
        </Button>
      </CardActions>
    </Card>
  );
};