import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import axiosInstance from '../../services/axios';
import { useNavigate, useLocation } from 'react-router-dom';

interface Provider {
  _id: string;
  user: {
    _id: string;
    name: string;
    email: string;
    phone?: string;
  };
  businessName: string;
  isVerified: boolean;
  isActive: boolean;
  onboardingStatus?: {
    completed: boolean;
  };
  createdAt: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`provider-tabpanel-${index}`}
      aria-labelledby={`provider-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ProviderManagement: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialFilter = queryParams.get('filter') || 'all';

  const [tabValue, setTabValue] = useState(initialFilter === 'pending' ? 1 : 0);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    fetchProviders();
  }, [tabValue]);

  const fetchProviders = async () => {
    try {
      setLoading(true);
      let url = '/admin/providers';
      
      // Add query parameters based on tab
      if (tabValue === 1) {
        url += '?verified=false&onboarded=true';
      } else if (tabValue === 2) {
        url += '?verified=true';
      } else if (tabValue === 3) {
        url += '?verified=false';
      }
      const response = await axiosInstance.get(url);
      setProviders(response.data);
      setProviders(response.data);
    } catch (err: any) {
      console.error('Error fetching providers:', err);
      setError(err.message || 'Failed to load providers');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    
    // Update URL query parameter
    const newFilter = newValue === 1 ? 'pending' : 
                      newValue === 2 ? 'verified' : 
                      newValue === 3 ? 'unverified' : 'all';
    navigate(`/admin/providers?filter=${newFilter}`);
  };

  const handleViewProvider = (provider: Provider) => {
    // Navigate to provider details page
    navigate(`/admin/providers/${provider._id}`);
  };

  const handleVerifyProvider = (provider: Provider) => {
    setSelectedProvider(provider);
    setRejectionReason('');
    setVerificationDialogOpen(true);
  };

  const handleCloseVerificationDialog = () => {
    setVerificationDialogOpen(false);
    setSelectedProvider(null);
  };

  const handleSubmitVerification = async (isVerified: boolean) => {
    if (!selectedProvider) return;
    
    try {
      setVerifying(true);
      
      const payload: { isVerified: boolean; rejectionReason?: string } = {
        isVerified
      };
      
      if (!isVerified && rejectionReason.trim()) {
        payload.rejectionReason = rejectionReason.trim();
      }
      await axiosInstance.put(`/admin/providers/${selectedProvider._id}/verify`, payload);
      
      
      // Update the provider in the list
      setProviders(prevProviders => 
        prevProviders.map(p => 
          p._id === selectedProvider._id 
            ? { ...p, isVerified } 
            : p
        )
      );
      
      setVerificationDialogOpen(false);
      setSelectedProvider(null);
      
      // Refresh the list to get updated data
      fetchProviders();
      
    } catch (err: any) {
      console.error('Error verifying provider:', err);
      setError(err.message || 'Failed to verify provider');
    } finally {
      setVerifying(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Provider Management
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Review and manage service providers
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="All Providers" />
          <Tab label="Pending Verification" />
          <Tab label="Verified Providers" />
          <Tab label="Unverified Providers" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          {renderProviderTable(providers)}
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          {renderProviderTable(providers)}
        </TabPanel>
        
        <TabPanel value={tabValue} index={2}>
          {renderProviderTable(providers)}
        </TabPanel>
        
        <TabPanel value={tabValue} index={3}>
          {renderProviderTable(providers)}
        </TabPanel>
      </Paper>

      {/* Verification Dialog */}
      <Dialog open={verificationDialogOpen} onClose={handleCloseVerificationDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {selectedProvider?.isVerified 
            ? "Revoke Provider Verification" 
            : "Verify Provider"}
        </DialogTitle>
        <DialogContent>
          {selectedProvider && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">
                {selectedProvider.businessName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {selectedProvider.user.name} ({selectedProvider.user.email})
              </Typography>
              
              {!selectedProvider.isVerified && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    Are you sure you want to verify this provider?
                  </Typography>
                </Box>
              )}
              
              {selectedProvider.isVerified && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    Are you sure you want to revoke verification for this provider?
                  </Typography>
                  <TextField
                    label="Reason for Rejection"
                    multiline
                    rows={4}
                    fullWidth
                    value={rejectionReason}
                    onChange={(e) => setRejectionReason(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVerificationDialog}>Cancel</Button>
          {selectedProvider && !selectedProvider.isVerified && (
            <Button 
              onClick={() => handleSubmitVerification(true)}
              variant="contained" 
              color="primary"
              disabled={verifying}
              startIcon={verifying ? <CircularProgress size={20} /> : <CheckIcon />}
            >
              Verify Provider
            </Button>
          )}
          {selectedProvider && selectedProvider.isVerified && (
            <Button 
              onClick={() => handleSubmitVerification(false)}
              variant="contained" 
              color="error"
              disabled={verifying}
              startIcon={verifying ? <CircularProgress size={20} /> : <CloseIcon />}
            >
              Revoke Verification
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );

  function renderProviderTable(data: Provider[]) {
    if (loading) {
      return (
        <Box sx={{ py: 4, textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="body2" sx={{ mt: 2 }}>Loading providers...</Typography>
        </Box>
      );
    }

    if (data.length === 0) {
      return (
        <Box sx={{ py: 4, textAlign: 'center' }}>
          <Typography variant="body1">No providers found</Typography>
        </Box>
      );
    }

    // Apply pagination
    const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Business Name</TableCell>
                <TableCell>Provider Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((provider) => (
                <TableRow key={provider._id}>
                  <TableCell>{provider.businessName}</TableCell>
                  <TableCell>{provider.user.name}</TableCell>
                  <TableCell>{provider.user.email}</TableCell>
                  <TableCell>
                    {provider.isVerified ? (
                      <Chip label="Verified" color="success" size="small" />
                    ) : (
                      <Chip label="Unverified" color="default" size="small" />
                    )}
                    {' '}
                    {provider.isActive ? (
                      <Chip label="Active" color="primary" size="small" sx={{ ml: 1 }} />
                    ) : (
                      <Chip label="Inactive" color="default" size="small" sx={{ ml: 1 }} />
                    )}
                  </TableCell>
                  <TableCell>
                    {new Date(provider.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="View Details">
                      <IconButton 
                        size="small" 
                        onClick={() => handleViewProvider(provider)}
                        sx={{ mr: 1 }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={provider.isVerified ? "Revoke Verification" : "Verify Provider"}>
                      <IconButton 
                        size="small" 
                        color={provider.isVerified ? "error" : "success"}
                        onClick={() => handleVerifyProvider(provider)}
                      >
                        {provider.isVerified ? (
                          <CloseIcon fontSize="small" />
                        ) : (
                          <CheckIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  }
};

export default ProviderManagement;