import React from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
} from '@mui/material';
import { PreferenceQuestion, CustomerPreference } from '../../../types/preference';
import PreferenceQuestions from '../PreferenceQuestions';
import CollapsibleSection from '../CollapsibleSection';

interface ProjectDetailsStepProps {
  formData: {
    service: string;
    description?: string;
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
  serviceQuestions: PreferenceQuestion[];
  preferences: CustomerPreference[];
  onPreferencesChange: (preferences: CustomerPreference[]) => void;
}

const ProjectDetailsStep: React.FC<ProjectDetailsStepProps> = ({
  formData,
  onChange,
  onNext,
  onBack,
  serviceQuestions,
  preferences,
  onPreferencesChange,
}) => {
  // Get selected options for summary
  const getSelectedOptionsSummary = (questions: PreferenceQuestion[]): string => {
    if (!questions.length) return '';

    const selectedOptions = questions.flatMap(q => {
      const pref = preferences.find(p => p.questionId === q._id);
      if (!pref || pref.selectedOptions.length === 0) return [];

      return pref.selectedOptions.map(opt => {
        const option = q.options.find(o => o.value === opt);
        return option ? option.optionText : opt;
      });
    });

    return selectedOptions.join(' • ');
  };

  // Filter project type questions
  const projectTypeQuestions = serviceQuestions.filter(
    q => (q.questionText.toLowerCase().includes('project') &&
          !q.questionText.toLowerCase().includes('property')) ||
         (q.questionText.toLowerCase().includes('type') &&
          !q.questionText.toLowerCase().includes('property')) ||
         q.questionText.toLowerCase().includes('option')
  );

  // Filter device type questions - only show for smart home or technology-related services
  const deviceQuestions = serviceQuestions.filter(
    q => {
      const isDeviceQuestion = q.questionText.toLowerCase().includes('device') ||
                              q.questionText.toLowerCase().includes('smart') ||
                              q.questionText.toLowerCase().includes('system');

      // Check if this is specifically about smart devices or technology
      const isSmartHomeRelated = q.questionText.toLowerCase().includes('smart home') ||
                                q.questionText.toLowerCase().includes('automation') ||
                                q.questionText.toLowerCase().includes('technology');

      // Only include device questions if they're explicitly about smart devices
      return isDeviceQuestion && isSmartHomeRelated;
    }
  );

  // Filter space questions - only show for renovation-related services
  const spaceQuestions = serviceQuestions.filter(
    q => {
      const isSpaceQuestion = q.questionText.toLowerCase().includes('space') ||
                             q.questionText.toLowerCase().includes('room') ||
                             q.questionText.toLowerCase().includes('area');

      // Check if this is a renovation-related question
      const isRenovationRelated = q.questionText.toLowerCase().includes('renovate') ||
                                 q.questionText.toLowerCase().includes('remodel');

      // Only include space questions if they're explicitly about renovation
      return isSpaceQuestion && isRenovationRelated;
    }
  );

  // Filter brand/preference questions
  const brandQuestions = serviceQuestions.filter(
    q => q.questionText.toLowerCase().includes('brand') ||
         q.questionText.toLowerCase().includes('prefer')
  );

  // Check if form is valid
  const isValid = () => {
    // Check if all required questions have answers
    const allQuestions = [...projectTypeQuestions, ...deviceQuestions, ...spaceQuestions, ...brandQuestions];
    const requiredQuestions = allQuestions.filter(q => q.isRequired);

    for (const question of requiredQuestions) {
      const preference = preferences.find(p => p.questionId === question._id);

      if (!preference ||
          (preference.selectedOptions.length === 0 && !preference.customAnswer)) {
        return false;
      }
    }

    return true;
  };

  return (
    <Paper elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
      <Typography variant="h5" gutterBottom>
        Project Details
      </Typography>

      {projectTypeQuestions.length > 0 && (
        <CollapsibleSection
          title={projectTypeQuestions[0]?.questionText || "Project Type"}
          summary={getSelectedOptionsSummary(projectTypeQuestions)}
          defaultExpanded={true}
        >
          <PreferenceQuestions
            questions={projectTypeQuestions}
            preferences={preferences}
            onPreferencesChange={onPreferencesChange}
          />
        </CollapsibleSection>
      )}

      {deviceQuestions.length > 0 && (
        <CollapsibleSection
          title={deviceQuestions[0]?.questionText || "Equipment Details"}
          summary={getSelectedOptionsSummary(deviceQuestions)}
          defaultExpanded={projectTypeQuestions.length === 0}
        >
          <PreferenceQuestions
            questions={deviceQuestions}
            preferences={preferences}
            onPreferencesChange={onPreferencesChange}
          />
        </CollapsibleSection>
      )}

      {spaceQuestions.length > 0 && (
        <CollapsibleSection
          title={spaceQuestions[0]?.questionText || "Location Details"}
          summary={getSelectedOptionsSummary(spaceQuestions)}
          defaultExpanded={deviceQuestions.length === 0 && projectTypeQuestions.length === 0}
        >
          <PreferenceQuestions
            questions={spaceQuestions}
            preferences={preferences}
            onPreferencesChange={onPreferencesChange}
          />
        </CollapsibleSection>
      )}

      {brandQuestions.length > 0 && (
        <CollapsibleSection
          title={brandQuestions[0]?.questionText || "Preferences"}
          summary={getSelectedOptionsSummary(brandQuestions)}
          defaultExpanded={deviceQuestions.length === 0 && spaceQuestions.length === 0 && projectTypeQuestions.length === 0}
        >
          <PreferenceQuestions
            questions={brandQuestions}
            preferences={preferences}
            onPreferencesChange={onPreferencesChange}
          />
        </CollapsibleSection>
      )}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onNext}
          disabled={!isValid()}
        >
          Next
        </Button>
      </Box>
    </Paper>
  );
};

export default ProjectDetailsStep;